// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var DropdownMenu$Astrolabe = require("../shared/DropdownMenu.bs.js");

function DownloadsDropdown(Props) {
  var experimentId = Props.experimentId;
  var hasDebarcodingKits = Props.hasDebarcodingKits;
  var item = function (reportType, name) {
    return React.createElement(DropdownMenu$Astrolabe.Item.make, {
                className: "p-2 btn-sm border-top-dark-blue-half-pixel rounded-0 astrolabe-bg-dark-blue-on-hover",
                onClick: (function (param) {
                    var url = Constants$Astrolabe.experimentReportsPath(experimentId, reportType);
                    var resultWindow = window.open(url, name, undefined);
                    return Util$Astrolabe.$$Option.if_none((resultWindow == null) ? undefined : Caml_option.some(resultWindow), (function (param) {
                                  console.log("Error: Could not open URL: " + url);
                                  
                                }));
                  }),
                children: React.createElement("div", {
                      className: "d-flex justify-content-between text-white",
                      style: {
                        width: "180px"
                      }
                    }, name, React.createElement(Icons$Astrolabe.Download.make, {
                          className: "white",
                          height: "15px",
                          width: "15px"
                        }))
              });
  };
  return React.createElement(DropdownMenu$Astrolabe.make, {
              rightAlignment: true,
              className: "btn-link btn-sm no-underline-on-hover text-white text-decoration-none p-0",
              dropdownClassName: "astrolabe-bg-blue border-0 rounded-0 p-0 mt-2",
              text: "Downloads",
              title: "Download the available Astrolabe exports",
              children: null
            }, item("fcs", "Labeled FCS Files"), hasDebarcodingKits ? item("debarcoding_report", "Debarcoding Report") : null, item("reports", "Reports"), item("analysis", "R Files"));
}

var make = DownloadsDropdown;

var $$default = DownloadsDropdown;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
