/* @flow */
"use strict";

import React from "react";
import { VictoryAxis, VictoryChart, VictoryGroup, VictoryLegend, VictoryScatter, VictoryVoronoiContainer } from "victory";
import type { ScatterPlotData, ScatterPlotProps } from "models";
import theme from "models/victoryTheme";

export default class ChannelScatterPlot extends React.PureComponent<ScatterPlotProps> {
  constructor(props: ScatterPlotProps) {
    super(props);
  }

  render() {
    return (
      <VictoryChart
        theme={theme}
        width={600}
        height={600}
        padding={{ top: 20, right: 50, bottom: 50, left: 50 }}
        containerComponent={<VictoryVoronoiContainer width={1000} height={700} responsive={false} />}
      >
        <VictoryAxis label={this.props.xAxisLabel} tickValues={[...Array(this.props.xMax).keys()]} />
        <VictoryAxis
          crossAxis
          dependentAxis
          label={this.props.yAxisLabel}
          tickValues={[...Array(this.props.yMax).keys()]}
          style={{ grid: { stroke: (t) => (t == 0 ? "#000" : "#eee") } }}
        />

        <VictoryGroup>
          {this.props.data.map((datum, i) => {
            return (
              <VictoryScatter
                key={i}
                data={datum}
                size={2}
                style={{ data: { fillOpacity: this.props.data.length == 1 ? 1 : 0.75 } }}
              />
            );
          })}
        </VictoryGroup>

        {this.props.legendLabels ? (
          <VictoryLegend
            x={40}
            y={600}
            itemsPerRow={3}
            data={this.props.legendLabels.map((name) => {
              return { name };
            })}
          />
        ) : null}
      </VictoryChart>
    );
  }
}
