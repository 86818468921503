// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function TitleWithIcon(Props) {
  var className = Props.className;
  var subtitle = Props.subtitle;
  var title = Props.title;
  var children = Props.children;
  return React.createElement("div", {
              className: "d-flex " + className
            }, React.createElement("div", {
                  className: "mr-3 mt-1",
                  style: {
                    minWidth: "60px"
                  }
                }, children), React.createElement("div", {
                  className: "d-flex flex-column"
                }, React.createElement("h3", {
                      className: "astrolabe-text-blue"
                    }, title), React.createElement("div", undefined, subtitle)));
}

var make = TitleWithIcon;

var $$default = TitleWithIcon;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
