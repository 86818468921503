/* @flow */
"use strict";

import React from "react";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import type { Session } from "models";
import { faqUrl, tutorialUrl } from "../utils/Constants.bs";

type Props = {
  onSignOut: () => void,
  session: Session,
};

type State = {
  navMenuExpanded: boolean,
};

export default class Nav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { navMenuExpanded: false };
  }

  isPublicExp = window.location.pathname.startsWith("/public");

  renderMenuButton() {
    if (!this.props.session.user) {
      return <Link to="/sign_in">Sign In</Link>;
    }

    return (
      <div className="nav ml-auto">
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => this.setState({ navMenuExpanded: !this.state.navMenuExpanded })}
        >
          {!this.state.navMenuExpanded ? <span className="navbar-toggler-icon" /> : <h3 className="mb-0">&times;</h3>}
        </button>
      </div>
    );
  }

  renderPublicExpButtons() {
    return (
      <div className="d-flex">
        <a href={faqUrl} className="nav-link" target="_blank">
          FAQ
        </a>
        <a href={tutorialUrl} className="nav-link" target="_blank">
          Tutorials
        </a>
      </div>
    );
  }

  render() {
    return (
      <nav className={"navbar navbar-collapse-sm fixed-top navbar-dark" + (this.state.navMenuExpanded ? " navbar-expanded" : "")}>
        <div className="d-flex navbar-expand-sm">
          {this.props.session.user ? (
            <Link className="navbar-brand" to="/">
              ASTROLABE
            </Link>
          ) : (
            <a className="navbar-brand" href={"https://astrolabediagnostics.com/"}>
              ASTROLABE
            </a>
          )}

          <div id="nav-title" className="navbar-collapse collapse" />
        </div>

        {this.isPublicExp ? (
          this.renderPublicExpButtons()
        ) : (
          <React.Fragment>
            {this.renderMenuButton()}

            {this.state.navMenuExpanded ? (
              <NavMenu
                session={this.props.session}
                onHide={() => this.setState({ navMenuExpanded: false })}
                onSignOut={this.props.onSignOut}
              />
            ) : null}
          </React.Fragment>
        )}
      </nav>
    );
  }
}
