/* @flow */
import React from "react";
import type { Match } from "react-router";
import { Redirect } from "react-router-dom";
import type { Session } from "models";
import { fetchJSON } from "components/utils";
import NewPassword from "./New";
import NavTitle from "../NavTitle";

type Props = {
  location: Location,
  match: Match,
  onSignIn: (session: Session) => any,
  session: Session,
};

type InvalidTokenReason = "link_expired" | "user_not_found";

type State = {
  error: ?string,
  invalidToken: boolean,
  invalidTokenReason: ?InvalidTokenReason,
  password: string,
  passwordConfirmation: string,
  resetPasswordSucceeded: boolean,
};

export default class New extends React.Component<Props, State> {
  handleSubmit: (SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      invalidToken: false,
      invalidTokenReason: null,
      password: "",
      passwordConfirmation: "",
      resetPasswordSucceeded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.validateToken();
  }

  async validateToken() {
    this.setState({ error: null });
    var resp = await fetchJSON("GET", `/users/passwords/${this.props.match.params.token}/validate`);

    if (resp.error) {
      this.setState({ error: resp.error });
    } else if (!resp.success) {
      this.setState({ invalidToken: true, invalidTokenReason: resp.reason });
    }
  }

  handleSubmit = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.resetPassword({
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      reset_password_token: this.props.match.params.token,
    });
  };

  async resetPassword(params: Object) {
    this.setState({ error: null });
    var resp = await fetchJSON("PUT", "/users/password.json", { user: params });

    if (resp.error) {
      this.setState({ error: resp.error });
    } else if (resp.user && resp.instance) {
      this.props.onSignIn(resp);
    } else {
      this.setState({ resetPasswordSucceeded: true, password: "", passwordConfirmation: "" });
    }
  }

  render() {
    if (this.props.session.user) {
      return <Redirect push to="/" />;
    }

    if (this.state.invalidToken) {
      let reasonText = "Please request a new one below.";

      if (this.state.invalidTokenReason === "link_expired") {
        reasonText = "A new one has been sent to you.";
      }

      return (
        <React.Fragment>
          <div className="row align-items-center justify-content-center">
            <div className="col" />
            <div className="alert alert-warning" role="alert">
              This link has expired. {reasonText}
            </div>
            <div className="col" />
          </div>
          {this.state.invalidTokenReason === "user_not_found" ? <NewPassword session={this.props.session} /> : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="Reset Password" />
        <div className="row align-items-center justify-content-center">
          <div className="col" />
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.state.resetPasswordSucceeded ? (
            <div className="alert alert-success" role="alert">
              Your password has been reset.
            </div>
          ) : null}
          <div className="col" />
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col" />
          <div className="jumbotron p-4 col-xs-12 col-md-6">
            <form className="form" method="post" action="/users/password" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password_confirmation">Password Confirmation</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password_confirmation"
                    value={this.state.passwordConfirmation}
                    onChange={(e) => this.setState({ passwordConfirmation: e.target.value })}
                  />
                </div>
              </div>

              <div className="form-group mb-0">
                <button className="btn btn-primary">Reset Password</button>
              </div>
            </form>
          </div>
          <div className="col" />
        </div>
      </React.Fragment>
    );
  }
}
