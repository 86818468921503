/* @flow */

import React from "react";
import { List, Map } from "immutable";
import { Link } from "react-router-dom";
import { Instance, User } from "models";
import { fetchJSON } from "components/utils";
import DeleteIcon from "../../shared/DeleteIcon.bs";

type Props = {
  accessingUserIsSysadmin: boolean,
  instance: Instance,
};

type State = {
  errorAction: ?string,
  errors: ?List<string>,
  roleNames: ?List<string>,
  successMessage: ?string,
  user: User,
  users: List<User>,
};

export default class Users extends React.Component<Props, State> {
  handleSave: (e: SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      errorAction: null,
      errors: null,
      roleNames: null,
      successMessage: null,
      user: new User({ instance_id: props.instance.get("id") }),
      users: List(),
    };

    this.fetchUsers();
  }

  async fetchUsers() {
    const json = await fetchJSON("GET", `/admin/instances/${this.props.instance.get("id")}/users.json`);
    const users = List(json.users.map((e) => new User(e)));
    const roleNames = List(json.role_names);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({ users, roleNames });
    }
  }

  createUserDisabled(): boolean {
    const email = this.state.user.get("email");
    const roleName = this.state.user.get("role_name");
    return !email || email === "" || !roleName || roleName === "";
  }

  handleSave = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.saveUser();
  };

  async saveUser() {
    this.setState({ errors: null });
    var resp = await fetchJSON("POST", `/admin/users.json`, { user: this.state.user });

    if (resp.errors) {
      this.setState({ errorAction: "saved", errors: List(resp.errors), successMessage: null });
    } else {
      this.setState({
        errors: null,
        successMessage: `Account for ${this.state.user.get(
          "email",
        )} has been created. They have been emailed password reset instructions.`,
        user: new User({ instance_id: this.props.instance.get("id") }),
      });
      this.fetchUsers();
    }
  }

  async deleteUser(user: User) {
    if (!window.confirm(`Are you sure you want to delete ${user.get("email")}? This action can't be undone.`)) {
      return;
    }

    var resp = await fetchJSON("DELETE", `/admin/users/${user.get("id")}.json`);
    if (resp.errors) {
      this.setState({ errorAction: "deleted", errors: List(resp.errors), successMessage: null });
    } else {
      this.setState({
        errors: null,
        successMessage: `Account for ${user.get("email")} has been deleted.`,
      });
      this.fetchUsers();
    }
  }

  setUserProp(prop: string, value: any) {
    this.setState({ user: this.state.user.set(prop, value) });
  }

  renderUsersTable(title: string, users: List<User>) {
    if (users.size == 0) {
      return null;
    }

    return (
      <div className="row mb-4">
        <h3>{title}</h3>
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th style={{ width: "30px" }}>&nbsp;</th>
              <th style={{ width: "500px" }}>Email Address</th>
              <th style={{ width: "100px" }}>Created On</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => {
              return (
                <tr key={user.get("id")}>
                  <td>
                    <DeleteIcon
                      title="Remove this user from the account and from all shared experiments. This action cannot be undone."
                      onClick={() => this.deleteUser(user)}
                    />
                  </td>
                  <td style={{ width: "500px" }}>{user.get("email")}</td>
                  <td style={{ width: "100px" }}>{user.get("created_at_ymd")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const groupedByRole = this.state.users.groupBy((u) => u.get("role_name"));

    return (
      <div>
        <div className="row align-items-center justify-content-center">
          {this.state.errors && this.state.errorAction ? (
            <div className="alert alert-danger" role="alert">
              <p>User couldn't be {this.state.errorAction} due to the following:</p>
              <ul className="mb-0">{this.state.errors.map((error, i) => <li key={i}>{error}</li>).toArray()}</ul>
            </div>
          ) : null}
          {this.state.successMessage ? (
            <div className="alert alert-success" role="alert">
              {this.state.successMessage}
            </div>
          ) : null}
        </div>
        <div className="row justify-content-end">
          <form className="form-inline" onSubmit={this.handleSave}>
            <input
              className="form-control mr-2"
              type="email"
              name="email"
              placeholder="Email Address"
              value={this.state.user.get("email") || ""}
              onChange={(e) => this.setUserProp("email", e.target.value)}
            />
            <select
              className="form-control mr-2"
              value={this.state.user.get("role_name") || ""}
              onChange={(e) => this.setUserProp("role_name", e.target.value)}
            >
              <option key="-1">Role</option>
              {(this.state.roleNames || []).map((roleName) => (
                <option key={roleName}>{roleName}</option>
              ))}
            </select>
            <button type="submit" disabled={this.createUserDisabled()} className="btn btn-primary">
              Create User
            </button>
          </form>
        </div>
        <hr className="invisible" />

        {this.props.accessingUserIsSysadmin && this.renderUsersTable("Staff", groupedByRole.get("staff", List()))}
        {this.renderUsersTable("Admins", groupedByRole.get("admin", List()))}
        {this.renderUsersTable("Users", groupedByRole.get("user", List()))}
      </div>
    );
  }
}
