// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../../models/Util.bs.js");
var FeatureMutations$Astrolabe = require("../FeatureMutations.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../../utils/ReasonReactHelpers.bs.js");

function CreateFeatureButton(Props) {
  var experimentId = Props.experimentId;
  var featureName = Props.featureName;
  var isAllowNa = Props.isAllowNa;
  var featureValues = Props.featureValues;
  var onErrors = Props.onErrors;
  var onSave = Props.onSave;
  return React.createElement(FeatureMutations$Astrolabe.CreateFeatureMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement("button", {
                              className: "btn btn-primary ml-1",
                              onClick: (function (e) {
                                  ReasonReactHelpers$Astrolabe.preventDefault(e);
                                  var variables = FeatureMutations$Astrolabe.CreateFeatureConfig.make(experimentId, featureName, isAllowNa, featureValues, undefined).variables;
                                  Curry._4(mutation, Caml_option.some(variables), ["getExperiment"], undefined, undefined).then((function (resp) {
                                          if (typeof resp !== "number") {
                                            if (resp.tag) {
                                              var createFeature = resp[0].createFeature;
                                              if (createFeature !== undefined) {
                                                var errors = Caml_option.valFromOption(createFeature).errors;
                                                if (Util$Astrolabe.$$Array.isEmpty(errors)) {
                                                  Curry._1(onSave, undefined);
                                                } else {
                                                  Curry._1(onErrors, errors);
                                                }
                                              }
                                              
                                            } else {
                                              console.log(resp[0]);
                                            }
                                          }
                                          return Promise.resolve(undefined);
                                        }));
                                  
                                })
                            }, "Save");
                })
            });
}

var CreateFeatureMutation;

var CreateFeatureConfig;

var make = CreateFeatureButton;

exports.CreateFeatureMutation = CreateFeatureMutation;
exports.CreateFeatureConfig = CreateFeatureConfig;
exports.make = make;
/* react Not a pure module */
