/* @flow */
"use strict";

import React, { Fragment } from "react";
import type { History, Match } from "react-router";
import { Link } from "react-router-dom";
import { List } from "immutable";
import { Experiment, Feature, Sample, SampleStatistics } from "models";
import Header from "./Header";
import NavTitle from "../NavTitle";
import { fetchJSON } from "components/utils";
import SampleNavigator from "./SampleNavigator";
import ExperimentHeader from "../header/ExperimentHeader.bs";

type Props = {
  history: History,
  match: Match,
};

type State = {
  experiment: ?Experiment,
  experimentSamples: List<Sample>,
  features: List<Feature>,
  fetchingSample: boolean,
  heatMapData: ?SampleStatistics,
  sample: ?Sample,
};

export default class Show extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      experiment: null,
      experimentSamples: List(),
      features: List(),
      fetchingSample: false,
      heatMapData: null,
      sample: null,
    };
  }

  componentDidMount() {
    this.fetchSample();
    this.fetchHeatMapData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.sample !== prevState.sample && this.state.sample && !this.state.sample.get("feature_values_loaded")) {
      this.fetchSample();
    }
  }

  async fetchSample() {
    this.setState({ fetchingSample: true });
    var sampleJSON = await fetchJSON(
      "GET",
      `/experiments/${this.props.match.params.experimentId}/samples/${this.props.match.params.sampleId}.json`,
    );

    if (sampleJSON.errors || sampleJSON.error) return this.setState({ fetchingSample: false });

    var sample = new Sample(sampleJSON);

    var experimentJSON = await fetchJSON("GET", `/experiments/${this.props.match.params.experimentId}.json`);
    var experiment = new Experiment(experimentJSON.experiment);
    var features = List(experimentJSON.features.map((f) => new Feature({ ...f, values: List(f.values) })));
    var experimentSamples = List(experimentJSON.samples.map((s) => new Sample(s)));

    this.setState({ fetchingSample: false, experiment, features, experimentSamples, sample });
  }

  async fetchHeatMapData() {
    if (this.state.heatMapData) {
      return;
    }

    const data = await fetchJSON(
      "GET",
      `/experiments/${this.props.match.params.experimentId}/samples/${this.props.match.params.sampleId}/heat_map_data.json`,
    );
    this.setState({ heatMapData: new SampleStatistics(data) });
  }

  renderErrors() {
    return (
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading mb-0 text-center">Experiment Not Found</h4>
      </div>
    );
  }

  render() {
    this.fetchHeatMapData();

    if (!this.state.experiment && !this.state.fetchingSample) return this.renderErrors();

    const isPublicPath = window.location.pathname.startsWith("/public");

    const privateExperimentWithPublicPath = this.state.experiment && !this.state.experiment.is_public && isPublicPath;
    const pubExperimentWithPrivatePathAndUnauthorized =
      this.state.experiment && !this.state.experiment.authorized_for_user_id && !isPublicPath;

    if (privateExperimentWithPublicPath || pubExperimentWithPrivatePathAndUnauthorized) {
      return this.renderErrors();
    }

    if (!this.state.experiment || !this.state.sample || !this.state.sample.get("feature_values_loaded")) {
      return <h3>Loading…</h3>;
    }

    const experiment = this.state.experiment;
    const sample = this.state.sample;

    const expPath = isPublicPath ? "/public/experiments" : "/experiments";

    const onChange = (sample: Sample) => {
      this.setState({ sample, heatMapData: null }, () =>
        this.props.history.push(`${expPath}/${experiment.get("id")}/samples/${sample.get("id")}/explorer`),
      );
    };

    return (
      <Fragment>
        <NavTitle documentTitle={`${experiment.get("name")} > Sample Navigator | Astrolabe`}>
          <Link to={`${expPath}/${experiment.get("id")}`}>{experiment.get("name")}</Link>
          <span className="navbar-text">&nbsp;> Sample Navigator</span>
        </NavTitle>
        <ExperimentHeader experimentId={experiment.get("id")} />
        <Header
          experiment={experiment}
          features={this.state.features}
          sample={sample}
          experimentSamples={this.state.experimentSamples}
          onChange={onChange}
        />
        <hr className="mt-0" />
        {this.state.heatMapData ? (
          <SampleNavigator experiment={experiment} sample={sample} heatMapData={this.state.heatMapData} />
        ) : (
          <h3>Loading…</h3>
        )}
      </Fragment>
    );
  }
}
