// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");

function str(prim) {
  return prim;
}

function LabelingStrategies(Props, _children) {
  var experiment = Props.experiment;
  var match = experiment.labeling_strategies.length;
  if (match !== 0) {
    return React.createElement("div", undefined, React.createElement("div", {
                    className: "row"
                  }, React.createElement("p", {
                        className: "col-12"
                      }, "The following table lists the labeling strategies for each of the cell subsets in the Assignment level of the experiment. ", "Astrolabe follows a hierarchical gating strategy where cells are assigned to increasingly granular subsets. ", "In the table, levels are separated from each other with a comma (for example, CD3+ CD19-, CD4+ CD8-). ")), React.createElement("table", {
                    className: "table"
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Compartment"), React.createElement("th", undefined, "Cell Subset"), React.createElement("th", undefined, "Description"))), React.createElement("tbody", undefined, $$Array.map((function (ls) {
                              return React.createElement("tr", {
                                          key: String(ls.id)
                                        }, React.createElement("td", undefined, ls.parent), React.createElement("td", undefined, ls.cell_subset), React.createElement("td", undefined, ls.desc));
                            }), experiment.labeling_strategies))));
  }
  var match$1 = experiment.organism;
  if (match$1 !== -278559483) {
    return React.createElement("div", {
                className: "alert alert-warning"
              }, React.createElement("strong", undefined, "This experiment has no labeling strategies. "), "Please contact ", React.createElement("a", {
                    href: "mailto:support@astrolabediagnostics.com"
                  }, "support@astrolabediagnostics.com"), " to have them added to this experiment.");
  } else {
    return React.createElement("div", {
                className: "alert alert-warning text-center"
              }, "“Profiling Only” experiments do not have an Assignment level, and therefore do not have subset labeling strategies.");
  }
}

var make = LabelingStrategies;

var $$default = LabelingStrategies;

exports.str = str;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
