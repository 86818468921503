// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function handleErrors(errors) {
  console.log(errors);
  
}

function ignoreDataHandleErrors(mutationPromise) {
  mutationPromise.then((function (resp) {
          if (typeof resp !== "number" && !resp.tag) {
            console.log(resp[0]);
          }
          return Promise.resolve(undefined);
        }));
  
}

function handleDataAndErrors(func, mutationPromise) {
  mutationPromise.then((function (resp) {
          if (typeof resp !== "number") {
            if (resp.tag) {
              Curry._1(func, resp[0]);
            } else {
              console.log(resp[0]);
            }
          }
          return Promise.resolve(undefined);
        }));
  
}

exports.handleErrors = handleErrors;
exports.ignoreDataHandleErrors = ignoreDataHandleErrors;
exports.handleDataAndErrors = handleDataAndErrors;
/* No side effect */
