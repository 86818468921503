// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_undefined = require("bs-platform/lib/js/js_undefined.js");
var JsModelsAttributes$Astrolabe = require("./JsModelsAttributes.bs.js");

var DataTable = { };

function all(t) {
  return Caml_option.nullable_to_opt(t.get("all"));
}

function $$default(t) {
  return Caml_option.nullable_to_opt(t.get("default"));
}

function values(t) {
  return Caml_option.nullable_to_opt(t.get("values"));
}

function cellSubsetMap(t) {
  return Caml_option.nullable_to_opt(t.get("cell_subset_map"));
}

var CellSubsets = {
  all: all,
  $$default: $$default,
  values: values,
  cellSubsetMap: cellSubsetMap
};

var Experiment = {
  getOrElse: JsModelsAttributes$Astrolabe.Getters.getOrElse
};

function chartData(t, channelDesc, cellSubsetLevel, cellSubset, xOffset, param) {
  return t.chartData(channelDesc, cellSubsetLevel, Js_undefined.fromOption(cellSubset), Js_undefined.fromOption(xOffset));
}

var SampleDensities = {
  chartData: chartData
};

function boxPlotDatum(cellSubset, featureId, featureValue, t) {
  return t.boxPlotDatum(cellSubset, featureId, featureValue);
}

function fromJs(jsObj) {
  return {
          box_plot_stats: jsObj.box_plot_stats,
          frequency: jsObj.frequency,
          scaled_frequency: jsObj.scaled_frequency,
          subset_channel_statistics: jsObj.subset_channel_statistics,
          suggested_order: {
            cell_subset_order: jsObj.suggested_order.cell_subset_order,
            channel_order: jsObj.suggested_order.channel_order,
            sample_id_order: jsObj.suggested_order.sample_id_order,
            sample_name_order: jsObj.suggested_order.sample_name_order
          }
        };
}

var ExperimentStatistics = {
  boxPlotDatum: boxPlotDatum,
  fromJs: fromJs
};

exports.DataTable = DataTable;
exports.CellSubsets = CellSubsets;
exports.Experiment = Experiment;
exports.SampleDensities = SampleDensities;
exports.ExperimentStatistics = ExperimentStatistics;
/* No side effect */
