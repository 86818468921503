// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

var styles = {
  control: ((base) => ({ ...base, fontSize: '0.875rem', minHeight: '31px' })),
  dropdownIndicator: ((base) => ({ ...base, padding: 4 })),
  clearIndicator: ((base) => ({ ...base, padding: 4 })),
  valueContainer: ((base) => ({ ...base, padding: '0px 3px' })),
  multiValueLabel: ((base) => ({ ...base, color: 'white' })),
  multiValue: ((base) => ({ ...base, backgroundColor: "rgb(43, 192, 226)", color: 'white' })),
  input: ((base) => ({ ...base, margin: 0, padding: 0 })),
  option: ((base, state) => ({ ...base, color: state.isFocused ? 'white' : base.color, fontSize: '0.875rem', paddingTop: "3px", paddingBottom: "3px" }))
};

var theme = ((theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "rgb(43, 192, 226)",
      }
    }));

function makeProps(defaultValue, options, onChange, styles, theme, value, children) {
  return (function (param, param$1) {
      var tmp = {
        isMulti: false
      };
      if (defaultValue !== undefined) {
        tmp.defaultValue = Caml_option.valFromOption(defaultValue);
      }
      if (options !== undefined) {
        tmp.options = Caml_option.valFromOption(options);
      }
      if (onChange !== undefined) {
        tmp.onChange = Caml_option.valFromOption(onChange);
      }
      if (styles !== undefined) {
        tmp.styles = Caml_option.valFromOption(styles);
      }
      if (theme !== undefined) {
        tmp.theme = Caml_option.valFromOption(theme);
      }
      if (value !== undefined) {
        tmp.value = Caml_option.valFromOption(value);
      }
      if (children !== undefined) {
        tmp.children = Caml_option.valFromOption(children);
      }
      if (param !== undefined) {
        tmp.key = Caml_option.valFromOption(param);
      }
      return tmp;
    });
}

var Single = {
  makeProps: makeProps
};

function makeProps$1(isDisabled, options, onChange, styles, theme, value, children) {
  return (function (param, param$1) {
      var tmp = {
        isMulti: true
      };
      if (isDisabled !== undefined) {
        tmp.isDisabled = Caml_option.valFromOption(isDisabled);
      }
      if (options !== undefined) {
        tmp.options = Caml_option.valFromOption(options);
      }
      if (onChange !== undefined) {
        tmp.onChange = Caml_option.valFromOption(onChange);
      }
      if (styles !== undefined) {
        tmp.styles = Caml_option.valFromOption(styles);
      }
      if (theme !== undefined) {
        tmp.theme = Caml_option.valFromOption(theme);
      }
      if (value !== undefined) {
        tmp.value = Caml_option.valFromOption(value);
      }
      if (children !== undefined) {
        tmp.children = Caml_option.valFromOption(children);
      }
      if (param !== undefined) {
        tmp.key = Caml_option.valFromOption(param);
      }
      return tmp;
    });
}

var Multi = {
  makeProps: makeProps$1
};

exports.styles = styles;
exports.theme = theme;
exports.Single = Single;
exports.Multi = Multi;
/* No side effect */
