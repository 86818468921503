/* @flow */
"use strict";

// THIS MODULES HAS BEEN REWRITTEN IN REASON AND WILL BE REMOVED SOON

import { VictoryTheme } from "victory";
import { dark2 } from "./Theme.bs.js";

const baseLabelStyles = {
  fontFamily: "'Rubik', 'Lucida Grande', 'Lucida Sans', Arial, 'sans-serif'",
  fontSize: 12,
  padding: 5,
};

const baseBoxPlotStyles = {
  stroke: (d) => dark2[d.eventKey % dark2.length],
  fill: (d) => dark2[d.eventKey % dark2.length],
};

const baseBoxPlotLabelStyles = {
  ...baseLabelStyles,
  fontSize: 10,
  padding: 5,
  fill: (d) => dark2[d.eventKey],
  stroke: "transparent",
};

const noGridVariant = {
  style: {
    axis: { stroke: "#ddd" },
    axisLabel: { ...baseLabelStyles, fontSize: 11, padding: 30 },
    grid: { stroke: "transparent", size: 0 },
    ticks: { stroke: VictoryTheme.grayscale.axis.style.axis.stroke, size: 5 },
    tickLabels: { ...baseLabelStyles, fontSize: 11 },
  },
};

export default {
  ...VictoryTheme.grayscale,
  axis: {
    style: {
      axisLabel: { ...baseLabelStyles, padding: 25 },
      tickLabels: baseLabelStyles,
      grid: { stroke: "#eee", size: 5 },
    },
  },
  boxplot: {
    ...VictoryTheme.grayscale.boxplot,
    boxWidth: 20,
    style: {
      ...VictoryTheme.grayscale.boxplot.style,
      min: baseBoxPlotStyles,
      q1: baseBoxPlotStyles,
      median: { stroke: "white", strokeWidth: 1 },
      q3: baseBoxPlotStyles,
      max: baseBoxPlotStyles,
      minLabels: baseBoxPlotLabelStyles,
      q1Labels: baseBoxPlotLabelStyles,
      medianLabels: baseBoxPlotLabelStyles,
      q3Labels: baseBoxPlotLabelStyles,
      maxLabels: baseBoxPlotLabelStyles,
    },
  },
  group: {
    colorScale: dark2,
    style: {
      data: { strokeWidth: 1, fillOpacity: 0.6 },
    },
  },
  legend: {
    colorScale: dark2,
    orientation: "horizontal",
    gutter: 15,
    style: {
      parent: { padding: 5 },
      labels: { ...VictoryTheme.grayscale.legend.style.labels, ...baseLabelStyles },
    },
  },
  tooltip: {
    cornerRadius: 0,
    style: {
      padding: 5,
      textAnchor: "middle",
    },
    flyoutStyle: {
      fill: "white",
      stroke: "#ccc",
      strokeWidth: 1,
    },
  },
  variants: {
    axis: {
      boxplot: {
        style: {
          ...noGridVariant.style,
          colorScale: dark2,
          tickLabels: { ...noGridVariant.style.tickLabels, angle: -90, padding: 5, verticalAnchor: "middle", textAnchor: "end" },
        },
      },
      matchingNivo: {
        style: {
          axis: { stroke: "#ddd" },
          axisLabel: { ...baseLabelStyles, fontSize: 11, padding: 40 },
          ticks: { stroke: VictoryTheme.grayscale.axis.style.axis.stroke, size: 5 },
          tickLabels: { ...baseLabelStyles, fontSize: 11 },
        },
      },
      mdsMap: {
        style: {
          ...noGridVariant.style,
          axisLabel: { ...baseLabelStyles, fontSize: 11, padding: 10 },
          ticks: { stroke: "#fff", size: 0 },
        },
      },
      sparkLine: {
        style: {
          axis: { padding: 0 },
          axisLabel: { padding: 0 },
          grid: { stroke: "transparent", size: 0 },
          ticks: { stroke: VictoryTheme.grayscale.axis.style.axis.stroke, size: 0 },
          tickLabels: { fontSize: 0 },
        },
      },
      noGrid: noGridVariant,
    },
  },
};
