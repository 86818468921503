/* @flow */
"use strict";

import React from "react";
import type { History } from "react-router";
import { List } from "immutable";
import { Instance } from "models";
import { fetchJSON, toISODateString } from "components/utils";
import Form from "./Form";
import NavTitle from "../../NavTitle";

type Props = {
  history: History,
};

type State = {
  instance: Instance,
  errors: ?List<string>,
};

export default class New extends React.Component<Props, State> {
  handleSave: (e: SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      instance: new Instance(),
      errors: null,
    };
  }

  handleSave = async (instance: Instance) => {
    this.setState({ errors: null });
    var resp = await fetchJSON("POST", "/admin/instances.json", { instance });

    if (resp.errors) {
      this.setState({ errors: List(resp.errors) });
    } else {
      window.location.pathname = `/admin/instances`;
    }
  };

  render() {
    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="New Instance" />
        <Form
          formProps={{ method: "POST", action: `/admin/instances.json` }}
          errors={this.state.errors}
          history={this.props.history}
          instance={this.state.instance}
          onSave={this.handleSave}
        />
      </React.Fragment>
    );
  }
}
