// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var SaveSvgAsPng = require("save-svg-as-png");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");

function options(backgroundColor, scale, param) {
  return {
          backgroundColor: Js_null_undefined.fromOption(backgroundColor),
          scale: Js_null_undefined.fromOption(scale)
        };
}

function saveSvgAsPng(element, filename, options) {
  SaveSvgAsPng.saveSvgAsPng(element, filename, options);
  
}

function saveSvg(element, filename, options) {
  SaveSvgAsPng.saveSvg(element, filename, options);
  
}

exports.options = options;
exports.saveSvgAsPng = saveSvgAsPng;
exports.saveSvg = saveSvg;
/* save-svg-as-png Not a pure module */
