// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

var ppx_printed_query = "mutation UpdateExperiment($experimentId: ID!, $instrument: Instrument)  {\nupdateExperiment(id: $experimentId, instrument: $instrument)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateExperiment: tmp
        };
}

function json_of_Instrument(value) {
  if (value >= 615611296) {
    if (value >= 947968673) {
      return "FLOW_CYTOMETRY";
    } else {
      return "AURORA";
    }
  } else if (value >= 498013768) {
    return "CITESEQ";
  } else {
    return "MASS_CYTOMETRY";
  }
}

function make(experimentId, instrument, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "instrument",
                    instrument !== undefined ? json_of_Instrument(instrument) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var instrument = variables.instrument;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "instrument",
                    instrument !== undefined ? json_of_Instrument(instrument) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, instrument, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "instrument",
                  instrument !== undefined ? json_of_Instrument(instrument) : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, instrument, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "instrument",
                      instrument !== undefined ? json_of_Instrument(instrument) : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var UpdateInstrumentConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_Instrument: json_of_Instrument,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UpdateInstrumentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function InstrumentSelect(Props) {
  var experimentId = Props.experimentId;
  var experimentInstrument = Props.experimentInstrument;
  return React.createElement(UpdateInstrumentMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement("select", {
                              className: "form-control form-control-sm col-6",
                              value: Experiment$Astrolabe.Instrument.toString(experimentInstrument),
                              onChange: (function ($$event) {
                                  var instrument = Experiment$Astrolabe.Instrument.fromDisplayString(ReasonReactHelpers$Astrolabe.valueFromEvent($$event));
                                  return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(experimentId, instrument, undefined).variables), ["getExperiment"], undefined, undefined));
                                })
                            }, Experiment$Astrolabe.Instrument.all.map((function (instrument, index) {
                                    return React.createElement("option", {
                                                key: String(index) + Experiment$Astrolabe.Instrument.toString(instrument),
                                                value: Experiment$Astrolabe.Instrument.toString(instrument)
                                              }, Experiment$Astrolabe.Instrument.toString(instrument));
                                  })));
                })
            });
}

var Instrument;

var make$1 = InstrumentSelect;

exports.UpdateInstrumentConfig = UpdateInstrumentConfig;
exports.UpdateInstrumentMutation = UpdateInstrumentMutation;
exports.Instrument = Instrument;
exports.make = make$1;
/* UpdateInstrumentMutation Not a pure module */
