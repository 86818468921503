// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Webapi__Dom__Element = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Element.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function AddFilesButton(Props) {
  var handleUpload = Props.handleUpload;
  var name = Props.name;
  var fileInputRef = React.useRef(null);
  var handleUploadClick = function (param) {
    Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(ReasonReactHelpers$Astrolabe.getCurrentRef(fileInputRef)), Webapi__Dom__Element.asHtmlElement), (function (prim) {
            prim.click();
            
          }));
    
  };
  return React.createElement("div", undefined, React.createElement("input", {
                  key: String(Util$Astrolabe.$$Math.randomNumber(undefined)),
                  ref: fileInputRef,
                  className: "d-none",
                  multiple: true,
                  name: Curry._1(Util$Astrolabe.$$String.lowercase, name),
                  required: true,
                  type: "file",
                  onChange: (function (evt) {
                      return Curry._1(handleUpload, ReasonReactHelpers$Astrolabe.filesFromEvent(evt));
                    })
                }), React.createElement("button", {
                  className: "btn btn-info btn-block",
                  type: "button",
                  onClick: handleUploadClick
                }, "Add " + name));
}

var make = AddFilesButton;

exports.make = make;
/* react Not a pure module */
