// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var User$Astrolabe = require("../../models/User.bs.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var UserExperimentRow$Astrolabe = require("./UserExperimentRow.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function sortUsers(users) {
  return users.sort((function (userA, userB) {
                return Util$Astrolabe.Compare.byEmailByUser(User$Astrolabe.email(userA), User$Astrolabe.email(userB));
              }));
}

function filterAndSortUsers(assignedUsers, unassignedUsers, filterEmail) {
  var assignedUsers$1 = sortUsers(Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(filterEmail, User$Astrolabe.email, assignedUsers));
  var unassignedUsers$1 = sortUsers(Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(filterEmail, User$Astrolabe.email, unassignedUsers));
  return /* tuple */[
          assignedUsers$1,
          unassignedUsers$1
        ];
}

function reducer(assignedUsers, unassignedUsers, state, action) {
  if (action.tag) {
    var match = filterAndSortUsers(action[0], action[1], state.filterEmail);
    return {
            assignedUsers: match[0],
            filterEmail: state.filterEmail,
            unassignedUsers: match[1]
          };
  }
  var filterEmail = action[0];
  var match$1 = filterAndSortUsers(assignedUsers, unassignedUsers, filterEmail);
  return {
          assignedUsers: match$1[0],
          filterEmail: filterEmail,
          unassignedUsers: match$1[1]
        };
}

function UserExperiments(Props) {
  var assignedUsers = Props.assignedUsers;
  var creatorEmail = Props.creatorEmail;
  var experimentId = Props.experimentId;
  var onClose = Props.onClose;
  var unassignedUsers = Props.unassignedUsers;
  var initialState = {
    assignedUsers: assignedUsers,
    filterEmail: "",
    unassignedUsers: unassignedUsers
  };
  var match = React.useReducer((function (param, param$1) {
          return reducer(assignedUsers, unassignedUsers, param, param$1);
        }), initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* SetUsers */Block.__(1, [
                  assignedUsers,
                  unassignedUsers
                ]));
          
        }), /* tuple */[
        assignedUsers,
        unassignedUsers
      ]);
  var userDivHeight = function (length) {
    if (length < 4) {
      return "30px";
    } else if (length < 7) {
      return "60px";
    } else if (length < 10) {
      return "100px";
    } else {
      return "110px";
    }
  };
  var listUsers = function (isSelected, users) {
    if (Util$Astrolabe.$$Array.isEmpty(users)) {
      if (Util$Astrolabe.$$String.isEmpty(state.filterEmail)) {
        return React.createElement("p", undefined, isSelected ? "No users have access" : "All users have access");
      } else {
        return React.createElement("p", undefined, isSelected ? "No filtered users have access" : "All filtered users have access");
      }
    } else {
      return React.createElement(React.Fragment, undefined, React.createElement("p", {
                      className: "cursor-help",
                      title: isSelected ? "Deselect a user to remove her or his access to the experiment." : "Select a user to allow her or him to access the experiment."
                    }, isSelected ? "Users With Access" : "Users Without Access"), React.createElement("div", {
                      className: "row ml-1 vertical-scroll overflow-y-scroll",
                      style: {
                        maxHeight: userDivHeight(users.length)
                      }
                    }, users.map((function (user, index) {
                            return React.createElement("div", {
                                        key: User$Astrolabe.email(user) + String(index),
                                        className: "col-4"
                                      }, React.createElement(UserExperimentRow$Astrolabe.make, {
                                            experimentId: experimentId,
                                            userEmail: User$Astrolabe.email(user),
                                            userId: User$Astrolabe.id(user),
                                            isUserAssigned: isSelected
                                          }));
                          }))));
    }
  };
  var listAssignedUsers = listUsers(true, state.assignedUsers);
  var listUnassignedUsers = listUsers(false, state.unassignedUsers);
  return React.createElement("div", {
              className: "p-3"
            }, React.createElement("input", {
                  className: "form-control form-control-sm mr-2",
                  placeholder: "Filter Users",
                  type: "text",
                  value: state.filterEmail,
                  onChange: (function (evt) {
                      return Curry._1(dispatch, /* FilterUserEmail */Block.__(0, [ReasonReactHelpers$Astrolabe.valueFromEvent(evt)]));
                    })
                }), React.createElement("hr", undefined), React.createElement("p", undefined, "Creator: " + creatorEmail), React.createElement("hr", undefined), listAssignedUsers, React.createElement("hr", undefined), listUnassignedUsers, React.createElement("hr", undefined), React.createElement("div", {
                  className: "col-12"
                }, React.createElement("div", {
                      className: "row justify-content-end"
                    }, React.createElement("button", {
                          className: "btn btn-primary",
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            })
                        }, "Done"))));
}

var make = UserExperiments;

exports.sortUsers = sortUsers;
exports.filterAndSortUsers = filterAndSortUsers;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
