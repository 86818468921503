// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function id(self) {
  return self.id;
}

function name(self) {
  return self.name;
}

exports.id = id;
exports.name = name;
/* No side effect */
