/* @flow */

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/* If this is updated, also update Sample.scrub_filename (in sample.rb). */
export function scrubFilename(filename: string): string {
  return filename.replace(/[^\w\d_\-\.\ ]/gi, "_");
}
