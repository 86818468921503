// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");

var ppx_printed_query = "mutation CreateFeature($experimentId: ID!, $name: String!, $isAllowNa: Boolean, $featureValues: [String!]!)  {\ncreateFeature(experimentId: $experimentId, name: $name, isAllowNa: $isAllowNa, featureValues: $featureValues)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createFeature");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type FeaturePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createFeature: tmp
        };
}

function make(experimentId, name, isAllowNa, featureValues, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "isAllowNa",
                    isAllowNa !== undefined ? isAllowNa : null
                  ],
                  /* tuple */[
                    "featureValues",
                    featureValues.map((function (prim) {
                            return prim;
                          }))
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var name = variables.name;
  var isAllowNa = variables.isAllowNa;
  var featureValues = variables.featureValues;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "isAllowNa",
                    isAllowNa !== undefined ? isAllowNa : null
                  ],
                  /* tuple */[
                    "featureValues",
                    featureValues.map((function (prim) {
                            return prim;
                          }))
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, name, isAllowNa, featureValues, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "name",
                  name
                ],
                /* tuple */[
                  "isAllowNa",
                  isAllowNa !== undefined ? isAllowNa : null
                ],
                /* tuple */[
                  "featureValues",
                  featureValues.map((function (prim) {
                          return prim;
                        }))
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, name, isAllowNa, featureValues, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "name",
                      name
                    ],
                    /* tuple */[
                      "isAllowNa",
                      isAllowNa !== undefined ? isAllowNa : null
                    ],
                    /* tuple */[
                      "featureValues",
                      featureValues.map((function (prim) {
                              return prim;
                            }))
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var CreateFeatureConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var CreateFeatureMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

var ppx_printed_query$1 = "mutation DestroyFeature($id: ID!)  {\ndestroyFeature(id: $id)  {\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyFeature");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type FeaturePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyFeature: tmp
        };
}

function make$1(id, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var id = variables.id;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(id, param) {
  return Js_dict.fromArray([/* tuple */[
                  "id",
                  id
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, id, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "id",
                      id
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var DestroyFeatureConfig = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var DestroyFeatureMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

var ppx_printed_query$2 = "mutation UpdateFeature($id: ID!, $name: String, $isAllowNa: Boolean, $featureValues: [String!])  {\nupdateFeature(id: $id, name: $name, isAllowNa: $isAllowNa, featureValues: $featureValues)  {\nerrors  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateFeature");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type FeaturePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateFeature: tmp
        };
}

function make$2(id, name, isAllowNa, featureValues, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ],
                  /* tuple */[
                    "isAllowNa",
                    isAllowNa !== undefined ? isAllowNa : null
                  ],
                  /* tuple */[
                    "featureValues",
                    featureValues !== undefined ? featureValues.map((function (prim) {
                              return prim;
                            })) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var id = variables.id;
  var name = variables.name;
  var isAllowNa = variables.isAllowNa;
  var featureValues = variables.featureValues;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ],
                  /* tuple */[
                    "isAllowNa",
                    isAllowNa !== undefined ? isAllowNa : null
                  ],
                  /* tuple */[
                    "featureValues",
                    featureValues !== undefined ? featureValues.map((function (prim) {
                              return prim;
                            })) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$2
        };
}

function makeVariables$2(id, name, isAllowNa, featureValues, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "name",
                  name !== undefined ? name : null
                ],
                /* tuple */[
                  "isAllowNa",
                  isAllowNa !== undefined ? isAllowNa : null
                ],
                /* tuple */[
                  "featureValues",
                  featureValues !== undefined ? featureValues.map((function (prim) {
                            return prim;
                          })) : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$2(graphql_ppx_use_json_variables_fn, id, name, isAllowNa, featureValues, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "name",
                      name !== undefined ? name : null
                    ],
                    /* tuple */[
                      "isAllowNa",
                      isAllowNa !== undefined ? isAllowNa : null
                    ],
                    /* tuple */[
                      "featureValues",
                      featureValues !== undefined ? featureValues.map((function (prim) {
                                return prim;
                              })) : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$2 = /* tuple */[
  parse$2,
  ppx_printed_query$2,
  definition_002$2
];

function ret_type$2(f) {
  return { };
}

var MT_Ret$2 = { };

var UpdateFeatureConfig = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var UpdateFeatureMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$2,
      parse: parse$2
    });

var ppx_printed_query$3 = "mutation CreateSampleFeatureValue($featureId: ID!, $sampleId: ID!, $value: String!)  {\ncreateSampleFeatureValue(featureId: $featureId, sampleId: $sampleId, value: $value)  {\nerrors  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createSampleFeatureValue");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type SampleFeatureValuePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createSampleFeatureValue: tmp
        };
}

function make$3(featureId, sampleId, value, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "featureId",
                    featureId
                  ],
                  /* tuple */[
                    "sampleId",
                    sampleId
                  ],
                  /* tuple */[
                    "value",
                    value
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var featureId = variables.featureId;
  var sampleId = variables.sampleId;
  var value = variables.value;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "featureId",
                    featureId
                  ],
                  /* tuple */[
                    "sampleId",
                    sampleId
                  ],
                  /* tuple */[
                    "value",
                    value
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$3
        };
}

function makeVariables$3(featureId, sampleId, value, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "featureId",
                  featureId
                ],
                /* tuple */[
                  "sampleId",
                  sampleId
                ],
                /* tuple */[
                  "value",
                  value
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$3(graphql_ppx_use_json_variables_fn, featureId, sampleId, value, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "featureId",
                      featureId
                    ],
                    /* tuple */[
                      "sampleId",
                      sampleId
                    ],
                    /* tuple */[
                      "value",
                      value
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$3 = /* tuple */[
  parse$3,
  ppx_printed_query$3,
  definition_002$3
];

function ret_type$3(f) {
  return { };
}

var MT_Ret$3 = { };

var CreateSampleFeatureValueConfig = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var CreateSampleFeatureValueMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$3,
      parse: parse$3
    });

var ppx_printed_query$4 = "mutation UpdateSampleFeatureValue($id: ID!, $value: String!)  {\nupdateSampleFeatureValue(id: $id, value: $value)  {\nerrors  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateSampleFeatureValue");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type SampleFeatureValuePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateSampleFeatureValue: tmp
        };
}

function make$4(id, value, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "value",
                    value
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var id = variables.id;
  var value = variables.value;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "value",
                    value
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$4
        };
}

function makeVariables$4(id, value, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "value",
                  value
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$4(graphql_ppx_use_json_variables_fn, id, value, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "value",
                      value
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$4 = /* tuple */[
  parse$4,
  ppx_printed_query$4,
  definition_002$4
];

function ret_type$4(f) {
  return { };
}

var MT_Ret$4 = { };

var UpdateSampleFeatureValueConfig = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

var UpdateSampleFeatureValueMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$4,
      parse: parse$4
    });

exports.CreateFeatureConfig = CreateFeatureConfig;
exports.CreateFeatureMutation = CreateFeatureMutation;
exports.DestroyFeatureConfig = DestroyFeatureConfig;
exports.DestroyFeatureMutation = DestroyFeatureMutation;
exports.UpdateFeatureConfig = UpdateFeatureConfig;
exports.UpdateFeatureMutation = UpdateFeatureMutation;
exports.CreateSampleFeatureValueConfig = CreateSampleFeatureValueConfig;
exports.CreateSampleFeatureValueMutation = CreateSampleFeatureValueMutation;
exports.UpdateSampleFeatureValueConfig = UpdateSampleFeatureValueConfig;
exports.UpdateSampleFeatureValueMutation = UpdateSampleFeatureValueMutation;
/* CreateFeatureMutation Not a pure module */
