/* @flow */

import React from "react";
import ReactDOM from "react-dom";

type Props = {
  children?: React$Node,
  documentTitle?: string,
  title?: string,
};

export const NAV_TITLE_ROOT_ELEMENT_ID = "nav-title";

export default class NavTitle extends React.Component<Props> {
  el: HTMLDivElement;

  static TextAndDocumentTitle({ title, documentTitle }: { title: string, documentTitle?: string }) {
    let docTitle = `${documentTitle || title} | Astrolabe`;
    return <NavTitle documentTitle={docTitle} title={title} />;
  }

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
  }

  withRootElement(callback: (HTMLElement) => any) {
    const root = document.getElementById(NAV_TITLE_ROOT_ELEMENT_ID);

    if (root) {
      callback(root);
    }
  }

  componentDidMount() {
    this.withRootElement((root) => root.appendChild(this.el));

    if (this.props.documentTitle) {
      document.title = `${this.props.documentTitle}`;
    }
  }

  componentWillUnmount() {
    this.withRootElement((root) => {
      try {
        root.removeChild(this.el);
      } catch (e) {
        // `removeChild` will throw if this.el isn't a child of root or if this.el doesn't exist on the page.
        // In either case, there's no cleanup to do, so we can ignore the error.
      }
    });
  }

  render() {
    return ReactDOM.createPortal(this.props.children || <span className="navbar-text">{this.props.title}</span>, this.el);
  }
}

export const TextAndDocumentTitle = NavTitle.TextAndDocumentTitle;
