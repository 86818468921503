// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Map = require("bs-platform/lib/js/map.js");
var $$Set = require("bs-platform/lib/js/set.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Bytes = require("bs-platform/lib/js/bytes.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Format = require("bs-platform/lib/js/format.js");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Printexc = require("bs-platform/lib/js/printexc.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Immutable = require("immutable");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_string = require("bs-platform/lib/js/caml_string.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

Printexc.record_backtrace(true);

function tToJs(param) {
  return {
          value: param.value,
          label: param.label
        };
}

function tFromJs(param) {
  return {
          value: param.value,
          label: param.label
        };
}

var HtmlOption = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toJs: tToJs,
  fromJs: tFromJs
};

function andThen(prim, prim$1) {
  return prim$1.then(Curry.__1(prim));
}

function map(f, self) {
  return self.then((function (x) {
                return Promise.resolve(Curry._1(f, x));
              }));
}

function tap(f, self) {
  return self.then((function (x) {
                Curry._1(f, x);
                return Promise.resolve(x);
              }));
}

function get(f, self) {
  self.then((function (x) {
          Curry._1(f, x);
          return Promise.resolve(undefined);
        }));
  
}

function ignore(self) {
  
}

var $$Promise$1 = {
  andThen: andThen,
  map: map,
  tap: tap,
  get: get,
  ignore: ignore
};

var compare = Caml_primitive.caml_int_compare;

var T = {
  compare: compare
};

var $$Map$1 = $$Map.Make(T);

var $$Set$1 = $$Set.Make(T);

var Int = {
  fromString: Belt_Int.fromString,
  T: T,
  $$Map: $$Map$1,
  $$Set: $$Set$1
};

function fromInt(prim) {
  return prim;
}

function toInt(prim) {
  return prim | 0;
}

function $star(prim, prim$1) {
  return prim * prim$1;
}

function $slash(prim, prim$1) {
  return prim / prim$1;
}

function $plus(prim, prim$1) {
  return prim + prim$1;
}

function $neg(prim, prim$1) {
  return prim - prim$1;
}

var Float = {
  fromInt: fromInt,
  toInt: toInt,
  $star: $star,
  $slash: $slash,
  $plus: $plus,
  $neg: $neg
};

function or(opt, $$default) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  } else {
    return $$default;
  }
}

function force(self) {
  if (self !== undefined) {
    return Caml_option.valFromOption(self);
  } else {
    return Pervasives.invalid_arg("Option.force: None");
  }
}

function orFail(msg, self) {
  if (self !== undefined) {
    return Caml_option.valFromOption(self);
  } else {
    return Pervasives.invalid_arg(msg);
  }
}

function if_some(opt, fn) {
  if (opt !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(opt));
  }
  
}

function if_none(opt, fn) {
  if (opt !== undefined) {
    return ;
  } else {
    return Curry._1(fn, undefined);
  }
}

function fromNullable(nullable) {
  if (nullable == null) {
    return ;
  } else {
    return Caml_option.some(nullable);
  }
}

var toNullable = Js_null_undefined.fromOption;

function andThen$1(f, opt) {
  if (opt !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(opt));
  }
  
}

var $$Option = {
  forEachU: Belt_Option.forEachU,
  forEach: Belt_Option.forEach,
  getExn: Belt_Option.getExn,
  mapWithDefaultU: Belt_Option.mapWithDefaultU,
  mapWithDefault: Belt_Option.mapWithDefault,
  mapU: Belt_Option.mapU,
  map: Belt_Option.map,
  flatMapU: Belt_Option.flatMapU,
  flatMap: Belt_Option.flatMap,
  getWithDefault: Belt_Option.getWithDefault,
  isSome: Belt_Option.isSome,
  isNone: Belt_Option.isNone,
  eqU: Belt_Option.eqU,
  eq: Belt_Option.eq,
  cmpU: Belt_Option.cmpU,
  cmp: Belt_Option.cmp,
  or: or,
  force: force,
  orFail: orFail,
  if_some: if_some,
  if_none: if_none,
  fromNullable: fromNullable,
  toNullable: toNullable,
  andThen: andThen$1
};

function factorial(n) {
  if (n <= 0) {
    return 1;
  } else {
    return Caml_int32.imul(n, factorial(n - 1 | 0));
  }
}

function combinations(n, k) {
  return Caml_int32.div(factorial(n), Caml_int32.imul(factorial(k), factorial(n - k | 0)));
}

function randomNumber(param) {
  return Js_math.ceil(Math.random() * 100000000.0);
}

var $$Math$1 = {
  unsafe_ceil: Js_math.unsafe_ceil,
  ceil_int: Js_math.ceil_int,
  ceil: Js_math.ceil,
  unsafe_floor: Js_math.unsafe_floor,
  floor_int: Js_math.floor_int,
  floor: Js_math.floor,
  random_int: Js_math.random_int,
  factorial: factorial,
  combinations: combinations,
  randomNumber: randomNumber
};

function equal(prim, prim$1) {
  return prim === prim$1;
}

function isEmpty(str) {
  return str.length === 0;
}

function toIdentifier(str) {
  var charIsId = function (x) {
    var switcher = x - 48 | 0;
    if (switcher > 42 || switcher < 0) {
      return !(switcher > 74 || switcher < 49);
    } else {
      return switcher > 16 || switcher < 10;
    }
  };
  var out = $$String.map((function (x) {
          if (charIsId(x)) {
            return x;
          } else {
            return /* "_" */95;
          }
        }), str);
  if (charIsId(Caml_string.get(str, 0))) {
    return out;
  }
  var out$1 = Bytes.of_string(out);
  out$1[0] = /* "_" */95;
  return Bytes.to_string(out$1);
}

var Base = $$Map.Make({
      compare: $$String.compare
    });

function add_to_list(key, value, self) {
  var values;
  var exit = 0;
  var values$1;
  try {
    values$1 = Curry._2(Base.find, key, self);
    exit = 1;
  }
  catch (exn){
    if (exn === Caml_builtin_exceptions.not_found) {
      values = /* :: */[
        value,
        /* [] */0
      ];
    } else {
      throw exn;
    }
  }
  if (exit === 1) {
    values = /* :: */[
      value,
      values$1
    ];
  }
  return Curry._3(Base.add, key, values, self);
}

function find_or(key, $$default, self) {
  try {
    return Curry._2(Base.find, key, self);
  }
  catch (exn){
    if (exn === Caml_builtin_exceptions.not_found) {
      return $$default;
    }
    throw exn;
  }
}

var Map_empty = Base.empty;

var Map_is_empty = Base.is_empty;

var Map_mem = Base.mem;

var Map_add = Base.add;

var Map_update = Base.update;

var Map_singleton = Base.singleton;

var Map_remove = Base.remove;

var Map_merge = Base.merge;

var Map_union = Base.union;

var Map_compare = Base.compare;

var Map_equal = Base.equal;

var Map_iter = Base.iter;

var Map_fold = Base.fold;

var Map_for_all = Base.for_all;

var Map_exists = Base.exists;

var Map_filter = Base.filter;

var Map_partition = Base.partition;

var Map_cardinal = Base.cardinal;

var Map_bindings = Base.bindings;

var Map_min_binding = Base.min_binding;

var Map_min_binding_opt = Base.min_binding_opt;

var Map_max_binding = Base.max_binding;

var Map_max_binding_opt = Base.max_binding_opt;

var Map_choose = Base.choose;

var Map_choose_opt = Base.choose_opt;

var Map_split = Base.split;

var Map_find = Base.find;

var Map_find_opt = Base.find_opt;

var Map_find_first = Base.find_first;

var Map_find_first_opt = Base.find_first_opt;

var Map_find_last = Base.find_last;

var Map_find_last_opt = Base.find_last_opt;

var Map_map = Base.map;

var Map_mapi = Base.mapi;

var $$Map$2 = {
  Base: Base,
  add_to_list: add_to_list,
  find_or: find_or,
  empty: Map_empty,
  is_empty: Map_is_empty,
  mem: Map_mem,
  add: Map_add,
  update: Map_update,
  singleton: Map_singleton,
  remove: Map_remove,
  merge: Map_merge,
  union: Map_union,
  compare: Map_compare,
  equal: Map_equal,
  iter: Map_iter,
  fold: Map_fold,
  for_all: Map_for_all,
  exists: Map_exists,
  filter: Map_filter,
  partition: Map_partition,
  cardinal: Map_cardinal,
  bindings: Map_bindings,
  min_binding: Map_min_binding,
  min_binding_opt: Map_min_binding_opt,
  max_binding: Map_max_binding,
  max_binding_opt: Map_max_binding_opt,
  choose: Map_choose,
  choose_opt: Map_choose_opt,
  split: Map_split,
  find: Map_find,
  find_opt: Map_find_opt,
  find_first: Map_find_first,
  find_first_opt: Map_find_first_opt,
  find_last: Map_find_last,
  find_last_opt: Map_find_last_opt,
  map: Map_map,
  mapi: Map_mapi
};

var Base$1 = $$Set.Make({
      compare: $$String.compare
    });

function contains(target, set) {
  return Curry._2(Base$1.exists, (function (param) {
                return target === param;
              }), set);
}

function to_list(set) {
  return Curry._1(Base$1.elements, set);
}

function to_array(set) {
  return $$Array.of_list(Curry._1(Base$1.elements, set));
}

function of_array(arr) {
  return Curry._1(Base$1.of_list, $$Array.to_list(arr));
}

function fromImmutableJsOrderedSet(imjs) {
  var arr = imjs.toArray();
  return Curry._1(Base$1.of_list, $$Array.to_list(arr));
}

function toggle(el, set) {
  if (Curry._2(Base$1.exists, (function (param) {
            return el === param;
          }), set)) {
    return Curry._2(Base$1.remove, el, set);
  } else {
    return Curry._2(Base$1.add, el, set);
  }
}

var Set_length = Base$1.cardinal;

var Set_empty = Base$1.empty;

var Set_is_empty = Base$1.is_empty;

var Set_mem = Base$1.mem;

var Set_add = Base$1.add;

var Set_singleton = Base$1.singleton;

var Set_remove = Base$1.remove;

var Set_union = Base$1.union;

var Set_inter = Base$1.inter;

var Set_diff = Base$1.diff;

var Set_compare = Base$1.compare;

var Set_equal = Base$1.equal;

var Set_subset = Base$1.subset;

var Set_iter = Base$1.iter;

var Set_map = Base$1.map;

var Set_fold = Base$1.fold;

var Set_for_all = Base$1.for_all;

var Set_exists = Base$1.exists;

var Set_filter = Base$1.filter;

var Set_partition = Base$1.partition;

var Set_cardinal = Base$1.cardinal;

var Set_elements = Base$1.elements;

var Set_min_elt = Base$1.min_elt;

var Set_min_elt_opt = Base$1.min_elt_opt;

var Set_max_elt = Base$1.max_elt;

var Set_max_elt_opt = Base$1.max_elt_opt;

var Set_choose = Base$1.choose;

var Set_choose_opt = Base$1.choose_opt;

var Set_split = Base$1.split;

var Set_find = Base$1.find;

var Set_find_opt = Base$1.find_opt;

var Set_find_first = Base$1.find_first;

var Set_find_first_opt = Base$1.find_first_opt;

var Set_find_last = Base$1.find_last;

var Set_find_last_opt = Base$1.find_last_opt;

var Set_of_list = Base$1.of_list;

var $$Set$2 = {
  Base: Base$1,
  contains: contains,
  to_list: to_list,
  to_array: to_array,
  of_array: of_array,
  fromImmutableJsOrderedSet: fromImmutableJsOrderedSet,
  length: Set_length,
  empty: Set_empty,
  is_empty: Set_is_empty,
  mem: Set_mem,
  add: Set_add,
  singleton: Set_singleton,
  remove: Set_remove,
  union: Set_union,
  inter: Set_inter,
  diff: Set_diff,
  compare: Set_compare,
  equal: Set_equal,
  subset: Set_subset,
  iter: Set_iter,
  map: Set_map,
  fold: Set_fold,
  for_all: Set_for_all,
  exists: Set_exists,
  filter: Set_filter,
  partition: Set_partition,
  cardinal: Set_cardinal,
  elements: Set_elements,
  min_elt: Set_min_elt,
  min_elt_opt: Set_min_elt_opt,
  max_elt: Set_max_elt,
  max_elt_opt: Set_max_elt_opt,
  choose: Set_choose,
  choose_opt: Set_choose_opt,
  split: Set_split,
  find: Set_find,
  find_opt: Set_find_opt,
  find_first: Set_find_first,
  find_first_opt: Set_find_first_opt,
  find_last: Set_find_last,
  find_last_opt: Set_find_last_opt,
  of_list: Set_of_list,
  toggle: toggle
};

function pluralizeCustom(num, word, plural) {
  return String(num) + (" " + (
            num !== 1 ? plural : word
          ));
}

function pluralize(num, word) {
  return pluralizeCustom(num, word, word + "s");
}

function truncate(str, len, truncatorOpt, param) {
  var truncator = truncatorOpt !== undefined ? truncatorOpt : "…";
  if (str.length <= len) {
    return str;
  } else {
    return str.substring(0, len - truncator.length | 0) + truncator;
  }
}

var $$String$1 = {
  Std: undefined,
  equal: equal,
  capitalize: $$String.capitalize_ascii,
  lowercase: $$String.lowercase_ascii,
  isEmpty: isEmpty,
  toIdentifier: toIdentifier,
  $$Map: $$Map$2,
  $$Set: $$Set$2,
  pluralizeCustom: pluralizeCustom,
  pluralize: pluralize,
  truncate: truncate
};

function isEmpty$1(arr) {
  return arr.length === 0;
}

function removeAt(position, arr) {
  if (position < 0) {
    throw [
          Caml_builtin_exceptions.invalid_argument,
          "Negative position"
        ];
  }
  if (position > arr.length || arr.length === 0) {
    return arr;
  } else {
    return $$Array.init(arr.length - 1 | 0, (function (i) {
                  if (i >= position) {
                    return Caml_array.caml_array_get(arr, i + 1 | 0);
                  } else {
                    return Caml_array.caml_array_get(arr, i);
                  }
                }));
  }
}

function reverse(arr) {
  return arr.slice().reverse();
}

function removeAt_test(param) {
  if (!Caml_obj.caml_equal(removeAt(0, []), [])) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Util.re",
            253,
            4
          ]
        ];
  }
  if (Caml_obj.caml_equal(removeAt(1, [
              1,
              2,
              3
            ]), [
          1,
          3
        ])) {
    return ;
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "Util.re",
          254,
          4
        ]
      ];
}

function last(arr) {
  if (arr.length === 0) {
    return ;
  } else {
    return Caml_option.some(Caml_array.caml_array_get(arr, arr.length - 1 | 0));
  }
}

function moveElementByIndex(elementIndex, delta, arr) {
  var element = Caml_array.caml_array_get(arr, elementIndex);
  var arr$1 = removeAt(elementIndex, arr);
  arr$1.splice(elementIndex + delta | 0, 0, element);
  return arr$1;
}

function reduceWithIndexU(a, x, f) {
  var r = x;
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    r = f(r, a[i], i);
  }
  return r;
}

function reduceWithIndex(a, x, f) {
  return reduceWithIndexU(a, x, Curry.__3(f));
}

function getOrFail(i, self) {
  return Caml_array.caml_array_get(self, i);
}

function max(self) {
  return $$Array.fold_left((function (acc, x) {
                if (acc !== undefined && !Caml_obj.caml_greaterthan(x, Caml_option.valFromOption(acc))) {
                  return acc;
                } else {
                  return Caml_option.some(x);
                }
              }), undefined, self);
}

function min(self) {
  return $$Array.fold_left((function (acc, x) {
                if (acc !== undefined && !Caml_obj.caml_lessthan(x, Caml_option.valFromOption(acc))) {
                  return acc;
                } else {
                  return Caml_option.some(x);
                }
              }), undefined, self);
}

function chunk(array, chunks) {
  var chunkSize = Js_math.ceil(array.length / chunks);
  return reduceWithIndex(array, [[]], (function (res, v, i) {
                var newRes = Caml_int32.mod_(i, chunkSize) === 0 && i !== 0 ? $$Array.concat(/* :: */[
                        res,
                        /* :: */[
                          [[]],
                          /* [] */0
                        ]
                      ]) : res;
                Caml_array.caml_array_set(newRes, newRes.length - 1 | 0, $$Array.concat(/* :: */[
                          Caml_array.caml_array_get(newRes, newRes.length - 1 | 0),
                          /* :: */[
                            [v],
                            /* [] */0
                          ]
                        ]));
                return newRes;
              }));
}

function without(b, a) {
  return a.filter((function (elem) {
                return !b.includes(elem);
              }));
}

function withoutBy(b, map, a) {
  var b$prime = $$Array.map(map, b);
  return a.filter((function (elem) {
                return !b$prime.includes(Curry._1(map, elem));
              }));
}

function powerSet(a) {
  return $$Array.map(Belt_Array.reverse, $$Array.fold_left((function (subsets, value) {
                    return $$Array.concat(/* :: */[
                                subsets,
                                /* :: */[
                                  $$Array.map((function (set) {
                                          return $$Array.concat(/* :: */[
                                                      [value],
                                                      /* :: */[
                                                        set,
                                                        /* [] */0
                                                      ]
                                                    ]);
                                        }), subsets),
                                  /* [] */0
                                ]
                              ]);
                  }), [[]], a));
}

function product(list) {
  return $$Array.fold_right((function (xs, yss) {
                return $$Array.concat($$Array.to_list($$Array.map((function (x) {
                                      return $$Array.map((function (ys) {
                                                    return $$Array.concat(/* :: */[
                                                                [x],
                                                                /* :: */[
                                                                  ys,
                                                                  /* [] */0
                                                                ]
                                                              ]);
                                                  }), yss);
                                    }), xs)));
              }), list, [[]]);
}

function fromImmutableJsList(imjs) {
  return imjs.toArray();
}

function toImmutableJsList(arr) {
  return Immutable.List(arr);
}

function filterWithGetterCaseInsensitive(filterText, getter, array) {
  var filterText$1 = filterText.toUpperCase();
  return array.filter((function (a) {
                return Curry._1(getter, a).toUpperCase().includes(filterText$1);
              }));
}

function float_range(startOpt, stepOpt, stop) {
  var start = startOpt !== undefined ? startOpt : 0.0;
  var step = stepOpt !== undefined ? stepOpt : 1.0;
  var loop = function (_acc, _n) {
    while(true) {
      var n = _n;
      var acc = _acc;
      if (Caml_obj.caml_greaterequal(acc, stop)) {
        return acc;
      }
      _n = n + step;
      _acc = /* :: */[
        n,
        acc
      ];
      continue ;
    };
  };
  var acc = loop(/* [] */0, start);
  return $$Array.of_list(List.rev(acc));
}

var generate = $$Array.init;

var $$Array$1 = {
  isEmpty: isEmpty$1,
  removeAt: removeAt,
  reverse: reverse,
  removeAt_test: removeAt_test,
  last: last,
  moveElementByIndex: moveElementByIndex,
  reduceWithIndexU: reduceWithIndexU,
  reduceWithIndex: reduceWithIndex,
  getOrFail: getOrFail,
  max: max,
  min: min,
  chunk: chunk,
  without: without,
  withoutBy: withoutBy,
  powerSet: powerSet,
  product: product,
  fromImmutableJsList: fromImmutableJsList,
  toImmutableJsList: toImmutableJsList,
  fromList: $$Array.of_list,
  toList: $$Array.to_list,
  filterWithGetterCaseInsensitive: filterWithGetterCaseInsensitive,
  float_range: float_range,
  generate: generate,
  mapIndexed: $$Array.mapi
};

function product$1(list) {
  return List.fold_right((function (xs, yss) {
                return List.concat(List.map((function (x) {
                                  return List.map((function (ys) {
                                                return /* :: */[
                                                        x,
                                                        ys
                                                      ];
                                              }), yss);
                                }), xs));
              }), list, /* :: */[
              /* [] */0,
              /* [] */0
            ]);
}

function fromImmutableJsList$1(imjs) {
  return $$Array.to_list(imjs.toArray());
}

var List$1 = {
  Std: undefined,
  length: Belt_List.length,
  size: Belt_List.size,
  head: Belt_List.head,
  headExn: Belt_List.headExn,
  tail: Belt_List.tail,
  tailExn: Belt_List.tailExn,
  add: Belt_List.add,
  get: Belt_List.get,
  getExn: Belt_List.getExn,
  make: Belt_List.make,
  makeByU: Belt_List.makeByU,
  makeBy: Belt_List.makeBy,
  shuffle: Belt_List.shuffle,
  drop: Belt_List.drop,
  take: Belt_List.take,
  splitAt: Belt_List.splitAt,
  concat: Belt_List.concat,
  concatMany: Belt_List.concatMany,
  reverseConcat: Belt_List.reverseConcat,
  flatten: Belt_List.flatten,
  mapU: Belt_List.mapU,
  map: Belt_List.map,
  zip: Belt_List.zip,
  zipByU: Belt_List.zipByU,
  zipBy: Belt_List.zipBy,
  mapWithIndexU: Belt_List.mapWithIndexU,
  mapWithIndex: Belt_List.mapWithIndex,
  fromArray: Belt_List.fromArray,
  toArray: Belt_List.toArray,
  reverse: Belt_List.reverse,
  mapReverseU: Belt_List.mapReverseU,
  mapReverse: Belt_List.mapReverse,
  forEachU: Belt_List.forEachU,
  forEach: Belt_List.forEach,
  forEachWithIndexU: Belt_List.forEachWithIndexU,
  forEachWithIndex: Belt_List.forEachWithIndex,
  reduceU: Belt_List.reduceU,
  reduce: Belt_List.reduce,
  reduceWithIndexU: Belt_List.reduceWithIndexU,
  reduceWithIndex: Belt_List.reduceWithIndex,
  reduceReverseU: Belt_List.reduceReverseU,
  reduceReverse: Belt_List.reduceReverse,
  mapReverse2U: Belt_List.mapReverse2U,
  mapReverse2: Belt_List.mapReverse2,
  forEach2U: Belt_List.forEach2U,
  forEach2: Belt_List.forEach2,
  reduce2U: Belt_List.reduce2U,
  reduce2: Belt_List.reduce2,
  reduceReverse2U: Belt_List.reduceReverse2U,
  reduceReverse2: Belt_List.reduceReverse2,
  everyU: Belt_List.everyU,
  every: Belt_List.every,
  someU: Belt_List.someU,
  some: Belt_List.some,
  every2U: Belt_List.every2U,
  every2: Belt_List.every2,
  some2U: Belt_List.some2U,
  some2: Belt_List.some2,
  cmpByLength: Belt_List.cmpByLength,
  cmpU: Belt_List.cmpU,
  cmp: Belt_List.cmp,
  eqU: Belt_List.eqU,
  eq: Belt_List.eq,
  hasU: Belt_List.hasU,
  has: Belt_List.has,
  getByU: Belt_List.getByU,
  getBy: Belt_List.getBy,
  keepU: Belt_List.keepU,
  keep: Belt_List.keep,
  filter: Belt_List.filter,
  keepWithIndexU: Belt_List.keepWithIndexU,
  keepWithIndex: Belt_List.keepWithIndex,
  filterWithIndex: Belt_List.filterWithIndex,
  keepMapU: Belt_List.keepMapU,
  keepMap: Belt_List.keepMap,
  partitionU: Belt_List.partitionU,
  partition: Belt_List.partition,
  unzip: Belt_List.unzip,
  getAssocU: Belt_List.getAssocU,
  getAssoc: Belt_List.getAssoc,
  hasAssocU: Belt_List.hasAssocU,
  hasAssoc: Belt_List.hasAssoc,
  removeAssocU: Belt_List.removeAssocU,
  removeAssoc: Belt_List.removeAssoc,
  setAssocU: Belt_List.setAssocU,
  setAssoc: Belt_List.setAssoc,
  sortU: Belt_List.sortU,
  sort: Belt_List.sort,
  product: product$1,
  fromImmutableJsList: fromImmutableJsList$1
};

function byEmailByUser(emailA, emailB) {
  var userOfEmail = function (email) {
    return Caml_array.caml_array_get(email.split("@"), 0);
  };
  return Caml_primitive.caml_string_compare(userOfEmail(emailA), userOfEmail(emailB));
}

var Compare = {
  byEmailByUser: byEmailByUser
};

function dup(dict) {
  return Js_dict.map((function (v) {
                return v;
              }), dict);
}

var Dict = {
  get: Js_dict.get,
  unsafeDeleteKey: Js_dict.unsafeDeleteKey,
  entries: Js_dict.entries,
  values: Js_dict.values,
  fromList: Js_dict.fromList,
  fromArray: Js_dict.fromArray,
  map: Js_dict.map,
  dup: dup
};

function fromOption(opt, error) {
  if (opt !== undefined) {
    return /* Ok */Block.__(0, [Caml_option.valFromOption(opt)]);
  } else {
    return /* Error */Block.__(1, [error]);
  }
}

function toOption(res) {
  if (res.tag) {
    return ;
  } else {
    return Caml_option.some(res[0]);
  }
}

var Result = {
  getExn: Belt_Result.getExn,
  mapWithDefaultU: Belt_Result.mapWithDefaultU,
  mapWithDefault: Belt_Result.mapWithDefault,
  mapU: Belt_Result.mapU,
  map: Belt_Result.map,
  flatMapU: Belt_Result.flatMapU,
  flatMap: Belt_Result.flatMap,
  getWithDefault: Belt_Result.getWithDefault,
  isOk: Belt_Result.isOk,
  isError: Belt_Result.isError,
  eqU: Belt_Result.eqU,
  eq: Belt_Result.eq,
  cmpU: Belt_Result.cmpU,
  cmp: Belt_Result.cmp,
  fromOption: fromOption,
  toOption: toOption
};

function first(param) {
  return param[0];
}

function second(param) {
  return param[1];
}

var Pair = {
  first: first,
  second: second
};

var objGetAny = (function(obj, key) { return obj[key];});

var Unsafe = {
  objGetAny: objGetAny,
  objGetInt: objGetAny,
  objGetString: objGetAny
};

var Fun = { };

var StdArray;

var fmt = Format.sprintf;

var js_reasonSetFromImmutableJsOrderedSet = fromImmutableJsOrderedSet;

exports.HtmlOption = HtmlOption;
exports.$$Promise = $$Promise$1;
exports.StdArray = StdArray;
exports.Int = Int;
exports.Float = Float;
exports.fmt = fmt;
exports.$$Option = $$Option;
exports.or = or;
exports.$$Math = $$Math$1;
exports.$$String = $$String$1;
exports.$$Array = $$Array$1;
exports.List = List$1;
exports.Compare = Compare;
exports.Dict = Dict;
exports.Result = Result;
exports.Pair = Pair;
exports.Unsafe = Unsafe;
exports.Fun = Fun;
exports.js_reasonSetFromImmutableJsOrderedSet = js_reasonSetFromImmutableJsOrderedSet;
/*  Not a pure module */
