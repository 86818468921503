/* @flow */

import React from "react";
import List from "immutable";
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryTheme } from "victory";
import { Link } from "react-router-dom";
import type { SampleHistories } from "models";
import { Instance } from "models";
import theme from "models/victoryTheme";

type Props = {
  instances: List<Instance>,
  lastTwelveMonths: Array<string>,
  sampleHistories: SampleHistories,
};

export default class SampleHistoryTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderInstanceRow(inst: Instance) {
    let billedSampleCount = inst.get("billed_sample_count") || 0;
    let sampleCount = inst.get("sample_count") || 0;

    let usage = null;
    if (billedSampleCount == sampleCount) {
      usage = String(billedSampleCount);
    } else {
      usage = `${billedSampleCount} + ${sampleCount - billedSampleCount} 🆓= ${sampleCount}`;
    }

    const history = ((this.props.sampleHistories || {})[inst.get("id")] || {}).history || {};

    return (
      <tr key={inst.get("id")}>
        <td style={{ maxWidth: "200px" }}>
          <Link to={`/admin/instances/${inst.id}/edit`}>{inst.get("name")}</Link>
          <br />
          {usage} / {inst.get("max_samples") || "Unlimited"}
        </td>
        {(this.props.lastTwelveMonths || []).map((month) => {
          return (
            <td className="text-right" key={month}>
              {history[month] || ""}
            </td>
          );
        })}
      </tr>
    );
  }

  render() {
    if (!this.props.sampleHistories) {
      return <h3>"Loading…"</h3>;
    }

    return (
      <table className="table table-striped table-bordered table-hover table-sm">
        <thead>
          <tr>
            {["Instance Name"].concat(this.props.lastTwelveMonths || []).map((v, i) => (
              <th key={i}>{v}</th>
            ))}
          </tr>
        </thead>
        <tbody>{this.props.instances.map((inst) => this.renderInstanceRow(inst))}</tbody>
      </table>
    );
  }
}
