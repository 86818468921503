// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var Modal$Astrolabe = require("../Modal.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ConfirmedButton$Astrolabe = require("./ConfirmedButton.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

var ppx_printed_query = "mutation DestroyExperiment($experimentId: ID!)  {\ndestroyExperiment(id: $experimentId)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyExperiment: tmp
        };
}

function make(experimentId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "experimentId",
                  experimentId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "experimentId",
                      experimentId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var DestroyExperimentConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var DestroyExperimentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function DeleteExperimentButton(Props) {
  var experimentId = Props.experimentId;
  var isDeletableByCustomer = Props.isDeletableByCustomer;
  var isStaff = Props.isStaff;
  var match = React.useState((function () {
          return false;
        }));
  var setShowModal = match[1];
  var isOnlyDeletableByStaff = !isDeletableByCustomer && isStaff;
  var deleteExperimentButton = React.createElement(DestroyExperimentMutation.make, {
        children: (function (mutation, param) {
            return React.createElement(ConfirmedButton$Astrolabe.make, {
                        buttonTitle: "Delete Experiment",
                        confirmationText: "DELETE",
                        isIcon: true,
                        icon: React.createElement(Icons$Astrolabe.Trash.make, {
                              className: "white no-hover",
                              height: "15",
                              width: "15"
                            }),
                        modalButtonClassName: "btn-danger",
                        modalContents: isOnlyDeletableByStaff ? React.createElement("p", {
                                className: "text-danger"
                              }, "WARNING: This experiment has already been analyzed. Deleting it will reimburse the customer for the deleted samples. Are you absolutely sure you would like to proceed?") : React.createElement(React.Fragment, undefined, React.createElement("p", {
                                    className: "text-danger"
                                  }, "All experiment data will be deleted, including experiment configuration and uploaded sample files. This cannot be\n        undone."), React.createElement("p", undefined, "Are you sure you want to delete this experiment?")),
                        onConfirm: (function (param) {
                            return GraphQLHelpers$Astrolabe.handleDataAndErrors((function (param) {
                                          return ReasonReactHelpers$Astrolabe.redirect("/");
                                        }), Curry._4(mutation, Caml_option.some(make(experimentId, undefined).variables), undefined, undefined, undefined));
                          }),
                        title: "Delete Experiment"
                      });
          })
      });
  var cannotDeleteExperimentButton = React.createElement(React.Fragment, undefined, React.createElement("div", {
            title: "Delete Experiment",
            onClick: (function (param) {
                return Curry._1(setShowModal, (function (param) {
                              return true;
                            }));
              })
          }, React.createElement(Icons$Astrolabe.Trash.make, {
                className: "white no-hover cursor-pointer",
                height: "15",
                width: "15"
              })), React.createElement(Modal$Astrolabe.make, {
            onCancel: (function (param) {
                return Curry._1(setShowModal, (function (param) {
                              return false;
                            }));
              }),
            title: "Delete Experiment",
            visible: match[0],
            children: React.createElement("div", {
                  className: "modal-body"
                }, "This experiment was analyzed at least once and therefore cannot be deleted. Contact ", React.createElement("a", {
                      href: "mailto:support@astrolabediagnostics.com"
                    }, "support@astrolabediagnostics.com"), " with questions.", React.createElement("div", {
                      className: "row justify-content-end pr-3"
                    }, React.createElement("button", {
                          className: "btn btn-light",
                          onClick: (function (param) {
                              return Curry._1(setShowModal, (function (param) {
                                            return false;
                                          }));
                            })
                        }, "Done")))
          }));
  if (isDeletableByCustomer || isStaff) {
    return deleteExperimentButton;
  } else {
    return cannotDeleteExperimentButton;
  }
}

var make$1 = DeleteExperimentButton;

var $$default = DeleteExperimentButton;

exports.DestroyExperimentConfig = DestroyExperimentConfig;
exports.DestroyExperimentMutation = DestroyExperimentMutation;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* DestroyExperimentMutation Not a pure module */
