/* @flow */

import React from "react";
import type { History } from "react-router";
import { List } from "immutable";
import UploadRow from "components/samples/UploadRow";
import FileUpload from "models/fileUpload";
import { fetchJSON } from "components/utils";
import NavTitle from "../NavTitle";

type Props = {
  history: History,
};

type State = {
  creating: boolean,
  errors: Array<string>,
  experimentName: string,
};

export default class New extends React.Component<Props, State> {
  handleCreate: () => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      creating: false,
      errors: [],
      experimentName: new Date().toString(),
    };
  }

  async createExperiment() {
    this.setState({ creating: true });
    const json = await fetchJSON("POST", "/experiments.json", {
      experiment: {
        name: this.state.experimentName,
      },
    });

    if (json.errors) {
      this.setState({ errors: json.errors });
      this.setState({ creating: false });
    } else {
      this.props.history.push(`/experiments/${json.experiment.id}/upload_samples`);
    }
  }

  handleCreate = () => {
    this.createExperiment();
  };

  render() {
    let errorsAlert = null;

    if (this.state.errors.length > 0) {
      errorsAlert = (
        <div className="row">
          <div className="col-md" />
          <div className="col-10 col-md-10 alert alert-danger" role="alert">
            Some errors must be addressed before continuing:
            <ul className="mb-0">
              {this.state.errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </div>
          <div className="col-md" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="New Experiment" />
        <div className="col-12">{errorsAlert}</div>
        <form role="form" className="row" onSubmit={(e) => e.preventDefault()}>
          <div className="col-12 col-lg-6">
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="experimentName">Experiment Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="experimentName"
                  value={this.state.experimentName}
                  onChange={(e) => this.setState({ experimentName: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-right">
            <button className="btn btn-primary" onClick={this.handleCreate}>
              Upload Samples &nbsp;▶
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
