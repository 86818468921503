// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ConfirmedButton$Astrolabe = require("./ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function ExperimentArchiveButton(Props) {
  var experimentId = Props.experimentId;
  var isArchived = Props.isArchived;
  var titleText = isArchived ? "Un-Archive" : "Archive";
  var modalText = isArchived ? "Are you sure you want to un-archive this experiment? It can be archived at any time." : "Are you sure you want to archive this experiment? It can be un-archived at any time.";
  var buttonTitle = isArchived ? "Un-archive the experiment." : "Archive the experiment. You can later access it by turning on \"Show Archived Experiment\" on the experiments list page.";
  return React.createElement(ExperimentGraphQL$Astrolabe.UpdateMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement(ConfirmedButton$Astrolabe.make, {
                              buttonTitle: buttonTitle,
                              isIcon: true,
                              icon: React.createElement(Icons$Astrolabe.Archive.make, {
                                    height: "15",
                                    width: "15"
                                  }),
                              modalButtonClassName: "btn-primary",
                              modalContents: React.createElement("p", undefined, modalText),
                              onConfirm: (function (param) {
                                  return GraphQLHelpers$Astrolabe.handleDataAndErrors((function (param) {
                                                return ReasonReactHelpers$Astrolabe.reload(undefined);
                                              }), Curry._4(mutation, Caml_option.some(ExperimentGraphQL$Astrolabe.UpdateConfig.make(experimentId, undefined, undefined, undefined, !isArchived, undefined, undefined, undefined, undefined, undefined, undefined).variables), undefined, undefined, undefined));
                                }),
                              title: titleText + " Experiment"
                            });
                })
            });
}

var UpdateExperiment;

var UpdateMutation;

var make = ExperimentArchiveButton;

var $$default = ExperimentArchiveButton;

exports.UpdateExperiment = UpdateExperiment;
exports.UpdateMutation = UpdateMutation;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
