/* @flow */
"use strict";

import React from "react";
import { List, OrderedMap } from "immutable";
import DataTableHeatMap from "../../DataTableHeatMap.bs";
import { ylOrBr } from "../../../models/Theme.bs";
import { DataTable, Experiment, Sample } from "models";
import GraphDownloadButton, { canvasGraphType } from "components/GraphDownloadButton.bs";

type Props = {
  experiment: Experiment,
  samples: List<Sample>,
};

type State = {
  dataTable: DataTable,
  rowSort: string,
  rowSortAscending: boolean,
  samples: List<Sample>,
};

export default class AOFHeatMap extends React.PureComponent<Props, State> {
  heatMap: ?React$ElementRef<*>;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataTable: this.constructor.dataTable(props),
      rowSort: "alphanumeric",
      rowSortAscending: true,
      samples: props.samples,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.samples !== nextProps.samples) {
      return {
        dataTable: this.dataTable(nextProps),
        rowSort: "alphanumeric",
        rowSortAscending: true,
        samples: nextProps.samples,
      };
    }

    return null;
  }

  static dataTable(props: Props): DataTable {
    let collator = new window.Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

    let rows = props.samples
      .map((s) => {
        let row = { rowLabel: s.name };
        s.get("qc_metrics")
          .filter((m) => m.name == "aof")
          .forEach((m) => {
            row[m.desc] = m.value;
          });
        return row;
      })
      .sort(collator.compare);

    let channels = rows
      .map((r) => List(Object.keys(r)))
      .flatten()
      .toSet()
      .toArray()
      .filter((k) => k != "rowLabel")
      .sort(collator.compare);

    return new DataTable({
      rowLabels: rows.map((r) => r.rowLabel).toArray(),
      columnLabels: channels,
      columns: OrderedMap(channels.map((channel) => [channel, rows.map((row) => row[channel])])).toJS(),
    });
  }

  handleRowSortChange = (e: SyntheticInputEvent<*>) => {
    this.setState({
      rowSort: e.target.value,
      dataTable: this.sortByRow(e.target.value, this.state.rowSortAscending),
    });
  };

  handleRowSortDirClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.setState({
      rowSortAscending: !this.state.rowSortAscending,
      dataTable: this.sortByRow(this.state.rowSort, !this.state.rowSortAscending),
    });
    return false;
  };

  sortByRow(key: string, ascending: boolean) {
    if (key === "alphanumeric") {
      return this.state.dataTable
        .transpose()
        .sortByRowLabel(ascending)
        .transpose();
    } else {
      return this.state.dataTable
        .transpose()
        .sortBy(key, ascending)
        .transpose();
    }
  }

  render() {
    let data = this.state.dataTable.toOrderedJSONRows();

    const topMargin =
      7 *
      Math.max.apply(
        null,
        this.state.dataTable.columnLabels.map((l) => l.length),
      );
    const leftMargin =
      7 *
      (Math.max.apply(
        null,
        this.state.dataTable.rowLabels.map((l) => l.length),
      ) || 5);

    const maxAOF = ((this.props.experiment.get("qc_metric_thresholds") || {}).aof || {}).max;

    return (
      <React.Fragment>
        <div className="row">
          <p className="col-12">
            The Average Overlap Frequency (AOF) is an efficient metric to evaluate and quantify the robustness of staining in mass
            cytometry data (see Amir et al., 2018). Passing values are between 0 and {maxAOF ? maxAOF.toFixed(2) : "N/A"}. A
            failing channel might indicate a staining issue, an Astrolabe clustering issue, or the inability of the metric to
            detect a rare cell subset.
          </p>
        </div>

        <div className="row">
          <div className="col-3">
            <form>
              <div className="form-row">
                <label className="col-12">Sort Channels By</label>

                <div className="form-group col-10">
                  <select className="form-control form-control-sm" onChange={this.handleRowSortChange} value={this.state.rowSort}>
                    <option value="alphanumeric">Channel Name</option>
                    {this.state.dataTable.rowLabels.map((val, i) => (
                      <option key={i} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-2">
                  <button className="btn btn-sm btn-primary" onClick={this.handleRowSortDirClick}>
                    {this.state.rowSortAscending ? "▲" : "▼"}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-9" ref={(el) => (this.heatMap = el)}>
            <div className="mx-auto">
              <GraphDownloadButton
                prefix={this.props.experiment.name}
                name="Heat Map"
                container={() => this.heatMap}
                graphType={canvasGraphType}
                filenames="AOF"
              />

              <DataTableHeatMap
                dataTable={this.state.dataTable}
                colors={ylOrBr}
                id="aof-heatmap"
                legendTitle="AOF"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
