// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Icons$Astrolabe = require("../Icons.bs.js");

function DeleteIcon(Props) {
  var onClick = Props.onClick;
  var title = Props.title;
  var match = React.useState((function () {
          return false;
        }));
  var setDeleting = match[1];
  return React.createElement("button", {
              className: "btn btn-sm btn-danger",
              style: {
                lineHeight: "12px",
                width: "30px"
              },
              title: title,
              onClick: (function (param) {
                  Curry._1(setDeleting, (function (param) {
                          return true;
                        }));
                  Curry._1(onClick, undefined);
                  return Curry._1(setDeleting, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement(Icons$Astrolabe.Trash.make, {
                  className: match[0] ? "no-hover" : "",
                  height: "15",
                  width: "15"
                }));
}

var make = DeleteIcon;

var $$default = DeleteIcon;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
