// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");
var SampleGraphQL$Astrolabe = require("../../../graphql/SampleGraphQL.bs.js");
var DebarcodingKit$Astrolabe = require("../../../models/DebarcodingKit.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");
var SampleNameInput$Astrolabe = require("../../samples/SampleNameInput.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var KitConfigurationsTable$Astrolabe = require("./KitConfigurationsTable.bs.js");

var rowHeightStyle = {
  height: "39px"
};

function SampleChannelMapping(Props) {
  var disabled = Props.disabled;
  var experimentDebarcodingKits = Props.experimentDebarcodingKits;
  var onComplete = Props.onComplete;
  var samples = Props.samples;
  var sourceSampleName = Props.sourceSampleName;
  var sourceSampleNameHidden = Props.sourceSampleNameHidden;
  var experimentDebarcodingKits$1 = $$Array.map(DebarcodingKit$Astrolabe.fromJs, experimentDebarcodingKits);
  return React.createElement(SampleGraphQL$Astrolabe.UpdateSampleMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement(React.Fragment, undefined, sourceSampleNameHidden ? null : React.createElement("h5", {
                                    className: "mt-4 ml-1"
                                  }, ReasonReactHelpers$Astrolabe.str(sourceSampleName)), React.createElement("div", {
                                  className: "d-flex flex-nowrap"
                                }, $$Array.map((function (kit) {
                                        return React.createElement(KitConfigurationsTable$Astrolabe.make, {
                                                    kit: kit,
                                                    samples: samples,
                                                    rowHeightStyle: rowHeightStyle,
                                                    key: kit.id
                                                  });
                                      }), experimentDebarcodingKits$1), React.createElement(React.Fragment, undefined, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                                      className: "border-left px-2"
                                                    }, React.createElement("span", {
                                                          title: "Channel combinations marked as Ignore will not be debarcoded and will not be included in the experiment"
                                                        }, ReasonReactHelpers$Astrolabe.str("Ignore")))), React.createElement("tr", undefined, React.createElement("th", {
                                                      className: "border-left",
                                                      style: {
                                                        height: "26px"
                                                      }
                                                    }, ReasonReactHelpers$Astrolabe.str("")))), React.createElement("tbody", undefined, $$Array.mapi((function (i, sample) {
                                                    return React.createElement("tr", {
                                                                key: String(i),
                                                                className: "text-center",
                                                                style: rowHeightStyle
                                                              }, React.createElement("td", {
                                                                    className: "border-left px-2"
                                                                  }, React.createElement("input", {
                                                                        checked: Sample$Astrolabe.Js.isIgnoredFromDebarcoding(sample),
                                                                        disabled: disabled,
                                                                        type: "checkbox",
                                                                        onChange: (function (e) {
                                                                            return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(SampleGraphQL$Astrolabe.UpdateSampleConfig.make(Sample$Astrolabe.Js.id(sample), undefined, ReasonReactHelpers$Astrolabe.checkedFromEvent(e), undefined, undefined).variables), ["getExperiment"], undefined, undefined));
                                                                          })
                                                                      })));
                                                  }), samples))), React.createElement("table", {
                                          style: {
                                            flexGrow: "2"
                                          }
                                        }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                                      className: "border-left px-2"
                                                    }, React.createElement("span", {
                                                          title: "The name of the sample (and its filename) resulting from this channel combination"
                                                        }, ReasonReactHelpers$Astrolabe.str("Sample Name")))), React.createElement("tr", undefined, React.createElement("th", {
                                                      className: "border-left",
                                                      style: {
                                                        height: "26px"
                                                      }
                                                    }, ReasonReactHelpers$Astrolabe.str("")))), React.createElement("tbody", undefined, $$Array.mapi((function (i, sample) {
                                                    return React.createElement("tr", {
                                                                key: String(i),
                                                                className: "text-center",
                                                                style: rowHeightStyle
                                                              }, React.createElement(SampleNameInput$Astrolabe.make, {
                                                                    disabled: disabled,
                                                                    onComplete: onComplete,
                                                                    sample: sample,
                                                                    shouldUpdateFileFileName: true
                                                                  }));
                                                  }), samples))))));
                })
            });
}

var $$Option;

var Sample;

var UpdateSampleConfig;

var UpdateSampleMutation;

var make = SampleChannelMapping;

var $$default = SampleChannelMapping;

exports.$$Option = $$Option;
exports.Sample = Sample;
exports.UpdateSampleConfig = UpdateSampleConfig;
exports.UpdateSampleMutation = UpdateSampleMutation;
exports.rowHeightStyle = rowHeightStyle;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
