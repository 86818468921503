// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("bs-platform/lib/js/string.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");

var InvalidInputExn = Caml_exceptions.create("GraphQL-Astrolabe.Types.InvalidInputExn");

function cellSubsetLevel_of_string(str) {
  var str$1 = $$String.uppercase_ascii(str);
  switch (str$1) {
    case "ASSIGNMENT" :
        return /* ASSIGNMENT */500777805;
    case "PROFILING" :
        return /* PROFILING */-899121530;
    default:
      throw [
            InvalidInputExn,
            str$1
          ];
  }
}

function string_of_cellSubsetLevel($$enum) {
  if ($$enum >= 500777805) {
    return "ASSIGNMENT";
  } else {
    return "PROFILING";
  }
}

function cellSubsetLevelName($$enum) {
  if ($$enum >= 500777805) {
    return "Assignment";
  } else {
    return "Profiling";
  }
}

function treatment_of_string(str) {
  var str$1 = $$String.uppercase_ascii(str);
  switch (str$1) {
    case "FIXED" :
        return /* FIXED */-24104620;
    case "FRESH" :
        return /* FRESH */74759758;
    default:
      throw [
            InvalidInputExn,
            str$1
          ];
  }
}

function string_of_treatment($$enum) {
  if ($$enum >= 74759758) {
    return "FRESH";
  } else {
    return "FIXED";
  }
}

function statistic_of_string(str) {
  var str$1 = $$String.uppercase_ascii(str);
  switch (str$1) {
    case "FIXATION" :
        return /* FIXATION */-744809280;
    case "MEDIAN" :
        return /* MEDIAN */826994442;
    case "PERCENT_POSITIVE" :
        return /* PERCENT_POSITIVE */78066387;
    default:
      throw [
            InvalidInputExn,
            str$1
          ];
  }
}

function string_of_statistic($$enum) {
  if ($$enum !== 78066387) {
    if ($$enum >= 826994442) {
      return "MEDIAN";
    } else {
      return "FIXATION";
    }
  } else {
    return "PERCENT_POSITIVE";
  }
}

function boxPlotGroupBy_of_string(str) {
  var str$1 = $$String.uppercase_ascii(str);
  switch (str$1) {
    case "ANTIBODY" :
        return /* ANTIBODY */-76541116;
    case "CELL_SUBSET" :
        return /* CELL_SUBSET */-470675873;
    default:
      throw [
            InvalidInputExn,
            str$1
          ];
  }
}

function string_of_boxPlotGroupBy($$enum) {
  if ($$enum >= -76541116) {
    return "ANTIBODY";
  } else {
    return "CELL_SUBSET";
  }
}

var Types = {
  InvalidInputExn: InvalidInputExn,
  cellSubsetLevel_of_string: cellSubsetLevel_of_string,
  string_of_cellSubsetLevel: string_of_cellSubsetLevel,
  cellSubsetLevelName: cellSubsetLevelName,
  treatment_of_string: treatment_of_string,
  string_of_treatment: string_of_treatment,
  statistic_of_string: statistic_of_string,
  string_of_statistic: string_of_statistic,
  boxPlotGroupBy_of_string: boxPlotGroupBy_of_string,
  string_of_boxPlotGroupBy: string_of_boxPlotGroupBy
};

exports.Types = Types;
/* No side effect */
