/* @flow */
"use strict";

export async function fetchJSON(method: string, url: string, body?: any = null) {
  var params: RequestOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": window.csrfToken,
    },
    credentials: "same-origin",
    method: method,
  };

  if (method !== "GET" && method !== "HEAD") {
    if (body) {
      params.body = JSON.stringify(body);
    }
  }

  try {
    var resp = await fetch(url, params);
    var json = await resp.json();
    return json;
  } catch (e) {
    throw e;
  }
}

export async function fetchCSV(method: string, url: string, body?: any = null) {
  var params: RequestOptions = {
    headers: {
      Accept: "application/csv",
      "Content-Type": "application/csv",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": window.csrfToken,
    },
    credentials: "same-origin",
    method: method,
  };

  if (method !== "GET" && method !== "HEAD") {
    if (body) {
      params.body = JSON.stringify(body);
    }
  }

  try {
    var resp = await fetch(url, params);
    var text = await resp.text();
    return text.split("\n");
  } catch (e) {
    throw e;
  }
}

export function formattedBytes(bytes: ?number, decimals: number = 2): string {
  if (!bytes) {
    return "Unknown Size";
  }
  if (bytes < 2000) {
    return `${bytes} B`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(decimals)} KB`;
  } else if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / 1024 / 1024).toFixed(decimals)} MB`;
  } else {
    return `${(bytes / 1024 / 1024 / 1024).toFixed(decimals)} GB`;
  }
}

export function joinQuoted(array: Array<string>): string {
  return array.map((elem) => `"${elem.replace(/\"/g, '\\"')}"`).join(", ");
}

export function roundFloat(num: number, precision: number): number {
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function toISODateString(date: Date | string | null): string {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return date ? date.toISOString().split("T")[0] : "";
}

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const ensureArray = (element) => (Array.isArray(element) ? element : [element]);
