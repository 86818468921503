import "core-js/stable";
import "regenerator-runtime/runtime";

require("bootstrap/dist/css/bootstrap");
require("application");
require("bootstrap-theme");

import React from "react";
import ReactDOM from "react-dom";
import App from "../components/App.jsx";
import ActionCable from "actioncable";

document.addEventListener("DOMContentLoaded", () => {
  const tokenElem = document.getElementsByName("csrf-token");
  const paramElem = document.getElementsByName("csrf-param");

  if (tokenElem.length == 0) {
    console.error("Couldn't find CSRF token.");
  }
  window.csrfToken = tokenElem[0].content;

  if (paramElem.length == 0) {
    console.error("Couldn't find CSRF param.");
  }
  window.csrfParam = paramElem[0].content;

  window.cable = ActionCable.createConsumer();

  ReactDOM.render(<App name="React" />, document.getElementById("app"));
});
