// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Theme$Astrolabe = require("../../models/Theme.bs.js");
var Feature$Astrolabe = require("../../models/Feature.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function FeatureTooltip(Props) {
  var feature = Props.feature;
  var name = Feature$Astrolabe.name(feature);
  var values = Feature$Astrolabe.values(feature);
  var allowNa = Feature$Astrolabe.is_allow_na(feature);
  var backgroundColor = function (i) {
    if (i < Theme$Astrolabe.Palette.dark2.length) {
      return Caml_array.caml_array_get(Theme$Astrolabe.Palette.dark2, i);
    } else {
      return "#000000";
    }
  };
  return React.createElement("div", {
              className: "position-absolute border",
              style: {
                backgroundColor: "white",
                width: "275px",
                zIndex: "1000"
              }
            }, React.createElement("div", {
                  className: "p-3"
                }, React.createElement("div", {
                      className: "text-right font-weight-bold pb-2"
                    }, ReasonReactHelpers$Astrolabe.str(Util$Astrolabe.$$String.truncate(name, 25, undefined, undefined) + " Values")), React.createElement("div", {
                      className: "d-flex flex-wrap justify-content-end"
                    }, values.map((function (name, i) {
                            return React.createElement("div", {
                                        key: String(i) + name,
                                        className: "d-flex justify-content-end px-1",
                                        style: {
                                          minWidth: "50%"
                                        }
                                      }, React.createElement("div", {
                                            className: "text-muted font-weight-light"
                                          }, ReasonReactHelpers$Astrolabe.str(Util$Astrolabe.$$String.truncate(name, 12, undefined, undefined))), React.createElement("div", {
                                            className: "d-flex flex-column justify-content-center"
                                          }, React.createElement("div", {
                                                className: "ml-2",
                                                style: {
                                                  backgroundColor: backgroundColor(i),
                                                  height: "15px",
                                                  width: "15px"
                                                }
                                              })));
                          })))), allowNa ? React.createElement("div", {
                    className: "text-right",
                    style: {
                      backgroundColor: "#e0d5d5",
                      color: "#892f2b"
                    }
                  }, React.createElement("div", {
                        className: "px-4 py-3"
                      }, ReasonReactHelpers$Astrolabe.str("One or more samples are marked \"NA\" for this feature. These will be ignored in analysis and visualizations."))) : null);
}

var make = FeatureTooltip;

exports.make = make;
/* react Not a pure module */
