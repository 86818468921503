// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Immutable = require("immutable");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");
var Constants$Astrolabe = require("../../../utils/Constants.bs.js");
var Experiment$Astrolabe = require("../../../models/Experiment.bs.js");
var TitleWithIcon$Astrolabe = require("../../header/TitleWithIcon.bs.js");
var ConfirmedButton$Astrolabe = require("../../header/ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../../graphql/ExperimentGraphQL.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var SortableTableHeader$Astrolabe = require("../../shared/SortableTableHeader.bs.js");
var SampleChannelMapping$Astrolabe = require("./SampleChannelMapping.bs.js");
var DownloadUploadTemplateBox$Astrolabe = require("../../shared/DownloadUploadTemplateBox.bs.js");

function ChannelMapping(Props) {
  var continueDisabled = Props.continueDisabled;
  var experiment = Props.experiment;
  var samples = Props.samples;
  var experimentDebarcodingKits = Props.experimentDebarcodingKits;
  var onComplete = Props.onComplete;
  var match = React.useState((function () {
          return "";
        }));
  var setSelectedSourceSampleName = match[1];
  var selectedSourceSampleName = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setIsUploading = match$1[1];
  var isUploading = match$1[0];
  var canEditChannelMapping = Experiment$Astrolabe.Js.canEditChannelMapping(experiment);
  var sourceSampleNames = Immutable.List(Belt_Array.keepMap(samples, Sample$Astrolabe.Js.sourceSampleFileName)).toOrderedSet().sortBy((function (s) {
            return s;
          })).toArray();
  var match$2 = selectedSourceSampleName === "" ? /* tuple */[
      sourceSampleNames,
      ""
    ] : /* tuple */[
      [selectedSourceSampleName],
      selectedSourceSampleName
    ];
  var channelMappingSubtitle = canEditChannelMapping ? React.createElement("span", undefined, "Define the mapping between each channel combination and the debarcoded sample name. For more information, refer to the ", React.createElement("a", {
              href: Constants$Astrolabe.debarcodingTutorialUrl,
              target: "_blank"
            }, "Debarcoding tutorial.")) : "Your channel mapping has been saved. You can no longer modify it.";
  var experimentId = Experiment$Astrolabe.Js.id(experiment);
  var finishBarcodingButton = isUploading ? null : React.createElement(ExperimentGraphQL$Astrolabe.UpdateEventMutation.make, {
          children: (function (mutation, param) {
              return React.createElement("div", {
                          className: "mx-3 title-button",
                          title: continueDisabled ? "Resolve errors before continuing" : "Finish mapping channels to debarcoded samples. This action cannot be undone."
                        }, React.createElement(ConfirmedButton$Astrolabe.make, {
                              buttonClassName: "btn-success title-button " + (
                                continueDisabled ? "cursor-not-allowed" : "astrolabe-bg-green"
                              ),
                              buttonTitle: "Finish Barcoding",
                              disabled: continueDisabled,
                              modalButtonClassName: "btn-primary",
                              modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Are you sure you want to continue?"), React.createElement("p", undefined, "Once you continue, you won't be able to update the channel mapping for this experiment.")),
                              onConfirm: (function (param) {
                                  return ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "done_channel_mapping", undefined, undefined);
                                }),
                              title: "Finished Mapping Channels"
                            }));
            })
        });
  var featureTemplateBox = React.createElement(DownloadUploadTemplateBox$Astrolabe.make, {
        downloadButtonTooltip: "Download a spreadsheet template where you can fill in debarcoded samples using an editor.",
        downloadUrl: "/experiments/" + (experimentId + "/debarcoding_template.csv"),
        isDownloadButtonDisabled: false,
        onUploadBegin: (function (param) {
            return Curry._1(setIsUploading, (function (param) {
                          return true;
                        }));
          }),
        onUploadComplete: (function (errors, disableContinue, success) {
            Curry._1(setIsUploading, (function (param) {
                    return false;
                  }));
            return Curry._5(onComplete, errors, disableContinue, undefined, success, undefined);
          }),
        text: "If you would like to define the mapping using a spreadsheet instead of the interface, download a template using the 'Download Template' button. You can then upload the completed spreadsheet using the 'Upload Template' button.",
        uploadButtonTooltip: "Upload a filled-in template into the Astrolabe channel mapping.",
        uploadUrl: "/experiments/" + (experimentId + "/apply_debarcoding_template")
      });
  return React.createElement("div", {
              className: "astrolabe-bg-light-grey full-width-app-container"
            }, React.createElement("div", {
                  className: "app-container"
                }, React.createElement("div", {
                      className: "d-flex justify-content-between align-items-center"
                    }, React.createElement(TitleWithIcon$Astrolabe.make, {
                          className: "margin-new-title",
                          subtitle: channelMappingSubtitle,
                          title: "Define Channel Mappings",
                          children: React.createElement(Icons$Astrolabe.Barcoding.make, {
                                className: "no-hover",
                                height: "60",
                                width: "60"
                              })
                        }), canEditChannelMapping ? finishBarcodingButton : null), canEditChannelMapping ? featureTemplateBox : null, React.createElement("div", {
                      className: "d-flex justify-content-between mb-4"
                    }, React.createElement("form", {
                          className: "form-inline"
                        }, React.createElement("div", {
                              className: "form-group"
                            }, React.createElement("label", undefined, ReasonReactHelpers$Astrolabe.str("Show:" + ReasonReactHelpers$Astrolabe.nbsp)), React.createElement("select", {
                                  className: "form-control form-control-sm",
                                  value: match$2[1],
                                  onChange: (function (evt) {
                                      var v = ReasonReactHelpers$Astrolabe.valueFromEvent(evt);
                                      return Curry._1(setSelectedSourceSampleName, (function (param) {
                                                    return v;
                                                  }));
                                    })
                                }, React.createElement("option", {
                                      value: ""
                                    }, ReasonReactHelpers$Astrolabe.str("All Samples")), $$Array.mapi((function (i, value) {
                                        return React.createElement("option", {
                                                    key: String(i),
                                                    value: value
                                                  }, ReasonReactHelpers$Astrolabe.str(value));
                                      }), sourceSampleNames))))), $$Array.mapi((function (i, sourceSampleName) {
                        return React.createElement(SampleChannelMapping$Astrolabe.make, {
                                    disabled: isUploading || !canEditChannelMapping,
                                    experimentDebarcodingKits: experimentDebarcodingKits,
                                    onComplete: (function (hasErrors) {
                                        return Curry._5(onComplete, undefined, hasErrors, undefined, undefined, undefined);
                                      }),
                                    samples: SortableTableHeader$Astrolabe.Sort.withGetter(Sample$Astrolabe.Js.id, /* ASC */0, $$Array.of_list(List.filter((function (s) {
                                                      return Caml_obj.caml_equal(Sample$Astrolabe.Js.sourceSampleFileName(s), sourceSampleName);
                                                    }))($$Array.to_list(samples)))),
                                    sourceSampleName: sourceSampleName,
                                    sourceSampleNameHidden: selectedSourceSampleName !== "",
                                    key: String(i)
                                  });
                      }), match$2[0])));
}

var UpdateExperimentEventMutation;

var Experiment;

var Sample;

var make = ChannelMapping;

var $$default = ChannelMapping;

exports.UpdateExperimentEventMutation = UpdateExperimentEventMutation;
exports.Experiment = Experiment;
exports.Sample = Sample;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
