/* @flow */

import React from "react";
import type { History, Match } from "react-router";
import { NavLink, Route } from "react-router-dom";
import { List } from "immutable";
import { Instance } from "models";
import { fetchJSON } from "components/utils";
import Form from "./Form";
import InstanceFileView from "./InstanceFileView.bs";
import Users from "./Users";
import NavTitle from "../../NavTitle";

type Props = {
  history: History,
  match: Match,
};

type State = {
  instance: ?Instance,
  errors: ?List<string>,
};

export default class Edit extends React.Component<Props, State> {
  handleSave: (instance: Instance) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      instance: null,
      errors: null,
    };

    this.fetchInstance();
  }

  async fetchInstance() {
    var json = await fetchJSON("GET", `/admin/instances/${this.props.match.params.id}.json`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({
        instance: new Instance(json.instance),
      });
    }
  }

  handleSave = async (instance: Instance) => {
    this.setState({ instance, errors: null });
    var resp = await fetchJSON("PATCH", `/admin/instances/${instance.id}.json`, { instance });

    if (resp.errors) {
      this.setState({ errors: List(resp.errors) });
    } else {
      window.location.pathname = `/admin/instances`;
    }
  };

  renderTabs() {
    return (
      <ul className="nav nav-pills justify-content-center mb-3">
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={`/admin/instances/${this.props.match.params.id}/edit`}>
            Instance
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={`/admin/instances/${this.props.match.params.id}/files`}>
            Files
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={`/admin/instances/${this.props.match.params.id}/users`}>
            Users
          </NavLink>
        </li>
      </ul>
    );
  }

  render() {
    if (!this.state.instance) {
      return (
        <div className="col-12">
          {this.renderTabs()}
          <h3>Loading…</h3>
        </div>
      );
    }

    return (
      <div className="col-12">
        <NavTitle.TextAndDocumentTitle title="Instance Setup" />
        {this.renderTabs()}
        <Route
          exact
          path="/admin/instances/:id/edit"
          render={() => {
            return (
              <Form
                formProps={{ method: "PATCH", action: `/admin/instances/${this.props.match.params.id}.json` }}
                errors={this.state.errors}
                history={this.props.history}
                instance={this.state.instance}
                onSave={this.handleSave}
              />
            );
          }}
        />
        <Route exact path="/admin/instances/:id/files" render={() => <InstanceFileView instanceId={this.state.instance.id} />} />
        <Route exact path="/admin/instances/:id/users" render={() => <Users instance={this.state.instance} />} />
      </div>
    );
  }
}
