// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function getWindowDimensions(param) {
  var width = window.innerWidth;
  var height = window.innerHeight;
  return {
          width: width,
          height: height
        };
}

function useWindowDimensions(param) {
  var match = React.useState((function () {
          return getWindowDimensions(undefined);
        }));
  var setWindowDimensions = match[1];
  React.useEffect((function () {
          var handleResize = function (param) {
            return Curry._1(setWindowDimensions, (function (param) {
                          return getWindowDimensions(undefined);
                        }));
          };
          window.addEventListener("resize", handleResize);
          return (function (param) {
                    window.removeEventListener("resize", handleResize);
                    
                  });
        }), []);
  return match[0];
}

exports.getWindowDimensions = getWindowDimensions;
exports.useWindowDimensions = useWindowDimensions;
/* react Not a pure module */
