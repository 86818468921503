// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_null = require("bs-platform/lib/js/js_null.js");
var NavTitle = require("./NavTitle");
var ReasonReact = require("reason-react/src/legacy/ReasonReact.bs.js");

var make = NavTitle.default;

var make$1 = NavTitle.TextAndDocumentTitle;

function makeProps(title, documentTitle, param) {
  return {
          title: title,
          documentTitle: Js_null.fromOption(documentTitle)
        };
}

var TextAndDocumentTitle = {
  make: make$1,
  makeProps: makeProps
};

var textAndDocumentTitle = NavTitle.TextAndDocumentTitle;

function make$2(title, documentTitle, children) {
  return ReasonReact.wrapJsForReason(textAndDocumentTitle, {
              title: title,
              documentTitle: documentTitle
            }, children);
}

var TextAndDocumentTitle$1 = {
  make: make$2
};

var JSX2 = {
  textAndDocumentTitle: textAndDocumentTitle,
  TextAndDocumentTitle: TextAndDocumentTitle$1
};

exports.make = make;
exports.TextAndDocumentTitle = TextAndDocumentTitle;
exports.JSX2 = JSX2;
/* make Not a pure module */
