// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var User$Astrolabe = require("../../models/User.bs.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Modal$Astrolabe = require("../Modal.bs.js");
var UserExperiments$Astrolabe = require("../users/UserExperiments.bs.js");

var or = Util$Astrolabe.$$Option.or;

function ManageUsersButton(Props) {
  var currentUser = Props.currentUser;
  var experiment = Props.experiment;
  var match = React.useState((function () {
          return false;
        }));
  var setShowModal = match[1];
  var match$1;
  if (currentUser !== undefined) {
    var currentUser$1 = Caml_option.valFromOption(currentUser);
    var admin = User$Astrolabe.Js.isAdmin(currentUser$1);
    var adminOrStaff = admin || User$Astrolabe.Js.isStaff(currentUser$1);
    var currentUserNotCreator = currentUser$1.email !== experiment.createUserEmail;
    var currentUserEmail = admin && currentUserNotCreator ? currentUser$1.email : undefined;
    match$1 = /* tuple */[
      currentUserEmail,
      experiment.status !== /* CREATED */289311176 && adminOrStaff
    ];
  } else {
    match$1 = /* tuple */[
      undefined,
      false
    ];
  }
  var emailIfCurrentUserAdmin = match$1[0];
  var unassignedUsers = or(experiment.unassignedUsers, []).map((function (user) {
          return User$Astrolabe.make(user.email, user.id, undefined, undefined);
        }));
  var assignedUsers = or(experiment.users, []).map((function (user) {
            return User$Astrolabe.make(user.email, user.id, undefined, undefined);
          })).filter((function (user) {
          if (emailIfCurrentUserAdmin !== undefined) {
            if (User$Astrolabe.email(user) !== experiment.createUserEmail) {
              return User$Astrolabe.email(user) !== emailIfCurrentUserAdmin;
            } else {
              return false;
            }
          } else {
            return User$Astrolabe.email(user) !== experiment.createUserEmail;
          }
        }));
  if (match$1[1]) {
    return React.createElement(React.Fragment, undefined, React.createElement("button", {
                    className: "btn btn-link btn-sm text-white p-0 no-underline-on-hover text-decoration-none",
                    title: "Add or remove users who can access the experiment",
                    onClick: (function (param) {
                        return Curry._1(setShowModal, (function (param) {
                                      return true;
                                    }));
                      })
                  }, "Users"), React.createElement(Modal$Astrolabe.make, {
                    onCancel: (function (param) {
                        return Curry._1(setShowModal, (function (param) {
                                      return false;
                                    }));
                      }),
                    size: "lg",
                    title: "Manage Users",
                    visible: match[0],
                    children: React.createElement(UserExperiments$Astrolabe.make, {
                          assignedUsers: assignedUsers,
                          creatorEmail: experiment.createUserEmail,
                          experimentId: experiment.id,
                          onClose: (function (param) {
                              return Curry._1(setShowModal, (function (param) {
                                            return false;
                                          }));
                            }),
                          unassignedUsers: unassignedUsers
                        })
                  }));
  } else {
    return null;
  }
}

var make = ManageUsersButton;

var $$default = ManageUsersButton;

exports.or = or;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
