// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function make(email, id, roleName, param) {
  return {
          email: email,
          id: id,
          roleName: roleName
        };
}

function email(self) {
  return self.email;
}

function id(self) {
  return self.id;
}

function roleName(self) {
  return self.roleName;
}

function id$1(self) {
  return self.id;
}

function isAdmin(self) {
  return self.isAdmin;
}

function isStaff(self) {
  return self.isStaff;
}

function isSysadmin(self) {
  return self.isSysadmin;
}

var Js = {
  id: id$1,
  isAdmin: isAdmin,
  isStaff: isStaff,
  isSysadmin: isSysadmin
};

exports.make = make;
exports.email = email;
exports.id = id;
exports.roleName = roleName;
exports.Js = Js;
/* No side effect */
