/* @flow */
import React, { Fragment } from "react";
import { matchPath } from "react-router";
import { Route, NavLink } from "react-router-dom";
import { List } from "immutable";

import ExperimentProvider from "./ExperimentProvider";
import type { RenderProps } from "./ExperimentProvider";

import CellSubsetNavigator from "./cell_subset_navigator/CellSubsetNavigator";
import ExperimentView from "./ExperimentView.bs";
import QualityControl from "./QualityControl";
import UploadSamples from "./UploadSamples";
/*
  TODO: migrate to reason
  import UploadSamples from "../setup/UploadSamples.bs";
*/
import UserRoute from "../UserRoute";

import { Experiment } from "models";
import NavTitle from "../NavTitle";

const summary = { path: "summary", component: ExperimentView };
const features = { path: "features", component: ExperimentView };
const debarcoding = {
  path: "debarcoding",
  match: "debarcoding",
  exact: false,
  component: ExperimentView,
  hideTab: (props) => !props.experiment.get("has_debarcoding_kits?"),
};

const qualityControl = {
  path: "quality_control",
  match: "quality_control(/.*)?",
  component: QualityControl,
};
const channels = { path: "channels", component: ExperimentView };
const uploadSamples = { path: "upload_samples", component: UploadSamples };

const analyses = {
  path: "analyses",
  match: "analyses/:kitId?",
  component: ExperimentView,
};
const cellSubsetNavigator = {
  path: "cell_subset_navigator",
  match: "cell_subset_navigator/:tab?",
  component: CellSubsetNavigator,
};
const settings = {
  path: "settings",
  component: ExperimentView,
};

const tabsByStatus = {
  created: [uploadSamples],
  preprocessing: [uploadSamples],
  adding_debarcoding_kits: [debarcoding],
  mapping_barcoding_channels: [debarcoding],
  pending_analysis: [settings, features, channels, analyses, debarcoding],
  debarcoding: [features, settings, channels, debarcoding],
  analyzing: [features, settings, channels, debarcoding],
  done: [summary, settings, analyses, cellSubsetNavigator, qualityControl, channels, debarcoding],
};

const experimentsPath = (isPublicExp) => (isPublicExp ? "/public/experiments" : "/experiments");

export default class View extends React.Component<RenderProps> {
  isPublicExp = window.location.pathname.startsWith("/public");
  expPath = experimentsPath(this.isPublicExp);

  renderErrors(props: RenderProps) {
    let errors = props.errors;
    if (errors.size === 0) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md" />
        <div className="col-12 col-md-6 alert alert-danger" role="alert">
          Some errors must be addressed before continuing:
          <ul className="mb-0">
            {errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
        <div className="col-md" />
      </div>
    );
  }

  renderRoutes(props: RenderProps) {
    return tabsByStatus[props.experiment.get("status")].map((tab, i) => {
      let path = `${this.expPath}/:id/${tab.match || tab.path}`;

      if (i === 0) {
        path = `${this.expPath}/:id/(${tab.match || tab.path})?`;
      }

      return (
        <UserRoute
          key={i}
          render={(routeProps) => (
            <React.Fragment>{React.createElement(tab.component, { ...props, ...routeProps })}</React.Fragment>
          )}
          exact={tab.exact == undefined ? true : tab.exact}
          isPublicExp={this.isPublicExp}
          path={path}
          roleName={tab.roleName}
          session={this.props.session}
        />
      );
    });
  }

  render() {
    return (
      <Fragment>
        <ExperimentProvider
          history={this.props.history}
          isPublicPath={this.isPublicExp}
          match={this.props.match}
          session={this.props.session}
          renderErrors={false}
          render={(props) => {
            props = {
              ...props,
              clearExperimentErrors: (_) => props.api.errors.update(List()),
            };
            return (
              <Fragment key={props.experiment.id}>
                {this.renderErrors(props)}
                {this.renderRoutes(props)}
              </Fragment>
            );
          }}
        />
      </Fragment>
    );
  }
}
