// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var SortByDropdown$Astrolabe = require("../SortByDropdown.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");

function CellSubsetOverview(Props) {
  var channelDescs = Props.channelDescs;
  var columnSort = Props.columnSort;
  var columnSortAscending = Props.columnSortAscending;
  var differentialAbundance = Props.differentialAbundance;
  var onScaledChange = Props.onScaledChange;
  var handleRowSortDirClick = Props.handleRowSortDirClick;
  var heatMap = Props.heatMap;
  var mdsColorBy = Props.mdsColorBy;
  var mdsMap = Props.mdsMap;
  var onColumnSortChange = Props.onColumnSortChange;
  var onMDSColorByChange = Props.onMDSColorByChange;
  var onRowSortChange = Props.onRowSortChange;
  var rowSort = Props.rowSort;
  var rowSortAscending = Props.rowSortAscending;
  var scaled = Props.scaled;
  var sortRowOptions = Props.sortRowOptions;
  var subsetLevel = Props.subsetLevel;
  var volcanoPlot = Props.volcanoPlot;
  var match = React.useState((function () {
          return /* FreqAcrossSamples */0;
        }));
  var setActiveTab = match[1];
  var activeTab = match[0];
  var differentialAbundanceOn = differentialAbundance !== "none";
  var handleColumnSortDirClick = function (e) {
    ReasonReactHelpers$Astrolabe.preventDefault(e);
    return Curry._2(onColumnSortChange, columnSort, !columnSortAscending);
  };
  var handleScaledChange = function (e) {
    ReasonReactHelpers$Astrolabe.preventDefault(e);
    return Curry._1(onScaledChange, !scaled);
  };
  var optionsWithPrepend = function (options, prepend) {
    return ReasonReactHelpers$Astrolabe.arr($$Array.append([React.createElement("option", {
                          key: "prepend",
                          value: ""
                        }, ReasonReactHelpers$Astrolabe.str(prepend))], $$Array.map((function (el) {
                          return React.createElement("option", {
                                      key: el,
                                      value: el
                                    }, ReasonReactHelpers$Astrolabe.str(el));
                        }), options)));
  };
  var match$1 = differentialAbundanceOn ? /* tuple */[
      "Color by Statistic",
      ReasonReactHelpers$Astrolabe.arr([
            React.createElement("option", {
                  key: "logFC",
                  value: "logFC"
                }, ReasonReactHelpers$Astrolabe.str("log(Fold Change)")),
            React.createElement("option", {
                  key: "negLog10FDR",
                  value: "negLog10FDR"
                }, ReasonReactHelpers$Astrolabe.str("-log10(FDR)"))
          ])
    ] : /* tuple */[
      "Color Subsets by",
      optionsWithPrepend(channelDescs, "Select Option")
    ];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "d-flex justify-content-between mt-3"
                }, React.createElement("div", {
                      className: "flex-grow-1"
                    }, React.createElement("h4", undefined, ReasonReactHelpers$Astrolabe.str("Overview: " + ($$String.capitalize_ascii(subsetLevel) + " Subsets"))), React.createElement("div", {
                          className: "d-flex flex-row mb-3"
                        }, React.createElement("div", undefined, React.createElement("span", {
                                  className: activeTab === /* FreqAcrossSamples */0 ? "text-info cursor-default" : "cursor-pointer",
                                  title: "Show the MDS Map, a dimensionality reduction view of cell subsets. Bubble sizes correlate with median subset frequency.",
                                  onClick: (function (param) {
                                      return Curry._1(setActiveTab, (function (param) {
                                                    return /* FreqAcrossSamples */0;
                                                  }));
                                    })
                                }, ReasonReactHelpers$Astrolabe.str("Frequency Across Samples"))), React.createElement("div", {
                              className: "pl-4"
                            }, React.createElement("span", {
                                  className: activeTab === /* FreqPerSample */1 ? "text-info cursor-default" : "cursor-pointer",
                                  title: "Show the Frequency Heat Map, which includes the frequency of each cell subset across all samples.",
                                  onClick: (function (param) {
                                      return Curry._1(setActiveTab, (function (param) {
                                                    return /* FreqPerSample */1;
                                                  }));
                                    })
                                }, ReasonReactHelpers$Astrolabe.str("Frequency by Sample"))))), activeTab ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "d-flex flex-column align-items-end pl-5"
                          }, React.createElement("label", {
                                title: "Scale the subset frequencies across samples. Scaled values can be compared for a given subset (column) between samples, but not across subsets."
                              }, ReasonReactHelpers$Astrolabe.str("Scale by Subset")), React.createElement("div", {
                                className: "d-flex justify-content-end"
                              }, React.createElement("button", {
                                    className: (
                                      scaled ? "btn-primary" : "btn-outline-primary"
                                    ) + " btn-sm ml-2",
                                    onClick: handleScaledChange
                                  }, ReasonReactHelpers$Astrolabe.str("ON")), React.createElement("button", {
                                    className: (
                                      scaled ? "btn-outline-primary" : "btn-primary"
                                    ) + " btn-sm ml-2",
                                    onClick: handleScaledChange
                                  }, ReasonReactHelpers$Astrolabe.str("OFF")))), React.createElement(SortByDropdown$Astrolabe.make, {
                            handleSortChange: (function (e) {
                                return Curry._2(onColumnSortChange, ReasonReactHelpers$Astrolabe.valueFromEvent(e), columnSortAscending);
                              }),
                            klass: "pl-5",
                            label: "Sort Subsets by",
                            onSortClick: handleColumnSortDirClick,
                            selectOptions: [
                              React.createElement("option", {
                                    key: "alphanumeric",
                                    value: "alphanumeric"
                                  }, ReasonReactHelpers$Astrolabe.str("Subset Name")),
                              React.createElement("option", {
                                    key: "hierarchical",
                                    value: "hierarchical"
                                  }, ReasonReactHelpers$Astrolabe.str("Hierarchical Clustering")),
                              React.createElement("option", {
                                    key: "mean_frequency",
                                    value: "mean_frequency"
                                  }, ReasonReactHelpers$Astrolabe.str("Mean Frequency"))
                            ],
                            sortAscending: columnSortAscending,
                            valueSort: columnSort,
                            width: "190px"
                          }), React.createElement(SortByDropdown$Astrolabe.make, {
                            handleSortChange: (function (e) {
                                return Curry._2(onRowSortChange, ReasonReactHelpers$Astrolabe.valueFromEvent(e), rowSortAscending);
                              }),
                            klass: "pl-5",
                            label: "Sort Samples by",
                            onSortClick: handleRowSortDirClick,
                            selectOptions: sortRowOptions,
                            sortAscending: rowSortAscending,
                            tooltip: "Set the order of the samples (rows) of the heat map. This dropdown is synchronized across the page.",
                            valueSort: rowSort,
                            width: "190px"
                          })) : React.createElement("div", {
                        className: "d-flex flex-column align-items-end"
                      }, React.createElement("label", undefined, ReasonReactHelpers$Astrolabe.str(match$1[0])), React.createElement("select", {
                            className: "form-control-sm",
                            style: {
                              width: "190px"
                            },
                            value: mdsColorBy,
                            onChange: (function (e) {
                                return Curry._1(onMDSColorByChange, ReasonReactHelpers$Astrolabe.valueFromEvent(e));
                              })
                          }, match$1[1]))), activeTab ? React.createElement("div", undefined, heatMap) : React.createElement("div", {
                    className: "d-flex justify-content-center flex-wrap"
                  }, differentialAbundanceOn ? volcanoPlot : null, mdsMap));
}

var make = CellSubsetOverview;

var $$default = CellSubsetOverview;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
