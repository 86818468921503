// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function DropdownMenu$Item(Props) {
  var className = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  return React.createElement("button", {
              className: "dropdown-item " + className,
              type: "button",
              onClick: onClick
            }, children);
}

var Item = {
  make: DropdownMenu$Item
};

function DropdownMenu(Props) {
  var rightAlignmentOpt = Props.rightAlignment;
  var className = Props.className;
  var dropdownClassNameOpt = Props.dropdownClassName;
  var text = Props.text;
  var titleOpt = Props.title;
  var children = Props.children;
  var rightAlignment = rightAlignmentOpt !== undefined ? rightAlignmentOpt : false;
  var dropdownClassName = dropdownClassNameOpt !== undefined ? dropdownClassNameOpt : "";
  var title = titleOpt !== undefined ? titleOpt : "";
  var dropdownAlignment = rightAlignment ? " dropdown-menu-right" : "";
  return React.createElement("div", {
              className: "dropdown",
              title: title
            }, React.createElement(ReasonReactHelpers$Astrolabe.SpreadProps.make, {
                  props: {
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true"
                  },
                  children: React.createElement("button", {
                        "aria-expanded": true,
                        className: "btn dropdown-toggle " + className,
                        id: "dropdownMenu2",
                        type: "button"
                      }, text)
                }), React.createElement("div", {
                  "aria-labelledby": "dropdownMenu2",
                  className: "dropdown-menu " + (dropdownClassName + (" " + dropdownAlignment))
                }, children));
}

var make = DropdownMenu;

exports.Item = Item;
exports.make = make;
/* react Not a pure module */
