// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var NivoHeatmap = require("../vendor/nivo-heatmap");

var make = NivoHeatmap.ResponsiveHeatMapCanvas;

var Dict;

var $$default = make;

exports.Dict = Dict;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* make Not a pure module */
