/* @flow */
"use strict";

import React, { Fragment } from "react";
import type { Match } from "react-router";
import { List, Map, OrderedSet } from "immutable";
import type { Api } from "./ExperimentProvider";
import { NavLink } from "react-router-dom";
import type { Session } from "models";
import {
  CellSubsetLevels,
  ChannelUsage,
  DataTable,
  Experiment,
  ExperimentDifferentialAbundance,
  ExperimentStatistics,
  Feature,
  Sample,
} from "models";
import { capitalize } from "../../utils.js";
import CSNBarCharts from "./CSNBarCharts.bs";
import CellSubsetNav from "./CellSubsetNav.bs";
import CellSubsetOverview from "./CellSubsetOverview.bs";
import DataTableHeatMap from "components/DataTableHeatMap.bs";
import MDSMap from "./MDSMap.bs";
import MDSData from "./MDSData.bs";
import VolcanoScatterPlot from "./VolcanoScatterPlot";
import GraphDownloadButton, { canvasGraphType, svgGraphType } from "../../GraphDownloadButton.bs";
import { ScrollSync } from "react-scroll-sync";
import { redWhiteBlue, purples } from "../../../models/Theme.bs.js";
import ReUtils from "../../../models/Util.bs";
import ExperimentHeader from "../../header/ExperimentHeader.bs";
import NotificationMessage from "../../shared/NotificationMessage.bs";

type Props = {
  api: Api,
  cellSubsetLevels: CellSubsetLevels,
  channelUsages: List<ChannelUsage>,
  errors: List<string>,
  differentialAbundanceData: { [string]: ?ExperimentDifferentialAbundance },
  experiment: Experiment,
  experimentStatistics: { [string]: ?ExperimentStatistics },
  features: List<Feature>,
  graphForDownload: ?React$ElementRef<*>,
  history: History,
  match: Match,
  renderTabs: React$ElementRef,
  samples: List<Sample>,
  session: Session,
};

type State = {
  analysisType: string,
  animate: boolean,
  columnSort: string,
  columnSortAscending: boolean,
  differentialAbundance: string,
  displaySubsetHint: boolean,
  experimentStatistics: { [string]: ?ExperimentStatistics },
  mdsData: { [string]: any },
  mdsColorBy: string,
  rowSort: string,
  rowSortAscending: boolean,
  scaled: boolean,
  selectedColumnLabels: OrderedSet<string>,
  selectedColumnIndexes: OrderedSet<integer>,
  showFrequencyHeatMap: boolean,
  showImmunoHeatMaps: boolean,
};

const LARGE_EXPERIMENT_THRESHOLD = 500;

const MDS_ENABLED = true;
const MDS_MAP_PATH = "mds_map";
const FREQUENCY_HEAT_MAP_PATH = "frequency_heat_map";
const TABS = [
  { label: "MDS Map", path: MDS_MAP_PATH },
  { label: "Frequency Heat Map", path: FREQUENCY_HEAT_MAP_PATH },
];

export default class CellSubsetNavigator extends React.Component<Props, State> {
  addSelectedColumnLabelIndexes: (selectedSubsets: OrderedSet<string>) => any;
  handleAnalysisTypeChange: (CellSubsetNavigatorAnalysisType) => any;
  handleDifferentialAbundanceChange: (string) => any;
  handleRowSortChange: (rowSort: string, rowSortAscending: boolean) => any;
  handleSelectedColumnLabelsChange: (selectedSubsets: OrderedSet<string>) => any;
  handleCellSubsetClick: (string) => any;
  heatMap: ?React$ElementRef<*>;
  freqHeatMap: ?React$ElementRef<*>;
  removeSelectedColumnLabelIndexes: (selectedSubsets: OrderedSet<string>) => any;
  volcanoScatterPlot: ?React$ElementRef<*>;

  constructor(props: Props) {
    super(props);

    this.state = this.initialState();
    this.cellSubsetColors = undefined;
  }

  defaultSort(): Object {
    return {
      columnSort: "alphanumeric",
      columnSortAscending: true,
      rowSort: "alphanumeric",
      rowSortAscending: true,
    };
  }

  initialState(): State {
    return {
      analysisType: null,
      animate: false,
      differentialAbundance: "none",
      displaySubsetHint: false,
      errors: null,
      experimentStatistics: {},
      mdsData: {},
      mdsColorBy: "",
      scaled: false,
      selectedColumnIndexes: OrderedSet(),
      selectedColumnLabels: OrderedSet(),
      showFrequencyHeatMap: true,
      showImmunoHeatMaps: true,
      ...this.defaultSort(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  static isLargeExperiment(props: Props) {
    return props.samples.size > LARGE_EXPERIMENT_THRESHOLD;
  }

  static getDerivedStateFromProps(nextProps: Props, state: State) {
    let nextState: $Shape<State> = {};

    if (nextProps.experimentStatistics !== state.experimentStatistics) {
      nextState = {
        experimentStatistics: nextProps.experimentStatistics,
        analysisType: nextProps.cellSubsetLevels.default,
      };
    }

    if (CellSubsetNavigator.isLargeExperiment(nextProps)) {
      nextState.showFrequencyHeatMap = false;
      nextState.showImmunoHeatMaps = false;
    }

    return nextState;
  }

  dataTable(analysisType: CellSubsetNavigatorAnalysisType, scaled: boolean): DataTable {
    if (!this.props.experimentStatistics[analysisType]) {
      return DataTable.empty();
    }

    const experimentStatistics = this.props.experimentStatistics[analysisType];
    var dataTable = experimentStatistics.dataTable({ scaled });

    if (this.state.columnSort) {
      const columnSortOrder = experimentStatistics.suggested_order.cell_subset_order[this.state.columnSort];
      dataTable = dataTable.select(this.state.columnSortAscending ? columnSortOrder : [...columnSortOrder].reverse());
    }

    if (this.state.rowSort) {
      if (this.state.rowSort === "alphanumeric") {
        dataTable = dataTable.sortByRowLabel(this.state.rowSortAscending);
      } else {
        let rowSortOrder = experimentStatistics.suggested_order.sample_name_order[this.state.rowSort];
        dataTable = dataTable.applySort(rowSortOrder, this.state.rowSortAscending);
      }
    }

    const selectedFeature = this.selectedFeature();
    if (selectedFeature && selectedFeature.get("is_allow_na")) {
      const samplesByName = this.props.samples.groupBy((s) => s.get("name")).map((vs) => vs.get(0));

      dataTable = dataTable.filterByRowLabel((rowLabel) => !samplesByName.get(rowLabel).featureValueIsNA(selectedFeature));
    }

    return dataTable;
  }

  async fetchData() {
    await this.props.api.experiments.fetchCellSubsetLevels();
    this.props.api.experiments.fetchDifferentialAbundanceData();
    this.props.api.experiments.fetchAggregateStatisticsData();

    MDSData.fetchData(this.props.experiment.id, (mdsData) => {
      this.setState({ mdsData });
    });
  }

  handleAnalysisTypeChange = (analysisType: CellSubsetNavigatorAnalysisType) => {
    if (analysisType === this.state.analysisType) return;
    const selectedColumnLabels = OrderedSet(
      this.state.selectedColumnIndexes.map((i) => this.cellSubsetMapForAnalysis(analysisType)[i]),
    );
    this.setState({ analysisType, selectedColumnLabels });
  };

  handleDifferentialAbundanceChange = (differentialAbundance: string) => {
    this.setState({ differentialAbundance });

    if (differentialAbundance === "none") {
      this.setState({ mdsColorBy: "" });
    } else {
      this.setState({ mdsColorBy: this.state.mdsColorBy === "negLog10FDR" ? "negLog10FDR" : "logFC" });
    }
  };

  handleRowSortChange = (rowSort: string, rowSortAscending: boolean) => {
    this.setState({
      rowSort,
      rowSortAscending,
    });
  };

  cellSubsetMapForAnalysis = (analysisType) => {
    return this.props.cellSubsetLevels.cell_subset_map[capitalize(analysisType)];
  };

  addSelectedColumnLabelIndexes = (selectedColumnLabels: OrderedSet<string>) => {
    // this reduce will get indexes even if there are duplicate labels in the cell subset maps, so there can be more indexes than labels
    return OrderedSet(
      this.cellSubsetMapForAnalysis(this.state.analysisType).reduce(
        (acc, subsetLevel, i) => (selectedColumnLabels.includes(subsetLevel) ? [...acc, i] : acc),
        [...this.state.selectedColumnIndexes],
      ),
    );
  };

  removeSelectedColumnLabelIndexes = (selectedColumnLabels: OrderedSet<string>) => {
    // this reduce will get indexes even if there are duplicate labels in the cell subset maps, so there can be more indexes than labels
    const indexesToRemove = this.cellSubsetMapForAnalysis(this.state.analysisType).reduce(
      (acc, subsetLevel, i) => (selectedColumnLabels.includes(subsetLevel) ? [...acc, i] : acc),
      [],
    );
    return OrderedSet(this.state.selectedColumnIndexes.filter((i) => !indexesToRemove.includes(i)));
  };

  handleSelectedColumnLabelsChange = (selectedColumnLabels: OrderedSet<string>) => {
    const selectedColumnIndexes = this.addSelectedColumnLabelIndexes(selectedColumnLabels);

    this.handleDisplayHint({ animate: false, selectedColumnLabels, selectedColumnIndexes });

    this.setState({ animate: true });
  };

  handleDisplayHint = (newState) => {
    /* If it's the first subset being clicked display the disappearing hint */
    if (this.state.selectedColumnLabels.size < 1) {
      this.setState({
        displaySubsetHint: true,
        ...newState,
      });

      setTimeout(() => {
        this.setState({ displaySubsetHint: false });
      }, 3000);
    } else {
      this.setState(newState);
    }
  };

  handleCellSubsetClick = (label: string) => {
    const selectedColumnLabels = this.state.selectedColumnLabels.contains(label)
      ? this.state.selectedColumnLabels.delete(label)
      : this.state.selectedColumnLabels.add(label);

    const selectedColumnIndexes = this.state.selectedColumnLabels.contains(label)
      ? this.removeSelectedColumnLabelIndexes([label])
      : this.addSelectedColumnLabelIndexes([label]);

    this.handleDisplayHint({ selectedColumnLabels, selectedColumnIndexes });
  };

  handleCloseMultipleSubsets = (subsetLabels: List<string>) => {
    const selectedColumnLabels = OrderedSet(this.state.selectedColumnLabels.filter((subset) => !subsetLabels.includes(subset)));
    const selectedColumnIndexes = this.removeSelectedColumnLabelIndexes(subsetLabels);
    this.setState({ selectedColumnLabels, selectedColumnIndexes });
  };

  handleOpenMultipleSubsets = (subsetLabels: List<string>) => {
    const selectedColumnLabels = OrderedSet(subsetLabels);
    const selectedColumnIndexes = this.addSelectedColumnLabelIndexes(subsetLabels);

    this.handleDisplayHint({ selectedColumnLabels, selectedColumnIndexes });
  };

  selectedFeature(state?: State): ?Feature {
    state = state || this.state;
    if (state.differentialAbundance === "none") {
      return null;
    }

    const id = parseInt(state.differentialAbundance.replace("feature_", ""));
    if (Number.isNaN(id)) {
      return null;
    } else {
      return this.props.features.find((f) => f.id === id);
    }
  }

  sortColumns(dataTable: DataTable, key: string, ascending: boolean): DataTable {
    if (!this.props.experimentStatistics[this.state.analysisType]) {
      return dataTable;
    }
    const sortOrder = this.props.experimentStatistics[this.state.analysisType].suggested_order.cell_subset_order[key];
    return dataTable.select(ascending ? sortOrder : [...sortOrder].reverse());
  }

  sortRowOptions() {
    return [
      <option key="alphanumeric" value="alphanumeric">
        Sample Name
      </option>,
      <option key="hierarchical" value="hierarchical">
        Hierarchical Clustering
      </option>,
    ].concat(
      this.props.features.map((f, i) => {
        if (this.props.feautureFlagEnabled) {
          return (
            <option key={i} value={`feature_${f.get("id")}`}>
              {f.get("name")}
            </option>
          );
        } else {
          return (
            <option key={i} value={`feature_${f.get("id")}`}>
              Feature: {f.get("name")}
            </option>
          );
        }
      }),
    );
  }

  sortRows(dataTable: DataTable, key: string, ascending: boolean): DataTable {
    if (!this.props.experimentStatistics[this.state.analysisType]) {
      return dataTable;
    }
    const sortOrder = this.props.experimentStatistics[this.state.analysisType].suggested_order.sample_name_order[key];
    return dataTable.applySort(sortOrder, ascending);
  }

  renderCellSubsetCharts(dataTable: DataTable) {
    return (
      <ScrollSync>
        <CSNBarCharts
          cellSubsetFrequencyDataTable={dataTable}
          closeCellSubset={this.handleCellSubsetClick}
          convertPercentages={!this.state.scaled}
          differentialAbundanceData={
            this.props.differentialAbundanceData[this.state.analysisType] || new ExperimentDifferentialAbundance({})
          }
          experimentName={this.props.experiment.name}
          experimentStatistics={this.props.experimentStatistics[this.state.analysisType]}
          handleRowSortDirClick={() => this.handleRowSortChange(this.state.rowSort, !this.state.rowSortAscending)}
          onRowSortChange={this.handleRowSortChange}
          rowSort={this.state.rowSort}
          rowSortAscending={this.state.rowSortAscending}
          samples={this.props.samples.toArray()}
          selectedColumnLabels={this.state.selectedColumnLabels.toJS()}
          selectedFeature={this.selectedFeature()}
          showBarCharts={!this.constructor.isLargeExperiment(this.props)}
          showImmunoHeatMaps={this.state.showImmunoHeatMaps}
          sortRowOptions={this.sortRowOptions()}
        />
      </ScrollSync>
    );
  }

  renderFrequencyHeatMap(dataTable: DataTable) {
    if (this.constructor.isLargeExperiment(this.props)) {
      return (
        <div className={`alert alert-warning text-center`} role="alert">
          <p>
            The experiment has more than {LARGE_EXPERIMENT_THRESHOLD} samples and some figures are not available online.
            <br />
            Download the Reports exports for the hidden figures.
          </p>
          <p className="mb-0">
            Please contact us at <a href="mailto:support@astrolabediagnostics.com">support@astrolabediagnostics.com</a> if you
            have any questions.
          </p>
        </div>
      );
    }

    if (!this.state.showFrequencyHeatMap) {
      return null;
    }

    const re_selectedColumnLabels = ReUtils.js_reasonSetFromImmutableJsOrderedSet(this.state.selectedColumnLabels)

    return (
      <div ref={(el) => (this.freqHeatMap = el)}>
        <div className="col-12">
          <GraphDownloadButton
            prefix={this.props.experiment.name}
            name="Heat Map"
            container={() => this.freqHeatMap}
            graphType={canvasGraphType}
            filenames="Heat Map"
          />
          <div className={"col-12 horizontal-scroll vertical-scroll"}>
            <DataTableHeatMap
              colors={this.state.scaled ? redWhiteBlue : purples}
              dataTable={dataTable}
              legendTitle={this.state.scaled ? "Scaled Frequency" : "Frequency"}
              symmetricLegendValues={this.state.scaled}
              convertValuesToPercentages={!this.state.scaled}
              id={"csn-freq-heatmap"}
              onSelectColumnLabel={this.handleCellSubsetClick}
              selectedColumnLabels={re_selectedColumnLabels}
            />
          </div>
        </div>
      </div>
    );
  }

  renderMDSMap() {
    if (!this.state.mdsData[this.state.analysisType]) {
      return null;
    }
    return (
      <div ref={(el) => (this.heatMap = el)}>
        <MDSMap
          colorBy={this.state.mdsColorBy}
          differentialAbundance={this.state.differentialAbundance}
          experimentName={this.props.experiment.name}
          mdsData={this.state.mdsData[this.state.analysisType]}
          onClick={this.handleCellSubsetClick}
          selectedLabels={this.state.selectedColumnLabels}
        />
      </div>
    );
  }

  getCellSubsetColors = () => {
    let mdsDataForLevel = this.state.mdsData[this.state.analysisType];

    let subsetMDSData =
      this.props.colorBy === ""
        ? MDSData.defaultMapData(mdsDataForLevel)
        : MDSData.getMapData(this.state.mdsColorBy, this.state.differentialAbundance, mdsDataForLevel);

    this.cellSubsetColors = MDSData.cellSubsetColors(subsetMDSData);

    return this.cellSubsetColors;
  };

  getCellSubsetColor = (subsetName) => {
    return this.cellSubsetColors ? this.cellSubsetColors[subsetName] : this.getCellSubsetColors()[subsetName];
  };

  renderVolcanoPlot(dataTable: DataTable) {
    const feature = this.selectedFeature();

    if (!feature) {
      return null;
    }

    if (!this.props.differentialAbundanceData[this.state.analysisType]) {
      return (
        <div className="col-12">
          <h3>Loading…</h3>
        </div>
      );
    }

    const diffData = this.props.differentialAbundanceData[this.state.analysisType] || new ExperimentDifferentialAbundance({});
    const { selectedColumnLabels } = this.state;

    if (!diffData.hasData(feature)) {
      return this.renderChartError(
        "Unable to run differential abundance analysis for this feature since each feature value only appears once",
      );
    }

    const diffDataTable = diffData.dataTable(feature, dataTable.columnLabels);
    const xAxisLabel = diffDataTable.rowLabels[1];
    const yAxisLabel = diffDataTable.rowLabels[0];

    // Separate cell subsets to selected and rest.
    var dataSelected = [];
    var dataRest = [];
    diffDataTable
      .transpose()
      .toOrderedJSONRows()
      .forEach(function (row) {
        const hash = {
          x: row[xAxisLabel],
          y: row[yAxisLabel],
          label: `${row.rowLabel} \n -log10(FDR): ${row[yAxisLabel].toFixed(2)} | log(FC): ${row[xAxisLabel].toFixed(
            2,
          )} \n Click to select`,
          name: row.rowLabel,
        };

        if (selectedColumnLabels.contains(hash.name)) {
          dataSelected.push(hash);
        } else {
          dataRest.push(hash);
        }
      });

    return (
      <div className="row">
        <div className="col-12" ref={(el) => (this.volcanoScatterPlot = el)}>
          <GraphDownloadButton
            prefix={this.props.experiment.name}
            name="Volcano Plot"
            graphType={svgGraphType}
            container={() => this.volcanoScatterPlot}
            filenames={`${this.state.analysisType}_${feature.get("name")}`}
          />
          <VolcanoScatterPlot
            data={[dataRest, dataSelected]}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            onDataClick={this.handleCellSubsetClick}
            height={500}
            width={450}
          />
        </div>
      </div>
    );
  }

  renderChartError(message: string) {
    return (
      <div className="col-12">
        <div className="col-12 alert alert-danger mt-4 text-center" role="alert">
          {message}
        </div>
      </div>
    );
  }

  renderTabs(props: Props) {
    if (!MDS_ENABLED || !state.mdsData[this.state.analysisType]) {
      return null;
    }

    return (
      <ul className="nav nav-pills justify-content-center mb-3">
        {TABS.map((tab, i) => {
          return (
            <li key={i} className="nav-item">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={`/experiments/${props.experiment.id}/cell_subset_navigator/${tab.path}`}
                isActive={(match, location) => {
                  if (match) {
                    return true;
                  } else if (i === 0) {
                    return location.pathname.match(new RegExp(`/experiments/${props.experiment.id}/cell_subset_navigator/?$`));
                  }
                }}
              >
                {tab.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    if (!this.props.experimentStatistics[this.state.analysisType]) {
      return (
        <div className="row">
          <div className="col-12">
            <h3>Loading…</h3>
          </div>
        </div>
      );
    }

    const dataTableFreqHeatMap = this.dataTable(this.state.analysisType, this.state.scaled);
    const dataTableSubset = this.state.scaled ? this.dataTable(this.state.analysisType, false) : dataTableFreqHeatMap;
    const cellSubsetLevels = this.props.cellSubsetLevels.all;

    const cellSubsets =
      this.state.mdsData[this.state.analysisType] && MDSData.cellSubsets(this.state.mdsData[this.state.analysisType]);

    const chartType: "mds" | "frequency" =
      MDS_ENABLED && this.state.mdsData[this.state.analysisType] && (this.props.match.params.tab || MDS_MAP_PATH) === MDS_MAP_PATH
        ? "mds"
        : "frequency";

    let volcanoPlot = null;

    if (this.state.differentialAbundance !== "none") {
      volcanoPlot = this.renderVolcanoPlot(dataTableSubset);
    }

    return (
      <React.Fragment>
        <ExperimentHeader experimentId={this.props.experiment.id}>{this.props.renderTabs}</ExperimentHeader>
        <CellSubsetNav
          cellSubsets={cellSubsets || []}
          cellSubsetClick={this.handleCellSubsetClick}
          cellSubsetLevels={cellSubsetLevels}
          differentialAbundance={this.state.differentialAbundance}
          features={this.props.features.valueSeq().toArray()}
          getCellSubsetColor={this.getCellSubsetColor}
          handleCloseMultipleSubsets={this.handleCloseMultipleSubsets}
          handleOpenMultipleSubsets={this.handleOpenMultipleSubsets}
          handleDifferentialAbundanceChange={this.handleDifferentialAbundanceChange}
          onSubsetLevelChange={this.handleAnalysisTypeChange}
          selectedFeature={this.selectedFeature()}
          selectedSubsets={this.state.selectedColumnLabels.toJS()}
          subsetLevel={this.state.analysisType}
        >
          <CellSubsetOverview
            channelDescs={this.props.channelUsages
              .filter((cu) => cu.usage === "analysis" || cu.usage == "classification")
              .map((cu) => cu.desc)
              .toArray()
              .sort()}
            columnSort={this.state.columnSort}
            columnSortAscending={this.state.columnSortAscending}
            differentialAbundance={this.state.differentialAbundance}
            onColumnSortChange={(columnSort, columnSortAscending) => this.setState({ columnSort, columnSortAscending })}
            onRowSortChange={this.handleRowSortChange}
            onScaledChange={(scaled) => this.setState({ scaled })}
            handleRowSortDirClick={() => this.handleRowSortChange(this.state.rowSort, !this.state.rowSortAscending)}
            heatMap={this.renderFrequencyHeatMap(dataTableFreqHeatMap)}
            mdsColorBy={this.state.mdsColorBy}
            mdsMap={this.renderMDSMap()}
            onMDSColorByChange={(mdsColorBy) => this.setState({ mdsColorBy })}
            rowSort={this.state.rowSort}
            rowSortAscending={this.state.rowSortAscending}
            scaled={this.state.scaled}
            sortRowOptions={this.sortRowOptions()}
            subsetLevel={this.state.analysisType}
            volcanoPlot={volcanoPlot}
          />
          {this.state.displaySubsetHint && (
            <NotificationMessage className="disappearing-toast text-center fixed-bottom">
              <h4> Scroll down for new figures </h4>
            </NotificationMessage>
          )}

          <div className="row">{this.renderCellSubsetCharts(dataTableSubset)}</div>
        </CellSubsetNav>
      </React.Fragment>
    );
  }
}
