// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Fetch = require("bs-fetch/src/Fetch.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");

var lazyCsrfToken = Caml_obj.caml_lazy_make((function (param) {
        return (window.csrfToken || "");
      }));

function getString(endpoint) {
  return fetch(endpoint, Fetch.RequestInit.make(/* Get */0, {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": CamlinternalLazy.force(lazyCsrfToken)
                    }, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then((function (prim) {
                return prim.text();
              }));
}

function getJson(endpoint) {
  return fetch(endpoint, Fetch.RequestInit.make(/* Get */0, {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": CamlinternalLazy.force(lazyCsrfToken)
                    }, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then((function (prim) {
                return prim.json();
              }));
}

function getHtml(endpoint) {
  return fetch(endpoint, Fetch.RequestInit.make(/* Get */0, {
                      Accept: "text/html",
                      "Content-Type": "text/html",
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": CamlinternalLazy.force(lazyCsrfToken)
                    }, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then((function (prim) {
                return prim.text();
              }));
}

function patch(endpoint, bodyFormData) {
  return fetch(endpoint, Fetch.RequestInit.make(/* Patch */8, {
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": CamlinternalLazy.force(lazyCsrfToken)
                    }, Caml_option.some(bodyFormData), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then((function (prim) {
                return prim.text();
              }));
}

function post(endpoint, bodyFormData) {
  return fetch(endpoint, Fetch.RequestInit.make(/* Post */2, {
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": CamlinternalLazy.force(lazyCsrfToken)
                    }, Caml_option.some(bodyFormData), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then((function (prim) {
                return prim.text();
              }));
}

var $$FormData = { };

function success(resp) {
  return resp.success;
}

function errors(resp) {
  return resp.errors;
}

function success$1(json) {
  return Json_decode.field("success", Json_decode.bool, json);
}

function errors$1(json) {
  return Json_decode.field("errors", (function (param) {
                return Json_decode.array(Json_decode.string, param);
              }), json);
}

var Decode = {
  success: success$1,
  errors: errors$1
};

var $$Response = {
  success: success,
  errors: errors,
  Decode: Decode
};

exports.lazyCsrfToken = lazyCsrfToken;
exports.getString = getString;
exports.getJson = getJson;
exports.getHtml = getHtml;
exports.patch = patch;
exports.post = post;
exports.$$FormData = $$FormData;
exports.$$Response = $$Response;
/* No side effect */
