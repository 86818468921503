// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function equal(t1, t2) {
  if (t1.name === t2.name && t1.label === t2.label) {
    return t1.description === t2.description;
  } else {
    return false;
  }
}

function idToString(value) {
  if (value !== undefined) {
    return String(Caml_option.valFromOption(value));
  } else {
    return "";
  }
}

function pctToString(value) {
  if (value !== undefined) {
    return (value * 100).toFixed(1) + "%";
  } else {
    return "N/A";
  }
}

var all = List.map((function (param) {
        return {
                name: param[0],
                label: param[1],
                description: param[2],
                toString: param[3]
              };
      }), /* :: */[
      /* tuple */[
        "total",
        "Total Count",
        "Number of events in the FCS file",
        idToString
      ],
      /* :: */[
        /* tuple */[
          "per_debris",
          "% Debris",
          "Percent debris (of total events)",
          pctToString
        ],
        /* :: */[
          /* tuple */[
            "per_root_unassigned",
            "% Unassigned",
            "Percent unassigned (of total events)",
            pctToString
          ],
          /* :: */[
            /* tuple */[
              "bead",
              "Bead Count",
              "Number of normalization beads detected by Astrolabe",
              idToString
            ],
            /* :: */[
              /* tuple */[
                "per_beads",
                "% Beads",
                "Percent beads (of total events)",
                pctToString
              ],
              /* :: */[
                /* tuple */[
                  "cell",
                  "Cell Count",
                  "Number of events classified as cells by Astrolabe (live, non-debris, non-bead)",
                  idToString
                ],
                /* :: */[
                  /* tuple */[
                    "oxidation_ratio",
                    "Oxidation Ratio",
                    "Oxidation ratio (Gd156 / Ce140)",
                    idToString
                  ],
                  /* :: */[
                    /* tuple */[
                      "stability",
                      "Signal Stability",
                      "Are any bead channels unstable over time?",
                      idToString
                    ],
                    /* :: */[
                      /* tuple */[
                        "cell_subset_frequency",
                        "Missing Subsets",
                        "Are any canonical subsets missing?",
                        idToString
                      ],
                      /* :: */[
                        /* tuple */[
                          "aof",
                          "AOF",
                          "Do any channels have a high Average Overlap Frequency (AOF)?",
                          idToString
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Type = {
  equal: equal,
  idToString: idToString,
  pctToString: pctToString,
  all: all
};

function equal$1(m1, m2) {
  if (m1.name === m2.name && equal(m1.metricType, m2.metricType) && Caml_obj.caml_equal(m1.value, m2.value)) {
    return Caml_obj.caml_equal(m1.desc, m2.desc);
  } else {
    return false;
  }
}

function sampleQcMetric(json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          metricType: List.find((function (mt) {
                  return mt.name === name;
                }), all),
          value: Json_decode.field("value", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          desc: Json_decode.field("desc", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

var Decode = {
  sampleQcMetric: sampleQcMetric
};

function sampleQcMetric$1(self) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                self.name
              ],
              /* :: */[
                /* tuple */[
                  "value",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), self.value)
                ],
                /* :: */[
                  /* tuple */[
                    "desc",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), self.desc)
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var Encode = {
  J: undefined,
  sampleQcMetric: sampleQcMetric$1
};

exports.Type = Type;
exports.equal = equal$1;
exports.Decode = Decode;
exports.Encode = Encode;
/* all Not a pure module */
