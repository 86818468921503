// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Channel$Astrolabe = require("../../../models/Channel.bs.js");
var DisplayErrors$Astrolabe = require("../../shared/DisplayErrors.bs.js");
var FocusOutInput$Astrolabe = require("../../shared/FocusOutInput.bs.js");
var ChannelUsageColumn$Astrolabe = require("./ChannelUsageColumn.bs.js");

var ppx_printed_query = "mutation UpdateChannel($id: ID!, $desc: String)  {\nupdateChannel(id: $id, desc: $desc)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateChannel");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ChannelPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateChannel: tmp
        };
}

function make(id, desc, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "desc",
                    desc !== undefined ? desc : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  var desc = variables.desc;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "desc",
                    desc !== undefined ? desc : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, desc, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "desc",
                  desc !== undefined ? desc : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, desc, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "desc",
                      desc !== undefined ? desc : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var UpdateChannelConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UpdateChannelMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function ChannelRow(Props) {
  var channel = Props.channel;
  var isEditable = Props.isEditable;
  var isShowAnalysisColumn = Props.isShowAnalysisColumn;
  var onSuccess = Props.onSuccess;
  return React.createElement(UpdateChannelMutation.make, {
              children: (function (mutation, result) {
                  var data = result.data;
                  var errors;
                  if (data !== undefined) {
                    var updateChannel = Caml_option.valFromOption(data).updateChannel;
                    errors = updateChannel !== undefined ? Caml_option.valFromOption(updateChannel).errors : [];
                  } else {
                    errors = [];
                  }
                  var descInput = React.createElement(React.Fragment, undefined, React.createElement(FocusOutInput$Astrolabe.make, {
                            defaultText: Channel$Astrolabe.desc(channel),
                            className: isShowAnalysisColumn ? "col-8" : "col-6",
                            onFocusOut: (function (updatedDesc) {
                                Curry._4(mutation, Caml_option.some(make(channel.id, updatedDesc, undefined).variables), ["getExperiment"], undefined, undefined).then((function (resp) {
                                        if (typeof resp !== "number") {
                                          if (resp.tag) {
                                            var updateChannel = resp[0].updateChannel;
                                            if (updateChannel !== undefined) {
                                              var hasErrors = !Util$Astrolabe.$$Array.isEmpty(Caml_option.valFromOption(updateChannel).errors);
                                              Curry._2(onSuccess, hasErrors, false);
                                            }
                                            
                                          } else {
                                            console.log(resp[0]);
                                          }
                                        }
                                        return Promise.resolve(undefined);
                                      }));
                                
                              })
                          }), React.createElement(DisplayErrors$Astrolabe.make, {
                            errors: errors
                          }));
                  return React.createElement("tr", undefined, React.createElement("td", undefined, Channel$Astrolabe.name(channel)), React.createElement("td", undefined, isEditable ? descInput : Channel$Astrolabe.desc(channel)), React.createElement(ChannelUsageColumn$Astrolabe.make, {
                                  channel: channel,
                                  isEditable: isEditable,
                                  isShowAnalysisColumn: isShowAnalysisColumn,
                                  onSuccess: (function (param) {
                                      return Curry._2(onSuccess, false, true);
                                    })
                                }));
                })
            });
}

var make$1 = ChannelRow;

exports.UpdateChannelConfig = UpdateChannelConfig;
exports.UpdateChannelMutation = UpdateChannelMutation;
exports.make = make$1;
/* UpdateChannelMutation Not a pure module */
