// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Models = require("models");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Immutable = require("immutable");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Util$Astrolabe = require("./Util.bs.js");
var Feature$Astrolabe = require("./Feature.bs.js");
var FeatureValue$Astrolabe = require("./FeatureValue.bs.js");
var SampleQcMetric$Astrolabe = require("./SampleQcMetric.bs.js");

var na_value = "__NA__";

function id(self) {
  return self.id;
}

function name(self) {
  return self.name;
}

function set_name(self, x) {
  return {
          id: self.id,
          name: x,
          barcoding_channels: self.barcoding_channels,
          qc_metrics: self.qc_metrics,
          is_ignored_from_debarcoding: self.is_ignored_from_debarcoding,
          errors: self.errors,
          file_file_name: self.file_file_name,
          source_sample_file_name: self.source_sample_file_name,
          event_counts: self.event_counts,
          feature_values: self.feature_values
        };
}

function barcoding_channels(self) {
  return self.barcoding_channels;
}

function qc_metrics(self) {
  return self.qc_metrics;
}

function is_ignored_from_debarcoding(self) {
  return self.is_ignored_from_debarcoding;
}

function set_is_ignored_from_debarcoding(self, x) {
  return {
          id: self.id,
          name: self.name,
          barcoding_channels: self.barcoding_channels,
          qc_metrics: self.qc_metrics,
          is_ignored_from_debarcoding: x,
          errors: self.errors,
          file_file_name: self.file_file_name,
          source_sample_file_name: self.source_sample_file_name,
          event_counts: self.event_counts,
          feature_values: self.feature_values
        };
}

function errors(self) {
  return self.errors;
}

function featureValues(self) {
  return self.feature_values;
}

function file_file_name(self) {
  return self.file_file_name;
}

function fileFileName(self) {
  return self.file_file_name;
}

function set_file_file_name(self, x) {
  return {
          id: self.id,
          name: self.name,
          barcoding_channels: self.barcoding_channels,
          qc_metrics: self.qc_metrics,
          is_ignored_from_debarcoding: self.is_ignored_from_debarcoding,
          errors: self.errors,
          file_file_name: x,
          source_sample_file_name: self.source_sample_file_name,
          event_counts: self.event_counts,
          feature_values: self.feature_values
        };
}

function source_sample_file_name(self) {
  return self.source_sample_file_name;
}

function toJson(self) {
  var filename = self.source_sample_file_name;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Caml_format.caml_int_of_string(self.id)
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  self.name
                ],
                /* :: */[
                  /* tuple */[
                    "barcoding_channels",
                    Json_encode.array((function (prim) {
                            return prim;
                          }), self.barcoding_channels)
                  ],
                  /* :: */[
                    /* tuple */[
                      "qc_metrics",
                      Json_encode.array(SampleQcMetric$Astrolabe.Encode.sampleQcMetric, self.qc_metrics)
                    ],
                    /* :: */[
                      /* tuple */[
                        "is_ignored_from_debarcoding",
                        self.is_ignored_from_debarcoding
                      ],
                      /* :: */[
                        /* tuple */[
                          "errors",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), self.errors)
                        ],
                        /* :: */[
                          /* tuple */[
                            "file_file_name",
                            self.file_file_name
                          ],
                          /* :: */[
                            /* tuple */[
                              "source_sample_file_name",
                              filename !== undefined ? filename : null
                            ],
                            /* :: */[
                              /* tuple */[
                                "event_counts",
                                Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "total",
                                        self.event_counts.total
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "bead",
                                          self.event_counts.bead
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "cell",
                                            self.event_counts.cell
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              ],
                              /* :: */[
                                /* tuple */[
                                  "feature_values",
                                  Json_encode.array(FeatureValue$Astrolabe.toJson, self.feature_values)
                                ],
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function fromImmutableJs(imjs) {
  var get = function ($$default, field) {
    var x = imjs.get(field);
    if (x == null) {
      console.log("[WARNING] Could not get value for field " + field);
      return $$default;
    } else {
      return x;
    }
  };
  var values = get(Immutable.List([]), "feature_values");
  var values$1 = values.toArray();
  return {
          id: String(get(-1, "id")),
          name: get("Unnamed Sample", "name"),
          barcoding_channels: get([], "barcoding_channels"),
          qc_metrics: $$Array.map(SampleQcMetric$Astrolabe.Decode.sampleQcMetric, get([], "qc_metrics")),
          is_ignored_from_debarcoding: get(false, "is_ignored_from_debarcoding"),
          errors: get(Immutable.List([]), "errors").toArray(),
          file_file_name: get("untitled_file", "file_file_name"),
          source_sample_file_name: Caml_option.nullable_to_opt(imjs.get("source_sample_file_name")),
          event_counts: get({
                total: 0,
                bead: 0,
                cell: 0
              }, "event_counts"),
          feature_values: $$Array.map(FeatureValue$Astrolabe.Compat.fromImmutableJs, values$1)
        };
}

function toImmutableJs(self) {
  var j = toJson(self);
  ((j["errors"] = Immutable.List(j["errors"])));
  return new Models.Sample(j);
}

var Compat = {
  fromImmutableJs: fromImmutableJs,
  toImmutableJs: toImmutableJs
};

function featureValueForId(featureId, t) {
  return $$Array.fold_left((function (r, feature_value) {
                if (FeatureValue$Astrolabe.featureId(feature_value) === featureId) {
                  return FeatureValue$Astrolabe.value(feature_value);
                } else {
                  return r;
                }
              }), undefined, t.feature_values);
}

function featureValue(feature, t) {
  return featureValueForId(Feature$Astrolabe.id(feature), t);
}

function featureValueDisplay(feature, t) {
  var x = featureValueForId(Feature$Astrolabe.id(feature), t);
  if (x !== undefined) {
    if (x === na_value) {
      return "NA";
    } else {
      return x;
    }
  } else {
    return "";
  }
}

function featureValueIsNa(feature, t) {
  return Caml_obj.caml_equal(featureValueForId(Feature$Astrolabe.id(feature), t), na_value);
}

function findFeatureValueVal(featureId, self) {
  var featureVal = FeatureValue$Astrolabe.findBySampleAndFeature(featureId, self.id, self.feature_values);
  if (featureVal !== undefined) {
    return FeatureValue$Astrolabe.value(featureVal);
  } else {
    return "";
  }
}

function getEventCount(kind, t) {
  var event_counts = t.event_counts;
  switch (kind) {
    case "bead" :
        return event_counts.bead;
    case "cell" :
        return event_counts.cell;
    case "total" :
        return event_counts.total;
    default:
      console.log("[WARNING] Sample.getEventCount: invalid metric kind name: " + kind);
      return -1;
  }
}

function barcodingChannels(self) {
  return self.barcodingChannels;
}

function featureValues$1(self) {
  return Util$Astrolabe.or(self.featureValues, []);
}

function fileFileName$1(self) {
  return self.fileFileName;
}

function isIgnoredFromDebarcoding(self) {
  return self.isIgnoredFromDebarcoding;
}

function id$1(self) {
  return self.id;
}

function name$1(self) {
  return self.name;
}

function sourceSampleFileName(self) {
  return self.sourceSampleFileName;
}

function featureValueForId$1(featureId, sample) {
  return $$Array.fold_left((function (r, feature_value) {
                if (Caml_obj.caml_equal(FeatureValue$Astrolabe.Js.featureId(feature_value), featureId)) {
                  return Caml_option.some(FeatureValue$Astrolabe.Js.value(feature_value));
                } else {
                  return r;
                }
              }), undefined, featureValues$1(sample));
}

function findFeatureValueVal$1(featureId, sample) {
  var featureVal = FeatureValue$Astrolabe.Js.findBySampleAndFeature(featureId, sample.id, featureValues$1(sample));
  if (featureVal !== undefined) {
    return FeatureValue$Astrolabe.Js.value(Caml_option.valFromOption(featureVal));
  } else {
    return "";
  }
}

var Js = {
  barcodingChannels: barcodingChannels,
  featureValues: featureValues$1,
  fileFileName: fileFileName$1,
  id: id$1,
  isIgnoredFromDebarcoding: isIgnoredFromDebarcoding,
  name: name$1,
  sourceSampleFileName: sourceSampleFileName,
  featureValueForId: featureValueForId$1,
  findFeatureValueVal: findFeatureValueVal$1
};

exports.id = id;
exports.name = name;
exports.set_name = set_name;
exports.barcoding_channels = barcoding_channels;
exports.qc_metrics = qc_metrics;
exports.is_ignored_from_debarcoding = is_ignored_from_debarcoding;
exports.set_is_ignored_from_debarcoding = set_is_ignored_from_debarcoding;
exports.errors = errors;
exports.featureValues = featureValues;
exports.file_file_name = file_file_name;
exports.fileFileName = fileFileName;
exports.set_file_file_name = set_file_file_name;
exports.source_sample_file_name = source_sample_file_name;
exports.getEventCount = getEventCount;
exports.featureValue = featureValue;
exports.featureValueForId = featureValueForId;
exports.featureValueIsNa = featureValueIsNa;
exports.featureValueDisplay = featureValueDisplay;
exports.toJson = toJson;
exports.findFeatureValueVal = findFeatureValueVal;
exports.Compat = Compat;
exports.Js = Js;
/* models Not a pure module */
