// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Icons$Astrolabe = require("../Icons.bs.js");

function direction(asc) {
  if (asc) {
    return /* ASC */0;
  } else {
    return /* DSC */1;
  }
}

function toggleDirection(dir) {
  if (dir === /* ASC */0) {
    return /* DSC */1;
  } else {
    return /* ASC */0;
  }
}

function isAscending(dir) {
  return dir === /* ASC */0;
}

function sort(sortDirection, array) {
  return array.sort((function (a, b) {
                if (sortDirection) {
                  return Caml_obj.caml_compare(b, a);
                } else {
                  return Caml_obj.caml_compare(a, b);
                }
              }));
}

function withGetter(getter, sortDirection, array) {
  return array.sort((function (a, b) {
                return Caml_primitive.caml_string_compare(Curry._1(getter, sortDirection ? b : a).toLowerCase(), Curry._1(getter, sortDirection ? a : b).toLowerCase());
              }));
}

var Sort = {
  direction: direction,
  toggleDirection: toggleDirection,
  isAscending: isAscending,
  sort: sort,
  withGetter: withGetter
};

function SortableTableHeader(Props) {
  var columnName = Props.columnName;
  var filterOpt = Props.filter;
  var headerName = Props.headerName;
  var handleOnClick = Props.handleOnClick;
  var sortState = Props.sortState;
  var filter = filterOpt !== undefined ? Caml_option.valFromOption(filterOpt) : null;
  var arrowDown = React.createElement(Icons$Astrolabe.ArrowDown.make, {
        className: "mx-1 cursor-pointer",
        height: "13",
        width: "13"
      });
  var arrowUp = React.createElement(Icons$Astrolabe.ArrowUp.make, {
        className: "mx-1 cursor-pointer",
        height: "13",
        width: "13"
      });
  var arrowDownOnHover = React.createElement(Icons$Astrolabe.ArrowDown.make, {
        className: "mx-1 cursor-pointer show-on-hover",
        height: "13",
        width: "13"
      });
  var sortColumnName = sortState[0];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "hover-container d-flex"
                }, headerName, React.createElement("div", {
                      onClick: (function (param) {
                          var direction = sortState[0] === columnName ? toggleDirection(sortState[1]) : /* ASC */0;
                          return Curry._1(handleOnClick, /* tuple */[
                                      columnName,
                                      direction
                                    ]);
                        })
                    }, sortState[1] ? (
                        sortColumnName === columnName ? arrowUp : arrowDownOnHover
                      ) : (
                        sortColumnName === columnName ? arrowDown : arrowDownOnHover
                      ))), filter);
}

var $$Math;

var make = SortableTableHeader;

exports.$$Math = $$Math;
exports.Sort = Sort;
exports.make = make;
/* react Not a pure module */
