/* @flow */
"use strict";

/**
 * An array that contains information to map a position in an array to its unsorted position.
 */
type SortMap = Array<{ initialIndex: number, value: any }>;

export default class SortTemplate {
  map: SortMap;

  constructor(map: SortMap) {
    this.map = map;
  }

  static fromArray(array: Array<any>, sortFn?: (any, any) => -1 | 0 | 1): SortTemplate {
    const map = array
      .map((val, i) => {
        return { initialIndex: i, value: val };
      })
      .sort(sortFn || ((v1, v2) => v1.value - v2.value));
    return new SortTemplate(map);
  }

  /**
   * Given an array to sort, returns the array sorted in the same order as the source array.
   */
  sort(array: Array<any>, ascending?: boolean = true): Array<any> {
    const sorted = array.map((_value, index) => array[this.map[index].initialIndex]);
    return ascending ? sorted : sorted.reverse();
  }
}
