/* @flow */

import React from "react";
import type { Session, SessionUser } from "models";
import { Link } from "react-router-dom";

type Props = {
  onHide: () => any,
  onSignOut: () => any,
  session: Session,
};

function PlanDetails(props: Props) {
  if (!props.session.instance || !props.session.user || !props.session.user.is_admin) {
    return null;
  }
  let instance = props.session.instance;

  if (instance.max_samples != null) {
    return (
      <div className="nav-item navbar-text">
        <h5>Plan Details</h5>
        {instance.billed_sample_count} / {instance.max_samples} samples used (
        {instance.max_samples - instance.billed_sample_count} left)
        <br />
        {instance.contract_end_date ? `Ends on ${instance.contract_end_date}` : null}
      </div>
    );
  } else {
    return <li className="nav-item navbar-text">Samples: {instance.sample_count} / Unlimited (Pay-As-You-Go)</li>;
  }
}

function renderAdminItems(props: { onClick: () => any, user: SessionUser }) {
  if (!props.user.is_admin) {
    return null;
  }

  const adminLinks = [
    <li key="1" className="nav-item">
      <Link to="/admin/users" className="nav-link" onClick={props.onClick}>
        Manage Users
      </Link>
    </li>,
  ];

  if (props.user.is_staff) {
    adminLinks.push(
      <li key="2" className="nav-item">
        <Link to="/admin/instances" className="nav-link" onClick={props.onClick}>
          Manage Instances
        </Link>
      </li>,
    );
  }

  return adminLinks;
}

export default function NavMenu(props: Props) {
  if (!props.session.user) {
    return <div className="navbar-collapse collapse" />;
  }

  const user = props.session.user;
  const instance = props.session.instance;

  return (
    <div className="NavMenu navbar-collapse collapse show">
      <div className="row d-flex flex-row-reverse flex-sm-row space-between">
        <div className="col-12 col-sm-6">
          <PlanDetails {...props} />
        </div>

        <div className="col-12 col-sm-6">
          <ul className="navbar-nav text-sm-right">
            <li className="nav-item navbar-text">
              <h5>Your Account</h5>
              {user.email}
              <br />
              {(instance || {}).name || "No Instance"}
            </li>

            {renderAdminItems({ user, onClick: props.onHide })}

            <li className="nav-item">
              <a
                href="https://github.com/astrolabediagnostics/orloj/wiki/Astrolabe-Diagnostics-Tutorials"
                className="nav-link"
                target="_blank"
              >
                Tutorials
              </a>
            </li>

            <li className="nav-item">
              <a
                href="https://github.com/astrolabediagnostics/orloj/wiki/Astrolabe-Diagnostics-FAQ"
                className="nav-link"
                target="_blank"
              >
                FAQ
              </a>
            </li>

            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  props.onSignOut();
                  props.onHide();
                }}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
