// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../../models/Util.bs.js");
var Icons$Astrolabe = require("../../../Icons.bs.js");
var Feature$Astrolabe = require("../../../../models/Feature.bs.js");
var ErrorsAlert$Astrolabe = require("../../../ErrorsAlert.bs.js");
var FeatureMutations$Astrolabe = require("../FeatureMutations.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../../utils/ReasonReactHelpers.bs.js");
var CreateFeatureButton$Astrolabe = require("./CreateFeatureButton.bs.js");
var UpdateFeatureButton$Astrolabe = require("./UpdateFeatureButton.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    var newFeatureValues = state.newFeatureValue.split(",").map((function (fv) {
              return fv.trim();
            })).filter((function (fv) {
            return fv.length > 0;
          }));
    var featureValues = newFeatureValues.concat(state.featureValues);
    return {
            featureAllowsNA: state.featureAllowsNA,
            featureName: state.featureName,
            featureValues: featureValues,
            errors: state.errors,
            newFeatureValue: ""
          };
  }
  switch (action.tag | 0) {
    case /* MoveFeatureValue */0 :
        var featureValues$1 = Util$Astrolabe.$$Array.moveElementByIndex(action[0], action[1], state.featureValues);
        return {
                featureAllowsNA: state.featureAllowsNA,
                featureName: state.featureName,
                featureValues: featureValues$1,
                errors: state.errors,
                newFeatureValue: state.newFeatureValue
              };
    case /* RemoveFeatureValue */1 :
        var featureValues$2 = Util$Astrolabe.$$Array.removeAt(action[0], state.featureValues);
        return {
                featureAllowsNA: state.featureAllowsNA,
                featureName: state.featureName,
                featureValues: featureValues$2,
                errors: state.errors,
                newFeatureValue: state.newFeatureValue
              };
    case /* SetErrors */2 :
        return {
                featureAllowsNA: state.featureAllowsNA,
                featureName: state.featureName,
                featureValues: state.featureValues,
                errors: action[0],
                newFeatureValue: state.newFeatureValue
              };
    case /* SetErrorsAndResetFeature */3 :
        var initialFeatureState = action[1];
        return {
                featureAllowsNA: initialFeatureState.featureAllowsNA,
                featureName: initialFeatureState.featureName,
                featureValues: initialFeatureState.featureValues,
                errors: action[0].concat(["All of your changes have been undone."]),
                newFeatureValue: initialFeatureState.newFeatureValue
              };
    case /* SetFeatureName */4 :
        return {
                featureAllowsNA: state.featureAllowsNA,
                featureName: action[0],
                featureValues: state.featureValues,
                errors: state.errors,
                newFeatureValue: state.newFeatureValue
              };
    case /* SetNewFeatureValue */5 :
        return {
                featureAllowsNA: state.featureAllowsNA,
                featureName: state.featureName,
                featureValues: state.featureValues,
                errors: state.errors,
                newFeatureValue: action[0]
              };
    case /* SetFeatureAllowsNA */6 :
        return {
                featureAllowsNA: action[0],
                featureName: state.featureName,
                featureValues: state.featureValues,
                errors: state.errors,
                newFeatureValue: state.newFeatureValue
              };
    
  }
}

function FeatureForm(Props) {
  var experimentId = Props.experimentId;
  var feature = Props.feature;
  var onCancel = Props.onCancel;
  var onSuccess = Props.onSuccess;
  var newFeatureState_featureValues = [];
  var newFeatureState_errors = [];
  var newFeatureState = {
    featureAllowsNA: false,
    featureName: "",
    featureValues: newFeatureState_featureValues,
    errors: newFeatureState_errors,
    newFeatureValue: ""
  };
  var match;
  if (feature !== undefined) {
    var ft = Caml_option.valFromOption(feature);
    match = /* tuple */[
      Feature$Astrolabe.id(ft),
      {
        featureAllowsNA: Feature$Astrolabe.is_allow_na(ft),
        featureName: Feature$Astrolabe.name(ft),
        featureValues: Feature$Astrolabe.values(ft),
        errors: newFeatureState_errors,
        newFeatureValue: ""
      }
    ];
  } else {
    match = /* tuple */[
      "0",
      newFeatureState
    ];
  }
  var initialState = match[1];
  var featureId = match[0];
  var match$1 = React.useReducer(reducer, initialState);
  var dispatch = match$1[1];
  var state = match$1[0];
  var hasErrors = !Util$Astrolabe.$$Array.isEmpty(state.errors);
  var hasFeatureValues = !Util$Astrolabe.$$Array.isEmpty(state.featureValues);
  var isNewFeature = featureId === "0";
  var deleteButton = React.createElement(FeatureMutations$Astrolabe.DestroyFeatureMutation.make, {
        children: (function (mutation, param) {
            return React.createElement("button", {
                        className: "btn btn-danger",
                        onClick: (function (e) {
                            ReasonReactHelpers$Astrolabe.preventDefault(e);
                            Curry._4(mutation, Caml_option.some(FeatureMutations$Astrolabe.DestroyFeatureConfig.make(featureId, undefined).variables), ["getExperiment"], undefined, undefined).then((function (resp) {
                                    if (typeof resp !== "number") {
                                      if (resp.tag) {
                                        var destroyFeature = resp[0].destroyFeature;
                                        if (destroyFeature !== undefined) {
                                          var errors = Caml_option.valFromOption(destroyFeature).errors;
                                          if (Util$Astrolabe.$$Array.isEmpty(errors)) {
                                            Curry._1(onSuccess, undefined);
                                          } else {
                                            Curry._1(dispatch, /* SetErrors */Block.__(2, [errors]));
                                          }
                                        }
                                        
                                      } else {
                                        console.log(resp[0]);
                                      }
                                    }
                                    return Promise.resolve(undefined);
                                  }));
                            
                          })
                      }, "Delete");
          })
      });
  var noArrowTd = React.createElement("td", {
        className: "border-0",
        style: {
          width: "20px"
        }
      }, null);
  var featureValuesTable = React.createElement("table", {
        className: "table table-sm table-striped table-hover w-100"
      }, React.createElement("tbody", undefined, state.featureValues.map((function (fv, i) {
                  return React.createElement("tr", {
                              key: fv + String(i),
                              className: "d-flex"
                            }, React.createElement("td", {
                                  className: "border-0 cursor-pointer",
                                  onClick: (function (param) {
                                      return Curry._1(dispatch, /* RemoveFeatureValue */Block.__(1, [i]));
                                    })
                                }, React.createElement(Icons$Astrolabe.Trash.make, {
                                      className: "cursor-pointer",
                                      height: "14",
                                      width: "14"
                                    })), i === 0 ? noArrowTd : React.createElement("td", {
                                    className: "border-0 cursor-pointer",
                                    onClick: (function (param) {
                                        return Curry._1(dispatch, /* MoveFeatureValue */Block.__(0, [
                                                      i,
                                                      -1
                                                    ]));
                                      })
                                  }, React.createElement(Icons$Astrolabe.ArrowUp.make, {
                                        className: "cursor-pointer",
                                        height: "12",
                                        width: "12"
                                      })), i === (state.featureValues.length - 1 | 0) ? noArrowTd : React.createElement("td", {
                                    className: "border-0 cursor-pointer",
                                    onClick: (function (param) {
                                        return Curry._1(dispatch, /* MoveFeatureValue */Block.__(0, [
                                                      i,
                                                      1
                                                    ]));
                                      })
                                  }, React.createElement(Icons$Astrolabe.ArrowDown.make, {
                                        className: "cursor-pointer",
                                        height: "12",
                                        width: "12"
                                      })), React.createElement("td", {
                                  className: "border-0"
                                }, fv));
                }))));
  return React.createElement("form", {
              className: "FeatureForm modal-body",
              role: "form"
            }, hasErrors ? React.createElement(ErrorsAlert$Astrolabe.make, {
                    title: "Some errors must be addressed before continuing:",
                    errors: state.errors.map((function (prim) {
                            return prim;
                          }))
                  }) : null, React.createElement("div", {
                  className: "form-group"
                }, React.createElement("label", {
                      htmlFor: "name"
                    }, "Feature Name"), React.createElement("input", {
                      className: "form-control",
                      id: "name",
                      type: "text",
                      value: state.featureName,
                      onChange: (function (e) {
                          return Curry._1(dispatch, /* SetFeatureName */Block.__(4, [ReasonReactHelpers$Astrolabe.valueFromEvent(e)]));
                        })
                    })), React.createElement("div", {
                  className: "form-group"
                }, React.createElement("label", undefined, "Feature Values"), React.createElement("div", {
                      className: "form-group form-row"
                    }, React.createElement("div", {
                          className: "col-10"
                        }, React.createElement("input", {
                              className: "form-control col-12",
                              placeholder: "Enter a value or many comma-separated values",
                              type: "text",
                              value: state.newFeatureValue,
                              onChange: (function (e) {
                                  return Curry._1(dispatch, /* SetNewFeatureValue */Block.__(5, [ReasonReactHelpers$Astrolabe.valueFromEvent(e)]));
                                })
                            })), React.createElement("div", {
                          className: "col-2 text-right"
                        }, React.createElement("button", {
                              className: "btn btn-primary",
                              onClick: (function (e) {
                                  ReasonReactHelpers$Astrolabe.preventDefault(e);
                                  return Curry._1(dispatch, /* AddNewFeatureValue */0);
                                })
                            }, "Add"))), hasFeatureValues ? featureValuesTable : null), React.createElement("div", {
                  className: "form-check"
                }, React.createElement("input", {
                      className: "form-check-input",
                      id: "feature_is_allow_na",
                      checked: state.featureAllowsNA,
                      type: "checkbox",
                      onChange: (function (e) {
                          return Curry._1(dispatch, /* SetFeatureAllowsNA */Block.__(6, [ReasonReactHelpers$Astrolabe.checkedFromEvent(e)]));
                        })
                    }), React.createElement("label", {
                      className: "form-check-label",
                      htmlFor: "feature_is_allow_na"
                    }, React.createElement("span", undefined, "Allow NA Values"), React.createElement("small", {
                          className: "form-text text-muted"
                        }, "Samples with NA values will not be included in visualization and analysis for this feature"))), React.createElement("hr", undefined), React.createElement("div", {
                  className: "col-12"
                }, React.createElement("div", {
                      className: "row " + (
                        isNewFeature ? "justify-content-end" : "justify-content-between"
                      )
                    }, React.createElement("div", undefined, isNewFeature ? null : deleteButton), React.createElement("div", undefined, React.createElement("button", {
                              className: "btn btn-light",
                              onClick: (function (param) {
                                  return Curry._1(onCancel, undefined);
                                })
                            }, "Cancel"), isNewFeature ? React.createElement(CreateFeatureButton$Astrolabe.make, {
                                experimentId: experimentId,
                                featureName: state.featureName,
                                isAllowNa: state.featureAllowsNA,
                                featureValues: state.featureValues,
                                onErrors: (function (errors) {
                                    return Curry._1(dispatch, /* SetErrors */Block.__(2, [errors]));
                                  }),
                                onSave: (function (param) {
                                    return Curry._1(onSuccess, undefined);
                                  })
                              }) : React.createElement(UpdateFeatureButton$Astrolabe.make, {
                                featureId: featureId,
                                featureName: state.featureName,
                                isAllowNa: state.featureAllowsNA,
                                featureValues: state.featureValues,
                                onErrors: (function (errors) {
                                    return Curry._1(dispatch, /* SetErrorsAndResetFeature */Block.__(3, [
                                                  errors,
                                                  initialState
                                                ]));
                                  }),
                                onUpdate: (function (param) {
                                    return Curry._1(onSuccess, undefined);
                                  })
                              })))));
}

var $$Array;

var $$String$1;

var $$Option;

var DestroyFeatureConfig;

var DestroyFeatureMutation;

var make = FeatureForm;

exports.$$Array = $$Array;
exports.$$String = $$String$1;
exports.$$Option = $$Option;
exports.DestroyFeatureConfig = DestroyFeatureConfig;
exports.DestroyFeatureMutation = DestroyFeatureMutation;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
