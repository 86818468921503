// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var GraphQL$Astrolabe = require("../../../models/GraphQL.bs.js");

function str(prim) {
  return prim;
}

function ASDSHeatMapControls$Dropdown(Props) {
  var title = Props.title;
  var value = Props.value;
  var onChange = Props.onChange;
  var options = Props.options;
  var disabled = Props.disabled;
  return React.createElement("div", {
              className: "col-md-4 col-12"
            }, React.createElement("label", {
                  className: "col-12 pl-0"
                }, title), React.createElement("div", {
                  className: "form-group col-12 pl-0 pr-0"
                }, React.createElement("select", {
                      className: "form-control form-control-sm",
                      disabled: Belt_Option.getWithDefault(disabled, false),
                      value: value,
                      onChange: (function (e) {
                          return Curry._1(onChange, e.target.value);
                        })
                    }, $$Array.map((function (opt) {
                            return React.createElement("option", {
                                        key: opt.value,
                                        value: opt.value
                                      }, opt.label);
                          }), options))));
}

var Dropdown = {
  make: ASDSHeatMapControls$Dropdown
};

function ASDSHeatMapControls(Props) {
  var cellSubsetLevel = Props.cellSubsetLevel;
  var treatment = Props.treatment;
  var statistic = Props.statistic;
  var onCellSubsetLevelChange = Props.onCellSubsetLevelChange;
  var onTreatmentChange = Props.onTreatmentChange;
  var onStatisticChange = Props.onStatisticChange;
  return React.createElement("div", {
              className: "form-row"
            }, React.createElement(ASDSHeatMapControls$Dropdown, {
                  title: "Statistic",
                  value: GraphQL$Astrolabe.Types.string_of_statistic(statistic),
                  onChange: (function (value) {
                      return Curry._1(onStatisticChange, GraphQL$Astrolabe.Types.statistic_of_string(value));
                    }),
                  options: [
                    {
                      value: "MEDIAN",
                      label: "Median"
                    },
                    {
                      value: "PERCENT_POSITIVE",
                      label: "Percent Positive"
                    },
                    {
                      value: "FIXATION",
                      label: "Fixation Ratio"
                    }
                  ]
                }), React.createElement(ASDSHeatMapControls$Dropdown, {
                  title: "Cell Subset Level",
                  value: GraphQL$Astrolabe.Types.string_of_cellSubsetLevel(cellSubsetLevel),
                  onChange: (function (value) {
                      return Curry._1(onCellSubsetLevelChange, GraphQL$Astrolabe.Types.cellSubsetLevel_of_string(value));
                    }),
                  options: [
                    {
                      value: "ASSIGNMENT",
                      label: "Assignment"
                    },
                    {
                      value: "PROFILING",
                      label: "Profiling"
                    }
                  ],
                  disabled: statistic === /* FIXATION */-744809280
                }), statistic !== /* FIXATION */-744809280 ? React.createElement(ASDSHeatMapControls$Dropdown, {
                    title: "Treatment",
                    value: GraphQL$Astrolabe.Types.string_of_treatment(treatment),
                    onChange: (function (value) {
                        return Curry._1(onTreatmentChange, GraphQL$Astrolabe.Types.treatment_of_string(value));
                      }),
                    options: [
                      {
                        value: "FRESH",
                        label: "Fresh"
                      },
                      {
                        value: "FIXED",
                        label: "Fixed"
                      }
                    ]
                  }) : null);
}

var make = ASDSHeatMapControls;

exports.str = str;
exports.Dropdown = Dropdown;
exports.make = make;
/* react Not a pure module */
