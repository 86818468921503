// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var AnalysisKit$Astrolabe = require("../../models/AnalysisKit.bs.js");
var DeKitsTable$Astrolabe = require("./analyses/DeKitsTable.bs.js");
var AddDeKitModal$Astrolabe = require("./analyses/AddDeKitModal.bs.js");
var TitleWithIcon$Astrolabe = require("../header/TitleWithIcon.bs.js");
var DifferentialExpressionAnalysisView$Astrolabe = require("../analyses/DifferentialExpressionAnalysisView.bs.js");

function str(prim) {
  return prim;
}

function Analyses(Props) {
  var canEditAnalysisKits = Props.canEditAnalysisKits;
  var channelUsages = Props.channelUsages;
  var experiment = Props.experiment;
  var features = Props.features;
  var match_ = Props.match;
  var samples = Props.samples;
  var cellSubsetLevels = Props.cellSubsetLevels;
  var match = React.useState((function () {
          
        }));
  var setSelectedKit = match[1];
  var selectedKit = match[0];
  var experimentId = Experiment$Astrolabe.Js.id(experiment);
  var analysisSubtitle = canEditAnalysisKits ? React.createElement("span", undefined, "Define which analyses Astrolabe should perform on your experiment. You will not be able to add new analyses once setup is done. Check out the ", React.createElement("a", {
              href: Constants$Astrolabe.dEASetupTutorialUrl,
              target: "_blank"
            }, "Differential Expression Analysis tutorial"), " for more information.") : React.createElement("span", undefined, "The list of analyses which Astrolabe applied to your experiment. This cannot be changed once the analysis was completed. Check out the ", React.createElement("a", {
              href: Constants$Astrolabe.dEAResultsTutorialUrl,
              target: "_blank"
            }, "Differential Expression Analysis tutorial"), " for more information.");
  var analysisHeader = React.createElement("div", {
        className: "d-flex justify-content-between align-items-center"
      }, React.createElement(TitleWithIcon$Astrolabe.make, {
            className: "margin-new-title",
            subtitle: analysisSubtitle,
            title: "Analyses",
            children: React.createElement(Icons$Astrolabe.Analyses.make, {
                  className: "no-hover",
                  height: "60",
                  width: "60"
                })
          }), canEditAnalysisKits ? React.createElement("button", {
              className: "btn btn-primary mx-3",
              style: {
                height: "43px",
                width: "550px"
              },
              onClick: (function (param) {
                  return Curry._1(setSelectedKit, (function (param) {
                                return AnalysisKit$Astrolabe.DifferentialExpression.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                              }));
                })
            }, "Add Differential Expression Analysis") : null);
  var kits = $$Array.map(AnalysisKit$Astrolabe.DifferentialExpression.fromJs, Experiment$Astrolabe.Js.deAnalysisKits(experiment));
  var match$1 = match_.params.kitId;
  var detailsKit;
  if (canEditAnalysisKits || match$1 === undefined) {
    detailsKit = undefined;
  } else {
    var kitId = Caml_option.valFromOption(match$1);
    detailsKit = Caml_option.undefined_to_opt(kits.find((function (k) {
                return Caml_obj.caml_equal(k.id, kitId);
              })));
  }
  var table = detailsKit !== undefined ? React.createElement(DifferentialExpressionAnalysisView$Astrolabe.make, {
          features: features,
          kit: detailsKit,
          experimentName: Experiment$Astrolabe.Js.name(experiment),
          match: match_,
          samples: samples,
          cellSubsetLevels: cellSubsetLevels
        }) : React.createElement(React.Fragment, undefined, analysisHeader, React.createElement(DeKitsTable$Astrolabe.make, {
              disabled: !canEditAnalysisKits,
              experimentId: experimentId,
              kits: kits,
              onEditKitClick: (function (kit) {
                  return Curry._1(setSelectedKit, (function (param) {
                                return kit;
                              }));
                })
            }));
  return React.createElement(React.Fragment, undefined, table, selectedKit !== undefined ? React.createElement(AddDeKitModal$Astrolabe.make, {
                    channelUsages: channelUsages,
                    experimentId: experimentId,
                    features: features,
                    kit: selectedKit,
                    onClose: (function (param) {
                        return Curry._1(setSelectedKit, (function (param) {
                                      
                                    }));
                      }),
                    visible: true
                  }) : null);
}

var or = Util$Astrolabe.or;

var Experiment;

var make = Analyses;

var $$default = Analyses;

exports.or = or;
exports.Experiment = Experiment;
exports.str = str;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
