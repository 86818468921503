// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var ReactRouterDom = require("react-router-dom");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var Constants$Astrolabe = require("../../../utils/Constants.bs.js");
var AnalysisKit$Astrolabe = require("../../../models/AnalysisKit.bs.js");
var ReactRouter$Astrolabe = require("../../../utils/ReactRouter.bs.js");
var ShiftDeKitButton$Astrolabe = require("./ShiftDeKitButton.bs.js");
var DestroyDeKitButton$Astrolabe = require("./DestroyDeKitButton.bs.js");
var DuplicateDeKitButton$Astrolabe = require("./DuplicateDeKitButton.bs.js");

function str(prim) {
  return prim;
}

function notYetImplemented(param) {
  return (window.alert("Not implemented yet!"));
}

function DeKitsTable(Props) {
  var disabled = Props.disabled;
  var experimentId = Props.experimentId;
  var kits = Props.kits;
  var onEditKitClick = Props.onEditKitClick;
  var kitCount = kits.length;
  if (kitCount !== 0) {
    return React.createElement("table", {
                className: "table table-sm table-striped"
              }, React.createElement("thead", undefined, React.createElement("tr", {
                        className: "shadow-border-bottom"
                      }, React.createElement("td", undefined, "#"), React.createElement("td", undefined, "Name"), React.createElement("td", undefined, React.createElement("span", {
                                title: "Marker intensity fold changes are calculated between each value of this feature and its baseline value."
                              }, "Primary Feature")), React.createElement("td", undefined, React.createElement("span", {
                                title: "This feature is used as a reference for marker background intensities. A common example is a reference spike-in to address batch effects."
                              }, "Reference Feature")), React.createElement("td", undefined, React.createElement("span", {
                                title: "These features could affect or explain some of the changes in the primary feature."
                              }, "Dependent Features")), React.createElement("td", undefined, React.createElement("span", {
                                title: "The list of markers for which fold changes are calculated."
                              }, "Markers")))), React.createElement("tbody", {
                    className: "pt20-on-first-tr"
                  }, $$Array.mapi((function (i, kit) {
                          var feature = kit.referenceFeature;
                          return React.createElement("tr", {
                                      key: Belt_Option.getExn(kit.id),
                                      className: "hover-container"
                                    }, React.createElement("td", {
                                          style: {
                                            width: "35px"
                                          }
                                        }, React.createElement("span", {
                                              className: disabled ? "" : "hide-on-hover"
                                            }, String(Belt_Option.getExn(kit.position))), !disabled && i !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(ShiftDeKitButton$Astrolabe.make, {
                                                    kit: kit,
                                                    direction: /* UP */19035,
                                                    children: React.createElement("span", {
                                                          className: "cursor-pointer show-on-hover"
                                                        }, React.createElement(Icons$Astrolabe.ArrowUp.make, {
                                                              height: "15",
                                                              width: "15"
                                                            }))
                                                  }), React.createElement("br", undefined)) : null, !disabled && i !== (kitCount - 1 | 0) ? React.createElement(ShiftDeKitButton$Astrolabe.make, {
                                                kit: kit,
                                                direction: /* DOWN */758038626,
                                                children: React.createElement("span", {
                                                      className: "cursor-pointer show-on-hover"
                                                    }, React.createElement(Icons$Astrolabe.ArrowDown.make, {
                                                          height: "15",
                                                          width: "15"
                                                        }))
                                              }) : null), React.createElement("td", {
                                          style: {
                                            width: "20%"
                                          }
                                        }, disabled ? React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps(Constants$Astrolabe.dEAPath(experimentId, Belt_Option.getExn(kit.id)), Caml_option.some(Util$Astrolabe.$$String.truncate(kit.name, 60, undefined, undefined)), undefined)) : React.createElement(React.Fragment, undefined, React.createElement("a", {
                                                    href: "",
                                                    onClick: (function (e) {
                                                        e.preventDefault();
                                                        return Curry._1(onEditKitClick, kit);
                                                      })
                                                  }, Util$Astrolabe.$$String.truncate(kit.name, 60, undefined, undefined)), React.createElement("span", {
                                                    className: "show-on-hover"
                                                  }, React.createElement(DuplicateDeKitButton$Astrolabe.make, {
                                                        kit: kit,
                                                        children: React.createElement(Icons$Astrolabe.Duplicate.make, {
                                                              className: "ml-2 mb-1",
                                                              height: "15",
                                                              width: "15"
                                                            })
                                                      }), React.createElement(DestroyDeKitButton$Astrolabe.make, {
                                                        kit: kit,
                                                        children: React.createElement(Icons$Astrolabe.Trash.make, {
                                                              className: "ml-1 mb-1",
                                                              height: "15",
                                                              width: "15"
                                                            })
                                                      })))), React.createElement("td", undefined, Belt_Option.mapWithDefault(kit.primaryFeature, "", Feature$Astrolabe.name), React.createElement("br", undefined), React.createElement("span", {
                                              className: "text-muted"
                                            }, "Baseline: " + kit.primaryFeatureBaselineValue)), React.createElement("td", undefined, feature !== undefined ? React.createElement(React.Fragment, undefined, Feature$Astrolabe.name(Caml_option.valFromOption(feature)), React.createElement("br", undefined), React.createElement("span", {
                                                    className: "text-muted"
                                                  }, "Baseline: " + Belt_Option.getWithDefault(kit.referenceFeatureBaselineValue, "<None>"))) : null), React.createElement("td", undefined, $$Array.map(Feature$Astrolabe.name, kit.dependentFeatures).join(", ")), React.createElement("td", {
                                          style: {
                                            width: "20%"
                                          }
                                        }, kit.markerChannels.map((function (cu) {
                                                  return cu.desc;
                                                })).join(", "), React.createElement("br", undefined), React.createElement("span", {
                                              className: "text-muted"
                                            }, "Statistic: " + AnalysisKit$Astrolabe.Statistic.name(kit.markerStatistic))));
                        }), kits)));
  } else {
    return React.createElement("div", {
                className: "row"
              }, React.createElement("div", {
                    className: "col-12 text-center"
                  }, "This experiment has no differential expression analyses."));
  }
}

var make = DeKitsTable;

exports.str = str;
exports.notYetImplemented = notYetImplemented;
exports.make = make;
/* react Not a pure module */
