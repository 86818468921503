// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../../models/Util.bs.js");
var FeatureMutations$Astrolabe = require("../FeatureMutations.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../../utils/ReasonReactHelpers.bs.js");

function UpdateFeatureButton(Props) {
  var featureId = Props.featureId;
  var featureName = Props.featureName;
  var isAllowNa = Props.isAllowNa;
  var featureValues = Props.featureValues;
  var onErrors = Props.onErrors;
  var onUpdate = Props.onUpdate;
  return React.createElement(FeatureMutations$Astrolabe.UpdateFeatureMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement("button", {
                              className: "btn btn-primary ml-1",
                              onClick: (function (e) {
                                  ReasonReactHelpers$Astrolabe.preventDefault(e);
                                  var variables = FeatureMutations$Astrolabe.UpdateFeatureConfig.make(featureId, featureName, isAllowNa, featureValues, undefined).variables;
                                  Curry._4(mutation, Caml_option.some(variables), ["getExperiment"], undefined, undefined).then((function (resp) {
                                          if (typeof resp !== "number") {
                                            if (resp.tag) {
                                              var updateFeature = resp[0].updateFeature;
                                              if (updateFeature !== undefined) {
                                                var errors = Caml_option.valFromOption(updateFeature).errors;
                                                if (Util$Astrolabe.$$Array.isEmpty(errors)) {
                                                  Curry._1(onUpdate, undefined);
                                                } else {
                                                  Curry._1(onErrors, errors);
                                                }
                                              }
                                              
                                            } else {
                                              console.log(resp[0]);
                                            }
                                          }
                                          return Promise.resolve(undefined);
                                        }));
                                  
                                })
                            }, "Update");
                })
            });
}

var UpdateFeatureMutation;

var UpdateFeatureConfig;

var make = UpdateFeatureButton;

exports.UpdateFeatureMutation = UpdateFeatureMutation;
exports.UpdateFeatureConfig = UpdateFeatureConfig;
exports.make = make;
/* react Not a pure module */
