/* @flow */
"use strict";

import React from "react";
import { Link } from "react-router-dom";
import { List } from "immutable";
import { PipelineRun } from "models";

type Props = {
  run: PipelineRun,
  includeLink: boolean,
  buttons?: any,
};

export default function Row(props: Props) {
  const run = props.run;
  var runId = run.get("id");

  if (props.includeLink) {
    runId = <Link to={`/pipeline_runs/${runId}`}>{runId}</Link>;
  }

  return (
    <tr>
      <td>{runId}</td>
      <td>{run.get("experiment_name")}</td>
      <td>{run.get("dag_type")}</td>
      <td>{run.get("status")}</td>
      <td>
        <div className="progress" style={{ height: "1rem" }}>
          <div className="progress-bar bg-success" style={{ width: `${run.get("job_percentages").finished}%` }} />
          <div className="progress-bar bg-warning" style={{ width: `${run.get("job_percentages").running}%` }} />
          <div className="progress-bar bg-info" style={{ width: `${run.get("job_percentages").waiting}%` }} />
          <div className="progress-bar" style={{ width: `${run.get("job_percentages").unstarted}%` }} />
          <div className="progress-bar bg-danger" style={{ width: `${run.get("job_percentages").errored}%` }} />
        </div>
      </td>
      <td>{run.get("started_at") && new Date(run.get("started_at")).toLocaleString()}</td>
      <td>{run.get("ended_at") && new Date(run.get("ended_at")).toLocaleString()}</td>
      <td>{props.buttons ? props.buttons : null}</td>
    </tr>
  );
}
