/* @flow */

import React from "react";
import type { History, Match, Session } from "react-router";
import { Redirect } from "react-router-dom";
import { List, Map, OrderedMap } from "immutable";
import type { CellSubsetNavigatorAnalysisType, JobInfo } from "models";
import {
  ChannelUsage,
  CellSubsetLevels,
  Experiment,
  ExperimentDifferentialAbundance,
  ExperimentStatistics,
  Feature,
  Sample,
} from "models";
import { fetchJSON } from "components/utils";

type Props = {
  history: History,
  isPublicPath?: boolean,
  loading?: () => React$Node,
  match: Match,
  render: (RenderProps) => React$Node,
  renderErrors?: boolean,
  session: Session,
};

type State = {
  cellSubsetLevels: CellSubsetLevels,
  channelUsages: List<ChannelUsage>,
  differentialAbundanceData: { [string]: ?ExperimentDifferentialAbundance },
  errors: List<Object>,
  experiment: ?Experiment,
  experimentStatistics: { [string]: ExperimentStatistics },
  experimentLoaded: boolean,
  features: List<Feature>,
  samples: List<Sample>,
};

export type Api = {
  experiments: {
    fetch: () => Promise<any>,
    fetchAggregateStatisticsData: () => Promise<any>,
    fetchCellSubsetLevels: () => Promise<any>,
    fetchDifferentialAbundanceData: () => Promise<any>,
    preprocess: (Experiment) => Promise<any>,
    save: (Experiment) => Promise<any>,
    unpreprocess: (Experiment) => Promise<any>,
  },
  errors: {
    update: (List<string>) => void,
  },
};

export type RenderProps = {
  api: Api,
  cellSubsetLevels: CellSubsetLevels,
  channelUsages: List<ChannelUsage>,
  differentialAbundanceData: { [string]: ?ExperimentDifferentialAbundance },
  errors: List<string>,
  experiment: Experiment,
  experimentStatistics: { [string]: ExperimentStatistics },
  history: History,
  match: Match,
  samples: List<Sample>,
  session: Session,
};

export default class ExperimentProvider extends React.Component<Props, State> {
  api: Api;
  intervalId: ?IntervalID;

  constructor(props: Props) {
    super(props);

    this.api = {
      experiments: {
        fetch: this.fetchExperiment,
        fetchAggregateStatisticsData: this.fetchAggregateStatisticsData,
        fetchCellSubsetLevels: this.fetchCellSubsetLevels,
        fetchDifferentialAbundanceData: this.fetchDifferentialAbundanceData,
        preprocess: this.preprocessExperiment,
        save: this.saveExperiment,
        unpreprocess: this.unpreprocessExperiment,
      },
      errors: {
        update: (errors) => this.setState({ errors }),
      },
    };

    this.state = {
      cellSubsetLevels: {},
      channelUsages: List(),
      differentialAbundanceData: {},
      errors: List(),
      experiment: null,
      experimentStatistics: {},
      experimentLoaded: false,
      samples: List(),
    };
  }

  async componentDidMount() {
    await this.fetchExperiment();
    this.scheduleReload();
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidUpdate() {
    this.scheduleReload();
  }

  disableOnDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  fetchExperiment = async () => {
    let json = {};

    try {
      json = await fetchJSON("GET", `/experiments/${this.props.match.params.id}.json?include_sample_feature_values=true`);
    } catch (e) {
      json.errors = e.message;
    }

    var samples = [];

    if (json.errors || json.error) {
      this.setState({ experimentLoaded: true, errors: List(json.errors) });
    } else {
      samples = List(json.samples.map((s) => new Sample(s)));

      const experiment = new Experiment(json.experiment);

      this.setState({
        channelUsages: List(json.channel_usages.map((c) => new ChannelUsage(c))),
        errors: List(),
        experiment,
        experimentLoaded: true,
        features: List(json.features.map((f) => new Feature({ ...f, values: List(f.values) }))),
        samples,
      });
    }

    if (!this.state.experiment.get("editable?")) this.fetchCellSubsetLevels();
  };

  fetchAggregateStatisticsData = async () => {
    if (!this.state.experiment) {
      throw `Can't call fetchAggregateStatisticsData() before experiment is loaded`;
    }
    const experiment = this.state.experiment;

    let path = `/experiments/${experiment.get("id")}/aggregate_statistics_data.json`;
    const json = await fetchJSON("GET", path);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      let experimentStatistics = {};

      Object.keys(json).forEach((key) => {
        experimentStatistics[key] = new ExperimentStatistics(this.state.samples, json[key]);
      });

      this.setState({ experimentStatistics, errors: List() });
    }
  };

  fetchDifferentialAbundanceData = async () => {
    if (!this.state.experiment && this.state.cellSubsetLevels.all.length > 0) {
      throw `Can't call fetchDifferentialAbundanceData() before experiment and cell subsets are loaded`;
    }
    const experiment = this.state.experiment;

    let path = `/experiments/${experiment.get("id")}/differential_abundance_data.json`;
    const json = await fetchJSON("GET", path);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      const differentialAbundanceData = this.state.cellSubsetLevels.all.reduce((acc, subsetLevel) => {
        acc[subsetLevel] = new ExperimentDifferentialAbundance(json[subsetLevel]);
        return acc;
      }, {});

      this.setState({
        differentialAbundanceData,
        errors: List(),
      });
    }
  };

  fetchCellSubsetLevels = async () => {
    // Avoid refetch if we already have cell subset levels.
    if (Object.keys(this.state.cellSubsetLevels).length > 0) {
      return;
    }

    if (!this.state.experiment) {
      throw `Can't call fechCellSubsetLevels() before experiment is loaded`;
    }

    const experiment = this.state.experiment;

    let path = `/experiments/${experiment.get("id")}/cell_subset_levels.json`;
    const json = await fetchJSON("GET", path);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      let cellSubsetLevels = {};
      /* check for profiling only */
      if (json.levels == "Profiling") {
        cellSubsetLevels["all"] = ["profiling"];
        cellSubsetLevels["default"] = "profiling";
        cellSubsetLevels["values"] = json.level_values;
        cellSubsetLevels["cell_subset_map"] = json.level_values;
      } else {
        cellSubsetLevels["all"] = json.levels.map((name) => name.toLowerCase());
        cellSubsetLevels["default"] = json.default_level.toLowerCase();
        cellSubsetLevels["values"] = json.level_values;
        cellSubsetLevels["cell_subset_map"] = json.cell_subset_map;
      }
      this.setState({ cellSubsetLevels, errors: List() });
    }
  };

  optimisticallyFetchExperimentEndpoint = async (method: string, action: string, experiment: Experiment) => {
    var currentState = { experiment: this.state.experiment };

    this.setState({ experiment, errors: List() });

    let path = `/experiments/${experiment.get("id")}`;

    if (action === "save") {
      path += ".json";
    } else {
      path += `/${action}.json`;
    }

    const json = await fetchJSON(method, path, { experiment: experiment.toJS() });

    if (json.errors) {
      this.setState({
        ...currentState,
        errors: List(json.errors),
      });
      return false;
    } else {
      let newState = {};

      if (json.experiment) {
        newState.experiment = new Experiment(json.experiment);
      }

      if (json.samples) {
        newState.samples = List(json.samples.map((s) => new Sample(s)));
      }

      if (Object.keys(newState).length > 0) {
        this.setState(newState);
      }
    }
    return true;
  };

  preprocessExperiment = async (experiment: Experiment) => {
    return this.optimisticallyFetchExperimentEndpoint("PATCH", "preprocess", experiment);
  };

  unpreprocessExperiment = async (experiment: Experiment) => {
    return this.optimisticallyFetchExperimentEndpoint("PATCH", "unpreprocess", experiment);
  };

  saveExperiment = async (experiment: Experiment) => {
    return this.optimisticallyFetchExperimentEndpoint("PATCH", "save", experiment);
  };

  scheduleReload = () => {
    if (!this.state.experiment) {
      return;
    }

    const experiment = this.state.experiment;

    if (this.intervalId) {
      let intervalId = this.intervalId;
      if (!["preprocessing", "debarcoding", "analyzing"].includes(experiment.status)) {
        clearInterval(intervalId);
      }
      return;
    }

    if (experiment.status === "preprocessing") {
      this.intervalId = setInterval(() => window.location.reload(), 15000);
    } else if (experiment.status === "debarcoding") {
      this.intervalId = setInterval(() => window.location.reload(), 5 * 60000);
    } else if (experiment.status === "analyzing") {
      this.intervalId = setInterval(() => window.location.reload(), 5 * 60000);
    } else if (
      experiment.status !== "created" &&
      location.pathname.match(new RegExp(`/experiments/${experiment.id}/upload_samples`))
    ) {
      this.props.history.replace(`/experiments/${experiment.id}`);
    }
  };

  renderErrors = () => {
    if (this.props.renderErrors !== true) {
      return;
    }

    let errorDesc = null;
    let errors = null;

    if (this.state.errors.size === 1) {
      errorDesc = "Error";
      errors = <p>{this.state.errors.get(0)}</p>;
    } else {
      errorDesc = "Errors";
      errors = (
        <ul>
          {this.state.errors.map((error, i) => {
            <li key={i}>{error}</li>;
          })}
        </ul>
      );
    }

    return (
      <div className="alert alert-danger" role="alert">
        <h5 className="alert-heading">{errorDesc} Loading Experiment</h5>
        {errors}
      </div>
    );
  };

  renderNotFound = () => {
    return (
      <div className="alert alert-danger" role="alert">
        <h5 className="alert-heading mb-0 text-center">Experiment Not Found</h5>
      </div>
    );
  };

  render() {
    const privateExperimentWithPublicPath = this.state.experiment && !this.state.experiment.is_public && this.props.isPublicPath;
    const pubExperimentWithPrivatePathAndUnauthorized =
      this.state.experiment && !this.state.experiment.authorized_for_user_id && !this.props.isPublicPath;

    if (privateExperimentWithPublicPath || pubExperimentWithPrivatePathAndUnauthorized) {
      return this.renderNotFound();
    }

    if (!this.state.experiment) {
      if (this.state.experimentLoaded) {
        return this.renderNotFound();
      }

      if (this.props.loading) {
        return this.props.loading();
      }

      return <h3>Loading…</h3>;
    }

    const propsForRenderProp = {
      api: this.api,
      cellSubsetLevels: this.state.cellSubsetLevels,
      channelUsages: this.state.channelUsages,
      errors: this.state.errors,
      differentialAbundanceData: this.state.differentialAbundanceData,
      experiment: this.state.experiment,
      experimentStatistics: this.state.experimentStatistics,
      features: this.state.features,
      history: this.props.history,
      match: this.props.match,
      samples: this.state.samples,
      session: this.props.session,
    };

    if (this.state.errors.size > 0) {
      return (
        <div>
          {this.renderErrors()}
          {this.props.render(propsForRenderProp)}
        </div>
      );
    } else {
      return (
        <div onDrop={this.disableOnDrop} onDragOver={this.disableOnDrop} key={(this.state.experiment || {}).id}>
          {this.props.render(propsForRenderProp)}
        </div>
      );
    }
  }
}
