// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/src/Json.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var FetchUtils$Astrolabe = require("../../utils/FetchUtils.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function DownloadUploadTemplateBox(Props) {
  var downloadButtonTooltip = Props.downloadButtonTooltip;
  var downloadUrl = Props.downloadUrl;
  var isDownloadButtonDisabled = Props.isDownloadButtonDisabled;
  var isUploadButtonDisabled = Props.isUploadButtonDisabled;
  var onUploadBegin = Props.onUploadBegin;
  var onUploadComplete = Props.onUploadComplete;
  var text = Props.text;
  var uploadButtonTooltip = Props.uploadButtonTooltip;
  var uploadUrl = Props.uploadUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setIsUploading = match[1];
  var fileInputRef = React.useRef(null);
  var handleUploadComplete = function (errors, disableContinue, success) {
    Curry._1(setIsUploading, (function (param) {
            return false;
          }));
    return Curry._3(onUploadComplete, errors, disableContinue, success);
  };
  var isDownloadButtonDisabled$1 = isDownloadButtonDisabled || match[0];
  var isUploadButtonDisabled$1 = Util$Astrolabe.or(isUploadButtonDisabled, false);
  var uploadTemplate = React.createElement(React.Fragment, undefined, React.createElement("input", {
            key: String(Util$Astrolabe.$$Math.randomNumber(undefined)),
            ref: fileInputRef,
            className: "d-none",
            multiple: false,
            name: "sample",
            required: true,
            type: "file",
            onChange: (function (evt) {
                var files = ReasonReactHelpers$Astrolabe.filesFromEvent(evt);
                Curry._1(setIsUploading, (function (param) {
                        return true;
                      }));
                Curry._1(onUploadBegin, undefined);
                var template = Caml_array.caml_array_get(files, 0);
                var formData = new FormData();
                formData.append("template", template);
                FetchUtils$Astrolabe.patch(uploadUrl, formData).then((function (text) {
                        var json = Json.parseOrRaise(text);
                        var success = Curry._1(FetchUtils$Astrolabe.$$Response.Decode.success, json);
                        var errors = success ? /* tuple */[
                            "",
                            []
                          ] : /* tuple */[
                            "Template Upload Failed",
                            $$Array.map((function (prim) {
                                    return prim;
                                  }), Curry._1(FetchUtils$Astrolabe.$$Response.Decode.errors, json))
                          ];
                        return Promise.resolve(handleUploadComplete(errors, false, success));
                      }));
                
              })
          }), React.createElement("button", {
            className: "btn btn-primary " + (
              isDownloadButtonDisabled$1 ? "cursor-not-allowed" : ""
            ),
            style: {
              height: "43px",
              width: "186px"
            },
            title: uploadButtonTooltip,
            disabled: isDownloadButtonDisabled$1,
            onClick: (function (param) {
                return ReasonReactHelpers$Astrolabe.handleButtonRefClick(fileInputRef);
              })
          }, "Upload Template"));
  return React.createElement("div", {
              className: "info-box mb-30px p-3 d-flex justify-content-between",
              style: {
                minHeight: "136px"
              }
            }, React.createElement("div", {
                  className: "p-3 text-center"
                }, text), React.createElement("div", {
                  className: "d-flex flex-column justify-content-around align-items-center",
                  style: {
                    paddingLeft: "7%"
                  }
                }, React.createElement("button", {
                      className: "btn btn-primary " + (
                        isUploadButtonDisabled$1 ? "cursor-not-allowed" : ""
                      ),
                      style: {
                        height: "43px",
                        width: "186px"
                      },
                      title: downloadButtonTooltip,
                      disabled: isUploadButtonDisabled$1,
                      onClick: (function (param) {
                          var resultWindow = window.open(downloadUrl, "Download Template", undefined);
                          return Util$Astrolabe.$$Option.if_none((resultWindow == null) ? undefined : Caml_option.some(resultWindow), (function (param) {
                                        console.log("Error: Could not open URL: " + downloadUrl);
                                        
                                      }));
                        })
                    }, "Download Template"), uploadTemplate));
}

var $$Option;

var or = Util$Astrolabe.or;

var make = DownloadUploadTemplateBox;

exports.$$Option = $$Option;
exports.or = or;
exports.make = make;
/* react Not a pure module */
