// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var ConfirmedButton$Astrolabe = require("./ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");

var UpdateExperimentEventMutation = ReasonApollo.CreateMutation({
      query: ExperimentGraphQL$Astrolabe.UpdateEventConfig.query,
      parse: ExperimentGraphQL$Astrolabe.UpdateEventConfig.parse
    });

function FinishSetupButton(Props) {
  var buttonDisabled = Props.buttonDisabled;
  var experimentId = Props.experimentId;
  var experimentData = Props.experimentData;
  var handleContinue = Props.handleContinue;
  var confirmText = function (param) {
    if (experimentData.hasFeatures) {
      return "Are you sure sample names and features are set up correctly?";
    } else {
      return "Warning: The experiment does not have ANY sample features. This means Astrolabe will NOT do any statistical analysis. Please review the Experiment Setup tutorial or email support@astrolabediagnostics.com for more information. Are you sure you want to proceed?";
    }
  };
  var ir191Or193NotIgnoredError = [React.createElement("div", undefined, "Please set Ir191Di and Ir193 to \"ignore\" in the \"Channels\" tab. See the ", React.createElement("a", {
              href: Constants$Astrolabe.fileUploadTutorialUrl,
              target: "_blank"
            }, "Experiment Setup tutorial"), " or ", React.createElement("a", {
              href: "mailto:support@astrolabediagnostics.com?subject=Ir191Di or Ir193Di is not ignored"
            }, "email support"), " for more information.")];
  var error = experimentData.customLabelingHierarchyError;
  var customLabelingHierarchyError = error !== undefined ? [error] : [];
  return React.createElement(UpdateExperimentEventMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement(ConfirmedButton$Astrolabe.make, {
                              buttonClassName: "btn btn-sm " + (
                                buttonDisabled ? "cursor-not-allowed astrolabe-bg-dark-blue text-muted" : "btn-success"
                              ),
                              buttonTitle: "Finish Setup",
                              disabled: buttonDisabled,
                              modalButtonClassName: "btn-primary",
                              modalContents: confirmText(undefined),
                              onConfirm: (function (complete) {
                                  if (experimentData.isIr191Or193NotIgnored) {
                                    Curry._5(handleContinue, /* tuple */[
                                          "Experiment cannot continue",
                                          ir191Or193NotIgnoredError
                                        ], true, undefined, undefined, undefined);
                                  } else if (Util$Astrolabe.$$Array.isEmpty(customLabelingHierarchyError)) {
                                    ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "analyze", undefined, undefined);
                                  } else {
                                    Curry._5(handleContinue, /* tuple */[
                                          "Experiment cannot continue",
                                          customLabelingHierarchyError
                                        ], true, undefined, undefined, undefined);
                                  }
                                  return Curry._1(complete, undefined);
                                }),
                              title: "Complete Setup"
                            });
                })
            });
}

var make = FinishSetupButton;

var $$default = FinishSetupButton;

exports.UpdateExperimentEventMutation = UpdateExperimentEventMutation;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* UpdateExperimentEventMutation Not a pure module */
