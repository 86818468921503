// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");

function str(prim) {
  return prim;
}

function KitConfigurationsTable(Props) {
  var kit = Props.kit;
  var samples = Props.samples;
  var rowHeightStyle = Props.rowHeightStyle;
  return React.createElement("table", {
              className: "flex-grow-1"
            }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                          className: "border-left pl-2",
                          colSpan: kit.channels.length
                        }, kit.name)), React.createElement("tr", {
                      className: "text-center"
                    }, $$Array.mapi((function (i, channel) {
                            return React.createElement("td", {
                                        key: kit.id + channel,
                                        className: i === 0 ? "border-left pl-2" : ""
                                      }, React.createElement("small", undefined, channel));
                          }), kit.channels))), React.createElement("tbody", undefined, $$Array.mapi((function (i, sample) {
                        return React.createElement("tr", {
                                    key: String(i),
                                    className: (
                                      i === 0 ? "border-left pl-2 " : ""
                                    ) + "text-center",
                                    style: rowHeightStyle
                                  }, $$Array.of_list(List.mapi((function (i, channel) {
                                              return React.createElement("td", {
                                                          key: kit.id + channel,
                                                          className: i === 0 ? "border-left pl-2 " : ""
                                                        }, React.createElement("input", {
                                                              checked: Sample$Astrolabe.Js.barcodingChannels(sample).includes(channel),
                                                              readOnly: true,
                                                              type: "checkbox"
                                                            }));
                                            }), $$Array.to_list(kit.channels))));
                      }), samples)));
}

var Sample;

var make = KitConfigurationsTable;

exports.Sample = Sample;
exports.str = str;
exports.make = make;
/* react Not a pure module */
