/* @flow */
"use strict";

import React from "react";
import { Link } from "react-router-dom";
import { List } from "immutable";
import { Experiment, Feature, Sample } from "models";
import SampleQualityControlTable from "./SampleQualityControlTable.bs";

type Props = {
  experiment: Experiment,
  features: List<Feature>,
  onChange: (Sample) => any,
  experimentSamples: List<Sample>,
  sample: Sample,
};

export default function Header(props: Props) {
  const sampleIndex = props.experimentSamples.findIndex((s) => s.get("id") === props.sample.get("id"));

  let prevButton = null;
  let nextButton = null;

  const isPublicExp = window.location.pathname.startsWith("/public");
  const experimentsPath = (isPublicExp) => (isPublicExp ? "/public/experiments" : "/experiments");
  const expPath = experimentsPath(isPublicExp);

  let linkHref = (sample: Sample): string => `${expPath}/${props.experiment.get("id")}/samples/${sample.get("id")}/explorer`;

  let onLinkClick = (sample: Sample): ((SyntheticKeyboardEvent<HTMLAnchorElement>) => any) => {
    return (e: SyntheticKeyboardEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) {
        window.open(e.currentTarget.href, "_blank");
      } else {
        props.onChange(sample);
      }
    };
  };

  if (sampleIndex > 0) {
    const prevSample = props.experimentSamples.get(sampleIndex - 1);
    prevButton = (
      <a
        className="btn btn-sm btn-primary"
        title={`Previous: ${prevSample.get("name")}`}
        href={linkHref(prevSample)}
        onClick={onLinkClick(prevSample)}
      >
        ◀
      </a>
    );
  }

  if (sampleIndex < props.experimentSamples.size - 1) {
    const nextSample = props.experimentSamples.get(sampleIndex + 1);
    nextButton = (
      <a
        className="btn btn-sm btn-primary"
        title={`Next: ${nextSample.get("name")}`}
        href={linkHref(nextSample)}
        onClick={onLinkClick(nextSample)}
      >
        ▶
      </a>
    );
  }

  const onSelectChange = (e: SyntheticInputEvent<*>) => {
    const newId = parseInt(e.currentTarget.value);
    const sample = props.experimentSamples.find((s) => s.get("id") === newId);

    if (sample) {
      props.onChange(sample);
    }
  };

  let tableFeatures = props.features;
  if (props.features.size > 15) {
    const displayFeatures = ["patient", "batch", "treatment"];
    tableFeatures = props.features.filter((f) => displayFeatures.includes(f.name.toLowerCase()));
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <h3 className="text-truncate" title={props.sample.get("name")}>
            {props.sample.get("name")}
          </h3>
        </div>

        <div className="col-12 col-lg-4 text-right text-md-center">
          {prevButton}
          &nbsp;
          <select className="col-8" value="" onChange={onSelectChange}>
            <option>Go to sample…</option>
            {props.experimentSamples.map((s, i) => (
              <option key={i} value={s.get("id")}>
                {s.get("name")}
              </option>
            ))}
          </select>
          &nbsp;
          {nextButton}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>File Name</th>
                {tableFeatures.map((f, i) => (
                  <th key={i}>{f.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{props.sample.get("file_file_name")}</th>
                {tableFeatures.map((f, i) => (
                  <td key={i}>{props.sample.featureValueDisplay(f)}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <SampleQualityControlTable samples={[props.sample]} thresholds={props.experiment.get("qc_metric_thresholds") || {}} />
          <p>
            Created by <strong>{props.sample.get("create_user_email")}</strong> on&nbsp;
            <strong>{props.sample.get("created_at_ymd")}</strong> for experiment&nbsp;
            <strong>
              <Link to={`${expPath}/${props.experiment.get("id")}`}>{props.experiment.get("name")}</Link>
            </strong>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
