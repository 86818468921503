// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var ReactRouterDom = require("react-router-dom");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var CircleIcon$Astrolabe = require("../experiments/CircleIcon.bs.js");
var ReactRouter$Astrolabe = require("../../utils/ReactRouter.bs.js");
var ExperimentHeader$Astrolabe = require("../header/ExperimentHeader.bs.js");
var ButtonWithOnClick$Astrolabe = require("../experiments/ButtonWithOnClick.bs.js");
var ItemMultiSelector$Astrolabe = require("../experiments/ItemMultiSelector.bs.js");

function str(prim) {
  return prim;
}

function DEAViewHeader(Props) {
  var kit = Props.kit;
  var match_ = Props.match;
  var availableMarkerChannels = Props.availableMarkerChannels;
  var selectedMarkerChannels = Props.selectedMarkerChannels;
  var onDeselectMarkerChannels = Props.onDeselectMarkerChannels;
  var onSelectMarkerChannels = Props.onSelectMarkerChannels;
  var availableCellSubsetLevels = Props.availableCellSubsetLevels;
  var selectedCellSubsetLevel = Props.selectedCellSubsetLevel;
  var onSelectCellSubsetLevel = Props.onSelectCellSubsetLevel;
  var availableCellSubsets = Props.availableCellSubsets;
  var selectedCellSubsets = Props.selectedCellSubsets;
  var onDeselectCellSubsets = Props.onDeselectCellSubsets;
  var onSelectCellSubsets = Props.onSelectCellSubsets;
  var reducer = function (state, action) {
    if (action[0] >= 462160309) {
      return {
              visibleSelector: state.visibleSelector,
              selectedSectionIdx: action[1]
            };
    }
    var selector = action[1];
    if (state.visibleSelector !== selector) {
      return {
              visibleSelector: selector,
              selectedSectionIdx: state.selectedSectionIdx
            };
    } else {
      return {
              visibleSelector: /* None */870530776,
              selectedSectionIdx: state.selectedSectionIdx
            };
    }
  };
  var match = React.useReducer(reducer, {
        visibleSelector: /* None */870530776,
        selectedSectionIdx: 0
      });
  var dispatch = match[1];
  var state = match[0];
  var displaySelectionPreview = function (onClose, items) {
    var len = items.length;
    var has_more = len > 3;
    var items$1 = items.map((function (item, i) {
            return React.createElement(ButtonWithOnClick$Astrolabe.make, {
                        name: item,
                        className: "ml-1",
                        onClick: onClose,
                        key: String(i)
                      });
          }));
    var more = function (n) {
      return ButtonWithOnClick$Astrolabe.container("ml-1", undefined, String(n) + " More", null);
    };
    if (has_more) {
      return [
              Caml_array.caml_array_get(items$1, 0),
              Caml_array.caml_array_get(items$1, 1),
              Caml_array.caml_array_get(items$1, 2),
              more(len - 3 | 0)
            ];
    } else {
      return items$1;
    }
  };
  var match$1 = state.visibleSelector;
  return React.createElement("div", {
              className: "sticky-top pt-2",
              style: {
                background: "white",
                top: ExperimentHeader$Astrolabe.getNavHeightPx(70, undefined)
              }
            }, React.createElement("div", {
                  className: "block d-flex align-items-start"
                }, React.createElement("div", {
                      className: "mr-auto"
                    }, React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps(Constants$Astrolabe.experimentIdPath(match_.params.id) + "/analyses", "< All Analyses", undefined)), React.createElement("h3", {
                          className: "mt-2"
                        }, kit.name)), React.createElement("div", undefined, React.createElement("div", {
                          className: "d-flex flex-row align-items-center justify-content-end"
                        }, "View expression of ", state.visibleSelector !== /* MarkerChannels */835304010 ? displaySelectionPreview((function (item) {
                                  return Curry._1(onSelectMarkerChannels, Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedMarkerChannels));
                                }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedMarkerChannels)) : null, React.createElement("div", {
                              className: "ml-1 py-2",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* `ToggleSelector */[
                                              -794822925,
                                              /* MarkerChannels */835304010
                                            ]);
                                })
                            }, React.createElement(CircleIcon$Astrolabe.make, {
                                  backgroundColor: /* Bootstrap */Block.__(0, ["bg-info"]),
                                  cursor: "pointer",
                                  size: 28.0,
                                  textColor: /* Bootstrap */Block.__(0, ["text-white"]),
                                  tooltip: state.visibleSelector === /* MarkerChannels */835304010 ? "Minimize marker channels selector" : "Add or remove specific marker channels to analyze in-depth",
                                  text: state.visibleSelector === /* MarkerChannels */835304010 ? "-" : "+"
                                }))), React.createElement("div", {
                          className: "d-flex flex-row align-items-center justify-content-end"
                        }, "In", React.createElement("span", {
                              className: "mx-1"
                            }, Curry._1(Util$Astrolabe.$$String.capitalize, selectedCellSubsetLevel)), "Subsets", state.visibleSelector !== /* CellSubsets */864285295 ? displaySelectionPreview((function (item) {
                                  return Curry._1(onSelectCellSubsets, Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedCellSubsets));
                                }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedCellSubsets)) : null, React.createElement("div", {
                              className: "ml-1 py-2",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* `ToggleSelector */[
                                              -794822925,
                                              /* CellSubsets */864285295
                                            ]);
                                })
                            }, React.createElement(CircleIcon$Astrolabe.make, {
                                  backgroundColor: /* Bootstrap */Block.__(0, ["bg-info"]),
                                  cursor: "pointer",
                                  size: 28.0,
                                  textColor: /* Bootstrap */Block.__(0, ["text-white"]),
                                  tooltip: state.visibleSelector === /* CellSubsets */864285295 ? "Minimize cell subset selector" : "Add or remove specific cell subsets to analyze in-depth",
                                  text: state.visibleSelector === /* CellSubsets */864285295 ? "-" : "+"
                                }))))), match$1 !== 864285295 ? (
                match$1 >= 870530776 ? React.createElement("hr", {
                        className: "my-2"
                      }) : React.createElement(ItemMultiSelector$Astrolabe.make, {
                        availableSections: Curry._1(Util$Astrolabe.$$String.$$Set.singleton, "Channels"),
                        selectedSectionIdx: 0,
                        setSelectedSectionIdx: (function (prim) {
                            
                          }),
                        availableItems: availableMarkerChannels,
                        selectedItems: selectedMarkerChannels,
                        onDeselectItems: onDeselectMarkerChannels,
                        onSelectItems: onSelectMarkerChannels,
                        title: "Filter Analysis Channels",
                        onClickItem: (function (item) {
                            return Curry._1(onSelectMarkerChannels, Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedMarkerChannels));
                          })
                      })
              ) : React.createElement(ItemMultiSelector$Astrolabe.make, {
                    availableSections: availableCellSubsetLevels,
                    selectedSectionIdx: state.selectedSectionIdx,
                    setSelectedSectionIdx: (function (idx) {
                        return Curry._1(dispatch, /* `SetSelectedSectionIdx */[
                                    462160309,
                                    idx
                                  ]);
                      }),
                    availableItems: availableCellSubsets,
                    selectedItems: selectedCellSubsets,
                    onDeselectItems: onDeselectCellSubsets,
                    onSelectItems: onSelectCellSubsets,
                    title: "Filter Analysis Subsets",
                    onClickItem: (function (item) {
                        return Curry._1(onSelectCellSubsets, Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedCellSubsets));
                      }),
                    onChangeSection: onSelectCellSubsetLevel
                  }));
}

var $$String$1;

var make = DEAViewHeader;

exports.$$String = $$String$1;
exports.str = str;
exports.make = make;
/* react Not a pure module */
