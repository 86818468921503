// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var Modal$Astrolabe = require("../../Modal.bs.js");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var ReactScrollSync = require("react-scroll-sync");
var JsModels$Astrolabe = require("../../../models/JsModels.bs.js");
var SortByDropdown$Astrolabe = require("../SortByDropdown.bs.js");
var DataTableHeatMap$Astrolabe = require("../../DataTableHeatMap.bs.js");
var FrequencyBoxPlot$Astrolabe = require("./FrequencyBoxPlot.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var CSNBarChartSettings$Astrolabe = require("./CSNBarChartSettings.bs.js");
var GraphDownloadButton$Astrolabe = require("../../GraphDownloadButton.bs.js");
var CellSubsetFrequencyBarChart$Astrolabe = require("./CellSubsetFrequencyBarChart.bs.js");
var ExperimentDifferentialAbundance$Astrolabe = require("../../../models/ExperimentDifferentialAbundance.bs.js");

function renderChartError(message) {
  return React.createElement("div", {
              className: "col-12"
            }, React.createElement("div", {
                  className: "col-12 alert alert-danger mt-4 text-center",
                  role: "alert"
                }, ReasonReactHelpers$Astrolabe.str(message)));
}

var Exn = Caml_exceptions.create("CSNBarCharts-Astrolabe.Exn");

function reducer(state, action) {
  if (action.tag) {
    return {
            activeTab: state.activeTab,
            cellSubsetSettings: action[0]
          };
  } else {
    return {
            activeTab: action[0],
            cellSubsetSettings: state.cellSubsetSettings
          };
  }
}

function CSNBarCharts(Props) {
  var cellSubsetFrequencyDataTable = Props.cellSubsetFrequencyDataTable;
  var closeCellSubset = Props.closeCellSubset;
  var differentialAbundanceData = Props.differentialAbundanceData;
  var experimentName = Props.experimentName;
  var experimentStatistics = Props.experimentStatistics;
  var handleRowSortDirClick = Props.handleRowSortDirClick;
  var onRowSortChange = Props.onRowSortChange;
  var rowSort = Props.rowSort;
  var rowSortAscending = Props.rowSortAscending;
  var samples = Props.samples;
  var selectedColumnLabels = Props.selectedColumnLabels;
  var selectedFeature = Props.selectedFeature;
  var showBarCharts = Props.showBarCharts;
  var showImmunoHeatMaps = Props.showImmunoHeatMaps;
  var sortRowOptions = Props.sortRowOptions;
  var initialState_cellSubsetSettings = { };
  var initialState = {
    activeTab: /* FreqPerSample */1,
    cellSubsetSettings: initialState_cellSubsetSettings
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var __x = (selectedFeature == null) ? undefined : Caml_option.some(selectedFeature);
  var selectedFeature$1 = Curry._2(Util$Astrolabe.$$Option.map, __x, Feature$Astrolabe.fromImmutableJs);
  var samples$1 = $$Array.map(Sample$Astrolabe.Compat.fromImmutableJs, samples);
  var barChartGraphRefs = React.useRef([]);
  var boxPlotGraphRefs = React.useRef([]);
  var heatMapGraphRefs = React.useRef([]);
  var subsetRefs = React.useRef([]);
  var prevSelectedLabels = React.useRef([]);
  var initialCellSubsetSettings = {
    isMinimized: false,
    barChartMax: undefined,
    showSettingsModal: false
  };
  React.useEffect((function () {
          var prev = ReasonReactHelpers$Astrolabe.getCurrentRef(prevSelectedLabels);
          if (prev.length > selectedColumnLabels.length) {
            var newCellSubsetSettings = Util$Astrolabe.Dict.dup(state.cellSubsetSettings);
            var removedSubset = Caml_array.caml_array_get(prev.filter((function (el) {
                        return !selectedColumnLabels.includes(el);
                      })), 0);
            var match = Js_dict.get(newCellSubsetSettings, removedSubset);
            if (match !== undefined) {
              newCellSubsetSettings[removedSubset] = initialCellSubsetSettings;
              Curry._1(dispatch, /* SetCellSubsetSettings */Block.__(1, [newCellSubsetSettings]));
            }
            
          }
          prevSelectedLabels.current = selectedColumnLabels;
          
        }), [selectedColumnLabels]);
  var updateCellSubsettings = function (cellSubsetName, updatedCellSubsetSettings) {
    var newCellSubsetSettingsState = Util$Astrolabe.Dict.dup(state.cellSubsetSettings);
    newCellSubsetSettingsState[cellSubsetName] = updatedCellSubsetSettings;
    return Curry._1(dispatch, /* SetCellSubsetSettings */Block.__(1, [newCellSubsetSettingsState]));
  };
  var filename = function (cellSubset, selectedFeature) {
    var parts = selectedFeature !== undefined ? List.append(/* :: */[
            cellSubset,
            /* [] */0
          ], /* :: */[
            Feature$Astrolabe.name(Caml_option.valFromOption(selectedFeature)),
            /* [] */0
          ]) : /* :: */[
        cellSubset,
        /* [] */0
      ];
    return $$Array.of_list(parts).join("_");
  };
  var dataTableRows = cellSubsetFrequencyDataTable.toOrderedJSONRows();
  var sampleNameSortOrder = experimentStatistics.suggested_order.sample_name_order[rowSort];
  var canRunDiffAnalysis = selectedFeature$1 !== undefined ? ExperimentDifferentialAbundance$Astrolabe.hasDataForId(differentialAbundanceData, Feature$Astrolabe.id(Caml_option.valFromOption(selectedFeature$1))) : true;
  var samplesByName = Js_dict.fromArray($$Array.map((function (s) {
              return /* tuple */[
                      Sample$Astrolabe.name(s),
                      s
                    ];
            }), samples$1));
  var toggleOpen = function (cellSubsetName) {
    var settings = Js_dict.get(state.cellSubsetSettings, cellSubsetName);
    if (settings !== undefined) {
      return !settings.isMinimized;
    } else {
      return true;
    }
  };
  if (canRunDiffAnalysis) {
    return ReasonReactHelpers$Astrolabe.arr($$Array.mapi((function (i, cellSubset) {
                      var log10Label = selectedFeature$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                  className: "text-muted pr-2 pl-1 font-weight-light",
                                  style: {
                                    fontSize: "130%",
                                    transform: "scale(.5, 1)"
                                  }
                                }, ReasonReactHelpers$Astrolabe.str("|")), React.createElement("div", {
                                  className: " text-muted d-flex flex-column"
                                }, React.createElement("div", {
                                      style: {
                                        fontSize: "75%",
                                        lineHeight: "initial"
                                      }
                                    }, ReasonReactHelpers$Astrolabe.str("-log10(FDR)")), React.createElement("div", {
                                      style: {
                                        fontSize: "90%",
                                        lineHeight: "initial"
                                      }
                                    }, ReasonReactHelpers$Astrolabe.str(ExperimentDifferentialAbundance$Astrolabe.getFDR(differentialAbundanceData, Caml_option.valFromOption(selectedFeature$1), cellSubset).toFixed(3))))) : null;
                      var featureValues = selectedFeature$1 !== undefined ? Feature$Astrolabe.displayValues(Caml_option.valFromOption(selectedFeature$1)) : [];
                      var barChartData;
                      if (selectedFeature$1 !== undefined) {
                        var feature = Caml_option.valFromOption(selectedFeature$1);
                        barChartData = $$Array.map((function (row) {
                                var sampleName = row.rowLabel;
                                var sample = samplesByName[sampleName];
                                var featureValue = Sample$Astrolabe.featureValueDisplay(feature, sample);
                                return {
                                        value: row[cellSubset] * 100,
                                        featureValue: featureValue
                                      };
                              }), dataTableRows);
                      } else {
                        barChartData = $$Array.map((function (row) {
                                return {
                                        value: row[cellSubset] * 100,
                                        featureValue: "frequency"
                                      };
                              }), dataTableRows);
                      }
                      var defaultBarChartMax = barChartData.reduce((function (acc, frequency) {
                              var freqValue = frequency.value;
                              if (acc > freqValue) {
                                return acc;
                              } else {
                                return freqValue;
                              }
                            }), 0.0) * 1.2;
                      var settings = Js_dict.get(state.cellSubsetSettings, cellSubset);
                      var barChartMax = settings !== undefined ? Util$Astrolabe.or(settings.barChartMax, defaultBarChartMax) : defaultBarChartMax;
                      var match;
                      if (showBarCharts) {
                        var seriesLabels = cellSubsetFrequencyDataTable.rowLabels;
                        match = /* tuple */[
                          React.createElement(CellSubsetFrequencyBarChart$Astrolabe.make, {
                                barChartData: barChartData,
                                featureValues: featureValues,
                                maxChartValue: barChartMax,
                                seriesLabels: seriesLabels,
                                width: 500 + Caml_int32.imul(seriesLabels.length, 17) | 0
                              }),
                          React.createElement(GraphDownloadButton$Astrolabe.make, {
                                prefix: experimentName,
                                name: "Bar Chart",
                                graphType: /* Svg */4153924,
                                container: (function (param) {
                                    return Caml_array.caml_array_get(barChartGraphRefs.current, i);
                                  }),
                                filenames: filename(cellSubset, selectedFeature$1)
                              })
                        ];
                      } else {
                        match = /* tuple */[
                          null,
                          null
                        ];
                      }
                      var barChartDownloadButton = match[1];
                      var barChart = match[0];
                      var match$1;
                      if (selectedFeature$1 !== undefined) {
                        var feature$1 = Caml_option.valFromOption(selectedFeature$1);
                        var xAxisLabel = Feature$Astrolabe.name(feature$1);
                        var boxPlotData = $$Array.map((function (featureValue) {
                                return JsModels$Astrolabe.ExperimentStatistics.boxPlotDatum(cellSubset, Feature$Astrolabe.id(feature$1), featureValue, experimentStatistics);
                              }), featureValues);
                        if (boxPlotData.length === 0) {
                          match$1 = /* tuple */[
                            renderChartError("Unable to show box plots for this experiment"),
                            null
                          ];
                        } else {
                          var featureValuesLength = featureValues.length;
                          var moreThanFourFeatureValues = featureValuesLength > 4;
                          match$1 = /* tuple */[
                            React.createElement(FrequencyBoxPlot$Astrolabe.make, {
                                  data: boxPlotData,
                                  dataLabels: featureValues,
                                  domainPadding: moreThanFourFeatureValues ? 35 : 75,
                                  maxChartValue: barChartMax,
                                  rightPadding: moreThanFourFeatureValues ? 55 : 0,
                                  width: moreThanFourFeatureValues ? Caml_int32.imul(featureValuesLength, 90) : 325,
                                  xAxisLabel: xAxisLabel
                                }),
                            React.createElement(GraphDownloadButton$Astrolabe.make, {
                                  prefix: experimentName,
                                  name: "Box Plot",
                                  graphType: /* Svg */4153924,
                                  container: (function (param) {
                                      return Caml_array.caml_array_get(boxPlotGraphRefs.current, i);
                                    }),
                                  filenames: filename(cellSubset, selectedFeature$1)
                                })
                          ];
                        }
                      } else {
                        match$1 = /* tuple */[
                          null,
                          null
                        ];
                      }
                      var match$2;
                      if (showImmunoHeatMaps && experimentStatistics.hasSubsetChannelData()) {
                        var unfilteredTable = experimentStatistics.subsetChannelDataTable(cellSubset).applySort(sampleNameSortOrder, Js_null_undefined.fromOption(rowSortAscending));
                        var sampleNames = cellSubsetFrequencyDataTable.rowLabels;
                        var subsetChannelDataTable = unfilteredTable.filterByRowLabel((function (rowLabel) {
                                return sampleNames.includes(rowLabel);
                              }));
                        var downloadFilename = filename(cellSubset, selectedFeature$1);
                        var downloadFilenameId = Util$Astrolabe.$$String.toIdentifier(downloadFilename);
                        match$2 = /* tuple */[
                          React.createElement(DataTableHeatMap$Astrolabe.make, {
                                dataTable: subsetChannelDataTable,
                                id: downloadFilenameId
                              }),
                          React.createElement(GraphDownloadButton$Astrolabe.make, {
                                prefix: experimentName,
                                name: "Heat Map",
                                graphType: /* Canvas */-321468168,
                                container: (function (param) {
                                    return Caml_array.caml_array_get(heatMapGraphRefs.current, i);
                                  }),
                                filenames: downloadFilename
                              })
                        ];
                      } else {
                        match$2 = /* tuple */[
                          null,
                          null
                        ];
                      }
                      var handleShowModal = function (cellSubsetName, status) {
                        var settings = Util$Astrolabe.or(Js_dict.get(state.cellSubsetSettings, cellSubsetName), initialCellSubsetSettings);
                        var updatedCellSubsetSettings_isMinimized = settings.isMinimized;
                        var updatedCellSubsetSettings_barChartMax = settings.barChartMax;
                        var updatedCellSubsetSettings = {
                          isMinimized: updatedCellSubsetSettings_isMinimized,
                          barChartMax: updatedCellSubsetSettings_barChartMax,
                          showSettingsModal: status
                        };
                        return updateCellSubsettings(cellSubsetName, updatedCellSubsetSettings);
                      };
                      var renderSettingsModal = function (cellSubsetName) {
                        var settings = Js_dict.get(state.cellSubsetSettings, cellSubsetName);
                        return React.createElement(Modal$Astrolabe.make, {
                                    onCancel: (function (param) {
                                        return handleShowModal(cellSubsetName, false);
                                      }),
                                    title: cellSubsetName + " Settings",
                                    visible: settings !== undefined ? settings.showSettingsModal : false,
                                    children: React.createElement(CSNBarChartSettings$Astrolabe.make, {
                                          yMax: barChartMax,
                                          onDone: (function (yMax) {
                                              var settings = Util$Astrolabe.or(Js_dict.get(state.cellSubsetSettings, cellSubsetName), initialCellSubsetSettings);
                                              var updatedCellSubsetSettings_isMinimized = settings.isMinimized;
                                              var updatedCellSubsetSettings_barChartMax = yMax;
                                              var updatedCellSubsetSettings = {
                                                isMinimized: updatedCellSubsetSettings_isMinimized,
                                                barChartMax: updatedCellSubsetSettings_barChartMax,
                                                showSettingsModal: false
                                              };
                                              return updateCellSubsettings(cellSubsetName, updatedCellSubsetSettings);
                                            })
                                        })
                                  });
                      };
                      var handleActiveTab = function (tab) {
                        Curry._1(dispatch, /* SetActiveTab */Block.__(0, [tab]));
                        Caml_array.caml_array_get(ReasonReactHelpers$Astrolabe.getCurrentRef(subsetRefs), i).scrollIntoView({
                              behavior: "smooth",
                              block: "start"
                            });
                        
                      };
                      var tmp;
                      if (toggleOpen(cellSubset)) {
                        var match$3 = state.activeTab;
                        tmp = React.createElement(React.Fragment, undefined, match$3 ? (
                                selectedFeature$1 !== undefined ? React.createElement(ReactScrollSync.ScrollSyncPane, {
                                        children: React.createElement("div", {
                                              className: "d-flex flex-row mb-3 horizontal-scroll"
                                            }, React.createElement("div", {
                                                  ref: (function (node) {
                                                      return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, i, boxPlotGraphRefs);
                                                    }),
                                                  className: "p-2 row"
                                                }, React.createElement("div", {
                                                      className: "col-12"
                                                    }, match$1[1], match$1[0])), React.createElement("div", {
                                                  ref: (function (node) {
                                                      return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, i, barChartGraphRefs);
                                                    }),
                                                  className: "p-2 row"
                                                }, React.createElement("div", {
                                                      className: "col-12"
                                                    }, barChartDownloadButton, barChart)))
                                      }) : React.createElement("div", {
                                        className: "row col-12"
                                      }, React.createElement("div", {
                                            ref: (function (node) {
                                                return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, i, barChartGraphRefs);
                                              }),
                                            className: "col-12"
                                          }, barChartDownloadButton, React.createElement(ReactScrollSync.ScrollSyncPane, {
                                                children: React.createElement("div", {
                                                      className: "row horizontal-scroll"
                                                    }, barChart)
                                              })))
                              ) : React.createElement("div", {
                                    className: "row mt-4"
                                  }, React.createElement("div", {
                                        ref: (function (node) {
                                            return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, i, heatMapGraphRefs);
                                          }),
                                        className: "col-12"
                                      }, match$2[1], React.createElement(ReactScrollSync.ScrollSyncPane, {
                                            children: React.createElement("div", {
                                                  className: "row"
                                                }, match$2[0])
                                          }))));
                      } else {
                        tmp = null;
                      }
                      return React.createElement("div", {
                                  key: String(i),
                                  ref: (function (node) {
                                      return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, i, subsetRefs);
                                    }),
                                  className: "col-12"
                                }, React.createElement("hr", undefined), React.createElement("div", {
                                      className: "col-12"
                                    }, React.createElement("div", {
                                          className: "d-flex justify-content-between"
                                        }, React.createElement("div", {
                                              className: "d-flex flex-column"
                                            }, React.createElement("div", {
                                                  className: "d-flex"
                                                }, React.createElement("div", {
                                                      className: "cursor-pointer show-on-hover mr-3",
                                                      onClick: (function (param) {
                                                          var settings = Js_dict.get(state.cellSubsetSettings, cellSubset);
                                                          var updatedCellSubsetSettings = settings !== undefined ? ({
                                                                isMinimized: !settings.isMinimized,
                                                                barChartMax: settings.barChartMax,
                                                                showSettingsModal: settings.showSettingsModal
                                                              }) : ({
                                                                isMinimized: true,
                                                                barChartMax: undefined,
                                                                showSettingsModal: false
                                                              });
                                                          return updateCellSubsettings(cellSubset, updatedCellSubsetSettings);
                                                        })
                                                    }, toggleOpen(cellSubset) ? React.createElement(Icons$Astrolabe.ArrowDown.make, {
                                                            className: "black",
                                                            height: "15",
                                                            width: "15"
                                                          }) : React.createElement(Icons$Astrolabe.ArrowRight.make, {
                                                            className: "black",
                                                            height: "15",
                                                            width: "15"
                                                          })), React.createElement("div", {
                                                      className: "d-flex pl-1"
                                                    }, React.createElement("h4", {
                                                          className: "pr-2"
                                                        }, ReasonReactHelpers$Astrolabe.str(cellSubset)), log10Label)), toggleOpen(cellSubset) ? React.createElement("div", {
                                                    className: "d-flex mb-4"
                                                  }, React.createElement("div", {
                                                        className: "mr-4"
                                                      }, React.createElement("span", {
                                                            className: state.activeTab === /* FreqPerSample */1 ? "text-info cursor-default" : "cursor-pointer",
                                                            title: "Subset frequencies across all samples",
                                                            onClick: (function (param) {
                                                                return handleActiveTab(/* FreqPerSample */1);
                                                              })
                                                          }, ReasonReactHelpers$Astrolabe.str("Frequency Per Sample"))), React.createElement("div", undefined, React.createElement("span", {
                                                            className: state.activeTab === /* Immunophenotyping */0 ? "text-info cursor-default" : "cursor-pointer",
                                                            title: "Subset immunophenotyping heat map across all samples",
                                                            onClick: (function (param) {
                                                                return handleActiveTab(/* Immunophenotyping */0);
                                                              })
                                                          }, ReasonReactHelpers$Astrolabe.str("Immunophenotyping")))) : null), toggleOpen(cellSubset) ? React.createElement("div", undefined, renderSettingsModal(cellSubset), React.createElement("div", {
                                                    className: "d-flex justify-content-end"
                                                  }, React.createElement("div", {
                                                        className: "align-self-end mb-1 cursor-pointer",
                                                        title: "Customize the figures for this cell subset.",
                                                        onClick: (function (param) {
                                                            return handleShowModal(cellSubset, true);
                                                          })
                                                      }, React.createElement(Icons$Astrolabe.Gear.make, {
                                                            height: "25",
                                                            width: "25"
                                                          })), React.createElement(SortByDropdown$Astrolabe.make, {
                                                        handleSortChange: (function (e) {
                                                            return Curry._2(onRowSortChange, ReasonReactHelpers$Astrolabe.valueFromEvent(e), rowSortAscending);
                                                          }),
                                                        klass: "pl-1",
                                                        label: "Sort Samples by",
                                                        onSortClick: handleRowSortDirClick,
                                                        selectOptions: sortRowOptions,
                                                        sortAscending: rowSortAscending,
                                                        tooltip: "Set the order of the samples (X-axis) of the bar chart. This dropdown is synchronized across the page.",
                                                        valueSort: rowSort
                                                      }), React.createElement("button", {
                                                        className: "close ml-4",
                                                        onClick: (function (param) {
                                                            return Curry._1(closeCellSubset, cellSubset);
                                                          })
                                                      }, ReasonReactHelpers$Astrolabe.str("x")))) : React.createElement("button", {
                                                className: "close ml-4",
                                                onClick: (function (param) {
                                                    return Curry._1(closeCellSubset, cellSubset);
                                                  })
                                              }, ReasonReactHelpers$Astrolabe.str("x"))), tmp));
                    }), selectedColumnLabels));
  } else {
    return renderChartError("Unable to run differential abundance analysis for this feature since each feature value only appears once");
  }
}

var $$Option;

var Dict;

var or = Util$Astrolabe.or;

var make = CSNBarCharts;

var $$default = CSNBarCharts;

exports.$$Option = $$Option;
exports.Dict = Dict;
exports.or = or;
exports.renderChartError = renderChartError;
exports.Exn = Exn;
exports.reducer = reducer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
