// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var ppx_printed_query = "fragment DebarcodingKit on DebarcodingKit   {\nid  \ncreateUserEmail  \nname  \nchannels  \nchannelsUsedPerSample  \nmaxSamplesPerFile  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "id");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field id on type DebarcodingKit is missing");
  }
  var value$5 = Js_dict.get(value$1, "createUserEmail");
  var tmp$1;
  if (value$5 !== undefined) {
    var value$6 = Caml_option.valFromOption(value$5);
    var match = Js_json.decodeNull(value$6);
    if (match !== undefined) {
      tmp$1 = undefined;
    } else {
      var value$7 = Js_json.decodeString(value$6);
      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
    }
  } else {
    tmp$1 = undefined;
  }
  var value$8 = Js_dict.get(value$1, "name");
  var tmp$2;
  if (value$8 !== undefined) {
    var value$9 = Caml_option.valFromOption(value$8);
    var value$10 = Js_json.decodeString(value$9);
    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
  } else {
    tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type DebarcodingKit is missing");
  }
  var value$11 = Js_dict.get(value$1, "channels");
  var value$12 = Js_dict.get(value$1, "channelsUsedPerSample");
  var tmp$3;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeNumber(value$13);
    tmp$3 = value$14 !== undefined ? value$14 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$13));
  } else {
    tmp$3 = Js_exn.raiseError("graphql_ppx: Field channelsUsedPerSample on type DebarcodingKit is missing");
  }
  var value$15 = Js_dict.get(value$1, "maxSamplesPerFile");
  var tmp$4;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeNumber(value$16);
    tmp$4 = value$17 !== undefined ? value$17 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$16));
  } else {
    tmp$4 = Js_exn.raiseError("graphql_ppx: Field maxSamplesPerFile on type DebarcodingKit is missing");
  }
  return {
          id: tmp,
          createUserEmail: tmp$1,
          name: tmp$2,
          channels: value$11 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$11))).map((function (value) {
                    var value$1 = Js_json.decodeString(value);
                    if (value$1 !== undefined) {
                      return value$1;
                    } else {
                      return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                    }
                  })) : Js_exn.raiseError("graphql_ppx: Field channels on type DebarcodingKit is missing"),
          channelsUsedPerSample: tmp$3,
          maxSamplesPerFile: tmp$4
        };
}

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var DebarcodingKit = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "DebarcodingKit",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var DebarcodingKitFragment = {
  DebarcodingKit: DebarcodingKit
};

exports.DebarcodingKitFragment = DebarcodingKitFragment;
/* No side effect */
