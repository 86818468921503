// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Models = require("models");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Cookies = require("utils/cookies");
var Util$Astrolabe = require("../../models/Util.bs.js");
var GraphQL$Astrolabe = require("../../models/GraphQL.bs.js");
var NavTitle$Astrolabe = require("../NavTitle.bs.js");
var CircleIcon$Astrolabe = require("../experiments/CircleIcon.bs.js");
var FetchUtils$Astrolabe = require("../../utils/FetchUtils.bs.js");
var ASDSHeatMap$Astrolabe = require("./ASDSHeatMap.bs.js");
var WelcomeModal$Astrolabe = require("./WelcomeModal.bs.js");
var ButtonWithOnClick$Astrolabe = require("../experiments/ButtonWithOnClick.bs.js");
var ItemMultiSelector$Astrolabe = require("../experiments/ItemMultiSelector.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

var ppx_printed_query = "query getHeatMapData($cellSubsetLevel: CellSubsetLevel!, $treatment: Treatment!, $antibodies: [String!]!, $statistic: Statistic!)  {\nasds  {\nheatMapDataTable(cellSubsetLevel: $cellSubsetLevel, treatment: $treatment, antibodies: $antibodies, statistic: $statistic)  {\ncolumnLabels  \ncolumns  \nrowLabels  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "asds");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "heatMapDataTable");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var match = Js_json.decodeNull(value$5);
      if (match !== undefined) {
        tmp$1 = undefined;
      } else {
        var value$6 = Js_option.getExn(Js_json.decodeObject(value$5));
        var value$7 = Js_dict.get(value$6, "columnLabels");
        var value$8 = Js_dict.get(value$6, "columns");
        var value$9 = Js_dict.get(value$6, "rowLabels");
        tmp$1 = {
          columnLabels: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map((function (value) {
                    var value$1 = Js_json.decodeString(value);
                    if (value$1 !== undefined) {
                      return value$1;
                    } else {
                      return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                    }
                  })) : Js_exn.raiseError("graphql_ppx: Field columnLabels on type DataTable is missing"),
          columns: value$8 !== undefined ? Caml_option.valFromOption(value$8) : Js_exn.raiseError("graphql_ppx: Field columns on type DataTable is missing"),
          rowLabels: value$9 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$9))).map((function (value) {
                    var value$1 = Js_json.decodeString(value);
                    if (value$1 !== undefined) {
                      return value$1;
                    } else {
                      return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                    }
                  })) : Js_exn.raiseError("graphql_ppx: Field rowLabels on type DataTable is missing")
        };
      }
    } else {
      tmp$1 = undefined;
    }
    tmp = {
      heatMapDataTable: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field asds on type Query is missing");
  }
  return {
          asds: tmp
        };
}

function json_of_CellSubsetLevel(value) {
  if (value >= 500777805) {
    return "ASSIGNMENT";
  } else {
    return "PROFILING";
  }
}

function json_of_Treatment(value) {
  if (value >= 74759758) {
    return "FRESH";
  } else {
    return "FIXED";
  }
}

function json_of_Statistic(value) {
  if (value !== 78066387) {
    if (value >= 826994442) {
      return "MEDIAN";
    } else {
      return "FIXATION";
    }
  } else {
    return "PERCENT_POSITIVE";
  }
}

function make(cellSubsetLevel, treatment, antibodies, statistic, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    json_of_Treatment(treatment)
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "statistic",
                    json_of_Statistic(statistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var cellSubsetLevel = variables.cellSubsetLevel;
  var treatment = variables.treatment;
  var antibodies = variables.antibodies;
  var statistic = variables.statistic;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    json_of_Treatment(treatment)
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "statistic",
                    json_of_Statistic(statistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(cellSubsetLevel, treatment, antibodies, statistic, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "cellSubsetLevel",
                  json_of_CellSubsetLevel(cellSubsetLevel)
                ],
                /* tuple */[
                  "treatment",
                  json_of_Treatment(treatment)
                ],
                /* tuple */[
                  "antibodies",
                  antibodies.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "statistic",
                  json_of_Statistic(statistic)
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, cellSubsetLevel, treatment, antibodies, statistic, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "cellSubsetLevel",
                      json_of_CellSubsetLevel(cellSubsetLevel)
                    ],
                    /* tuple */[
                      "treatment",
                      json_of_Treatment(treatment)
                    ],
                    /* tuple */[
                      "antibodies",
                      antibodies.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "statistic",
                      json_of_Statistic(statistic)
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetIntensityHeatMapData = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_CellSubsetLevel: json_of_CellSubsetLevel,
  json_of_Treatment: json_of_Treatment,
  json_of_Statistic: json_of_Statistic,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetIntensityHeatMapDataQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

var initState_antibodyStaining = {
  allAntibodies: [],
  defaultAntibodies: []
};

var initState_allCellSubsets = [];

var initState_defaultCellSubsets = [];

var initState_introHidden = Cookies.getCookie("antibody_staining_is_intro_hidden") === "true";

var initState_selectedAntibodies = Util$Astrolabe.$$String.$$Set.empty;

var initState_selectedCellSubsets = Util$Astrolabe.$$String.$$Set.empty;

var initState_welcomeModalVisible = Cookies.getCookie("antibody_staining_is_welcome_modal_seen") === "false";

var initState_cellSubsetLevel = GraphQL$Astrolabe.Types.cellSubsetLevel_of_string("assignment");

var initState_treatment = GraphQL$Astrolabe.Types.treatment_of_string("fresh");

var initState_statistic = GraphQL$Astrolabe.Types.statistic_of_string("median");

var initState = {
  antibodyStaining: initState_antibodyStaining,
  allCellSubsets: initState_allCellSubsets,
  defaultCellSubsets: initState_defaultCellSubsets,
  intensityHeatMapDataTable: undefined,
  introHidden: initState_introHidden,
  selectedAntibodies: initState_selectedAntibodies,
  selectedCellSubsets: initState_selectedCellSubsets,
  antibodySelectDisclosed: false,
  cellSubsetSelectDisclosed: false,
  welcomeModalVisible: initState_welcomeModalVisible,
  cellSubsetLevel: initState_cellSubsetLevel,
  treatment: initState_treatment,
  statistic: initState_statistic
};

var location_search = location.search;

function decodeAntibodyStaining(json) {
  var allAntibodies = Json_decode.field("all_antibodies", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), json);
  var defaultAntibodies = Json_decode.field("default_antibodies", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), json);
  return {
          allAntibodies: allAntibodies,
          defaultAntibodies: defaultAntibodies
        };
}

function fetchAntibodies(onData) {
  return Util$Astrolabe.$$Promise.get(onData, Util$Astrolabe.$$Promise.map(decodeAntibodyStaining, FetchUtils$Astrolabe.getJson(Curry._1(Util$Astrolabe.fmt(/* Format */[
                              /* String_literal */Block.__(11, [
                                  "/antibody_staining.json",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* End_of_format */0
                                    ])
                                ]),
                              "/antibody_staining.json%s"
                            ]), location_search))));
}

function AntibodyStainingIndex(Props) {
  var match = React.useState((function () {
          return initState;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* None */870530776;
        }));
  var setVisibleSelector = match$1[1];
  var visibleSelector = match$1[0];
  var setVisibleSelector$1 = function (selector) {
    if (visibleSelector !== selector) {
      return Curry._1(setVisibleSelector, (function (param) {
                    return selector;
                  }));
    } else {
      return Curry._1(setVisibleSelector, (function (param) {
                    return /* None */870530776;
                  }));
    }
  };
  var displaySelectionPreview = function (onClose, items) {
    var len = items.length;
    var has_more = len > 3;
    var items$1 = items.map((function (item, i) {
            return React.createElement(ButtonWithOnClick$Astrolabe.make, {
                        name: item,
                        className: "ml-1",
                        onClick: onClose,
                        key: String(i)
                      });
          }));
    var more = function (n) {
      return ButtonWithOnClick$Astrolabe.container("ml-1", undefined, String(n) + " More", null);
    };
    if (has_more) {
      return [
              Caml_array.caml_array_get(items$1, 0),
              Caml_array.caml_array_get(items$1, 1),
              Caml_array.caml_array_get(items$1, 2),
              more(len - 3 | 0)
            ];
    } else {
      return items$1;
    }
  };
  var setAntibodyStaining = function (antibodyStaining) {
    return Curry._1(setState, (function (param) {
                  return {
                          antibodyStaining: antibodyStaining,
                          allCellSubsets: state.allCellSubsets,
                          defaultCellSubsets: state.defaultCellSubsets,
                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                          introHidden: Cookies.getCookie("antibody_staining_is_intro_hidden") === "true",
                          selectedAntibodies: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, antibodyStaining.defaultAntibodies),
                          selectedCellSubsets: state.selectedCellSubsets,
                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                          welcomeModalVisible: Cookies.getCookie("antibody_staining_is_welcome_modal_seen") === "false",
                          cellSubsetLevel: state.cellSubsetLevel,
                          treatment: state.treatment,
                          statistic: state.statistic
                        };
                }));
  };
  React.useEffect((function () {
          fetchAntibodies(setAntibodyStaining);
          
        }), ([]));
  var setWelcomeModalSeen = function (value, email) {
    var formData = new FormData();
    formData.set("is_welcome_modal_seen", Pervasives.string_of_bool(value));
    formData.set("email", email);
    return Util$Astrolabe.$$Promise.get((function (param) {
                  return Curry._1(setState, (function (param) {
                                return {
                                        antibodyStaining: state.antibodyStaining,
                                        allCellSubsets: state.allCellSubsets,
                                        defaultCellSubsets: state.defaultCellSubsets,
                                        intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                        introHidden: state.introHidden,
                                        selectedAntibodies: state.selectedAntibodies,
                                        selectedCellSubsets: state.selectedCellSubsets,
                                        antibodySelectDisclosed: state.antibodySelectDisclosed,
                                        cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                        welcomeModalVisible: !value,
                                        cellSubsetLevel: state.cellSubsetLevel,
                                        treatment: state.treatment,
                                        statistic: state.statistic
                                      };
                              }));
                }), FetchUtils$Astrolabe.patch("/antibody_staining.json", formData));
  };
  var handleWelcomeModalCancel = function (param) {
    return setWelcomeModalSeen(true, "<declined>");
  };
  var handleWelcomeModalSave = function (email) {
    return setWelcomeModalSeen(true, email);
  };
  var intro = React.createElement("div", {
        className: "row"
      }, React.createElement("div", {
            className: "col"
          }), React.createElement("div", {
            className: "col-lg-8 col-12"
          }, React.createElement("div", {
                className: "embed-container"
              }, React.createElement(ReasonReactHelpers$Astrolabe.SpreadProps.make, {
                    props: {
                      frameBorder: "0",
                      allow: "autoplay; encrypted-media",
                      allowFullScreen: "{true}"
                    },
                    children: React.createElement("iframe", {
                          src: "https://www.youtube-nocookie.com/embed/yBPdkptpO5g?rel=0&amp;showinfo=0"
                        })
                  })), React.createElement("br", undefined), React.createElement("p", undefined, "The Antibody Staining Data Set (", React.createElement("a", {
                    href: "https://www.frontiersin.org/articles/10.3389/fimmu.2019.01315/full",
                    target: "_blank"
                  }, "Amir et al., Front. Immunol., 11 June 2019"), ") is a comprehensive screen performed by the Mount Sinai HIMC to establish the expression of 326 surface markers\n                across all major circulating immune subsets at single-cell resolution. It is composed of 2,232 mass cytometry\n                samples (", React.createElement("a", {
                    href: "https://flowrepository.org/id/FR-FCM-Z23S",
                    target: "_blank"
                  }, "hosted on FlowRepository"), ") which were analyzed using the Astrolabe Cytometery Platform."), React.createElement("p", undefined, "The Antibody Staining Data Set was generated using BioLegend’s LEGENDScreen™ Human PE Kit (cat#", React.createElement("a", {
                    href: "https://www.biolegend.com/en-us/products/legendscreen-human-pe-kit-10105",
                    target: "_blank"
                  }, "700007"), "). Please contact ", React.createElement("a", {
                    href: "https://www.biolegend.com/en-us/support-tab",
                    target: "_blank"
                  }, "BioLegend Support"), " for any questions about their products or services."), React.createElement("p", undefined, "Need help analyzing your cytometry data? ", React.createElement("a", {
                    href: "https://astrolabediagnostics.com/request-a-demo",
                    target: "_blank"
                  }, "Contact us for a demo"), "!")), React.createElement("div", {
            className: "col"
          }));
  var fetchHeatMapVariables = make(state.cellSubsetLevel, state.treatment, Curry._1(Util$Astrolabe.$$String.$$Set.to_array, state.selectedAntibodies), state.statistic, undefined).variables;
  return React.createElement(GetIntensityHeatMapDataQuery.make, {
              variables: fetchHeatMapVariables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return " Loading… ";
                  }
                  if (!result.tag) {
                    return React.createElement("div", undefined, result[0].message);
                  }
                  var heatMapDataRaw = result[0];
                  var heatMapData = Curry._2(Util$Astrolabe.$$Option.map, Curry._2(Util$Astrolabe.$$Option.map, heatMapDataRaw.asds.heatMapDataTable, (function (prim) {
                              return prim;
                            })), (function (prim) {
                          return new Models.DataTable(prim);
                        }));
                  var dataTable = heatMapDataRaw.asds.heatMapDataTable;
                  var availableCellSubsets = dataTable !== undefined ? Curry._1(Util$Astrolabe.$$String.$$Set.of_array, Caml_option.valFromOption(dataTable).columnLabels) : Util$Astrolabe.$$String.$$Set.empty;
                  var selectedCellSubsets = state.selectedCellSubsets;
                  var onSelectCellSubsets = function (items) {
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: state.selectedAntibodies,
                                          selectedCellSubsets: items,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: state.cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: state.statistic
                                        };
                                }));
                  };
                  var onDeselectCellSubsets = function (items) {
                    var selectedCellSubsets = Curry._2(Util$Astrolabe.$$String.$$Set.diff, availableCellSubsets, items);
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: state.selectedAntibodies,
                                          selectedCellSubsets: selectedCellSubsets,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: state.cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: state.statistic
                                        };
                                }));
                  };
                  var availableAntibodies = Curry._1(Util$Astrolabe.$$String.$$Set.of_array, state.antibodyStaining.allAntibodies);
                  var selectedAntibodies = state.selectedAntibodies;
                  var defaultSelectedAntibodies = Curry._1(Util$Astrolabe.$$String.$$Set.of_array, state.antibodyStaining.defaultAntibodies);
                  var onSelectAntibodies = function (items) {
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: items,
                                          selectedCellSubsets: state.selectedCellSubsets,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: state.cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: state.statistic
                                        };
                                }));
                  };
                  var onDeselectAntibodies = function (items) {
                    var selectedAntibodies = Curry._2(Util$Astrolabe.$$String.$$Set.diff, availableAntibodies, items);
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: selectedAntibodies,
                                          selectedCellSubsets: state.selectedCellSubsets,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: state.cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: state.statistic
                                        };
                                }));
                  };
                  var setStatistic = function (statistic) {
                    var selectedCellSubsets = statistic === /* FIXATION */-744809280 && state.cellSubsetLevel === /* PROFILING */-899121530 ? Util$Astrolabe.$$String.$$Set.empty : state.selectedCellSubsets;
                    var cellSubsetLevel = statistic === /* FIXATION */-744809280 ? /* ASSIGNMENT */500777805 : state.cellSubsetLevel;
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: state.selectedAntibodies,
                                          selectedCellSubsets: selectedCellSubsets,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: statistic
                                        };
                                }));
                  };
                  var setCellSubsetLevel = function (cellSubsetLevel) {
                    return Curry._1(setState, (function (param) {
                                  return {
                                          antibodyStaining: state.antibodyStaining,
                                          allCellSubsets: state.allCellSubsets,
                                          defaultCellSubsets: state.defaultCellSubsets,
                                          intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                          introHidden: state.introHidden,
                                          selectedAntibodies: state.selectedAntibodies,
                                          selectedCellSubsets: Util$Astrolabe.$$String.$$Set.empty,
                                          antibodySelectDisclosed: state.antibodySelectDisclosed,
                                          cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                          welcomeModalVisible: state.welcomeModalVisible,
                                          cellSubsetLevel: cellSubsetLevel,
                                          treatment: state.treatment,
                                          statistic: state.statistic
                                        };
                                }));
                  };
                  var tmp;
                  if (Curry._1(Util$Astrolabe.$$String.$$Set.is_empty, state.selectedAntibodies)) {
                    tmp = React.createElement("h4", undefined, "Please select at least one antibody.");
                  } else {
                    var tmp$1 = {
                      onCellSubsetsChange: (function (selectedCellSubsets) {
                          return Curry._1(setState, (function (param) {
                                        return {
                                                antibodyStaining: state.antibodyStaining,
                                                allCellSubsets: state.allCellSubsets,
                                                defaultCellSubsets: state.defaultCellSubsets,
                                                intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                                introHidden: state.introHidden,
                                                selectedAntibodies: state.selectedAntibodies,
                                                selectedCellSubsets: selectedCellSubsets,
                                                antibodySelectDisclosed: state.antibodySelectDisclosed,
                                                cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                                welcomeModalVisible: state.welcomeModalVisible,
                                                cellSubsetLevel: state.cellSubsetLevel,
                                                treatment: state.treatment,
                                                statistic: state.statistic
                                              };
                                      }));
                        }),
                      selectedAntibodies: state.selectedAntibodies,
                      selectedCellSubsets: state.selectedCellSubsets,
                      treatment: state.treatment,
                      setTreatment: (function (treatment) {
                          return Curry._1(setState, (function (param) {
                                        return {
                                                antibodyStaining: state.antibodyStaining,
                                                allCellSubsets: state.allCellSubsets,
                                                defaultCellSubsets: state.defaultCellSubsets,
                                                intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                                introHidden: state.introHidden,
                                                selectedAntibodies: state.selectedAntibodies,
                                                selectedCellSubsets: state.selectedCellSubsets,
                                                antibodySelectDisclosed: state.antibodySelectDisclosed,
                                                cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                                welcomeModalVisible: state.welcomeModalVisible,
                                                cellSubsetLevel: state.cellSubsetLevel,
                                                treatment: treatment,
                                                statistic: state.statistic
                                              };
                                      }));
                        }),
                      cellSubsetLevel: state.cellSubsetLevel,
                      setCellSubsetLevel: setCellSubsetLevel,
                      statistic: state.statistic,
                      setStatistic: setStatistic
                    };
                    if (heatMapData !== undefined) {
                      tmp$1.data = Caml_option.valFromOption(heatMapData);
                    }
                    tmp = React.createElement(ASDSHeatMap$Astrolabe.make, tmp$1);
                  }
                  return React.createElement(React.Fragment, {
                              children: null
                            }, React.createElement(NavTitle$Astrolabe.TextAndDocumentTitle.make, NavTitle$Astrolabe.TextAndDocumentTitle.makeProps("Antibody Staining Data Set", undefined, undefined)), React.createElement("div", {
                                  className: "d-flex justify-content-end"
                                }, React.createElement("button", {
                                      className: "btn btn-link pr-0",
                                      onClick: (function (param) {
                                          var value = !state.introHidden;
                                          var formData = new FormData();
                                          formData.set("is_intro_hidden", Pervasives.string_of_bool(value));
                                          return Util$Astrolabe.$$Promise.get((function (param) {
                                                        return Curry._1(setState, (function (param) {
                                                                      return {
                                                                              antibodyStaining: state.antibodyStaining,
                                                                              allCellSubsets: state.allCellSubsets,
                                                                              defaultCellSubsets: state.defaultCellSubsets,
                                                                              intensityHeatMapDataTable: state.intensityHeatMapDataTable,
                                                                              introHidden: value,
                                                                              selectedAntibodies: state.selectedAntibodies,
                                                                              selectedCellSubsets: state.selectedCellSubsets,
                                                                              antibodySelectDisclosed: state.antibodySelectDisclosed,
                                                                              cellSubsetSelectDisclosed: state.cellSubsetSelectDisclosed,
                                                                              welcomeModalVisible: state.welcomeModalVisible,
                                                                              cellSubsetLevel: state.cellSubsetLevel,
                                                                              treatment: state.treatment,
                                                                              statistic: state.statistic
                                                                            };
                                                                    }));
                                                      }), FetchUtils$Astrolabe.patch("/antibody_staining.json", formData));
                                        })
                                    }, state.introHidden ? "Show Intro" : "Hide Intro")), state.introHidden ? null : intro, React.createElement(WelcomeModal$Astrolabe.make, {
                                  onCancel: handleWelcomeModalCancel,
                                  onOk: handleWelcomeModalSave,
                                  saving: false,
                                  visible: state.welcomeModalVisible
                                }), React.createElement("div", undefined, React.createElement("div", {
                                      className: "d-flex flex-row align-items-center justify-content-end"
                                    }, "View antibodies ", visibleSelector !== /* Antibodies */-894823230 ? displaySelectionPreview((function (item) {
                                              return onSelectAntibodies(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedAntibodies));
                                            }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedAntibodies)) : null, React.createElement("div", {
                                          className: "ml-1 py-2",
                                          onClick: (function (param) {
                                              return setVisibleSelector$1(/* Antibodies */-894823230);
                                            })
                                        }, React.createElement(CircleIcon$Astrolabe.make, {
                                              backgroundColor: /* Bootstrap */Block.__(0, ["bg-info"]),
                                              cursor: "pointer",
                                              size: 28.0,
                                              textColor: /* Bootstrap */Block.__(0, ["text-white"]),
                                              tooltip: visibleSelector === /* Antibodies */-894823230 ? "Minimize antibodies selector" : "Add or remove specific antibodies to analyze in-depth",
                                              text: visibleSelector === /* Antibodies */-894823230 ? "-" : "+"
                                            }))), React.createElement("div", {
                                      className: "d-flex flex-row align-items-center justify-content-end"
                                    }, "In", React.createElement("span", {
                                          className: "mx-1"
                                        }, Curry._1(Util$Astrolabe.$$String.capitalize, GraphQL$Astrolabe.Types.cellSubsetLevelName(state.cellSubsetLevel))), "Subsets", visibleSelector !== /* CellSubsets */864285295 ? displaySelectionPreview((function (item) {
                                              return onSelectCellSubsets(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedCellSubsets));
                                            }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedCellSubsets)) : null, React.createElement("div", {
                                          className: "ml-1 py-2",
                                          onClick: (function (param) {
                                              return setVisibleSelector$1(/* CellSubsets */864285295);
                                            })
                                        }, React.createElement(CircleIcon$Astrolabe.make, {
                                              backgroundColor: /* Bootstrap */Block.__(0, ["bg-info"]),
                                              cursor: "pointer",
                                              size: 28.0,
                                              textColor: /* Bootstrap */Block.__(0, ["text-white"]),
                                              tooltip: visibleSelector === /* CellSubsets */864285295 ? "Minimize cell subset selector" : "Add or remove specific cell subsets to analyze in-depth",
                                              text: visibleSelector === /* CellSubsets */864285295 ? "-" : "+"
                                            })))), React.createElement("div", {
                                  className: "my-3"
                                }, visibleSelector !== 864285295 ? (
                                    visibleSelector >= 870530776 ? React.createElement("hr", undefined) : React.createElement(ItemMultiSelector$Astrolabe.make, {
                                            availableSections: Curry._1(Util$Astrolabe.$$String.$$Set.singleton, "Antibodies"),
                                            selectedSectionIdx: 0,
                                            setSelectedSectionIdx: (function (prim) {
                                                
                                              }),
                                            availableItems: availableAntibodies,
                                            defaultSelectedItems: defaultSelectedAntibodies,
                                            selectedItems: selectedAntibodies,
                                            onDeselectItems: onDeselectAntibodies,
                                            onSelectItems: onSelectAntibodies,
                                            title: "Choose Antibodies",
                                            onClickItem: (function (item) {
                                                return onSelectAntibodies(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedAntibodies));
                                              })
                                          })
                                  ) : React.createElement(ItemMultiSelector$Astrolabe.make, {
                                        availableSections: Curry._1(Util$Astrolabe.$$String.$$Set.singleton, "Subsets"),
                                        selectedSectionIdx: 0,
                                        setSelectedSectionIdx: (function (prim) {
                                            
                                          }),
                                        availableItems: availableCellSubsets,
                                        selectedItems: selectedCellSubsets,
                                        onDeselectItems: onDeselectCellSubsets,
                                        onSelectItems: onSelectCellSubsets,
                                        title: "Choose Subsets",
                                        onClickItem: (function (item) {
                                            return onSelectCellSubsets(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, item, selectedCellSubsets));
                                          })
                                      })), tmp);
                })
            });
}

var $$Promise;

var fmt = Util$Astrolabe.fmt;

var make$1 = AntibodyStainingIndex;

var $$default = AntibodyStainingIndex;

exports.$$Promise = $$Promise;
exports.fmt = fmt;
exports.GetIntensityHeatMapData = GetIntensityHeatMapData;
exports.GetIntensityHeatMapDataQuery = GetIntensityHeatMapDataQuery;
exports.initState = initState;
exports.location_search = location_search;
exports.decodeAntibodyStaining = decodeAntibodyStaining;
exports.fetchAntibodies = fetchAntibodies;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* GetIntensityHeatMapDataQuery Not a pure module */
