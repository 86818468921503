// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Victory = require("victory");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");
var GraphDownloadButton$Astrolabe = require("../GraphDownloadButton.bs.js");

function DEASampleDotPlot(Props) {
  var position = Props.position;
  var graphRefs = Props.graphRefs;
  var getColorForFeatureValue = Props.getColorForFeatureValue;
  var experimentName = Props.experimentName;
  var cellSubset = Props.cellSubset;
  var channelDesc = Props.channelDesc;
  var data = Props.data;
  var minDataY = Props.minDataY;
  var maxDataY = Props.maxDataY;
  var ref = function (node) {
    return ReasonReactHelpers$Astrolabe.setRefInArrayRef(node, position, graphRefs);
  };
  var scatterStyle = {
    data: {
      fill: (function (item) {
          return Curry._1(getColorForFeatureValue, item.featureValue);
        })
    }
  };
  var extraWidth = data.length * 17;
  return React.createElement("div", {
              key: String(position),
              ref: ref,
              className: "position-relative flex-shrink-0"
            }, React.createElement("div", {
                  style: {
                    left: "25px",
                    position: "absolute",
                    right: "10px"
                  }
                }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                      prefix: experimentName,
                      name: "Dot Plot",
                      graphType: /* Svg */4153924,
                      container: (function (param) {
                          return Caml_array.caml_array_get(graphRefs.current, position);
                        }),
                      filenames: cellSubset + ("_" + (channelDesc + "_DotPlot"))
                    })), React.createElement(Victory.VictoryChart, {
                  domainPadding: {
                    x: [
                      20.0,
                      20.0
                    ],
                    y: 1.0
                  },
                  padding: {
                    left: 80,
                    top: 50,
                    right: 50,
                    botttom: 50
                  },
                  responsive: false,
                  width: 350.0 + extraWidth,
                  height: 300.0,
                  containerComponent: React.createElement(Victory.VictoryContainer, {
                        height: 450,
                        responsive: false
                      }),
                  children: null
                }, React.createElement(Victory.VictoryLabel, {
                      x: 120.0,
                      y: 30.0,
                      textAnchor: "start",
                      text: cellSubset,
                      style: {
                        fontSize: 20.0
                      }
                    }), React.createElement(Victory.VictoryAxis, {
                      height: 201.0,
                      style: {
                        area: {
                          height: 200.0
                        },
                        tickLabels: {
                          verticalAnchor: "middle",
                          textAnchor: "end",
                          angle: -90,
                          fontSize: 12,
                          fontFamily: "'Rubik', 'Lucida Grande', 'Lucida Sans', Arial, 'sans-serif'"
                        },
                        grid: {
                          stroke: "#e1e1e1"
                        }
                      }
                    }), React.createElement(Victory.VictoryAxis, {
                      label: channelDesc,
                      tickFormat: (function (x) {
                          return x.toFixed(2);
                        }),
                      dependentAxis: true,
                      domain: [
                        minDataY,
                        maxDataY
                      ],
                      style: {
                        grid: {
                          stroke: "#e1e1e1"
                        },
                        axisLabel: {
                          padding: 50
                        }
                      }
                    }), React.createElement(Victory.VictoryScatter, {
                      size: 7,
                      height: 201.0,
                      style: scatterStyle,
                      data: data
                    })));
}

var setRefInArrayRef = ReasonReactHelpers$Astrolabe.setRefInArrayRef;

var make = DEASampleDotPlot;

exports.setRefInArrayRef = setRefInArrayRef;
exports.make = make;
/* react Not a pure module */
