// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var FeatureValue$Astrolabe = require("../../../models/FeatureValue.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");
var FeatureMutations$Astrolabe = require("./FeatureMutations.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");

function FeatureValueInput(Props) {
  var feature = Props.feature;
  var featureValue = Props.featureValue;
  var sampleFeatureId = Props.sampleFeatureId;
  var sampleId = Props.sampleId;
  var featureValues = Feature$Astrolabe.values(feature);
  var featureOpts = Feature$Astrolabe.is_allow_na(feature) ? [
        "",
        "NA"
      ].concat(featureValues) : [""].concat(featureValues);
  var featureId = Feature$Astrolabe.id(feature);
  if (sampleFeatureId !== undefined) {
    return React.createElement(FeatureMutations$Astrolabe.UpdateSampleFeatureValueMutation.make, {
                children: (function (mutation, param) {
                    return React.createElement("select", {
                                className: "form-control form-control-sm",
                                value: FeatureValue$Astrolabe.toDisplayString(featureValue),
                                onChange: (function ($$event) {
                                    var value = FeatureValue$Astrolabe.toGraphQLString(ReasonReactHelpers$Astrolabe.valueFromEvent($$event));
                                    return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(FeatureMutations$Astrolabe.UpdateSampleFeatureValueConfig.make(sampleFeatureId, value, undefined).variables), ["getExperiment"], undefined, undefined));
                                  })
                              }, featureOpts.map((function (value, index) {
                                      return React.createElement("option", {
                                                  key: String(index) + value,
                                                  value: value
                                                }, value);
                                    })));
                  })
              });
  } else {
    return React.createElement(FeatureMutations$Astrolabe.CreateSampleFeatureValueMutation.make, {
                children: (function (mutation, param) {
                    return React.createElement("select", {
                                className: "form-control form-control-sm",
                                value: featureValue,
                                onChange: (function ($$event) {
                                    var value = FeatureValue$Astrolabe.toGraphQLString(ReasonReactHelpers$Astrolabe.valueFromEvent($$event));
                                    return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(FeatureMutations$Astrolabe.CreateSampleFeatureValueConfig.make(featureId, sampleId, value, undefined).variables), ["getExperiment"], undefined, undefined));
                                  })
                              }, featureOpts.map((function (value, index) {
                                      return React.createElement("option", {
                                                  key: String(index) + value,
                                                  value: value
                                                }, value);
                                    })));
                  })
              });
  }
}

var make = FeatureValueInput;

exports.make = make;
/* react Not a pure module */
