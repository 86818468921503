// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Util$Astrolabe = require("./Util.bs.js");

function tToJs(param) {
  return {
          id: param.id,
          createUserEmail: param.createUserEmail,
          name: param.name,
          channels: param.channels,
          channelsUsedPerSample: param.channelsUsedPerSample,
          maxSamplesPerFile: param.maxSamplesPerFile
        };
}

function tFromJs(param) {
  return {
          id: param.id,
          createUserEmail: param.createUserEmail,
          name: param.name,
          channels: param.channels,
          channelsUsedPerSample: param.channelsUsedPerSample,
          maxSamplesPerFile: param.maxSamplesPerFile
        };
}

function id(self) {
  return self.id;
}

function channels(self) {
  return self.channels;
}

function channelsUsedPerSample(self) {
  return self.channelsUsedPerSample;
}

function maxSamplesPerFile(channelCount, channelsUsedPerSample) {
  if (channelCount === 1) {
    return 2;
  } else {
    return Util$Astrolabe.$$Math.combinations(channelCount, channelsUsedPerSample);
  }
}

function make(id, name, channels, channelsUsedPerSample, param) {
  var channels$1 = Belt_Option.getWithDefault(channels, []);
  var channelCount = channels$1.length;
  var channelsUsedPerSample$1 = Belt_Option.getWithDefault(channelsUsedPerSample, 1);
  return {
          id: id,
          createUserEmail: undefined,
          name: name,
          channels: channels$1,
          channelsUsedPerSample: channelsUsedPerSample$1,
          maxSamplesPerFile: maxSamplesPerFile(channelCount, channelsUsedPerSample$1)
        };
}

function withChannels(channels, kit) {
  return {
          id: kit.id,
          createUserEmail: kit.createUserEmail,
          name: kit.name,
          channels: channels,
          channelsUsedPerSample: kit.channelsUsedPerSample,
          maxSamplesPerFile: maxSamplesPerFile(channels.length, kit.channelsUsedPerSample)
        };
}

function withChannelsUsedPerSample(channelsUsedPerSample, kit) {
  return {
          id: kit.id,
          createUserEmail: kit.createUserEmail,
          name: kit.name,
          channels: kit.channels,
          channelsUsedPerSample: channelsUsedPerSample,
          maxSamplesPerFile: maxSamplesPerFile(kit.channels.length, channelsUsedPerSample)
        };
}

function valid(kit) {
  var channelCount = kit.channels.length;
  if (kit.name.length !== 0 && channelCount > 0 && kit.channelsUsedPerSample > 0) {
    if (kit.channelsUsedPerSample < channelCount) {
      return true;
    } else {
      return channelCount === 1;
    }
  } else {
    return false;
  }
}

function channelConfigurations(kit) {
  var match = kit.channels.length;
  if (match !== 1) {
    return Util$Astrolabe.$$Array.powerSet(kit.channels).filter((function (s) {
                  return s.length === kit.channelsUsedPerSample;
                }));
  } else {
    return [
            [],
            [Caml_array.caml_array_get(kit.channels, 0)]
          ];
  }
}

var fromJs = tFromJs;

var toJs = tToJs;

exports.tToJs = tToJs;
exports.tFromJs = tFromJs;
exports.id = id;
exports.channels = channels;
exports.channelsUsedPerSample = channelsUsedPerSample;
exports.fromJs = fromJs;
exports.toJs = toJs;
exports.maxSamplesPerFile = maxSamplesPerFile;
exports.make = make;
exports.withChannels = withChannels;
exports.withChannelsUsedPerSample = withChannelsUsedPerSample;
exports.valid = valid;
exports.channelConfigurations = channelConfigurations;
/* Util-Astrolabe Not a pure module */
