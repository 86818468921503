// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Forms$ExplanatoryText(Props) {
  var text = Props.text;
  var children = Props.children;
  return React.createElement("small", {
              className: "form-text text-muted mb-1"
            }, children !== undefined ? Caml_option.valFromOption(children) : Belt_Option.getWithDefault(text, ""));
}

var ExplanatoryText = {
  make: Forms$ExplanatoryText
};

function Forms$Required(Props) {
  return React.createElement("span", {
              className: "text-danger"
            }, " *");
}

var Required = {
  make: Forms$Required
};

exports.ExplanatoryText = ExplanatoryText;
exports.Required = Required;
/* react Not a pure module */
