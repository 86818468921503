// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var D3Scale = require("d3-scale");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var D3Interpolate = require("d3-interpolate");
var D3ScaleChromatic = require("d3-scale-chromatic");
var FetchUtils$Astrolabe = require("../../../utils/FetchUtils.bs.js");

var interpolateRdBkGn = D3Interpolate.interpolateRgbBasis([
      "#f00",
      "#000",
      "#0f0"
    ]);

function make(cellSubsets, points, medianChannelIntensity, logFCOpt, negLog10FDROpt, param) {
  var logFC = logFCOpt !== undefined ? Caml_option.valFromOption(logFCOpt) : ({ });
  var negLog10FDR = negLog10FDROpt !== undefined ? Caml_option.valFromOption(negLog10FDROpt) : ({ });
  var minX = Math.abs(Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.min(points.x)));
  var minY = Math.abs(Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.min(points.y)));
  var maxX = Math.abs(minX) + Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.max(points.x));
  var maxY = Math.abs(minY) + Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.max(points.y));
  var defaultMapData_colorInterpolator = function (param) {
    return Util$Astrolabe.$$Array.getOrFail(0, D3ScaleChromatic.schemeAccent);
  };
  var defaultMapData_extent = /* tuple */[
    0.0,
    0.0
  ];
  var defaultMapData_points = Util$Astrolabe.$$Array.generate(points.x.length, (function (i) {
          return {
                  x: Util$Astrolabe.$$Array.getOrFail(i, points.x) + minX,
                  y: Util$Astrolabe.$$Array.getOrFail(i, points.y) + minY,
                  freq: Util$Astrolabe.$$Array.getOrFail(i, points.freq),
                  label: [
                    Util$Astrolabe.$$Array.getOrFail(i, cellSubsets),
                    "Click to select"
                  ],
                  name: Util$Astrolabe.$$Array.getOrFail(i, cellSubsets),
                  value: undefined,
                  cellSubset: Util$Astrolabe.$$Array.getOrFail(i, cellSubsets),
                  fill: Util$Astrolabe.$$Array.getOrFail(0, D3ScaleChromatic.schemeAccent)
                };
        }));
  var defaultMapData = {
    colorInterpolator: defaultMapData_colorInterpolator,
    extent: defaultMapData_extent,
    legendTitle: undefined,
    points: defaultMapData_points
  };
  return {
          maxX: maxX,
          maxY: maxY,
          cellSubsets: cellSubsets,
          medianChannelIntensity: medianChannelIntensity,
          logFC: logFC,
          negLog10FDR: negLog10FDR,
          defaultMapData: defaultMapData
        };
}

function defaultMapData(self) {
  return self.defaultMapData;
}

function maxX(self) {
  return self.maxX;
}

function maxY(self) {
  return self.maxY;
}

function getMapData(colorBy, differentialAbundance, self) {
  if (colorBy === "") {
    return self.defaultMapData;
  } else if (differentialAbundance === "none") {
    var medianChannelIntensity = Curry._2(Util$Astrolabe.Dict.get, self.medianChannelIntensity, colorBy);
    if (medianChannelIntensity !== undefined) {
      var x = Util$Astrolabe.$$Option.orFail("No medianChannelIntensity values.", Util$Astrolabe.$$Array.max(medianChannelIntensity));
      var extent_001 = 2.0 > x ? 2.0 : x;
      var extent = /* tuple */[
        0.0,
        extent_001
      ];
      var scale = D3Scale.scaleLinear().domain(extent).range(/* tuple */[
            0.0,
            1.0
          ]);
      var points = Curry._2(Util$Astrolabe.$$Array.mapIndexed, (function (i, pt) {
              var value = Util$Astrolabe.$$Array.getOrFail(i, medianChannelIntensity);
              var fill = D3ScaleChromatic.interpolateGreens(Curry._1(scale, value));
              var cellSubset = Util$Astrolabe.$$Array.getOrFail(i, self.cellSubsets);
              var name = pt.name;
              var label = [
                pt.name,
                Curry._2(Util$Astrolabe.fmt(/* Format */[
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  ": ",
                                  /* Float */Block.__(8, [
                                      /* Float_f */0,
                                      /* No_padding */0,
                                      /* Lit_precision */[2],
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "%s: %.02f"
                        ]), colorBy, value),
                "Click to select"
              ];
              return {
                      x: pt.x,
                      y: pt.y,
                      freq: pt.freq,
                      label: label,
                      name: name,
                      value: value,
                      cellSubset: cellSubset,
                      fill: fill
                    };
            }), self.defaultMapData.points);
      return {
              colorInterpolator: (function (prim) {
                  return D3ScaleChromatic.interpolateGreens(prim);
                }),
              extent: extent,
              legendTitle: colorBy,
              points: points
            };
    }
    console.log("No median channel intensity data found in MDS data for " + colorBy);
    return self.defaultMapData;
  } else if (colorBy === "logFC") {
    var logFC = Curry._2(Util$Astrolabe.Dict.get, self.logFC, differentialAbundance);
    if (logFC !== undefined && logFC.length !== 0) {
      var minVal = Caml_primitive.caml_float_min(-1.0, Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.min(logFC)));
      var maxVal = Caml_primitive.caml_float_max(1.0, Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.max(logFC)));
      var maxVal$1 = Caml_primitive.caml_float_max(Math.abs(minVal), Math.abs(maxVal));
      var extent_000 = -maxVal$1;
      var extent$1 = /* tuple */[
        extent_000,
        maxVal$1
      ];
      var scale$1 = D3Scale.scaleLinear().domain(extent$1).range(/* tuple */[
            0.0,
            1.0
          ]);
      var points$1 = Curry._2(Util$Astrolabe.$$Array.mapIndexed, (function (i, pt) {
              var value = Util$Astrolabe.$$Array.getOrFail(i, logFC);
              var fill = Curry._1(interpolateRdBkGn, Curry._1(scale$1, value));
              var cellSubset = Util$Astrolabe.$$Array.getOrFail(i, self.cellSubsets);
              var name = pt.name;
              var label = [
                pt.name,
                Curry._1(Util$Astrolabe.fmt(/* Format */[
                          /* String_literal */Block.__(11, [
                              "log(FC): ",
                              /* Float */Block.__(8, [
                                  /* Float_f */0,
                                  /* No_padding */0,
                                  /* Lit_precision */[2],
                                  /* End_of_format */0
                                ])
                            ]),
                          "log(FC): %.02f"
                        ]), value),
                "Click to select"
              ];
              return {
                      x: pt.x,
                      y: pt.y,
                      freq: pt.freq,
                      label: label,
                      name: name,
                      value: value,
                      cellSubset: cellSubset,
                      fill: fill
                    };
            }), self.defaultMapData.points);
      return {
              colorInterpolator: interpolateRdBkGn,
              extent: extent$1,
              legendTitle: "log(Fold Change)",
              points: points$1
            };
    }
    console.log("No log(FC) data found in MDS data for Feature ID " + differentialAbundance);
    return self.defaultMapData;
  } else if (colorBy === "negLog10FDR") {
    var negLog10FDR = Curry._2(Util$Astrolabe.Dict.get, self.negLog10FDR, differentialAbundance);
    if (negLog10FDR !== undefined) {
      var x$1 = Util$Astrolabe.$$Option.orFail("No negLog10FDR values.", Util$Astrolabe.$$Array.max(negLog10FDR));
      var extent_001$1 = 2.0 > x$1 ? 2.0 : x$1;
      var extent$2 = /* tuple */[
        0.0,
        extent_001$1
      ];
      var scale$2 = D3Scale.scaleLinear().domain(extent$2).range(/* tuple */[
            0.0,
            1.0
          ]);
      var points$2 = Curry._2(Util$Astrolabe.$$Array.mapIndexed, (function (i, pt) {
              var value = Util$Astrolabe.$$Array.getOrFail(i, negLog10FDR);
              var fill = D3ScaleChromatic.interpolateGreens(Curry._1(scale$2, value));
              var cellSubset = Util$Astrolabe.$$Array.getOrFail(i, self.cellSubsets);
              var name = pt.name;
              var label = [
                pt.name,
                Curry._1(Util$Astrolabe.fmt(/* Format */[
                          /* String_literal */Block.__(11, [
                              "-log10(FDR): ",
                              /* Float */Block.__(8, [
                                  /* Float_f */0,
                                  /* No_padding */0,
                                  /* Lit_precision */[2],
                                  /* End_of_format */0
                                ])
                            ]),
                          "-log10(FDR): %.02f"
                        ]), value),
                "Click to select"
              ];
              return {
                      x: pt.x,
                      y: pt.y,
                      freq: pt.freq,
                      label: label,
                      name: name,
                      value: value,
                      cellSubset: cellSubset,
                      fill: fill
                    };
            }), self.defaultMapData.points);
      return {
              colorInterpolator: (function (prim) {
                  return D3ScaleChromatic.interpolateGreens(prim);
                }),
              extent: extent$2,
              legendTitle: "-log10(FDR)",
              points: points$2
            };
    }
    console.log("No -log10(FDR) data found in MDS data for Feature ID " + differentialAbundance);
    return self.defaultMapData;
  } else {
    return Pervasives.failwith(Curry._2(Util$Astrolabe.fmt(/* Format */[
                        /* String_literal */Block.__(11, [
                            "Invalid differentialAbundance (",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    ") and colorBy (",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* String_literal */Block.__(11, [
                                            ") combination",
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ]),
                        "Invalid differentialAbundance (%s) and colorBy (%s) combination"
                      ]), differentialAbundance, colorBy));
  }
}

function cellSubsetColors(mapData) {
  var out = { };
  mapData.points.forEach((function (subset) {
          out[subset.name] = subset.fill;
          
        }));
  return out;
}

function cellSubsets(self) {
  return self.cellSubsets;
}

function decodePoints(j) {
  var x = Json_decode.field("x", (function (param) {
          return Json_decode.array(Json_decode.$$float, param);
        }), j);
  var y = Json_decode.field("y", (function (param) {
          return Json_decode.array(Json_decode.$$float, param);
        }), j);
  var freq = Json_decode.field("freq", (function (param) {
          return Json_decode.array(Json_decode.$$float, param);
        }), j);
  return {
          x: x,
          y: y,
          freq: freq
        };
}

function decodeFloatArrayDict(j) {
  return Json_decode.dict((function (param) {
                return Json_decode.array(Json_decode.$$float, param);
              }), j);
}

function decodeMdsData(j) {
  var cellSubsets = Json_decode.field("cell_subsets", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), j);
  var points = Json_decode.field("points", decodePoints, j);
  var medianChannelIntensity = Json_decode.field("median_channel_intensity", decodeFloatArrayDict, j);
  var logFC = Json_decode.optional((function (param) {
          return Json_decode.field("log_fc", decodeFloatArrayDict, param);
        }), j);
  var negLog10FDR = Json_decode.optional((function (param) {
          return Json_decode.field("neg_log_10_fdr", decodeFloatArrayDict, param);
        }), j);
  return make(cellSubsets, points, medianChannelIntensity, logFC, negLog10FDR, undefined);
}

function decodeMdsDataByLevel(j) {
  return Json_decode.dict(decodeMdsData, j);
}

function checkErrors(json) {
  var dict = Js_json.decodeObject(json);
  if (dict === undefined) {
    return Pervasives.failwith("Invalid JSON format: expected object.");
  }
  var errors = Curry._2(Util$Astrolabe.Dict.get, Caml_option.valFromOption(dict), "errors");
  if (errors !== undefined) {
    console.log(Caml_option.valFromOption(errors));
    return Pervasives.failwith("Could not get data: server responded with errors.");
  }
  
}

function mdsDataByLevel(experimentId, callback) {
  return Util$Astrolabe.$$Promise.get(callback, Util$Astrolabe.$$Promise.map(decodeMdsDataByLevel, Util$Astrolabe.$$Promise.tap(checkErrors, FetchUtils$Astrolabe.getJson(Curry._1(Util$Astrolabe.fmt(/* Format */[
                                  /* String_literal */Block.__(11, [
                                      "/experiments/",
                                      /* Int */Block.__(4, [
                                          /* Int_d */0,
                                          /* No_padding */0,
                                          /* No_precision */0,
                                          /* String_literal */Block.__(11, [
                                              "/mds_data.json",
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "/experiments/%d/mds_data.json"
                                ]), experimentId)))));
}

var Dict;

var fetchData = mdsDataByLevel;

exports.Dict = Dict;
exports.make = make;
exports.getMapData = getMapData;
exports.defaultMapData = defaultMapData;
exports.maxX = maxX;
exports.maxY = maxY;
exports.fetchData = fetchData;
exports.cellSubsetColors = cellSubsetColors;
exports.cellSubsets = cellSubsets;
/* interpolateRdBkGn Not a pure module */
