/* @flow */

import React from "react";
import InstanceUsers from "../instances/Users";
import type { Session } from "models";
import { Instance } from "models";
import NavTitle from "../../NavTitle";

type Props = {
  session: Session,
};

export default function Index(props: Props) {
  return (
    <React.Fragment>
      <NavTitle.TextAndDocumentTitle title="User Admin" />
      <InstanceUsers instance={new Instance(props.session.instance)} accessingUserIsSysadmin={props.session.user.is_sysadmin} />
    </React.Fragment>
  );
}
