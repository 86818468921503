// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Victory = require("victory");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var D3ScaleChromatic = require("d3-scale-chromatic");
var DensityData$Astrolabe = require("../../models/DensityData.bs.js");
var ASDSDensityLineChart$Astrolabe = require("./ASDSDensityLineChart.bs.js");

var ppx_printed_query = "query getDensityPlotData($cellSubsetLevel: CellSubsetLevel!, $treatment: Treatment, $antibodies: [String!]!, $cellSubsets: [String!]!)  {\nasds  {\ndensityData(cellSubsetLevel: $cellSubsetLevel, treatment: $treatment, antibodies: $antibodies, cellSubsets: $cellSubsets)  {\nantibodies  \ncellSubsets  \ndata  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "asds");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "densityData");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$4)));
      var value$6 = Js_dict.get(value$5, "antibodies");
      var value$7 = Js_dict.get(value$5, "cellSubsets");
      var value$8 = Js_dict.get(value$5, "data");
      tmp$1 = {
        antibodies: value$6 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$6))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field antibodies on type DensityData is missing"),
        cellSubsets: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field cellSubsets on type DensityData is missing"),
        data: value$8 !== undefined ? Caml_option.valFromOption(value$8) : Js_exn.raiseError("graphql_ppx: Field data on type DensityData is missing")
      };
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field densityData on type ASDS is missing");
    }
    tmp = {
      densityData: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field asds on type Query is missing");
  }
  return {
          asds: tmp
        };
}

function json_of_CellSubsetLevel(value) {
  if (value >= 500777805) {
    return "ASSIGNMENT";
  } else {
    return "PROFILING";
  }
}

function json_of_Treatment(value) {
  if (value >= 74759758) {
    return "FRESH";
  } else {
    return "FIXED";
  }
}

function make(cellSubsetLevel, treatment, antibodies, cellSubsets, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    treatment !== undefined ? json_of_Treatment(treatment) : null
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "cellSubsets",
                    cellSubsets.map((function (prim) {
                            return prim;
                          }))
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var cellSubsetLevel = variables.cellSubsetLevel;
  var treatment = variables.treatment;
  var antibodies = variables.antibodies;
  var cellSubsets = variables.cellSubsets;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    treatment !== undefined ? json_of_Treatment(treatment) : null
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "cellSubsets",
                    cellSubsets.map((function (prim) {
                            return prim;
                          }))
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(cellSubsetLevel, treatment, antibodies, cellSubsets, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "cellSubsetLevel",
                  json_of_CellSubsetLevel(cellSubsetLevel)
                ],
                /* tuple */[
                  "treatment",
                  treatment !== undefined ? json_of_Treatment(treatment) : null
                ],
                /* tuple */[
                  "antibodies",
                  antibodies.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "cellSubsets",
                  cellSubsets.map((function (prim) {
                          return prim;
                        }))
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, cellSubsetLevel, treatment, antibodies, cellSubsets, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "cellSubsetLevel",
                      json_of_CellSubsetLevel(cellSubsetLevel)
                    ],
                    /* tuple */[
                      "treatment",
                      treatment !== undefined ? json_of_Treatment(treatment) : null
                    ],
                    /* tuple */[
                      "antibodies",
                      antibodies.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "cellSubsets",
                      cellSubsets.map((function (prim) {
                              return prim;
                            }))
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetDensityPlotData = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_CellSubsetLevel: json_of_CellSubsetLevel,
  json_of_Treatment: json_of_Treatment,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetDensityPlotDataQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function str(prim) {
  return prim;
}

function ASDSDensityLineChartContainer(Props) {
  var cellSubsetLevel = Props.cellSubsetLevel;
  var treatment = Props.treatment;
  var antibodies = Props.antibodies;
  var cellSubsets = Props.cellSubsets;
  var getDensityPlotDataQuery = make(cellSubsetLevel, treatment, antibodies, cellSubsets, undefined);
  return React.createElement(GetDensityPlotDataQuery.make, {
              variables: getDensityPlotDataQuery.variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  }
                  if (!result.tag) {
                    return React.createElement("div", undefined, result[0].message);
                  }
                  var densityData = result[0].asds.densityData;
                  var data = DensityData$Astrolabe.fromJson(densityData.data);
                  var uniqueGroups = $$Array.fold_left((function (accum, d) {
                          var group = Belt_Option.getWithDefault(d.group, d.id);
                          var match = accum.find((function (other) {
                                  return Belt_Option.getWithDefault(other.group, other.id) === group;
                                }));
                          if (match !== undefined) {
                            return accum;
                          } else {
                            return $$Array.concat(/* :: */[
                                        accum,
                                        /* :: */[
                                          [d],
                                          /* [] */0
                                        ]
                                      ]);
                          }
                        }), [], DensityData$Astrolabe.getSinglePlotData(Caml_array.caml_array_get(antibodies, 0), Caml_array.caml_array_get(cellSubsets, 0), data));
                  return React.createElement(React.Fragment, undefined, React.createElement(Victory.VictoryLegend, {
                                  colorScale: D3ScaleChromatic.schemeAccent,
                                  containerComponent: React.createElement(Victory.VictoryContainer, {
                                        height: 30,
                                        responsive: false,
                                        width: 500
                                      }),
                                  data: $$Array.map((function (d) {
                                          return {
                                                  name: Belt_Option.getWithDefault(d.group, d.id)
                                                };
                                        }), uniqueGroups),
                                  gutter: 20,
                                  height: 30,
                                  orientation: "horizontal",
                                  style: {
                                    title: {
                                      fontSize: 16,
                                      padding: 5
                                    }
                                  },
                                  width: 500
                                }), $$Array.mapi((function (antibodyIndex, antibody) {
                                    var maxY = DensityData$Astrolabe.getMaxY(antibody, data);
                                    return React.createElement("div", {
                                                key: antibody,
                                                className: "d-flex"
                                              }, $$Array.mapi((function (cellSubsetIndex, cellSubset) {
                                                      return React.createElement("div", {
                                                                  key: cellSubset,
                                                                  className: "p-2" + (
                                                                    cellSubsetIndex !== 0 ? " border-left" : ""
                                                                  )
                                                                }, React.createElement(ASDSDensityLineChart$Astrolabe.make, ASDSDensityLineChart$Astrolabe.makeProps(DensityData$Astrolabe.getSinglePlotData(antibody, cellSubset, data), maxY, Caml_option.some(antibodyIndex === 0 ? cellSubset : undefined), Caml_option.some(cellSubsetIndex === 0 ? antibody : undefined), undefined)));
                                                    }), densityData.cellSubsets));
                                  }), densityData.antibodies));
                })
            });
}

var make$1 = ASDSDensityLineChartContainer;

exports.GetDensityPlotData = GetDensityPlotData;
exports.GetDensityPlotDataQuery = GetDensityPlotDataQuery;
exports.str = str;
exports.make = make$1;
/* GetDensityPlotDataQuery Not a pure module */
