// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var CircleIcon$Astrolabe = require("../CircleIcon.bs.js");
var CustomHooks$Astrolabe = require("../../../models/CustomHooks.bs.js");
var FeatureTooltip$Astrolabe = require("../FeatureTooltip.bs.js");
var ExperimentHeader$Astrolabe = require("../../header/ExperimentHeader.bs.js");
var ButtonWithOnClick$Astrolabe = require("../ButtonWithOnClick.bs.js");
var ItemMultiSelector$Astrolabe = require("../ItemMultiSelector.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");

function CellSubsetNav(Props) {
  var cellSubsets = Props.cellSubsets;
  var cellSubsetClick = Props.cellSubsetClick;
  var cellSubsetLevels = Props.cellSubsetLevels;
  var differentialAbundance = Props.differentialAbundance;
  var features = Props.features;
  var getCellSubsetColor = Props.getCellSubsetColor;
  var handleDifferentialAbundanceChange = Props.handleDifferentialAbundanceChange;
  var handleOpenMultipleSubsets = Props.handleOpenMultipleSubsets;
  var handleCloseMultipleSubsets = Props.handleCloseMultipleSubsets;
  var onSubsetLevelChange = Props.onSubsetLevelChange;
  var selectedFeature = Props.selectedFeature;
  var selectedSubsets = Props.selectedSubsets;
  var subsetLevel = Props.subsetLevel;
  var children = Props.children;
  var features$1 = $$Array.map(Feature$Astrolabe.fromImmutableJs, features);
  var selectedFeature$1 = Curry._2(Util$Astrolabe.$$Option.map, (selectedFeature == null) ? undefined : Caml_option.some(selectedFeature), Feature$Astrolabe.fromImmutableJs);
  var initialState = {
    controlsState: /* Expanded */1,
    daaHovered: false,
    displayValues: cellSubsets,
    searchText: "",
    selectedSectionIdx: 0
  };
  var reducer = function (state, action) {
    if (typeof action === "number") {
      if (action === /* ResetDisplayValues */0) {
        return {
                controlsState: state.controlsState,
                daaHovered: state.daaHovered,
                displayValues: cellSubsets,
                searchText: state.searchText,
                selectedSectionIdx: state.selectedSectionIdx
              };
      } else {
        return {
                controlsState: state.controlsState,
                daaHovered: state.daaHovered,
                displayValues: cellSubsets,
                searchText: "",
                selectedSectionIdx: state.selectedSectionIdx
              };
      }
    }
    switch (action.tag | 0) {
      case /* SearchTextChanged */0 :
          var searchText = action[0];
          var upperCase = searchText.toUpperCase();
          var displayValues = cellSubsets.filter((function (v) {
                  return v.toUpperCase().includes(upperCase);
                }));
          return {
                  controlsState: state.controlsState,
                  daaHovered: state.daaHovered,
                  displayValues: displayValues,
                  searchText: searchText,
                  selectedSectionIdx: state.selectedSectionIdx
                };
      case /* SetSelectedSectionIdx */1 :
          return {
                  controlsState: state.controlsState,
                  daaHovered: state.daaHovered,
                  displayValues: state.displayValues,
                  searchText: state.searchText,
                  selectedSectionIdx: action[0]
                };
      case /* SetDaaHovered */2 :
          return {
                  controlsState: state.controlsState,
                  daaHovered: action[0],
                  displayValues: state.displayValues,
                  searchText: state.searchText,
                  selectedSectionIdx: state.selectedSectionIdx
                };
      case /* ToggleControls */3 :
          return {
                  controlsState: action[0],
                  daaHovered: state.daaHovered,
                  displayValues: state.displayValues,
                  searchText: state.searchText,
                  selectedSectionIdx: state.selectedSectionIdx
                };
      
    }
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* ResetDisplayValues */0);
          
        }), [cellSubsets]);
  var windowDimensions = CustomHooks$Astrolabe.useWindowDimensions(undefined);
  var differentialAbundanceOn = differentialAbundance !== "none";
  var differentialAbundanceOptions = ReasonReactHelpers$Astrolabe.arr($$Array.append([React.createElement("option", {
                  key: "none",
                  value: "none"
                }, ReasonReactHelpers$Astrolabe.str("Select Feature"))], $$Array.mapi((function (i, feature) {
                  return React.createElement("option", {
                              key: String(i),
                              value: "feature_" + Feature$Astrolabe.id(feature)
                            }, ReasonReactHelpers$Astrolabe.str(Feature$Astrolabe.name(feature)));
                }), features$1)));
  var selectedSubsetsToString = selectedSubsets.toString();
  var cellSubsetButtonWidth = selectedSubsetsToString.length + (selectedSubsets.length << 2) | 0;
  var calculateTwoDisplayRows = function (param) {
    var firstRow = selectedSubsetsToString.slice(0, windowDimensions.width * 0.046 | 0).split(",").length;
    var secondRowToString = selectedSubsets.slice(firstRow).toString();
    var secondRow = secondRowToString.length > (windowDimensions.width * 0.07 | 0) ? secondRowToString.slice(0, windowDimensions.width * 0.063 | 0).split(",").length : secondRowToString.slice(0, windowDimensions.width * 0.065 | 0).split(",").length;
    return firstRow + secondRow | 0;
  };
  var amountSubsetsDisplayed = cellSubsetButtonWidth <= (windowDimensions.width * 0.086 | 0) ? selectedSubsetsToString.slice(0, windowDimensions.width * 0.076 | 0).split(",").length : calculateTwoDisplayRows(undefined);
  var limitedSubsetsToDisplay = selectedSubsets.slice(0, amountSubsetsDisplayed);
  var amountSubsetsHidden = selectedSubsets.length - amountSubsetsDisplayed | 0;
  var displaySubsetsAsButtons = function (cellSubsets) {
    return cellSubsets.map((function (cellSubsetName, i) {
                  var tmp = {
                    name: cellSubsetName,
                    className: "ml-1 mb-1",
                    onClick: cellSubsetClick,
                    key: String(i) + cellSubsetName
                  };
                  var tmp$1 = differentialAbundanceOn ? Curry._1(getCellSubsetColor, cellSubsetName) : undefined;
                  if (tmp$1 !== undefined) {
                    tmp.borderColor = Caml_option.valFromOption(tmp$1);
                  }
                  return React.createElement(ButtonWithOnClick$Astrolabe.make, tmp);
                }));
  };
  var closeHiddenSubsets = function (param) {
    return Curry._1(handleCloseMultipleSubsets, selectedSubsets.slice(selectedSubsets.length - amountSubsetsHidden | 0));
  };
  var controlsExpanded = state.controlsState === /* Expanded */1;
  var expandControlsButton = React.createElement("div", {
        className: "ml-1",
        onClick: (function (param) {
            return Curry._1(dispatch, /* ToggleControls */Block.__(3, [controlsExpanded ? /* Minimized */0 : /* Expanded */1]));
          })
      }, React.createElement(CircleIcon$Astrolabe.make, {
            backgroundColor: /* Bootstrap */Block.__(0, ["bg-info"]),
            cursor: "pointer",
            size: 28.0,
            textColor: /* Bootstrap */Block.__(0, ["text-white"]),
            tooltip: controlsExpanded ? "Minimize cell subset controls" : "Add or remove specific cell subsets to analyze in-depth",
            text: controlsExpanded ? "-" : "+"
          }));
  var dASelect = React.createElement("div", {
        className: "position-relative"
      }, React.createElement("select", {
            className: "form-control-sm",
            style: {
              width: "190px"
            },
            value: differentialAbundance,
            onChange: (function (e) {
                return Curry._1(handleDifferentialAbundanceChange, ReasonReactHelpers$Astrolabe.valueFromEvent(e));
              })
          }, differentialAbundanceOptions), differentialAbundanceOn ? React.createElement("div", {
              className: "position-absolute",
              style: {
                right: "-30px",
                top: "3px"
              },
              onMouseEnter: (function (param) {
                  return Curry._1(dispatch, /* SetDaaHovered */Block.__(2, [true]));
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(dispatch, /* SetDaaHovered */Block.__(2, [false]));
                })
            }, React.createElement(CircleIcon$Astrolabe.make, {
                  backgroundColor: /* CssStyle */Block.__(1, ["#d3d3d3"]),
                  textColor: /* Bootstrap */Block.__(0, ["text-white"]),
                  tooltip: "",
                  text: "i"
                })) : null);
  var exploreEffectOf = function (useShortLabel) {
    return React.createElement("div", {
                className: "d-flex py-2 " + (
                  useShortLabel ? "flex-column align-items-end" : "flex-row align-items-center"
                )
              }, React.createElement("label", {
                    className: "mb-0 " + (
                      useShortLabel ? "" : "mr-2"
                    )
                  }, ReasonReactHelpers$Astrolabe.str(useShortLabel ? "Explore Effect of" : "Explore differential abundance effect of")), dASelect);
  };
  var handleSubsetLevelChange = function (csLevel) {
    Curry._1(onSubsetLevelChange, csLevel);
    return Curry._1(dispatch, /* SetSubsetLevel */1);
  };
  var onlySubsetHeader = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: "d-flex flex-grow-1 pr-4 pb-2 border-right mr-4 pt-2 align-items-center"
          }, ReasonReactHelpers$Astrolabe.str("View Frequency of "), React.createElement("div", {
                className: (
                  controlsExpanded ? "" : "cursor-pointer"
                ) + " text-info pl-2",
                onClick: (function (param) {
                    return Curry._1(dispatch, /* ToggleControls */Block.__(3, [/* Expanded */1]));
                  })
              }, ReasonReactHelpers$Astrolabe.str(Curry._1(Util$Astrolabe.$$String.capitalize, subsetLevel) + " Subsets")), expandControlsButton), React.createElement("div", {
            className: "mb-2 d-flex flex-grow-2 justify-content-end pt-2 align-items-center position-relative"
          }, exploreEffectOf(false)));
  var bottomRowSubsetandHeader = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: "d-flex flex-grow-1 pr-4 mr-4 pt-2 justify-content-end"
          }, React.createElement("div", {
                className: "d-flex align-items-stretch"
              }, React.createElement("div", {
                    className: "d-flex flex-column align-items-end pb-2"
                  }, React.createElement("div", {
                        className: "d-flex mb-1"
                      }, ReasonReactHelpers$Astrolabe.str("View Frequency of "), React.createElement("div", {
                            className: (
                              controlsExpanded ? "" : "cursor-pointer"
                            ) + " text-info pl-2",
                            onClick: (function (param) {
                                return Curry._1(dispatch, /* ToggleControls */Block.__(3, [/* Expanded */1]));
                              })
                          }, ReasonReactHelpers$Astrolabe.str(Curry._1(Util$Astrolabe.$$String.capitalize, subsetLevel) + " Subsets"))), React.createElement("div", {
                        className: "d-flex justify-contend-end"
                      }, displaySubsetsAsButtons(selectedSubsets))), React.createElement("div", {
                    className: "pl-2 pr-4 border-right mr-4 d-flex flex-column align-items-center justify-content-center"
                  }, expandControlsButton))), exploreEffectOf(true));
  var twoRowSubsetsandHeader = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: "d-flex"
          }, React.createElement("div", {
                className: "d-flex flex-grow-1 pr-4 mr-4 pt-2"
              }, React.createElement("div", {
                    className: "justify-content-start pb-2 d-flex flex-wrap align-items-stretch"
                  }, React.createElement("div", {
                        className: "d-flex mb-1"
                      }, ReasonReactHelpers$Astrolabe.str("View Frequency of "), React.createElement("div", {
                            className: (
                              controlsExpanded ? "" : "cursor-pointer"
                            ) + " pl-2 text-info cursor-pointer",
                            onClick: (function (param) {
                                return Curry._1(dispatch, /* ToggleControls */Block.__(3, [/* Expanded */1]));
                              })
                          }, ReasonReactHelpers$Astrolabe.str(Curry._1(Util$Astrolabe.$$String.capitalize, subsetLevel) + " Subsets"))), amountSubsetsHidden > 0 ? React.createElement(React.Fragment, undefined, displaySubsetsAsButtons(limitedSubsetsToDisplay), React.createElement("div", {
                              className: "ml-1"
                            }, React.createElement(ButtonWithOnClick$Astrolabe.make, {
                                  name: "And " + (String(amountSubsetsHidden) + " more"),
                                  onClick: closeHiddenSubsets
                                }))) : displaySubsetsAsButtons(selectedSubsets)), React.createElement("div", {
                    className: "pr-4 border-right mr-4 d-flex flex-column align-items-center justify-content-center"
                  }, expandControlsButton))), exploreEffectOf(true));
  var match$1 = state.controlsState;
  var match$2 = state.daaHovered;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "sticky-top",
                  style: {
                    background: "white",
                    top: ExperimentHeader$Astrolabe.getNavHeightPx(70, undefined)
                  }
                }, React.createElement("div", {
                      className: "d-flex justify-content-between"
                    }, match$1 || selectedSubsets.length === 0 ? onlySubsetHeader : (
                        cellSubsetButtonWidth < (windowDimensions.width * 0.082 | 0) ? bottomRowSubsetandHeader : twoRowSubsetsandHeader
                      )), selectedFeature$1 !== undefined && match$2 ? React.createElement("div", {
                        className: "d-flex justify-content-end"
                      }, React.createElement(FeatureTooltip$Astrolabe.make, {
                            feature: Caml_option.valFromOption(selectedFeature$1)
                          })) : null, controlsExpanded ? React.createElement(ItemMultiSelector$Astrolabe.make, {
                        availableSections: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, cellSubsetLevels),
                        selectedSectionIdx: state.selectedSectionIdx,
                        setSelectedSectionIdx: (function (idx) {
                            return Curry._1(dispatch, /* SetSelectedSectionIdx */Block.__(1, [idx]));
                          }),
                        availableItems: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, state.displayValues),
                        selectedItems: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, selectedSubsets),
                        onDeselectItems: (function (items) {
                            return Curry._1(handleCloseMultipleSubsets, Curry._1(Util$Astrolabe.$$String.$$Set.to_array, items));
                          }),
                        onSelectItems: (function (items) {
                            return Curry._1(handleOpenMultipleSubsets, Curry._1(Util$Astrolabe.$$String.$$Set.to_array, items));
                          }),
                        title: "Filter Analysis Subsets",
                        onClickItem: cellSubsetClick,
                        onChangeSection: handleSubsetLevelChange
                      }) : React.createElement("hr", {
                        className: "my-0"
                      })), children);
}

var $$Option;

var $$String$1;

var make = CellSubsetNav;

var $$default = CellSubsetNav;

exports.$$Option = $$Option;
exports.$$String = $$String$1;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
