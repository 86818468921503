/* @flow */
"use strict";

import React from "react";
import type { History } from "react-router";
import { List } from "immutable";
import { Instance } from "models";
import { toISODateString } from "components/utils";

type Props = {
  errors: ?List<string>,
  formProps?: Object,
  history: History,
  instance: Instance,
  onSave: (instance: Instance) => any,
};

type State = {
  instance: Instance,
  errors: ?List<string>,
};

export default class Form extends React.Component<Props, State> {
  handleSave: (e: SyntheticEvent<*>) => any;
  setInstanceProp: (prop: string, value: any) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      instance: props.instance,
      errors: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    var newState = {};

    if (this.props.instance !== nextProps.instance) {
      newState.instance = nextProps.instance;
    }

    if (this.props.errors !== nextProps.errors) {
      newState.errors = nextProps.errors;
    }
    this.setState(newState);
  }

  handleSave = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.props.onSave(this.state.instance);
  };

  setInstanceProp = (prop: string, value: any) => {
    this.setState({ instance: this.state.instance.set(prop, value) });
  };

  render() {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          {this.state.errors ? (
            <div className="alert alert-danger" role="alert">
              <p>Instance couldn't be saved due to the following:</p>
              <ul className="mb-0">{this.state.errors.map((error, i) => <li key={i}>{error}</li>).toArray()}</ul>
            </div>
          ) : null}
        </div>

        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-5">
            <form className="form" onSubmit={this.handleSave} {...this.props.formProps}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  className="form-control"
                  type="name"
                  name="name"
                  autoFocus={true}
                  value={this.state.instance.name}
                  onChange={(e) => this.setInstanceProp("name", e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="allowed_email_domain">Allowed Email Domains</label>
                <input
                  className="form-control"
                  type="text"
                  name="allowed_email_domain"
                  value={this.state.instance.allowed_email_domain}
                  onChange={(e) => this.setInstanceProp("allowed_email_domain", e.target.value)}
                />
                <small className="form-text text-muted">
                  Enter domain names separated by commas and without “@” symbols. Leave blank to allow instance admins to add
                  users from any domain.
                </small>
              </div>

              <div className="form-group">
                <label htmlFor="max_samples">Max Samples</label>
                <input
                  className="form-control"
                  type="number"
                  name="max_samples"
                  value={this.state.instance.max_samples || ""}
                  onChange={(e) => this.setInstanceProp("max_samples", e.target.value)}
                />
                <small className="form-text text-muted">Leave blank to allow for unlimited samples.</small>
              </div>

              <div className="form-group">
                <label htmlFor="contract_start_date">Contract Start</label>
                <input
                  className="form-control"
                  type="date"
                  name="contract_start_date"
                  value={toISODateString(this.state.instance.contract_start_date)}
                  onChange={(e) => this.setInstanceProp("contract_start_date", new Date(e.target.value))}
                />
              </div>

              <div className="form-group">
                <label htmlFor="contract_end_date">Contract End</label>
                <input
                  className="form-control"
                  type="date"
                  name="contract_end_date"
                  value={toISODateString(this.state.instance.contract_end_date)}
                  onChange={(e) => this.setInstanceProp("contract_end_date", new Date(e.target.value))}
                />
              </div>

              <div className="form-group mb-0 d-flex align-items-center justify-content-between">
                <button className="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
