// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var Constants$Astrolabe = require("../../../utils/Constants.bs.js");
var FeatureValue$Astrolabe = require("../../../models/FeatureValue.bs.js");
var SampleNameInput$Astrolabe = require("../../samples/SampleNameInput.bs.js");
var FeatureValueInput$Astrolabe = require("./FeatureValueInput.bs.js");
var SortableTableHeader$Astrolabe = require("../../shared/SortableTableHeader.bs.js");

function FeatureRow(Props) {
  var isEditable = Props.isEditable;
  var experimentId = Props.experimentId;
  var features = Props.features;
  var onComplete = Props.onComplete;
  var sample = Props.sample;
  var featureIds = SortableTableHeader$Astrolabe.Sort.sort(/* ASC */0, features.map((function (feature) {
              return Caml_format.caml_int_of_string(Feature$Astrolabe.id(feature));
            })));
  var sampleId = Sample$Astrolabe.Js.id(sample);
  var featureValues = featureIds.map((function (featureId) {
          var featureId$1 = String(featureId);
          var feature = Feature$Astrolabe.findById(featureId$1, features);
          var sampleFeatureValue = FeatureValue$Astrolabe.Js.findBySampleAndFeature(featureId$1, sampleId, Sample$Astrolabe.Js.featureValues(sample));
          var tmp;
          if (sampleFeatureValue !== undefined) {
            var sampleFeatureVal = Caml_option.valFromOption(sampleFeatureValue);
            tmp = feature !== undefined && isEditable ? React.createElement(FeatureValueInput$Astrolabe.make, {
                    feature: Caml_option.valFromOption(feature),
                    featureValue: FeatureValue$Astrolabe.Js.value(sampleFeatureVal),
                    sampleFeatureId: FeatureValue$Astrolabe.Js.id(sampleFeatureVal),
                    sampleId: sampleId
                  }) : FeatureValue$Astrolabe.Js.toDisplayValue(sampleFeatureVal);
          } else if (feature !== undefined && isEditable) {
            tmp = React.createElement(FeatureValueInput$Astrolabe.make, {
                  feature: Caml_option.valFromOption(feature),
                  featureValue: "",
                  sampleFeatureId: undefined,
                  sampleId: sampleId
                });
          } else {
            console.log("Something went wrong with locating the feature.");
            tmp = "Something went wrong";
          }
          return React.createElement("td", {
                      key: String(Util$Astrolabe.$$Math.randomNumber(undefined))
                    }, tmp);
        }));
  var sampleName = isEditable ? React.createElement(SampleNameInput$Astrolabe.make, {
          onComplete: onComplete,
          sample: sample
        }) : React.createElement("td", undefined, React.createElement("a", {
              href: Constants$Astrolabe.sampleNavigatorPath(experimentId, sampleId)
            }, Sample$Astrolabe.Js.name(sample)));
  return React.createElement("tr", undefined, React.createElement("td", {
                  className: isEditable ? "form-control-sm pt-2" : ""
                }, Sample$Astrolabe.Js.fileFileName(sample)), sampleName, featureValues);
}

var FeatureValue;

var Sample;

var make = FeatureRow;

exports.FeatureValue = FeatureValue;
exports.Sample = Sample;
exports.make = make;
/* react Not a pure module */
