// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Immutable = require("immutable");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function make(id, name, values, is_allow_naOpt, param) {
  var is_allow_na = is_allow_naOpt !== undefined ? is_allow_naOpt : false;
  return {
          id: id,
          name: name,
          values: values,
          is_allow_na: is_allow_na
        };
}

function fromJs(js) {
  return make(js.id, js.name, js.values, undefined, undefined);
}

function fromImmutableJs(imjs) {
  var get = function ($$default, field) {
    var x = imjs.get(field);
    if (x == null) {
      console.log("[WARNING] Could not get value for field " + field);
      return $$default;
    } else {
      return x;
    }
  };
  return {
          id: String(get(-1, "id")),
          name: get("", "name"),
          values: get(Immutable.List([]), "values").toArray(),
          is_allow_na: get(false, "is_allow_na")
        };
}

function id(self) {
  return self.id;
}

function name(self) {
  return self.name;
}

function values(self) {
  return self.values;
}

function is_allow_na(self) {
  return self.is_allow_na;
}

function displayValues(self) {
  if (self.is_allow_na) {
    return $$Array.map((function (v) {
                  if (v === "__NA__") {
                    return "NA";
                  } else {
                    return v;
                  }
                }), self.values);
  } else {
    return self.values;
  }
}

function findById(id, features) {
  return Caml_option.undefined_to_opt(features.find((function (feature) {
                    return feature.id === id;
                  })));
}

function feature(feature$1) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                feature$1.id
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  feature$1.name
                ],
                /* :: */[
                  /* tuple */[
                    "values",
                    Json_encode.array((function (prim) {
                            return prim;
                          }), feature$1.values)
                  ],
                  /* :: */[
                    /* tuple */[
                      "is_allow_na",
                      feature$1.is_allow_na
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var Encode = {
  feature: feature
};

exports.make = make;
exports.fromJs = fromJs;
exports.fromImmutableJs = fromImmutableJs;
exports.id = id;
exports.name = name;
exports.values = values;
exports.is_allow_na = is_allow_na;
exports.displayValues = displayValues;
exports.findById = findById;
exports.Encode = Encode;
/* immutable Not a pure module */
