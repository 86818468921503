// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Modal$Astrolabe = require("../Modal.bs.js");

var initialState = {
  email: ""
};

function reducer(_state, action) {
  return {
          email: action[0]
        };
}

function WelcomeModal(Props) {
  var onCancel = Props.onCancel;
  var onOk = Props.onOk;
  var saving = Props.saving;
  var visible = Props.visible;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(Modal$Astrolabe.make, {
              onCancel: onCancel,
              title: "Welcome!",
              visible: visible,
              children: React.createElement("div", {
                    className: "modal-body"
                  }, React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("p", undefined, "The Antibody Staining Data Set is composed of 2,232 mass cytometry samples which were analyzed by the Astrolabe Cytometry Platform."), React.createElement("p", undefined, "Need help analyzing your cytometry data? Enter your email and we will contact you for a demo!"), React.createElement("form", {
                                className: "form"
                              }, React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("input", {
                                        className: "form-control",
                                        name: "email_address",
                                        type: "email",
                                        value: state.email,
                                        onChange: (function (e) {
                                            return Curry._1(dispatch, /* EmailUpdated */[e.currentTarget.value]);
                                          })
                                      }))))), React.createElement("hr", undefined), React.createElement("div", {
                        className: "col-12"
                      }, React.createElement("div", {
                            className: "row justify-content-between"
                          }, React.createElement("button", {
                                className: "btn text-danger btn-link btn-sm",
                                disabled: saving,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    return Curry._1(onCancel, undefined);
                                  })
                              }, "No thanks"), " ", React.createElement("button", {
                                className: "btn btn-primary",
                                disabled: saving,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    return Curry._1(onOk, state.email);
                                  })
                              }, saving ? " Saving… " : "Yes, please contact me for a demo"))))
            });
}

var make = WelcomeModal;

var $$default = WelcomeModal;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
