// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var Modal$Astrolabe = require("../../Modal.bs.js");
var Sample$Astrolabe = require("../../../models/Sample.bs.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var Constants$Astrolabe = require("../../../utils/Constants.bs.js");
var FeatureRow$Astrolabe = require("./FeatureRow.bs.js");
var FeatureForm$Astrolabe = require("./form/FeatureForm.bs.js");
var TitleWithIcon$Astrolabe = require("../../header/TitleWithIcon.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var SortableTableHeader$Astrolabe = require("../../shared/SortableTableHeader.bs.js");
var DownloadUploadTemplateBox$Astrolabe = require("../../shared/DownloadUploadTemplateBox.bs.js");

function sortSamples(sort, samples) {
  var featureId = sort[0];
  switch (featureId) {
    case "fileName" :
        return SortableTableHeader$Astrolabe.Sort.withGetter(Sample$Astrolabe.Js.fileFileName, sort[1], samples);
    case "sampleName" :
        return SortableTableHeader$Astrolabe.Sort.withGetter(Sample$Astrolabe.Js.name, sort[1], samples);
    default:
      return SortableTableHeader$Astrolabe.Sort.withGetter((function (param) {
                    return Sample$Astrolabe.Js.findFeatureValueVal(featureId, param);
                  }), sort[1], samples);
  }
}

function reducer(state, action, samples) {
  if (typeof action === "number") {
    if (action === /* CloseModal */0) {
      return {
              sortedSamples: state.sortedSamples,
              sort: state.sort,
              isUploadingTemplate: state.isUploadingTemplate,
              modalFeature: undefined,
              showModal: false
            };
    } else {
      return {
              sortedSamples: state.sortedSamples,
              sort: state.sort,
              isUploadingTemplate: state.isUploadingTemplate,
              modalFeature: state.modalFeature,
              showModal: true
            };
    }
  }
  switch (action.tag | 0) {
    case /* SetModalFeature */0 :
        return {
                sortedSamples: state.sortedSamples,
                sort: state.sort,
                isUploadingTemplate: state.isUploadingTemplate,
                modalFeature: Caml_option.some(action[0]),
                showModal: true
              };
    case /* SetIsUploadingTemplate */1 :
        return {
                sortedSamples: state.sortedSamples,
                sort: state.sort,
                isUploadingTemplate: action[0],
                modalFeature: state.modalFeature,
                showModal: state.showModal
              };
    case /* SetSamples */2 :
        var sortedSamples = sortSamples(state.sort, action[0]);
        return {
                sortedSamples: sortedSamples,
                sort: state.sort,
                isUploadingTemplate: state.isUploadingTemplate,
                modalFeature: state.modalFeature,
                showModal: state.showModal
              };
    case /* SetSort */3 :
        var sort = action[0];
        var sortedSamples$1 = sortSamples(sort, samples);
        return {
                sortedSamples: sortedSamples$1,
                sort: sort,
                isUploadingTemplate: state.isUploadingTemplate,
                modalFeature: state.modalFeature,
                showModal: state.showModal
              };
    
  }
}

function FeaturesTable(Props) {
  var experimentId = Props.experimentId;
  var features = Props.features;
  var isEditable = Props.isEditable;
  var hasDeAnalysis = Props.hasDeAnalysis;
  var samples = Props.samples;
  var onComplete = Props.onComplete;
  var initialState_sort = /* tuple */[
    "fileName",
    /* ASC */0
  ];
  var initialState = {
    sortedSamples: samples,
    sort: initialState_sort,
    isUploadingTemplate: false,
    modalFeature: undefined,
    showModal: false
  };
  var match = React.useReducer((function (param, param$1) {
          return reducer(param, param$1, samples);
        }), initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* SetSamples */Block.__(2, [samples]));
          
        }), [samples]);
  var match$1 = state.modalFeature;
  var renderModal = React.createElement(Modal$Astrolabe.make, {
        onCancel: (function (param) {
            return Curry._1(dispatch, /* CloseModal */0);
          }),
        title: match$1 !== undefined ? "Edit Feature" : "Add Feature",
        visible: state.showModal,
        children: React.createElement(FeatureForm$Astrolabe.make, {
              experimentId: experimentId,
              feature: state.modalFeature,
              onCancel: (function (param) {
                  return Curry._1(dispatch, /* CloseModal */0);
                }),
              onSuccess: (function (param) {
                  return Curry._1(dispatch, /* CloseModal */0);
                })
            })
      });
  var featureHeaders = features.map((function (feature, i) {
          var featureName = Feature$Astrolabe.name(feature);
          var headerName = isEditable ? React.createElement("a", {
                  href: "",
                  onClick: (function (e) {
                      ReasonReactHelpers$Astrolabe.preventDefault(e);
                      return Curry._1(dispatch, /* SetModalFeature */Block.__(0, [feature]));
                    })
                }, featureName) : featureName;
          return React.createElement("th", {
                      key: String(i) + featureName,
                      style: {
                        minWidth: "150px"
                      }
                    }, React.createElement(SortableTableHeader$Astrolabe.make, {
                          columnName: Feature$Astrolabe.id(feature),
                          headerName: headerName,
                          handleOnClick: (function (sort) {
                              return Curry._1(dispatch, /* SetSort */Block.__(3, [sort]));
                            }),
                          sortState: state.sort
                        }));
        }));
  var subtitle = isEditable ? React.createElement("span", undefined, "Define the features used in your experiment and map them to samples. Check out the ", React.createElement("a", {
              href: Constants$Astrolabe.fileUploadTutorialUrl,
              target: "_blank"
            }, "Experiment Setup tutorial"), " for more information.") : "The features used in your experiment and how they are mapped to samples. This cannot be edited since the analysis is done.";
  var featureTemplateText = React.createElement(React.Fragment, undefined, "If you would like to define the features using a spreadsheet, download a template using the 'Download Template' button. You can then upload the completed spreadsheet using the 'Upload Template' button. This will replace all existing features. Check out the ", React.createElement("a", {
            href: Constants$Astrolabe.usingTemplateTutorialUrl,
            target: "_blank"
          }, "Using Templates tutorial"), " for more information.", hasDeAnalysis ? React.createElement("div", {
              className: "text-danger text-center pt-2"
            }, "Templates can only be uploaded if there are no Differential Expression Analysis.") : null);
  var uploadButtonTooltip = hasDeAnalysis ? "Templates can only be uploaded if there are no Differential Expression Analysis." : "Upload a filled-in template into the Astrolabe sample features. This will replace all existing features.";
  var featureTemplateBox = React.createElement(DownloadUploadTemplateBox$Astrolabe.make, {
        downloadButtonTooltip: "Download a spreadsheet template where you can fill in sample features using an editor.",
        downloadUrl: "/experiments/" + (experimentId + "/features_template.csv"),
        isDownloadButtonDisabled: hasDeAnalysis,
        onUploadBegin: (function (param) {
            Curry._1(dispatch, /* SetIsUploadingTemplate */Block.__(1, [true]));
            return Curry._5(onComplete, undefined, true, undefined, undefined, undefined);
          }),
        onUploadComplete: (function (errors, disableContinue, success) {
            if (success) {
              return ReasonReactHelpers$Astrolabe.reload(undefined);
            } else {
              Curry._1(dispatch, /* SetIsUploadingTemplate */Block.__(1, [false]));
              return Curry._5(onComplete, errors, disableContinue, undefined, success, undefined);
            }
          }),
        text: featureTemplateText,
        uploadButtonTooltip: uploadButtonTooltip,
        uploadUrl: "/experiments/" + (experimentId + "/import_features_template")
      });
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "d-flex justify-content-between align-items-center"
                }, React.createElement(TitleWithIcon$Astrolabe.make, {
                      className: "margin-new-title",
                      subtitle: subtitle,
                      title: "Sample Features",
                      children: React.createElement(Icons$Astrolabe.Features.make, {
                            className: "no-hover",
                            height: "60",
                            width: "60"
                          })
                    }), isEditable ? React.createElement("button", {
                        className: "btn btn-primary mx-3 title-button",
                        title: "Add a new feature to the experiment",
                        onClick: (function (param) {
                            return Curry._1(dispatch, /* OpenModal */1);
                          })
                      }, "Add Feature") : null), renderModal, isEditable ? featureTemplateBox : null, state.isUploadingTemplate ? React.createElement("div", undefined, "Uploading Template...") : React.createElement("div", {
                    className: "horizontal-scroll",
                    style: {
                      maxHeight: "59vh"
                    }
                  }, React.createElement("table", {
                        className: "table table-hover table-striped table-sm"
                      }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                    style: {
                                      minWidth: "150px"
                                    }
                                  }, React.createElement(SortableTableHeader$Astrolabe.make, {
                                        columnName: "fileName",
                                        headerName: React.createElement("div", undefined, "File Name"),
                                        handleOnClick: (function (sort) {
                                            return Curry._1(dispatch, /* SetSort */Block.__(3, [sort]));
                                          }),
                                        sortState: state.sort
                                      })), React.createElement("th", {
                                    style: {
                                      minWidth: "150px"
                                    }
                                  }, React.createElement(SortableTableHeader$Astrolabe.make, {
                                        columnName: "sampleName",
                                        headerName: React.createElement("div", undefined, "Sample Name"),
                                        handleOnClick: (function (sort) {
                                            return Curry._1(dispatch, /* SetSort */Block.__(3, [sort]));
                                          }),
                                        sortState: state.sort
                                      })), featureHeaders)), React.createElement("tbody", undefined, $$Array.map((function (sample) {
                                  return React.createElement(FeatureRow$Astrolabe.make, {
                                              isEditable: isEditable,
                                              experimentId: experimentId,
                                              features: features,
                                              onComplete: (function (hasErrors) {
                                                  return Curry._5(onComplete, undefined, hasErrors, undefined, undefined, undefined);
                                                }),
                                              sample: sample,
                                              key: Sample$Astrolabe.Js.name(sample)
                                            });
                                }), state.sortedSamples)))));
}

var $$Math;

var $$Option;

var Sample;

var Sort;

var make = FeaturesTable;

exports.$$Math = $$Math;
exports.$$Option = $$Option;
exports.Sample = Sample;
exports.Sort = Sort;
exports.sortSamples = sortSamples;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
