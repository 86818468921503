/* @flow */

import React from "react";
import { List, Map } from "immutable";
import { Link, NavLink, Route } from "react-router-dom";
import { Instance } from "models";
import { fetchJSON } from "components/utils";
import InstanceTable from "./InstanceTable.bs";
import SampleHistoryTable from "./SampleHistoryTable";
import NavTitle from "../../NavTitle";
import type { SampleHistories, Session } from "models";

type Props = {
  session: Session,
};

type State = {
  errors: ?List<string>,
  instances: List<Instance>,
  lastTwelveMonths: ?Array<string>,
  sampleHistories: ?SampleHistories,
};

export default class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errors: null,
      instances: List(),
      lastTwelveMonths: null,
      sampleHistories: null,
    };

    this.fetchInstances();
    this.fetchSampleHistories();
  }

  async fetchInstances() {
    const json = await fetchJSON("GET", `/admin/instances.json`);
    const instances = List(json.instances.map((e) => new Instance(e))).sortBy((e) => -e.get("id"));

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({ instances });
    }
  }

  async fetchSampleHistories() {
    const json = await fetchJSON("GET", `/admin/instances/sample_histories.json`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({ sampleHistories: json.histories, lastTwelveMonths: json.last_twelve_months });
    }
  }

  renderRoutes() {
    if (!this.state.instances || !this.state.sampleHistories || !this.state.lastTwelveMonths) {
      return <h3>Loading…</h3>;
    }

    const { instances, sampleHistories, lastTwelveMonths } = this.state;

    return (
      <React.Fragment>
        <Route exact path="/admin/instances" render={() => <InstanceTable />} />
        <Route
          exact
          path="/admin/instances/sample_history"
          render={() => (
            <SampleHistoryTable
              instances={instances}
              lastTwelveMonths={lastTwelveMonths}
              sampleHistories={sampleHistories}
              fetchSampleHistories={this.fetchSampleHistories}
            />
          )}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="Instance Admin" />
        <div className="row justify-content-end">
          <Link to="/admin/instances/new">
            <button className="btn btn-primary">New Instance</button>
          </Link>
        </div>
        <hr className="invisible" />

        <ul className="nav nav-pills justify-content-center mb-3">
          <li className="nav-item">
            <NavLink to="/admin/instances" exact className="nav-link" activeClassName="active">
              Instances
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/instances/sample_history" exact className="nav-link" activeClassName="active">
              Sample History
            </NavLink>
          </li>
        </ul>

        <div className="row">{this.renderRoutes()}</div>
      </React.Fragment>
    );
  }
}
