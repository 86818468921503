// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Models = require("models");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function id(self) {
  return self.id;
}

function sample_id(self) {
  return self.sample_id;
}

function sampleId(self) {
  return self.sample_id;
}

function featureId(self) {
  return self.feature_id;
}

function value(self) {
  return self.value;
}

function findBySampleAndFeature(featureId, sampleId, sampleFeatureValues) {
  return Caml_option.undefined_to_opt(sampleFeatureValues.find((function (featureValue) {
                    if (featureValue.sample_id === sampleId) {
                      return featureValue.feature_id === featureId;
                    } else {
                      return false;
                    }
                  })));
}

function toDisplayValue(featureValue) {
  var match = featureValue.value;
  if (match === "__NA__") {
    return "NA";
  } else {
    return featureValue.value;
  }
}

function toGraphQLString(string) {
  if (string === "NA") {
    return "__NA__";
  } else {
    return string;
  }
}

function toDisplayString(string) {
  if (string === "__NA__") {
    return "NA";
  } else {
    return string;
  }
}

function toJson(self) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Caml_format.caml_int_of_string(self.id)
              ],
              /* :: */[
                /* tuple */[
                  "sample_id",
                  Caml_format.caml_int_of_string(self.sample_id)
                ],
                /* :: */[
                  /* tuple */[
                    "feature_id",
                    Caml_format.caml_int_of_string(self.feature_id)
                  ],
                  /* :: */[
                    /* tuple */[
                      "value",
                      self.value
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function fromImmutableJs(imjs) {
  var get = function ($$default, field) {
    var x = imjs.get(field);
    if (x == null) {
      console.log("[WARNING] Could not get value for field " + field);
      return $$default;
    } else {
      return x;
    }
  };
  return {
          id: String(get(-1, "id")),
          sample_id: String(get(-1, "sample_id")),
          feature_id: String(get(-1, "feature_id")),
          value: get("", "value")
        };
}

function toImmutableJs(self) {
  return new Models.FeatureValue(toJson(self));
}

var Compat = {
  fromImmutableJs: fromImmutableJs,
  toImmutableJs: toImmutableJs
};

function id$1(self) {
  return self.id;
}

function sampleId$1(self) {
  return self.sampleId;
}

function featureId$1(self) {
  return self.featureId;
}

function value$1(self) {
  return self.value;
}

function toDisplayValue$1(featureValue) {
  var match = featureValue.value;
  if (match === "__NA__") {
    return "NA";
  } else {
    return featureValue.value;
  }
}

function findBySampleAndFeature$1(findFeatureId, findSampleId, sampleFeatureValues) {
  return Caml_option.undefined_to_opt(sampleFeatureValues.find((function (featureValue) {
                    if (Caml_obj.caml_equal(featureValue.sampleId, findSampleId)) {
                      return Caml_obj.caml_equal(featureValue.featureId, findFeatureId);
                    } else {
                      return false;
                    }
                  })));
}

var Js = {
  id: id$1,
  sampleId: sampleId$1,
  featureId: featureId$1,
  value: value$1,
  toDisplayValue: toDisplayValue$1,
  findBySampleAndFeature: findBySampleAndFeature$1
};

exports.id = id;
exports.sample_id = sample_id;
exports.sampleId = sampleId;
exports.featureId = featureId;
exports.value = value;
exports.findBySampleAndFeature = findBySampleAndFeature;
exports.toDisplayValue = toDisplayValue;
exports.toGraphQLString = toGraphQLString;
exports.toDisplayString = toDisplayString;
exports.toJson = toJson;
exports.Compat = Compat;
exports.Js = Js;
/* models Not a pure module */
