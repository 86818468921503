// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Models = require("models");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var Theme$Astrolabe = require("../../models/Theme.bs.js");
var Sample$Astrolabe = require("../../models/Sample.bs.js");
var Feature$Astrolabe = require("../../models/Feature.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var ReactScrollSync = require("react-scroll-sync");
var AnalysisKit$Astrolabe = require("../../models/AnalysisKit.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SortByDropdown$Astrolabe = require("../experiments/SortByDropdown.bs.js");
var DEASampleDotPlot$Astrolabe = require("./DEASampleDotPlot.bs.js");
var DataTableHeatMap$Astrolabe = require("../DataTableHeatMap.bs.js");
var DEAFeatureBoxPlot$Astrolabe = require("./DEAFeatureBoxPlot.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");
var GraphDownloadButton$Astrolabe = require("../GraphDownloadButton.bs.js");
var DifferentialExpressionData$Astrolabe = require("../../models/DifferentialExpressionData.bs.js");

function str(prim) {
  return prim;
}

function array_min_max(data, get) {
  return $$Array.fold_left((function (param, item) {
                var max = param[1];
                var min = param[0];
                var num = Curry._1(get, item);
                var min$1 = num < min ? num : min;
                var max$1 = num > max ? num : max;
                return /* tuple */[
                        min$1,
                        max$1
                      ];
              }), /* tuple */[
              0.0,
              0.0
            ], data);
}

function roundMinYVal(minYVal) {
  if (minYVal >= 0.0) {
    return 0.0;
  } else {
    return Math.floor(minYVal * 10.0) / 10.0;
  }
}

function roundMaxYVal(maxYVal) {
  if (maxYVal >= 2.0) {
    return Math.ceil(maxYVal);
  } else if (maxYVal >= 0.0) {
    return Math.ceil(maxYVal * 10.0) / 10.0;
  } else {
    return Math.floor(maxYVal * 10.0) / 10.0;
  }
}

function DEAMarkerChannelView(Props) {
  var cellSubsets = Props.cellSubsets;
  var channelDesc = Props.channelDesc;
  var kitName = Props.kitName;
  var kitMarkerStatistic = Props.kitMarkerStatistic;
  var deData = Props.deData;
  var features = Props.features;
  var experimentName = Props.experimentName;
  var primaryFeatureId = Props.primaryFeatureId;
  var samples = Props.samples;
  var disclosed = Props.disclosed;
  var activeTab = Props.activeTab;
  var onActiveTabChanged = Props.onActiveTabChanged;
  var setNotificationStatus = Props.setNotificationStatus;
  var onCloseClick = Props.onCloseClick;
  var param = Props.sorting;
  var setSorting = Props.setSorting;
  var sortAscending = param[1];
  var sortOrder = param[0];
  var match = React.useState((function () {
          return disclosed;
        }));
  var setDisclosed = match[1];
  var disclosed$1 = match[0];
  var boxPlotGraphRefs = React.useRef([]);
  var dotPlotGraphRefs = React.useRef([]);
  var heatMapRef = React.useRef(null);
  var onActiveTabChanged$1 = function (tab) {
    if (cellSubsets.length === 0 && tab !== /* Overview */0) {
      Curry._1(setNotificationStatus, /* tuple */[
            /* warning */164354652,
            "No Cell Subsets selected. Please select at least one to view the plots."
          ]);
      return Curry._1(onActiveTabChanged, /* Overview */0);
    } else {
      return Curry._1(onActiveTabChanged, tab);
    }
  };
  var sampleById = React.useMemo((function () {
          var addById = function (acc, sample) {
            return Curry._3(Util$Astrolabe.$$String.$$Map.add, Sample$Astrolabe.Js.id(sample), sample, acc);
          };
          return $$Array.fold_left(addById, Util$Astrolabe.$$String.$$Map.empty, samples);
        }), [samples]);
  var featureById = React.useMemo((function () {
          var addById = function (acc, feature) {
            return Curry._3(Util$Astrolabe.$$String.$$Map.add, Feature$Astrolabe.id(feature), feature, acc);
          };
          return $$Array.fold_left(addById, Util$Astrolabe.$$String.$$Map.empty, features);
        }), [features]);
  var featureValueData = React.useMemo((function () {
          var primaryFeature = Curry._2(Util$Astrolabe.$$String.$$Map.find, primaryFeatureId, featureById);
          var primaryFeatureValues = Feature$Astrolabe.values(primaryFeature);
          var addFeatureValueColor = function (param, featureValue) {
            var i = param[1];
            var color = Util$Astrolabe.or(Belt_Array.get(Theme$Astrolabe.dark2, i), "#666666");
            return /* tuple */[
                    Curry._3(Util$Astrolabe.$$String.$$Map.add, featureValue, {
                          color: color,
                          index: i
                        }, param[0]),
                    i + 1 | 0
                  ];
          };
          return $$Array.fold_left(addFeatureValueColor, /* tuple */[
                        Util$Astrolabe.$$String.$$Map.empty,
                        0
                      ], primaryFeatureValues)[0];
        }), /* tuple */[
        primaryFeatureId,
        featureById
      ]);
  var getColorForFeatureValue = function (featureValue) {
    if (featureValue === "") {
      return "rgba(255, 255, 255, 0.0)";
    }
    try {
      return Curry._2(Util$Astrolabe.$$String.$$Map.find, featureValue, featureValueData).color;
    }
    catch (exn){
      if (exn === Caml_builtin_exceptions.not_found) {
        return "#AAAAAA";
      }
      throw exn;
    }
  };
  var makeProps = disclosed$1 ? (function (prim, prim$1, prim$2, prim$3, prim$4, prim$5) {
        var tmp = { };
        if (prim !== undefined) {
          tmp.className = Caml_option.valFromOption(prim);
        }
        if (prim$1 !== undefined) {
          tmp.height = Caml_option.valFromOption(prim$1);
        }
        if (prim$2 !== undefined) {
          tmp.width = Caml_option.valFromOption(prim$2);
        }
        if (prim$3 !== undefined) {
          tmp.style = Caml_option.valFromOption(prim$3);
        }
        if (prim$4 !== undefined) {
          tmp.key = Caml_option.valFromOption(prim$4);
        }
        return tmp;
      }) : (function (prim, prim$1, prim$2, prim$3, prim$4, prim$5) {
        var tmp = { };
        if (prim !== undefined) {
          tmp.className = Caml_option.valFromOption(prim);
        }
        if (prim$1 !== undefined) {
          tmp.height = Caml_option.valFromOption(prim$1);
        }
        if (prim$2 !== undefined) {
          tmp.width = Caml_option.valFromOption(prim$2);
        }
        if (prim$3 !== undefined) {
          tmp.style = Caml_option.valFromOption(prim$3);
        }
        if (prim$4 !== undefined) {
          tmp.key = Caml_option.valFromOption(prim$4);
        }
        return tmp;
      });
  var make = disclosed$1 ? Icons$Astrolabe.ArrowDown.make : Icons$Astrolabe.ArrowRight.make;
  var header = React.createElement("div", {
        className: "d-flex justify-content-between py-2 my-2"
      }, React.createElement("h3", {
            className: "cursor-pointer mb-0",
            onClick: (function (param) {
                return Curry._1(setDisclosed, (function (param) {
                              return !disclosed$1;
                            }));
              })
          }, Curry._1(make, Curry._6(makeProps, "align-baseline", "20px", "20px", Caml_option.some({
                        filter: "brightness(0)"
                      }), undefined, undefined)), " " + channelDesc), React.createElement("button", {
            className: "close ml-4",
            onClick: (function (param) {
                return Curry._1(onCloseClick, undefined);
              })
          }, "x"));
  if (!disclosed$1) {
    return header;
  }
  var samplesSortOrder = DifferentialExpressionData$Astrolabe.getSortOrder(sortOrder, deData);
  var samplesSortOrder$1 = sortAscending ? samplesSortOrder : Belt_Array.reverse(samplesSortOrder);
  var unsortedDataTable = DifferentialExpressionData$Astrolabe.channelExpressionDataTable(channelDesc, deData);
  var sortedDataTable = sortOrder === "alphanumeric" && sortAscending ? unsortedDataTable : unsortedDataTable.applySort(samplesSortOrder$1, Js_null_undefined.fromOption(true));
  var rowLabels = $$Array.map((function (id) {
          return Sample$Astrolabe.Js.name(Curry._2(Util$Astrolabe.$$String.$$Map.find, id, sampleById));
        }), sortedDataTable.rowLabels);
  var dataTable = new Models.DataTable({
        rowLabels: rowLabels,
        columnLabels: sortedDataTable.columnLabels,
        columns: sortedDataTable.columns
      });
  var groupByFeatureValue = function (param, sampleId) {
    var sampleIndex = param[1];
    var acc = param[0];
    var sample = Curry._2(Util$Astrolabe.$$String.$$Map.find, sampleId, sampleById);
    var featureValue = Sample$Astrolabe.Js.featureValueForId(primaryFeatureId, sample);
    var acc$1;
    if (featureValue !== undefined && featureValue !== "__NA__") {
      var sampleWithIndex = {
        sample: sample,
        sampleIndex: sampleIndex
      };
      acc$1 = Curry._3(Util$Astrolabe.$$String.$$Map.add_to_list, featureValue, sampleWithIndex, acc);
    } else {
      acc$1 = acc;
    }
    return /* tuple */[
            acc$1,
            sampleIndex + 1 | 0
          ];
  };
  var compareItems = function (param, param$1) {
    var fv1Idx = Curry._2(Util$Astrolabe.$$String.$$Map.find, param[0], featureValueData).index;
    var fv2Idx = Curry._2(Util$Astrolabe.$$String.$$Map.find, param$1[0], featureValueData).index;
    return Caml_primitive.caml_int_compare(fv1Idx, fv2Idx);
  };
  var samplesWithIndexByFeatureValue = List.sort(compareItems, Curry._1(Util$Astrolabe.$$String.$$Map.bindings, $$Array.fold_left(groupByFeatureValue, /* tuple */[
                  Util$Astrolabe.$$String.$$Map.empty,
                  0
                ], deData.sampleIds)[0]));
  var match$1 = cellSubsets.reduce((function (param, cellSubset) {
          var maxYs = param[1];
          var minYs = param[0];
          var expr = DifferentialExpressionData$Astrolabe.channelExpression(channelDesc, cellSubset, deData);
          var getExprValues = function (samplesWithIndex) {
            return $$Array.of_list(List.fold_left((function (acc, sampleWithIndex) {
                              var x = Caml_array.caml_array_get(expr, sampleWithIndex.sampleIndex);
                              if (Caml_primitive.caml_float_compare(x, Number.NaN) === 0) {
                                return acc;
                              } else {
                                return /* :: */[
                                        x,
                                        acc
                                      ];
                              }
                            }), /* [] */0, samplesWithIndex));
          };
          var yDataBySampleId = List.fold_left((function (yData, param) {
                  var y = getExprValues(param[1]);
                  yData.push({
                        y: y.length === 0 ? [0.0] : y
                      });
                  return yData;
                }), [], samplesWithIndexByFeatureValue);
          var match = $$Array.fold_left((function (param, col) {
                  var max = param[1];
                  var min = param[0];
                  var match = array_min_max(col.y, (function (item) {
                          return item;
                        }));
                  var col_max = match[1];
                  var col_min = match[0];
                  var min$1 = col_min < min ? col_min : min;
                  var max$1 = col_max > max ? col_max : max;
                  return /* tuple */[
                          min$1,
                          max$1
                        ];
                }), /* tuple */[
                0.0,
                0.0
              ], yDataBySampleId);
          minYs.push(match[0]);
          maxYs.push(match[1]);
          return /* tuple */[
                  minYs,
                  maxYs
                ];
        }), /* tuple */[
        [],
        []
      ]);
  var maxDataY = Util$Astrolabe.or(Util$Astrolabe.$$Array.max(match$1[1]), roundMaxYVal(0.0));
  var minDataY = Util$Astrolabe.or(Util$Astrolabe.$$Array.min(match$1[0]), roundMinYVal(0.0));
  var tmp;
  if (activeTab !== /* FeatureBoxPlots */1) {
    var selectOptions = $$Array.map((function (value) {
            var label;
            if (value.startsWith("feature_")) {
              var featureId = value.replace("feature_", "");
              try {
                label = Feature$Astrolabe.name(Curry._2(Util$Astrolabe.$$String.$$Map.find, featureId, featureById));
              }
              catch (exn){
                if (exn === Caml_builtin_exceptions.not_found) {
                  console.log("[WARNING] Could not find feature with id " + featureId);
                  label = value;
                } else {
                  throw exn;
                }
              }
            } else {
              label = value === "alphanumeric" ? "Sample Name" : value;
            }
            return React.createElement("option", {
                        key: value,
                        value: value
                      }, label);
          }), Object.keys(deData.suggestedOrder));
    tmp = React.createElement(SortByDropdown$Astrolabe.make, {
          handleSortChange: (function (e) {
              var order = ReasonReactHelpers$Astrolabe.valueFromEvent(e);
              return Curry._1(setSorting, /* tuple */[
                          order,
                          sortAscending
                        ]);
            }),
          klass: "pl-5",
          label: "Sort Samples by",
          onSortClick: (function (param) {
              return Curry._1(setSorting, /* tuple */[
                          sortOrder,
                          !sortAscending
                        ]);
            }),
          selectOptions: selectOptions,
          sortAscending: sortAscending,
          valueSort: sortOrder,
          width: "190px"
        });
  } else {
    tmp = null;
  }
  var tmp$1;
  switch (activeTab) {
    case /* Overview */0 :
        tmp$1 = React.createElement(ReactScrollSync.ScrollSyncPane, {
              children: React.createElement("div", {
                    ref: heatMapRef,
                    className: "position-relative"
                  }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                        prefix: experimentName,
                        name: "Box Plot",
                        graphType: /* Canvas */-321468168,
                        container: (function (param) {
                            return heatMapRef.current;
                          }),
                        filenames: "Analyses " + (kitName + " Overview")
                      }), React.createElement(DataTableHeatMap$Astrolabe.make, {
                        dataTable: dataTable,
                        colors: Curry._1(AnalysisKit$Astrolabe.HeatMap.Overview.colors, kitMarkerStatistic),
                        legendTitle: Curry._1(AnalysisKit$Astrolabe.HeatMap.Overview.legendTitle, kitMarkerStatistic),
                        convertValuesToPercentages: Curry._1(AnalysisKit$Astrolabe.HeatMap.Overview.convertValuesToPercentages, kitMarkerStatistic)
                      }))
            });
        break;
    case /* FeatureBoxPlots */1 :
        tmp$1 = React.createElement(ReactScrollSync.ScrollSyncPane, {
              children: React.createElement("div", {
                    className: "d-flex flex-nowrap horizontal-scroll"
                  }, $$Array.mapi((function (i, cellSubset) {
                          var expr = DifferentialExpressionData$Astrolabe.channelExpression(channelDesc, cellSubset, deData);
                          var getExprValues = function (samplesWithIndex) {
                            return $$Array.of_list(List.fold_left((function (acc, sampleWithIndex) {
                                              var x = Caml_array.caml_array_get(expr, sampleWithIndex.sampleIndex);
                                              if (Caml_primitive.caml_float_compare(x, Number.NaN) === 0) {
                                                return acc;
                                              } else {
                                                return /* :: */[
                                                        x,
                                                        acc
                                                      ];
                                              }
                                            }), /* [] */0, samplesWithIndex));
                          };
                          var match = List.fold_left((function (param, param$1) {
                                  var featureValue = param$1[0];
                                  var featureValues = param[1];
                                  var data = param[0];
                                  var y = getExprValues(param$1[1]);
                                  var data$1 = y.length === 0 ? data : (data.push({
                                            x: featureValue,
                                            y: y
                                          }), data);
                                  featureValues.push(featureValue);
                                  return /* tuple */[
                                          data$1,
                                          featureValues
                                        ];
                                }), /* tuple */[
                                [],
                                []
                              ], samplesWithIndexByFeatureValue);
                          var dataBoxPlot = match[0];
                          var dataScatter = $$Array.concat($$Array.to_list($$Array.map((function (item) {
                                          return $$Array.map((function (y) {
                                                        return {
                                                                x: item.x,
                                                                y: y
                                                              };
                                                      }), item.y);
                                        }), dataBoxPlot)));
                          return React.createElement(DEAFeatureBoxPlot$Astrolabe.make, {
                                      experimentName: experimentName,
                                      cellSubset: cellSubset,
                                      channelDesc: channelDesc,
                                      graphRefs: boxPlotGraphRefs,
                                      getColorForFeatureValue: getColorForFeatureValue,
                                      featureValues: match[1],
                                      dataBoxPlot: dataBoxPlot,
                                      dataScatter: dataScatter,
                                      position: i,
                                      minDataY: minDataY,
                                      maxDataY: maxDataY,
                                      key: String(i)
                                    });
                        }), cellSubsets))
            });
        break;
    case /* SampleDotPlots */2 :
        tmp$1 = React.createElement(ReactScrollSync.ScrollSyncPane, {
              children: React.createElement("div", {
                    className: "d-flex flex-nowrap horizontal-scroll"
                  }, $$Array.mapi((function (i, cellSubset) {
                          var expr = DifferentialExpressionData$Astrolabe.channelExpression(channelDesc, cellSubset, deData);
                          var aggregateSampleData = function (dataBySampleId, param) {
                            var featureValue = param[0];
                            return List.fold_left((function (dataBySampleId, sampleWithIndex) {
                                          var x = Sample$Astrolabe.Js.name(sampleWithIndex.sample);
                                          var y = Caml_array.caml_array_get(expr, sampleWithIndex.sampleIndex);
                                          if (Caml_primitive.caml_float_compare(y, Number.NaN) === 0) {
                                            return dataBySampleId;
                                          }
                                          var p = {
                                            x: x,
                                            featureValue: featureValue,
                                            y: y
                                          };
                                          return Curry._3(Util$Astrolabe.$$String.$$Map.add, Sample$Astrolabe.Js.id(sampleWithIndex.sample), p, dataBySampleId);
                                        }), dataBySampleId, param[1]);
                          };
                          var dataBySampleId = List.fold_left(aggregateSampleData, Util$Astrolabe.$$String.$$Map.empty, samplesWithIndexByFeatureValue);
                          var data = $$Array.map((function (id) {
                                  var sampleData = Curry._2(Util$Astrolabe.$$String.$$Map.find_opt, id, dataBySampleId);
                                  if (sampleData !== undefined) {
                                    return Caml_option.valFromOption(sampleData);
                                  } else {
                                    return {
                                            x: Util$Astrolabe.or(Curry._2(Util$Astrolabe.$$Option.map, Curry._2(Util$Astrolabe.$$String.$$Map.find_opt, id, sampleById), Sample$Astrolabe.Js.name), "Sample " + id),
                                            featureValue: "",
                                            y: 0.0
                                          };
                                  }
                                }), samplesSortOrder$1);
                          return React.createElement(DEASampleDotPlot$Astrolabe.make, {
                                      position: i,
                                      graphRefs: dotPlotGraphRefs,
                                      getColorForFeatureValue: getColorForFeatureValue,
                                      experimentName: experimentName,
                                      cellSubset: cellSubset,
                                      channelDesc: channelDesc,
                                      data: data,
                                      minDataY: minDataY,
                                      maxDataY: maxDataY,
                                      key: String(i)
                                    });
                        }), cellSubsets))
            });
        break;
    
  }
  return React.createElement("div", undefined, React.createElement("hr", undefined), header, React.createElement("div", {
                  className: "row"
                }, React.createElement("div", {
                      className: "col-12"
                    }, React.createElement("div", {
                          className: "d-flex flex-row justify-content-between mb-3"
                        }, React.createElement("div", {
                              className: "d-flex mb-4"
                            }, React.createElement("div", {
                                  className: "mr-4"
                                }, React.createElement("span", {
                                      className: activeTab === /* Overview */0 ? "text-info cursor-default" : "cursor-pointer",
                                      title: "Overview",
                                      onClick: (function (param) {
                                          return onActiveTabChanged$1(/* Overview */0);
                                        })
                                    }, "Overview")), React.createElement("div", {
                                  className: "mr-4"
                                }, React.createElement("span", {
                                      className: activeTab === /* FeatureBoxPlots */1 ? "text-info cursor-default" : "cursor-pointer",
                                      title: "Feature Box Plots",
                                      onClick: (function (param) {
                                          return onActiveTabChanged$1(/* FeatureBoxPlots */1);
                                        })
                                    }, "Feature Box Plots")), React.createElement("div", undefined, React.createElement("span", {
                                      className: activeTab === /* SampleDotPlots */2 ? "text-info cursor-default" : "cursor-pointer",
                                      title: "SampleDotPlots",
                                      onClick: (function (param) {
                                          return onActiveTabChanged$1(/* SampleDotPlots */2);
                                        })
                                    }, "Sample Dot Plots"))), tmp), tmp$1)));
}

var $$Option;

var $$String$1;

var Float;

var Int;

var Sample;

var or = Util$Astrolabe.or;

var ScrollSync;

var make = DEAMarkerChannelView;

exports.$$Option = $$Option;
exports.$$String = $$String$1;
exports.Float = Float;
exports.Int = Int;
exports.Sample = Sample;
exports.or = or;
exports.ScrollSync = ScrollSync;
exports.str = str;
exports.array_min_max = array_min_max;
exports.roundMinYVal = roundMinYVal;
exports.roundMaxYVal = roundMaxYVal;
exports.make = make;
/* react Not a pure module */
