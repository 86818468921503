// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var ppx_printed_query = "query getVisibleNotifications  {\nvisibleNotifications  {\nid  \ntitle  \ntext  \nalertStyle  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "visibleNotifications");
  return {
          visibleNotifications: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map((function (value) {
                    var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                    var value$2 = Js_dict.get(value$1, "id");
                    var tmp;
                    if (value$2 !== undefined) {
                      var value$3 = Caml_option.valFromOption(value$2);
                      var value$4 = Js_json.decodeString(value$3);
                      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                    } else {
                      tmp = Js_exn.raiseError("graphql_ppx: Field id on type Notification is missing");
                    }
                    var value$5 = Js_dict.get(value$1, "title");
                    var tmp$1;
                    if (value$5 !== undefined) {
                      var value$6 = Caml_option.valFromOption(value$5);
                      var value$7 = Js_json.decodeString(value$6);
                      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Field title on type Notification is missing");
                    }
                    var value$8 = Js_dict.get(value$1, "text");
                    var tmp$2;
                    if (value$8 !== undefined) {
                      var value$9 = Caml_option.valFromOption(value$8);
                      var value$10 = Js_json.decodeString(value$9);
                      tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                    } else {
                      tmp$2 = Js_exn.raiseError("graphql_ppx: Field text on type Notification is missing");
                    }
                    var value$11 = Js_dict.get(value$1, "alertStyle");
                    var tmp$3;
                    if (value$11 !== undefined) {
                      var value$12 = Caml_option.valFromOption(value$11);
                      var value$13 = Js_json.decodeString(value$12);
                      if (value$13 !== undefined) {
                        switch (value$13) {
                          case "DANGER" :
                              tmp$3 = /* DANGER */-787153181;
                              break;
                          case "DARK" :
                              tmp$3 = /* DARK */757341302;
                              break;
                          case "INFO" :
                              tmp$3 = /* INFO */813432942;
                              break;
                          case "LIGHT" :
                              tmp$3 = /* LIGHT */-219494218;
                              break;
                          case "PRIMARY" :
                              tmp$3 = /* PRIMARY */55579554;
                              break;
                          case "SECONDARY" :
                              tmp$3 = /* SECONDARY */-577585324;
                              break;
                          case "SUCCESS" :
                              tmp$3 = /* SUCCESS */941750691;
                              break;
                          case "WARNING" :
                              tmp$3 = /* WARNING */161459772;
                              break;
                          default:
                            tmp$3 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AlertStyle: " + value$13);
                        }
                      } else {
                        tmp$3 = Js_exn.raiseError("graphql_ppx: Expected enum value for AlertStyle, got " + JSON.stringify(value$12));
                      }
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field alertStyle on type Notification is missing");
                    }
                    return {
                            id: tmp,
                            title: tmp$1,
                            text: tmp$2,
                            alertStyle: tmp$3
                          };
                  })) : Js_exn.raiseError("graphql_ppx: Field visibleNotifications on type Query is missing")
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_002(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetVisible = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation DismissNotification($notificationId: ID!)  {\ndismissNotification(notificationId: $notificationId)  {\ndismissedNotification  {\nid  \n}\n\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "dismissNotification");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "dismissedNotification");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "id");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field id on type DismissedNotification is missing");
          }
          tmp$1 = {
            id: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      var value$11 = Js_dict.get(value$4, "errors");
      tmp = {
        dismissedNotification: tmp$1,
        errors: value$11 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$11))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type DismissedNotificationPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          dismissNotification: tmp
        };
}

function make$1(notificationId, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "notificationId",
                    notificationId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var notificationId = variables.notificationId;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "notificationId",
                    notificationId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(notificationId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "notificationId",
                  notificationId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, notificationId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "notificationId",
                      notificationId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var Dismiss = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

exports.GetVisible = GetVisible;
exports.Dismiss = Dismiss;
/* No side effect */
