// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactSelect = require("react-select");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Modal$Astrolabe = require("../../Modal.bs.js");
var ReactSelect$Astrolabe = require("../../../utils/ReactSelect.bs.js");
var DebarcodingKit$Astrolabe = require("../../../models/DebarcodingKit.bs.js");

function str(prim) {
  return prim;
}

function valueFromEvent(evt) {
  return evt.currentTarget.value;
}

function newForm(kit, channelUsages, send) {
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "form-group"
                }, React.createElement("label", undefined, "Kit Name"), React.createElement("input", {
                      className: "form-control",
                      name: "name",
                      type: "text",
                      value: kit.name,
                      onChange: (function (e) {
                          return Curry._1(send, /* NameChanged */Block.__(2, [e.currentTarget.value]));
                        })
                    })), React.createElement("div", {
                  className: "form-group"
                }, React.createElement("label", undefined, "Which channels are used to barcode?"), React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Multi.makeProps(undefined, $$Array.map((function (cu) {
                                  return Util$Astrolabe.HtmlOption.toJs({
                                              value: cu.name,
                                              label: cu.name
                                            });
                                }), channelUsages), (function (opts) {
                              return Curry._1(send, /* ChannelsChanged */Block.__(3, [$$Array.map((function (opt) {
                                                    return Util$Astrolabe.HtmlOption.fromJs(opt).value;
                                                  }), opts)]));
                            }), undefined, undefined, undefined, undefined)(undefined, undefined))), React.createElement("div", {
                  className: "form-group"
                }, React.createElement("label", undefined, "How many channels are used to identify each sample?"), React.createElement("input", {
                      className: "form-control",
                      max: String(Math.max(kit.channels.length - 1 | 0, 1)),
                      min: "1",
                      name: "channelsUsedPerSample",
                      type: "number",
                      value: String(kit.channelsUsedPerSample),
                      onChange: (function (e) {
                          return Curry._1(send, /* ChannelsUsedPerSampleChanged */Block.__(4, [Caml_format.caml_int_of_string(e.currentTarget.value)]));
                        })
                    })));
}

function renderErrors(errors, shouldDisplayErrors) {
  if (errors.length !== 0 && shouldDisplayErrors) {
    return React.createElement("div", {
                className: "row align-items-center justify-content-center"
              }, React.createElement("div", {
                    className: "alert alert-danger",
                    role: "alert"
                  }, React.createElement("p", {
                        className: "mb-1"
                      }, "Couldn't add the debarcoding kit due to the following errors:"), React.createElement("ul", {
                        className: "mb-0"
                      }, $$Array.mapi((function (i, error) {
                              return React.createElement("li", {
                                          key: String(i)
                                        }, error);
                            }), errors))));
  } else {
    return null;
  }
}

function makeInitialState(debarcodingKits) {
  return {
          shouldDisplayErrors: true,
          existingKitId: "",
          kit: DebarcodingKit$Astrolabe.make("", "", undefined, undefined, undefined),
          newOrExisting: debarcodingKits.length === 0 ? /* New */0 : /* Existing */1
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            shouldDisplayErrors: state.shouldDisplayErrors,
            existingKitId: "",
            kit: state.kit,
            newOrExisting: state.newOrExisting
          };
  }
  switch (action.tag | 0) {
    case /* NewOrExistingChanged */0 :
        return {
                shouldDisplayErrors: false,
                existingKitId: "",
                kit: DebarcodingKit$Astrolabe.make("", "", undefined, undefined, undefined),
                newOrExisting: action[0]
              };
    case /* ExistingDebarcodingKitChanged */1 :
        return {
                shouldDisplayErrors: false,
                existingKitId: action[0],
                kit: state.kit,
                newOrExisting: state.newOrExisting
              };
    case /* NameChanged */2 :
        var init = state.kit;
        return {
                shouldDisplayErrors: state.shouldDisplayErrors,
                existingKitId: state.existingKitId,
                kit: {
                  id: init.id,
                  createUserEmail: init.createUserEmail,
                  name: action[0],
                  channels: init.channels,
                  channelsUsedPerSample: init.channelsUsedPerSample,
                  maxSamplesPerFile: init.maxSamplesPerFile
                },
                newOrExisting: state.newOrExisting
              };
    case /* ChannelsChanged */3 :
        return {
                shouldDisplayErrors: state.shouldDisplayErrors,
                existingKitId: state.existingKitId,
                kit: DebarcodingKit$Astrolabe.withChannels(action[0], state.kit),
                newOrExisting: state.newOrExisting
              };
    case /* ChannelsUsedPerSampleChanged */4 :
        return {
                shouldDisplayErrors: state.shouldDisplayErrors,
                existingKitId: state.existingKitId,
                kit: DebarcodingKit$Astrolabe.withChannelsUsedPerSample(action[0], state.kit),
                newOrExisting: state.newOrExisting
              };
    case /* SetShouldDisplayErrors */5 :
        return {
                shouldDisplayErrors: action[0],
                existingKitId: state.existingKitId,
                kit: state.kit,
                newOrExisting: state.newOrExisting
              };
    case /* UpdateNewToExisting */6 :
        var kit = action[1];
        return {
                shouldDisplayErrors: state.shouldDisplayErrors,
                existingKitId: kit.id,
                kit: kit,
                newOrExisting: action[0]
              };
    
  }
}

function AddDebarcodingKitModal(Props) {
  var debarcodingKits = Props.debarcodingKits;
  var channelUsages = Props.channelUsages;
  var errors = Props.errors;
  var onCancel = Props.onCancel;
  var onOk = Props.onOk;
  var visible = Props.visible;
  var initialState = makeInitialState(debarcodingKits);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var kit = debarcodingKits.find((function (kit) {
                  return kit.name === state.kit.name;
                }));
          if (kit !== undefined) {
            Curry._1(dispatch, /* UpdateNewToExisting */Block.__(6, [
                    /* Existing */1,
                    kit
                  ]));
          }
          if (Util$Astrolabe.$$Array.isEmpty(errors)) {
            Curry._1(dispatch, /* ResetExistingId */0);
          }
          
        }), [debarcodingKits]);
  var existingForm = function (debarcodingKits) {
    var defaultValue = Util$Astrolabe.HtmlOption.toJs({
          value: state.kit.id,
          label: state.kit.name
        });
    var onChange = function (o) {
      var kitId = Util$Astrolabe.HtmlOption.fromJs(o).value;
      var selectedKit = debarcodingKits.find((function (kit) {
              return DebarcodingKit$Astrolabe.id(kit) === kitId;
            }));
      return Util$Astrolabe.$$Option.if_some(selectedKit === undefined ? undefined : Caml_option.some(selectedKit), (function (kit) {
                    return Curry._1(dispatch, /* UpdateNewToExisting */Block.__(6, [
                                  /* Existing */1,
                                  kit
                                ]));
                  }));
    };
    var options = $$Array.map((function (kit) {
            return Util$Astrolabe.HtmlOption.toJs({
                        value: kit.id,
                        label: kit.name
                      });
          }), debarcodingKits);
    var hasExistingKitSelected = state.existingKitId !== "";
    var existingKitChannels = hasExistingKitSelected ? DebarcodingKit$Astrolabe.channels(state.kit) : [];
    var existingKitChannelOpts = $$Array.map((function (channel) {
            return Util$Astrolabe.HtmlOption.toJs({
                        value: channel,
                        label: channel
                      });
          }), existingKitChannels);
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: "form-group"
                  }, React.createElement("label", undefined, "Kit Name"), hasExistingKitSelected ? React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Single.makeProps(Caml_option.some(defaultValue), options, onChange, undefined, undefined, undefined, undefined)(undefined, undefined)) : React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Single.makeProps(undefined, options, onChange, undefined, undefined, undefined, undefined)(undefined, undefined))), React.createElement("div", {
                    className: "form-group"
                  }, React.createElement("label", undefined, "Which channels are used to barcode?"), React.createElement("div", {
                        className: "cursor-not-allowed"
                      }, React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Multi.makeProps(true, undefined, undefined, undefined, undefined, existingKitChannelOpts, undefined)(undefined, undefined)))), React.createElement("div", {
                    className: "form-group"
                  }, React.createElement("label", undefined, "How many channels are used to identify each sample?"), React.createElement("input", {
                        className: "form-control",
                        disabled: true,
                        name: "channelsUsedPerSample",
                        value: hasExistingKitSelected ? String(DebarcodingKit$Astrolabe.channelsUsedPerSample(state.kit)) : ""
                      })));
  };
  var match$1 = state.newOrExisting;
  var addButtonDisabled = match$1 ? state.existingKitId === "" : !DebarcodingKit$Astrolabe.valid(state.kit);
  var match$2 = state.newOrExisting;
  var kit = match$2 ? Caml_option.undefined_to_opt(debarcodingKits.find((function (kit) {
                return kit.id === state.existingKitId;
              }))) : state.kit;
  var match$3 = state.newOrExisting;
  return React.createElement(Modal$Astrolabe.make, {
              onCancel: onCancel,
              title: "Add Debarcoding Kit",
              visible: visible,
              children: React.createElement("div", {
                    className: "modal-body"
                  }, renderErrors(errors, state.shouldDisplayErrors), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("form", {
                                className: "form"
                              }, React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("label", undefined, "Add a new or existing kit?"), React.createElement("div", {
                                        className: "form-check"
                                      }, React.createElement("input", {
                                            className: "form-check-input",
                                            id: "new_or_existing_kit_existing",
                                            checked: state.newOrExisting === /* Existing */1,
                                            type: "radio",
                                            value: "existing",
                                            onChange: (function (param) {
                                                return Curry._1(dispatch, /* NewOrExistingChanged */Block.__(0, [/* Existing */1]));
                                              })
                                          }), React.createElement("label", {
                                            className: "form-check-label",
                                            htmlFor: "new_or_existing_kit_existing"
                                          }, "Existing")), React.createElement("div", {
                                        className: "form-check"
                                      }, React.createElement("input", {
                                            className: "form-check-input",
                                            id: "new_or_existing_kit_new",
                                            checked: state.newOrExisting === /* New */0,
                                            type: "radio",
                                            value: "new",
                                            onChange: (function (param) {
                                                return Curry._1(dispatch, /* NewOrExistingChanged */Block.__(0, [/* New */0]));
                                              })
                                          }), React.createElement("label", {
                                            className: "form-check-label",
                                            htmlFor: "new_or_existing_kit_new"
                                          }, "New"))), match$3 ? existingForm(debarcodingKits) : newForm(state.kit, channelUsages, dispatch)))), React.createElement("hr", undefined), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-7 d-flex align-items-center text-muted"
                          }, React.createElement("small", undefined, kit !== undefined && kit.channels.length !== 0 ? "This kit allows up to " + (Util$Astrolabe.$$String.pluralize(kit.maxSamplesPerFile, "sample") + " per file") : null)), React.createElement("div", {
                            className: "col-5 d-flex justify-content-end"
                          }, React.createElement("button", {
                                className: "btn btn-light",
                                disabled: false,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    return Curry._1(onCancel, undefined);
                                  })
                              }, "Cancel"), React.createElement("span", {
                                className: "mr-1"
                              }), React.createElement("button", {
                                className: "btn btn-primary",
                                disabled: addButtonDisabled,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    var match = state.newOrExisting;
                                    if (match) {
                                      Curry._1(onOk, /* ExistingKit */Block.__(1, [state.existingKitId]));
                                    } else {
                                      Curry._1(onOk, /* NewKit */Block.__(0, [state.kit]));
                                    }
                                    return Curry._1(dispatch, /* SetShouldDisplayErrors */Block.__(5, [true]));
                                  })
                              }, "Add"))))
            });
}

var make = AddDebarcodingKitModal;

exports.str = str;
exports.valueFromEvent = valueFromEvent;
exports.newForm = newForm;
exports.renderErrors = renderErrors;
exports.makeInitialState = makeInitialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
