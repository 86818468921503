// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var ReactRouterDom = require("react-router-dom");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var ReactRouter$Astrolabe = require("../../utils/ReactRouter.bs.js");
var ExperimentsTable$Astrolabe = require("../experiments/ExperimentsTable.bs.js");
var NotificationBanners$Astrolabe = require("../notifications/NotificationBanners.bs.js");

function valueFromEvent(evt) {
  return evt.target.value;
}

function filterExperiments(query, showArchived, experiments) {
  return $$Array.of_list(List.filter((function (e) {
                      if (showArchived || !Experiment$Astrolabe.Decode.is_archived(e)) {
                        return new RegExp(query, "i").test(Curry._1(Util$Astrolabe.$$String.lowercase, Experiment$Astrolabe.Decode.name(e)));
                      } else {
                        return false;
                      }
                    }))($$Array.to_list(experiments)));
}

var initialState_experiments = [];

var initialState_filteredExperiments = [];

var initialState_errors = [];

var initialState = {
  searchText: "",
  showArchived: false,
  experiments: initialState_experiments,
  filteredExperiments: initialState_filteredExperiments,
  errors: initialState_errors
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* ExperimentsLoaded */0 :
        var experiments = action[0];
        return {
                searchText: state.searchText,
                showArchived: state.showArchived,
                experiments: experiments,
                filteredExperiments: filterExperiments(state.searchText, state.showArchived, experiments),
                errors: state.errors
              };
    case /* SearchChanged */1 :
        var searchText = action[0];
        return {
                searchText: searchText,
                showArchived: state.showArchived,
                experiments: state.experiments,
                filteredExperiments: filterExperiments(searchText, state.showArchived, state.experiments),
                errors: state.errors
              };
    case /* ShowArchivedChanged */2 :
        var showArchived = action[0];
        return {
                searchText: state.searchText,
                showArchived: showArchived,
                experiments: state.experiments,
                filteredExperiments: filterExperiments(state.searchText, showArchived, state.experiments),
                errors: state.errors
              };
    
  }
}

function ExperimentIndex(Props) {
  var session = Props.session;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Experiment$Astrolabe.fetchAllJson(undefined).then((function (experimentsJson) {
                  var sortedExperiments = Belt_SortArray.stableSortBy(experimentsJson, (function (e1, e2) {
                          return Caml_primitive.caml_int_compare(Experiment$Astrolabe.Decode.id(e2), Experiment$Astrolabe.Decode.id(e1));
                        }));
                  Curry._1(dispatch, /* ExperimentsLoaded */Block.__(0, [sortedExperiments]));
                  return Promise.resolve(undefined);
                }));
          
        }), ([]));
  var user = session.user;
  var isAdmin = (user == null) ? false : user.is_admin;
  var newExperimentButton = isAdmin ? React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps("/experiments/new", Caml_option.some(React.createElement("button", {
                      className: "btn btn-primary"
                    }, "New Experiment")), undefined)) : "";
  return React.createElement("div", undefined, React.createElement(NotificationBanners$Astrolabe.make, { }), React.createElement("div", {
                  className: "row justify-content-center"
                }, React.createElement("form", {
                      className: "form-inline col-12",
                      role: "form"
                    }, React.createElement("input", {
                          className: "form-control col-7",
                          id: "search",
                          placeholder: "Search by Experiment Name",
                          type: "search",
                          value: state.searchText,
                          onChange: (function (evt) {
                              return Curry._1(dispatch, /* SearchChanged */Block.__(1, [evt.target.value]));
                            })
                        }), React.createElement("div", {
                          className: "col-3 form-check"
                        }, React.createElement("input", {
                              className: "form-check-input",
                              id: "showArchived",
                              checked: state.showArchived,
                              type: "checkbox",
                              onChange: (function (evt) {
                                  return Curry._1(dispatch, /* ShowArchivedChanged */Block.__(2, [evt.target.checked]));
                                })
                            }), React.createElement("label", {
                              className: "form-check-label mt-1",
                              htmlFor: "showArchived"
                            }, "Show Archived Experiments")), React.createElement("div", {
                          className: "col-2 text-right pr-0"
                        }, newExperimentButton))), React.createElement("hr", {
                  className: "invisible"
                }), React.createElement(ExperimentsTable$Astrolabe.make, {
                  experiments: state.filteredExperiments
                }));
}

var $$String;

var make = ExperimentIndex;

var $$default = ExperimentIndex;

exports.$$String = $$String;
exports.valueFromEvent = valueFromEvent;
exports.filterExperiments = filterExperiments;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
