/* @flow */
"use strict";

import DataTable from "./dataTable";
import { ensureArray } from "../components/utils";

type RNumberVector = Array<number> | number;
type RStringVector = Array<string> | string;

const numberArrayWrap = (valOrArray: RNumberVector): Array<number> => ensureArray(valOrArray);
const stringArrayWrap = (valOrArray: RStringVector): Array<string> => ensureArray(valOrArray);

export default class SampleStatistics {
  subset_cell_counts: {
    [string]: { cell_subset: RStringVector, n: RNumberVector },
  };
  subset_channel_statistics: {
    [string]: { [string]: RNumberVector },
  };
  suggested_order: {
    [string]: {
      cell_subset_order: { alphanumeric: RStringVector, hierarchical: RStringVector },
      channel_order: { alphanumeric: RStringVector, hierarchical: RStringVector },
    },
  };

  constructor(props: Object) {
    this.subset_cell_counts = props.subset_cell_counts;
    this.subset_channel_statistics = props.subset_channel_statistics;
    this.suggested_order = props.suggested_order;
  }

  dataTable(channel: string): DataTable {
    const stats = this.subset_channel_statistics[channel];
    if (!stats) {
      throw `Invalid Channel ${channel}`;
    }

    let columns = {};
    for (let k in stats) {
      columns[k] = numberArrayWrap(stats[k]).map((v) => parseFloat(v));
    }

    let columnLabels = stringArrayWrap(this.suggested_order[channel].channel_order.alphanumeric);
    let rowLabels = stringArrayWrap(this.subset_cell_counts[channel].cell_subset);

    return new DataTable({ columns, columnLabels, rowLabels });
  }
}
