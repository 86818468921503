// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function BannerNotice(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "info-box mb-30px astrolabe-bg-light-yellow p-3 text-center"
            }, children);
}

var make = BannerNotice;

var $$default = BannerNotice;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
