// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Icon(M) {
  var Icons$Icon = function (Props) {
    var classNameOpt = Props.className;
    var heightOpt = Props.height;
    var widthOpt = Props.width;
    var styleOpt = Props.style;
    var className = classNameOpt !== undefined ? classNameOpt : "";
    var height = heightOpt !== undefined ? heightOpt : M.h;
    var width = widthOpt !== undefined ? widthOpt : M.w;
    var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : M.style;
    var match = M.style;
    var tmp;
    if (style !== undefined) {
      var argStyle = Caml_option.valFromOption(style);
      tmp = match !== undefined ? Caml_option.some(Object.assign(({}), Caml_option.valFromOption(match), argStyle)) : Caml_option.some(argStyle);
    } else {
      tmp = match !== undefined ? Caml_option.some(Caml_option.valFromOption(match)) : undefined;
    }
    var tmp$1 = {
      className: "icon " + className,
      height: height,
      src: M.src,
      width: width
    };
    if (tmp !== undefined) {
      tmp$1.style = Caml_option.valFromOption(tmp);
    }
    return React.createElement("img", tmp$1);
  };
  return {
          make: Icons$Icon
        };
}

var src = (require("../images/icons/analyses.png"));

function Icons$Icon(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Analyses = {
  make: Icons$Icon
};

var src$1 = (require("../images/icons/archive.png"));

function Icons$Icon$1(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$1,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Archive = {
  make: Icons$Icon$1
};

var src$2 = (require("../images/icons/arrow_down.png"));

function Icons$Icon$2(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$2,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var ArrowDown = {
  make: Icons$Icon$2
};

var src$3 = (require("../images/icons/arrow_up.png"));

function Icons$Icon$3(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$3,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var ArrowUp = {
  make: Icons$Icon$3
};

var src$4 = (require("../images/icons/arrow_down.png"));

var style = {
  transform: "rotate(-90deg)"
};

function Icons$Icon$4(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style$1 = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : style;
  var tmp = {
    className: "icon " + className,
    height: height,
    src: src$4,
    width: width
  };
  var tmp$1 = style$1 !== undefined ? Caml_option.some(Object.assign(({}), Caml_option.valFromOption(style), Caml_option.valFromOption(style$1))) : Caml_option.some(Caml_option.valFromOption(style));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("img", tmp);
}

var ArrowRight = {
  make: Icons$Icon$4
};

var src$5 = (require("../images/icons/arrow_down.png"));

var style$1 = {
  transform: "rotate(90deg)"
};

function Icons$Icon$5(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style$2 = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : style$1;
  var tmp = {
    className: "icon " + className,
    height: height,
    src: src$5,
    width: width
  };
  var tmp$1 = style$2 !== undefined ? Caml_option.some(Object.assign(({}), Caml_option.valFromOption(style$1), Caml_option.valFromOption(style$2))) : Caml_option.some(Caml_option.valFromOption(style$1));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("img", tmp);
}

var ArrowLeft = {
  make: Icons$Icon$5
};

var src$6 = (require("../images/icons/barcoding.png"));

function Icons$Icon$6(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$6,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Barcoding = {
  make: Icons$Icon$6
};

var src$7 = (require("../images/icons/bullets.png"));

function Icons$Icon$7(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$7,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Bullets = {
  make: Icons$Icon$7
};

function Icons$Close(Props) {
  var classNameOpt = Props.className;
  var style = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: className
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement("span", tmp, '\u00d7');
}

var Close = {
  make: Icons$Close
};

var src$8 = (require("../images/icons/download.png"));

function Icons$Icon$8(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$8,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Download = {
  make: Icons$Icon$8
};

var src$9 = (require("../images/icons/duplicate.png"));

function Icons$Icon$9(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$9,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Duplicate = {
  make: Icons$Icon$9
};

var src$10 = (require("../images/icons/features.png"));

function Icons$Icon$10(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$10,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Features = {
  make: Icons$Icon$10
};

var src$11 = (require("../images/icons/gear.png"));

function Icons$Icon$11(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$11,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Gear = {
  make: Icons$Icon$11
};

var src$12 = (require("../images/icons/info.png"));

function Icons$Icon$12(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$12,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Info = {
  make: Icons$Icon$12
};

var src$13 = (require("../images/icons/magnifier.png"));

function Icons$Icon$13(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$13,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Magnifier = {
  make: Icons$Icon$13
};

var src$14 = (require("../images/icons/quality_control.png"));

function Icons$Icon$14(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$14,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var QualityControl = {
  make: Icons$Icon$14
};

var src$15 = (require("../images/icons/rewind.svg"));

function Icons$Icon$15(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$15,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Rewind = {
  make: Icons$Icon$15
};

var src$16 = (require("../images/icons/sort_arrow.png"));

function Icons$Icon$16(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$16,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var SortDown = {
  make: Icons$Icon$16
};

var src$17 = (require("../images/icons/sort_arrow.png"));

var style$2 = {
  transform: "rotate(180deg)"
};

function Icons$Icon$17(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style$3 = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : style$2;
  var tmp = {
    className: "icon " + className,
    height: height,
    src: src$17,
    width: width
  };
  var tmp$1 = style$3 !== undefined ? Caml_option.some(Object.assign(({}), Caml_option.valFromOption(style$2), Caml_option.valFromOption(style$3))) : Caml_option.some(Caml_option.valFromOption(style$2));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("img", tmp);
}

var SortUp = {
  make: Icons$Icon$17
};

var src$18 = (require("../images/icons/sysadmin.png"));

function Icons$Icon$18(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$18,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Sysadmin = {
  make: Icons$Icon$18
};

var src$19 = (require("../images/icons/trash.png"));

function Icons$Icon$19(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$19,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Trash = {
  make: Icons$Icon$19
};

var src$20 = (require("../images/icons/upload.png"));

function Icons$Icon$20(Props) {
  var classNameOpt = Props.className;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var height = heightOpt !== undefined ? heightOpt : "80";
  var width = widthOpt !== undefined ? widthOpt : "80";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : undefined;
  var tmp;
  if (style !== undefined) {
    var argStyle = Caml_option.valFromOption(style);
    tmp = Caml_option.some(argStyle);
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    className: "icon " + className,
    height: height,
    src: src$20,
    width: width
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  return React.createElement("img", tmp$1);
}

var Upload = {
  make: Icons$Icon$20
};

function toJs(module_repr) {
  return module_repr.make;
}

exports.Icon = Icon;
exports.Analyses = Analyses;
exports.Archive = Archive;
exports.ArrowDown = ArrowDown;
exports.ArrowUp = ArrowUp;
exports.ArrowRight = ArrowRight;
exports.ArrowLeft = ArrowLeft;
exports.Barcoding = Barcoding;
exports.Bullets = Bullets;
exports.Close = Close;
exports.Download = Download;
exports.Duplicate = Duplicate;
exports.Features = Features;
exports.Gear = Gear;
exports.Info = Info;
exports.Magnifier = Magnifier;
exports.QualityControl = QualityControl;
exports.Rewind = Rewind;
exports.SortDown = SortDown;
exports.SortUp = SortUp;
exports.Sysadmin = Sysadmin;
exports.Trash = Trash;
exports.Upload = Upload;
exports.toJs = toJs;
/* src Not a pure module */
