// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactSelect = require("react-select");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Forms$Astrolabe = require("../../Forms.bs.js");
var Modal$Astrolabe = require("../../Modal.bs.js");
var Channel$Astrolabe = require("../../../models/Channel.bs.js");
var Feature$Astrolabe = require("../../../models/Feature.bs.js");
var AnalysisKit$Astrolabe = require("../../../models/AnalysisKit.bs.js");
var ErrorsAlert$Astrolabe = require("../../ErrorsAlert.bs.js");
var PulseEffect$Astrolabe = require("../../PulseEffect.bs.js");
var ReactSelect$Astrolabe = require("../../../utils/ReactSelect.bs.js");
var SaveDeKitButton$Astrolabe = require("./SaveDeKitButton.bs.js");

function str(prim) {
  return prim;
}

function getFeatureId(featureOpt) {
  if (featureOpt !== undefined) {
    return Feature$Astrolabe.id(Caml_option.valFromOption(featureOpt));
  } else {
    return "";
  }
}

function onChange(fn, e) {
  return Curry._1(fn, e.currentTarget.value);
}

function featureUtilHtmlOptions(features) {
  return $$Array.map((function (feature) {
                return Util$Astrolabe.HtmlOption.toJs({
                            value: Feature$Astrolabe.id(feature),
                            label: Feature$Astrolabe.name(feature)
                          });
              }), features);
}

function channelUsageUtilHtmlOptions(channelUsages) {
  return $$Array.map((function (f) {
                return Util$Astrolabe.HtmlOption.toJs({
                            value: f.id,
                            label: f.desc
                          });
              }), channelUsages.filter((function (cu) {
                    if (cu.usage !== /* IGNORED */-592639246) {
                      return cu.usage !== /* LIVE_DEAD */-568609769;
                    } else {
                      return false;
                    }
                  })));
}

function featureHtmlOptions(features) {
  return $$Array.map((function (feature) {
                return React.createElement("option", {
                            key: Feature$Astrolabe.id(feature),
                            value: Feature$Astrolabe.id(feature)
                          }, Feature$Astrolabe.name(feature));
              }), features);
}

function AddDeKitModal(Props) {
  var channelUsages = Props.channelUsages;
  var experimentId = Props.experimentId;
  var features = Props.features;
  var kit = Props.kit;
  var onClose = Props.onClose;
  var visible = Props.visible;
  var channelUsages$1 = React.useMemo((function () {
          return $$Array.map(Channel$Astrolabe.Decode.channelUsage, channelUsages.toArray());
        }), [channelUsages]);
  var match = React.useState((function () {
          return {
                  kit: kit,
                  pulseEffect: /* Disabled */0
                };
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setErrors = match$1[1];
  var onClose$1 = function (param) {
    Curry._1(onClose, undefined);
    Curry._1(setErrors, (function (param) {
            return [];
          }));
    return Curry._1(setState, (function (param) {
                  return {
                          kit: AnalysisKit$Astrolabe.DifferentialExpression.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                          pulseEffect: /* Disabled */0
                        };
                }));
  };
  var findFeature = function (featureId) {
    return Caml_option.undefined_to_opt(features.find((function (f) {
                      return getFeatureId(Caml_option.some(f)) === featureId;
                    })));
  };
  var unusedFeatures = features.filter((function (feature) {
          var id = Feature$Astrolabe.id(feature);
          if (id !== getFeatureId(state.kit.primaryFeature)) {
            return !$$Array.map(Feature$Astrolabe.id, state.kit.dependentFeatures).includes(id);
          } else {
            return false;
          }
        }));
  var primaryFeatureOptions = unusedFeatures.concat(Belt_Option.mapWithDefault(state.kit.primaryFeature, [], (function (f) {
              return [f];
            })));
  var dependentFeatureOptions = unusedFeatures.concat(state.kit.dependentFeatures);
  var feature = state.kit.referenceFeature;
  return React.createElement(Modal$Astrolabe.make, {
              onCancel: onClose$1,
              size: "lg",
              title: "Add Differential Expression Analysis",
              visible: visible,
              children: React.createElement("div", {
                    className: "modal-body"
                  }, React.createElement(ErrorsAlert$Astrolabe.make, {
                        title: "Couldn't add the analysis due to the following errors:",
                        errors: match$1[0]
                      }), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("form", {
                                className: "form"
                              }, React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("label", undefined, "Name", React.createElement(Forms$Astrolabe.Required.make, { })), React.createElement("input", {
                                        className: "form-control " + PulseEffect$Astrolabe.className(state.pulseEffect),
                                        name: "name",
                                        type: "text",
                                        value: state.kit.name,
                                        onChange: (function (param) {
                                            var name = param.currentTarget.value;
                                            return Curry._1(setState, (function (param) {
                                                          var init = state.kit;
                                                          return {
                                                                  kit: {
                                                                    id: init.id,
                                                                    position: init.position,
                                                                    name: name,
                                                                    primaryFeature: init.primaryFeature,
                                                                    primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                    dependentFeatures: init.dependentFeatures,
                                                                    referenceFeature: init.referenceFeature,
                                                                    referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                    markerChannels: init.markerChannels,
                                                                    markerStatistic: init.markerStatistic
                                                                  },
                                                                  pulseEffect: state.pulseEffect
                                                                };
                                                        }));
                                          })
                                      })), React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("label", {
                                            className: "col mb-0"
                                          }, "Primary Feature", React.createElement(Forms$Astrolabe.Required.make, { }))), React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("div", {
                                            className: "col"
                                          }, React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which feature should fold change be calculated across?"
                                              })), React.createElement("div", {
                                            className: "col"
                                          }, React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which of this feature's values should be used as a baseline?"
                                              }))), React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("select", {
                                                className: "form-control",
                                                value: getFeatureId(state.kit.primaryFeature),
                                                onChange: (function (param) {
                                                    var v = param.currentTarget.value;
                                                    var primaryFeature = findFeature(v);
                                                    return Curry._1(setState, (function (oldState) {
                                                                  var name = Belt_Option.mapWithDefault(primaryFeature, state.kit.name, Feature$Astrolabe.name);
                                                                  var init = state.kit;
                                                                  var tmp;
                                                                  if (primaryFeature !== undefined) {
                                                                    var values = Feature$Astrolabe.values(Caml_option.valFromOption(primaryFeature));
                                                                    tmp = values.length === 0 ? "" : Caml_array.caml_array_get(values, 0);
                                                                  } else {
                                                                    tmp = "";
                                                                  }
                                                                  return {
                                                                          kit: {
                                                                            id: init.id,
                                                                            position: init.position,
                                                                            name: name,
                                                                            primaryFeature: primaryFeature,
                                                                            primaryFeatureBaselineValue: tmp,
                                                                            dependentFeatures: init.dependentFeatures,
                                                                            referenceFeature: init.referenceFeature,
                                                                            referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                            markerChannels: init.markerChannels,
                                                                            markerStatistic: init.markerStatistic
                                                                          },
                                                                          pulseEffect: oldState.kit.name !== name ? PulseEffect$Astrolabe.animate(state.pulseEffect) : /* Disabled */0
                                                                        };
                                                                }));
                                                  })
                                              }, React.createElement("option", {
                                                    key: ""
                                                  }), featureHtmlOptions(primaryFeatureOptions))), React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("select", {
                                                className: "form-control",
                                                value: state.kit.primaryFeatureBaselineValue,
                                                onChange: (function (param) {
                                                    var primaryFeatureBaselineValue = param.currentTarget.value;
                                                    return Curry._1(setState, (function (param) {
                                                                  var init = state.kit;
                                                                  return {
                                                                          kit: {
                                                                            id: init.id,
                                                                            position: init.position,
                                                                            name: init.name,
                                                                            primaryFeature: init.primaryFeature,
                                                                            primaryFeatureBaselineValue: primaryFeatureBaselineValue,
                                                                            dependentFeatures: init.dependentFeatures,
                                                                            referenceFeature: init.referenceFeature,
                                                                            referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                            markerChannels: init.markerChannels,
                                                                            markerStatistic: init.markerStatistic
                                                                          },
                                                                          pulseEffect: state.pulseEffect
                                                                        };
                                                                }));
                                                  })
                                              }, Belt_Option.mapWithDefault(state.kit.primaryFeature, null, (function (feature) {
                                                      return $$Array.map((function (value) {
                                                                    return React.createElement("option", {
                                                                                key: value
                                                                              }, value);
                                                                  }), Feature$Astrolabe.values(feature));
                                                    })))))), React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("label", {
                                        className: "mb-0"
                                      }, "Dependent Features"), React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                        text: "Which features (if any) could affect or explain the changes in the primary feature?"
                                      }), React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Multi.makeProps(undefined, featureUtilHtmlOptions(dependentFeatureOptions), (function (opts) {
                                                return Curry._1(setState, (function (param) {
                                                              var init = state.kit;
                                                              return {
                                                                      kit: {
                                                                        id: init.id,
                                                                        position: init.position,
                                                                        name: init.name,
                                                                        primaryFeature: init.primaryFeature,
                                                                        primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                        dependentFeatures: Belt_Array.keepMap(opts, (function (o) {
                                                                                return findFeature(Util$Astrolabe.HtmlOption.fromJs(o).value);
                                                                              })),
                                                                        referenceFeature: init.referenceFeature,
                                                                        referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                        markerChannels: init.markerChannels,
                                                                        markerStatistic: init.markerStatistic
                                                                      },
                                                                      pulseEffect: state.pulseEffect
                                                                    };
                                                            }));
                                              }), undefined, undefined, featureUtilHtmlOptions(state.kit.dependentFeatures), undefined)(undefined, undefined))), React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("label", {
                                            className: "col mb-0"
                                          }, "Reference Feature")), React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("div", {
                                            className: "col"
                                          }, React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which feature should be used as a reference for marker background intensities?"
                                              })), React.createElement("div", {
                                            className: "col"
                                          }, React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which of this feature's values should be used as a baseline for background calculation?"
                                              }))), React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("select", {
                                                className: "form-control",
                                                value: getFeatureId(state.kit.referenceFeature),
                                                onChange: (function (param) {
                                                    var v = param.currentTarget.value;
                                                    var referenceFeature = findFeature(v);
                                                    return Curry._1(setState, (function (param) {
                                                                  var init = state.kit;
                                                                  return {
                                                                          kit: {
                                                                            id: init.id,
                                                                            position: init.position,
                                                                            name: init.name,
                                                                            primaryFeature: init.primaryFeature,
                                                                            primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                            dependentFeatures: init.dependentFeatures,
                                                                            referenceFeature: referenceFeature,
                                                                            referenceFeatureBaselineValue: Belt_Option.map(referenceFeature, (function (f) {
                                                                                    return Caml_array.caml_array_get(Feature$Astrolabe.values(f), 0);
                                                                                  })),
                                                                            markerChannels: init.markerChannels,
                                                                            markerStatistic: init.markerStatistic
                                                                          },
                                                                          pulseEffect: state.pulseEffect
                                                                        };
                                                                }));
                                                  })
                                              }, React.createElement("option", {
                                                    key: ""
                                                  }), featureHtmlOptions(features))), React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("select", {
                                                className: "form-control",
                                                value: Belt_Option.getWithDefault(state.kit.referenceFeatureBaselineValue, ""),
                                                onChange: (function (param) {
                                                    var referenceFeatureBaselineValue = param.currentTarget.value;
                                                    return Curry._1(setState, (function (param) {
                                                                  var init = state.kit;
                                                                  return {
                                                                          kit: {
                                                                            id: init.id,
                                                                            position: init.position,
                                                                            name: init.name,
                                                                            primaryFeature: init.primaryFeature,
                                                                            primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                            dependentFeatures: init.dependentFeatures,
                                                                            referenceFeature: init.referenceFeature,
                                                                            referenceFeatureBaselineValue: referenceFeatureBaselineValue,
                                                                            markerChannels: init.markerChannels,
                                                                            markerStatistic: init.markerStatistic
                                                                          },
                                                                          pulseEffect: state.pulseEffect
                                                                        };
                                                                }));
                                                  })
                                              }, feature !== undefined ? $$Array.map((function (fv) {
                                                        return React.createElement("option", {
                                                                    key: fv
                                                                  }, fv);
                                                      }), Feature$Astrolabe.values(Caml_option.valFromOption(feature))) : null)))), React.createElement("div", {
                                    className: "form-group"
                                  }, React.createElement("div", {
                                        className: "row"
                                      }, React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("label", {
                                                className: "mb-0"
                                              }, "Markers", React.createElement(Forms$Astrolabe.Required.make, { })), React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which channels represent markers relevant to this analysis? Activation and phosphorylation markers are a common choice."
                                              }), React.createElement(ReactSelect.default, ReactSelect$Astrolabe.Multi.makeProps(undefined, channelUsageUtilHtmlOptions(channelUsages$1), (function (opts) {
                                                        return Curry._1(setState, (function (param) {
                                                                      var init = state.kit;
                                                                      return {
                                                                              kit: {
                                                                                id: init.id,
                                                                                position: init.position,
                                                                                name: init.name,
                                                                                primaryFeature: init.primaryFeature,
                                                                                primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                                dependentFeatures: init.dependentFeatures,
                                                                                referenceFeature: init.referenceFeature,
                                                                                referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                                markerChannels: Belt_Array.keepMap(opts, (function (o) {
                                                                                        var cuId = Util$Astrolabe.HtmlOption.fromJs(o).value;
                                                                                        return Caml_option.undefined_to_opt(channelUsages$1.find((function (cu) {
                                                                                                          return cuId === cu.id;
                                                                                                        })));
                                                                                      })),
                                                                                markerStatistic: init.markerStatistic
                                                                              },
                                                                              pulseEffect: state.pulseEffect
                                                                            };
                                                                    }));
                                                      }), undefined, undefined, channelUsageUtilHtmlOptions(state.kit.markerChannels), undefined)(undefined, undefined))), React.createElement("div", {
                                            className: "col"
                                          }, React.createElement("label", {
                                                className: "mb-0"
                                              }, "Marker Statistic"), React.createElement(Forms$Astrolabe.ExplanatoryText.make, {
                                                text: "Which marker statistic should be used to aggregate marker intensity values?"
                                              }), $$Array.map((function (stat) {
                                                  var id = AnalysisKit$Astrolabe.Statistic.to_string(stat);
                                                  return React.createElement("div", {
                                                              key: id,
                                                              className: "form-check"
                                                            }, React.createElement("input", {
                                                                  className: "form-check-input",
                                                                  id: id,
                                                                  checked: stat === state.kit.markerStatistic,
                                                                  name: "marker-stat",
                                                                  type: "radio",
                                                                  value: id,
                                                                  onChange: (function (param) {
                                                                      var v = param.currentTarget.value;
                                                                      var markerStatistic = AnalysisKit$Astrolabe.Statistic.of_string(v);
                                                                      return Curry._1(setState, (function (param) {
                                                                                    var init = state.kit;
                                                                                    return {
                                                                                            kit: {
                                                                                              id: init.id,
                                                                                              position: init.position,
                                                                                              name: init.name,
                                                                                              primaryFeature: init.primaryFeature,
                                                                                              primaryFeatureBaselineValue: init.primaryFeatureBaselineValue,
                                                                                              dependentFeatures: init.dependentFeatures,
                                                                                              referenceFeature: init.referenceFeature,
                                                                                              referenceFeatureBaselineValue: init.referenceFeatureBaselineValue,
                                                                                              markerChannels: init.markerChannels,
                                                                                              markerStatistic: markerStatistic
                                                                                            },
                                                                                            pulseEffect: state.pulseEffect
                                                                                          };
                                                                                  }));
                                                                    })
                                                                }), React.createElement("label", {
                                                                  className: "form-check-label",
                                                                  htmlFor: id
                                                                }, AnalysisKit$Astrolabe.Statistic.name(stat) + (
                                                                  AnalysisKit$Astrolabe.Statistic.isBeta(stat) ? " " : ""
                                                                ), AnalysisKit$Astrolabe.Statistic.isBeta(stat) ? React.createElement("span", {
                                                                        className: "font-italic",
                                                                        title: "This option is in beta. Contact support@astrolabediagnostics.com with any questions."
                                                                      }, "(Experimental)") : null));
                                                }), AnalysisKit$Astrolabe.Statistic.all))))))), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-12 d-flex justify-content-end"
                          }, React.createElement("button", {
                                className: "btn btn-light",
                                disabled: false,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    return onClose$1(undefined);
                                  })
                              }, "Cancel"), React.createElement("span", {
                                className: "mr-1"
                              }), React.createElement(SaveDeKitButton$Astrolabe.make, {
                                experimentId: experimentId,
                                kit: state.kit,
                                onClick: (function (param) {
                                    return Curry._1(setErrors, (function (param) {
                                                  return [];
                                                }));
                                  }),
                                onCompleted: (function (data) {
                                    var match = data.errors.length;
                                    if (match !== 0) {
                                      return Curry._1(setErrors, (function (param) {
                                                    return data.errors;
                                                  }));
                                    } else {
                                      return onClose$1(undefined);
                                    }
                                  })
                              }))))
            });
}

var $$Option;

var Statistic;

var make = AddDeKitModal;

exports.$$Option = $$Option;
exports.Statistic = Statistic;
exports.str = str;
exports.getFeatureId = getFeatureId;
exports.onChange = onChange;
exports.featureUtilHtmlOptions = featureUtilHtmlOptions;
exports.channelUsageUtilHtmlOptions = channelUsageUtilHtmlOptions;
exports.featureHtmlOptions = featureHtmlOptions;
exports.make = make;
/* react Not a pure module */
