/* @flow */

import React from "react";
import type { History } from "react-router";
import S3Upload from "react-s3-uploader-multipart/s3upload";
import SparkMD5 from "spark-md5";
import { sha256 } from "js-sha256";
import { List } from "immutable";
import type { Api } from "./ExperimentProvider";
import UploadRow from "components/samples/UploadRow";
import ExperimentHeader from "../header/ExperimentHeader.bs";
import DropZone from "../shared/DropZone";
import TitleWithIcon from "../header/TitleWithIcon.bs";
import { tutorialUrl } from "../../utils/Constants.bs";
import Icons from "../Icons.bs";
import { Experiment, Sample } from "models";
import FileUpload from "models/fileUpload";
import { fetchJSON } from "components/utils";
import { scrubFilename } from "utils/string";

type FileWithIndex = File & { __index: number };

type Props = {
  api: Api,
  errors: List<string>,
  experiment: Experiment,
  history: History,
  samples: List<Sample>,
  session: {
    instance: {
      id: number,
    },
  },
};

type State = {
  uploads: List<FileUpload>,
  canceledUploads: List<FileUpload>,
};

export default class UploadSamples extends React.Component<Props, State> {
  handleDefineFeaturesClick: () => any;
  onUploadStart: () => any;
  onUploadProgress: () => any;
  onUploadError: () => any;
  onUploadFinish: () => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      canceledUploads: new Array(),
      uploads: new List(),
    };

    this.fileInputRef = React.createRef();
  }

  async createSample(upload: FileUpload) {
    const json = await fetchJSON("POST", `/experiments/${this.props.experiment.get("id")}/samples.json`, {
      sample: {
        experiment_id: this.props.experiment.get("id"),
        file_file_name: upload.filename,
        file_content_type: upload.file.type,
        file_file_size: upload.file.size,
      },
    });

    if (json.errors) {
      this.updateUploadProps(upload.index, { errors: json.errors });
    } else {
      this.updateUploadProps(upload.index, { sample: json.sample });
    }
  }

  preprocessExperiment() {
    this.props.api.experiments.preprocess(this.props.experiment);
    /*refresh page to update get graphql experiment query to know change of status*/
    window.location.reload();
  }

  handleDefineFeaturesClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.preprocessExperiment();
  };

  handleFileChange = (e) => {
    this.handleFileUpload(e.target.files);
  };

  handleFileUpload = (files) => {
    var filesArray = [];
    for (var i = 0; i < files.length; i++) {
      filesArray.push(files[i]);
    }

    const uploads = filesArray.map((file, i) => {
      return new FileUpload({
        file,
        uploader: this.uploader(file),
        index: this.state.uploads.size + i,
        status: "added",
      });
    });

    this.setState({ uploads: this.state.uploads.concat(uploads) });
  };

  handleSampleDelete = (upload: FileUpload) => {
    const uploadInState = this.state.uploads.get(upload.index);
    // Cannot delete the upload from props or samples since this will create errors if there are other files uploading.
    // copyWith in updateUploadProps fails if something was removed from state during an upload.
    // so just keep track of canceled uploads in their own state for enabling processing button
    this.setState({ canceledUploads: [...this.state.canceledUploads, upload] });
  };

  onUploadStart = (file: FileWithIndex, next: (FileWithIndex) => any) => {
    setTimeout(() => next(file), 0);
  };

  onUploadProgress = (progress: number, status: string, file: FileWithIndex) => {
    this.updateUploadProps(file.__index, { progress: progress * 100, status });
  };

  onUploadError = (errorMessage: string, file: FileWithIndex) => {
    this.updateUploadProps(file.__index, { errors: [errorMessage] });
  };

  onUploadFinish = (signResult: { signedUrl: string, publicUrl: string }, file: FileWithIndex) => {
    this.updateUploadProps(file.__index, { status: "finished", publicUrl: signResult.publicUrl }, () => {
      this.createSample(this.state.uploads.get(file.__index));
    });
  };

  onUploadClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  updateUploadProps(index: number, props: Object, callback?: () => any) {
    this.setState((state) => {
      return { uploads: state.uploads.set(index, state.uploads.get(index).copyWith(props)) };
    }, callback);
  }

  uploader(file: File): S3Upload {
    const signingUrl = `/experiments/${this.props.experiment.get("id")}/sample_upload_signature`;

    return new S3Upload({
      files: [file],
      /* react-s3-uploader-multipart no longer appends object name and content type, so we do it ourselves */
      signingUrl: signingUrl, // + `?objectName=${scrubFilename(file.name)}&contentType=${encodeURIComponent(file.type)}`,
      s3Path: `instances/${this.props.session.instance.id}/experiments/${this.props.experiment.get("id")}/samples/`,
      signingUrlMethod: "GET",
      accept: "*/*",
      preprocess: this.onUploadStart,
      onProgress: this.onUploadProgress,
      onError: this.onUploadError,
      onFinishS3Put: this.onUploadFinish,
      signingUrlWithCredentials: true, // in case when need to pass authentication credentials via CORS
      uploadRequestHeaders: {},
      contentDisposition: "auto",
      scrubFilename: scrubFilename,
      evaporateOptions: {
        aws_key: window.__astrolabe.s3.access_key_id,
        awsRegion: window.__astrolabe.s3.region,
        bucket: window.__astrolabe.s3.bucket,
        computeContentMd5: true,
        cryptoMd5Method: (data) => btoa(SparkMD5.ArrayBuffer.hash(data, true)),
        cryptoHexEncodedHash256: sha256,
        partSize: 100 * 1024 * 1024, // 100 MB parts. EvaporateJS defaults to 5 MB, we're going higher to save on PUT requests.
        logging: window.__astrolabe.enableS3UploadLogging,
      },
    });
  }

  render() {
    const experimentIsPreprocessing = this.props.experiment.get("status") == "preprocessing";

    const sampleRows = this.props.samples.map((sample, i) => {
      const upload = new FileUpload({
        sample,
        index: i,
        progress: 100,
        status: "finished",
        file: { name: sample.get("file_file_name"), size: sample.get("file_file_size") },
      });
      return (
        <UploadRow
          key={i + sample.get("file_file_name")}
          upload={upload}
          experimentId={this.props.experiment.get("id")}
          experimentIsPreprocessing={experimentIsPreprocessing}
          onDelete={this.handleSampleDelete}
        />
      );
    });

    const uploadRows = this.state.uploads.map((upload, i) => {
      return (
        <UploadRow
          key={i}
          upload={upload}
          experimentIsPreprocessing={experimentIsPreprocessing}
          onDelete={this.handleSampleDelete}
        />
      );
    });

    // if there have been uploads make sure all are finished or have been aborted by the user (which are stuck at status uploading, but will disappear on a refresh)
    const allUploadingFilesComplete = this.state.uploads.every(
      (u) => u.status === "finished" || (u.errors && u.errors.includes("User aborted the upload")),
    );

    // Check for finished uploads, and ignore uploads that were duplicates since those will not end up in the state.canceledUploads
    const completedUploads = this.state.uploads.filter(
      (u) => u.status === "finished" && !(u.errors && u.errors.includes("Name has already been taken")),
    );

    // Cannot refresh state while uploading in case there are uploading files in progress duplicating the current upload props in state.
    // Keep track of cancels with their own state and compare size to uploads + samples
    const moreUploadsAndSamplesThanCanceled = this.props.samples.size + completedUploads.size > this.state.canceledUploads.length;

    const canProcess = allUploadingFilesComplete && moreUploadsAndSamplesThanCanceled;

    const UploadIcon = Icons.toJs(Icons.Upload);

    let uploadSampleButtons = (
      <React.Fragment>
        <input
          className="d-none"
          // add unique key so can use the button more than once in a row
          key={Date.now()}
          type="file"
          name="sample"
          onChange={this.handleFileChange}
          multiple={true}
          ref={this.fileInputRef}
        />
        <button
          type_="button"
          className="btn btn-block astrolabe-bg-light-blue text-white"
          style={{ width: "350px" }}
          onClick={this.onUploadClick}
          title="Choose files from your computer to upload"
        >
          Add Samples
        </button>
        {canProcess && (
          <button
            type_="button"
            className="btn btn-success btn-block astrolabe-bg-green"
            style={{ width: "350px" }}
            title="Process the samples. Astrolabe will verify the files are working and import channel names. This action cannot be undone."
            onClick={this.handleDefineFeaturesClick}
          >
            Process Samples & Continue Setup
          </button>
        )}
      </React.Fragment>
    );

    let preprocessingButtons = (
      <div className="progress rounded" style={{ height: "36px", fontSize: "1rem", lineHeight: "36px", width: "350px" }}>
        <div className="position-absolute" style={{ width: "350px" }}>
          <div className="text-center">Processing...</div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <ExperimentHeader experimentId={this.props.experiment.get("id")} />
        <div className="d-flex justify-content-between">
          <TitleWithIcon
            className="margin-new-title"
            subtitle={
              <span>
                Please upload all of the FCS files required for the experiment. Please refer to the
                <a href={tutorialUrl} target="_blank">
                  {" "}
                  Experiment Setup tutorial{" "}
                </a>
                for more information.
              </span>
            }
            title="Upload Samples"
          >
            <UploadIcon height="60" width="60" className="no-hover" />
          </TitleWithIcon>
          <div className="ml-3 pl-3" style={{ width: "400px", marginTop: "40px" }}>
            {experimentIsPreprocessing ? preprocessingButtons : uploadSampleButtons}
          </div>
        </div>

        {sampleRows}
        {uploadRows}

        <hr className="invisible" />
        {!experimentIsPreprocessing && (
          <DropZone
            handleFileChange={this.handleFileChange}
            handleFileUpload={this.handleFileUpload}
            text="To upload samples, click the button or drag and drop files into this area"
          />
        )}
      </React.Fragment>
    );
  }
}
