// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var Channel$Astrolabe = require("../../models/Channel.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var TitleWithIcon$Astrolabe = require("../header/TitleWithIcon.bs.js");
var LiveDeadSelect$Astrolabe = require("./LiveDeadSelect.bs.js");
var OrganismSelect$Astrolabe = require("./OrganismSelect.bs.js");
var InstrumentSelect$Astrolabe = require("./InstrumentSelect.bs.js");
var TissueTypeSelect$Astrolabe = require("./TissueTypeSelect.bs.js");
var CustomLabelingHierarchy$Astrolabe = require("../../models/CustomLabelingHierarchy.bs.js");
var CustomLabelingHierarchySelect$Astrolabe = require("./CustomLabelingHierarchySelect.bs.js");

function ExperimentSettings(Props) {
  var clearExperimentErrors = Props.clearExperimentErrors;
  var experimentId = Props.experimentId;
  var experimentData = Props.experimentData;
  var channelsForSelection = Experiment$Astrolabe.Js.channelsForLiveDeadSelection(experimentData);
  var instrument = Experiment$Astrolabe.Js.instrument(experimentData);
  var isEditable = Experiment$Astrolabe.Js.isEditable(experimentData) && !Experiment$Astrolabe.Js.hasBeenAnalyzed(experimentData);
  var organism = Experiment$Astrolabe.Js.organism(experimentData);
  var tissueType = Experiment$Astrolabe.Js.tissueType(experimentData);
  var liveDead = Experiment$Astrolabe.Js.liveDeadChannel(experimentData);
  var liveDeadChannelName = liveDead !== undefined ? Channel$Astrolabe.Js.name(Caml_option.valFromOption(liveDead)) : "None - don't remove dead cells";
  var channel = experimentData.liveDeadChannel;
  var liveDeadId = channel !== undefined ? Caml_option.valFromOption(channel).id : "0";
  var clh = experimentData.customLabelingHierarchy;
  var match;
  if (clh !== undefined) {
    var clh$1 = Caml_option.valFromOption(clh);
    match = /* tuple */[
      true,
      CustomLabelingHierarchy$Astrolabe.id(clh$1),
      CustomLabelingHierarchy$Astrolabe.name(clh$1)
    ];
  } else {
    match = /* tuple */[
      false,
      "-1",
      "Default"
    ];
  }
  var settingsSubtitle = isEditable ? "Set the relevant experiment settings. You will not be able to change these once the analysis begins." : "Relevant experiment settings. These cannot be changed since the analysis is done.";
  var row = function (labelText, valueClassNameOpt, value) {
    var valueClassName = valueClassNameOpt !== undefined ? valueClassNameOpt : "col-8 d-flex flex-column justify-content-center";
    return React.createElement("tr", {
                className: "row",
                style: {
                  height: "56px"
                }
              }, React.createElement("td", {
                    className: "col-4 d-flex flex-column justify-content-center border-right"
                  }, labelText), React.createElement("td", {
                    className: valueClassName
                  }, value));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "d-flex justify-content-between"
                }, React.createElement(TitleWithIcon$Astrolabe.make, {
                      className: "margin-new-title",
                      subtitle: settingsSubtitle,
                      title: "Experiment Settings",
                      children: React.createElement(Icons$Astrolabe.Gear.make, {
                            className: "no-hover",
                            height: "60",
                            width: "60"
                          })
                    })), React.createElement("table", {
                  className: "table table-striped"
                }, React.createElement("tbody", undefined, row("Experiment ID", undefined, experimentId), row("Created By", undefined, Experiment$Astrolabe.Js.createUserEmail(experimentData)), row("Created Date", undefined, new Date(Experiment$Astrolabe.Js.createdAt(experimentData)).toDateString()), row("Which instrument was used for acquiring the data?", undefined, isEditable ? React.createElement(InstrumentSelect$Astrolabe.make, {
                                experimentId: experimentId,
                                experimentInstrument: instrument
                              }) : Experiment$Astrolabe.Instrument.toString(instrument)), row("Which channel includes Live/Dead staining?", undefined, isEditable ? React.createElement(LiveDeadSelect$Astrolabe.make, {
                                experimentId: experimentId,
                                channelsForSelection: channelsForSelection,
                                experimentLiveDeadId: liveDeadId
                              }) : liveDeadChannelName), row("What is the tissue type acquired?", undefined, isEditable ? React.createElement(TissueTypeSelect$Astrolabe.make, {
                                experimentId: experimentId,
                                experimentTissueType: tissueType
                              }) : Experiment$Astrolabe.TissueType.toString(Experiment$Astrolabe.Js.tissueType(experimentData))), row("Which organism was acquired in this experiment?", "col-8 d-flex flex-column justify-content-center", isEditable ? React.createElement(OrganismSelect$Astrolabe.make, {
                                experimentId: experimentId,
                                experimentOrganism: organism,
                                disabled: match[0]
                              }) : Experiment$Astrolabe.Organism.toString(Experiment$Astrolabe.Js.organism(experimentData))), row("Which labeling hierarchy should be used?", undefined, isEditable ? React.createElement(CustomLabelingHierarchySelect$Astrolabe.make, {
                                clearExperimentErrors: clearExperimentErrors,
                                experimentId: experimentId,
                                experimentCustomLabelingHierarchyId: match[1]
                              }) : match[2]))));
}

var Instrument;

var Organism;

var TissueType;

var Experiment;

var make = ExperimentSettings;

var $$default = ExperimentSettings;

exports.Instrument = Instrument;
exports.Organism = Organism;
exports.TissueType = TissueType;
exports.Experiment = Experiment;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
