// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var ReactRouterDom = require("react-router-dom");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var NavTitle$Astrolabe = require("../NavTitle.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var ReactRouter$Astrolabe = require("../../utils/ReactRouter.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function ExperimentTabs(Props) {
  var experimentStatus = Props.experimentStatus;
  var experimentId = Props.experimentId;
  var experimentName = Props.experimentName;
  var hasDebarcodingKits = Props.hasDebarcodingKits;
  var renderTab = function (tab) {
    var className = tab.isSelected ? "btn btn-link astrolabe-text-blue bg-white p-0 no-underline-on-hover text-decoration-none p-3 rounded-0" : "btn btn-link p-0 no-underline-on-hover text-decoration-none";
    var textColor = tab.isSelected ? "astrolabe-text-blue" : "text-white";
    if (tab.isDisabled) {
      return React.createElement("button", {
                  key: tab.text,
                  className: className,
                  disabled: tab.isDisabled
                }, React.createElement("span", {
                      className: textColor
                    }, tab.text));
    } else {
      return React.createElement("span", {
                  key: tab.text
                }, React.createElement(ReactRouterDom.NavLink, ReactRouter$Astrolabe.NavLink.makeProps(Constants$Astrolabe.experimentIdPath(experimentId) + tab.path, undefined, undefined, undefined, className, true, Caml_option.some(React.createElement("span", {
                                  className: textColor
                                }, tab.text)), undefined)));
    }
  };
  var isUploading = experimentStatus === /* CREATED */289311176 || experimentStatus === /* PREPROCESSING */-525726826;
  var isAnalyzing = experimentStatus === /* ANALYZING */-384073879 || experimentStatus === /* DEBARCODING */1020136956;
  var isDebarcoding = experimentStatus === /* ADDING_DEBARCODING_KITS */889637598 || experimentStatus === /* MAPPING_BARCODING_CHANNELS */-408660381;
  var isDone = experimentStatus === /* DONE */758036610;
  var isSetup = experimentStatus === /* PENDING_ANALYSIS */175814564;
  var path = ReasonReactRouter.useUrl(undefined, undefined).path;
  var match;
  var exit = 0;
  var exit$1 = 0;
  if (path) {
    switch (path[0]) {
      case "experiments" :
          var match$1 = path[1];
          if (match$1) {
            var match$2 = match$1[1];
            if (match$2) {
              var exit$2 = 0;
              switch (match$2[0]) {
                case "analyses" :
                    match = match$2[1] ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "analyses",
                        "Analyses"
                      ];
                    break;
                case "cell_subset_navigator" :
                    match = match$2[1] ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "cell_subset_navigator",
                        "Cell Subset Navigator"
                      ];
                    break;
                case "channels" :
                    match = match$2[1] ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "channels",
                        "Channels"
                      ];
                    break;
                case "debarcoding" :
                    match = match$2[1] || !hasDebarcodingKits ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "debarcoding",
                        "Debarcoding Kits"
                      ];
                    break;
                case "quality_control" :
                    match = match$2[1] ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "quality_control",
                        "Quality Control"
                      ];
                    break;
                case "settings" :
                    match = match$2[1] || isDebarcoding ? /* tuple */[
                        "",
                        ""
                      ] : /* tuple */[
                        "settings",
                        isDone ? "Experiment Settings" : "Experiment Setup"
                      ];
                    break;
                case "features" :
                case "summary" :
                    exit$2 = 3;
                    break;
                default:
                  match = /* tuple */[
                    "",
                    ""
                  ];
              }
              if (exit$2 === 3) {
                match = match$2[1] ? /* tuple */[
                    "",
                    ""
                  ] : /* tuple */[
                    "features",
                    isAnalyzing ? "Features" : "Experiment Summary"
                  ];
              }
              
            } else if (isDebarcoding) {
              match = /* tuple */[
                "debarcoding",
                "Debarcoding Kits"
              ];
            } else {
              exit$1 = 2;
            }
          } else {
            match = /* tuple */[
              "",
              ""
            ];
          }
          break;
      case "public" :
          var match$3 = path[1];
          if (match$3 && match$3[0] === "experiments") {
            var match$4 = match$3[1];
            if (match$4) {
              var match$5 = match$4[1];
              if (match$5) {
                var exit$3 = 0;
                switch (match$5[0]) {
                  case "analyses" :
                      match = match$5[1] ? /* tuple */[
                          "",
                          ""
                        ] : /* tuple */[
                          "analyses",
                          "Analyses"
                        ];
                      break;
                  case "cell_subset_navigator" :
                      match = match$5[1] ? /* tuple */[
                          "",
                          ""
                        ] : /* tuple */[
                          "cell_subset_navigator",
                          "Cell Subset Navigator"
                        ];
                      break;
                  case "channels" :
                      match = match$5[1] ? /* tuple */[
                          "",
                          ""
                        ] : /* tuple */[
                          "channels",
                          "Channels"
                        ];
                      break;
                  case "debarcoding" :
                      match = match$5[1] || !hasDebarcodingKits ? /* tuple */[
                          "",
                          ""
                        ] : /* tuple */[
                          "debarcoding",
                          "Debarcoding Kits"
                        ];
                      break;
                  case "quality_control" :
                      match = match$5[1] ? /* tuple */[
                          "",
                          ""
                        ] : /* tuple */[
                          "quality_control",
                          "Quality Control"
                        ];
                      break;
                  case "features" :
                  case "summary" :
                      exit$3 = 3;
                      break;
                  default:
                    match = /* tuple */[
                      "",
                      ""
                    ];
                }
                if (exit$3 === 3) {
                  match = match$5[1] ? /* tuple */[
                      "",
                      ""
                    ] : /* tuple */[
                      "features",
                      isAnalyzing ? "Features" : "Experiment Summary"
                    ];
                }
                
              } else {
                exit$1 = 2;
              }
            } else {
              match = /* tuple */[
                "",
                ""
              ];
            }
          } else {
            match = /* tuple */[
              "",
              ""
            ];
          }
          break;
      default:
        exit = 1;
    }
  } else {
    match = /* tuple */[
      "",
      ""
    ];
  }
  if (exit$1 === 2) {
    if (isAnalyzing || isDone) {
      match = /* tuple */[
        "features",
        isAnalyzing ? "features" : "Experiment Summary"
      ];
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    if (path[0] === "experiments") {
      var match$6 = path[1];
      match = match$6 && !match$6[1] ? (
          isSetup ? /* tuple */[
              "settings",
              "Experiment Setup"
            ] : (
              isUploading ? /* tuple */[
                  "",
                  "Upload"
                ] : /* tuple */[
                  "",
                  ""
                ]
            )
        ) : /* tuple */[
          "",
          ""
        ];
    } else {
      match = /* tuple */[
        "",
        ""
      ];
    }
  }
  var currentPath = match[0];
  var navTitle = isUploading || isSetup ? "Experiment Setup" : match[1];
  var setupTabs = [
    {
      text: "Upload",
      path: "/",
      isDisabled: !isUploading,
      isSelected: isUploading
    },
    {
      text: "Debarcoding",
      path: isDebarcoding ? "/" : "/debarcoding",
      isDisabled: isUploading || isAnalyzing && !hasDebarcodingKits || isSetup && !hasDebarcodingKits,
      isSelected: isDebarcoding || currentPath === "debarcoding"
    },
    {
      text: "Settings",
      path: "/settings",
      isDisabled: isUploading || isDebarcoding,
      isSelected: currentPath === "settings"
    },
    {
      text: "Channels",
      path: "/channels",
      isDisabled: isUploading || isDebarcoding,
      isSelected: currentPath === "channels"
    },
    {
      text: "Features",
      path: "/features",
      isDisabled: isUploading || isDebarcoding,
      isSelected: currentPath === "features"
    },
    {
      text: "Analyses",
      path: "/analyses",
      isDisabled: isAnalyzing || isUploading || isDebarcoding,
      isSelected: currentPath === "analyses"
    }
  ];
  var completedTabs = [
    {
      text: "Settings",
      path: "/settings",
      isDisabled: false,
      isSelected: currentPath === "settings"
    },
    {
      text: "Summary",
      path: "/summary",
      isDisabled: false,
      isSelected: currentPath === "features"
    },
    {
      text: "Analyses",
      path: "/analyses",
      isDisabled: false,
      isSelected: currentPath === "analyses"
    },
    {
      text: "Cell Subset Navigator",
      path: "/cell_subset_navigator",
      isDisabled: false,
      isSelected: currentPath === "cell_subset_navigator"
    },
    {
      text: "Quality Control",
      path: "/quality_control",
      isDisabled: false,
      isSelected: currentPath === "quality_control"
    },
    {
      text: "Channels",
      path: "/channels",
      isDisabled: false,
      isSelected: currentPath === "channels"
    }
  ];
  var debarcodingKitsTab = {
    text: "Debarcoding",
    path: "/debarcoding",
    isDisabled: false,
    isSelected: currentPath === "debarcoding"
  };
  var completedTabsWithDebarcoding = function (param) {
    completedTabs.splice(4, 0, debarcodingKitsTab);
    return completedTabs;
  };
  var completedTabsWithDebarcodingOption = hasDebarcodingKits ? completedTabsWithDebarcoding(undefined) : completedTabs;
  var renderNavTitle = navTitle === "" ? null : (
      isAnalyzing || isDone ? React.createElement(NavTitle$Astrolabe.make, {
              children: null
            }, React.createElement("a", {
                  href: Constants$Astrolabe.experimentIdPath(experimentId)
                }, experimentName), React.createElement("span", {
                  className: "navbar-text"
                }, ReasonReactHelpers$Astrolabe.nbsp, "> " + Curry._1(Util$Astrolabe.$$String.capitalize, navTitle))) : React.createElement(NavTitle$Astrolabe.TextAndDocumentTitle.make, NavTitle$Astrolabe.TextAndDocumentTitle.makeProps(navTitle, undefined, undefined))
    );
  return React.createElement(React.Fragment, undefined, renderNavTitle, React.createElement("div", {
                  className: "children-mr-4"
                }, isDone ? completedTabsWithDebarcodingOption.map(renderTab) : setupTabs.map(renderTab)));
}

var $$Array;

var $$String;

var make = ExperimentTabs;

exports.$$Array = $$Array;
exports.$$String = $$String;
exports.make = make;
/* react Not a pure module */
