/* @flow */
"use strict";

import React from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import type { ScatterPlotData, ScatterPlotProps } from "models";
import theme from "models/victoryTheme";

type State = {
  activatedLabel: string,
};

export default class VolcanoScatterPlot extends React.PureComponent<ScatterPlotProps, State> {
  constructor(props: ScatterPlotProps) {
    super(props);

    this.state = {
      activatedLabel: "",
    };
  }

  handleDataClick() {
    if (this.props.onDataClick) {
      this.props.onDataClick(this.state.activatedLabel);
    }
  }

  render() {
    const xValsAbs = [].concat.apply([], this.props.data.map((arr) => arr.map((row) => Math.abs(row.x))));
    const yVals = [].concat.apply([], this.props.data.map((arr) => arr.map((row) => row.y)));
    const xLim = Math.max.apply([], xValsAbs) * 1.2;
    const yLim = Math.max(2 / 1.2, Math.max.apply([], yVals)) * 1.2;

    const domain = {
      x: [-xLim, xLim],
      y: [0, yLim],
    };

    return (
      <VictoryChart
        theme={theme}
        width={this.props.width}
        height={this.props.height}
        padding={{ left: 0, top: 20 }}
        containerComponent={
          <VictoryVoronoiContainer
            height={this.props.height + 50}
            responsive={false}
            onActivated={(points, props) => this.setState({ activatedLabel: points[0].name })}
            events={{
              onClick: () => this.handleDataClick(),
            }}
          />
        }
      >
        <VictoryAxis label={this.props.xAxisLabel} domain={domain.x} />
        <VictoryAxis crossAxis dependentAxis label={this.props.yAxisLabel} domain={domain.y} />

        <VictoryGroup colorScale="qualitative">
          {this.props.data.map((datum, i) => {
            return (
              <VictoryScatter
                key={i}
                data={datum}
                domain={domain}
                size={6}
                style={{ data: { fillOpacity: 1 } }}
                labels={(datum) => datum.label}
                labelComponent={<VictoryTooltip {...theme.tooltip} />}
              />
            );
          })}
        </VictoryGroup>

        {this.props.legendLabels && this.props.legendLabels.length > 1 ? (
          <VictoryLegend
            x={20}
            y={10}
            itemsPerRow={6}
            data={this.props.legendLabels.map((name) => {
              return { name };
            })}
          />
        ) : null}
      </VictoryChart>
    );
  }
}
