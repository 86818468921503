// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var Constants$Astrolabe = require("../utils/Constants.bs.js");
var GraphQLHelpers$Astrolabe = require("../utils/GraphQLHelpers.bs.js");
var ReasonReactHelpers$Astrolabe = require("../utils/ReasonReactHelpers.bs.js");

var ppx_printed_query = "mutation DestroyExperimentPipelines($experimentId: ID!, $dagType: String!)  {\ndestroyExperimentPipelines(id: $experimentId, dagType: $dagType)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyExperimentPipelines");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyExperimentPipelines: tmp
        };
}

function make(experimentId, dagType, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "dagType",
                    dagType
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var dagType = variables.dagType;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "dagType",
                    dagType
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, dagType, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "dagType",
                  dagType
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, dagType, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "dagType",
                      dagType
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var DestroyPipelinesConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var DestroyPipelinesMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

var ppx_printed_query$1 = "mutation UpdateExperiment($experimentId: ID!, $customLabelingHierarchyId: ID, $flags: String, $instrument: Instrument, $isArchived: Boolean, $isShowAnalysisColumn: Boolean, $name: String, $organism: Organism, $status: Status, $tissueType: Tissue)  {\nupdateExperiment(id: $experimentId, customLabelingHierarchyId: $customLabelingHierarchyId, flags: $flags, instrument: $instrument, isArchived: $isArchived, isShowAnalysisColumn: $isShowAnalysisColumn, name: $name, organism: $organism, status: $status, tissueType: $tissueType)  {\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateExperiment: tmp
        };
}

function json_of_Instrument(value) {
  if (value >= 615611296) {
    if (value >= 947968673) {
      return "FLOW_CYTOMETRY";
    } else {
      return "AURORA";
    }
  } else if (value >= 498013768) {
    return "CITESEQ";
  } else {
    return "MASS_CYTOMETRY";
  }
}

function json_of_Organism(value) {
  if (value !== 173231621) {
    if (value >= 759401869) {
      return "HUMAN";
    } else {
      return "PROFILING_ONLY";
    }
  } else {
    return "MOUSE";
  }
}

function json_of_Status(value) {
  if (value >= 289311176) {
    if (value >= 889637598) {
      if (value >= 1020136956) {
        return "DEBARCODING";
      } else {
        return "ADDING_DEBARCODING_KITS";
      }
    } else if (value >= 758036610) {
      return "DONE";
    } else {
      return "CREATED";
    }
  } else if (value >= -384073879) {
    if (value >= 175814564) {
      return "PENDING_ANALYSIS";
    } else {
      return "ANALYZING";
    }
  } else if (value >= -408660381) {
    return "MAPPING_BARCODING_CHANNELS";
  } else {
    return "PREPROCESSING";
  }
}

function json_of_Tissue(value) {
  if (value >= 17459) {
    if (value >= 890464712) {
      return "PBMC";
    } else {
      return "NA";
    }
  } else if (value >= -270592759) {
    return "MIXED_TISSUE";
  } else {
    return "WHOLE_BLOOD";
  }
}

function make$1(experimentId, customLabelingHierarchyId, flags, instrument, isArchived, isShowAnalysisColumn, name, organism, status, tissueType, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "customLabelingHierarchyId",
                    customLabelingHierarchyId !== undefined ? customLabelingHierarchyId : null
                  ],
                  /* tuple */[
                    "flags",
                    flags !== undefined ? flags : null
                  ],
                  /* tuple */[
                    "instrument",
                    instrument !== undefined ? json_of_Instrument(instrument) : null
                  ],
                  /* tuple */[
                    "isArchived",
                    isArchived !== undefined ? isArchived : null
                  ],
                  /* tuple */[
                    "isShowAnalysisColumn",
                    isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ],
                  /* tuple */[
                    "organism",
                    organism !== undefined ? json_of_Organism(organism) : null
                  ],
                  /* tuple */[
                    "status",
                    status !== undefined ? json_of_Status(status) : null
                  ],
                  /* tuple */[
                    "tissueType",
                    tissueType !== undefined ? json_of_Tissue(tissueType) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var experimentId = variables.experimentId;
  var customLabelingHierarchyId = variables.customLabelingHierarchyId;
  var flags = variables.flags;
  var instrument = variables.instrument;
  var isArchived = variables.isArchived;
  var isShowAnalysisColumn = variables.isShowAnalysisColumn;
  var name = variables.name;
  var organism = variables.organism;
  var status = variables.status;
  var tissueType = variables.tissueType;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "customLabelingHierarchyId",
                    customLabelingHierarchyId !== undefined ? customLabelingHierarchyId : null
                  ],
                  /* tuple */[
                    "flags",
                    flags !== undefined ? flags : null
                  ],
                  /* tuple */[
                    "instrument",
                    instrument !== undefined ? json_of_Instrument(instrument) : null
                  ],
                  /* tuple */[
                    "isArchived",
                    isArchived !== undefined ? isArchived : null
                  ],
                  /* tuple */[
                    "isShowAnalysisColumn",
                    isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ],
                  /* tuple */[
                    "organism",
                    organism !== undefined ? json_of_Organism(organism) : null
                  ],
                  /* tuple */[
                    "status",
                    status !== undefined ? json_of_Status(status) : null
                  ],
                  /* tuple */[
                    "tissueType",
                    tissueType !== undefined ? json_of_Tissue(tissueType) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(experimentId, customLabelingHierarchyId, flags, instrument, isArchived, isShowAnalysisColumn, name, organism, status, tissueType, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "customLabelingHierarchyId",
                  customLabelingHierarchyId !== undefined ? customLabelingHierarchyId : null
                ],
                /* tuple */[
                  "flags",
                  flags !== undefined ? flags : null
                ],
                /* tuple */[
                  "instrument",
                  instrument !== undefined ? json_of_Instrument(instrument) : null
                ],
                /* tuple */[
                  "isArchived",
                  isArchived !== undefined ? isArchived : null
                ],
                /* tuple */[
                  "isShowAnalysisColumn",
                  isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                ],
                /* tuple */[
                  "name",
                  name !== undefined ? name : null
                ],
                /* tuple */[
                  "organism",
                  organism !== undefined ? json_of_Organism(organism) : null
                ],
                /* tuple */[
                  "status",
                  status !== undefined ? json_of_Status(status) : null
                ],
                /* tuple */[
                  "tissueType",
                  tissueType !== undefined ? json_of_Tissue(tissueType) : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, experimentId, customLabelingHierarchyId, flags, instrument, isArchived, isShowAnalysisColumn, name, organism, status, tissueType, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "customLabelingHierarchyId",
                      customLabelingHierarchyId !== undefined ? customLabelingHierarchyId : null
                    ],
                    /* tuple */[
                      "flags",
                      flags !== undefined ? flags : null
                    ],
                    /* tuple */[
                      "instrument",
                      instrument !== undefined ? json_of_Instrument(instrument) : null
                    ],
                    /* tuple */[
                      "isArchived",
                      isArchived !== undefined ? isArchived : null
                    ],
                    /* tuple */[
                      "isShowAnalysisColumn",
                      isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                    ],
                    /* tuple */[
                      "name",
                      name !== undefined ? name : null
                    ],
                    /* tuple */[
                      "organism",
                      organism !== undefined ? json_of_Organism(organism) : null
                    ],
                    /* tuple */[
                      "status",
                      status !== undefined ? json_of_Status(status) : null
                    ],
                    /* tuple */[
                      "tissueType",
                      tissueType !== undefined ? json_of_Tissue(tissueType) : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var UpdateConfig = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_Instrument: json_of_Instrument,
  json_of_Organism: json_of_Organism,
  json_of_Status: json_of_Status,
  json_of_Tissue: json_of_Tissue,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var UpdateMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

var ppx_printed_query$2 = "mutation UpdateExperimentEvent($experimentId: ID!, $event: String!)  {\nupdateExperimentEvent(id: $experimentId, event: $event)  {\nerrors  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateExperimentEvent");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateExperimentEvent: tmp
        };
}

function make$2(experimentId, $$event, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "event",
                    $$event
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var experimentId = variables.experimentId;
  var $$event = variables.event;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "event",
                    $$event
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$2
        };
}

function makeVariables$2(experimentId, $$event, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "event",
                  $$event
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$2(graphql_ppx_use_json_variables_fn, experimentId, $$event, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "event",
                      $$event
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$2 = /* tuple */[
  parse$2,
  ppx_printed_query$2,
  definition_002$2
];

function ret_type$2(f) {
  return { };
}

var MT_Ret$2 = { };

var UpdateEventConfig = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var UpdateEventMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$2,
      parse: parse$2
    });

function updateEventAndRedirectToExpRoot(mutation, experimentId, $$event, handleErrorsOpt, param) {
  var handleErrors = handleErrorsOpt !== undefined ? handleErrorsOpt : (function (param) {
        
      });
  return GraphQLHelpers$Astrolabe.handleDataAndErrors((function (data) {
                var updateEvent = data.updateExperimentEvent;
                if (updateEvent === undefined) {
                  return ;
                }
                var updateEvent$1 = Caml_option.valFromOption(updateEvent);
                if (Util$Astrolabe.$$Array.isEmpty(updateEvent$1.errors)) {
                  return ReasonReactHelpers$Astrolabe.redirect(Constants$Astrolabe.experimentIdPath(experimentId));
                } else {
                  return Curry._1(handleErrors, updateEvent$1.errors);
                }
              }), Curry._4(mutation, Caml_option.some(make$2(experimentId, $$event, undefined).variables), undefined, undefined, undefined));
}

exports.DestroyPipelinesConfig = DestroyPipelinesConfig;
exports.DestroyPipelinesMutation = DestroyPipelinesMutation;
exports.UpdateConfig = UpdateConfig;
exports.UpdateMutation = UpdateMutation;
exports.UpdateEventConfig = UpdateEventConfig;
exports.UpdateEventMutation = UpdateEventMutation;
exports.updateEventAndRedirectToExpRoot = updateEventAndRedirectToExpRoot;
/* DestroyPipelinesMutation Not a pure module */
