/* @flow */
import React from "react";
import { List } from "immutable";
import { Route, NavLink } from "react-router-dom";
import withExperiment from "components/WithExperiment";
import SampleQualityControlTable from "components/samples/SampleQualityControlTable.bs";
import AOFHeatMap from "./quality_control/AOFHeatMap";
import LabelingStrategies from "./quality_control/LabelingStrategies.bs";
import { Experiment, Sample, User } from "models";
import ExperimentHeader from "../header/ExperimentHeader.bs";
import TitleWithIcon from "../header/TitleWithIcon.bs";
import Icons from "../Icons.bs";

type Props = {
  experiment: Experiment,
  samples: List<Sample>,
};

function SampleMetrics(props: Props) {
  return (
    <SampleQualityControlTable
      experimentId={props.experiment.get("id")}
      samples={props.samples.toArray()}
      thresholds={props.experiment.get("qc_metric_thresholds") || {}}
    />
  );
}

function Dummy(props: Props) {
  return "Dummy";
}

const TABS = [
  { label: "Sample Metrics", path: "sample_metrics", component: SampleMetrics },
  { label: "AOF", path: "aof", component: AOFHeatMap },
  { label: "Labeling Strategies", path: "labeling_strategies", component: LabelingStrategies },
];

const experimentsPath = (isPublicExp) => (isPublicExp ? "/public/experiments" : "/experiments");

export class QualityControl extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  isPublicExp = window.location.pathname.startsWith("/public");
  expPath = experimentsPath(this.isPublicExp);

  renderTabs(props: Props) {
    return (
      <ul className="nav nav-pills justify-content-center mb-3">
        {TABS.map((tab, i) => {
          return (
            <li key={i} className="nav-item">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={`${this.expPath}/${props.experiment.id}/quality_control/${tab.path}`}
                isActive={(match, location) => {
                  if (match) {
                    return true;
                  } else if (i === 0) {
                    return location.pathname.match(new RegExp(`${this.expPath}/${props.experiment.id}/quality_control/?$`));
                  }
                }}
              >
                {tab.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    );
  }

  renderRoutes(props: Props) {
    return (
      <React.Fragment>
        {TABS.map((tab, i) => {
          let path = `${this.expPath}/:id/quality_control/${tab.path}`;

          if (i === 0) {
            path = `${this.expPath}/:id/quality_control/(${tab.path})?`;
          }

          return <Route key={i} render={() => React.createElement(tab.component, props)} exact path={path} />;
        })}
      </React.Fragment>
    );
  }

  render() {
    const QualityControlIcon = Icons.toJs(Icons.QualityControl);

    return (
      <React.Fragment>
        <ExperimentHeader experimentId={this.props.experiment.get("id")}>{this.props.renderTabs}</ExperimentHeader>
        <div className="d-flex justify-content-between align-items-center">
          <TitleWithIcon
            className="margin-new-title"
            subtitle="Review Astrolabe's quality control metrics for your experiment."
            title="Quality Control"
          >
            <QualityControlIcon height="60" width="60" className="no-hover" />
          </TitleWithIcon>
          {this.renderTabs(this.props)}
        </div>
        {this.renderRoutes(this.props)}
      </React.Fragment>
    );
  }
}

export default withExperiment(QualityControl);
