// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var ReactRouterDom = require("react-router-dom");
var Instance$Astrolabe = require("../../../models/Instance.bs.js");
var ReactRouter$Astrolabe = require("../../../utils/ReactRouter.bs.js");

var ppx_printed_query = "query   {\nstaff  {\ninstances  {\nid  \nname  \nallowedEmailDomain  \nbilledSampleCount  \ncontractEndDate  \ncontractStartDate  \nmaxSamples  \nsampleCount  \nuserCount  \n}\n\nsampleHistories  \nlastTwelveMonths  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "staff");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "instances");
    var value$5 = Js_dict.get(value$3, "sampleHistories");
    var value$6 = Js_dict.get(value$3, "lastTwelveMonths");
    tmp = {
      instances: value$4 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$4))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type Instance is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var match = Js_json.decodeNull(value$6);
                  if (match !== undefined) {
                    tmp$1 = undefined;
                  } else {
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  }
                } else {
                  tmp$1 = undefined;
                }
                var value$8 = Js_dict.get(value$1, "allowedEmailDomain");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var match$1 = Js_json.decodeNull(value$9);
                  if (match$1 !== undefined) {
                    tmp$2 = undefined;
                  } else {
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  }
                } else {
                  tmp$2 = undefined;
                }
                var value$11 = Js_dict.get(value$1, "billedSampleCount");
                var tmp$3;
                if (value$11 !== undefined) {
                  var value$12 = Caml_option.valFromOption(value$11);
                  var value$13 = Js_json.decodeNumber(value$12);
                  tmp$3 = value$13 !== undefined ? value$13 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$12));
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field billedSampleCount on type Instance is missing");
                }
                var value$14 = Js_dict.get(value$1, "contractEndDate");
                var tmp$4;
                if (value$14 !== undefined) {
                  var value$15 = Caml_option.valFromOption(value$14);
                  var match$2 = Js_json.decodeNull(value$15);
                  if (match$2 !== undefined) {
                    tmp$4 = undefined;
                  } else {
                    var value$16 = Js_json.decodeString(value$15);
                    tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                  }
                } else {
                  tmp$4 = undefined;
                }
                var value$17 = Js_dict.get(value$1, "contractStartDate");
                var tmp$5;
                if (value$17 !== undefined) {
                  var value$18 = Caml_option.valFromOption(value$17);
                  var match$3 = Js_json.decodeNull(value$18);
                  if (match$3 !== undefined) {
                    tmp$5 = undefined;
                  } else {
                    var value$19 = Js_json.decodeString(value$18);
                    tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
                  }
                } else {
                  tmp$5 = undefined;
                }
                var value$20 = Js_dict.get(value$1, "maxSamples");
                var tmp$6;
                if (value$20 !== undefined) {
                  var value$21 = Caml_option.valFromOption(value$20);
                  var match$4 = Js_json.decodeNull(value$21);
                  if (match$4 !== undefined) {
                    tmp$6 = undefined;
                  } else {
                    var value$22 = Js_json.decodeNumber(value$21);
                    tmp$6 = value$22 !== undefined ? value$22 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$21));
                  }
                } else {
                  tmp$6 = undefined;
                }
                var value$23 = Js_dict.get(value$1, "sampleCount");
                var tmp$7;
                if (value$23 !== undefined) {
                  var value$24 = Caml_option.valFromOption(value$23);
                  var value$25 = Js_json.decodeNumber(value$24);
                  tmp$7 = value$25 !== undefined ? value$25 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$24));
                } else {
                  tmp$7 = Js_exn.raiseError("graphql_ppx: Field sampleCount on type Instance is missing");
                }
                var value$26 = Js_dict.get(value$1, "userCount");
                var tmp$8;
                if (value$26 !== undefined) {
                  var value$27 = Caml_option.valFromOption(value$26);
                  var value$28 = Js_json.decodeNumber(value$27);
                  tmp$8 = value$28 !== undefined ? value$28 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$27));
                } else {
                  tmp$8 = Js_exn.raiseError("graphql_ppx: Field userCount on type Instance is missing");
                }
                return {
                        id: tmp,
                        name: tmp$1,
                        allowedEmailDomain: tmp$2,
                        billedSampleCount: tmp$3,
                        contractEndDate: tmp$4,
                        contractStartDate: tmp$5,
                        maxSamples: tmp$6,
                        sampleCount: tmp$7,
                        userCount: tmp$8
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field instances on type Staff is missing"),
      sampleHistories: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field sampleHistories on type Staff is missing"),
      lastTwelveMonths: value$6 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$6))).map((function (value) {
                var value$1 = Js_json.decodeString(value);
                if (value$1 !== undefined) {
                  return value$1;
                } else {
                  return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                }
              })) : Js_exn.raiseError("graphql_ppx: Field lastTwelveMonths on type Staff is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field staff on type Query is missing");
  }
  return {
          staff: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_002(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var Instances = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function dataPointToJs(param) {
  return {
          x: param.x,
          y: param.y
        };
}

function dataPointFromJs(param) {
  return {
          x: param.x,
          y: param.y
        };
}

var InstancesQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function str(prim) {
  return prim;
}

var absolutelyPositionedCellStyle = {
  height: "100%",
  left: "0",
  padding: "0.3rem",
  position: "absolute",
  top: "0",
  width: "100%"
};

function InstanceTable(Props, _children) {
  return React.createElement(InstancesQuery.make, {
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  } else if (result.tag) {
                    return React.createElement("table", {
                                className: "table table-striped table-hover table-sm"
                              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "ID"), React.createElement("th", undefined, "Instance Name"), React.createElement("th", undefined, "Allowed Domains"), React.createElement("th", undefined, "Users"), React.createElement("th", undefined, "Sample Usage"), React.createElement("th", undefined, "Unbilled Samples"), React.createElement("th", undefined, "Sample Limit"), React.createElement("th", undefined, "Contract Start"), React.createElement("th", undefined, "Contract End"))), React.createElement("tbody", undefined, $$Array.map((function (inst) {
                                          return React.createElement("tr", {
                                                      key: inst.id
                                                    }, React.createElement("td", undefined, React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps("/admin/instances/" + (inst.id + "/edit"), Caml_option.some(inst.id), undefined))), React.createElement("td", undefined, React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps("/admin/instances/" + (inst.id + "/edit"), Caml_option.some(Belt_Option.getWithDefault(inst.name, "")), undefined))), React.createElement("td", undefined, Belt_Option.getWithDefault(inst.allowedEmailDomain, "").length !== 0 ? Belt_Option.getWithDefault(inst.allowedEmailDomain, "<any>") : "<any>"), React.createElement("td", undefined, String(inst.userCount)), React.createElement("td", undefined, String(inst.billedSampleCount)), React.createElement("td", undefined, String(inst.sampleCount - inst.billedSampleCount | 0)), React.createElement("td", undefined, Belt_Option.mapWithDefault(inst.maxSamples, "Unlimited", (function (prim) {
                                                                return String(prim);
                                                              }))), React.createElement("td", undefined, Belt_Option.getWithDefault(inst.contractStartDate, "")), React.createElement("td", undefined, Belt_Option.getWithDefault(inst.contractEndDate, "")));
                                        }), Belt_Array.reverse($$Array.map(Instance$Astrolabe.GraphQL.fromJs, result[0].staff.instances)))));
                  } else {
                    return React.createElement("div", undefined, result[0].message);
                  }
                })
            });
}

var make$1 = InstanceTable;

var $$default = InstanceTable;

exports.Instances = Instances;
exports.dataPointToJs = dataPointToJs;
exports.dataPointFromJs = dataPointFromJs;
exports.InstancesQuery = InstancesQuery;
exports.str = str;
exports.absolutelyPositionedCellStyle = absolutelyPositionedCellStyle;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* InstancesQuery Not a pure module */
