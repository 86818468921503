// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");

var ppx_printed_query = "query getInstanceFiles($instanceId: ID!)  {\ninstanceFiles(instanceId: $instanceId)  {\nid  \nfileFileName  \ncreatedAt  \nupdatedAt  \ncreateUser  {\nid  \nemail  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "instanceFiles");
  return {
          instanceFiles: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map((function (value) {
                    var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                    var value$2 = Js_dict.get(value$1, "id");
                    var tmp;
                    if (value$2 !== undefined) {
                      var value$3 = Caml_option.valFromOption(value$2);
                      var value$4 = Js_json.decodeString(value$3);
                      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                    } else {
                      tmp = Js_exn.raiseError("graphql_ppx: Field id on type InstanceFile is missing");
                    }
                    var value$5 = Js_dict.get(value$1, "fileFileName");
                    var tmp$1;
                    if (value$5 !== undefined) {
                      var value$6 = Caml_option.valFromOption(value$5);
                      var value$7 = Js_json.decodeString(value$6);
                      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Field fileFileName on type InstanceFile is missing");
                    }
                    var value$8 = Js_dict.get(value$1, "createdAt");
                    var tmp$2;
                    if (value$8 !== undefined) {
                      var value$9 = Caml_option.valFromOption(value$8);
                      var value$10 = Js_json.decodeString(value$9);
                      tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                    } else {
                      tmp$2 = Js_exn.raiseError("graphql_ppx: Field createdAt on type InstanceFile is missing");
                    }
                    var value$11 = Js_dict.get(value$1, "updatedAt");
                    var tmp$3;
                    if (value$11 !== undefined) {
                      var value$12 = Caml_option.valFromOption(value$11);
                      var value$13 = Js_json.decodeString(value$12);
                      tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field updatedAt on type InstanceFile is missing");
                    }
                    var value$14 = Js_dict.get(value$1, "createUser");
                    var tmp$4;
                    if (value$14 !== undefined) {
                      var value$15 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$14)));
                      var value$16 = Js_dict.get(value$15, "id");
                      var tmp$5;
                      if (value$16 !== undefined) {
                        var value$17 = Caml_option.valFromOption(value$16);
                        var value$18 = Js_json.decodeString(value$17);
                        tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                      } else {
                        tmp$5 = Js_exn.raiseError("graphql_ppx: Field id on type User is missing");
                      }
                      var value$19 = Js_dict.get(value$15, "email");
                      var tmp$6;
                      if (value$19 !== undefined) {
                        var value$20 = Caml_option.valFromOption(value$19);
                        var value$21 = Js_json.decodeString(value$20);
                        tmp$6 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$20));
                      } else {
                        tmp$6 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
                      }
                      tmp$4 = {
                        id: tmp$5,
                        email: tmp$6
                      };
                    } else {
                      tmp$4 = Js_exn.raiseError("graphql_ppx: Field createUser on type InstanceFile is missing");
                    }
                    return {
                            id: tmp,
                            fileFileName: tmp$1,
                            createdAt: tmp$2,
                            updatedAt: tmp$3,
                            createUser: tmp$4
                          };
                  })) : Js_exn.raiseError("graphql_ppx: Field instanceFiles on type Query is missing")
        };
}

function make(instanceId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "instanceId",
                    instanceId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var instanceId = variables.instanceId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "instanceId",
                    instanceId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(instanceId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "instanceId",
                  instanceId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, instanceId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "instanceId",
                      instanceId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetInstanceFiles = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetInstanceFilesQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

var ppx_printed_query$1 = "mutation DestroyInstanceFile($id: ID!)  {\ndestroyInstanceFile(id: $id)  {\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyInstanceFile");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type InstanceFilePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyInstanceFile: tmp
        };
}

function make$1(id, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var id = variables.id;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(id, param) {
  return Js_dict.fromArray([/* tuple */[
                  "id",
                  id
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, id, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "id",
                      id
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var DestroyInstanceFileConfig = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var DestroyInstanceFileMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

exports.GetInstanceFiles = GetInstanceFiles;
exports.GetInstanceFilesQuery = GetInstanceFilesQuery;
exports.DestroyInstanceFileConfig = DestroyInstanceFileConfig;
exports.DestroyInstanceFileMutation = DestroyInstanceFileMutation;
/* GetInstanceFilesQuery Not a pure module */
