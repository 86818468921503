// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var ASDSBoxPlot$Astrolabe = require("./ASDSBoxPlot.bs.js");

var ppx_printed_query = "query getBoxPlotData($cellSubsetLevel: CellSubsetLevel!, $treatment: Treatment!, $antibodies: [String!]!, $cellSubsets: [String!]!, $groupBy: BoxPlotGroupBy!)  {\nasds  {\nboxPlotData(cellSubsetLevel: $cellSubsetLevel, treatment: $treatment, antibodies: $antibodies, cellSubsets: $cellSubsets, groupBy: $groupBy)  {\nkeys1  \nkeys2  \ndata  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "asds");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "boxPlotData");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$4)));
      var value$6 = Js_dict.get(value$5, "keys1");
      var value$7 = Js_dict.get(value$5, "keys2");
      var value$8 = Js_dict.get(value$5, "data");
      tmp$1 = {
        keys1: value$6 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$6))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field keys1 on type BoxPlotData is missing"),
        keys2: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field keys2 on type BoxPlotData is missing"),
        data: value$8 !== undefined ? Caml_option.valFromOption(value$8) : Js_exn.raiseError("graphql_ppx: Field data on type BoxPlotData is missing")
      };
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field boxPlotData on type ASDS is missing");
    }
    tmp = {
      boxPlotData: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field asds on type Query is missing");
  }
  return {
          asds: tmp
        };
}

function json_of_CellSubsetLevel(value) {
  if (value >= 500777805) {
    return "ASSIGNMENT";
  } else {
    return "PROFILING";
  }
}

function json_of_Treatment(value) {
  if (value >= 74759758) {
    return "FRESH";
  } else {
    return "FIXED";
  }
}

function json_of_BoxPlotGroupBy(value) {
  if (value >= -76541116) {
    return "ANTIBODY";
  } else {
    return "CELL_SUBSET";
  }
}

function make(cellSubsetLevel, treatment, antibodies, cellSubsets, groupBy, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    json_of_Treatment(treatment)
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "cellSubsets",
                    cellSubsets.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "groupBy",
                    json_of_BoxPlotGroupBy(groupBy)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var cellSubsetLevel = variables.cellSubsetLevel;
  var treatment = variables.treatment;
  var antibodies = variables.antibodies;
  var cellSubsets = variables.cellSubsets;
  var groupBy = variables.groupBy;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "cellSubsetLevel",
                    json_of_CellSubsetLevel(cellSubsetLevel)
                  ],
                  /* tuple */[
                    "treatment",
                    json_of_Treatment(treatment)
                  ],
                  /* tuple */[
                    "antibodies",
                    antibodies.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "cellSubsets",
                    cellSubsets.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "groupBy",
                    json_of_BoxPlotGroupBy(groupBy)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(cellSubsetLevel, treatment, antibodies, cellSubsets, groupBy, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "cellSubsetLevel",
                  json_of_CellSubsetLevel(cellSubsetLevel)
                ],
                /* tuple */[
                  "treatment",
                  json_of_Treatment(treatment)
                ],
                /* tuple */[
                  "antibodies",
                  antibodies.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "cellSubsets",
                  cellSubsets.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "groupBy",
                  json_of_BoxPlotGroupBy(groupBy)
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, cellSubsetLevel, treatment, antibodies, cellSubsets, groupBy, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "cellSubsetLevel",
                      json_of_CellSubsetLevel(cellSubsetLevel)
                    ],
                    /* tuple */[
                      "treatment",
                      json_of_Treatment(treatment)
                    ],
                    /* tuple */[
                      "antibodies",
                      antibodies.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "cellSubsets",
                      cellSubsets.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "groupBy",
                      json_of_BoxPlotGroupBy(groupBy)
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetBoxPlotData = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_CellSubsetLevel: json_of_CellSubsetLevel,
  json_of_Treatment: json_of_Treatment,
  json_of_BoxPlotGroupBy: json_of_BoxPlotGroupBy,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetBoxPlotDataQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function str(prim) {
  return prim;
}

function ASDSBoxPlotContainer(Props) {
  var cellSubsetLevel = Props.cellSubsetLevel;
  var treatment = Props.treatment;
  var antibodies = Props.antibodies;
  var cellSubsets = Props.cellSubsets;
  var groupBy = Props.groupBy;
  var getBoxPlotDataQuery = make(cellSubsetLevel, treatment, antibodies, cellSubsets, groupBy, undefined);
  return React.createElement(GetBoxPlotDataQuery.make, {
              variables: getBoxPlotDataQuery.variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  }
                  if (!result.tag) {
                    return React.createElement("div", undefined, result[0].message);
                  }
                  var boxPlotData = result[0].asds.boxPlotData;
                  return React.createElement(React.Fragment, undefined, antibodies.length !== 0 && cellSubsets.length !== 0 ? React.createElement(ASDSBoxPlot$Astrolabe.make, {
                                    keys1: boxPlotData.keys1,
                                    keys2: boxPlotData.keys2,
                                    data: boxPlotData.data,
                                    legendTitle: groupBy >= -76541116 ? "Cell Subsets" : "Antibodies"
                                  }) : null);
                })
            });
}

var make$1 = ASDSBoxPlotContainer;

exports.GetBoxPlotData = GetBoxPlotData;
exports.GetBoxPlotDataQuery = GetBoxPlotDataQuery;
exports.str = str;
exports.make = make$1;
/* GetBoxPlotDataQuery Not a pure module */
