/* @flow */
"use strict";

import React from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import theme from "models/victoryTheme";

export type DensityChartSeries = Array<{ x: number, y: number, label?: string }>;
export type DensityChartData = Array<DensityChartSeries>;

type Props = {
  data: DensityChartData,
  containerHeight?: number,
  containerWidth?: number,
  height?: number,
  width?: number,
  padding?: Object,
  style?: Object,
  xAxisLabel?: string,
  yAxisLabel?: string,
  legendLabels?: Array<string>,
  noGrid?: boolean,
  areaStyle?: Object,
};

export default class ChannelDensityChart extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <VictoryChart
        theme={this.props.theme || theme}
        width={this.props.width || 600}
        height={this.props.height || 300}
        padding={this.props.padding || { top: 20, right: 50, bottom: 50, left: 50 }}
        containerComponent={
          <VictoryVoronoiContainer width={this.props.width || 1000} height={this.props.height || 400} responsive={false} />
        }
      >
        <VictoryAxis
          tickValues={[0.0, 1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0]}
          label={this.props.xAxisLabel}
          style={this.props.noGrid ? theme.variants.axis.noGrid.style : null}
        />
        <VictoryAxis crossAxis dependentAxis style={this.props.noGrid ? theme.variants.axis.noGrid.style : null} />

        <VictoryGroup>
          {this.props.data.map((datum, i) => (
            <VictoryArea key={i} data={datum} labelComponent={<VictoryTooltip theme={theme} />} style={this.props.areaStyle} />
          ))}
        </VictoryGroup>

        {this.props.legendLabels ? (
          <VictoryLegend
            x={20}
            y={300}
            itemsPerRow={3}
            data={this.props.legendLabels.map((name) => {
              return { name };
            })}
          />
        ) : null}
      </VictoryChart>
    );
  }
}
