/* @flow */
"use strict";

import React from "react";
import { Route, Redirect } from "react-router-dom";
import type { Location } from "react-router";
import type { Session } from "models";

type Props = {
  component?: Class<*> | ((*) => React$Node),
  isPublicExp?: boolean,
  location?: Location,
  render?: () => any,
  roleName?: string,
  allow?: (Session) => boolean,
  session: Session,
};

export default function UserRoute(props: Props) {
  let render = props.render;
  const { component, isPublicExp, location, roleName, session, ...rest } = props;

  if (isPublicExp || (session.user && (!roleName || session.user[`is_${roleName}`] || (props.allow && props.allow(session))))) {
    if (!render && component) {
      render = (props) => React.createElement(component, { ...props, session });
    } else if (!render && !component) {
      throw "Either a `component` or `render` prop must be specified";
    }
  } else if (session.user) {
    render = (props) => <Redirect to={{ pathname: "/", state: { from: location } }} />;
  } else {
    render = (props) => <Redirect to={{ pathname: "/sign_in", state: { from: location } }} />;
  }

  return <Route {...rest} render={render} />;
}
