/* @flow */
"use strict";

import React from "react";
import { CellSubsetLevels, Experiment, Sample, SampleDensities, SampleStatistics } from "models";
import ChannelCellHeatMap from "./ChannelCellHeatMap";
import ChannelDensityChart from "./ChannelDensityChart";
import ChannelScatterPlot from "./ChannelScatterPlot";
import { fetchCSV, fetchJSON } from "components/utils";
import GraphDownloadButton, { svgGraphType } from "../GraphDownloadButton.bs";
import { List, Map, OrderedSet } from "immutable";

type Props = {
  experiment: Experiment,
  heatMapData: SampleStatistics,
  sample: Sample,
};

type State = {
  cellSubsetLevel: string,
  cellSubsetLevels: Array<string>,
  densityChartData: ?SampleDensities,
  fetchingScatterDataNames: OrderedSet<string>,
  scatterData: Map<string, Array<number>>,
  selectedRowLabels: OrderedSet<string>,
  selectedColumnLabels: OrderedSet<string>,
  includeAllCells: boolean,
};

export default class SampleNavigator extends React.Component<Props, State> {
  channelDensityChart: ?React$ElementRef<*>;
  channelScatterPlot: ?React$ElementRef<*>;
  handleCellSubsetChange: (selectedSubsets: OrderedSet<string>) => void;
  handleCellSubsetLevelChange: (string) => void;
  handleChannelChange: (OrderedSet<string>) => void;
  handleAllCellsChange: (boolean) => void;
  mounted: boolean;

  constructor(props: Props) {
    super(props);

    const cellSubsetLevels = Object.keys(props.heatMapData.subset_cell_counts);
    // Right now the default subset level is the first of the array, if we want to set a different default level
    // we will need to add to the channel_intensities/<sample_id>.yml or default to the first for older experiments

    this.state = {
      cellSubsetLevels: cellSubsetLevels,
      cellSubsetLevel: cellSubsetLevels[0],
      densityChartData: null,
      errors: List(),
      fetchingScatterDataNames: OrderedSet(),
      scatterData: new Map(),
      selectedRowLabels: new OrderedSet(),
      selectedColumnLabels: new OrderedSet(),
      includeAllCells: false,
    };

    this.mounted = false;
  }

  componentDidMount() {
    this.fetchDensityChartData();
    this.mounted = true;
  }

  UNSAFE_componentWillUnmount() {
    this.mounted = false;
  }

  async fetchDensityChartData() {
    if (this.state.densityChartData) {
      return;
    }

    const data = await fetchJSON(
      "GET",
      `/experiments/${this.props.experiment.id}/samples/${this.props.sample.get("id")}/density_chart_data.json`,
    );

    if (this.mounted) {
      this.setState({ densityChartData: new SampleDensities(data) });
    }
  }

  async fetchScatterData() {
    if (this.state.selectedColumnLabels.size !== 2) {
      return;
    }

    const fetchUnlessExists = (type: "channel" | "cell_subset", name: string) => {
      if (!this.state.scatterData.get(name)) {
        this.fetchScatterDatum(type, name);
      }
    };

    this.state.selectedColumnLabels.forEach(fetchUnlessExists.bind(this, "channel"));
    fetchUnlessExists("cell_subset", this.state.cellSubsetLevel);
  }

  async fetchScatterDatum(type: "channel" | "cell_subset", name: string) {
    if (this.state.fetchingScatterDataNames.contains(name)) {
      return;
    }

    this.setState({ fetchingScatterDataNames: this.state.fetchingScatterDataNames.add(name) });

    var data = await fetchCSV(
      "GET",
      `/experiments/${this.props.experiment.id}/samples/${this.props.sample.id}/scatter_plot_data.csv?${type}=${name}`,
    );
    if (type === "channel") {
      data = data.map((v) => {
        const float = parseFloat(v);
        return isNaN(float) ? 0 : float;
      });
    } else {
      data = data.slice(1);
    }

    this.setState({
      scatterData: this.state.scatterData.set(name, data),
      fetchingScatterDataNames: this.state.fetchingScatterDataNames.remove(name),
    });
  }

  handleCellSubsetChange = (selectedSubsets: OrderedSet<string>) => {
    this.setState({ selectedRowLabels: selectedSubsets });
  };

  handleCellSubsetLevelChange = (cellSubsetLevel: string) => {
    this.setState(
      {
        cellSubsetLevel,
        selectedRowLabels: new OrderedSet(),
        selectedColumnLabels: new OrderedSet(),
      },
      () => {
        () => this.fetchScatterData();
      },
    );
  };

  handleChannelChange = (selectedChannels: Immutable.OrderedSet<string>) => {
    this.setState({ selectedColumnLabels: selectedChannels }, () => this.fetchScatterData());
  };

  handleAllCellsChange = (includeAllCells: boolean) => {
    this.setState({ includeAllCells });
  };

  renderChannelDensityChart() {
    if (this.state.densityChartData && this.state.selectedColumnLabels.size === 1) {
      const densityChartData = this.state.densityChartData;
      const includeAllCells = this.state.includeAllCells || this.state.selectedRowLabels.size == 0;
      const seriesNames = (includeAllCells ? ["All Cells"] : []).concat(this.state.selectedRowLabels.toJS());
      const data = seriesNames.map((cellSubset) => {
        return densityChartData.chartData(this.state.selectedColumnLabels.first(), this.state.cellSubsetLevel, cellSubset);
      });

      return (
        <div className="col-12">
          <hr />
          <div className="row">
            <div className="col-12" ref={(el) => (this.channelDensityChart = el)}>
              <h4>
                Density: {this.state.selectedColumnLabels.first()}
                &nbsp;
                <GraphDownloadButton
                  className="download-top-50"
                  graphType={svgGraphType}
                  prefix={this.props.experiment.name}
                  name="Density Chart"
                  container={() => this.channelDensityChart}
                  filenames={[
                    this.props.sample.get("name"),
                    this.state.selectedColumnLabels.first(),
                    this.state.selectedRowLabels.join("_"),
                  ]
                    .filter((v) => !!v || v.length > 0)
                    .join("_")}
                />
              </h4>
              <ChannelDensityChart data={data} xAxisLabel={this.state.selectedColumnLabels.first()} legendLabels={seriesNames} />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  renderScatterPlot() {
    if (this.state.selectedColumnLabels.size !== 2) {
      return null;
    }

    const selectedColumnLabels = this.state.selectedColumnLabels.toJS();
    const selectedRowLabels = this.state.selectedRowLabels.toJS();

    const yChannel = selectedColumnLabels[0];
    const xChannel = selectedColumnLabels[1];

    var xData = this.state.scatterData.get(xChannel);
    var yData = this.state.scatterData.get(yChannel);
    const subsetData = this.state.scatterData.get(this.state.cellSubsetLevel);

    if (!xData || !yData || !subsetData) {
      return (
        <div className="col-12">
          <div className="row">
            <hr className="col-12" />
          </div>
          <div className="row justify-content-center">
            <div className="col" />
            <div className="col-6">Loading…</div>
          </div>
        </div>
      );
    }

    // fetchCSV drops any rows at the end of the file which are empty (0s). We
    // fill them back here.
    if (subsetData.length > xData.length) {
      xData = xData.concat(Array(subsetData.length - xData.length).fill(0));
    }
    if (subsetData.length > yData.length) {
      yData = yData.concat(Array(subsetData.length - yData.length).fill(0));
    }

    const xMax = Math.ceil(xData.reduce((x, y) => Math.max(x, y)));
    const yMax = Math.ceil(yData.reduce((x, y) => Math.max(x, y)));

    var data = [[]];
    var labelsToIndexes: { [string]: number } = { Other: 0 };
    var legendLabels = ["Other"].concat(selectedRowLabels);

    selectedRowLabels.forEach((label, i) => {
      data.push([]);
      labelsToIndexes[label] = i + 1;
    });

    // Collect points from each subset into its own array.
    var uniquePoints = new Set();
    xData.forEach((x, i) => {
      const y = yData[i];
      var subset = subsetData[i];

      const str = `${subset},${x},${y}`;
      if (uniquePoints.has(str)) {
        return;
      }
      uniquePoints.add(str);

      if (!this.state.selectedRowLabels.contains(subset)) {
        subset = "Other";
      }
      data[labelsToIndexes[subset]].push({ x, y });
    });

    // Rename "other" if no other subsets selected.
    if (selectedRowLabels.length == 0) {
      legendLabels = ["All Cells"];
    } else {
      if (!this.state.includeAllCells) {
        data.shift();
        legendLabels.shift();
      }
    }

    const pointCount = data
      .map((datum) => datum.length)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    if (pointCount > 50000) {
      console.warn(
        `Attempting to draw too many data points!\n`,
        `Total: ${pointCount}\n`,
        legendLabels.map((label, i) => `${label}: ${data[i].length}`).join("\n"),
      );

      return (
        <div className="col-12">
          <div className="row">
            <hr className="col-12" />
          </div>
          <div className="row justify-content-center">
            <div className="col" />
            <div className="col-6">
              <div className="alert alert-danger text-center" role="alert">
                Can't show scatter plot because the data is too large.
                <br />
                Try deselecting one or more cell subsets.
              </div>
            </div>
            <div className="col" />
          </div>
        </div>
      );
    }

    const retVal = (
      <div className="col-12">
        <hr />
        <div className="row">
          <div className="col-12" ref={(el) => (this.channelScatterPlot = el)}>
            <h4>
              {xChannel} versus {yChannel}
              &nbsp;
              <GraphDownloadButton
                className="download-top-50"
                prefix={this.props.experiment.name}
                graphType={svgGraphType}
                name="Scatter Plot"
                container={() => this.channelScatterPlot}
                filenames={`${this.props.sample.get("name")}_${xChannel}_${yChannel}_${this.state.selectedRowLabels.join("_")}`}
              />
            </h4>
            <ChannelScatterPlot
              data={data}
              xAxisLabel={xChannel}
              yAxisLabel={yChannel}
              xMax={xMax}
              yMax={yMax}
              legendLabels={legendLabels}
            />
          </div>
        </div>
      </div>
    );

    return retVal;
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <ChannelCellHeatMap
            sample={this.props.sample}
            sampleStatistics={this.props.heatMapData}
            cellSubsetLevel={this.state.cellSubsetLevel}
            cellSubsetLevels={this.state.cellSubsetLevels}
            experiment={this.props.experiment}
            onCellSubsetChange={this.handleCellSubsetLevelChange}
            onSelectedRowLabelsChange={this.handleCellSubsetChange}
            onSelectedColumnLabelsChange={this.handleChannelChange}
            onAllCellsChange={this.handleAllCellsChange}
            selectedRowLabels={this.state.selectedRowLabels}
            selectedColumnLabels={this.state.selectedColumnLabels}
            includeAllCells={this.state.includeAllCells}
          />
        </div>

        {this.renderChannelDensityChart()}
        {this.renderScatterPlot()}
      </div>
    );
  }
}
