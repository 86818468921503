// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

var ppx_printed_query = "query getAvailableCustomLabelingHierarchies($experimentId: ID!)  {\navailableCustomLabelingHierarchies(experimentId: $experimentId)  {\nid  \nname  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "availableCustomLabelingHierarchies");
  return {
          availableCustomLabelingHierarchies: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map((function (value) {
                    var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                    var value$2 = Js_dict.get(value$1, "id");
                    var tmp;
                    if (value$2 !== undefined) {
                      var value$3 = Caml_option.valFromOption(value$2);
                      var value$4 = Js_json.decodeString(value$3);
                      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                    } else {
                      tmp = Js_exn.raiseError("graphql_ppx: Field id on type CustomLabelingHierarchy is missing");
                    }
                    var value$5 = Js_dict.get(value$1, "name");
                    var tmp$1;
                    if (value$5 !== undefined) {
                      var value$6 = Caml_option.valFromOption(value$5);
                      var value$7 = Js_json.decodeString(value$6);
                      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type CustomLabelingHierarchy is missing");
                    }
                    return {
                            id: tmp,
                            name: tmp$1
                          };
                  })) : Js_exn.raiseError("graphql_ppx: Field availableCustomLabelingHierarchies on type Query is missing")
        };
}

function make(experimentId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "experimentId",
                  experimentId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "experimentId",
                      experimentId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetAvailableCustomLabelingHierarchies = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetAvailableCustomLabelingHierarchiesQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function CustomLabelingHierarchySelect(Props) {
  var clearExperimentErrors = Props.clearExperimentErrors;
  var experimentId = Props.experimentId;
  var experimentCustomLabelingHierarchyId = Props.experimentCustomLabelingHierarchyId;
  return React.createElement(GetAvailableCustomLabelingHierarchiesQuery.make, {
              variables: make(experimentId, undefined).variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  }
                  if (!result.tag) {
                    return React.createElement("div", undefined, result[0].message);
                  }
                  var availableCustomLabelingHierarchies = result[0].availableCustomLabelingHierarchies;
                  return React.createElement(ExperimentGraphQL$Astrolabe.UpdateMutation.make, {
                              children: (function (mutation, param) {
                                  return React.createElement("select", {
                                              className: "form-control form-control-sm col-6",
                                              value: experimentCustomLabelingHierarchyId,
                                              onChange: (function ($$event) {
                                                  var customLabelingHierarchyId = ReasonReactHelpers$Astrolabe.valueFromEvent($$event) === "-1" ? "" : ReasonReactHelpers$Astrolabe.valueFromEvent($$event);
                                                  return GraphQLHelpers$Astrolabe.handleDataAndErrors((function (param) {
                                                                return Curry._1(clearExperimentErrors, undefined);
                                                              }), Curry._4(mutation, Caml_option.some(ExperimentGraphQL$Astrolabe.UpdateConfig.make(experimentId, customLabelingHierarchyId, undefined, undefined, undefined, undefined, undefined, /* HUMAN */759401869, undefined, undefined, undefined).variables), ["getExperiment"], undefined, undefined));
                                                })
                                            }, availableCustomLabelingHierarchies.map((function (clh) {
                                                    return React.createElement("option", {
                                                                key: clh.id,
                                                                value: clh.id
                                                              }, clh.name);
                                                  })));
                                })
                            });
                })
            });
}

var $$Array;

var UpdateExperimentConfig;

var UpdateExperimentMutation;

var make$1 = CustomLabelingHierarchySelect;

exports.$$Array = $$Array;
exports.UpdateExperimentConfig = UpdateExperimentConfig;
exports.UpdateExperimentMutation = UpdateExperimentMutation;
exports.GetAvailableCustomLabelingHierarchies = GetAvailableCustomLabelingHierarchies;
exports.GetAvailableCustomLabelingHierarchiesQuery = GetAvailableCustomLabelingHierarchiesQuery;
exports.make = make$1;
/* GetAvailableCustomLabelingHierarchiesQuery Not a pure module */
