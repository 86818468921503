// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Icons$Astrolabe = require("../Icons.bs.js");
var Modal$Astrolabe = require("../Modal.bs.js");
var SysadminSettings$Astrolabe = require("../sysadmin/SysadminSettings.bs.js");

function SysadminButton(Props) {
  var experiment = Props.experiment;
  var match = React.useState((function () {
          return false;
        }));
  var setShowModal = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "cursor-pointer",
                  title: "Sysadmin options",
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, React.createElement(Icons$Astrolabe.Sysadmin.make, {
                      height: "20",
                      width: "30"
                    })), React.createElement(Modal$Astrolabe.make, {
                  onCancel: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  title: "Sysadmin Options",
                  visible: match[0],
                  children: React.createElement(SysadminSettings$Astrolabe.make, {
                        experiment: experiment,
                        onDone: (function (param) {
                            return Curry._1(setShowModal, (function (param) {
                                          return false;
                                        }));
                          })
                      })
                }));
}

var make = SysadminButton;

var $$default = SysadminButton;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
