// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Modal = require("./Modal");
var ReasonReact = require("reason-react/src/legacy/ReasonReact.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");

var modal = Modal.default;

function make(onCancel, title, visible, children) {
  return ReasonReact.wrapJsForReason(modal, {
              onCancel: onCancel,
              title: Js_null_undefined.fromOption(title),
              visible: visible
            }, children);
}

var JSX2 = {
  modal: modal,
  make: make
};

var make$1 = Modal.default;

exports.JSX2 = JSX2;
exports.make = make$1;
/* modal Not a pure module */
