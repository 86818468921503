// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Theme$Astrolabe = require("../../models/Theme.bs.js");
var Feature$Astrolabe = require("../../models/Feature.bs.js");
var ReactScrollSync = require("react-scroll-sync");
var AnalysisKit$Astrolabe = require("../../models/AnalysisKit.bs.js");
var DEAViewHeader$Astrolabe = require("./DEAViewHeader.bs.js");
var DataTableHeatMap$Astrolabe = require("../DataTableHeatMap.bs.js");
var GraphDownloadButton$Astrolabe = require("../GraphDownloadButton.bs.js");
var NotificationMessage$Astrolabe = require("../shared/NotificationMessage.bs.js");
var DEAMarkerChannelView$Astrolabe = require("./DEAMarkerChannelView.bs.js");
var DifferentialExpressionData$Astrolabe = require("../../models/DifferentialExpressionData.bs.js");

var or = Util$Astrolabe.$$Option.or;

var ppx_printed_query = "query getDeAnalysisKit($id: ID!)  {\ndeAnalysisKit(id: $id)  {\ndata  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "deAnalysisKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "data");
      tmp = {
        data: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field data on type DEAnalysisKit is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          deAnalysisKit: tmp
        };
}

function make(id, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, param) {
  return Js_dict.fromArray([/* tuple */[
                  "id",
                  id
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "id",
                      id
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetDeAnalysisKitData = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetDeAnalysisKitDataQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function makeInit(defaultCellSubsetLevel) {
  return {
          selectedMarkerChannels: Util$Astrolabe.$$String.$$Set.empty,
          selectedSubsetLevel: defaultCellSubsetLevel,
          selectedCellSubsets: Util$Astrolabe.$$String.$$Set.empty,
          selectedCellSubsetIndexes: Util$Astrolabe.Int.$$Set.empty,
          activeTab: /* Overview */0,
          notificationStatus: undefined,
          sorting: /* tuple */[
            "alphanumeric",
            true
          ]
        };
}

function collectCellSubsetIndexes(cellSubsetMap) {
  var go = function (subsets) {
    return $$Array.fold_left((function (param, subset) {
                    var i = param[1];
                    var acc = param[0];
                    var indexes = or(Curry._2(Util$Astrolabe.$$String.$$Map.find_opt, subset, acc), Util$Astrolabe.Int.$$Set.empty);
                    var indexes$1 = Curry._2(Util$Astrolabe.Int.$$Set.add, i, indexes);
                    var acc$1 = Curry._3(Util$Astrolabe.$$String.$$Map.add, subset, indexes$1, acc);
                    return /* tuple */[
                            acc$1,
                            i + 1 | 0
                          ];
                  }), /* tuple */[
                  Util$Astrolabe.$$String.$$Map.empty,
                  0
                ], subsets)[0];
  };
  return Js_dict.map(go, cellSubsetMap);
}

function indexesForCellSubsets(cellSubsetIndexes, selectedCellSubsets) {
  var init = Util$Astrolabe.Int.$$Set.empty;
  var go = function (subset, acc) {
    var indexes = Curry._2(Util$Astrolabe.$$String.$$Map.find, subset, cellSubsetIndexes);
    return Curry._2(Util$Astrolabe.Int.$$Set.union, indexes, acc);
  };
  return Curry._3(Util$Astrolabe.$$String.$$Set.fold, go, selectedCellSubsets, init);
}

function getCellSubset(cellSubsetMap, subsetLevel, i) {
  var levelSubsets = or(Js_dict.get(cellSubsetMap, Curry._1(Util$Astrolabe.$$String.capitalize, subsetLevel)), []);
  return Caml_array.caml_array_get(levelSubsets, i);
}

function handleCellSubsetLevelChange(cellSubsetMap, state, subsetLevel) {
  var selectedCellSubsets = Curry._3(Util$Astrolabe.Int.$$Set.fold, (function (i, acc) {
          return Curry._2(Util$Astrolabe.$$String.$$Set.add, getCellSubset(cellSubsetMap, subsetLevel, i), acc);
        }), state.selectedCellSubsetIndexes, Util$Astrolabe.$$String.$$Set.empty);
  return {
          selectedMarkerChannels: state.selectedMarkerChannels,
          selectedSubsetLevel: subsetLevel,
          selectedCellSubsets: selectedCellSubsets,
          selectedCellSubsetIndexes: state.selectedCellSubsetIndexes,
          activeTab: state.activeTab,
          notificationStatus: state.notificationStatus,
          sorting: state.sorting
        };
}

function DifferentialExpressionAnalysisView(Props) {
  var features = Props.features;
  var kit = Props.kit;
  var experimentName = Props.experimentName;
  var match_ = Props.match;
  var samples = Props.samples;
  var cellSubsetLevels = Props.cellSubsetLevels;
  var cellSubsetMap = cellSubsetLevels.cell_subset_map;
  var cellSubsetLevelIndexes = collectCellSubsetIndexes(cellSubsetMap);
  var match = React.useState((function () {
          return makeInit(cellSubsetLevels.default);
        }));
  var setState = match[1];
  var state = match[0];
  var heatMapRef = React.useRef(null);
  var setSelectedMarkerChannels = function (selectedMarkerChannels) {
    return Curry._1(setState, (function (state) {
                  return {
                          selectedMarkerChannels: selectedMarkerChannels,
                          selectedSubsetLevel: state.selectedSubsetLevel,
                          selectedCellSubsets: state.selectedCellSubsets,
                          selectedCellSubsetIndexes: state.selectedCellSubsetIndexes,
                          activeTab: state.activeTab,
                          notificationStatus: Curry._1(Util$Astrolabe.$$String.$$Set.length, state.selectedMarkerChannels) === 0 ? /* tuple */[
                              /* success */944645571,
                              "Scroll down for new figures"
                            ] : undefined,
                          sorting: state.sorting
                        };
                }));
  };
  var setSelectedCellSubsets = function (selectedCellSubsets) {
    return Curry._1(setState, (function (state) {
                  return {
                          selectedMarkerChannels: state.selectedMarkerChannels,
                          selectedSubsetLevel: state.selectedSubsetLevel,
                          selectedCellSubsets: selectedCellSubsets,
                          selectedCellSubsetIndexes: indexesForCellSubsets(Util$Astrolabe.$$Option.force(Js_dict.get(cellSubsetLevelIndexes, Curry._1(Util$Astrolabe.$$String.capitalize, state.selectedSubsetLevel))), selectedCellSubsets),
                          activeTab: Curry._1(Util$Astrolabe.$$String.$$Set.cardinal, selectedCellSubsets) === 0 ? /* Overview */0 : state.activeTab,
                          notificationStatus: state.notificationStatus,
                          sorting: state.sorting
                        };
                }));
  };
  var setSorting = function (sorting) {
    return Curry._1(setState, (function (state) {
                  return {
                          selectedMarkerChannels: state.selectedMarkerChannels,
                          selectedSubsetLevel: state.selectedSubsetLevel,
                          selectedCellSubsets: state.selectedCellSubsets,
                          selectedCellSubsetIndexes: state.selectedCellSubsetIndexes,
                          activeTab: state.activeTab,
                          notificationStatus: state.notificationStatus,
                          sorting: sorting
                        };
                }));
  };
  var setActiveTab = function (x) {
    return Curry._1(setState, (function (state) {
                  return {
                          selectedMarkerChannels: state.selectedMarkerChannels,
                          selectedSubsetLevel: state.selectedSubsetLevel,
                          selectedCellSubsets: state.selectedCellSubsets,
                          selectedCellSubsetIndexes: state.selectedCellSubsetIndexes,
                          activeTab: x,
                          notificationStatus: state.notificationStatus,
                          sorting: state.sorting
                        };
                }));
  };
  var setNotificationStatus = function (x) {
    return Curry._1(setState, (function (state) {
                  return {
                          selectedMarkerChannels: state.selectedMarkerChannels,
                          selectedSubsetLevel: state.selectedSubsetLevel,
                          selectedCellSubsets: state.selectedCellSubsets,
                          selectedCellSubsetIndexes: state.selectedCellSubsetIndexes,
                          activeTab: state.activeTab,
                          notificationStatus: x,
                          sorting: state.sorting
                        };
                }));
  };
  var variables = make(Belt_Option.getExn(kit.id), undefined).variables;
  return React.createElement(GetDeAnalysisKitDataQuery.make, {
              variables: variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  }
                  if (!result.tag) {
                    return React.createElement("div", undefined, result[0].message);
                  }
                  var dataByCellSubsetLevel = DifferentialExpressionData$Astrolabe.byCellSubsetLevelFromJson(Belt_Option.getExn(result[0].deAnalysisKit).data);
                  var selectedSubsetLevel = state.selectedSubsetLevel === "" ? Belt_Option.getWithDefault(Belt_Array.get(Object.keys(dataByCellSubsetLevel), 0), "") : state.selectedSubsetLevel;
                  var deData = Js_dict.get(dataByCellSubsetLevel, selectedSubsetLevel);
                  var heatMap;
                  if (deData !== undefined) {
                    var dataTable = DifferentialExpressionData$Astrolabe.foldChangeDataTable(deData);
                    heatMap = React.createElement(DataTableHeatMap$Astrolabe.make, {
                          dataTable: dataTable,
                          colors: Theme$Astrolabe.Palette.redWhiteBlue,
                          selectedRowLabels: state.selectedCellSubsets,
                          selectedColumnLabels: state.selectedMarkerChannels,
                          onSelectColumnLabel: (function (label) {
                              return setSelectedMarkerChannels(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, label, state.selectedMarkerChannels));
                            }),
                          onSelectRowLabel: (function (label) {
                              return setSelectedCellSubsets(Curry._2(Util$Astrolabe.$$String.$$Set.toggle, label, state.selectedCellSubsets));
                            }),
                          legendTitle: "Fold Change",
                          symmetricLegendValues: true,
                          minValue: Curry._1(AnalysisKit$Astrolabe.HeatMap.FC.value, kit.markerStatistic) * -1.0,
                          maxValue: Curry._1(AnalysisKit$Astrolabe.HeatMap.FC.value, kit.markerStatistic)
                        });
                  } else {
                    heatMap = null;
                  }
                  var match = state.notificationStatus;
                  return React.createElement(React.Fragment, undefined, React.createElement(DEAViewHeader$Astrolabe.make, {
                                  kit: kit,
                                  match: match_,
                                  availableMarkerChannels: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, $$Array.map((function (cu) {
                                              return cu.desc;
                                            }), kit.markerChannels)),
                                  selectedMarkerChannels: state.selectedMarkerChannels,
                                  onDeselectMarkerChannels: (function (filtered) {
                                      return setSelectedMarkerChannels(Curry._2(Util$Astrolabe.$$String.$$Set.diff, state.selectedMarkerChannels, filtered));
                                    }),
                                  onSelectMarkerChannels: setSelectedMarkerChannels,
                                  availableCellSubsetLevels: Curry._1(Util$Astrolabe.$$String.$$Set.of_array, Object.keys(dataByCellSubsetLevel)),
                                  selectedCellSubsetLevel: selectedSubsetLevel,
                                  onSelectCellSubsetLevel: (function (v) {
                                      return Curry._1(setState, (function (state) {
                                                    return handleCellSubsetLevelChange(cellSubsetMap, state, v);
                                                  }));
                                    }),
                                  availableCellSubsets: deData !== undefined ? Curry._1(Util$Astrolabe.$$String.$$Set.of_array, deData.cellSubsets) : Util$Astrolabe.$$String.$$Set.empty,
                                  selectedCellSubsets: state.selectedCellSubsets,
                                  onDeselectCellSubsets: (function (filtered) {
                                      return setSelectedCellSubsets(Curry._2(Util$Astrolabe.$$String.$$Set.diff, state.selectedCellSubsets, filtered));
                                    }),
                                  onSelectCellSubsets: setSelectedCellSubsets
                                }), React.createElement("div", {
                                  ref: heatMapRef,
                                  className: "row pt-4 position-relative"
                                }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                                      prefix: experimentName,
                                      name: "Box Plot",
                                      graphType: /* Canvas */-321468168,
                                      container: (function (param) {
                                          return heatMapRef.current;
                                        }),
                                      filenames: "Analyses " + kit.name
                                    }), heatMap), React.createElement(ReactScrollSync.ScrollSync, {
                                  children: React.createElement("div", undefined, $$Array.map((function (channelDesc) {
                                              if (deData === undefined) {
                                                return null;
                                              }
                                              var feature = kit.primaryFeature;
                                              return React.createElement(DEAMarkerChannelView$Astrolabe.make, {
                                                          cellSubsets: Curry._1(Util$Astrolabe.$$String.$$Set.to_array, state.selectedCellSubsets),
                                                          channelDesc: channelDesc,
                                                          kitName: kit.name,
                                                          kitMarkerStatistic: kit.markerStatistic,
                                                          deData: deData,
                                                          features: features,
                                                          experimentName: experimentName,
                                                          primaryFeatureId: feature !== undefined ? Feature$Astrolabe.id(Caml_option.valFromOption(feature)) : "-1",
                                                          samples: samples,
                                                          disclosed: true,
                                                          activeTab: state.activeTab,
                                                          onActiveTabChanged: setActiveTab,
                                                          setNotificationStatus: setNotificationStatus,
                                                          onCloseClick: (function (param) {
                                                              return setSelectedMarkerChannels(Curry._2(Util$Astrolabe.$$String.$$Set.remove, channelDesc, state.selectedMarkerChannels));
                                                            }),
                                                          sorting: state.sorting,
                                                          setSorting: setSorting,
                                                          key: channelDesc
                                                        });
                                            }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, state.selectedMarkerChannels)))
                                }), match !== undefined ? React.createElement(NotificationMessage$Astrolabe.make, {
                                    onDismiss: (function (param) {
                                        return setNotificationStatus(undefined);
                                      }),
                                    className: "disappearing-toast text-center fixed-bottom",
                                    children: React.createElement("h4", undefined, match[1])
                                  }) : null);
                })
            });
}

var $$String;

var Int;

var ScrollSync;

var make$1 = DifferentialExpressionAnalysisView;

exports.$$String = $$String;
exports.Int = Int;
exports.ScrollSync = ScrollSync;
exports.or = or;
exports.GetDeAnalysisKitData = GetDeAnalysisKitData;
exports.GetDeAnalysisKitDataQuery = GetDeAnalysisKitDataQuery;
exports.makeInit = makeInit;
exports.collectCellSubsetIndexes = collectCellSubsetIndexes;
exports.indexesForCellSubsets = indexesForCellSubsets;
exports.getCellSubset = getCellSubset;
exports.handleCellSubsetLevelChange = handleCellSubsetLevelChange;
exports.make = make$1;
/* GetDeAnalysisKitDataQuery Not a pure module */
