/* @flow */
"use strict";

import React from "react";
import { schemeAccent } from "d3-scale-chromatic";
import { Box, VictoryAxis, VictoryBoxPlot, VictoryChart, VictoryContainer, VictoryGroup, VictoryLegend } from "victory";
import type { BoxPlotDatum } from "models";
import theme from "models/victoryTheme";

type Props = {
  keys1: Array<string>,
  keys2: Array<string>,
  data: { [string]: { [string]: BoxPlotDatum } },
  legendTitle: string,
};

export default class FrequencyBoxPlot extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const k2Length = this.props.keys2.length;
    let data = [];
    let yAxisTickLabels: { [number]: string } = {};

    [...this.props.keys1].reverse().forEach((k1, k1i) => {
      let offset = k2Length % 2 === 0 ? 0.5 : 0;

      [...this.props.keys2].reverse().forEach((k2, k2i) => {
        const y = 1 + k1i * k2Length + k2i - offset;
        if (k2i == Math.floor((k2Length - 1) / 2)) {
          yAxisTickLabels[Math.ceil(y)] = k1;
        }
        data.push({ y, ...this.props.data[k1][k2] });
      });
    });

    /* Chart metrics */
    const leftPadding = 9 * Math.max.apply(null, this.props.keys1.map((x) => x.length));
    const yDomainPadding = 10;
    const topPadding = 10;
    const bottomPadding = 30;
    const chartHeight = Math.max(data.length * 30, 80);
    const chartWidth = Math.min(Math.max(400, leftPadding + 300), 600);

    /* Legend metrics */
    const legendLeftMargin = 50;
    const legendWidth = 30 + 9 * Math.max.apply(null, this.props.keys2.map((k) => k.length));

    /* Container metrics */
    const containerHeight = chartHeight + yDomainPadding + topPadding + bottomPadding;
    const containerWidth = chartWidth + legendLeftMargin + legendWidth;

    let maxY = Math.max.apply(null, data.map((d) => d.y));
    maxY = maxY % 1 === 0 ? maxY + 0.5 : maxY;

    const boxPlotStyle = {
      fill: (d) => schemeAccent[k2Length - (d.eventKey % k2Length) - 1],
      stroke: (d) => schemeAccent[k2Length - (d.eventKey % k2Length) - 1],
    };

    return (
      <VictoryChart
        theme={{ ...theme, boxplot: { ...theme.boxplot, boxWidth: 12 } }}
        width={chartWidth}
        height={chartHeight}
        domainPadding={{ x: 20, y: yDomainPadding }}
        padding={{ left: leftPadding, right: 0, top: topPadding, bottom: bottomPadding }}
        containerComponent={<VictoryContainer width={containerWidth} height={containerHeight} responsive={false} />}
        domain={{ x: [0, 7], y: [0, maxY] }}
      >
        <VictoryAxis style={theme.variants.axis.noGrid} tickCount={7} tickFormat={(d) => `${d.toFixed(1)}`} />

        <VictoryAxis
          dependentAxis
          style={{
            ...theme.boxplot.style,
            grid: { stroke: (t) => (t === "" ? "#fff" : "#eee") },
            tickLabels: { ...theme.boxplot.style.tickLabels, fontSize: 11, padding: 10 },
          }}
          tickCount={data.length == 1 ? data.length + 1 : data.length}
          tickFormat={(d, i, ticks) => {
            if (ticks.length === 1 && Object.values(yAxisTickLabels).length === 1) {
              return Object.values(yAxisTickLabels)[0];
            } else {
              return yAxisTickLabels[d];
            }
          }}
        />

        <VictoryBoxPlot
          style={{
            ...theme.variants.axis.matchingNivo.style,
            axis: { stroke: "transparent" },
            min: boxPlotStyle,
            q1: boxPlotStyle,
            q3: boxPlotStyle,
            max: boxPlotStyle,
          }}
          horizontal={true}
          data={data}
        />

        <VictoryLegend
          x={chartWidth + legendLeftMargin}
          y={0}
          title={this.props.legendTitle}
          orientation="vertical"
          style={{ title: { fontSize: 16, padding: 5 }, data: { type: "square" } }}
          data={this.props.keys2.map((k) => ({ name: k }))}
        />
      </VictoryChart>
    );
  }
}
