// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function id(self) {
  return self.id;
}

function createUserEmail(self) {
  return self.createUser.email;
}

function createdAt(self) {
  return self.createdAt;
}

function fileFileName(self) {
  return self.fileFileName;
}

function updatedAt(self) {
  return self.updatedAt;
}

exports.id = id;
exports.createdAt = createdAt;
exports.createUserEmail = createUserEmail;
exports.fileFileName = fileFileName;
exports.updatedAt = updatedAt;
/* No side effect */
