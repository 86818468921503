// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var Channel$Astrolabe = require("../../../models/Channel.bs.js");
var ChannelRow$Astrolabe = require("./ChannelRow.bs.js");
var TitleWithIcon$Astrolabe = require("../../header/TitleWithIcon.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var SortableTableHeader$Astrolabe = require("../../shared/SortableTableHeader.bs.js");
var AnalysisColumnSettings$Astrolabe = require("./AnalysisColumnSettings.bs.js");
var DownloadUploadTemplateBox$Astrolabe = require("../../shared/DownloadUploadTemplateBox.bs.js");

function filterDisplayChannelsByIgnore(filterIgnore, channels) {
  switch (filterIgnore) {
    case /* OnlyAnalysis */0 :
        return channels.filter((function (channel) {
                      return channel.usage === /* ANALYSIS */739437404;
                    }));
    case /* OnlyClassification */1 :
        return channels.filter((function (channel) {
                      return channel.usage === /* CLASSIFICATION */257034022;
                    }));
    case /* OnlyIgnore */2 :
        return channels.filter((function (channel) {
                      if (channel.usage === /* IGNORED */-592639246) {
                        return true;
                      } else {
                        return channel.usage === /* LIVE_DEAD */-568609769;
                      }
                    }));
    case /* ExceptIgnore */3 :
        return channels.filter((function (channel) {
                      if (channel.usage !== /* IGNORED */-592639246) {
                        return true;
                      } else {
                        return channel.usage === /* LIVE_DEAD */-568609769;
                      }
                    }));
    case /* NoFilter */4 :
        return channels;
    
  }
}

function sortChannels(sort, channels) {
  var str = sort[0];
  switch (str) {
    case "desc" :
        return SortableTableHeader$Astrolabe.Sort.withGetter(Channel$Astrolabe.desc, sort[1], channels);
    case "name" :
        return SortableTableHeader$Astrolabe.Sort.withGetter(Channel$Astrolabe.name, sort[1], channels);
    default:
      return Pervasives.invalid_arg(str);
  }
}

function filterAllByStateFilters(state, channels) {
  return sortChannels(state.sort, Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(state.filterName, Channel$Astrolabe.name, Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(state.filterDesc, Channel$Astrolabe.desc, filterDisplayChannelsByIgnore(state.filterIgnore, channels))));
}

function reducer(state, action, channels) {
  switch (action.tag | 0) {
    case /* FilterChannelDescs */0 :
        var filterDesc = action[0];
        var stateWithClearedFilterIgnore_displayChannels = state.displayChannels;
        var stateWithClearedFilterIgnore_filterName = state.filterName;
        var stateWithClearedFilterIgnore_filterIgnore = state.filterIgnore;
        var stateWithClearedFilterIgnore_isUploadingTemplate = state.isUploadingTemplate;
        var stateWithClearedFilterIgnore_sort = state.sort;
        var stateWithClearedFilterIgnore = {
          displayChannels: stateWithClearedFilterIgnore_displayChannels,
          filterDesc: "",
          filterName: stateWithClearedFilterIgnore_filterName,
          filterIgnore: stateWithClearedFilterIgnore_filterIgnore,
          isUploadingTemplate: stateWithClearedFilterIgnore_isUploadingTemplate,
          sort: stateWithClearedFilterIgnore_sort
        };
        var displayChannels = Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(filterDesc, Channel$Astrolabe.desc, filterAllByStateFilters(stateWithClearedFilterIgnore, channels));
        return {
                displayChannels: displayChannels,
                filterDesc: filterDesc,
                filterName: state.filterName,
                filterIgnore: state.filterIgnore,
                isUploadingTemplate: state.isUploadingTemplate,
                sort: state.sort
              };
    case /* FilterChannelUsage */1 :
        var filterIgnore = action[0];
        var stateWithClearedFilterIgnore_displayChannels$1 = state.displayChannels;
        var stateWithClearedFilterIgnore_filterDesc = state.filterDesc;
        var stateWithClearedFilterIgnore_filterName$1 = state.filterName;
        var stateWithClearedFilterIgnore_isUploadingTemplate$1 = state.isUploadingTemplate;
        var stateWithClearedFilterIgnore_sort$1 = state.sort;
        var stateWithClearedFilterIgnore$1 = {
          displayChannels: stateWithClearedFilterIgnore_displayChannels$1,
          filterDesc: stateWithClearedFilterIgnore_filterDesc,
          filterName: stateWithClearedFilterIgnore_filterName$1,
          filterIgnore: /* NoFilter */4,
          isUploadingTemplate: stateWithClearedFilterIgnore_isUploadingTemplate$1,
          sort: stateWithClearedFilterIgnore_sort$1
        };
        var displayChannels$1 = filterDisplayChannelsByIgnore(filterIgnore, filterAllByStateFilters(stateWithClearedFilterIgnore$1, channels));
        return {
                displayChannels: displayChannels$1,
                filterDesc: state.filterDesc,
                filterName: state.filterName,
                filterIgnore: filterIgnore,
                isUploadingTemplate: state.isUploadingTemplate,
                sort: state.sort
              };
    case /* FilterChannelNames */2 :
        var filterName = action[0];
        var stateWithClearedFilterName_displayChannels = state.displayChannels;
        var stateWithClearedFilterName_filterDesc = state.filterDesc;
        var stateWithClearedFilterName_filterIgnore = state.filterIgnore;
        var stateWithClearedFilterName_isUploadingTemplate = state.isUploadingTemplate;
        var stateWithClearedFilterName_sort = state.sort;
        var stateWithClearedFilterName = {
          displayChannels: stateWithClearedFilterName_displayChannels,
          filterDesc: stateWithClearedFilterName_filterDesc,
          filterName: "",
          filterIgnore: stateWithClearedFilterName_filterIgnore,
          isUploadingTemplate: stateWithClearedFilterName_isUploadingTemplate,
          sort: stateWithClearedFilterName_sort
        };
        var displayChannels$2 = Util$Astrolabe.$$Array.filterWithGetterCaseInsensitive(filterName, Channel$Astrolabe.name, filterAllByStateFilters(stateWithClearedFilterName, channels));
        return {
                displayChannels: displayChannels$2,
                filterDesc: state.filterDesc,
                filterName: filterName,
                filterIgnore: state.filterIgnore,
                isUploadingTemplate: state.isUploadingTemplate,
                sort: state.sort
              };
    case /* SetChannels */3 :
        var displayChannels$3 = filterAllByStateFilters(state, action[0]);
        return {
                displayChannels: displayChannels$3,
                filterDesc: state.filterDesc,
                filterName: state.filterName,
                filterIgnore: state.filterIgnore,
                isUploadingTemplate: state.isUploadingTemplate,
                sort: state.sort
              };
    case /* SetIsUploadingTemplate */4 :
        return {
                displayChannels: state.displayChannels,
                filterDesc: state.filterDesc,
                filterName: state.filterName,
                filterIgnore: state.filterIgnore,
                isUploadingTemplate: action[0],
                sort: state.sort
              };
    case /* SetSort */5 :
        var sort = action[0];
        var displayChannels$4 = sortChannels(sort, filterAllByStateFilters(state, channels));
        return {
                displayChannels: displayChannels$4,
                filterDesc: state.filterDesc,
                filterName: state.filterName,
                filterIgnore: state.filterIgnore,
                isUploadingTemplate: state.isUploadingTemplate,
                sort: sort
              };
    
  }
}

function ChannelTable(Props) {
  var isEditable = Props.isEditable;
  var experimentId = Props.experimentId;
  var channels = Props.channels;
  var isShowAnalysisColumn = Props.isShowAnalysisColumn;
  var onComplete = Props.onComplete;
  var initialState_sort = /* tuple */[
    "desc",
    /* ASC */0
  ];
  var initialState = {
    displayChannels: channels,
    filterDesc: "",
    filterName: "",
    filterIgnore: /* NoFilter */4,
    isUploadingTemplate: false,
    sort: initialState_sort
  };
  var match = React.useReducer((function (param, param$1) {
          return reducer(param, param$1, channels);
        }), initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* SetChannels */Block.__(3, [channels]));
          
        }), [channels]);
  var channelHeadersDisabled = React.createElement("th", {
        className: "text-center cursor-help"
      }, "Usage");
  var channelHeadersAdvancedDisabled = function (title) {
    var match = state.filterIgnore;
    return React.createElement("th", {
                className: "w-11",
                scope: "col"
              }, React.createElement("div", {
                    className: "text-center cursor-help",
                    title: title
                  }, "Ignore Channel"), match === 3 || match === 2 ? React.createElement("div", {
                      className: "text-primary text-center cursor-pointer",
                      onClick: (function (param) {
                          return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* NoFilter */4]));
                        })
                    }, "Reset") : React.createElement("div", {
                      className: "d-flex justify-content-center"
                    }, React.createElement("div", {
                          className: "text-primary cursor-pointer",
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* OnlyIgnore */2]));
                            })
                        }, "Only"), React.createElement("div", undefined, "/"), React.createElement("div", {
                          className: "text-primary cursor-pointer",
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* ExceptIgnore */3]));
                            })
                        }, "Except")));
  };
  var match$1 = state.filterIgnore;
  var match$2 = state.filterIgnore;
  var channelHeadersAdvancedEnabled = React.createElement(React.Fragment, undefined, channelHeadersAdvancedDisabled("This channel will be completely ignored"), React.createElement("th", {
            className: "text-center w-11"
          }, React.createElement("div", {
                className: "cursor-help",
                title: "This channel will only be included in visualizations"
              }, "Analysis Only"), match$1 !== 0 ? React.createElement("div", {
                  className: "text-primary cursor-pointer",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* OnlyAnalysis */0]));
                    })
                }, "Only") : React.createElement("div", {
                  className: "text-primary",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* NoFilter */4]));
                    })
                }, "Reset")), React.createElement("th", {
            className: "text-center cursor-help w-11"
          }, React.createElement("div", {
                className: "cursor-help",
                title: "This channel will be included in visualizations, cell subset labeling, and follow-up statistics"
              }, "Classification and Analysis"), match$2 !== 1 ? React.createElement("div", {
                  className: "text-primary cursor-pointer",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* OnlyClassification */1]));
                    })
                }, "Only") : React.createElement("div", {
                  className: "text-primary",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* FilterChannelUsage */Block.__(1, [/* NoFilter */4]));
                    })
                }, "Reset")));
  var channelsSubtitle = isEditable ? "Change the channel descriptions, if necessary, and indicate which channels should be used for the analysis." : "List of channels used in the analysis. This cannot be changed since the analysis is done.";
  var filterClassName = (
    isShowAnalysisColumn ? "col-8" : "col-6"
  ) + " form-control form-control-sm mr-2";
  var uploadButtonTooltip = isShowAnalysisColumn ? "Upload Analysis Only Column enabled spreadsheet template where you can fill in channel descriptions and usages using an editor" : "Upload Analysis Only Column disabled spreadsheet template where you can fill in channel descriptions and usage using an editor";
  var channelTemplateBox = React.createElement(DownloadUploadTemplateBox$Astrolabe.make, {
        downloadButtonTooltip: "Download a spreadsheet template where you can fill in channel descriptions and usage using an editor",
        downloadUrl: "/experiments/" + (experimentId + "/channel_usage_template.csv"),
        isDownloadButtonDisabled: false,
        onUploadBegin: (function (param) {
            Curry._1(dispatch, /* SetIsUploadingTemplate */Block.__(4, [true]));
            return Curry._5(onComplete, undefined, true, undefined, undefined, undefined);
          }),
        onUploadComplete: (function (errors, disableContinue, success) {
            Curry._1(dispatch, /* SetIsUploadingTemplate */Block.__(4, [false]));
            return Curry._5(onComplete, errors, disableContinue, undefined, success, undefined);
          }),
        text: "If you would like to define the channels using a spreadsheet, download a template using the 'Download Template' button. You can then upload the completed spreadsheet using the 'Upload Template' button. This will replace all existing channels except the Live/Dead channel.",
        uploadButtonTooltip: uploadButtonTooltip,
        uploadUrl: "/experiments/" + (experimentId + "/import_channel_usage_template")
      });
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "d-flex justify-content-between"
                }, React.createElement(TitleWithIcon$Astrolabe.make, {
                      className: "margin-new-title",
                      subtitle: channelsSubtitle,
                      title: "Channels",
                      children: React.createElement(Icons$Astrolabe.Bullets.make, {
                            className: "no-hover",
                            height: "60",
                            width: "60"
                          })
                    }), isEditable ? React.createElement(AnalysisColumnSettings$Astrolabe.make, {
                        experimentId: experimentId,
                        isShowAnalysisColumn: isShowAnalysisColumn
                      }) : null), isEditable ? channelTemplateBox : null, state.isUploadingTemplate ? React.createElement("div", undefined, "Uploading Template...") : React.createElement("div", {
                    className: "row justify-content-center"
                  }, React.createElement("table", {
                        className: "table table-sm table-striped table-hover"
                      }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(SortableTableHeader$Astrolabe.make, {
                                        columnName: "name",
                                        filter: React.createElement("input", {
                                              className: filterClassName,
                                              placeholder: "Filter Name",
                                              type: "text",
                                              value: state.filterName,
                                              onChange: (function (evt) {
                                                  return Curry._1(dispatch, /* FilterChannelNames */Block.__(2, [ReasonReactHelpers$Astrolabe.valueFromEvent(evt)]));
                                                })
                                            }),
                                        headerName: React.createElement("div", undefined, "Name"),
                                        handleOnClick: (function (sort) {
                                            return Curry._1(dispatch, /* SetSort */Block.__(5, [sort]));
                                          }),
                                        sortState: state.sort
                                      })), React.createElement("th", undefined, React.createElement(SortableTableHeader$Astrolabe.make, {
                                        columnName: "desc",
                                        filter: React.createElement("input", {
                                              className: filterClassName,
                                              placeholder: "Filter Desc",
                                              type: "text",
                                              value: state.filterDesc,
                                              onChange: (function (evt) {
                                                  return Curry._1(dispatch, /* FilterChannelDescs */Block.__(0, [ReasonReactHelpers$Astrolabe.valueFromEvent(evt)]));
                                                })
                                            }),
                                        headerName: React.createElement("div", undefined, "Description"),
                                        handleOnClick: (function (sort) {
                                            return Curry._1(dispatch, /* SetSort */Block.__(5, [sort]));
                                          }),
                                        sortState: state.sort
                                      })), isEditable ? (
                                  isShowAnalysisColumn ? channelHeadersAdvancedEnabled : channelHeadersAdvancedDisabled("If checked, this channel will not be used for clustering and visualization.")
                                ) : channelHeadersDisabled)), React.createElement("tbody", undefined, state.displayChannels.map((function (channel) {
                                  return React.createElement(ChannelRow$Astrolabe.make, {
                                              channel: channel,
                                              isEditable: isEditable,
                                              isShowAnalysisColumn: isShowAnalysisColumn,
                                              onSuccess: (function (disableContinue, shouldClearExperimentErrors) {
                                                  return Curry._5(onComplete, undefined, disableContinue, shouldClearExperimentErrors, undefined, undefined);
                                                }),
                                              key: channel.name
                                            });
                                }))))));
}

var $$Array;

var Sort;

var make = ChannelTable;

var $$default = ChannelTable;

exports.$$Array = $$Array;
exports.Sort = Sort;
exports.filterDisplayChannelsByIgnore = filterDisplayChannelsByIgnore;
exports.sortChannels = sortChannels;
exports.filterAllByStateFilters = filterAllByStateFilters;
exports.reducer = reducer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
