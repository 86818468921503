/* @flow */
"use strict";

import React from "react";
import type { Location } from "react-router";
import { Link, Redirect } from "react-router-dom";
import type { Session } from "models";
import { fetchJSON } from "components/utils";
import NavTitle from "../NavTitle";

type Props = {
  location: Location,
  onSignIn: (session: Session) => any,
  session: Session,
};

type State = {
  email: string,
  password: string,
  rememberMe: boolean,
  error: ?string,
  redirectToReferrer: boolean,
  showTermsOfAccess: boolean,
};

export default class SignIn extends React.Component<Props, State> {
  handleSignIn: (SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      rememberMe: true,
      error: null,
      redirectToReferrer: false,
      showTermsOfAccess: false,
    };
  }

  handleSignIn = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.signIn({
      email: this.state.email,
      password: this.state.password,
      remember_me: this.state.rememberMe,
    });
  };

  async signIn(params: Object) {
    this.setState({ error: null });
    var resp = await fetchJSON("POST", "/users/sign_in.json", { user: params });

    if (resp.error) {
      this.setState({ error: resp.error });
    } else {
      this.props.onSignIn(resp);
    }
  }

  render() {
    if (this.props.session.user || this.state.redirectToReferrer) {
      return <Redirect to={(this.props.location.state || {}).from || { pathname: "/" }} />;
    }

    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="Sign In" />
        <div className="row align-items-center justify-content-center">
          <div className="col" />
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          <div className="col" />
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col" />
          <div className="jumbotron p-4 col-xs-12 col-md-6 mb-2">
            <form className="form" method="post" action="/users/sign_in" onSubmit={this.handleSignIn}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  autoFocus={true}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>

              <div className="form-check form-group">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  checked={this.state.rememberMe}
                  onChange={(e) => this.setState({ rememberMe: e.target.checked })}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>

              <div className="form-group mb-0 d-flex align-items-center justify-content-between">
                <Link to="/forgot_password">Forgot password?</Link>
                <span>
                  <a
                    href=""
                    className="mr-3"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showTermsOfAccess: true });
                    }}
                  >
                    Terms of Access
                  </a>

                  <button className="btn btn-primary">Sign In</button>
                </span>
              </div>
            </form>
          </div>
          <div className="col" />
        </div>

        <div className="row align-items-center justify-content-center flex-column" hidden={!this.state.showTermsOfAccess}>
          <div className="col-md-6 d-flex align-items-center justify-content-between">
            <small className="col-md-10 pl-0">Unauthorized access to this network device is prohibited.</small>
            <h5 className="mb-0 cursor-pointer" onClick={() => this.setState({ showTermsOfAccess: false })}>
              &times;
            </h5>
          </div>
          <small className="col-md-6 font-weight-light text-muted">
            This system is for the use of authorized users only. Individuals using this computer system without authority, or in
            excess of their authority, are subject to having all of their activities on this system monitored and recorded by
            system personnel. In the course of monitoring individuals improperly using this system, or in the course of system
            maintenance, the activities of authorized users may also be monitored. Anyone using this system expressly consents to
            such monitoring and is advised that if such monitoring reveals possible evidence of criminal activity, system
            personnel may provide the evidence of such monitoring to law enforcement officials.
          </small>
        </div>
      </React.Fragment>
    );
  }
}
