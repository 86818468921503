/* @flow */
"use strict";

import React from "react";
import { schemeAccent } from "d3-scale-chromatic";
import { Box, VictoryAxis, VictoryBoxPlot, VictoryChart, VictoryContainer, VictoryGroup } from "victory";
import type { BoxPlotDatum } from "models";
import theme from "models/victoryTheme";

type Props = {
  data: Array<BoxPlotDatum>,
  dataLabels: Array<string>,
  domainPadding: int,
  maxChartValue: float,
  rightPadding: int,
  width: int,
  xAxisLabel?: string,
};

export default class FrequencyBoxPlot extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let data = this.props.data.map((d, i) => (d ? { x: i + 1, ...d } : { x: 0, ...d })).filter((d) => d.x > 0);
    const bottomPadding =
      13 *
        Math.max.apply(
          null,
          this.props.dataLabels.map((x) => x.length),
        ) +
      5;
    const height = 250 + bottomPadding;

    return (
      <VictoryChart
        theme={theme}
        width={this.props.width}
        height={height}
        domain={{ y: [0, this.props.maxChartValue] }}
        padding={{ left: 55, right: this.props.rightPadding, top: 10, bottom: bottomPadding }}
        domainPadding={{ x: this.props.domainPadding }}
        containerComponent={<VictoryContainer width={this.props.width} height={height} responsive={false} />}
      >
        <VictoryAxis style={{ ...theme.variants.axis.boxplot.style }} />
        <VictoryAxis
          crossAxis
          dependentAxis
          label="Frequency (%)"
          style={{ ...theme.variants.axis.matchingNivo.style, axis: { stroke: "transparent" } }}
        />
        <VictoryBoxPlot
          style={theme.boxplot.style}
          padding={0}
          categories={{ x: this.props.dataLabels }}
          data={data}
          minLabels={(d) => `${d.min.toFixed(1)}%`}
          q1Labels={(d) => `${d.q1.toFixed(1)}%`}
          medianLabels={(d) => `${d.median.toFixed(1)}%`}
          q3Labels={(d) => `${d.q3.toFixed(1)}%`}
          maxLabels={(d) => `${d.max.toFixed(1)}%`}
        />
      </VictoryChart>
    );
  }
}
