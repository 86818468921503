// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var ConfirmedButton$Astrolabe = require("./ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");

var UpdateExperimentEventMutation = ReasonApollo.CreateMutation({
      query: ExperimentGraphQL$Astrolabe.UpdateEventConfig.query,
      parse: ExperimentGraphQL$Astrolabe.UpdateEventConfig.parse
    });

function RewindAnalysisButton(Props) {
  var experimentId = Props.experimentId;
  return React.createElement(UpdateExperimentEventMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement(ConfirmedButton$Astrolabe.make, {
                              buttonTitle: "Rewind Analysis",
                              confirmationText: "REWIND",
                              isIcon: true,
                              icon: React.createElement(Icons$Astrolabe.Rewind.make, {
                                    className: "white no-hover",
                                    height: "23",
                                    width: "23"
                                  }),
                              modalButtonClassName: "btn-danger",
                              modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", {
                                        className: "text-danger"
                                      }, "You are going to rewind the experiment. All current analysis results will be lost. Once you analyze the experiment again, the new results will be available. This cannot be undone."), React.createElement("p", undefined, "Contact us at ", React.createElement("a", {
                                            href: "mailto:support@astrolabediagnostics.com"
                                          }, "support@astrolabediagnostics.com"), " with any questions."), React.createElement("p", undefined, "Are you sure you want to rewind this experiment?")),
                              onConfirm: (function (complete) {
                                  ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "rewind_analysis", undefined, undefined);
                                  return Curry._1(complete, undefined);
                                }),
                              title: "Rewind Analysis"
                            });
                })
            });
}

var make = RewindAnalysisButton;

var $$default = RewindAnalysisButton;

exports.UpdateExperimentEventMutation = UpdateExperimentEventMutation;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* UpdateExperimentEventMutation Not a pure module */
