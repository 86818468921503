// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Util$Astrolabe = require("../../models/Util.bs.js");

function DisplayErrors(Props) {
  var errors = Props.errors;
  return React.createElement("div", {
              className: (
                Util$Astrolabe.$$Array.isEmpty(errors) ? "d-none " : "d-block "
              ) + "invalid-feedback"
            }, errors.map((function (error, i) {
                    return React.createElement("div", {
                                key: String(i) + "error"
                              }, error);
                  })));
}

var $$Array;

var make = DisplayErrors;

exports.$$Array = $$Array;
exports.make = make;
/* react Not a pure module */
