// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Sample$Astrolabe = require("../../models/Sample.bs.js");
var Channel$Astrolabe = require("../../models/Channel.bs.js");
var Feature$Astrolabe = require("../../models/Feature.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var Analyses$Astrolabe = require("./Analyses.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var ErrorsAlert$Astrolabe = require("../ErrorsAlert.bs.js");
var ChannelTable$Astrolabe = require("./channels/ChannelTable.bs.js");
var FeaturesTable$Astrolabe = require("./features/FeaturesTable.bs.js");
var ChannelMapping$Astrolabe = require("./debarcoding/ChannelMapping.bs.js");
var DebarcodingKits$Astrolabe = require("./debarcoding/DebarcodingKits.bs.js");
var ExperimentHeader$Astrolabe = require("../header/ExperimentHeader.bs.js");
var GraphQLFragments$Astrolabe = require("../../graphql/GraphQLFragments.bs.js");
var ExperimentSettings$Astrolabe = require("../setup/ExperimentSettings.bs.js");

var ppx_printed_query = "query getExperiment($id: ID!)  {\ndebarcodingKits  {\n..." + GraphQLFragments$Astrolabe.DebarcodingKitFragment.DebarcodingKit.name + "   \n}\n\nexperiment(id: $id)  {\nid  \ncanEditKits  \ncanEditChannelMapping  \ncanEditAnalyses  \ncreatedAt  \ncreateUserEmail  \ncustomLabelingHierarchyError  \ninstrument  \nisEditable  \nisIr191Or193NotIgnored  \nisShowAnalysisColumn  \nhasBeenAnalyzed  \nhasFeatures  \nname  \norganism  \nstatus  \ntissueType  \nisShowAnalysisColumn  \nisEditable  \nisPublic  \nhasDeAnalysis  \nchannels  {\nid  \ndesc  \nname  \nusage  \n}\n\ncustomLabelingHierarchy  {\nid  \nname  \n}\n\ndeAnalysisKits  {\nid  \nname  \nposition  \nprimaryFeature  {\nid  \nname  \nvalues  \n}\n\nprimaryFeatureBaselineValue  \nreferenceFeature  {\nid  \nname  \nvalues  \n}\n\nreferenceFeatureBaselineValue  \ndependentFeatures  {\nid  \nname  \nvalues  \n}\n\nmarkerChannels  {\nid  \nname  \ndesc  \nusage  \n}\n\nmarkerStatistic  \n}\n\ndebarcodingKits  {\n..." + GraphQLFragments$Astrolabe.DebarcodingKitFragment.DebarcodingKit.name + "   \n}\n\nliveDeadChannel  {\nid  \nname  \n}\n\nchannelsForLiveDeadSelection  {\nid  \nname  \n}\n\nfeatures  {\nid  \nname  \nvalues  \nisAllowNa  \n}\n\nsamples  {\nid  \nname  \nfileFileName  \nisIgnoredFromDebarcoding  \nbarcodingChannels  \nsourceSampleFileName  \nfeatureValues  {\nid  \nvalue  \nfeatureId  \nsampleId  \n}\n\n}\n\n}\n\n}\n" + GraphQLFragments$Astrolabe.DebarcodingKitFragment.DebarcodingKit.query;

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "debarcodingKits");
  var value$3 = Js_dict.get(value$1, "experiment");
  var tmp;
  if (value$3 !== undefined) {
    var value$4 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$3)));
    var value$5 = Js_dict.get(value$4, "id");
    var tmp$1;
    if (value$5 !== undefined) {
      var value$6 = Caml_option.valFromOption(value$5);
      var value$7 = Js_json.decodeString(value$6);
      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Experiment is missing");
    }
    var value$8 = Js_dict.get(value$4, "canEditKits");
    var tmp$2;
    if (value$8 !== undefined) {
      var value$9 = Caml_option.valFromOption(value$8);
      var value$10 = Js_json.decodeBoolean(value$9);
      tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$9));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field canEditKits on type Experiment is missing");
    }
    var value$11 = Js_dict.get(value$4, "canEditChannelMapping");
    var tmp$3;
    if (value$11 !== undefined) {
      var value$12 = Caml_option.valFromOption(value$11);
      var value$13 = Js_json.decodeBoolean(value$12);
      tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$12));
    } else {
      tmp$3 = Js_exn.raiseError("graphql_ppx: Field canEditChannelMapping on type Experiment is missing");
    }
    var value$14 = Js_dict.get(value$4, "canEditAnalyses");
    var tmp$4;
    if (value$14 !== undefined) {
      var value$15 = Caml_option.valFromOption(value$14);
      var value$16 = Js_json.decodeBoolean(value$15);
      tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$15));
    } else {
      tmp$4 = Js_exn.raiseError("graphql_ppx: Field canEditAnalyses on type Experiment is missing");
    }
    var value$17 = Js_dict.get(value$4, "createdAt");
    var tmp$5;
    if (value$17 !== undefined) {
      var value$18 = Caml_option.valFromOption(value$17);
      var value$19 = Js_json.decodeString(value$18);
      tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
    } else {
      tmp$5 = Js_exn.raiseError("graphql_ppx: Field createdAt on type Experiment is missing");
    }
    var value$20 = Js_dict.get(value$4, "createUserEmail");
    var tmp$6;
    if (value$20 !== undefined) {
      var value$21 = Caml_option.valFromOption(value$20);
      var value$22 = Js_json.decodeString(value$21);
      tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
    } else {
      tmp$6 = Js_exn.raiseError("graphql_ppx: Field createUserEmail on type Experiment is missing");
    }
    var value$23 = Js_dict.get(value$4, "customLabelingHierarchyError");
    var tmp$7;
    if (value$23 !== undefined) {
      var value$24 = Caml_option.valFromOption(value$23);
      var match = Js_json.decodeNull(value$24);
      if (match !== undefined) {
        tmp$7 = undefined;
      } else {
        var value$25 = Js_json.decodeString(value$24);
        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
      }
    } else {
      tmp$7 = undefined;
    }
    var value$26 = Js_dict.get(value$4, "instrument");
    var tmp$8;
    if (value$26 !== undefined) {
      var value$27 = Caml_option.valFromOption(value$26);
      var value$28 = Js_json.decodeString(value$27);
      if (value$28 !== undefined) {
        switch (value$28) {
          case "AURORA" :
              tmp$8 = /* AURORA */615611296;
              break;
          case "CITESEQ" :
              tmp$8 = /* CITESEQ */498013768;
              break;
          case "FLOW_CYTOMETRY" :
              tmp$8 = /* FLOW_CYTOMETRY */947968673;
              break;
          case "MASS_CYTOMETRY" :
              tmp$8 = /* MASS_CYTOMETRY */259835687;
              break;
          default:
            tmp$8 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Instrument: " + value$28);
        }
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Expected enum value for Instrument, got " + JSON.stringify(value$27));
      }
    } else {
      tmp$8 = Js_exn.raiseError("graphql_ppx: Field instrument on type Experiment is missing");
    }
    var value$29 = Js_dict.get(value$4, "isEditable");
    var tmp$9;
    if (value$29 !== undefined) {
      var value$30 = Caml_option.valFromOption(value$29);
      var value$31 = Js_json.decodeBoolean(value$30);
      tmp$9 = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$30));
    } else {
      tmp$9 = Js_exn.raiseError("graphql_ppx: Field isEditable on type Experiment is missing");
    }
    var value$32 = Js_dict.get(value$4, "isIr191Or193NotIgnored");
    var tmp$10;
    if (value$32 !== undefined) {
      var value$33 = Caml_option.valFromOption(value$32);
      var value$34 = Js_json.decodeBoolean(value$33);
      tmp$10 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$33));
    } else {
      tmp$10 = Js_exn.raiseError("graphql_ppx: Field isIr191Or193NotIgnored on type Experiment is missing");
    }
    var value$35 = Js_dict.get(value$4, "isShowAnalysisColumn");
    var tmp$11;
    if (value$35 !== undefined) {
      var value$36 = Caml_option.valFromOption(value$35);
      var value$37 = Js_json.decodeBoolean(value$36);
      tmp$11 = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$36));
    } else {
      tmp$11 = Js_exn.raiseError("graphql_ppx: Field isShowAnalysisColumn on type Experiment is missing");
    }
    var value$38 = Js_dict.get(value$4, "hasBeenAnalyzed");
    var tmp$12;
    if (value$38 !== undefined) {
      var value$39 = Caml_option.valFromOption(value$38);
      var value$40 = Js_json.decodeBoolean(value$39);
      tmp$12 = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$39));
    } else {
      tmp$12 = Js_exn.raiseError("graphql_ppx: Field hasBeenAnalyzed on type Experiment is missing");
    }
    var value$41 = Js_dict.get(value$4, "hasFeatures");
    var tmp$13;
    if (value$41 !== undefined) {
      var value$42 = Caml_option.valFromOption(value$41);
      var value$43 = Js_json.decodeBoolean(value$42);
      tmp$13 = value$43 !== undefined ? value$43 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$42));
    } else {
      tmp$13 = Js_exn.raiseError("graphql_ppx: Field hasFeatures on type Experiment is missing");
    }
    var value$44 = Js_dict.get(value$4, "name");
    var tmp$14;
    if (value$44 !== undefined) {
      var value$45 = Caml_option.valFromOption(value$44);
      var value$46 = Js_json.decodeString(value$45);
      tmp$14 = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$45));
    } else {
      tmp$14 = Js_exn.raiseError("graphql_ppx: Field name on type Experiment is missing");
    }
    var value$47 = Js_dict.get(value$4, "organism");
    var tmp$15;
    if (value$47 !== undefined) {
      var value$48 = Caml_option.valFromOption(value$47);
      var value$49 = Js_json.decodeString(value$48);
      if (value$49 !== undefined) {
        switch (value$49) {
          case "HUMAN" :
              tmp$15 = /* HUMAN */759401869;
              break;
          case "MOUSE" :
              tmp$15 = /* MOUSE */173231621;
              break;
          case "PROFILING_ONLY" :
              tmp$15 = /* PROFILING_ONLY */-278559483;
              break;
          default:
            tmp$15 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Organism: " + value$49);
        }
      } else {
        tmp$15 = Js_exn.raiseError("graphql_ppx: Expected enum value for Organism, got " + JSON.stringify(value$48));
      }
    } else {
      tmp$15 = Js_exn.raiseError("graphql_ppx: Field organism on type Experiment is missing");
    }
    var value$50 = Js_dict.get(value$4, "status");
    var tmp$16;
    if (value$50 !== undefined) {
      var value$51 = Caml_option.valFromOption(value$50);
      var value$52 = Js_json.decodeString(value$51);
      if (value$52 !== undefined) {
        switch (value$52) {
          case "ADDING_DEBARCODING_KITS" :
              tmp$16 = /* ADDING_DEBARCODING_KITS */889637598;
              break;
          case "ANALYZING" :
              tmp$16 = /* ANALYZING */-384073879;
              break;
          case "CREATED" :
              tmp$16 = /* CREATED */289311176;
              break;
          case "DEBARCODING" :
              tmp$16 = /* DEBARCODING */1020136956;
              break;
          case "DONE" :
              tmp$16 = /* DONE */758036610;
              break;
          case "MAPPING_BARCODING_CHANNELS" :
              tmp$16 = /* MAPPING_BARCODING_CHANNELS */-408660381;
              break;
          case "PENDING_ANALYSIS" :
              tmp$16 = /* PENDING_ANALYSIS */175814564;
              break;
          case "PREPROCESSING" :
              tmp$16 = /* PREPROCESSING */-525726826;
              break;
          default:
            tmp$16 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Status: " + value$52);
        }
      } else {
        tmp$16 = Js_exn.raiseError("graphql_ppx: Expected enum value for Status, got " + JSON.stringify(value$51));
      }
    } else {
      tmp$16 = Js_exn.raiseError("graphql_ppx: Field status on type Experiment is missing");
    }
    var value$53 = Js_dict.get(value$4, "tissueType");
    var tmp$17;
    if (value$53 !== undefined) {
      var value$54 = Caml_option.valFromOption(value$53);
      var value$55 = Js_json.decodeString(value$54);
      if (value$55 !== undefined) {
        switch (value$55) {
          case "MIXED_TISSUE" :
              tmp$17 = /* MIXED_TISSUE */-270592759;
              break;
          case "NA" :
              tmp$17 = /* NA */17459;
              break;
          case "PBMC" :
              tmp$17 = /* PBMC */890464712;
              break;
          case "WHOLE_BLOOD" :
              tmp$17 = /* WHOLE_BLOOD */-669224142;
              break;
          default:
            tmp$17 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Tissue: " + value$55);
        }
      } else {
        tmp$17 = Js_exn.raiseError("graphql_ppx: Expected enum value for Tissue, got " + JSON.stringify(value$54));
      }
    } else {
      tmp$17 = Js_exn.raiseError("graphql_ppx: Field tissueType on type Experiment is missing");
    }
    var value$56 = Js_dict.get(value$4, "isShowAnalysisColumn");
    var tmp$18;
    if (value$56 !== undefined) {
      var value$57 = Caml_option.valFromOption(value$56);
      var value$58 = Js_json.decodeBoolean(value$57);
      tmp$18 = value$58 !== undefined ? value$58 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$57));
    } else {
      tmp$18 = Js_exn.raiseError("graphql_ppx: Field isShowAnalysisColumn on type Experiment is missing");
    }
    var value$59 = Js_dict.get(value$4, "isEditable");
    var tmp$19;
    if (value$59 !== undefined) {
      var value$60 = Caml_option.valFromOption(value$59);
      var value$61 = Js_json.decodeBoolean(value$60);
      tmp$19 = value$61 !== undefined ? value$61 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$60));
    } else {
      tmp$19 = Js_exn.raiseError("graphql_ppx: Field isEditable on type Experiment is missing");
    }
    var value$62 = Js_dict.get(value$4, "isPublic");
    var tmp$20;
    if (value$62 !== undefined) {
      var value$63 = Caml_option.valFromOption(value$62);
      var value$64 = Js_json.decodeBoolean(value$63);
      tmp$20 = value$64 !== undefined ? value$64 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$63));
    } else {
      tmp$20 = Js_exn.raiseError("graphql_ppx: Field isPublic on type Experiment is missing");
    }
    var value$65 = Js_dict.get(value$4, "hasDeAnalysis");
    var tmp$21;
    if (value$65 !== undefined) {
      var value$66 = Caml_option.valFromOption(value$65);
      var value$67 = Js_json.decodeBoolean(value$66);
      tmp$21 = value$67 !== undefined ? value$67 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$66));
    } else {
      tmp$21 = Js_exn.raiseError("graphql_ppx: Field hasDeAnalysis on type Experiment is missing");
    }
    var value$68 = Js_dict.get(value$4, "channels");
    var value$69 = Js_dict.get(value$4, "customLabelingHierarchy");
    var tmp$22;
    if (value$69 !== undefined) {
      var value$70 = Caml_option.valFromOption(value$69);
      var match$1 = Js_json.decodeNull(value$70);
      if (match$1 !== undefined) {
        tmp$22 = undefined;
      } else {
        var value$71 = Js_option.getExn(Js_json.decodeObject(value$70));
        var value$72 = Js_dict.get(value$71, "id");
        var tmp$23;
        if (value$72 !== undefined) {
          var value$73 = Caml_option.valFromOption(value$72);
          var value$74 = Js_json.decodeString(value$73);
          tmp$23 = value$74 !== undefined ? value$74 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$73));
        } else {
          tmp$23 = Js_exn.raiseError("graphql_ppx: Field id on type CustomLabelingHierarchy is missing");
        }
        var value$75 = Js_dict.get(value$71, "name");
        var tmp$24;
        if (value$75 !== undefined) {
          var value$76 = Caml_option.valFromOption(value$75);
          var value$77 = Js_json.decodeString(value$76);
          tmp$24 = value$77 !== undefined ? value$77 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$76));
        } else {
          tmp$24 = Js_exn.raiseError("graphql_ppx: Field name on type CustomLabelingHierarchy is missing");
        }
        tmp$22 = {
          id: tmp$23,
          name: tmp$24
        };
      }
    } else {
      tmp$22 = undefined;
    }
    var value$78 = Js_dict.get(value$4, "deAnalysisKits");
    var value$79 = Js_dict.get(value$4, "debarcodingKits");
    var value$80 = Js_dict.get(value$4, "liveDeadChannel");
    var tmp$25;
    if (value$80 !== undefined) {
      var value$81 = Caml_option.valFromOption(value$80);
      var match$2 = Js_json.decodeNull(value$81);
      if (match$2 !== undefined) {
        tmp$25 = undefined;
      } else {
        var value$82 = Js_option.getExn(Js_json.decodeObject(value$81));
        var value$83 = Js_dict.get(value$82, "id");
        var tmp$26;
        if (value$83 !== undefined) {
          var value$84 = Caml_option.valFromOption(value$83);
          var value$85 = Js_json.decodeString(value$84);
          tmp$26 = value$85 !== undefined ? value$85 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$84));
        } else {
          tmp$26 = Js_exn.raiseError("graphql_ppx: Field id on type Channel is missing");
        }
        var value$86 = Js_dict.get(value$82, "name");
        var tmp$27;
        if (value$86 !== undefined) {
          var value$87 = Caml_option.valFromOption(value$86);
          var value$88 = Js_json.decodeString(value$87);
          tmp$27 = value$88 !== undefined ? value$88 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$87));
        } else {
          tmp$27 = Js_exn.raiseError("graphql_ppx: Field name on type Channel is missing");
        }
        tmp$25 = {
          id: tmp$26,
          name: tmp$27
        };
      }
    } else {
      tmp$25 = undefined;
    }
    var value$89 = Js_dict.get(value$4, "channelsForLiveDeadSelection");
    var value$90 = Js_dict.get(value$4, "features");
    var value$91 = Js_dict.get(value$4, "samples");
    tmp = {
      id: tmp$1,
      canEditKits: tmp$2,
      canEditChannelMapping: tmp$3,
      canEditAnalyses: tmp$4,
      createdAt: tmp$5,
      createUserEmail: tmp$6,
      customLabelingHierarchyError: tmp$7,
      instrument: tmp$8,
      isEditable: tmp$9,
      isIr191Or193NotIgnored: tmp$10,
      isShowAnalysisColumn: tmp$11,
      hasBeenAnalyzed: tmp$12,
      hasFeatures: tmp$13,
      name: tmp$14,
      organism: tmp$15,
      status: tmp$16,
      tissueType: tmp$17,
      isShowAnalysisColumn: tmp$18,
      isEditable: tmp$19,
      isPublic: tmp$20,
      hasDeAnalysis: tmp$21,
      channels: value$68 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$68))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type Channel is missing");
                }
                var value$5 = Js_dict.get(value$1, "desc");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field desc on type Channel is missing");
                }
                var value$8 = Js_dict.get(value$1, "name");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var value$10 = Js_json.decodeString(value$9);
                  tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type Channel is missing");
                }
                var value$11 = Js_dict.get(value$1, "usage");
                var tmp$3;
                if (value$11 !== undefined) {
                  var value$12 = Caml_option.valFromOption(value$11);
                  var value$13 = Js_json.decodeString(value$12);
                  if (value$13 !== undefined) {
                    switch (value$13) {
                      case "ANALYSIS" :
                          tmp$3 = /* ANALYSIS */739437404;
                          break;
                      case "CLASSIFICATION" :
                          tmp$3 = /* CLASSIFICATION */257034022;
                          break;
                      case "IGNORED" :
                          tmp$3 = /* IGNORED */-592639246;
                          break;
                      case "LIVE_DEAD" :
                          tmp$3 = /* LIVE_DEAD */-568609769;
                          break;
                      default:
                        tmp$3 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Usage: " + value$13);
                    }
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Expected enum value for Usage, got " + JSON.stringify(value$12));
                  }
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field usage on type Channel is missing");
                }
                return {
                        id: tmp,
                        desc: tmp$1,
                        name: tmp$2,
                        usage: tmp$3
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field channels on type Experiment is missing"),
      customLabelingHierarchy: tmp$22,
      deAnalysisKits: value$78 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$78))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type DEAnalysisKit is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type DEAnalysisKit is missing");
                }
                var value$8 = Js_dict.get(value$1, "position");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var value$10 = Js_json.decodeNumber(value$9);
                  tmp$2 = value$10 !== undefined ? value$10 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$9));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field position on type DEAnalysisKit is missing");
                }
                var value$11 = Js_dict.get(value$1, "primaryFeature");
                var tmp$3;
                if (value$11 !== undefined) {
                  var value$12 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$11)));
                  var value$13 = Js_dict.get(value$12, "id");
                  var tmp$4;
                  if (value$13 !== undefined) {
                    var value$14 = Caml_option.valFromOption(value$13);
                    var value$15 = Js_json.decodeString(value$14);
                    tmp$4 = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$14));
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field id on type Feature is missing");
                  }
                  var value$16 = Js_dict.get(value$12, "name");
                  var tmp$5;
                  if (value$16 !== undefined) {
                    var value$17 = Caml_option.valFromOption(value$16);
                    var value$18 = Js_json.decodeString(value$17);
                    tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                  } else {
                    tmp$5 = Js_exn.raiseError("graphql_ppx: Field name on type Feature is missing");
                  }
                  var value$19 = Js_dict.get(value$12, "values");
                  tmp$3 = {
                    id: tmp$4,
                    name: tmp$5,
                    values: value$19 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$19))).map((function (value) {
                              var value$1 = Js_json.decodeString(value);
                              if (value$1 !== undefined) {
                                return value$1;
                              } else {
                                return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                              }
                            })) : Js_exn.raiseError("graphql_ppx: Field values on type Feature is missing")
                  };
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field primaryFeature on type DEAnalysisKit is missing");
                }
                var value$20 = Js_dict.get(value$1, "primaryFeatureBaselineValue");
                var tmp$6;
                if (value$20 !== undefined) {
                  var value$21 = Caml_option.valFromOption(value$20);
                  var value$22 = Js_json.decodeString(value$21);
                  tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
                } else {
                  tmp$6 = Js_exn.raiseError("graphql_ppx: Field primaryFeatureBaselineValue on type DEAnalysisKit is missing");
                }
                var value$23 = Js_dict.get(value$1, "referenceFeature");
                var tmp$7;
                if (value$23 !== undefined) {
                  var value$24 = Caml_option.valFromOption(value$23);
                  var match = Js_json.decodeNull(value$24);
                  if (match !== undefined) {
                    tmp$7 = undefined;
                  } else {
                    var value$25 = Js_option.getExn(Js_json.decodeObject(value$24));
                    var value$26 = Js_dict.get(value$25, "id");
                    var tmp$8;
                    if (value$26 !== undefined) {
                      var value$27 = Caml_option.valFromOption(value$26);
                      var value$28 = Js_json.decodeString(value$27);
                      tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
                    } else {
                      tmp$8 = Js_exn.raiseError("graphql_ppx: Field id on type Feature is missing");
                    }
                    var value$29 = Js_dict.get(value$25, "name");
                    var tmp$9;
                    if (value$29 !== undefined) {
                      var value$30 = Caml_option.valFromOption(value$29);
                      var value$31 = Js_json.decodeString(value$30);
                      tmp$9 = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$30));
                    } else {
                      tmp$9 = Js_exn.raiseError("graphql_ppx: Field name on type Feature is missing");
                    }
                    var value$32 = Js_dict.get(value$25, "values");
                    tmp$7 = {
                      id: tmp$8,
                      name: tmp$9,
                      values: value$32 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$32))).map((function (value) {
                                var value$1 = Js_json.decodeString(value);
                                if (value$1 !== undefined) {
                                  return value$1;
                                } else {
                                  return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                                }
                              })) : Js_exn.raiseError("graphql_ppx: Field values on type Feature is missing")
                    };
                  }
                } else {
                  tmp$7 = undefined;
                }
                var value$33 = Js_dict.get(value$1, "referenceFeatureBaselineValue");
                var tmp$10;
                if (value$33 !== undefined) {
                  var value$34 = Caml_option.valFromOption(value$33);
                  var match$1 = Js_json.decodeNull(value$34);
                  if (match$1 !== undefined) {
                    tmp$10 = undefined;
                  } else {
                    var value$35 = Js_json.decodeString(value$34);
                    tmp$10 = value$35 !== undefined ? value$35 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$34));
                  }
                } else {
                  tmp$10 = undefined;
                }
                var value$36 = Js_dict.get(value$1, "dependentFeatures");
                var value$37 = Js_dict.get(value$1, "markerChannels");
                var value$38 = Js_dict.get(value$1, "markerStatistic");
                var tmp$11;
                if (value$38 !== undefined) {
                  var value$39 = Caml_option.valFromOption(value$38);
                  var value$40 = Js_json.decodeString(value$39);
                  if (value$40 !== undefined) {
                    switch (value$40) {
                      case "MEDIAN" :
                          tmp$11 = /* MEDIAN */826994442;
                          break;
                      case "PERCENT_POSITIVE" :
                          tmp$11 = /* PERCENT_POSITIVE */78066387;
                          break;
                      case "Q95" :
                          tmp$11 = /* Q95 */4040813;
                          break;
                      default:
                        tmp$11 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for MarkerStatistic: " + value$40);
                    }
                  } else {
                    tmp$11 = Js_exn.raiseError("graphql_ppx: Expected enum value for MarkerStatistic, got " + JSON.stringify(value$39));
                  }
                } else {
                  tmp$11 = Js_exn.raiseError("graphql_ppx: Field markerStatistic on type DEAnalysisKit is missing");
                }
                return {
                        id: tmp,
                        name: tmp$1,
                        position: tmp$2,
                        primaryFeature: tmp$3,
                        primaryFeatureBaselineValue: tmp$6,
                        referenceFeature: tmp$7,
                        referenceFeatureBaselineValue: tmp$10,
                        dependentFeatures: value$36 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$36))).map((function (value) {
                                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                                  var value$2 = Js_dict.get(value$1, "id");
                                  var tmp;
                                  if (value$2 !== undefined) {
                                    var value$3 = Caml_option.valFromOption(value$2);
                                    var value$4 = Js_json.decodeString(value$3);
                                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                                  } else {
                                    tmp = Js_exn.raiseError("graphql_ppx: Field id on type Feature is missing");
                                  }
                                  var value$5 = Js_dict.get(value$1, "name");
                                  var tmp$1;
                                  if (value$5 !== undefined) {
                                    var value$6 = Caml_option.valFromOption(value$5);
                                    var value$7 = Js_json.decodeString(value$6);
                                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                                  } else {
                                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type Feature is missing");
                                  }
                                  var value$8 = Js_dict.get(value$1, "values");
                                  return {
                                          id: tmp,
                                          name: tmp$1,
                                          values: value$8 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$8))).map((function (value) {
                                                    var value$1 = Js_json.decodeString(value);
                                                    if (value$1 !== undefined) {
                                                      return value$1;
                                                    } else {
                                                      return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                                                    }
                                                  })) : Js_exn.raiseError("graphql_ppx: Field values on type Feature is missing")
                                        };
                                })) : Js_exn.raiseError("graphql_ppx: Field dependentFeatures on type DEAnalysisKit is missing"),
                        markerChannels: value$37 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$37))).map((function (value) {
                                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                                  var value$2 = Js_dict.get(value$1, "id");
                                  var tmp;
                                  if (value$2 !== undefined) {
                                    var value$3 = Caml_option.valFromOption(value$2);
                                    var value$4 = Js_json.decodeString(value$3);
                                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                                  } else {
                                    tmp = Js_exn.raiseError("graphql_ppx: Field id on type Channel is missing");
                                  }
                                  var value$5 = Js_dict.get(value$1, "name");
                                  var tmp$1;
                                  if (value$5 !== undefined) {
                                    var value$6 = Caml_option.valFromOption(value$5);
                                    var value$7 = Js_json.decodeString(value$6);
                                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                                  } else {
                                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type Channel is missing");
                                  }
                                  var value$8 = Js_dict.get(value$1, "desc");
                                  var tmp$2;
                                  if (value$8 !== undefined) {
                                    var value$9 = Caml_option.valFromOption(value$8);
                                    var value$10 = Js_json.decodeString(value$9);
                                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                                  } else {
                                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field desc on type Channel is missing");
                                  }
                                  var value$11 = Js_dict.get(value$1, "usage");
                                  var tmp$3;
                                  if (value$11 !== undefined) {
                                    var value$12 = Caml_option.valFromOption(value$11);
                                    var value$13 = Js_json.decodeString(value$12);
                                    if (value$13 !== undefined) {
                                      switch (value$13) {
                                        case "ANALYSIS" :
                                            tmp$3 = /* ANALYSIS */739437404;
                                            break;
                                        case "CLASSIFICATION" :
                                            tmp$3 = /* CLASSIFICATION */257034022;
                                            break;
                                        case "IGNORED" :
                                            tmp$3 = /* IGNORED */-592639246;
                                            break;
                                        case "LIVE_DEAD" :
                                            tmp$3 = /* LIVE_DEAD */-568609769;
                                            break;
                                        default:
                                          tmp$3 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Usage: " + value$13);
                                      }
                                    } else {
                                      tmp$3 = Js_exn.raiseError("graphql_ppx: Expected enum value for Usage, got " + JSON.stringify(value$12));
                                    }
                                  } else {
                                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field usage on type Channel is missing");
                                  }
                                  return {
                                          id: tmp,
                                          name: tmp$1,
                                          desc: tmp$2,
                                          usage: tmp$3
                                        };
                                })) : Js_exn.raiseError("graphql_ppx: Field markerChannels on type DEAnalysisKit is missing"),
                        markerStatistic: tmp$11
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field deAnalysisKits on type Experiment is missing"),
      debarcodingKits: value$79 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$79))).map((function (value) {
                return Curry._1(GraphQLFragments$Astrolabe.DebarcodingKitFragment.DebarcodingKit.parse, value);
              })) : Js_exn.raiseError("graphql_ppx: Field debarcodingKits on type Experiment is missing"),
      liveDeadChannel: tmp$25,
      channelsForLiveDeadSelection: value$89 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$89))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type Channel is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type Channel is missing");
                }
                return {
                        id: tmp,
                        name: tmp$1
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field channelsForLiveDeadSelection on type Experiment is missing"),
      features: value$90 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$90))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type Feature is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type Feature is missing");
                }
                var value$8 = Js_dict.get(value$1, "values");
                var value$9 = Js_dict.get(value$1, "isAllowNa");
                var tmp$2;
                if (value$9 !== undefined) {
                  var value$10 = Caml_option.valFromOption(value$9);
                  var value$11 = Js_json.decodeBoolean(value$10);
                  tmp$2 = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$10));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field isAllowNa on type Feature is missing");
                }
                return {
                        id: tmp,
                        name: tmp$1,
                        values: value$8 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$8))).map((function (value) {
                                  var value$1 = Js_json.decodeString(value);
                                  if (value$1 !== undefined) {
                                    return value$1;
                                  } else {
                                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                                  }
                                })) : Js_exn.raiseError("graphql_ppx: Field values on type Feature is missing"),
                        isAllowNa: tmp$2
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field features on type Experiment is missing"),
      samples: value$91 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$91))).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type Sample is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type Sample is missing");
                }
                var value$8 = Js_dict.get(value$1, "fileFileName");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var value$10 = Js_json.decodeString(value$9);
                  tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field fileFileName on type Sample is missing");
                }
                var value$11 = Js_dict.get(value$1, "isIgnoredFromDebarcoding");
                var tmp$3;
                if (value$11 !== undefined) {
                  var value$12 = Caml_option.valFromOption(value$11);
                  var value$13 = Js_json.decodeBoolean(value$12);
                  tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$12));
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field isIgnoredFromDebarcoding on type Sample is missing");
                }
                var value$14 = Js_dict.get(value$1, "barcodingChannels");
                var value$15 = Js_dict.get(value$1, "sourceSampleFileName");
                var tmp$4;
                if (value$15 !== undefined) {
                  var value$16 = Caml_option.valFromOption(value$15);
                  var match = Js_json.decodeNull(value$16);
                  if (match !== undefined) {
                    tmp$4 = undefined;
                  } else {
                    var value$17 = Js_json.decodeString(value$16);
                    tmp$4 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
                  }
                } else {
                  tmp$4 = undefined;
                }
                var value$18 = Js_dict.get(value$1, "featureValues");
                var tmp$5;
                if (value$18 !== undefined) {
                  var value$19 = Caml_option.valFromOption(value$18);
                  var match$1 = Js_json.decodeNull(value$19);
                  tmp$5 = match$1 !== undefined ? undefined : Js_option.getExn(Js_json.decodeArray(value$19)).map((function (value) {
                            var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                            var value$2 = Js_dict.get(value$1, "id");
                            var tmp;
                            if (value$2 !== undefined) {
                              var value$3 = Caml_option.valFromOption(value$2);
                              var value$4 = Js_json.decodeString(value$3);
                              tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                            } else {
                              tmp = Js_exn.raiseError("graphql_ppx: Field id on type SampleFeatureValue is missing");
                            }
                            var value$5 = Js_dict.get(value$1, "value");
                            var tmp$1;
                            if (value$5 !== undefined) {
                              var value$6 = Caml_option.valFromOption(value$5);
                              var value$7 = Js_json.decodeString(value$6);
                              tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                            } else {
                              tmp$1 = Js_exn.raiseError("graphql_ppx: Field value on type SampleFeatureValue is missing");
                            }
                            var value$8 = Js_dict.get(value$1, "featureId");
                            var tmp$2;
                            if (value$8 !== undefined) {
                              var value$9 = Caml_option.valFromOption(value$8);
                              var value$10 = Js_json.decodeString(value$9);
                              tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                            } else {
                              tmp$2 = Js_exn.raiseError("graphql_ppx: Field featureId on type SampleFeatureValue is missing");
                            }
                            var value$11 = Js_dict.get(value$1, "sampleId");
                            var tmp$3;
                            if (value$11 !== undefined) {
                              var value$12 = Caml_option.valFromOption(value$11);
                              var value$13 = Js_json.decodeString(value$12);
                              tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                            } else {
                              tmp$3 = Js_exn.raiseError("graphql_ppx: Field sampleId on type SampleFeatureValue is missing");
                            }
                            return {
                                    id: tmp,
                                    value: tmp$1,
                                    featureId: tmp$2,
                                    sampleId: tmp$3
                                  };
                          }));
                } else {
                  tmp$5 = undefined;
                }
                return {
                        id: tmp,
                        name: tmp$1,
                        fileFileName: tmp$2,
                        isIgnoredFromDebarcoding: tmp$3,
                        barcodingChannels: value$14 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$14))).map((function (value) {
                                  var value$1 = Js_json.decodeString(value);
                                  if (value$1 !== undefined) {
                                    return value$1;
                                  } else {
                                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                                  }
                                })) : Js_exn.raiseError("graphql_ppx: Field barcodingChannels on type Sample is missing"),
                        sourceSampleFileName: tmp$4,
                        featureValues: tmp$5
                      };
              })) : Js_exn.raiseError("graphql_ppx: Field samples on type Experiment is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field experiment on type Query is missing");
  }
  return {
          debarcodingKits: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map((function (value) {
                    return Curry._1(GraphQLFragments$Astrolabe.DebarcodingKitFragment.DebarcodingKit.parse, value);
                  })) : Js_exn.raiseError("graphql_ppx: Field debarcodingKits on type Query is missing"),
          experiment: tmp
        };
}

function make(id, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "id",
                    id
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, param) {
  return Js_dict.fromArray([/* tuple */[
                  "id",
                  id
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "id",
                      id
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetExperiment = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function reducer(state, action) {
  if (action.tag) {
    return {
            errorTitle: state.errorTitle,
            errors: state.errors,
            disableContinue: action[0]
          };
  } else {
    return {
            errorTitle: action[0],
            errors: action[1],
            disableContinue: state.disableContinue
          };
  }
}

var GetExperimentQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function ExperimentView(Props) {
  var cellSubsetLevels = Props.cellSubsetLevels;
  var immutableJsExperiment = Props.experiment;
  var match_ = Props.match;
  var initialState_errors = [];
  var initialState = {
    errorTitle: "",
    errors: initialState_errors,
    disableContinue: false
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var exp = Experiment$Astrolabe.Decode.experiment(immutableJsExperiment);
  var experimentId = String(exp.id);
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var hasErrors = !Util$Astrolabe.$$Array.isEmpty(state.errors);
  return React.createElement(GetExperimentQuery.make, {
              variables: make(experimentId, undefined).variables,
              children: (function (param) {
                  var refetch = param.refetch;
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  }
                  if (!result.tag) {
                    return React.createElement("div", {
                                className: "alert alert-danger",
                                role: "alert"
                              }, React.createElement("h5", {
                                    className: "alert-heading mb-0 text-center"
                                  }, "Experiment Not Found"));
                  }
                  var data = result[0];
                  var experiment = data.experiment;
                  var isDebarcoding = experiment.status === /* ADDING_DEBARCODING_KITS */889637598 || experiment.status === /* MAPPING_BARCODING_CHANNELS */-408660381;
                  var isEditable = experiment.isEditable;
                  var isPublic = experiment.isPublic && !isEditable;
                  var isShowAnalysisColumn = experiment.isShowAnalysisColumn;
                  var canEditDebarcoding = experiment.canEditKits || experiment.canEditChannelMapping;
                  var channels = experiment.channels.map((function (channel) {
                          return Channel$Astrolabe.make(channel.id, channel.name, channel.desc, channel.usage, undefined);
                        }));
                  var channelUsages = Util$Astrolabe.$$Array.toImmutableJsList(channels.map((function (channel) {
                              return Channel$Astrolabe.Encode.channelUsage(channel);
                            })));
                  var debarcodingKits = data.debarcodingKits;
                  var experimentDebarcodingKits = experiment.debarcodingKits;
                  var features = experiment.features.map((function (feature) {
                          return Feature$Astrolabe.make(feature.id, feature.name, feature.values, feature.isAllowNa, undefined);
                        }));
                  var samples = experiment.samples;
                  var sampleFeatureOptionsLength = Caml_int32.imul(samples.length, features.length);
                  var countOfSampleFeatureValues = samples.reduce((function (acc, sample) {
                          return acc + Sample$Astrolabe.Js.featureValues(sample).length | 0;
                        }), 0);
                  var hasBlankSampleFeatureValues = countOfSampleFeatureValues < sampleFeatureOptionsLength;
                  var handleComplete = function (errors, disableContinueOpt, shouldClearExperimentErrorsOpt, shouldRefetchGraphQLOpt, param) {
                    var disableContinue = disableContinueOpt !== undefined ? disableContinueOpt : false;
                    var shouldClearExperimentErrors = shouldClearExperimentErrorsOpt !== undefined ? shouldClearExperimentErrorsOpt : false;
                    var shouldRefetchGraphQL = shouldRefetchGraphQLOpt !== undefined ? shouldRefetchGraphQLOpt : false;
                    if (errors !== undefined) {
                      Curry._1(dispatch, /* SetErrors */Block.__(0, [
                              errors[0],
                              errors[1]
                            ]));
                    }
                    Curry._1(dispatch, /* SetDisableContinue */Block.__(1, [disableContinue]));
                    if (shouldClearExperimentErrors) {
                      Curry._1(dispatch, /* SetErrors */Block.__(0, [
                              "",
                              []
                            ]));
                    }
                    if (shouldRefetchGraphQL) {
                      Curry._1(refetch, undefined);
                      return ;
                    }
                    
                  };
                  var displayErrors = hasErrors ? React.createElement(ErrorsAlert$Astrolabe.make, {
                          title: state.errorTitle,
                          errors: state.errors
                        }) : (
                      hasBlankSampleFeatureValues ? React.createElement(ErrorsAlert$Astrolabe.make, {
                              title: "Some errors must be addressed before continuing:",
                              errors: ["Feature values cannot be blank"]
                            }) : null
                    );
                  var analysisView = React.createElement(Analyses$Astrolabe.make, {
                        canEditAnalysisKits: experiment.canEditAnalyses,
                        channelUsages: channelUsages,
                        experiment: experiment,
                        features: features,
                        match: match_,
                        samples: samples,
                        cellSubsetLevels: cellSubsetLevels
                      });
                  var channelsView = React.createElement(ChannelTable$Astrolabe.make, {
                        isEditable: isEditable && !Experiment$Astrolabe.Js.hasBeenAnalyzed(experiment),
                        experimentId: experimentId,
                        channels: channels,
                        isShowAnalysisColumn: isShowAnalysisColumn,
                        onComplete: handleComplete
                      });
                  var showChannelMapping = !experiment.canEditKits;
                  var debarcodingView = React.createElement(React.Fragment, undefined, React.createElement(DebarcodingKits$Astrolabe.make, {
                            canEditKits: experiment.canEditKits,
                            channelUsages: channelUsages,
                            debarcodingKits: debarcodingKits,
                            experimentDebarcodingKits: experimentDebarcodingKits,
                            experimentId: experimentId
                          }), showChannelMapping ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                  className: "py-3"
                                }), React.createElement(ChannelMapping$Astrolabe.make, {
                                  continueDisabled: state.disableContinue,
                                  experiment: experiment,
                                  samples: samples,
                                  experimentDebarcodingKits: experimentDebarcodingKits,
                                  onComplete: handleComplete
                                })) : null);
                  var experimentSettingsView = React.createElement(ExperimentSettings$Astrolabe.make, {
                        clearExperimentErrors: (function (param) {
                            return handleComplete(undefined, undefined, true, undefined, undefined);
                          }),
                        experimentId: experimentId,
                        experimentData: experiment
                      });
                  var featuresView = React.createElement(FeaturesTable$Astrolabe.make, {
                        experimentId: experimentId,
                        features: features,
                        isEditable: isEditable,
                        hasDeAnalysis: experiment.hasDeAnalysis,
                        samples: samples,
                        onComplete: handleComplete
                      });
                  var match = url.path;
                  var tmp;
                  if (match) {
                    switch (match[0]) {
                      case "experiments" :
                          var match$1 = match[1];
                          if (match$1) {
                            var match$2 = match$1[1];
                            if (match$2) {
                              switch (match$2[0]) {
                                case "analyses" :
                                    var match$3 = match$2[1];
                                    tmp = match$3 && match$3[1] ? null : analysisView;
                                    break;
                                case "channels" :
                                    tmp = match$2[1] ? null : channelsView;
                                    break;
                                case "debarcoding" :
                                    tmp = match$2[1] ? null : debarcodingView;
                                    break;
                                case "settings" :
                                    tmp = match$2[1] || isDebarcoding ? null : experimentSettingsView;
                                    break;
                                case "features" :
                                case "summary" :
                                    tmp = match$2[1] ? null : featuresView;
                                    break;
                                default:
                                  tmp = null;
                              }
                            } else {
                              tmp = canEditDebarcoding ? debarcodingView : (
                                  isEditable ? experimentSettingsView : featuresView
                                );
                            }
                          } else {
                            tmp = null;
                          }
                          break;
                      case "public" :
                          var match$4 = match[1];
                          if (match$4 && match$4[0] === "experiments") {
                            var match$5 = match$4[1];
                            if (match$5) {
                              var match$6 = match$5[1];
                              if (match$6) {
                                switch (match$6[0]) {
                                  case "analyses" :
                                      var match$7 = match$6[1];
                                      tmp = match$7 ? (
                                          match$7[1] || !isPublic ? null : analysisView
                                        ) : (
                                          isPublic ? analysisView : null
                                        );
                                      break;
                                  case "channels" :
                                      tmp = match$6[1] || !isPublic ? null : channelsView;
                                      break;
                                  case "debarcoding" :
                                      tmp = match$6[1] || !isPublic ? null : debarcodingView;
                                      break;
                                  case "summary" :
                                      tmp = match$6[1] || !isPublic ? null : featuresView;
                                      break;
                                  default:
                                    tmp = null;
                                }
                              } else {
                                tmp = isPublic ? featuresView : null;
                              }
                            } else {
                              tmp = null;
                            }
                          } else {
                            tmp = null;
                          }
                          break;
                      default:
                        tmp = null;
                    }
                  } else {
                    tmp = null;
                  }
                  return React.createElement(React.Fragment, undefined, React.createElement(ExperimentHeader$Astrolabe.make, {
                                  continueDisabled: state.disableContinue || hasBlankSampleFeatureValues,
                                  experimentData: experiment,
                                  experimentId: experimentId,
                                  handleContinue: handleComplete
                                }), displayErrors, React.createElement("div", {
                                  className: "px-3"
                                }, tmp));
                })
            });
}

var make$1 = ExperimentView;

var $$default = ExperimentView;

exports.GetExperiment = GetExperiment;
exports.reducer = reducer;
exports.GetExperimentQuery = GetExperimentQuery;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* GetExperimentQuery Not a pure module */
