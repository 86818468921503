/* @flow */
"use strict";

import React from "react";
import type { Match } from "react-router";
import { Link } from "react-router-dom";
import { List, Map, Set } from "immutable";
import { PipelineRun } from "models";
import Row from "./Row";
import { fetchJSON } from "components/utils";
import NavTitle from "../NavTitle";

type Props = {
  match: Match,
};

type State = {
  errors: ?List<string>,
  run: ?PipelineRun,
  tasks: List<any>,
};

export default class Show extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      run: null,
      tasks: List(),
      errors: null,
    };

    this.fetchPipelineRun();
    this.fetchTasks();
  }

  async fetchPipelineRun() {
    var json = await fetchJSON("GET", `/pipeline_runs/${this.props.match.params.id}.json`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({
        run: new PipelineRun(json),
      });
    }
  }

  async fetchTasks() {
    const json = await fetchJSON("GET", `/pipeline_runs/${this.props.match.params.id}/tasks.json?include_placeholders=true`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      const taskOrder = {
        unstarted: -1,
        created: 0,
        running: 1,
        errored: 2,
        finished: 3,
      };
      const tasks = List(json).sort((a, b) => taskOrder[a.task.status] - taskOrder[b.task.status]);
      this.setState({ tasks });
    }
  }

  renderTasks() {
    return this.state.tasks.map((t, i) => {
      return (
        <tr key={i} className="table-sm">
          <td>{t.vertex.id}</td>
          <td>{t.task.status}</td>
          <td>{t.task.started_at && new Date(t.task.started_at).toLocaleString()}</td>
          <td>{t.task.ended_at && new Date(t.task.ended_at).toLocaleString()}</td>
        </tr>
      );
    });
  }

  render() {
    if (!this.state.run || !this.state.tasks) {
      return <h3>Loading…</h3>;
    }

    const run = this.state.run;

    return (
      <React.Fragment>
        <NavTitle>
          <Link to="/pipeline_runs">Pipeline Runs</Link>
        </NavTitle>
        <div className="progress" style={{ height: "1rem" }}>
          <div className="progress-bar bg-success" style={{ width: `20%` }}>
            Finished
          </div>
          <div className="progress-bar bg-warning" style={{ width: `20%` }}>
            Running
          </div>
          <div className="progress-bar bg-info" style={{ width: `20%` }}>
            Waiting
          </div>
          <div className="progress-bar" style={{ width: `20%` }}>
            Unstarted
          </div>
          <div className="progress-bar bg-danger" style={{ width: `20%` }}>
            Errored
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Run ID</th>
              <th>Experiment Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Progress</th>
              <th>Started At</th>
              <th>Ended At</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <Row run={this.state.run} includeLink={false} />
          </tbody>
        </table>

        <table className="table">
          <thead>
            <tr>
              <th>Task ID</th>
              <th>Status</th>
              <th>Started At</th>
              <th>Ended At</th>
            </tr>
          </thead>
          <tbody>{this.renderTasks()}</tbody>
        </table>
      </React.Fragment>
    );
  }
}
