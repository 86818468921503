// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Webapi__Dom__HtmlElement = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__HtmlElement.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function toFormControlsize(param) {
  if (param !== 311976103) {
    if (param >= 861718677) {
      return "form-control-md";
    } else {
      return "form-control-lg";
    }
  } else {
    return "form-control-sm";
  }
}

function FocusOutInput(Props) {
  var defaultText = Props.defaultText;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var nameOpt = Props.name;
  var onFocusOut = Props.onFocusOut;
  var sizeOpt = Props.size;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var name = nameOpt !== undefined ? nameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Small */311976103;
  var match = React.useState((function () {
          return defaultText;
        }));
  var setInputText = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setInputWasChanged = match$1[1];
  var inputWasChanged = match$1[0];
  var inputRef = React.useRef(null);
  var handleOnFocusOut = function (evt) {
    return Curry._1(onFocusOut, Util$Astrolabe.$$Option.force(Webapi__Dom__HtmlElement.ofElement(evt.target)).value);
  };
  React.useEffect((function () {
          Curry._1(setInputText, (function (param) {
                  return defaultText;
                }));
          
        }), [defaultText]);
  React.useEffect((function () {
          var inputEl = Util$Astrolabe.$$Option.force(Caml_option.nullable_to_opt(inputRef.current));
          if (inputWasChanged) {
            inputEl.addEventListener("focusout", handleOnFocusOut);
          }
          return (function (param) {
                    if (inputWasChanged) {
                      inputEl.removeEventListener("focusout", handleOnFocusOut);
                      return ;
                    }
                    
                  });
        }), [inputWasChanged]);
  return React.createElement("input", {
              ref: inputRef,
              className: toFormControlsize(size) + (" form-control " + className),
              disabled: disabled,
              name: name,
              type: "text",
              value: match[0],
              onChange: (function (evt) {
                  var updatedInputText = ReasonReactHelpers$Astrolabe.valueFromEvent(evt);
                  Curry._1(setInputText, (function (param) {
                          return updatedInputText;
                        }));
                  if (!inputWasChanged) {
                    return Curry._1(setInputWasChanged, (function (param) {
                                  return true;
                                }));
                  }
                  
                })
            });
}

var make = FocusOutInput;

var $$default = FocusOutInput;

exports.toFormControlsize = toFormControlsize;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
