/* @flow */
"use strict";

import * as React from "react";

type Props = {
  handleFileChange: (SyntheticInputEvent) => any,
  handleFileUpload: (SyntheticInputEvent) => any,
  text: string,
};

type State = {
  dragging: false,
};

/* Got ideas from this tutorial: https://medium.com/p/2cb409d88929/responses/show */
export default class DropZone extends React.Component<Props, State> {
  dropRef = React.createRef();

  state = {
    dragging: false,
  };

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("drop", this.handleDrop);
  }

  handleDragIn = (e) => {
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    this.setState({ dragging: false });
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.props.handleFileUpload(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  render() {
    return (
      <div className="row justify-content-around" ref={this.dropRef}>
        <div className="col-12 col-md-6">
          <label
            className={`custom-file rounded p-3 text-center text-white ${
              this.state.dragging ? "astrolabe-bg-green" : "astrolabe-bg-light-blue"
            }`}
          >
            <input type="file" multiple={true} onChange={this.props.handleFileChange} className="custom-file-input" />
            <span className="custom-file-label text-center text-white">{this.props.text}</span>
          </label>
        </div>
      </div>
    );
  }
}
