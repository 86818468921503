/* @flow */
"use strict";

import React from "react";
import type { History, Match } from "react-router";
import { List } from "immutable";
import type { JobInfo } from "models";
import { ChannelUsage, Experiment, Feature, Sample } from "models";
import { fetchJSON } from "components/utils";

type Props = {
  history: History,
  match: Match,
};

type State = {
  channelUsages: List<ChannelUsage>,
  errors: List<Object>,
  experiment: ?Experiment,
  experimentLoaded: boolean,
  features: List<Feature>,
  samples: List<Sample>,
};

export type Api = {
  experiments: {
    analyze: (Experiment) => Promise<any>,
    fetch: () => Promise<any>,
    save: (Experiment) => Promise<any>,
  },
  samples: {
    save: (Sample) => Promise<any>,
  },
};

type WrappedComponentProps = {
  api: Api,
  channelUsages?: List<ChannelUsage>,
  errors?: List<Object>,
  experiment?: Experiment,
  features?: List<Feature>,
  samples?: List<Sample>,
};

type Options = {
  /**
   * Defaults to null, which does not perform any redirection. Otherwise, redirects the user if the wrapped component
   * allows editing and the experiment is not editable or vice versa.
   */
  allowsEditing?: ?boolean,
  // Defaults to true. Causes this component to render errors above the wrapped component.
  renderErrors?: boolean,
};

export default function withExperiment(WrappedComponent: any, options?: Options = {}) {
  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        channelUsages: List(),
        errors: List(),
        experiment: null,
        experimentLoaded: false,
        features: List(),
        samples: List(),
      };
    }

    componentDidMount() {
      this.fetchExperiment();
    }

    analyzeExperiment = async (experiment: Experiment) => {
      return this.optimisticallyFetchExperimentEndpoint(
        "PATCH",
        `/experiments/${this.props.match.params.id}/analyze.json`,
        { experiment: experiment.toJS() },
        { experiment },
      );
    };

    fetchExperiment = async () => {
      let json = {};

      try {
        json = await fetchJSON("GET", `/experiments/${this.props.match.params.id}.json`);
      } catch (e) {
        json.errors = e.message;
      }
      var samples = [];

      if (json.errors) {
        this.setState({ experimentLoaded: true, errors: List(json.errors) });
      } else {
        samples = List(json.samples.map((s) => new Sample(s)));

        const experiment = new Experiment(json.experiment);

        this.setState({
          channelUsages: List(json.channel_usages.map((c) => new ChannelUsage(c))),
          experiment,
          experimentLoaded: true,
          features: List(json.features.map((f) => new Feature({ ...f, values: List(f.values) }))),
          samples,
        });

        const nextEditable = experiment.get("editable?");

        /**
         * If we allow editing and the experiment becomes not editable, redirect to /experiments/Show.
         * If we don't allow editing and the experiment become editable, redirect to /experiments/Edit.
         */
        if (options.allowsEditing && !nextEditable) {
          this.props.history.push(`/experiments/${experiment.get("id")}`);
        } else if (options.allowsEditing === false && nextEditable) {
          this.props.history.push(`/experiments/${experiment.get("id")}/edit`);
        }
      }
    };

    optimisticallyFetchExperimentEndpoint = async (method: string, endpoint: string, params: ?Object, futureState: Object) => {
      var currentState = {};
      Object.keys(futureState).forEach((key) => (currentState[key] = this.state[key]));

      this.setState(futureState);

      const json = await fetchJSON(method, endpoint, params);

      if (json.errors) {
        this.setState({
          ...currentState,
          errors: List(json.errors),
        });
      } else {
        var updatedState = {};
        Object.keys(futureState).forEach((key) => (updatedState[key] = this.state[key]));
        this.setState({ experiment: new Experiment(json.experiment) });
      }
    };

    preprocessExperiment = async (experiment: Experiment) => {
      return this.optimisticallyFetchExperimentEndpoint(
        "PATCH",
        `/experiments/${this.props.match.params.id}/preprocess.json`,
        { experiment: experiment.toJS() },
        { experiment },
      );
    };

    saveExperiment = async (experiment: Experiment) => {
      return this.optimisticallyFetchExperimentEndpoint(
        "PATCH",
        `/experiments/${this.props.match.params.id}.json`,
        { experiment: experiment.toJS() },
        { experiment },
      );
    };

    saveSample = async (sample: Sample) => {
      const index = this.state.samples.findIndex((s) => s.get("id") === sample.get("id"));
      const oldSample = this.state.samples.get(index);

      if (index == -1) {
        throw `Couldn't find sample with id ${sample.get("id")} ` +
          `in this.state.samples: ${JSON.stringify(this.state.samples)}`;
      } else {
        this.setState({ samples: this.state.samples.set(index, sample) });
      }

      const json = await fetchJSON("PATCH", `/experiments/${this.props.match.params.id}/samples/${sample.get("id")}.json`, {
        sample: {
          ...sample.toJS(),
          feature_values_attributes: sample.get("feature_values"),
          feature_values: undefined,
        },
      });

      if (json.errors) {
        this.setState({
          errors: List(json.errors),
          samples: this.state.samples.set(index, oldSample),
        });
      }
    };

    updateFeature = (feature: Feature) => {
      const index = this.state.features.findIndex((f) => f.id == feature.id);

      if (index == -1) {
        this.setState({ features: this.state.features.push(feature) });
      } else {
        this.setState({ features: this.state.features.set(index, feature) });
      }
    };

    renderError() {
      let errorDesc = null;
      let errors = null;

      if (this.state.errors.size === 1) {
        errorDesc = "Error";
        errors = <p>{this.state.errors[0]}</p>;
      } else {
        errorDesc = "Errors";
        errors = (
          <ul>
            {this.state.errors.map((error, i) => {
              <li key={i}>{error}</li>;
            })}
          </ul>
        );
      }

      return (
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading">{errorDesc} Loading Experiment</h4>
          {errors}
        </div>
      );
    }

    renderNotFound() {
      return (
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading mb-0 text-center">Experiment Not Found</h4>
        </div>
      );
    }

    render() {
      if (!this.state.experiment) {
        return this.state.experimentLoaded ? this.renderNotFound() : <h3>Loading…</h3>;
      }

      const wrappedProps = {
        api: {
          experiments: {
            analyze: this.analyzeExperiment,
            fetch: this.fetchExperiment,
            preprocess: this.preprocessExperiment,
            save: this.saveExperiment,
          },
          samples: {
            save: this.saveSample,
          },
        },
        channelUsages: this.state.channelUsages,
        errors: this.state.errors,
        experiment: this.state.experiment,
        features: this.state.features,
        samples: this.state.samples,
        updateFeature: this.updateFeature,
      };

      if (this.state.errors.size > 0) {
        return (
          <div>
            {this.renderError()}
            <WrappedComponent {...this.props} {...wrappedProps} />
          </div>
        );
      } else {
        return <WrappedComponent {...this.props} {...wrappedProps} />;
      }
    }
  };
}
