// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Sample$Astrolabe = require("../../models/Sample.bs.js");
var ReactRouterDom = require("react-router-dom");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var ReactRouter$Astrolabe = require("../../utils/ReactRouter.bs.js");
var SampleQcMetric$Astrolabe = require("../../models/SampleQcMetric.bs.js");
var QcMetricDisplayInfo$Astrolabe = require("../../models/QcMetricDisplayInfo.bs.js");

function SampleQualityControlTable(Props) {
  var experimentId = Props.experimentId;
  var samples = Props.samples;
  var thresholds = Props.thresholds;
  var samples$1 = $$Array.map(Sample$Astrolabe.Compat.fromImmutableJs, samples);
  var singleSample = samples$1.length === 1;
  var sampleRow = function (sample, thresholds, includeSampleName) {
    var metrics = $$Array.to_list(Sample$Astrolabe.qc_metrics(sample));
    var prepend = includeSampleName ? [React.createElement("td", {
              key: "0"
            }, React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps(Constants$Astrolabe.sampleNavigatorPath(experimentId, Sample$Astrolabe.id(sample)), Caml_option.some(Sample$Astrolabe.name(sample)), undefined)))] : [];
    var columns = $$Array.of_list(List.mapi((function (i, metricType) {
                var key = String(i + 1 | 0);
                var metrics$1 = List.filter((function (m) {
                          return SampleQcMetric$Astrolabe.Type.equal(m.metricType, metricType);
                        }))(metrics);
                var match = metricType.name;
                switch (match) {
                  case "bead" :
                  case "cell" :
                  case "total" :
                      break;
                  default:
                    if (!metrics$1) {
                      return React.createElement("td", {
                                  key: key
                                }, "No data");
                    }
                    var metricType$1 = List.hd(metrics$1).metricType;
                    var jsThreshold = Js_dict.get(thresholds, metricType$1.name);
                    if (jsThreshold === undefined) {
                      return React.createElement("td", {
                                  key: key
                                }, "No data");
                    }
                    var threshold = Experiment$Astrolabe.Decode.qcMetricThreshold(Caml_option.valFromOption(jsThreshold));
                    var displayInfo = QcMetricDisplayInfo$Astrolabe.get(threshold, metrics$1);
                    var match$1 = displayInfo.detail;
                    var className = match$1 !== undefined ? "alert alert-danger cursor-help" : "";
                    return React.createElement("td", {
                                key: key,
                                className: className,
                                title: Belt_Option.getWithDefault(displayInfo.detail, "")
                              }, displayInfo.value);
                }
                var count = Sample$Astrolabe.getEventCount(metricType.name, sample).toLocaleString();
                return React.createElement("td", {
                            key: key
                          }, count);
              }), List.filter((function (metricType) {
                      return metricType.name !== "aof";
                    }))(SampleQcMetric$Astrolabe.Type.all)));
    return $$Array.concat(/* :: */[
                prepend,
                /* :: */[
                  columns,
                  /* [] */0
                ]
              ]);
  };
  var metrics = Sample$Astrolabe.qc_metrics(Caml_array.caml_array_get(samples$1, 0));
  if (singleSample && metrics.length === 0) {
    return null;
  }
  var sampleRows = $$Array.mapi((function (i, sample) {
          return React.createElement("tr", {
                      key: String(i)
                    }, sampleRow(sample, thresholds, !singleSample));
        }), samples$1);
  var prepend = singleSample ? [] : [React.createElement("th", {
            key: "0"
          }, "Sample Name")];
  var headerRow = $$Array.of_list(List.mapi((function (i, metricType) {
              return React.createElement("th", {
                          key: String(i + 1 | 0)
                        }, React.createElement("span", {
                              title: metricType.description
                            }, metricType.label));
            }), List.filter((function (metricType) {
                    return metricType.name !== "aof";
                  }))(SampleQcMetric$Astrolabe.Type.all)));
  var headerRowWithPrepend = $$Array.concat(/* :: */[
        prepend,
        /* :: */[
          headerRow,
          /* [] */0
        ]
      ]);
  if (Belt_Array.every(sampleRows, (function (row) {
            return Caml_obj.caml_equal(row, null);
          }))) {
    return null;
  } else {
    return React.createElement("table", {
                className: "table" + (
                  singleSample ? "" : " table-hover table-striped table-sm"
                )
              }, React.createElement("thead", undefined, React.createElement("tr", undefined, headerRowWithPrepend)), React.createElement("tbody", undefined, sampleRows));
  }
}

var make = SampleQualityControlTable;

var $$default = SampleQualityControlTable;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
