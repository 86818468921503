// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ConfirmedButton$Astrolabe = require("../header/ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function DeletePipelinesButton(Props) {
  var experimentId = Props.experimentId;
  var disabled = Props.disabled;
  var dagType = Props.dagType;
  return React.createElement(ExperimentGraphQL$Astrolabe.DestroyPipelinesMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement(ConfirmedButton$Astrolabe.make, {
                              buttonClassName: "btn btn-danger",
                              buttonTitle: "Delete " + (Curry._1(Util$Astrolabe.$$String.capitalize, dagType) + " Pipeline Runs"),
                              buttonTooltip: disabled ? "Pipeline runs can only be deleted when experiment status is pending_analysis or done" : "",
                              confirmationText: "DELETE",
                              disabled: disabled,
                              modalButtonClassName: "btn-danger",
                              modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", {
                                        className: "text-danger"
                                      }, "All experiment " + (dagType + " pipeline runs will be canceled and deleted. This cannot be undone.")), React.createElement("p", undefined, "Are you sure you want to delete all of the experiment " + (dagType + " pipeline runs?"))),
                              onConfirm: (function (param) {
                                  return GraphQLHelpers$Astrolabe.handleDataAndErrors((function (_data) {
                                                return ReasonReactHelpers$Astrolabe.redirect(Constants$Astrolabe.experimentIdPath(experimentId));
                                              }), Curry._4(mutation, Caml_option.some(ExperimentGraphQL$Astrolabe.DestroyPipelinesConfig.make(experimentId, dagType, undefined).variables), undefined, undefined, undefined));
                                }),
                              title: "Delete " + (Curry._1(Util$Astrolabe.$$String.capitalize, dagType) + " Pipeline Runs")
                            });
                })
            });
}

var DestroyPipelinesConfig;

var DestroyPipelinesMutation;

var make = DeletePipelinesButton;

exports.DestroyPipelinesConfig = DestroyPipelinesConfig;
exports.DestroyPipelinesMutation = DestroyPipelinesMutation;
exports.make = make;
/* react Not a pure module */
