// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");

var ppx_printed_query = "mutation CreateUserExperiment($experimentId: ID!, $userId: ID!)  {\ncreateUserExperiment(experimentId: $experimentId, userId: $userId)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createUserExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type UserExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createUserExperiment: tmp
        };
}

function make(experimentId, userId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "userId",
                    userId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var userId = variables.userId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "userId",
                    userId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, userId, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "userId",
                  userId
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, userId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "userId",
                      userId
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var CreateUserExperimentConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var CreateUserExperimentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

var ppx_printed_query$1 = "mutation DestroyUserExperiment($experimentId: ID!, $userId: ID!)  {\ndestroyUserExperiment(experimentId: $experimentId, userId: $userId)  {\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyUserExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type UserExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyUserExperiment: tmp
        };
}

function make$1(experimentId, userId, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "userId",
                    userId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var experimentId = variables.experimentId;
  var userId = variables.userId;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "userId",
                    userId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(experimentId, userId, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "userId",
                  userId
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, experimentId, userId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "userId",
                      userId
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var DestroyUserExperimentConfig = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var DestroyUserExperimentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

exports.CreateUserExperimentConfig = CreateUserExperimentConfig;
exports.CreateUserExperimentMutation = CreateUserExperimentMutation;
exports.DestroyUserExperimentConfig = DestroyUserExperimentConfig;
exports.DestroyUserExperimentMutation = DestroyUserExperimentMutation;
/* CreateUserExperimentMutation Not a pure module */
