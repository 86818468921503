/* @flow */
import React from "react";
import { Redirect } from "react-router-dom";
import type { Session } from "models";
import { fetchJSON } from "components/utils";
import NavTitle from "../NavTitle";

type Props = {
  session: Session,
};

type State = {
  email: string,
  error: ?string,
  sendResetSucceeded: boolean,
};

export default class New extends React.Component<Props, State> {
  handleSubmit: (SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      error: null,
      sendResetSucceeded: false,
    };
  }

  handleSubmit = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.sendResetPassword({ email: this.state.email });
  };

  async sendResetPassword(params: Object) {
    this.setState({ error: null });
    var resp = await fetchJSON("POST", "/users/password.json", { user: params });

    if (resp.error) {
      this.setState({ error: resp.error });
    } else {
      this.setState({ sendResetSucceeded: true });
    }
  }

  render() {
    if (this.props.session.user) {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="Forgot Password" />
        <div className="row align-items-center justify-content-center">
          <div className="col" />
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.state.sendResetSucceeded ? (
            <div className="alert alert-success" role="alert">
              A password reset link has been sent to you.
            </div>
          ) : null}
          <div className="col" />
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col" />
          <div className="jumbotron p-4 col-xs-12 col-md-6">
            <form className="form" method="post" action="/users/password" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <p>Enter your email address and we'll send you a link to reset your password.</p>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  autoFocus={true}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Email"
                />
              </div>

              <div className="form-group mb-0">
                <button className="btn btn-primary">Send</button>
              </div>
            </form>
          </div>
          <div className="col" />
        </div>
      </React.Fragment>
    );
  }
}
