/* @flow */
"use strict";

import { Map } from "immutable";
import DataTable from "./dataTable";
import { Feature, Sample } from "./index";
import type { BoxPlotDatum } from "./index";
import { ensureArray } from "../components/utils";

export default class ExperimentStatistics {
  box_plot_stats: {
    [string]: {
      // Cell Subset
      [string]: {
        // feature_${id}
        [string]: BoxPlotDatum, // Feature Value
      },
    },
  };
  frequency: {
    [string]: Array<number>,
  };
  scaled_frequency: {
    [string]: Array<number>,
  };
  subset_channel_statistics: {
    [string]: {
      // Cell Subset
      [string]: Array<number>, // Channel
    },
  };
  suggested_order: {
    // TODO: Make the keys for each order valid JS names.
    cell_subset_order: {
      alphanumeric: Array<string>,
      hierarchical: Array<string>,
      mean_frequency: Array<string>,
    },
    channel_order: {
      alphanumeric: Array<string>,
    },
    sample_id_order: {
      alphanumeric: Array<number>,
      hierarchical: Array<number>,
      [string]: Array<number>, // Keys should be in the format "feature_${id}".
    },
    sample_name_order: {
      alphanumeric: Array<string>,
      hierarchical: Array<string>,
      [string]: Array<string>, // Keys should be in the format "feature_${id}".
    },
  };

  constructor(samples: Array<Sample>, props: Object) {
    this.box_plot_stats = props.box_plot_stats;
    this.frequency = props.frequency;
    this.scaled_frequency = props.scaled_frequency;
    this.subset_channel_statistics = props.subset_channel_statistics;
    this.suggested_order = props.suggested_order;
    this.suggested_order.sample_name_order = {};

    let samplesById = Map(samples.map((s) => [s.id, s]));

    for (var type in this.suggested_order.sample_id_order) {
      const sampleIdOrderType = this.suggested_order.sample_id_order[type];
      // This is for single sample experiments where the column label comes in from the yaml without an array
      const sampleIdOrderTypeArray = ensureArray(sampleIdOrderType);
      const mappedSort = sampleIdOrderTypeArray.map((id) => {
        const sample = samplesById.get(id);
        if (!sample) {
          throw `Couldn't find sample with id ${id} in ${Object.keys(samplesById).join(", ")}.`;
        }
        return sample.get("name");
      });
      this.suggested_order.sample_name_order[type] = mappedSort;
    }
  }

  boxPlotDatum(cellSubset: string, featureId: string, featureValue: string): ?BoxPlotDatum {
    let datum = ((this.box_plot_stats[cellSubset] || {})[`feature_${featureId}`] || {})[featureValue];
    return datum;
  }

  dataTable(options: { scaled?: boolean } = {}): DataTable {
    return new DataTable({
      columns: options.scaled ? this.scaled_frequency : this.frequency,
      columnLabels: this.suggested_order.cell_subset_order.alphanumeric,
      rowLabels: this.suggested_order.sample_name_order.alphanumeric,
    });
  }

  hasSubsetChannelData(): boolean {
    return !!this.subset_channel_statistics && Object.keys(this.subset_channel_statistics).length > 0;
  }

  subsetChannelDataTable(cellSubset: string): DataTable {
    const columns = this.subset_channel_statistics[cellSubset];
    const columnLabels = this.suggested_order.channel_order.alphanumeric;
    const rowLabels = this.suggested_order.sample_name_order.alphanumeric;

    Object.keys(columns).forEach((k) => (columns[k] = ensureArray(columns[k]).map((v) => parseFloat(v))));

    return new DataTable({ columns, columnLabels, rowLabels });
  }
}
