// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");

var ppx_printed_query = "mutation UpdateSample($id: ID!, $fileFileName: String, $isIgnoredFromDebarcoding: Boolean, $name: String)  {\nupdateSample(id: $id, isIgnoredFromDebarcoding: $isIgnoredFromDebarcoding, fileFileName: $fileFileName, name: $name)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateSample");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type SamplePayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateSample: tmp
        };
}

function make(id, fileFileName, isIgnoredFromDebarcoding, name, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "fileFileName",
                    fileFileName !== undefined ? fileFileName : null
                  ],
                  /* tuple */[
                    "isIgnoredFromDebarcoding",
                    isIgnoredFromDebarcoding !== undefined ? isIgnoredFromDebarcoding : null
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  var fileFileName = variables.fileFileName;
  var isIgnoredFromDebarcoding = variables.isIgnoredFromDebarcoding;
  var name = variables.name;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "fileFileName",
                    fileFileName !== undefined ? fileFileName : null
                  ],
                  /* tuple */[
                    "isIgnoredFromDebarcoding",
                    isIgnoredFromDebarcoding !== undefined ? isIgnoredFromDebarcoding : null
                  ],
                  /* tuple */[
                    "name",
                    name !== undefined ? name : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, fileFileName, isIgnoredFromDebarcoding, name, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "fileFileName",
                  fileFileName !== undefined ? fileFileName : null
                ],
                /* tuple */[
                  "isIgnoredFromDebarcoding",
                  isIgnoredFromDebarcoding !== undefined ? isIgnoredFromDebarcoding : null
                ],
                /* tuple */[
                  "name",
                  name !== undefined ? name : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, fileFileName, isIgnoredFromDebarcoding, name, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "fileFileName",
                      fileFileName !== undefined ? fileFileName : null
                    ],
                    /* tuple */[
                      "isIgnoredFromDebarcoding",
                      isIgnoredFromDebarcoding !== undefined ? isIgnoredFromDebarcoding : null
                    ],
                    /* tuple */[
                      "name",
                      name !== undefined ? name : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var UpdateSampleConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UpdateSampleMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

exports.UpdateSampleConfig = UpdateSampleConfig;
exports.UpdateSampleMutation = UpdateSampleMutation;
/* UpdateSampleMutation Not a pure module */
