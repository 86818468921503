/* @flow */
"use strict";

import React from "react";
import { Link } from "react-router-dom";
import { List, Map, Set } from "immutable";
import { PipelineRun } from "models";
import Row from "./Row";
import { fetchJSON } from "components/utils";
import NavTitle from "../NavTitle";

type Props = {};

type State = {
  runs: List<PipelineRun>,
  tasksByRunId: Map<number, any>,
  starting: Set<string>,
  canceling: Set<string>,
  errors: ?List<string>,
};

export default class Index extends React.Component<Props, State> {
  startRun: () => any;
  cancelRun: () => any;

  constructor(props: Props) {
    super(props);
    this.state = {
      runs: List(),
      tasksByRunId: new Map(),
      starting: new Set(),
      canceling: new Set(),
      errors: null,
    };
    this.fetchRuns();
  }

  async fetchRuns() {
    const json = await fetchJSON("GET", `/pipeline_runs.json`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      const runs = List(json.runs.map((r) => new PipelineRun(r))).sort((a, b) => b.get("id") - a.get("id"));
      this.setState({ runs });
    }
  }

  async fetchTasks(runId: number) {
    const json = await fetchJSON("GET", `/pipeline_runs/${runId}/tasks.json?include_placeholders=true`);

    if (json.errors) {
      this.setState({ errors: List(json.errors) });
    } else {
      this.setState({
        tasksByRunId: this.state.tasksByRunId.set(runId, List(json)),
      });
    }
  }

  startRun = (e: ElementEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ starting: this.state.starting.add(e.target.href) });
    this.startRunAsync(e.target.href);
  };

  async startRunAsync(href: string) {
    const json = await fetchJSON("POST", href);

    if (json.errors) {
      this.setState({
        starting: this.state.starting.remove(href),
        errors: List(json.errors),
      });
    } else {
      const run = new PipelineRun(json.run);
      const index = this.state.runs.findIndex((r) => r.id === run.id);

      this.setState({
        starting: this.state.starting.remove(href),
        runs: this.state.runs.set(index, run),
      });
    }
  }

  cancelRun = (e: ElementEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ canceling: this.state.canceling.add(e.target.href) });
    this.cancelRunAsync(e.target.href);
  };

  async cancelRunAsync(href: string) {
    const json = await fetchJSON("POST", href);

    if (json.errors) {
      this.setState({
        canceling: this.state.canceling.remove(href),
        errors: List(json.errors),
      });
    } else {
      const run = new PipelineRun(json.run);
      const index = this.state.runs.findIndex((r) => r.id === run.id);

      this.setState({
        canceling: this.state.canceling.remove(href),
        runs: this.state.runs.set(index, run),
      });
    }
  }

  renderTableBody(run: PipelineRun) {
    var href = null;
    var buttons = null;

    if (run.status === "created") {
      href = `/pipeline_runs/${run.id}/start`;
      buttons = (
        <a
          className="btn btn-outline-primary btn-sm"
          disabled={this.state.starting.has(href)}
          href={href}
          onClick={this.startRun}
        >
          Start
        </a>
      );
    } else if (run.status === "started") {
      href = `/pipeline_runs/${run.id}/cancel`;
      buttons = (
        <a
          className="btn btn-outline-danger btn-sm"
          disabled={this.state.canceling.has(href)}
          href={href}
          onClick={this.cancelRun}
        >
          Cancel
        </a>
      );
    }

    return (
      <tbody key={run.id}>
        <Row run={run} buttons={buttons} includeLink={true} />
      </tbody>
    );
  }

  render() {
    return (
      <React.Fragment>
        <NavTitle.TextAndDocumentTitle title="Pipeline Runs" />
        <div className="progress" style={{ height: "1rem" }}>
          <div className="progress-bar bg-success" style={{ width: `20%` }}>
            Finished
          </div>
          <div className="progress-bar bg-warning" style={{ width: `20%` }}>
            Running
          </div>
          <div className="progress-bar bg-info" style={{ width: `20%` }}>
            Waiting
          </div>
          <div className="progress-bar" style={{ width: `20%` }}>
            Unstarted
          </div>
          <div className="progress-bar bg-danger" style={{ width: `20%` }}>
            Errored
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Run ID</th>
              <th>Experiment Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Progress</th>
              <th>Started At</th>
              <th>Ended At</th>
              <th />
            </tr>
          </thead>
          {this.state.runs.map((r) => this.renderTableBody(r)).toJS()}
        </table>
      </React.Fragment>
    );
  }
}
