// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function NotificationMessage(Props) {
  var onDismissOpt = Props.onDismiss;
  var kindOpt = Props.kind;
  var dismissAfterMsOpt = Props.dismissAfterMs;
  var classNameOpt = Props.className;
  var children = Props.children;
  var onDismiss = onDismissOpt !== undefined ? onDismissOpt : (function (prim) {
        
      });
  var kind = kindOpt !== undefined ? kindOpt : /* info */-977586066;
  var dismissAfterMs = dismissAfterMsOpt !== undefined ? dismissAfterMsOpt : 5000;
  var className = classNameOpt !== undefined ? classNameOpt : "position-fixed alert shadow-sm";
  var match = React.useState((function () {
          return true;
        }));
  var setShouldDisplay = match[1];
  React.useEffect((function () {
          var timer = setTimeout((function (param) {
                  Curry._1(setShouldDisplay, (function (param) {
                          return false;
                        }));
                  return Curry._1(onDismiss, undefined);
                }), dismissAfterMs);
          return (function (param) {
                    clearTimeout(timer);
                    
                  });
        }), ([]));
  var alertKind = kind >= 58474434 ? (
      kind >= 512905955 ? (
          kind >= 944645571 ? "alert-success" : "alert-danger"
        ) : (
          kind >= 164354652 ? "alert-warning" : "alert-primary"
        )
    ) : (
      kind >= -499495052 ? (
          kind >= -184774442 ? "alert-light" : "alert-secondary"
        ) : (
          kind >= -977586066 ? "alert-info" : "alert-dark"
        )
    );
  if (match[0]) {
    return React.createElement("div", {
                className: className + (" " + alertKind),
                role: "alert"
              }, children);
  } else {
    return null;
  }
}

var make = NotificationMessage;

var $$default = NotificationMessage;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
