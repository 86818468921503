// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/src/Json.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Models = require("models");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FetchUtils$Astrolabe = require("../utils/FetchUtils.bs.js");

function make(unstartedOpt, createdOpt, startedOpt, finishedOpt, erroredOpt, param) {
  var unstarted = unstartedOpt !== undefined ? unstartedOpt : 0;
  var created = createdOpt !== undefined ? createdOpt : 0;
  var started = startedOpt !== undefined ? startedOpt : 0;
  var finished = finishedOpt !== undefined ? finishedOpt : 0;
  var errored = erroredOpt !== undefined ? erroredOpt : 0;
  return {
          unstarted: unstarted,
          created: created,
          started: started,
          finished: finished,
          errored: errored
        };
}

var PipelineJobPercentages = {
  make: make
};

var QcMetricThreshold = { };

var LabelingStrategy = { };

function toDisplayString(param) {
  if (param >= 289311176) {
    if (param >= 889637598) {
      if (param >= 1020136956) {
        return "debarcoding";
      } else {
        return "adding debarcoding kits";
      }
    } else if (param >= 758036610) {
      return "done";
    } else {
      return "created";
    }
  } else if (param >= -384073879) {
    if (param >= 175814564) {
      return "pending analysis";
    } else {
      return "analyzing";
    }
  } else if (param >= -408660381) {
    return "mapping barcoding channels";
  } else {
    return "preprocessing";
  }
}

function fromDisplayString(str) {
  switch (str) {
    case "adding debarcoding kits" :
        return /* ADDING_DEBARCODING_KITS */889637598;
    case "analyzing" :
        return /* ANALYZING */-384073879;
    case "created" :
        return /* CREATED */289311176;
    case "debarcoding" :
        return /* DEBARCODING */1020136956;
    case "done" :
        return /* DONE */758036610;
    case "mapping barcoding channels" :
        return /* MAPPING_BARCODING_CHANNELS */-408660381;
    case "pending analysis" :
        return /* PENDING_ANALYSIS */175814564;
    case "preprocessing" :
        return /* PREPROCESSING */-525726826;
    default:
      return Pervasives.invalid_arg(str);
  }
}

var all = [
  /* CREATED */289311176,
  /* PREPROCESSING */-525726826,
  /* ADDING_DEBARCODING_KITS */889637598,
  /* MAPPING_BARCODING_CHANNELS */-408660381,
  /* PENDING_ANALYSIS */175814564,
  /* DEBARCODING */1020136956,
  /* ANALYZING */-384073879,
  /* DONE */758036610
];

var Status = {
  toDisplayString: toDisplayString,
  fromDisplayString: fromDisplayString,
  all: all
};

function toString(param) {
  if (param !== 173231621) {
    if (param >= 759401869) {
      return "Human";
    } else {
      return "Profiling Only";
    }
  } else {
    return "Mouse";
  }
}

function fromDisplayString$1(str) {
  switch (str) {
    case "Human" :
        return /* HUMAN */759401869;
    case "Mouse" :
        return /* MOUSE */173231621;
    case "Profiling Only" :
        return /* PROFILING_ONLY */-278559483;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function fromRailsJsonString(str) {
  switch (str) {
    case "human" :
        return /* HUMAN */759401869;
    case "mouse" :
        return /* MOUSE */173231621;
    case "profiling_only" :
        return /* PROFILING_ONLY */-278559483;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function toRailsJsonString(param) {
  if (param !== 173231621) {
    if (param >= 759401869) {
      return "human";
    } else {
      return "profiling_only";
    }
  } else {
    return "mouse";
  }
}

var all$1 = [
  /* HUMAN */759401869,
  /* MOUSE */173231621,
  /* PROFILING_ONLY */-278559483
];

var Organism = {
  toString: toString,
  fromDisplayString: fromDisplayString$1,
  fromRailsJsonString: fromRailsJsonString,
  toRailsJsonString: toRailsJsonString,
  all: all$1
};

function toString$1(param) {
  if (param >= 615611296) {
    if (param >= 947968673) {
      return "Flow Cytometer (other than Cytek Aurora)";
    } else {
      return "Cytek Aurora";
    }
  } else if (param >= 498013768) {
    return "Citeseq";
  } else {
    return "Mass Cytometer (such as the Fluidigm Helios)";
  }
}

function fromDisplayString$2(str) {
  switch (str) {
    case "Cytek Aurora" :
        return /* AURORA */615611296;
    case "Flow Cytometer (other than Cytek Aurora)" :
        return /* FLOW_CYTOMETRY */947968673;
    case "Mass Cytometer (such as the Fluidigm Helios)" :
        return /* MASS_CYTOMETRY */259835687;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function fromRailsJsonString$1(str) {
  switch (str) {
    case "aurora" :
        return /* AURORA */615611296;
    case "flow_cytometry" :
        return /* FLOW_CYTOMETRY */947968673;
    case "mass_cytometry" :
        return /* MASS_CYTOMETRY */259835687;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function toRailsJsonString$1(param) {
  if (param >= 615611296) {
    if (param >= 947968673) {
      return "flow_cytometry";
    } else {
      return "aurora";
    }
  } else if (param >= 498013768) {
    return "citeseq";
  } else {
    return "mass_cytometry";
  }
}

var all$2 = [
  /* MASS_CYTOMETRY */259835687,
  /* AURORA */615611296,
  /* FLOW_CYTOMETRY */947968673
];

var Instrument = {
  toString: toString$1,
  fromDisplayString: fromDisplayString$2,
  fromRailsJsonString: fromRailsJsonString$1,
  toRailsJsonString: toRailsJsonString$1,
  all: all$2
};

function toString$2(param) {
  if (param >= 17459) {
    if (param >= 890464712) {
      return "pbmc";
    } else {
      return "Default";
    }
  } else if (param >= -270592759) {
    return "Identify granulocytes, mark CD45- as debris";
  } else {
    return "Identify granulocytes using CD66 or CD16";
  }
}

function fromDisplayString$3(str) {
  switch (str) {
    case "Default" :
        return /* NA */17459;
    case "Identify granulocytes using CD66 or CD16" :
        return /* WHOLE_BLOOD */-669224142;
    case "Identify granulocytes, mark CD45- as debris" :
        return /* MIXED_TISSUE */-270592759;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function fromRailsJsonString$2(str) {
  switch (str) {
    case "mixed_tissue" :
        return /* MIXED_TISSUE */-270592759;
    case "na" :
        return /* NA */17459;
    case "whole_blood" :
        return /* WHOLE_BLOOD */-669224142;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function toRailsJsonString$2(param) {
  if (param >= 17459) {
    if (param >= 890464712) {
      return "pbmc";
    } else {
      return "na";
    }
  } else if (param >= -270592759) {
    return "mixed_tissue";
  } else {
    return "whole_blood";
  }
}

var all$3 = [
  /* NA */17459,
  /* WHOLE_BLOOD */-669224142,
  /* MIXED_TISSUE */-270592759
];

var TissueType = {
  toString: toString$2,
  fromDisplayString: fromDisplayString$3,
  fromRailsJsonString: fromRailsJsonString$2,
  toRailsJsonString: toRailsJsonString$2,
  all: all$3
};

function id(e) {
  return e.id;
}

function is_archived(e) {
  return e.is_archived;
}

function name(e) {
  return e.name;
}

function status(e) {
  return e.status;
}

function organism(e) {
  return e.organism;
}

function created_at_ymd(e) {
  return e.created_at_ymd;
}

function create_user_email(e) {
  return e.create_user_email;
}

function authorized_for_user_id(e) {
  return e.authorized_for_user_id;
}

function statusToDisplayString(status) {
  switch (status) {
    case "adding_debarcoding_kits" :
        return "Adding Debarcoding Kits";
    case "analyzing" :
        return "Analyzing";
    case "created" :
        return "Pending Preprocessing";
    case "debarcoding" :
        return "Debarcoding";
    case "done" :
        return "Done";
    case "mapping_barcoding_channels" :
        return "Mapping Barcoding Channels";
    case "pending_analysis" :
        return "Pending Analysis";
    case "preprocessing" :
        return "Preprocessing";
    default:
      return "";
  }
}

function displayStatus(e) {
  return statusToDisplayString(e.status);
}

function displayTissueType(e) {
  return toString$2(e.tissue_type);
}

function id$1(json) {
  return Json_decode.field("id", Json_decode.$$int, json);
}

function is_archived$1(json) {
  return Json_decode.field("is_archived", Json_decode.bool, json);
}

function is_public(json) {
  return Json_decode.field("is_public", Json_decode.bool, json);
}

function name$1(json) {
  return Json_decode.field("name", Json_decode.string, json);
}

function status$1(json) {
  return Json_decode.field("status", Json_decode.string, json);
}

function instrument(json) {
  return fromRailsJsonString$1(Json_decode.field("instrument", Json_decode.string, json));
}

function organism$1(json) {
  return fromRailsJsonString(Json_decode.field("organism", Json_decode.string, json));
}

function created_at_ymd$1(json) {
  return Json_decode.field("created_at_ymd", Json_decode.string, json);
}

function create_user_email$1(json) {
  return Json_decode.field("create_user_email", Json_decode.string, json);
}

function authorized_for_user_id$1(json) {
  return Json_decode.field("authorized_for_user_id", (function (param) {
                return Json_decode.optional(Json_decode.$$int, param);
              }), json);
}

function tissue_type(json) {
  return fromRailsJsonString$2(Json_decode.field("tissue_type", Json_decode.string, json));
}

function pipelineJobPercentages(json) {
  return {
          unstarted: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("unstarted", Json_decode.$$int, param);
                }), json),
          created: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("created", Json_decode.$$int, param);
                }), json),
          started: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("started", Json_decode.$$int, param);
                }), json),
          finished: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("finished", Json_decode.$$int, param);
                }), json),
          errored: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("errored", Json_decode.$$int, param);
                }), json)
        };
}

function qcMetricThreshold(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          experiment_id: Json_decode.field("experiment_id", Json_decode.$$int, json),
          metric_name: Json_decode.field("metric_name", Json_decode.string, json),
          min: Json_decode.withDefault(Pervasives.neg_infinity, (function (param) {
                  return Json_decode.field("min", Json_decode.$$float, param);
                }), json),
          max: Json_decode.withDefault(Pervasives.infinity, (function (param) {
                  return Json_decode.field("max", Json_decode.$$float, param);
                }), json)
        };
}

function labelingStrategy(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          experiment_id: Json_decode.field("experiment_id", Json_decode.$$int, json),
          parent: Json_decode.field("parent", Json_decode.string, json),
          cell_subset: Json_decode.field("cell_subset", Json_decode.string, json),
          desc: Json_decode.field("desc", Json_decode.string, json)
        };
}

function experiment(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          instrument: fromRailsJsonString$1(Json_decode.field("instrument", Json_decode.string, json)),
          is_public: Json_decode.field("is_public", Json_decode.bool, json),
          organism: organism$1(json),
          tissue_type: tissue_type(json),
          display_live_dead: Json_decode.field("display_live_dead", Json_decode.string, json),
          editable: Json_decode.field("editable?", Json_decode.bool, json),
          can_edit_analyses: Json_decode.field("can_edit_analyses?", Json_decode.bool, json),
          can_edit_features: Json_decode.field("can_edit_features?", Json_decode.bool, json),
          can_edit_kits: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("can_edit_kits?", Json_decode.bool, param);
                }), json),
          can_edit_channel_mapping: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("can_edit_channel_mapping?", Json_decode.bool, param);
                }), json),
          has_de_analysis: Json_decode.field("has_de_analysis?", Json_decode.bool, json),
          is_archived: Json_decode.field("is_archived", Json_decode.bool, json),
          pipeline_job_percentages: Json_decode.field("pipeline_job_percentages", pipelineJobPercentages, json),
          qc_metric_thresholds: Js_dict.fromArray($$Array.map((function (t) {
                      return /* tuple */[
                              t.metric_name,
                              t
                            ];
                    }), Json_decode.withDefault([], (function (param) {
                          return Json_decode.field("qc_metric_thresholds", (function (param) {
                                        return Json_decode.array(qcMetricThreshold, param);
                                      }), param);
                        }), json))),
          labeling_strategies: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("labeling_strategies", (function (param) {
                                return Json_decode.array(labelingStrategy, param);
                              }), param);
                }), json),
          created_at_ymd: Json_decode.field("created_at_ymd", Json_decode.string, json),
          create_user_email: Json_decode.field("create_user_email", Json_decode.string, json),
          authorized_for_user_id: authorized_for_user_id$1(json)
        };
}

function experiments(json) {
  return ({
            experiments: Json_decode.field("experiments", (function (param) {
                    return Json_decode.array(experiment, param);
                  }), json)
          }).experiments;
}

var Decode = {
  id: id$1,
  is_archived: is_archived$1,
  is_public: is_public,
  name: name$1,
  status: status$1,
  instrument: instrument,
  organism: organism$1,
  created_at_ymd: created_at_ymd$1,
  create_user_email: create_user_email$1,
  authorized_for_user_id: authorized_for_user_id$1,
  tissue_type: tissue_type,
  pipelineJobPercentages: pipelineJobPercentages,
  qcMetricThreshold: qcMetricThreshold,
  labelingStrategy: labelingStrategy,
  experiment: experiment,
  experiments: experiments
};

function pipelineJobPercentages$1(pjp) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "unstarted",
                pjp.unstarted
              ],
              /* :: */[
                /* tuple */[
                  "created",
                  pjp.created
                ],
                /* :: */[
                  /* tuple */[
                    "started",
                    pjp.started
                  ],
                  /* :: */[
                    /* tuple */[
                      "finished",
                      pjp.finished
                    ],
                    /* :: */[
                      /* tuple */[
                        "errored",
                        pjp.errored
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function qcMetricThresholds(qcmts) {
  return $$Array.map((function (qcmt) {
                return Json_encode.object_(/* :: */[
                            /* tuple */[
                              "id",
                              qcmt.id
                            ],
                            /* :: */[
                              /* tuple */[
                                "experiment_id",
                                qcmt.experiment_id
                              ],
                              /* :: */[
                                /* tuple */[
                                  "metric_name",
                                  qcmt.metric_name
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "min",
                                    qcmt.min
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "max",
                                      qcmt.max
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]);
              }), Js_dict.values(qcmts));
}

function experiment$1(experiment$2) {
  return new Models.Experiment(Json_encode.object_(/* :: */[
                  /* tuple */[
                    "id",
                    experiment$2.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "is_archived",
                      experiment$2.is_archived
                    ],
                    /* :: */[
                      /* tuple */[
                        "is_public",
                        experiment$2.is_public
                      ],
                      /* :: */[
                        /* tuple */[
                          "name",
                          experiment$2.name
                        ],
                        /* :: */[
                          /* tuple */[
                            "status",
                            experiment$2.status
                          ],
                          /* :: */[
                            /* tuple */[
                              "organism",
                              toRailsJsonString(experiment$2.organism)
                            ],
                            /* :: */[
                              /* tuple */[
                                "tissue_type",
                                toRailsJsonString$2(experiment$2.tissue_type)
                              ],
                              /* :: */[
                                /* tuple */[
                                  "display_live_dead",
                                  experiment$2.display_live_dead
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "instrument",
                                    toRailsJsonString$1(experiment$2.instrument)
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "editable?",
                                      experiment$2.editable
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "can_edit_analyses?",
                                        experiment$2.can_edit_analyses
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "can_edit_features?",
                                          experiment$2.can_edit_features
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "has_de_analysis?",
                                            experiment$2.has_de_analysis
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "can_edit_kits?",
                                              experiment$2.can_edit_kits
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "can_edit_channel_mapping?",
                                                experiment$2.can_edit_channel_mapping
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "pipeline_job_percentages",
                                                  pipelineJobPercentages$1(experiment$2.pipeline_job_percentages)
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "qc_metric_thresholds",
                                                    qcMetricThresholds(experiment$2.qc_metric_thresholds)
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      "created_at_ymd",
                                                      experiment$2.created_at_ymd
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        "create_user_email",
                                                        experiment$2.create_user_email
                                                      ],
                                                      /* :: */[
                                                        /* tuple */[
                                                          "display_live_dead",
                                                          experiment$2.display_live_dead
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            "authorized_for_user_id",
                                                            Json_encode.nullable((function (prim) {
                                                                    return prim;
                                                                  }), experiment$2.authorized_for_user_id)
                                                          ],
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

var Encode = {
  pipelineJobPercentages: pipelineJobPercentages$1,
  qcMetricThresholds: qcMetricThresholds,
  experiment: experiment$1
};

function fetchAllJson(param) {
  return FetchUtils$Astrolabe.getString("/experiments.json").then((function (text) {
                var json = Json.parseOrRaise(text);
                var items = Js_json.decodeObject(json);
                if (items === undefined) {
                  return Pervasives.failwith("Invalid experiments.json format: expected object but got: " + text);
                }
                var experimentsJson = Js_json.decodeArray(Caml_option.valFromOption(items)["experiments"]);
                if (experimentsJson !== undefined) {
                  return Promise.resolve(experimentsJson);
                } else {
                  return Pervasives.failwith("Invalid experiments.json format: experiments key is not an array");
                }
              }));
}

function fetchAll(param) {
  return FetchUtils$Astrolabe.getString("/experiments.json").then((function (text) {
                return Promise.resolve(experiments(Json.parseOrRaise(text)));
              }));
}

function fetchOne(id) {
  return FetchUtils$Astrolabe.getString("/experiments/" + (String(id) + ".json")).then((function (text) {
                return Promise.resolve(experiment(Json.parseOrRaise(text)));
              }));
}

function canEditChannelMapping(self) {
  return self.canEditChannelMapping;
}

function canEditKits(self) {
  return self.canEditKits;
}

function channelsForLiveDeadSelection(self) {
  return self.channelsForLiveDeadSelection;
}

function createdAt(self) {
  return self.createdAt;
}

function createUserEmail(self) {
  return self.createUserEmail;
}

function deAnalysisKits(self) {
  return self.deAnalysisKits;
}

function flags(self) {
  return self.flags;
}

function hasBeenAnalyzed(self) {
  return self.hasBeenAnalyzed;
}

function id$2(self) {
  return self.id;
}

function isArchived(self) {
  return self.isArchived;
}

function isEditable(self) {
  return self.isEditable;
}

function liveDeadChannel(self) {
  return self.liveDeadChannel;
}

function instrument$1(self) {
  return self.instrument;
}

function name$2(self) {
  return self.name;
}

function organism$2(self) {
  return self.organism;
}

function status$2(self) {
  return self.status;
}

function tissueType(self) {
  return self.tissueType;
}

var Js = {
  canEditChannelMapping: canEditChannelMapping,
  canEditKits: canEditKits,
  channelsForLiveDeadSelection: channelsForLiveDeadSelection,
  createdAt: createdAt,
  createUserEmail: createUserEmail,
  deAnalysisKits: deAnalysisKits,
  flags: flags,
  hasBeenAnalyzed: hasBeenAnalyzed,
  id: id$2,
  isArchived: isArchived,
  isEditable: isEditable,
  liveDeadChannel: liveDeadChannel,
  instrument: instrument$1,
  name: name$2,
  organism: organism$2,
  status: status$2,
  tissueType: tissueType
};

exports.PipelineJobPercentages = PipelineJobPercentages;
exports.QcMetricThreshold = QcMetricThreshold;
exports.LabelingStrategy = LabelingStrategy;
exports.Status = Status;
exports.Organism = Organism;
exports.Instrument = Instrument;
exports.TissueType = TissueType;
exports.id = id;
exports.is_archived = is_archived;
exports.name = name;
exports.status = status;
exports.organism = organism;
exports.created_at_ymd = created_at_ymd;
exports.create_user_email = create_user_email;
exports.authorized_for_user_id = authorized_for_user_id;
exports.statusToDisplayString = statusToDisplayString;
exports.displayStatus = displayStatus;
exports.displayTissueType = displayTissueType;
exports.Decode = Decode;
exports.Encode = Encode;
exports.fetchAllJson = fetchAllJson;
exports.fetchAll = fetchAll;
exports.fetchOne = fetchOne;
exports.Js = Js;
/* models Not a pure module */
