// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Constants$Astrolabe = require("../../utils/Constants.bs.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");
var DeletePipelinesButton$Astrolabe = require("./DeletePipelinesButton.bs.js");
var RewindDebarcodingButton$Astrolabe = require("./RewindDebarcodingButton.bs.js");

function reducer(state, action) {
  if (action.tag) {
    return {
            experimentFlags: state.experimentFlags,
            experimentStatus: action[0]
          };
  } else {
    return {
            experimentFlags: action[0],
            experimentStatus: state.experimentStatus
          };
  }
}

function SysadminSettings(Props) {
  var experiment = Props.experiment;
  var onDone = Props.onDone;
  var experimentStatus = Experiment$Astrolabe.Js.status(experiment);
  var initialState_experimentFlags = Experiment$Astrolabe.Js.flags(experiment);
  var initialState = {
    experimentFlags: initialState_experimentFlags,
    experimentStatus: experimentStatus
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var experimentId = Experiment$Astrolabe.Js.id(experiment);
  var featureFlagStatus = React.createElement("div", {
        className: "row ml-1"
      }, [
          "enabled",
          "disabled"
        ].map((function (featureFlagStatus, index) {
              var radioID = featureFlagStatus + String(index);
              return React.createElement("div", {
                          key: radioID,
                          className: "form-check col-6"
                        }, React.createElement("input", {
                              className: "form-check-input",
                              id: radioID,
                              checked: featureFlagStatus === "disabled",
                              name: "featureFlag",
                              type: "radio",
                              value: String(index),
                              onChange: (function (param) {
                                  
                                })
                            }), React.createElement("label", {
                              className: "form-check-label",
                              htmlFor: radioID
                            }, Curry._1(Util$Astrolabe.$$String.capitalize, featureFlagStatus)));
            })));
  var statusInput = React.createElement("select", {
        className: "form-control form-control-sm",
        value: Experiment$Astrolabe.Status.toDisplayString(state.experimentStatus),
        onChange: (function (evt) {
            var status = Experiment$Astrolabe.Status.fromDisplayString(ReasonReactHelpers$Astrolabe.valueFromEvent(evt));
            return Curry._1(dispatch, /* SetExperimentStatus */Block.__(1, [status]));
          })
      }, Experiment$Astrolabe.Status.all.map((function (status) {
              return React.createElement("option", {
                          key: Experiment$Astrolabe.Status.toDisplayString(status),
                          value: Experiment$Astrolabe.Status.toDisplayString(status)
                        }, Experiment$Astrolabe.Status.toDisplayString(status));
            })));
  var flagsInput = React.createElement("input", {
        className: "form-control form-control-sm",
        type: "text",
        value: state.experimentFlags,
        onChange: (function (evt) {
            return Curry._1(dispatch, /* SetExperimentFlags */Block.__(0, [ReasonReactHelpers$Astrolabe.valueFromEvent(evt)]));
          })
      });
  var deleteAnalysisPipelinesButton = React.createElement(DeletePipelinesButton$Astrolabe.make, {
        experimentId: experimentId,
        disabled: !(experimentStatus === /* DONE */758036610 || experimentStatus === /* PENDING_ANALYSIS */175814564),
        dagType: "analysis"
      });
  var deleteDebarcodingPipelinesButton = React.createElement(DeletePipelinesButton$Astrolabe.make, {
        experimentId: experimentId,
        disabled: !(experimentStatus === /* DONE */758036610 || experimentStatus === /* PENDING_ANALYSIS */175814564),
        dagType: "debarcoding"
      });
  var rewindDebarcodingButton = React.createElement(RewindDebarcodingButton$Astrolabe.make, {
        experimentId: experimentId,
        disabled: !(experimentStatus === /* MAPPING_BARCODING_CHANNELS */-408660381 || experimentStatus === /* PENDING_ANALYSIS */175814564)
      });
  var doneButton = React.createElement(ExperimentGraphQL$Astrolabe.UpdateMutation.make, {
        children: (function (mutation, param) {
            return React.createElement("div", {
                        className: "col-12"
                      }, React.createElement("div", {
                            className: "row justify-content-end"
                          }, React.createElement("button", {
                                className: "btn btn-primary",
                                onClick: (function (param) {
                                    GraphQLHelpers$Astrolabe.handleDataAndErrors((function (_data) {
                                            return ReasonReactHelpers$Astrolabe.redirect(Constants$Astrolabe.experimentIdPath(experimentId));
                                          }), Curry._4(mutation, Caml_option.some(ExperimentGraphQL$Astrolabe.UpdateConfig.make(experimentId, undefined, state.experimentFlags, undefined, undefined, undefined, undefined, undefined, state.experimentStatus, undefined, undefined).variables), ["getExperiment"], undefined, undefined));
                                    return Curry._1(onDone, undefined);
                                  })
                              }, "Done")));
          })
      });
  var row = function (labelText, valueElement) {
    return React.createElement("tr", {
                className: "row d-flex align-items-center justify-content-between"
              }, React.createElement("td", {
                    className: "col-4 font-weight-bold px-0 border-0",
                    style: {
                      marginLeft: "7%"
                    }
                  }, labelText), React.createElement("td", {
                    className: "col-6 px-0 border-0",
                    style: {
                      marginRight: "7%"
                    }
                  }, valueElement));
  };
  return React.createElement("div", {
              className: "m-3"
            }, React.createElement("div", {
                  className: "d-flex justify-content-center"
                }, React.createElement("table", {
                      className: "table table-striped"
                    }, React.createElement("tbody", undefined, row("FeatureFlag", featureFlagStatus), row("Status", statusInput), row("Flags", flagsInput)))), React.createElement("div", {
                  className: "col-12"
                }, React.createElement("div", {
                      className: "row d-flex flex-column justify-content-center children-mb-1"
                    }, deleteAnalysisPipelinesButton, deleteDebarcodingPipelinesButton, rewindDebarcodingButton)), doneButton);
}

var UpdateExperimentConfig;

var UpdateExperimentMutation;

var make = SysadminSettings;

exports.UpdateExperimentConfig = UpdateExperimentConfig;
exports.UpdateExperimentMutation = UpdateExperimentMutation;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
