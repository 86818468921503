/* @flow */
"use strict";

import SortTemplate from "./sortTemplate";
import { ensureArray } from "../components/utils";

type Props = {
  rowLabels: Array<string>,
  columnLabels: Array<string>,
  columns: { [string]: Array<any> }, // Array of columns
};

export default class DataTable {
  length: number;
  rowLabels: Array<string>;
  columnLabels: Array<string>;
  columns: { [string]: Array<any> };
  _columnLabelsToIndex: { [string]: any };
  _JSONRows: Array<{ [string]: any }>;

  constructor(props: Props) {
    this.rowLabels = props.rowLabels || [];
    this.columns = props.columns;
    this.columnLabels = props.columnLabels || [];

    const numCols = Object.keys(this.columns).length;
    this.length = (this.columns[this.columnLabels[0]] || []).length;

    if (!this.columnLabels.every((label) => (this.columns[label] || []).length === this.length)) {
      throw `All columns must have length ${this.length}`;
    }

    if (numCols !== this.columnLabels.length) {
      throw `Number of columns ${numCols} doesn't match number of column labels ${this.columnLabels.length}`;
    }

    this._columnLabelsToIndex = {};
    this.columnLabels.forEach((label, i) => (this._columnLabelsToIndex[label] = i));

    this._JSONRows = [];
  }

  static empty(): DataTable {
    return new DataTable({ rowLabels: [], columnLabels: [], columns: {} });
  }

  toColumnsArray(): Array<Array<Number>> {
    var acc = [];
    this.columnLabels.forEach((colName) => {
      acc.push(this.columns[colName])
    });
    return acc;
  }

  _columnToJSON(columnLabel: string): { [string]: any } {
    const column = this.columns[columnLabel];

    let obj = { columnLabel };
    this.rowLabels.forEach((rowLabel, i) => (obj[rowLabel] = column[i]));
    return obj;
  }

  _rowToJSON(rowIndex: number): { [string]: any } {
    if (this._JSONRows[rowIndex]) {
      return { ...this._JSONRows[rowIndex] };
    }
    let obj = { rowLabel: this.rowLabels[rowIndex] };
    this.columnLabels.forEach((columnLabel, i) => (obj[columnLabel] = this.columns[columnLabel][rowIndex]));

    this._JSONRows[rowIndex] = obj;
    return { ...obj };
  }

  select(columnLabels: Array<string>): DataTable {
    let columns = {};
    columnLabels.forEach((label) => (columns[label] = this.columns[label]));

    return new DataTable({
      columns,
      columnLabels,
      rowLabels: this.rowLabels,
    });
  }

  filterByRowLabel(fn: (rowLabel: string) => boolean): DataTable {
    const rowInclusions = this.rowLabels.map((v) => fn(v));

    if (rowInclusions.every((v) => v === true)) {
      return this;
    }

    let columns = {};
    let rowLabels = this.rowLabels.filter((_, i) => rowInclusions[i]);

    this.columnLabels.forEach((columnLabel) => {
      rowInclusions.forEach((included, i) => {
        columns[columnLabel] = columns[columnLabel] || [];
        if (included) {
          columns[columnLabel].push(this.columns[columnLabel][i]);
        }
      });
    });

    return new DataTable({ columns, columnLabels: this.columnLabels, rowLabels });
  }

  sortByRowLabel(ascending?: boolean = true): DataTable {
    const collator = new window.Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    return this.applySort(this.rowLabels.concat().sort(collator.compare), ascending);
  }

  sortBy(columnLabel: string, ascending?: boolean = true): DataTable {
    return this._sortWithTemplate(SortTemplate.fromArray(this.columns[columnLabel]), ascending);
  }

  applySort(sortedRowLabels: Array<string>, ascending?: boolean = true): DataTable {
    return this._sortWithTemplate(
      new SortTemplate(
        sortedRowLabels.map((label, i) => {
          return { initialIndex: this.rowLabels.indexOf(label), value: label };
        }),
      ),
      ascending,
    );
  }

  _sortWithTemplate(sortTemplate: SortTemplate, ascending?: boolean = true): DataTable {
    let columns = {};
    Object.keys(this.columns).forEach((label) => {
      // This is for single sample experiments where the column label comes in from the yaml without an array
      const columnLabel = ensureArray(this.columns[label]);
      columns[label] = sortTemplate.sort(columnLabel, ascending);
    });

    return new DataTable({
      columns: columns,
      columnLabels: this.columnLabels,
      rowLabels: sortTemplate.sort(this.rowLabels, ascending),
    });
  }

  toOrderedColumns(): Array<Array<any>> {
    return this.columnLabels.map((label) => this.columns[label]);
  }

  toOrderedJSONColumns(): Array<{ [string]: any }> {
    return this.columnLabels.map((label) => this._columnToJSON(label));
  }

  toJSONColumns(): { [string]: { [string]: any } } {
    let obj = {};
    this.columnLabels.forEach((colLabel) => (obj[colLabel] = this._columnToJSON(colLabel)));
    return obj;
  }

  toOrderedJSONRows(): Array<{ [string]: any }> {
    return this.rowLabels.map((_label, i) => this._rowToJSON(i));
  }

  transpose(): DataTable {
    let columns = {};
    this.rowLabels.forEach((rowLabel, rowIndex) => {
      columns[rowLabel] = this.columnLabels.map((columnLabel) => this.columns[columnLabel][rowIndex]);
    });

    return new DataTable({
      columns,
      columnLabels: this.rowLabels,
      rowLabels: this.columnLabels,
    });
  }
}
