// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var CircleIcon$Astrolabe = require("./CircleIcon.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function container(className, borderColorOpt, name, content) {
  var borderColor = borderColorOpt !== undefined ? borderColorOpt : "#E7E7E7";
  return React.createElement("div", {
              className: className + " d-flex align-items-center",
              style: {
                background: "#E7E7E7",
                borderColor: borderColor,
                borderStyle: "solid",
                borderWidth: "2px",
                color: "#808080",
                fontSize: "80%",
                padding: "4px",
                borderRadius: "25px"
              }
            }, React.createElement("span", {
                  className: "mx-1"
                }, ReasonReactHelpers$Astrolabe.str(name)), content);
}

function ButtonWithOnClick(Props) {
  var buttonTextOpt = Props.buttonText;
  var name = Props.name;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var borderColor = Props.borderColor;
  var buttonText = buttonTextOpt !== undefined ? buttonTextOpt : "X";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return container(className, borderColor, name, React.createElement("div", {
                  onClick: (function (param) {
                      return Curry._1(onClick, name);
                    })
                }, React.createElement(CircleIcon$Astrolabe.make, {
                      backgroundColor: /* CssStyle */Block.__(1, ["#D8D8D8"]),
                      cursor: "pointer",
                      size: 20,
                      textColor: /* CssStyle */Block.__(1, ["#808080"]),
                      tooltip: "",
                      text: buttonText
                    })));
}

var make = ButtonWithOnClick;

exports.container = container;
exports.make = make;
/* react Not a pure module */
