// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("bs-platform/lib/js/string.js");
var GraphQL$Astrolabe = require("./GraphQL.bs.js");

function alert_style_of_string(str) {
  var str$1 = $$String.uppercase_ascii(str);
  switch (str$1) {
    case "DANGER" :
        return /* DANGER */-787153181;
    case "DARK" :
        return /* DARK */757341302;
    case "INFO" :
        return /* INFO */813432942;
    case "LIGHT" :
        return /* LIGHT */-219494218;
    case "PRIMARY" :
        return /* PRIMARY */55579554;
    case "SECONDARY" :
        return /* SECONDARY */-577585324;
    case "SUCCESS" :
        return /* SUCCESS */941750691;
    case "WARNING" :
        return /* WARNING */161459772;
    default:
      throw [
            GraphQL$Astrolabe.Types.InvalidInputExn,
            str$1
          ];
  }
}

function string_of_alert_style($$enum) {
  if ($$enum >= 161459772) {
    if ($$enum >= 813432942) {
      if ($$enum >= 941750691) {
        return "success";
      } else {
        return "info";
      }
    } else if ($$enum >= 757341302) {
      return "dark";
    } else {
      return "warning";
    }
  } else if ($$enum >= -219494218) {
    if ($$enum >= 55579554) {
      return "primary";
    } else {
      return "light";
    }
  } else if ($$enum >= -577585324) {
    return "secondary";
  } else {
    return "danger";
  }
}

function displayAlertStyle(n) {
  var match = n.alertStyle;
  switch (match) {
    case "danger" :
        return "danger";
    case "dark" :
        return "dark";
    case "info" :
        return "info";
    case "light" :
        return "light";
    case "primary" :
        return "primary";
    case "secondary" :
        return "secondary";
    case "success" :
        return "success";
    case "warning" :
        return "warning";
    default:
      return "";
  }
}

function tToJs(param) {
  return {
          id: param.id,
          title: param.title,
          text: param.text,
          alertStyle: param.alertStyle
        };
}

function tFromJs(param) {
  return {
          id: param.id,
          title: param.title,
          text: param.text,
          alertStyle: param.alertStyle
        };
}

var GraphQL = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  fromJs: tFromJs,
  toJs: tToJs
};

exports.alert_style_of_string = alert_style_of_string;
exports.string_of_alert_style = string_of_alert_style;
exports.displayAlertStyle = displayAlertStyle;
exports.GraphQL = GraphQL;
/* No side effect */
