// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Util$Astrolabe = require("./Util.bs.js");
var D3ScaleChromatic = require("d3-scale-chromatic");

var dark2 = [
  "#1B9E77",
  "#D95F02",
  "#7570B3",
  "#E7298A",
  "#66A61E",
  "#E6AB02",
  "#A6761D",
  "#666666"
];

var redWhiteBlue = [
  "#172C5F",
  "#3A60A9",
  "#5A8EC0",
  "#9DC2DC",
  "#D5E4EF",
  "#F7F7F7",
  "#F7DEC9",
  "#E9AC86",
  "#C96B51",
  "#A5322F",
  "#5F1320"
];

var greens = Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemeGreens));

var blues = Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemeBlues));

var purples = Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemePurples));

var rdBu = Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemeRdBu));

var buRd = Util$Astrolabe.$$Array.reverse(Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemeRdBu)));

var ylOrBr = Util$Astrolabe.$$Option.force(Util$Astrolabe.$$Array.last(D3ScaleChromatic.schemeYlOrBr));

var Palette = {
  dark2: dark2,
  redWhiteBlue: redWhiteBlue,
  greens: greens,
  blues: blues,
  purples: purples,
  rdBu: rdBu,
  buRd: buRd,
  ylOrBr: ylOrBr
};

var $$Array;

var $$Option;

exports.$$Array = $$Array;
exports.$$Option = $$Option;
exports.Palette = Palette;
exports.dark2 = dark2;
exports.redWhiteBlue = redWhiteBlue;
exports.purples = purples;
exports.ylOrBr = ylOrBr;
/* greens Not a pure module */
