/* @flow */
"use strict";

import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import ExperimentIndex from "./home/ExperimentIndex.bs";
import ExperimentNew from "./experiments/New";
import ExperimentView from "./experiments/View";
import PipelineRunIndex from "./pipeline_runs/Index";
import PipelineRunShow from "./pipeline_runs/Show";
import SampleShow from "./samples/Show";
import SignIn from "./users/SignIn";
import PasswordNew from "./passwords/New";
import PasswordEdit from "./passwords/Edit";

import AntibodyStainingIndex from "./antibody_staining/AntibodyStainingIndex.bs";

import AdminInstanceIndex from "./admin/instances/Index";
import AdminInstanceNew from "./admin/instances/New";
import AdminInstanceEdit from "./admin/instances/Edit";
import AdminUserIndex from "./admin/users/Index";

import Nav from "./Nav";
import NavTitle from "./NavTitle";
import NoUserRoute from "./NoUserRoute";
import UserRoute from "./UserRoute";
import type { Session } from "models";
import { fetchJSON } from "./utils.js";

type Props = any;
type State = {
  hasError: boolean,
  navTitle: ?string,
  session: Session,
  sessionLoaded: boolean,
};

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
});

class App extends React.Component<Props, State> {
  handleSignIn: (Session) => void;
  handleSignOut: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      navTitle: null,
      session: { user: null, instance: null, csrf_token: window.csrfToken },
      sessionLoaded: false,
    };
    window.setNavTitle = this.setTitle;
    this.fetchSession();
  }

  setTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  async fetchSession() {
    var session = await fetchJSON("GET", "/session.json");
    this.setState({ session, sessionLoaded: true });
  }

  handleSignIn = (session: Session) => {
    window.csrfToken = session.csrf_token;
    this.setState({ session, sessionLoaded: true });
  };

  handleSignOut = () => {
    this.signOut();
  };

  async signOut() {
    var session = await fetchJSON("DELETE", "/users/sign_out.json");
    window.csrfToken = session.csrf_token;
    this.setState({ session });
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    this.setState({ hasError: true });

    if (window.location.search.match(/log_error=true/) != null) {
      console.error(error);
      console.error(info.componentStack);
    }
  }

  isPublicExp = window.location.pathname.startsWith("/public");

  render() {
    if (!this.state.sessionLoaded) {
      return <h3>Loading…</h3>;
    }

    if (this.state.hasError) {
      return (
        <Router>
          <React.Fragment>
            <Nav navTitle={this.state.navTitle} session={this.state.session} onSignOut={this.handleSignOut} />
            <h3>Something went wrong.</h3>
            <p>
              You can try{" "}
              <a href={window.location.toString()} onClick={() => window.location.reload()}>
                reloading the page
              </a>{" "}
              and trying again. If the problem persists, please contact{" "}
              <a href="mailto:support@astrolabediagnostics.com">support</a>.
            </p>
          </React.Fragment>
        </Router>
      );
    }

    return (
      <ApolloProvider client={apolloClient}>
        <Router>
          <React.Fragment>
            <Nav navTitle={this.state.navTitle} session={this.state.session} onSignOut={this.handleSignOut} />
            <Switch>
              {this.state.session.user ? (
                <UserRoute exact session={this.state.session} path="/" component={ExperimentIndex} />
              ) : (
                <Route exact path="/" render={() => (window.location = "https://astrolabediagnostics.com")} />
              )}
              <Route exact session={this.state.session} path="/antibody_staining_data_set" component={AntibodyStainingIndex} />
              <Route
                exact
                path="/(sign_in|users/sign_in)"
                render={(props) => <SignIn {...props} session={this.state.session} onSignIn={this.handleSignIn} />}
              />
              <Route exact path="/forgot_password" render={(props) => <PasswordNew {...props} session={this.state.session} />} />
              <Route
                exact
                path="/reset_password/:token"
                render={(props) => <PasswordEdit {...props} session={this.state.session} onSignIn={this.handleSignIn} />}
              />
              <UserRoute
                exact
                isPublicExp={this.isPublicExp}
                session={this.state.session}
                path="(/|/public/)experiments/:experimentId(\d+)/samples/:sampleId(\d+)/explorer"
                component={SampleShow}
              />
              <UserRoute exact session={this.state.session} path="/experiments/new" component={ExperimentNew} />
              <UserRoute
                isPublicExp={this.isPublicExp}
                session={this.state.session}
                path="(/|/public/)experiments/:id(\d+)"
                component={ExperimentView}
              />
              <UserRoute exact session={this.state.session} path="/pipeline_runs/" component={PipelineRunIndex} />
              <UserRoute exact session={this.state.session} path="/pipeline_runs/:id(\d+)" component={PipelineRunShow} />
              <UserRoute
                roleName="staff"
                exact
                session={this.state.session}
                path="/admin/instances(/sample_history)?"
                component={AdminInstanceIndex}
              />
              <UserRoute
                roleName="staff"
                exact
                session={this.state.session}
                path="/admin/instances/new"
                component={AdminInstanceNew}
              />
              <UserRoute
                roleName="staff"
                exact
                session={this.state.session}
                path="/admin/instances/:id/(edit|users|files)"
                component={AdminInstanceEdit}
              />
              <UserRoute roleName="admin" exact session={this.state.session} path="/admin/users" component={AdminUserIndex} />
              /** Catch all route */
              <Route render={(props) => <SignIn {...props} session={this.state.session} onSignIn={this.handleSignIn} />} />
            </Switch>
          </React.Fragment>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
