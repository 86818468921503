// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/src/Json.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var FetchUtils$Astrolabe = require("../../../utils/FetchUtils.bs.js");
var AddFilesButton$Astrolabe = require("../../shared/AddFilesButton.bs.js");
var InstanceFileTable$Astrolabe = require("./InstanceFileTable.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");

function InstanceFileView(Props) {
  var instanceId = Props.instanceId;
  var match = React.useState((function () {
          return false;
        }));
  var setIsUploading = match[1];
  if (match[0]) {
    return React.createElement("div", undefined, "Please wait while the files upload. Do not close the page.");
  } else {
    return React.createElement("div", {
                className: "d-flex flex-column align-items-end children-mb-3"
              }, React.createElement(AddFilesButton$Astrolabe.make, {
                    handleUpload: (function (files) {
                        Curry._1(setIsUploading, (function (param) {
                                return true;
                              }));
                        files.forEach((function (file, index) {
                                var formData = new FormData();
                                formData.append("file", file);
                                var uploadUrl = "/admin/instances/" + (instanceId + "/instance_files.json");
                                FetchUtils$Astrolabe.post(uploadUrl, formData).then((function (text) {
                                        var json = Json.parseOrRaise(text);
                                        var success = Curry._1(FetchUtils$Astrolabe.$$Response.Decode.success, json);
                                        if (!success) {
                                          console.log({
                                                "errors occurred": $$Array.map((function (prim) {
                                                        return prim;
                                                      }), Curry._1(FetchUtils$Astrolabe.$$Response.Decode.errors, json))
                                              });
                                        }
                                        return Promise.resolve(index === (files.length - 1 | 0) ? ReasonReactHelpers$Astrolabe.reload(undefined) : (setTimeout((function (param) {
                                                              
                                                            }), 5000), undefined));
                                      }));
                                
                              }));
                        
                      }),
                    name: "Files"
                  }), React.createElement(InstanceFileTable$Astrolabe.make, {
                    instanceId: instanceId
                  }));
  }
}

var make = InstanceFileView;

var $$default = InstanceFileView;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
