// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");

function ErrorsAlert(Props) {
  var title = Props.title;
  var errors = Props.errors;
  if (errors.length !== 0) {
    return React.createElement("div", {
                className: "row align-items-center justify-content-center"
              }, React.createElement("div", {
                    className: "alert alert-danger",
                    role: "alert"
                  }, React.createElement("p", {
                        className: "mb-1"
                      }, title), React.createElement("ul", {
                        className: "mb-0"
                      }, $$Array.mapi((function (i, error) {
                              return React.createElement("li", {
                                          key: String(i)
                                        }, error);
                            }), errors))));
  } else {
    return null;
  }
}

var make = ErrorsAlert;

exports.make = make;
/* react Not a pure module */
