// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Victory = require("victory");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var Theme$Astrolabe = require("../models/Theme.bs.js");

var fontFamily = "'Rubik', 'Lucida Grande', 'Lucida Sans', Arial, 'sans-serif'";

var baseLabelStyles = {
  fontFamily: fontFamily,
  fontSize: 12,
  padding: 5
};

var baseBoxPlotStyles = {
  stroke: (function (d) {
      return Util$Astrolabe.$$Array.getOrFail(Caml_int32.mod_(Util$Astrolabe.Unsafe.objGetInt(d, "eventKey"), Theme$Astrolabe.dark2.length), Theme$Astrolabe.dark2);
    }),
  fill: (function (d) {
      return Util$Astrolabe.$$Array.getOrFail(Caml_int32.mod_(Util$Astrolabe.Unsafe.objGetInt(d, "eventKey"), Theme$Astrolabe.dark2.length), Theme$Astrolabe.dark2);
    })
};

var baseBoxPlotLabelStyles = {
  fontFamily: fontFamily,
  fontSize: 10,
  padding: 5,
  fill: (function (d) {
      return Util$Astrolabe.$$Array.getOrFail(Util$Astrolabe.Unsafe.objGetInt(d, "eventKey"), Theme$Astrolabe.dark2);
    }),
  stroke: "transparent"
};

var noGridVariant = {
  style: {
    axis: {
      stroke: "#ddd"
    },
    axisLabel: {
      fontFamily: fontFamily,
      fontSize: 11,
      padding: 30
    },
    grid: {
      stroke: "transparent",
      size: 0
    },
    ticks: {
      stroke: Victory.VictoryTheme.grayscale.axis.style.axis.stroke,
      size: 5
    },
    tickLabels: {
      fontFamily: fontFamily,
      padding: 5,
      fontSize: 11
    }
  }
};

var theme = {
  axis: {
    style: {
      axisLabel: {
        fontFamily: fontFamily,
        fontSize: 12,
        padding: 25
      },
      tickLabels: baseLabelStyles,
      grid: {
        stroke: "#eee",
        size: 5
      }
    }
  },
  boxplot: {
    boxWidth: 20,
    style: {
      min: baseBoxPlotStyles,
      q1: baseBoxPlotStyles,
      median: {
        stroke: "white",
        strokeWidth: 1
      },
      q3: baseBoxPlotStyles,
      max: baseBoxPlotStyles,
      minLabels: baseBoxPlotLabelStyles,
      q1Labels: baseBoxPlotLabelStyles,
      medianLabels: baseBoxPlotLabelStyles,
      q3Labels: baseBoxPlotLabelStyles,
      maxLabels: baseBoxPlotLabelStyles
    }
  },
  group: {
    colorScale: Theme$Astrolabe.dark2,
    style: {
      data: {
        strokeWidth: 1,
        fillOpacity: 0.6
      }
    }
  },
  legend: {
    colorScale: Theme$Astrolabe.dark2,
    orientation: "horizontal",
    gutter: 15,
    style: {
      parent: {
        padding: 5
      },
      labels: Victory.VictoryTheme.grayscale.legend.style.labels
    }
  },
  tooltip: {
    cornerRadius: 0.0,
    style: {
      padding: 5,
      textAnchor: "middle"
    },
    flyoutStyle: {
      fill: "white",
      stroke: "#ccc",
      strokeWidth: 1
    }
  },
  variants: {
    axis: {
      boxplot: {
        style: {
          axis: {
            stroke: "#ddd"
          },
          axisLabel: {
            fontFamily: fontFamily,
            fontSize: 11,
            padding: 30
          },
          grid: {
            stroke: "transparent",
            size: 0
          },
          ticks: {
            stroke: Victory.VictoryTheme.grayscale.axis.style.axis.stroke,
            size: 5
          },
          colorScale: Theme$Astrolabe.dark2,
          tickLabels: {
            fontFamily: fontFamily,
            fontSize: 12,
            angle: -90,
            padding: 5,
            verticalAnchor: "middle",
            textAnchor: "end"
          }
        }
      },
      matchingNivo: {
        style: {
          axis: {
            stroke: "#ddd"
          },
          axisLabel: {
            fontFamily: fontFamily,
            fontSize: 11,
            padding: 40
          },
          ticks: {
            stroke: Victory.VictoryTheme.grayscale.axis.style.axis.stroke,
            size: 5
          },
          tickLabels: {
            fontFamily: fontFamily,
            fontSize: 11
          }
        }
      },
      mdsMap: {
        style: {
          axis: {
            stroke: "#ddd"
          },
          grid: {
            stroke: "transparent",
            size: 0
          },
          tickLabels: {
            fontFamily: fontFamily,
            padding: 5,
            fontSize: 11
          },
          axisLabel: {
            fontFamily: fontFamily,
            fontSize: 11,
            padding: 10
          },
          ticks: {
            stroke: "#fff",
            size: 0
          }
        }
      },
      sparkLine: {
        style: {
          axis: {
            padding: 0
          },
          axisLabel: {
            padding: 0
          },
          grid: {
            stroke: "transparent",
            size: 0
          },
          ticks: {
            stroke: Victory.VictoryTheme.grayscale.axis.style.axis.stroke,
            size: 0
          },
          tickLabels: {
            fontSize: 0
          }
        }
      },
      noGrid: noGridVariant
    }
  }
};

var $$Array;

var fontSize = 12;

var padding = 5;

exports.$$Array = $$Array;
exports.fontFamily = fontFamily;
exports.fontSize = fontSize;
exports.padding = padding;
exports.baseLabelStyles = baseLabelStyles;
exports.baseBoxPlotStyles = baseBoxPlotStyles;
exports.baseBoxPlotLabelStyles = baseBoxPlotLabelStyles;
exports.noGridVariant = noGridVariant;
exports.theme = theme;
/* noGridVariant Not a pure module */
