// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_null = require("bs-platform/lib/js/js_null.js");

function optionToBool(optional) {
  return optional !== undefined;
}

function makeProps(_to, children, param) {
  return {
          to: _to,
          children: children
        };
}

var Link = {
  makeProps: makeProps
};

function makeProps$1(_to, activeClassName, style, activeStyle, className, exact, children, param) {
  return {
          to: _to,
          activeClassName: Js_null.fromOption(activeClassName),
          activeStyle: Js_null.fromOption(activeStyle),
          className: Js_null.fromOption(className),
          exact: optionToBool(exact),
          style: Js_null.fromOption(style),
          children: children
        };
}

var NavLink = {
  makeProps: makeProps$1
};

var $$History = { };

exports.optionToBool = optionToBool;
exports.Link = Link;
exports.NavLink = NavLink;
exports.$$History = $$History;
/* No side effect */
