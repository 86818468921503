// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var Channel$Astrolabe = require("../../../models/Channel.bs.js");
var Constants$Astrolabe = require("../../../utils/Constants.bs.js");
var BannerNotice$Astrolabe = require("../../shared/BannerNotice.bs.js");
var TitleWithIcon$Astrolabe = require("../../header/TitleWithIcon.bs.js");
var DebarcodingKit$Astrolabe = require("../../../models/DebarcodingKit.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");
var ConfirmedButton$Astrolabe = require("../../header/ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../../graphql/ExperimentGraphQL.bs.js");
var DebarcodingKitsTable$Astrolabe = require("./DebarcodingKitsTable.bs.js");
var AddDebarcodingKitModal$Astrolabe = require("./AddDebarcodingKitModal.bs.js");

var ppx_printed_query = "mutation CreateAndAddToExperiment($experimentId: ID!, $name: String!, $channels: [String!]!, $channelsUsedPerSample: Int!)  {\ncreateDebarcodingKitAndAddToExperiment(experimentId: $experimentId, name: $name, channels: $channels, channelsUsedPerSample: $channelsUsedPerSample)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createDebarcodingKitAndAddToExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type DebarcodingKitPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createDebarcodingKitAndAddToExperiment: tmp
        };
}

function make(experimentId, name, channels, channelsUsedPerSample, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "channels",
                    channels.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "channelsUsedPerSample",
                    channelsUsedPerSample
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var name = variables.name;
  var channels = variables.channels;
  var channelsUsedPerSample = variables.channelsUsedPerSample;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "channels",
                    channels.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "channelsUsedPerSample",
                    channelsUsedPerSample
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, name, channels, channelsUsedPerSample, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "name",
                  name
                ],
                /* tuple */[
                  "channels",
                  channels.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "channelsUsedPerSample",
                  channelsUsedPerSample
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, name, channels, channelsUsedPerSample, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "name",
                      name
                    ],
                    /* tuple */[
                      "channels",
                      channels.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "channelsUsedPerSample",
                      channelsUsedPerSample
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var CreateAndAddToExperiment = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation AddToExperiment($experimentId: ID!, $debarcodingKitId: ID!)  {\ncreateExperimentDebarcodingKit(experimentId: $experimentId, debarcodingKitId: $debarcodingKitId)  {\nexperimentDebarcodingKit  {\nid  \n}\n\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createExperimentDebarcodingKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "experimentDebarcodingKit");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "id");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var match$2 = Js_json.decodeNull(value$9);
            if (match$2 !== undefined) {
              tmp$2 = undefined;
            } else {
              var value$10 = Js_json.decodeString(value$9);
              tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
            }
          } else {
            tmp$2 = undefined;
          }
          tmp$1 = {
            id: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      var value$11 = Js_dict.get(value$4, "errors");
      tmp = {
        experimentDebarcodingKit: tmp$1,
        errors: value$11 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$11))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentDebarcodingKitPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createExperimentDebarcodingKit: tmp
        };
}

function make$1(experimentId, debarcodingKitId, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "debarcodingKitId",
                    debarcodingKitId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var experimentId = variables.experimentId;
  var debarcodingKitId = variables.debarcodingKitId;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "debarcodingKitId",
                    debarcodingKitId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(experimentId, debarcodingKitId, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "debarcodingKitId",
                  debarcodingKitId
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, experimentId, debarcodingKitId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "debarcodingKitId",
                      debarcodingKitId
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var AddToExperiment = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var CreateAndAddToExperimentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

var AddToExperimentMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

function reducer(state, action) {
  if (action.tag) {
    return {
            addModalVisible: state.addModalVisible,
            errors: action[0]
          };
  } else {
    return {
            addModalVisible: action[0],
            errors: []
          };
  }
}

function str(prim) {
  return prim;
}

function DebarcodingKits(Props) {
  var canEditKits = Props.canEditKits;
  var channelUsages = Props.channelUsages;
  var debarcodingKits = Props.debarcodingKits;
  var experimentDebarcodingKits = Props.experimentDebarcodingKits;
  var experimentId = Props.experimentId;
  var channelUsages$1 = channelUsages.toArray().map(Channel$Astrolabe.Decode.channelUsage);
  var debarcodingKits$1 = debarcodingKits.map(DebarcodingKit$Astrolabe.fromJs);
  var experimentDebarcodingKits$1 = experimentDebarcodingKits.map(DebarcodingKit$Astrolabe.fromJs);
  var initialState_errors = [];
  var initialState = {
    addModalVisible: false,
    errors: initialState_errors
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var onCreateAndAddCompleted = function (data) {
    var errors = data.createDebarcodingKitAndAddToExperiment.errors;
    if (errors.length !== 0) {
      return Curry._1(dispatch, /* CreateErrorsReceived */Block.__(1, [errors]));
    } else {
      return Curry._1(dispatch, /* ModalVisibleChanged */Block.__(0, [false]));
    }
  };
  var onAddCompleted = function (data) {
    var errors = data.createExperimentDebarcodingKit.errors;
    if (errors.length !== 0) {
      return Curry._1(dispatch, /* CreateErrorsReceived */Block.__(1, [errors]));
    } else {
      return Curry._1(dispatch, /* ModalVisibleChanged */Block.__(0, [false]));
    }
  };
  var debarcodingSubtitle = canEditKits ? React.createElement("span", undefined, "If your experiment requires debarcoding, please add the debarcoding kits at this stage. For more information, refer to the ", React.createElement("a", {
              href: Constants$Astrolabe.debarcodingTutorialUrl,
              target: "_blank"
            }, "Debarcoding tutorial.")) : "Your debarcoding kits have been saved. You can no longer modify them.";
  var skipDebarcodingMessage = React.createElement(ExperimentGraphQL$Astrolabe.UpdateEventMutation.make, {
        children: (function (mutation, param) {
            return React.createElement(BannerNotice$Astrolabe.make, {
                        children: null
                      }, "This step is optional. ", React.createElement("button", {
                            className: "btn btn-link p-0",
                            onClick: (function (param) {
                                return ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "done_adding_kits", undefined, undefined);
                              })
                          }, "Click here to skip it."), " You will not be able to return later and add debarcoding kits.");
          })
      });
  var hasDebarcodingKits = !Util$Astrolabe.$$Array.isEmpty(experimentDebarcodingKits$1);
  var addDebarcodingKitButton = React.createElement("button", {
        className: "btn btn-block astrolabe-bg-light-blue text-white ml-3",
        style: {
          width: "350px"
        },
        title: "Add a debarcoding kit, which specifies which channels are used for debarcoding.",
        onClick: (function (param) {
            return Curry._1(dispatch, /* ModalVisibleChanged */Block.__(0, [true]));
          })
      }, "Add Debarcoding Kit");
  var continueButton = React.createElement(ExperimentGraphQL$Astrolabe.UpdateEventMutation.make, {
        children: (function (mutation, param) {
            return React.createElement("div", {
                        className: "mt-2",
                        style: {
                          width: "350px"
                        },
                        title: "Finish adding kits and proceed with mapping channels to debarcoded samples."
                      }, React.createElement(ConfirmedButton$Astrolabe.make, {
                            buttonClassName: "btn btn-block astrolabe-bg-green text-white ml-3",
                            buttonTitle: "Continue to Channel Mapping",
                            modalButtonClassName: "btn-primary",
                            modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Are you sure you want to continue?"), React.createElement("p", undefined, "Once you continue, you won't be able to add any additional debarcoding kits to this experiment.")),
                            onConfirm: (function (param) {
                                return ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "done_adding_kits", undefined, undefined);
                              }),
                            title: "Finished Adding Kits"
                          }));
          })
      });
  return React.createElement(React.Fragment, undefined, hasDebarcodingKits ? null : skipDebarcodingMessage, React.createElement("div", {
                  className: "d-flex justify-content-between align-items-center"
                }, React.createElement(TitleWithIcon$Astrolabe.make, {
                      className: "margin-new-title",
                      subtitle: debarcodingSubtitle,
                      title: "Define Debarcoding Kits",
                      children: React.createElement(Icons$Astrolabe.Barcoding.make, {
                            className: "no-hover",
                            height: "60",
                            width: "60"
                          })
                    }), canEditKits ? React.createElement("div", {
                        className: "d-flex flex-column justify-content-between"
                      }, addDebarcodingKitButton, hasDebarcodingKits ? continueButton : null) : null), React.createElement(CreateAndAddToExperimentMutation.make, {
                  onCompleted: onCreateAndAddCompleted,
                  children: (function (createAndAddToExperimentMutation, param) {
                      return React.createElement(AddToExperimentMutation.make, {
                                  onCompleted: onAddCompleted,
                                  children: (function (addToExperimentMutation, param) {
                                      return React.createElement(React.Fragment, undefined, React.createElement(DebarcodingKitsTable$Astrolabe.make, {
                                                      debarcodingKits: experimentDebarcodingKits$1,
                                                      disabled: !canEditKits,
                                                      experimentId: experimentId
                                                    }), React.createElement(AddDebarcodingKitModal$Astrolabe.make, {
                                                      debarcodingKits: Util$Astrolabe.$$Array.withoutBy(experimentDebarcodingKits$1, (function (k) {
                                                              return k.id;
                                                            }), debarcodingKits$1),
                                                      channelUsages: channelUsages$1,
                                                      errors: state.errors,
                                                      onCancel: (function (param) {
                                                          return Curry._1(dispatch, /* ModalVisibleChanged */Block.__(0, [false]));
                                                        }),
                                                      onOk: (function (kitToAdd) {
                                                          if (kitToAdd.tag) {
                                                            var debarcodingKitId = kitToAdd[0];
                                                            var addToExperimentMutation$1 = make$1(experimentId, debarcodingKitId, undefined);
                                                            return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(addToExperimentMutation, Caml_option.some(addToExperimentMutation$1.variables), ["getExperiment"], undefined, undefined));
                                                          } else {
                                                            var kit = kitToAdd[0];
                                                            var createAndAddToExperimentMutation$1 = make(experimentId, kit.name, kit.channels, kit.channelsUsedPerSample, undefined);
                                                            return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(createAndAddToExperimentMutation, Caml_option.some(createAndAddToExperimentMutation$1.variables), ["getExperiment"], undefined, undefined));
                                                          }
                                                        }),
                                                      visible: state.addModalVisible
                                                    }));
                                    })
                                });
                    })
                }));
}

var $$Array;

var Experiment;

var UpdateExperimentEventMutation;

var make$2 = DebarcodingKits;

var $$default = DebarcodingKits;

exports.$$Array = $$Array;
exports.Experiment = Experiment;
exports.UpdateExperimentEventMutation = UpdateExperimentEventMutation;
exports.CreateAndAddToExperiment = CreateAndAddToExperiment;
exports.AddToExperiment = AddToExperiment;
exports.CreateAndAddToExperimentMutation = CreateAndAddToExperimentMutation;
exports.AddToExperimentMutation = AddToExperimentMutation;
exports.reducer = reducer;
exports.str = str;
exports.make = make$2;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* CreateAndAddToExperimentMutation Not a pure module */
