/* @flow */
"use strict";

import React from "react";
import { List } from "immutable";
import { Experiment, ExperimentStatistics } from "models";
import { Bar } from "@nivo/bar";
import { dark2 } from "../../../models/Theme.bs";

type Props = {
  barChartData: Array<{ value: number, featureValue?: string }>,
  featureValues: Array<string>,
  maxChartValue: float,
  seriesLabels: Array<string>,
  width: int,
};

export default class CellSubsetFrequencyBarChart extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // nivo's automatic coloring algorithm works on series, so we're converting
    // frequency to different series.
    var data = this.props.barChartData.map((d, i) => {
      return {
        sampleName: this.props.seriesLabels[i],
        [d.featureValue || "frequency"]: d.value,
        featureValue: d.featureValue || "frequency",
      };
    });

    const daeEnabled = this.props.featureValues.length > 0;
    let featureValues = ["frequency"];

    if (daeEnabled) {
      featureValues = this.props.featureValues;
      // Previously, if the differential abundance analysis was on then the
      // subset frequency bar chart was sorted in increasing frequency order.
      // This behavior has been removed.
      // data.sort((a, b) => a[a.featureValue] - b[b.featureValue]);
    }

    const frequencies = data.map((row) => row[row.featureValue]);
    const minValue = Math.min(0, Math.min.apply([], frequencies) * 1.2);

    const bottomMargin = 9 * Math.max.apply(null, data.map((x) => x.sampleName.length));
    /**
     * Fix the chart area's height to 250px, expanding the entire chart's height by the size of the bottom margin.
     */
    const height = 250 + bottomMargin;

    /**
     * If a series has all 0 values, nivo will not assign a color to that series since no bar is beign shown. To maintain
     * consistency between the bar chart and any associated box plots (which do properly skip colors for series with
     * all 0 values), we need to manually choose the color for each series based on its position, and not rely on
     * the `colors` prop.
     */
    let seriesIdToColorIndex = {};
    featureValues.map((fv, i) => (seriesIdToColorIndex[fv] = i));

    return (
      <Bar
        width={this.props.width}
        height={height}
        data={data}
        indexBy="sampleName"
        keys={featureValues}
        groupMode={daeEnabled ? "stacked" : "grouped"}
        colorBy={({ id }) => dark2[seriesIdToColorIndex[id]]}
        minValue={minValue}
        maxValue={this.props.maxChartValue}
        enableLabel={false}
        margin={{
          top: 10,
          right: 60,
          bottom: bottomMargin,
          left: 60,
        }}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Frequency (%)",
          legendPosition: "center",
          legendOffset: -50,
        }}
      />
    );
  }
}
