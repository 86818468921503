// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var Webapi__Dom__Element = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Element.js");

function str(prim) {
  return prim;
}

function arr(prim) {
  return prim;
}

var ellipsis = "…";

var nbsp = '\u00a0';

function strEllipsis(s) {
  return s + ellipsis;
}

function filesFromEvent(evt) {
  return evt.target.files;
}

function handleButtonRefClick(ref) {
  Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), Webapi__Dom__Element.asHtmlElement), (function (prim) {
          prim.click();
          
        }));
  
}

function valueFromEvent(evt) {
  return evt.target.value;
}

function checkedFromEvent(evt) {
  return evt.target.checked;
}

function preventDefault(evt) {
  evt.preventDefault();
  
}

function stopPropagation(evt) {
  evt.stopPropagation();
  
}

function preventDefaultAndPropagation(evt) {
  evt.preventDefault();
  evt.stopPropagation();
  
}

var url_path = window.location.host;

var pathname = window.location.pathname;

var isPublicRoute = pathname.startsWith("/public");

function redirect(path) {
  window.location.replace(path);
  
}

function reload(param) {
  window.location.reload();
  
}

function getCurrentRef(ref) {
  return ref.current;
}

function setRefInArrayRef(node, i, arrayRefs) {
  var newCurrentArray = $$Array.concat(/* :: */[
        [],
        /* :: */[
          arrayRefs.current,
          /* [] */0
        ]
      ]);
  var newCurrent = newCurrentArray.length <= i ? $$Array.append(newCurrentArray, [node]) : (Caml_array.caml_array_set(newCurrentArray, i, node), newCurrentArray);
  arrayRefs.current = newCurrent;
  
}

var Pane = { };

var ScrollSync = {
  Pane: Pane
};

var Dropzone = { };

function ReasonReactHelpers$SpreadProps(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var SpreadProps = {
  make: ReasonReactHelpers$SpreadProps
};

var jQueryFindElement = (function (selector) {
    return jQuery(selector)[0];
  });

function getFirstChildByTag(tag, parent) {
  return Util$Astrolabe.$$Option.orFail("Could not find tag: " + tag, Caml_option.nullable_to_opt(Util$Astrolabe.$$Option.orFail("Parent element is undefined", (parent == null) ? undefined : Caml_option.some(parent)).getElementsByTagName(tag).item(0)));
}

var downloadDataUrl = (function downloadDataUrl(dataUrl, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

exports.str = str;
exports.arr = arr;
exports.ellipsis = ellipsis;
exports.nbsp = nbsp;
exports.strEllipsis = strEllipsis;
exports.filesFromEvent = filesFromEvent;
exports.handleButtonRefClick = handleButtonRefClick;
exports.valueFromEvent = valueFromEvent;
exports.checkedFromEvent = checkedFromEvent;
exports.preventDefault = preventDefault;
exports.stopPropagation = stopPropagation;
exports.preventDefaultAndPropagation = preventDefaultAndPropagation;
exports.url_path = url_path;
exports.pathname = pathname;
exports.isPublicRoute = isPublicRoute;
exports.redirect = redirect;
exports.reload = reload;
exports.getCurrentRef = getCurrentRef;
exports.setRefInArrayRef = setRefInArrayRef;
exports.ScrollSync = ScrollSync;
exports.Dropzone = Dropzone;
exports.SpreadProps = SpreadProps;
exports.jQueryFindElement = jQueryFindElement;
exports.getFirstChildByTag = getFirstChildByTag;
exports.downloadDataUrl = downloadDataUrl;
/* url_path Not a pure module */
