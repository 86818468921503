/* @flow */
"use strict";

import { Sample } from "models";
import { formattedBytes } from "components/utils";
import { scrubFilename } from "utils/string";

export default class FileUpload {
  file: File;
  uploader: any;
  index: number;
  progress: number;
  status: any;
  errors: ?string;
  publicUrl: string;
  sample: Sample;

  constructor(props: Object) {
    this.file = props.file;
    this.uploader = props.uploader;
    this.index = props.index;
    props.file.__index = props.index;
    this.progress = props.progress || 0;
    this.status = props.status;
    this.errors = props.errors;
    this.publicUrl = props.publicUrl;
    this.sample = props.sample;
  }

  copyWith(props: Object) {
    return new FileUpload({
      file: this.file,
      uploader: this.uploader,
      index: this.index,
      progress: this.progress,
      status: this.status,
      errors: this.errors,
      publicUrl: this.publicUrl,
      sample: this.sample,
      ...props,
    });
  }

  get bytesSent(): number {
    return this.file.size * (this.progress / 100);
  }

  formattedBytesSent(decimals: number = 2): string {
    return formattedBytes(this.bytesSent, decimals);
  }

  formattedFileSize(decimals: number = 2): string {
    return formattedBytes(this.file.size, decimals);
  }

  get filename(): string {
    return scrubFilename(this.file.name);
  }
}
