// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");

function compare(threshold, metric) {
  var value = metric.value;
  if (value !== undefined) {
    if (value < threshold.min) {
      return /* BelowMin */Block.__(0, [
                metric,
                threshold.min
              ]);
    } else if (value > threshold.max) {
      return /* AboveMax */Block.__(1, [
                metric,
                threshold.max
              ]);
    } else {
      return /* InRange */Block.__(2, [metric]);
    }
  } else {
    return /* NA */Block.__(3, [metric]);
  }
}

function getSingleValue(threshold, metric) {
  var value = Curry._1(metric.metricType.toString, metric.value);
  var displayMin = Curry._1(metric.metricType.toString, threshold.min);
  var displayMax = Curry._1(metric.metricType.toString, threshold.max);
  var match = compare(threshold, metric);
  switch (match.tag | 0) {
    case /* BelowMin */0 :
        return {
                value: value,
                detail: metric.metricType.label + (" is lower than " + displayMin)
              };
    case /* AboveMax */1 :
        return {
                value: value,
                detail: metric.metricType.label + (" is higher than " + displayMax)
              };
    case /* InRange */2 :
        return {
                value: value,
                detail: undefined
              };
    case /* NA */3 :
        return {
                value: "N/A",
                detail: undefined
              };
    
  }
}

function getMultiValue(threshold, metrics) {
  var statuses = List.map((function (param) {
          return compare(threshold, param);
        }), metrics);
  var belowMin = Belt_List.keepMap(statuses, (function (s) {
          if (s.tag) {
            return ;
          } else {
            return /* tuple */[
                    s[0],
                    s[1]
                  ];
          }
        }));
  var aboveMax = Belt_List.keepMap(statuses, (function (s) {
          if (s.tag === /* AboveMax */1) {
            return /* tuple */[
                    s[0],
                    s[1]
                  ];
          }
          
        }));
  if (Caml_obj.caml_equal(List.map(List.length, /* :: */[
              belowMin,
              /* :: */[
                aboveMax,
                /* [] */0
              ]
            ]), /* :: */[
          0,
          /* :: */[
            0,
            /* [] */0
          ]
        ])) {
    return {
            value: "Pass",
            detail: undefined
          };
  }
  var label = List.hd(metrics).metricType.label;
  var comparators = [
    " lower than ",
    " higher than "
  ];
  var limits = $$Array.map((function (statuses) {
          if (List.length(statuses) > 0) {
            return List.hd(statuses)[1];
          } else {
            return 0;
          }
        }), [
        belowMin,
        aboveMax
      ]);
  var belowMinValues = $$Array.of_list(List.map((function (param) {
                return param[0].desc;
              }), belowMin)).join(", ");
  var aboveMaxValues = $$Array.of_list(List.map((function (param) {
                return param[0].desc;
              }), aboveMax)).join(", ");
  var detail = $$Array.of_list(List.map((function (param) {
                return param[0] + param[1];
              }), List.filter((function (param) {
                      return param[1].length !== 0;
                    }))(List.mapi((function (i, warningValues) {
                        return /* tuple */[
                                "The following have " + (label + (Caml_array.caml_array_get(comparators, i) + (String(Caml_array.caml_array_get(limits, i)) + ": "))),
                                warningValues
                              ];
                      }), /* :: */[
                      belowMinValues,
                      /* :: */[
                        aboveMaxValues,
                        /* [] */0
                      ]
                    ])))).join("\n");
  return {
          value: "Warning",
          detail: detail
        };
}

function get(threshold, metrics) {
  var metric;
  try {
    metric = List.hd(metrics);
  }
  catch (exn){
    return {
            value: "",
            detail: ""
          };
  }
  var match = metric.desc;
  if (match !== undefined) {
    return getMultiValue(threshold, metrics);
  } else {
    return getSingleValue(threshold, metric);
  }
}

exports.compare = compare;
exports.getSingleValue = getSingleValue;
exports.getMultiValue = getMultiValue;
exports.get = get;
/* No side effect */
