// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var Icons$Astrolabe = require("./Icons.bs.js");
var Modal$Astrolabe = require("./Modal.bs.js");
var SaveSvgAsPng$Astrolabe = require("../utils/SaveSvgAsPng.bs.js");
var ReasonReactHelpers$Astrolabe = require("../utils/ReasonReactHelpers.bs.js");

function toString(param) {
  if (param) {
    return "png";
  } else {
    return "svg";
  }
}

function fromString(str) {
  switch (str) {
    case "png" :
        return /* Png */1;
    case "svg" :
        return /* Svg */0;
    default:
      return Pervasives.invalid_arg(str);
  }
}

var DownloadFormat = {
  toString: toString,
  fromString: fromString
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            filename: state.filename,
            format: state.format,
            showModal: false,
            filenameWasUpdated: false
          };
  }
  switch (action.tag | 0) {
    case /* SetFileName */0 :
        var filename = action[0].replace(new RegExp("\\." + ((
                    state.format ? "png" : "svg"
                  ) + "$")), "");
        return {
                filename: filename,
                format: state.format,
                showModal: state.showModal,
                filenameWasUpdated: true
              };
    case /* ShowModal */1 :
        return {
                filename: action[0],
                format: state.format,
                showModal: true,
                filenameWasUpdated: state.filenameWasUpdated
              };
    case /* SetFormat */2 :
        return {
                filename: state.filename,
                format: action[0],
                showModal: state.showModal,
                filenameWasUpdated: state.filenameWasUpdated
              };
    
  }
}

var initialState = {
  filename: "",
  format: /* Png */1,
  showModal: false,
  filenameWasUpdated: false
};

function GraphDownloadButton(Props) {
  var prefix = Props.prefix;
  var name = Props.name;
  var graphType = Props.graphType;
  var container = Props.container;
  var filenames = Props.filenames;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "download";
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var baseFilename = state.filenameWasUpdated ? state.filename : prefix + (" " + state.filename);
  var saveCanvasElement = function (container) {
    var element = ReasonReactHelpers$Astrolabe.getFirstChildByTag("canvas", Curry._1(container, undefined));
    var url = element.toDataURL();
    var filename = Util$Astrolabe.$$String.toIdentifier(baseFilename) + ".png";
    return Curry._2(ReasonReactHelpers$Astrolabe.downloadDataUrl, url, filename);
  };
  var saveSvgElement = function (container) {
    var element = ReasonReactHelpers$Astrolabe.getFirstChildByTag("svg", Curry._1(container, undefined));
    var filename = Util$Astrolabe.$$String.toIdentifier(baseFilename) + ("." + (
        state.format ? "png" : "svg"
      ));
    var options = SaveSvgAsPng$Astrolabe.options("white", 2, undefined);
    var match = state.format;
    if (match) {
      return SaveSvgAsPng$Astrolabe.saveSvgAsPng(element, filename, options);
    } else {
      return SaveSvgAsPng$Astrolabe.saveSvg(element, filename, options);
    }
  };
  var format = state.format ? "png" : "svg";
  var renderModal = function (param) {
    if (state.showModal) {
      return React.createElement(Modal$Astrolabe.make, {
                  onCancel: (function (param) {
                      return Curry._1(dispatch, /* CloseModal */0);
                    }),
                  title: "Download " + name,
                  visible: state.showModal,
                  children: React.createElement("div", {
                        className: "modal-body"
                      }, graphType === /* Svg */4153924 ? React.createElement("div", {
                              role: "form"
                            }, React.createElement("div", {
                                  className: "form-group"
                                }, React.createElement("label", undefined, ReasonReactHelpers$Astrolabe.str("Format")), React.createElement("select", {
                                      className: "form-control",
                                      value: format,
                                      onChange: (function (e) {
                                          return Curry._1(dispatch, /* SetFormat */Block.__(2, [fromString(ReasonReactHelpers$Astrolabe.valueFromEvent(e))]));
                                        })
                                    }, React.createElement("option", {
                                          value: "png"
                                        }, ReasonReactHelpers$Astrolabe.str("PNG")), React.createElement("option", {
                                          value: "svg"
                                        }, ReasonReactHelpers$Astrolabe.str("SVG"))))) : null, React.createElement("div", {
                            className: "form-group"
                          }, React.createElement("label", undefined, ReasonReactHelpers$Astrolabe.str("File Name")), React.createElement("div", {
                                className: "form-inline"
                              }, React.createElement("input", {
                                    className: "form-control col-11",
                                    type: "text",
                                    value: baseFilename,
                                    onChange: (function (e) {
                                        return Curry._1(dispatch, /* SetFileName */Block.__(0, [ReasonReactHelpers$Astrolabe.valueFromEvent(e)]));
                                      })
                                  }), React.createElement("label", {
                                    className: "form-label ml-1"
                                  }, ReasonReactHelpers$Astrolabe.str("." + format)))), React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("div", {
                                className: "row justify-content-end"
                              }, React.createElement("button", {
                                    className: "btn btn-light",
                                    onClick: (function (param) {
                                        return Curry._1(dispatch, /* CloseModal */0);
                                      })
                                  }, ReasonReactHelpers$Astrolabe.str("Cancel")), ReasonReactHelpers$Astrolabe.nbsp, React.createElement("button", {
                                    className: "btn btn-primary",
                                    onClick: (function (e) {
                                        ReasonReactHelpers$Astrolabe.preventDefault(e);
                                        if (graphType >= 4153924) {
                                          saveSvgElement(container);
                                        } else {
                                          saveCanvasElement(container);
                                        }
                                        return Curry._1(dispatch, /* CloseModal */0);
                                      })
                                  }, ReasonReactHelpers$Astrolabe.str("Download")))))
                });
    } else {
      return null;
    }
  };
  return React.createElement("span", undefined, React.createElement("span", {
                  className: "border-bottom-0",
                  title: "Download figure",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ShowModal */Block.__(1, [filenames]));
                    })
                }, React.createElement(Icons$Astrolabe.Download.make, {
                      className: className + " cursor-pointer",
                      height: "15",
                      width: "15"
                    })), renderModal(undefined));
}

var svgGraphType = /* Svg */4153924;

var canvasGraphType = /* Canvas */-321468168;

var make = GraphDownloadButton;

var $$default = GraphDownloadButton;

exports.DownloadFormat = DownloadFormat;
exports.svgGraphType = svgGraphType;
exports.canvasGraphType = canvasGraphType;
exports.reducer = reducer;
exports.initialState = initialState;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
