/* @flow */

import React from "react";
import ReactDOM from "react-dom";

type Props = {
  children?: React$Node,
  onCancel: () => any,
  size?: "sm" | "lg" | "xl",
  title?: string,
  visible: boolean,
};

export const MODAL_ROOT_ELEMENT_ID = "modal-root";

export default class Modal extends React.Component<Props> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
  }

  withRootElement(callback: (HTMLElement) => any) {
    const root = document.getElementById(MODAL_ROOT_ELEMENT_ID);

    if (root) {
      callback(root);
    }
  }

  componentDidMount() {
    this.withRootElement((root) => root.appendChild(this.el));
  }

  componentWillUnmount() {
    this.withRootElement((root) => {
      try {
        root.removeChild(this.el);
      } catch (e) {
        // `removeChild` will throw if this.el isn't a child of root or if this.el doesn't exist on the page.
        // In either case, there's no cleanup to do, so we can ignore the error.
      }
    });
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    let sizeClass = "";
    if (this.props.size) {
      sizeClass = ` modal-${this.props.size}`;
    }

    const modal = (
      <div className="modal-open">
        <div className="modal fade show modal-open" tabIndex="-1" role="dialog" style={{ display: "block" }}>
          <div className={"modal-dialog" + sizeClass}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{this.props.title}</h4>
                <button type="button" className="close" aria-label="Close" onClick={this.props.onCancel}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </div>
    );

    return ReactDOM.createPortal(modal, this.el);
  }
}
