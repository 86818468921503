// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function animate(self) {
  if (self !== 1) {
    return /* Animation1 */1;
  } else {
    return /* Animation2 */2;
  }
}

function className(self) {
  switch (self) {
    case /* Disabled */0 :
        return "";
    case /* Animation1 */1 :
        return "pulse-1";
    case /* Animation2 */2 :
        return "pulse-2";
    
  }
}

exports.animate = animate;
exports.className = className;
/* No side effect */
