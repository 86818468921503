// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");

var ppx_printed_query = "mutation ShiftDeAnalysisKit($id: ID!, $direction: Direction!)  {\nshiftDeAnalysisKit(id: $id, direction: $direction)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "shiftDeAnalysisKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type AnalysisKit__DifferentialExpressionPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          shiftDeAnalysisKit: tmp
        };
}

function json_of_Direction(value) {
  if (value >= 758038626) {
    return "DOWN";
  } else {
    return "UP";
  }
}

function make(id, direction, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "direction",
                    json_of_Direction(direction)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  var direction = variables.direction;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "direction",
                    json_of_Direction(direction)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, direction, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "direction",
                  json_of_Direction(direction)
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, direction, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "direction",
                      json_of_Direction(direction)
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var ShiftDeAnalysisKitConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_Direction: json_of_Direction,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var $$window = window;

var ShiftDeAnalysisKitMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function ShiftDeKitButton(Props) {
  var kit = Props.kit;
  var direction = Props.direction;
  var children = Props.children;
  var match = React.useState((function () {
          return false;
        }));
  var setSaving = match[1];
  var saving = match[0];
  return React.createElement(ShiftDeAnalysisKitMutation.make, {
              onCompleted: (function (data) {
                  Curry._1(setSaving, (function (param) {
                          return false;
                        }));
                  var errors = data.shiftDeAnalysisKit.errors;
                  var match = errors.length;
                  if (match !== 0) {
                    $$window.alert("Couldn't update analysis:\n\n" + $$Array.map((function (e) {
                                  return "• " + e;
                                }), errors).join("\n"));
                    return ;
                  }
                  
                }),
              children: (function (mutation, param) {
                  return React.createElement("span", {
                              className: "cursor-pointer",
                              onClick: (function (param) {
                                  if (saving) {
                                    return ;
                                  }
                                  Curry._1(setSaving, (function (param) {
                                          return true;
                                        }));
                                  var variables = make(Belt_Option.getExn(kit.id), direction, undefined).variables;
                                  return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(variables), ["getExperiment"], undefined, undefined));
                                })
                            }, children);
                })
            });
}

var make$1 = ShiftDeKitButton;

exports.ShiftDeAnalysisKitConfig = ShiftDeAnalysisKitConfig;
exports.$$window = $$window;
exports.ShiftDeAnalysisKitMutation = ShiftDeAnalysisKitMutation;
exports.make = make$1;
/* window Not a pure module */
