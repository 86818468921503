// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DeleteIcon$Astrolabe = require("../../shared/DeleteIcon.bs.js");
var InstanceFile$Astrolabe = require("../../../models/InstanceFile.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");
var InstanceFileGraphQL$Astrolabe = require("../../../graphql/InstanceFileGraphQL.bs.js");

function InstanceFileTable(Props) {
  var instanceId = Props.instanceId;
  return React.createElement(InstanceFileGraphQL$Astrolabe.GetInstanceFilesQuery.make, {
              variables: InstanceFileGraphQL$Astrolabe.GetInstanceFiles.make(instanceId, undefined).variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return null;
                  }
                  if (result.tag) {
                    return React.createElement("table", {
                                className: "table table-striped table-hover table-sm"
                              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                            style: {
                                              width: "30px"
                                            }
                                          }, ReasonReactHelpers$Astrolabe.nbsp), React.createElement("th", undefined, "File Name"), React.createElement("th", undefined, "Create User Email"), React.createElement("th", undefined, "Create Date"), React.createElement("th", undefined, "Update Date"))), React.createElement("tbody", undefined, $$Array.map((function (instanceFile) {
                                          return React.createElement("tr", {
                                                      key: InstanceFile$Astrolabe.id(instanceFile)
                                                    }, React.createElement("td", undefined, React.createElement(InstanceFileGraphQL$Astrolabe.DestroyInstanceFileMutation.make, {
                                                              children: (function (mutation, param) {
                                                                  return React.createElement(DeleteIcon$Astrolabe.make, {
                                                                              onClick: (function (param) {
                                                                                  return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(InstanceFileGraphQL$Astrolabe.DestroyInstanceFileConfig.make(InstanceFile$Astrolabe.id(instanceFile), undefined).variables), ["getInstanceFiles"], undefined, undefined));
                                                                                }),
                                                                              title: "Remove this instance file. This action cannot be undone."
                                                                            });
                                                                })
                                                            })), React.createElement("td", undefined, InstanceFile$Astrolabe.fileFileName(instanceFile)), React.createElement("td", undefined, InstanceFile$Astrolabe.createUserEmail(instanceFile)), React.createElement("td", undefined, new Date(InstanceFile$Astrolabe.createdAt(instanceFile)).toLocaleString()), React.createElement("td", undefined, new Date(InstanceFile$Astrolabe.updatedAt(instanceFile)).toLocaleString()));
                                        }), result[0].instanceFiles)));
                  }
                  console.log({
                        errors: result[0]
                      });
                  return React.createElement("div", {
                              className: "alert alert-danger",
                              role: "alert"
                            }, React.createElement("h4", {
                                  className: "alert-heading mb-0 text-center"
                                }, "Something Went Wrong"));
                })
            });
}

var make = InstanceFileTable;

var $$default = InstanceFileTable;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
