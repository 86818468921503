// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactRouterDom = require("react-router-dom");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var ReactRouter$Astrolabe = require("../../utils/ReactRouter.bs.js");

function str(prim) {
  return prim;
}

function ExperimentsTable(Props) {
  var experiments = Props.experiments;
  return React.createElement("table", {
              className: "table table-hover"
            }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Experiment Name"), React.createElement("th", undefined, "Created By"), React.createElement("th", {
                          style: {
                            width: "12.5%"
                          }
                        }, "Created At"), React.createElement("th", undefined, "Organism"), React.createElement("th", undefined, "Instrument"), React.createElement("th", undefined, "Status"))), React.createElement("tbody", undefined, $$Array.mapi((function (i, e) {
                        return React.createElement("tr", {
                                    key: String(i)
                                  }, React.createElement("td", undefined, React.createElement(ReactRouterDom.Link, ReactRouter$Astrolabe.Link.makeProps("/experiments/" + String(Experiment$Astrolabe.Decode.id(e)), Caml_option.some(Experiment$Astrolabe.Decode.name(e)), undefined))), React.createElement("td", undefined, Experiment$Astrolabe.Decode.create_user_email(e)), React.createElement("td", {
                                        style: {
                                          width: "12.5%"
                                        }
                                      }, Experiment$Astrolabe.Decode.created_at_ymd(e)), React.createElement("td", undefined, Experiment$Astrolabe.Organism.toString(Experiment$Astrolabe.Decode.organism(e))), React.createElement("td", undefined, Experiment$Astrolabe.Instrument.toString(Experiment$Astrolabe.Decode.instrument(e))), React.createElement("td", undefined, Experiment$Astrolabe.statusToDisplayString(Experiment$Astrolabe.Decode.status(e))));
                      }), experiments)));
}

var make = ExperimentsTable;

var $$default = ExperimentsTable;

exports.str = str;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
