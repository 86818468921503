// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Theme$Astrolabe = require("../../models/Theme.bs.js");
var GraphQL$Astrolabe = require("../../models/GraphQL.bs.js");
var HeatMap$Astrolabe = require("../HeatMap.bs.js");
var ASDSHeatMapControls$Astrolabe = require("./heat_map/ASDSHeatMapControls.bs.js");
var GraphDownloadButton$Astrolabe = require("../GraphDownloadButton.bs.js");
var ASDSBoxPlotContainer$Astrolabe = require("./ASDSBoxPlotContainer.bs.js");
var ASDSDensityLineChartContainer$Astrolabe = require("./ASDSDensityLineChartContainer.bs.js");

function str(prim) {
  return prim;
}

function makeInitialState(param) {
  return {
          boxPlotElement: null,
          heatMapElement: null,
          boxPlotGroupBy: GraphQL$Astrolabe.Types.boxPlotGroupBy_of_string("antibody")
        };
}

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* BoxPlotElementUpdated */0 :
        var boxPlotElement = action[0];
        if ((state.boxPlotElement == null) && !(boxPlotElement == null)) {
          return {
                  boxPlotElement: boxPlotElement,
                  heatMapElement: state.heatMapElement,
                  boxPlotGroupBy: state.boxPlotGroupBy
                };
        } else {
          return state;
        }
    case /* HeatMapElementUpdated */1 :
        var heatMapElement = action[0];
        if ((state.heatMapElement == null) && !(heatMapElement == null)) {
          return {
                  boxPlotElement: state.boxPlotElement,
                  heatMapElement: heatMapElement,
                  boxPlotGroupBy: state.boxPlotGroupBy
                };
        } else {
          return state;
        }
    case /* BoxPlotGroupByChange */2 :
        return {
                boxPlotElement: state.boxPlotElement,
                heatMapElement: state.heatMapElement,
                boxPlotGroupBy: action[0]
              };
    
  }
}

function ASDSHeatMap(Props) {
  var onCellSubsetsChange = Props.onCellSubsetsChange;
  var selectedAntibodies = Props.selectedAntibodies;
  var selectedCellSubsets = Props.selectedCellSubsets;
  var treatment = Props.treatment;
  var setTreatment = Props.setTreatment;
  var cellSubsetLevel = Props.cellSubsetLevel;
  var setCellSubsetLevel = Props.setCellSubsetLevel;
  var statistic = Props.statistic;
  var setStatistic = Props.setStatistic;
  var dataTableOpt = Props.data;
  var selectedAntibodiesArray = Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedAntibodies);
  var selectedCellSubsetsArray = Curry._1(Util$Astrolabe.$$String.$$Set.to_array, selectedCellSubsets);
  var selectedAntibodiesLength = selectedAntibodiesArray.length;
  var selectedCellSubsetsLength = selectedCellSubsetsArray.length;
  var match = React.useReducer(reducer, makeInitialState(undefined));
  var dispatch = match[1];
  var state = match[0];
  var controls = function (param) {
    return React.createElement(ASDSHeatMapControls$Astrolabe.make, {
                cellSubsetLevel: cellSubsetLevel,
                treatment: treatment,
                statistic: statistic,
                onCellSubsetLevelChange: setCellSubsetLevel,
                onTreatmentChange: setTreatment,
                onStatisticChange: setStatistic
              });
  };
  var loading = React.createElement(React.Fragment, undefined, controls(undefined), React.createElement("h3", undefined, " Loading… "));
  if (dataTableOpt === undefined) {
    if (selectedAntibodiesLength !== 0) {
      return loading;
    } else {
      return null;
    }
  }
  var heatMapDataTable = Caml_option.valFromOption(dataTableOpt);
  if (selectedAntibodiesLength === 0) {
    return loading;
  }
  var columnLabels = heatMapDataTable.columnLabels;
  var rowLabels = heatMapDataTable.rowLabels;
  var topMargin = (Util$Astrolabe.or(Util$Astrolabe.$$Array.max($$Array.map((function (prim) {
                    return prim.length;
                  }), columnLabels)), 5) << 3);
  var leftMargin = Caml_int32.imul(10, Util$Astrolabe.or(Util$Astrolabe.$$Array.max($$Array.map((function (prim) {
                      return prim.length;
                    }), rowLabels)), 5));
  var height = topMargin + Caml_int32.imul(20, rowLabels.length) | 0;
  var width = leftMargin + Caml_int32.imul(20, columnLabels.length) | 0;
  var onSelctColumnLabel = function (label) {
    return Curry._1(onCellSubsetsChange, Curry._2(Util$Astrolabe.$$String.$$Set.toggle, label, selectedCellSubsets));
  };
  var legendTitle = statistic !== 78066387 ? (
      statistic >= 826994442 ? "Marker Intensity" : "Fixation Ratio"
    ) : "Percent Positive";
  var symmetricLegendValues = statistic === /* FIXATION */-744809280;
  var heatMapRef = function (el) {
    return Curry._1(dispatch, /* HeatMapElementUpdated */Block.__(1, [el]));
  };
  var boxPlotRef = function (el) {
    return Curry._1(dispatch, /* BoxPlotElementUpdated */Block.__(0, [el]));
  };
  return React.createElement(React.Fragment, undefined, controls(undefined), React.createElement("div", {
                  className: "row"
                }, React.createElement("div", {
                      className: "col-12"
                    }, React.createElement("div", {
                          className: "d-flex justify-content-end"
                        }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                              prefix: "default",
                              name: "Heat Map",
                              graphType: /* Canvas */-321468168,
                              container: (function (param) {
                                  return state.heatMapElement;
                                }),
                              filenames: "Astrolabe Antibody Staining Heat Map"
                            })))), React.createElement("div", {
                  className: "row d-flex align-items-end"
                }, React.createElement("div", {
                      ref: heatMapRef,
                      className: "col-12",
                      style: {
                        height: String(height) + "px"
                      }
                    }, React.createElement(HeatMap$Astrolabe.make, {
                          data: heatMapDataTable.toColumnsArray(),
                          width: width,
                          height: height,
                          margin: {
                            top: topMargin,
                            left: leftMargin,
                            right: 0.0,
                            bottom: 0.0
                          },
                          colors: statistic !== 78066387 ? (
                              statistic >= 826994442 ? Theme$Astrolabe.Palette.greens : Theme$Astrolabe.Palette.buRd
                            ) : Theme$Astrolabe.Palette.purples,
                          rowLabels: heatMapDataTable.rowLabels,
                          columnLabels: heatMapDataTable.columnLabels,
                          selectedColumnLabels: selectedCellSubsets,
                          onSelctColumnLabel: onSelctColumnLabel,
                          legendTitle: legendTitle,
                          symmetricLegendValues: symmetricLegendValues
                        }))), statistic !== /* FIXATION */-744809280 && selectedAntibodiesLength > 0 && selectedCellSubsetsLength > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("hr", undefined), React.createElement("div", {
                                className: "d-flex justify-content-between align-items-center"
                              }, React.createElement("form", {
                                    className: "form-inline"
                                  }, React.createElement("div", {
                                        className: "form-group"
                                      }, React.createElement("label", {
                                            className: "mr-2"
                                          }, "Group Box Plot By"), React.createElement("select", {
                                            value: GraphQL$Astrolabe.Types.string_of_boxPlotGroupBy(state.boxPlotGroupBy),
                                            onChange: (function (e) {
                                                return Curry._1(dispatch, /* BoxPlotGroupByChange */Block.__(2, [GraphQL$Astrolabe.Types.boxPlotGroupBy_of_string(e.target.value)]));
                                              })
                                          }, React.createElement("option", {
                                                value: "ANTIBODY"
                                              }, "Antibody"), React.createElement("option", {
                                                value: "CELL_SUBSET"
                                              }, "Cell Subset"))))))), React.createElement("div", {
                        className: "d-flex"
                      }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                            prefix: "default",
                            name: "Box Plot",
                            graphType: /* Svg */4153924,
                            container: (function (param) {
                                return state.boxPlotElement;
                              }),
                            filenames: "Astrolabe Antibody Staining Box Plot",
                            className: ""
                          }), React.createElement("div", {
                            ref: boxPlotRef
                          }, React.createElement(ASDSBoxPlotContainer$Astrolabe.make, {
                                cellSubsetLevel: cellSubsetLevel,
                                treatment: treatment,
                                antibodies: selectedAntibodiesArray,
                                cellSubsets: selectedCellSubsetsArray,
                                groupBy: state.boxPlotGroupBy
                              })))) : null, Curry._1(Util$Astrolabe.$$String.$$Set.length, selectedAntibodies) > 0 && Curry._1(Util$Astrolabe.$$String.$$Set.length, selectedCellSubsets) > 0 ? React.createElement(React.Fragment, undefined, React.createElement("hr", undefined), React.createElement(ASDSDensityLineChartContainer$Astrolabe.make, {
                        cellSubsetLevel: cellSubsetLevel,
                        treatment: statistic === /* FIXATION */-744809280 ? undefined : treatment,
                        antibodies: selectedAntibodiesArray,
                        cellSubsets: selectedCellSubsetsArray
                      })) : null);
}

var or = Util$Astrolabe.or;

var make = ASDSHeatMap;

var $$default = ASDSHeatMap;

exports.or = or;
exports.str = str;
exports.makeInitialState = makeInitialState;
exports.reducer = reducer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
