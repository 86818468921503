// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Notification$Astrolabe = require("../../models/Notification.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var NotificationGraphQL$Astrolabe = require("../../graphql/NotificationGraphQL.bs.js");

var DismissNotificationMutation = ReasonApollo.CreateMutation({
      query: NotificationGraphQL$Astrolabe.Dismiss.query,
      parse: NotificationGraphQL$Astrolabe.Dismiss.parse
    });

function str(prim) {
  return prim;
}

function NotificationBanner(Props) {
  var id = Props.id;
  var title = Props.title;
  var text = Props.text;
  var alertStyle = Props.alertStyle;
  var match = React.useState((function () {
          return true;
        }));
  var setActiveNotification = match[1];
  var alertStyle$1 = Notification$Astrolabe.string_of_alert_style(alertStyle);
  if (match[0]) {
    return React.createElement("div", {
                className: "row $alertStyle"
              }, React.createElement("div", {
                    className: "col"
                  }), React.createElement("div", {
                    className: "col-10 alert alert-" + (String(alertStyle$1) + " alert-dismissible"),
                    role: "alert"
                  }, React.createElement("h4", {
                        className: "alert-heading"
                      }, title), React.createElement("div", {
                        dangerouslySetInnerHTML: {
                          __html: text
                        }
                      }), React.createElement(DismissNotificationMutation.make, {
                        children: (function (mutation, param) {
                            return React.createElement("button", {
                                        className: "close",
                                        onClick: (function (param) {
                                            GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(NotificationGraphQL$Astrolabe.Dismiss.make(id, undefined).variables), ["getVisibleNotifications"], undefined, undefined));
                                            return Curry._1(setActiveNotification, (function (param) {
                                                          return false;
                                                        }));
                                          })
                                      }, React.createElement("span", undefined, "×"));
                          })
                      })), React.createElement("div", {
                    className: "col"
                  }));
  } else {
    return null;
  }
}

var make = NotificationBanner;

var $$default = NotificationBanner;

exports.DismissNotificationMutation = DismissNotificationMutation;
exports.str = str;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* DismissNotificationMutation Not a pure module */
