// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Icons$Astrolabe = require("../../Icons.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");

var ppx_printed_query = "mutation DestroyExperimentDebarcodingKit($experimentId: ID!, $debarcodingKitId: ID!)  {\ndestroyExperimentDebarcodingKit(experimentId: $experimentId, debarcodingKitId: $debarcodingKitId)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "destroyExperimentDebarcodingKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type DestroyPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          destroyExperimentDebarcodingKit: tmp
        };
}

function make(experimentId, debarcodingKitId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "debarcodingKitId",
                    debarcodingKitId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var debarcodingKitId = variables.debarcodingKitId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "debarcodingKitId",
                    debarcodingKitId
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, debarcodingKitId, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "debarcodingKitId",
                  debarcodingKitId
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, debarcodingKitId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "debarcodingKitId",
                      debarcodingKitId
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var DestroyExperimentDebarcodingKit = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var DestroyExperimentDebarcodingKitMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function str(prim) {
  return prim;
}

function DebarcodingKitsTable(Props) {
  var debarcodingKits = Props.debarcodingKits;
  var disabled = Props.disabled;
  var experimentId = Props.experimentId;
  var deleteIcon = function (kit) {
    return React.createElement("th", {
                className: "border-0 bg-white"
              }, React.createElement(DestroyExperimentDebarcodingKitMutation.make, {
                    children: (function (mutation, param) {
                        return React.createElement("button", {
                                    className: "btn btn-sm btn-outline-danger",
                                    title: "Remove this kit from the experiment",
                                    onClick: (function (param) {
                                        return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(experimentId, kit.id, undefined).variables), ["getExperiment"], undefined, undefined));
                                      })
                                  }, React.createElement(Icons$Astrolabe.Trash.make, {
                                        height: "15",
                                        width: "15"
                                      }));
                      })
                  }));
  };
  return React.createElement("table", {
              className: "table table-striped table-hover table-sm"
            }, React.createElement("thead", undefined, React.createElement("tr", undefined, disabled ? null : React.createElement("th", {
                            className: "border-0"
                          }), React.createElement("th", undefined, "Kit Name"), React.createElement("th", undefined, "Created By"), React.createElement("th", undefined, "Channels"), React.createElement("th", undefined, "Channels Used Per Sample"), React.createElement("th", undefined, "Samples Per File"))), React.createElement("tbody", undefined, $$Array.map((function (kit) {
                        return React.createElement("tr", {
                                    key: kit.name
                                  }, disabled ? null : deleteIcon(kit), React.createElement("td", undefined, kit.name), React.createElement("td", undefined, Belt_Option.getWithDefault(kit.createUserEmail, "N/A")), React.createElement("td", undefined, kit.channels.join(", ")), React.createElement("td", undefined, String(kit.channelsUsedPerSample)), React.createElement("td", undefined, String(kit.maxSamplesPerFile)));
                      }), debarcodingKits)));
}

var make$1 = DebarcodingKitsTable;

exports.DestroyExperimentDebarcodingKit = DestroyExperimentDebarcodingKit;
exports.DestroyExperimentDebarcodingKitMutation = DestroyExperimentDebarcodingKitMutation;
exports.str = str;
exports.make = make$1;
/* DestroyExperimentDebarcodingKitMutation Not a pure module */
