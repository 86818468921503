// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReactHelpers$Astrolabe = require("./ReasonReactHelpers.bs.js");

function experimentReportsPath(experimentId, reportType) {
  return "/experiments/" + (experimentId + ("/export.zip?type=" + reportType));
}

function experimentIdPath(experimentId) {
  return (
          ReasonReactHelpers$Astrolabe.isPublicRoute ? "/public/experiments/" : "/experiments/"
        ) + experimentId;
}

function dEAPath(experimentId, kitId) {
  return (
          ReasonReactHelpers$Astrolabe.isPublicRoute ? "/public/experiments/" : "/experiments/"
        ) + (experimentId + ("/analyses/" + kitId));
}

function sampleNavigatorPath(experimentId, sampleId) {
  return (
          ReasonReactHelpers$Astrolabe.isPublicRoute ? "/public/experiments/" : "/experiments/"
        ) + (experimentId + ("/samples/" + (sampleId + "/explorer")));
}

var faqUrl = "https://github.com/astrolabediagnostics/orloj/wiki/Astrolabe-Diagnostics-FAQ";

var tutorialUrl = "https://github.com/astrolabediagnostics/orloj/wiki/Astrolabe-Diagnostics-Tutorials";

var dEASetupTutorialUrl = "https://youtu.be/TwnXCaQdVSw";

var dEAResultsTutorialUrl = "https://youtu.be/elySZiNO_QU";

var debarcodingTutorialUrl = "https://youtu.be/KmqOBZpqb34";

var fileUploadTutorialUrl = "https://youtu.be/nyO7v4mMM6g";

var usingTemplateTutorialUrl = "https://youtu.be/IH7IhIGmqsI";

exports.faqUrl = faqUrl;
exports.tutorialUrl = tutorialUrl;
exports.dEASetupTutorialUrl = dEASetupTutorialUrl;
exports.dEAResultsTutorialUrl = dEAResultsTutorialUrl;
exports.debarcodingTutorialUrl = debarcodingTutorialUrl;
exports.fileUploadTutorialUrl = fileUploadTutorialUrl;
exports.usingTemplateTutorialUrl = usingTemplateTutorialUrl;
exports.experimentReportsPath = experimentReportsPath;
exports.experimentIdPath = experimentIdPath;
exports.dEAPath = dEAPath;
exports.sampleNavigatorPath = sampleNavigatorPath;
/* ReasonReactHelpers-Astrolabe Not a pure module */
