// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var UserExperimentMutations$Astrolabe = require("./UserExperimentMutations.bs.js");

function UserExperimentRow(Props) {
  var experimentId = Props.experimentId;
  var userEmail = Props.userEmail;
  var userId = Props.userId;
  var isUserAssigned = Props.isUserAssigned;
  var id = userEmail + userId;
  var unassignUserFromExperiment = React.createElement(UserExperimentMutations$Astrolabe.DestroyUserExperimentMutation.make, {
        children: (function (mutation, param) {
            return React.createElement("input", {
                        id: id,
                        checked: isUserAssigned,
                        type: "checkbox",
                        value: userId,
                        onChange: (function (param) {
                            var variables = UserExperimentMutations$Astrolabe.DestroyUserExperimentConfig.make(experimentId, userId, undefined).variables;
                            return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(variables), ["getUserExperiment"], undefined, undefined));
                          })
                      });
          })
      });
  var assignUserToExperiment = React.createElement(UserExperimentMutations$Astrolabe.CreateUserExperimentMutation.make, {
        children: (function (mutation, param) {
            return React.createElement("input", {
                        id: id,
                        checked: isUserAssigned,
                        type: "checkbox",
                        value: userId,
                        onChange: (function (param) {
                            var variables = UserExperimentMutations$Astrolabe.CreateUserExperimentConfig.make(experimentId, userId, undefined).variables;
                            return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(variables), ["getUserExperiment"], undefined, undefined));
                          })
                      });
          })
      });
  return React.createElement("div", {
              key: id,
              className: "blue-checkbox mr-3",
              style: {
                maxWidth: "max-content"
              }
            }, isUserAssigned ? unassignUserFromExperiment : assignUserToExperiment, React.createElement("label", {
                  className: "no-help",
                  title: userEmail,
                  htmlFor: id
                }, Util$Astrolabe.$$String.truncate(userEmail, 26, undefined, undefined)));
}

var make = UserExperimentRow;

exports.make = make;
/* react Not a pure module */
