// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Experiment$Astrolabe = require("../../models/Experiment.bs.js");
var GraphQLHelpers$Astrolabe = require("../../utils/GraphQLHelpers.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");

function OrganismSelect(Props) {
  var experimentId = Props.experimentId;
  var experimentOrganism = Props.experimentOrganism;
  var disabled = Props.disabled;
  return React.createElement("div", {
              className: "d-flex"
            }, React.createElement(ExperimentGraphQL$Astrolabe.UpdateMutation.make, {
                  children: (function (mutation, param) {
                      return Experiment$Astrolabe.Organism.all.map((function (organism, index) {
                                    var radioID = "organism" + String(index);
                                    return React.createElement("div", {
                                                key: radioID,
                                                className: "form-check col-4"
                                              }, React.createElement("input", {
                                                    className: "form-check-input",
                                                    id: radioID,
                                                    checked: organism === experimentOrganism,
                                                    disabled: disabled,
                                                    name: "organisms",
                                                    type: "radio",
                                                    value: String(index),
                                                    onChange: (function (param) {
                                                        return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(ExperimentGraphQL$Astrolabe.UpdateConfig.make(experimentId, undefined, undefined, undefined, undefined, undefined, undefined, organism, undefined, undefined, undefined).variables), ["getExperiment"], undefined, undefined));
                                                      })
                                                  }), React.createElement("label", {
                                                    className: "form-check-label",
                                                    htmlFor: radioID
                                                  }, Experiment$Astrolabe.Organism.toString(organism)));
                                  }));
                    })
                }));
}

var UpdateExperimentConfig;

var UpdateExperimentMutation;

var Organism;

var make = OrganismSelect;

exports.UpdateExperimentConfig = UpdateExperimentConfig;
exports.UpdateExperimentMutation = UpdateExperimentMutation;
exports.Organism = Organism;
exports.make = make;
/* react Not a pure module */
