// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var BannerNotice$Astrolabe = require("../shared/BannerNotice.bs.js");

function ProcessingNotificationBanners(Props) {
  var status = Props.status;
  var notice = function (message) {
    return React.createElement(BannerNotice$Astrolabe.make, {
                children: message
              });
  };
  if (status !== -384073879) {
    if (status !== 1020136956) {
      return null;
    } else {
      return notice("This experiment is being debarcoded. This usually takes 1-3 hours, depending on number of samples. After debarcoding Astrolabe will continue with the analysis.");
    }
  } else {
    return notice("This experiment is being analyzed. This usually takes 1-3 hours, depending on number of samples.");
  }
}

var make = ProcessingNotificationBanners;

var $$default = ProcessingNotificationBanners;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
