// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Feature$Astrolabe = require("./Feature.bs.js");

function getFDR(t, feature, cellSubset) {
  return t.getFDRForId(Feature$Astrolabe.id(feature), cellSubset);
}

function make(prim) {
  return new ExperimentDifferentialAbundance(prim);
}

function hasDataForId(prim, prim$1) {
  return prim.hasDataForId(prim$1);
}

exports.make = make;
exports.hasDataForId = hasDataForId;
exports.getFDR = getFDR;
/* Feature-Astrolabe Not a pure module */
