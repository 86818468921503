/* @flow */
"use strict";

import { List, Map, OrderedMap, Record } from "immutable";
import _DataTable from "./dataTable";
import _ExperimentDifferentialAbundance from "./experimentDifferentialAbundance";
import _ExperimentStatistics from "./experimentStatistics";
import { type MDSPoint as _MDSPoint } from "./mdsData";
import _MDSData from "./mdsData";
import _SampleStatistics from "./sampleStatistics";
import _SampleDensities from "./sampleDensities";

export const DataTable = _DataTable;
export const ExperimentDifferentialAbundance = _ExperimentDifferentialAbundance;
export const ExperimentStatistics = _ExperimentStatistics;
export const MDSData = _MDSData;
export const SampleStatistics = _SampleStatistics;
export const SampleDensities = _SampleDensities;

export type MDSPoint = _MDSPoint;

export const NA_VALUE = "__NA__";

export const _Experiment = Record({
  id: null,
  name: "",
  status: null,
  organism: null,
  instrument: null,
  tissue_type: null,
  display_live_dead: null,
  is_show_analysis_column: null,
  is_archived: null,
  is_public: null,
  "editable?": null,
  "can_edit_kits?": null,
  "can_edit_channel_mapping?": null,
  "can_edit_analyses?": null,
  "can_edit_features?": null,
  "has_debarcoding_kits?": null,
  "has_de_analysis?": null,
  pipeline_job_percentages: {
    unstarted: 0,
    created: 0,
    started: 0,
    finished: 0,
    errored: 0,
  },
  qc_metric_thresholds: {},
  labeling_strategies: [],
  created_at_ymd: "",
  create_user_email: "",
  authorized_for_user_id: null,
});

export class Experiment extends _Experiment {
  constructor(props: Object) {
    super({
      ...props,
      qc_metric_thresholds: Map((props.qc_metric_thresholds || []).map((qcmt) => [qcmt.metric_name, qcmt])).toJS(),
    });
  }

  get(key: string): any {
    if (key === "display_status") {
      const status = this.get("status");
      if (status === "created") {
        return "Pending Preprocessing";
      } else if (status === "preprocessing") {
        return "Preprocessing";
      } else if (status === "pending_analysis") {
        return "Pending Analysis";
      } else if (status === "adding_debarcoding_kits") {
        return "Adding Debarcoding Kits";
      } else if (status === "mapping_barcoding_channels") {
        return "Mapping Barcoding Channels";
      } else if (status === "debarcoding") {
        return "Debarcoding";
      } else if (status === "analyzing") {
        return "Analyzing";
      } else if (status === "done") {
        return "Done";
      }
    } else if (key === "display_tissue_type") {
      const tissueType = this.get("tissue_type");
      if (tissueType === "na") {
        return "Default";
      } else if (tissueType === "whole_blood") {
        return "Identify granulocytes using CD66 or CD16";
      } else if (tissueType === "mixed_tissue") {
        return "Identify granulocytes, mark CD45- as debris";
      }
    } else {
      return super.get(key);
    }
  }
}

export const CellSubsetLevels = Record({
  all: [],
  default: "",
  values: {},
  cell_subset_map: {},
});

export const _ChannelUsage = Record({
  id: null,
  name: "",
  desc: "",
  usage: "ignored",
  errors: OrderedMap(),
});

export class ChannelUsage extends _ChannelUsage {
  get(key: string): any {
    if (key === "display_usage") {
      const usage = this.get("usage");
      if (usage === "ignored") {
        return "Ignored";
      } else if (usage === "analysis") {
        return "Analysis Only";
      } else if (usage === "live_dead") {
        return "Live/Dead";
      } else if (usage === "classification") {
        return "Used";
      }
    } else {
      return super.get(key);
    }
  }
}

export const _Feature = Record({
  id: null,
  experiment_id: null,
  name: "",
  values: new List(),
  is_allow_na: false,
});

export class Feature extends _Feature {
  get displayValues(): List<string> {
    let values = this.get("values");

    if (!this.get("is_allow_na")) {
      return values;
    } else {
      return values.map((v) => (v == NA_VALUE ? "NA" : v));
    }
  }
}

export const FeatureValue = Record({
  id: null,
  sample_id: null,
  feature_id: null,
  value: null,
});

export const Instance = Record({
  id: null,
  name: "",
  allowed_email_domain: "",
  billed_sample_count: 0,
  sample_count: 0,
  user_count: 0,
  max_samples: "",
  contract_start_date: null,
  contract_end_date: null,
});

export const PipelineRun = Record({
  id: null,
  experiment_id: null,
  experiment_name: "",
  status: null,
  started_at: null,
  ended_at: null,
  dag_type: "",
  job_percentages: {
    unstarted: 0,
    created: 0,
    started: 0,
    finished: 0,
    errored: 0,
  },
});

const _Sample = Record({
  id: null,
  experiment_id: null,
  name: "",
  file_file_name: null,
  file_file_size: 0,
  preprocessing_job_id: null,
  preprocessing_status: null,
  parameter_digest: "",
  event_counts: {
    total: 0,
    bead: 0,
    cell: 0,
  },
  feature_values: List(),
  feature_values_loaded: false,
  errors: List(),
  qc_metrics: [],
  created_at_ymd: "",
  create_user_email: "",
  source_sample_file_name: "",
  has_been_debarcoded: false,
  barcoding_channels: [],
  is_ignored_from_debarcoding: false,
});

export class Sample extends _Sample {
  constructor(props: Object) {
    super({
      ...props,
      feature_values_loaded: !!props.feature_values,
      feature_values: List((props.feature_values || []).map((fv) => new FeatureValue(fv))),
    });
  }

  featureValue(featureOrId: Feature | number): ?string {
    var featureValue = null;
    if (typeof featureOrId === "number") {
      featureValue = this.get("feature_values").find((fv) => fv.get("feature_id") === featureOrId);
    } else {
      const featureId = (featureOrId: Feature).get("id");
      featureValue = this.get("feature_values").find((fv) => fv.get("feature_id") === featureId);
    }

    return featureValue ? featureValue.get("value") : null;
  }

  featureValueDisplay(featureOrId: Feature | number): string {
    const featureValue = this.featureValue(featureOrId);
    if (!featureValue) {
      return "";
    } else if (featureValue === NA_VALUE) {
      return "NA";
    } else {
      return featureValue;
    }
  }

  featureValueIsNA(featureOrId: Feature | number): boolean {
    return this.featureValue(featureOrId) === NA_VALUE;
  }
}

export type JobInfo = {
  id: number,
  status: "completed" | "failed" | "running" | "pending",
  error?: string,
};

export type BoxPlotDatum = {
  min: number,
  q1: number,
  median: number,
  q3: number,
  max: number,
};

export type SampleHistories = {
  instance_id: number,
  history: { [string]: number },
};

export type ScatterPlotSeries = Array<{ x: number, y: number }>;
export type ScatterPlotData = Array<ScatterPlotSeries>;
export type ScatterPlotProps = {
  data: ScatterPlotData,
  xAxisLabel?: string,
  yAxisLabel?: string,
  xMax?: number,
  yMax?: number,
  legendLabels?: Array<string>,
  onDataClick?: (string) => any,
  height?: number,
  width?: number,
};

export type Session = {
  user: ?SessionUser,
  instance: ?Instance,
  csrf_token: string,
};

export type SessionUser = {
  email: string,
  role_name: string,
  is_admin: boolean,
  is_staff: boolean,
  is_sysadmin: boolean,
  has_asds_access: boolean,
};

export const User = new Record({
  id: null /* number */,
  instance_id: null /* number */,
  email: null /* string */,
  role_name: null /* string */,
  reset_password_sent_at: null /* string */,
  sign_in_count: null /* number */,
  current_sign_in_at: null /* string */,
  last_sign_in_at: null /* string */,
  confirmed_at: null /* string */,
  confirmation_sent_at: null /* string */,
  failed_attempts: null /* number */,
  locked_at: null /* string */,
  created_at: null /* string */,
  created_at_ymd: "",
});
