/* @flow */
"use strict";

import type { DensityChartSeries } from "components/samples/ChannelDensityChart";
import { joinQuoted, roundFloat } from "components/utils";

export default class SampleDensities {
  density_ranges: {
    [string]: Array<number>, // Channel
  };
  densities: {
    [string]: {
      // Level
      [string]: {
        // Cell Subset or "All Cells"
        [string]: Array<number>, // Channel
      },
    },
  };

  constructor(props: Object) {
    this.density_ranges = props.density_ranges;
    this.densities = props.densities;
  }

  chartData(channel: string, level: string, cellSubset?: string = "All Cells", xOffset?: number = 0): DensityChartSeries {
    const xValues = this.density_ranges[channel];
    if (!xValues) {
      throw `Couldn't find density ranges for '${channel}' (available ranges are: ${joinQuoted(
        Object.keys(this.density_ranges),
      )})`;
    }

    const levelDensities = this.densities[level];
    if (!levelDensities) {
      throw `Couldn't find densities for level '${level}' (available levels are: ${joinQuoted(Object.keys(this.densities))})`;
    }

    const cellSubsetDensities = levelDensities[cellSubset];
    if (!cellSubsetDensities) {
      throw `Couldn't find densities for cell subset '${cellSubset}' ` +
        `(available subsets for level '${level}' are: ${joinQuoted(Object.keys(levelDensities))})`;
    }

    const channelYValues = cellSubsetDensities[channel];
    if (!channelYValues) {
      throw `Couldn't find densities for ${channel} ` +
        `(available channels for ${level} > ${cellSubset} are: ${joinQuoted(Object.keys(cellSubsetDensities))})`;
    }

    return xValues.map((originalX, i) => {
      const x = originalX + xOffset;
      const y = channelYValues[i];
      return { x, y, label: `${channel} - ${cellSubset}: (${roundFloat(x, 3)}, ${roundFloat(y, 3)})` };
    });
  }
}
