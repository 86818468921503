// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function ItemMultiSelector(Props) {
  var availableSections = Props.availableSections;
  var selectedSectionIdx = Props.selectedSectionIdx;
  var setSelectedSectionIdx = Props.setSelectedSectionIdx;
  var availableItems = Props.availableItems;
  var defaultSelectedItems = Props.defaultSelectedItems;
  var selectedItems = Props.selectedItems;
  var onDeselectItems = Props.onDeselectItems;
  var onSelectItems = Props.onSelectItems;
  var title = Props.title;
  var rows = Props.rows;
  var onClickItem = Props.onClickItem;
  var onChangeSectionOpt = Props.onChangeSection;
  var onChangeSection = onChangeSectionOpt !== undefined ? onChangeSectionOpt : (function (prim) {
        
      });
  var match = React.useState((function () {
          return "";
        }));
  var setSearchFilter = match[1];
  var searchFilter = match[0];
  var filteredItems = Curry._2(Util$Astrolabe.$$String.$$Set.filter, (function (x) {
          return x.toUpperCase().includes(searchFilter);
        }), availableItems);
  var rows$1 = Util$Astrolabe.or(rows, Curry._1(Util$Astrolabe.$$String.$$Set.length, filteredItems) <= 6 ? 2 : 5);
  var maxSelectorHeight = String((rows$1 << 5) + 15 | 0) + "px";
  var selectDefaultButton;
  if (defaultSelectedItems !== undefined) {
    var items = Caml_option.valFromOption(defaultSelectedItems);
    selectDefaultButton = React.createElement("button", {
          className: "btn btn-primary ml-2",
          onClick: (function (param) {
              return Curry._1(onSelectItems, items);
            })
        }, "Select Default");
  } else {
    selectDefaultButton = null;
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "d-flex flex-row align-items-center justify-content-end pt-2"
                }, React.createElement("ul", {
                      className: "nav nav-pills d-flex mr-auto"
                    }, $$Array.mapi((function (idx, section) {
                            return React.createElement("li", {
                                        key: section + String(idx),
                                        className: (
                                          idx === selectedSectionIdx ? "active " : ""
                                        ) + "nav-link cursor-pointer",
                                        onClick: (function (param) {
                                            Curry._1(setSelectedSectionIdx, idx);
                                            Curry._1(setSearchFilter, (function (param) {
                                                    return "";
                                                  }));
                                            return Curry._1(onChangeSection, section);
                                          })
                                      }, Curry._1(Util$Astrolabe.$$String.capitalize, section));
                          }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, availableSections))), React.createElement("div", {
                      className: "d-flex align-items-center border px-3",
                      style: {
                        borderRadius: "0.25rem"
                      }
                    }, React.createElement(Icons$Astrolabe.Magnifier.make, {
                          height: "15",
                          width: "15"
                        }), React.createElement("input", {
                          style: {
                            border: "none",
                            minWidth: "275px",
                            outline: "none",
                            padding: "0.375rem 0.75rem"
                          },
                          placeholder: title,
                          type: "text",
                          value: searchFilter,
                          onChange: (function (e) {
                              var text = ReasonReactHelpers$Astrolabe.valueFromEvent(e);
                              return Curry._1(setSearchFilter, (function (param) {
                                            return text.toUpperCase();
                                          }));
                            })
                        })), React.createElement("div", {
                      className: "d-flex justify-content-end align-items-center"
                    }, React.createElement("button", {
                          className: "btn btn-primary ml-2",
                          onClick: (function (param) {
                              return Curry._1(onSelectItems, Curry._2(Util$Astrolabe.$$String.$$Set.union, selectedItems, filteredItems));
                            })
                        }, "Select All"), React.createElement("button", {
                          className: "btn btn-primary ml-2",
                          onClick: (function (param) {
                              return Curry._1(onDeselectItems, filteredItems);
                            })
                        }, "Select None"), selectDefaultButton)), React.createElement("div", {
                  className: "d-flex"
                }, React.createElement("div", {
                      className: "horizontal-scroll d-flex flex-column flex-wrap border rounded mt-2 p-1 pt-2 px-4 align-items-start",
                      style: {
                        height: "auto",
                        maxHeight: maxSelectorHeight
                      }
                    }, $$Array.mapi((function (i, item) {
                            var id = item + String(i);
                            return React.createElement("div", {
                                        key: id,
                                        className: "blue-checkbox mr-3",
                                        style: {
                                          maxWidth: "max-content"
                                        }
                                      }, React.createElement("input", {
                                            id: id,
                                            checked: Curry._2(Util$Astrolabe.$$String.$$Set.contains, item, selectedItems),
                                            type: "checkbox",
                                            value: item,
                                            onChange: (function (param) {
                                                return Curry._1(onClickItem, item);
                                              })
                                          }), React.createElement("label", {
                                            htmlFor: id
                                          }, item));
                          }), Curry._1(Util$Astrolabe.$$String.$$Set.to_array, filteredItems)))));
}

var $$String$1;

var or = Util$Astrolabe.or;

var row_height_px = 32;

var row_container_padding_px = 15;

var make = ItemMultiSelector;

exports.$$String = $$String$1;
exports.or = or;
exports.row_height_px = row_height_px;
exports.row_container_padding_px = row_container_padding_px;
exports.make = make;
/* react Not a pure module */
