// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Models = require("models");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function rFloat(json) {
  var $$float = Js_json.decodeNumber(json);
  if ($$float !== undefined) {
    return $$float;
  } else {
    return NaN;
  }
}

function fromJson(json) {
  var partial_arg = [];
  var partial_arg$1 = [];
  return {
          sampleIds: Json_decode.field("sample_ids", (function (param) {
                  return Json_decode.withDefault(partial_arg, (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          cellSubsets: Json_decode.field("cell_subsets", (function (param) {
                  return Json_decode.withDefault(partial_arg$1, (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          channelExpressionByChannelAndCellSubset: Json_decode.field("channel_expression", (function (param) {
                  return Json_decode.dict((function (param) {
                                return Json_decode.dict((function (param) {
                                              return Json_decode.array(rFloat, param);
                                            }), param);
                              }), param);
                }), json),
          markerLegendLabel: Json_decode.field("marker_legend_label", Json_decode.string, json),
          maxLogFoldChangeByChannel: Json_decode.field("max_log_fold_change", (function (param) {
                  return Json_decode.dict((function (param) {
                                return Json_decode.array(rFloat, param);
                              }), param);
                }), json),
          suggestedOrder: Json_decode.field("suggested_order", (function (param) {
                    return Json_decode.dict((function (param) {
                                  return Json_decode.dict((function (param) {
                                                return Json_decode.array(Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json)["sample_id_order"]
        };
}

function byCellSubsetLevelFromJson(json) {
  var dict = Belt_Option.getWithDefault(Js_json.decodeObject(json), { });
  return Js_dict.map(fromJson, dict);
}

function foldChangeDataTable(t) {
  return new Models.DataTable({
              columns: t.maxLogFoldChangeByChannel,
              rowLabels: t.cellSubsets,
              columnLabels: $$Array.map((function (param) {
                      return param[0];
                    }), Js_dict.entries(t.maxLogFoldChangeByChannel))
            });
}

function channelExpressionDataTable(channelDesc, t) {
  var channelExpressionByCellSubset = Belt_Option.getExn(Js_dict.get(t.channelExpressionByChannelAndCellSubset, channelDesc));
  return new Models.DataTable({
              columns: channelExpressionByCellSubset,
              rowLabels: t.sampleIds,
              columnLabels: $$Array.map((function (param) {
                      return param[0];
                    }), Js_dict.entries(channelExpressionByCellSubset))
            });
}

function channelExpression(channelDesc, cellSubset, t) {
  return Belt_Option.getExn(Js_dict.get(Belt_Option.getExn(Js_dict.get(t.channelExpressionByChannelAndCellSubset, channelDesc)), cellSubset));
}

function getSortOrder(sortOrderKey, t) {
  return Belt_Option.getExn(Js_dict.get(t.suggestedOrder, sortOrderKey));
}

exports.rFloat = rFloat;
exports.fromJson = fromJson;
exports.byCellSubsetLevelFromJson = byCellSubsetLevelFromJson;
exports.foldChangeDataTable = foldChangeDataTable;
exports.channelExpressionDataTable = channelExpressionDataTable;
exports.channelExpression = channelExpression;
exports.getSortOrder = getSortOrder;
/* models Not a pure module */
