// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Channel$Astrolabe = require("../../../models/Channel.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");

var ppx_printed_query = "mutation UpdateChannel($id: ID!, $usage: Usage)  {\nupdateChannel(id: $id, usage: $usage)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateChannel");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ChannelPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateChannel: tmp
        };
}

function json_of_Usage(value) {
  if (value >= 257034022) {
    if (value >= 739437404) {
      return "ANALYSIS";
    } else {
      return "CLASSIFICATION";
    }
  } else if (value >= -568609769) {
    return "LIVE_DEAD";
  } else {
    return "IGNORED";
  }
}

function make(id, usage, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "usage",
                    usage !== undefined ? json_of_Usage(usage) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  var usage = variables.usage;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "usage",
                    usage !== undefined ? json_of_Usage(usage) : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, usage, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "usage",
                  usage !== undefined ? json_of_Usage(usage) : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, usage, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "usage",
                      usage !== undefined ? json_of_Usage(usage) : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var UpdateUsageConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_Usage: json_of_Usage,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UpdateUsageMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function ChannelUsageColumn(Props) {
  var channel = Props.channel;
  var isEditable = Props.isEditable;
  var isShowAnalysisColumn = Props.isShowAnalysisColumn;
  var onSuccess = Props.onSuccess;
  var channelUsageToString = Channel$Astrolabe.Usage.toDisplayString(channel.usage);
  var liveDead = channel.usage === /* LIVE_DEAD */-568609769;
  var liveDeadTitle = liveDead ? "This channel was selected as a Live/Dead channel and will be ignored from the rest of the analysis." : "";
  var name = channelUsageToString + channel.id;
  return React.createElement(UpdateUsageMutation.make, {
              children: (function (mutation, param) {
                  var checked = channel.usage === /* IGNORED */-592639246 || channel.usage === /* LIVE_DEAD */-568609769;
                  var isShowAnalysisDisabled = React.createElement("td", {
                        className: "w-11 text-center",
                        title: liveDeadTitle
                      }, React.createElement("input", {
                            checked: checked,
                            disabled: liveDead,
                            name: name,
                            type: "checkbox",
                            value: Channel$Astrolabe.Usage.toDisplayString(channel.usage),
                            onChange: (function (param) {
                                var usage = checked ? /* CLASSIFICATION */257034022 : /* IGNORED */-592639246;
                                GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(channel.id, usage, undefined).variables), ["getExperiment"], undefined, undefined));
                                return Curry._1(onSuccess, undefined);
                              })
                          }));
                  var isShowAnalysisEnabled = React.createElement(React.Fragment, undefined, React.createElement("td", {
                            className: "w-11 text-center",
                            title: liveDeadTitle
                          }, React.createElement("input", {
                                checked: channel.usage === /* IGNORED */-592639246 || liveDead,
                                disabled: liveDead,
                                name: name,
                                type: "radio",
                                value: "ignored",
                                onChange: (function (param) {
                                    GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(channel.id, /* IGNORED */-592639246, undefined).variables), ["getExperiment"], undefined, undefined));
                                    return Curry._1(onSuccess, undefined);
                                  })
                              })), React.createElement("td", {
                            className: "w-11 text-center",
                            title: liveDeadTitle
                          }, React.createElement("input", {
                                checked: channel.usage === /* ANALYSIS */739437404,
                                disabled: liveDead,
                                name: name,
                                type: "radio",
                                value: "analysis",
                                onChange: (function (param) {
                                    GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(channel.id, /* ANALYSIS */739437404, undefined).variables), ["getExperiment"], undefined, undefined));
                                    return Curry._1(onSuccess, undefined);
                                  })
                              })), React.createElement("td", {
                            className: "w-11 text-center",
                            title: liveDeadTitle
                          }, React.createElement("input", {
                                checked: channel.usage === /* CLASSIFICATION */257034022,
                                disabled: liveDead,
                                name: name,
                                type: "radio",
                                value: "classification",
                                onChange: (function (param) {
                                    GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(channel.id, /* CLASSIFICATION */257034022, undefined).variables), ["getExperiment"], undefined, undefined));
                                    return Curry._1(onSuccess, undefined);
                                  })
                              })));
                  var showUsage = React.createElement("td", {
                        className: "w-11 text-center"
                      }, channelUsageToString);
                  return React.createElement(React.Fragment, undefined, isEditable ? (
                                isShowAnalysisColumn ? isShowAnalysisEnabled : isShowAnalysisDisabled
                              ) : showUsage);
                })
            });
}

var make$1 = ChannelUsageColumn;

exports.UpdateUsageConfig = UpdateUsageConfig;
exports.UpdateUsageMutation = UpdateUsageMutation;
exports.make = make$1;
/* UpdateUsageMutation Not a pure module */
