// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReactHelpers$Astrolabe = require("../../../utils/ReasonReactHelpers.bs.js");

function reducer(_state, action) {
  return {
          yMax: action[0]
        };
}

function CSNBarChartSettings(Props) {
  var yMax = Props.yMax;
  var onDone = Props.onDone;
  var initialState = {
    yMax: yMax.toFixed(1)
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "p-3"
            }, React.createElement("div", {
                  className: "d-flex justify-content-between"
                }, "Y-Axis Limit:", React.createElement("input", {
                      min: "0",
                      step: 0.1,
                      type: "number",
                      value: state.yMax,
                      onChange: (function (e) {
                          return Curry._1(dispatch, /* SetYMax */[ReasonReactHelpers$Astrolabe.valueFromEvent(e)]);
                        })
                    })), React.createElement("div", {
                  className: "d-flex justify-content-end pt-2"
                }, React.createElement("button", {
                      className: "btn btn-primary",
                      onClick: (function (param) {
                          return Curry._1(onDone, Number(state.yMax));
                        })
                    }, "Done")));
}

var make = CSNBarChartSettings;

exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
