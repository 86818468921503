// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Victory = require("victory");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var MDSData$Astrolabe = require("./MDSData.bs.js");
var VictoryTheme$Astrolabe = require("../../VictoryTheme.bs.js");
var GradientLegend$Astrolabe = require("./GradientLegend.bs.js");
var GraphDownloadButton$Astrolabe = require("../../GraphDownloadButton.bs.js");

function MDSMap(Props) {
  var colorBy = Props.colorBy;
  var differentialAbundance = Props.differentialAbundance;
  var experimentName = Props.experimentName;
  var mdsData = Props.mdsData;
  var onClick = Props.onClick;
  var immutableJsSelectedLabels = Props.selectedLabels;
  var selectedLabels = immutableJsSelectedLabels.toArray();
  var renderLegend = function (mapData) {
    if (colorBy === "") {
      return null;
    } else {
      return React.createElement(GradientLegend$Astrolabe.make, {
                  gradientStops: [
                    Curry._1(mapData.colorInterpolator, 0.0),
                    Curry._1(mapData.colorInterpolator, 0.5),
                    Curry._1(mapData.colorInterpolator, 1.0)
                  ],
                  max: Util$Astrolabe.Pair.second(mapData.extent),
                  min: Util$Astrolabe.Pair.first(mapData.extent)
                });
    }
  };
  var mapData = MDSData$Astrolabe.getMapData(colorBy, differentialAbundance, mdsData);
  var chartDomain = {
    x: [
      -0.5,
      MDSData$Astrolabe.maxX(mdsData) + 0.5
    ],
    y: [
      -0.5,
      MDSData$Astrolabe.maxY(mdsData) + 0.5
    ]
  };
  var scatterTooltip = React.createElement(Victory.VictoryTooltip, {
        style: VictoryTheme$Astrolabe.theme.tooltip.style,
        flyoutStyle: VictoryTheme$Astrolabe.theme.tooltip.flyoutStyle,
        cornerRadius: VictoryTheme$Astrolabe.theme.tooltip.cornerRadius
      });
  var data = mapData.points.map((function (p) {
          return {
                  x: p.x,
                  y: p.y,
                  cell_subset: p.cellSubset,
                  freq: p.freq,
                  fill: p.fill,
                  label: p.label,
                  name: p.name
                };
        }));
  var ref = React.useRef(null);
  return React.createElement("div", {
              ref: ref,
              className: "row"
            }, React.createElement("div", {
                  className: "col-10"
                }, React.createElement(GraphDownloadButton$Astrolabe.make, {
                      prefix: experimentName,
                      name: "MDS Map",
                      graphType: /* Svg */4153924,
                      container: (function (param) {
                          return ref.current;
                        }),
                      filenames: "MDS Map"
                    }), React.createElement(Victory.VictoryChart, {
                      padding: {
                        top: 20,
                        right: 0,
                        bottom: 50,
                        left: 50
                      },
                      theme: VictoryTheme$Astrolabe.theme,
                      width: 650.0,
                      height: 550.0,
                      domain: chartDomain,
                      containerComponent: React.createElement(Victory.VictoryVoronoiContainer, {
                            height: 550.0,
                            responsive: false
                          }),
                      children: null,
                      key: selectedLabels.join("-")
                    }, React.createElement(Victory.VictoryAxis, {
                          label: "MDS Map 1",
                          offsetY: 50.0,
                          tickFormat: (function (param) {
                              return null;
                            }),
                          style: VictoryTheme$Astrolabe.theme.variants.axis.mdsMap.style
                        }), React.createElement(Victory.VictoryAxis, {
                          label: "MDS Map 2",
                          offsetX: 50.0,
                          crossAxis: true,
                          tickFormat: (function (param) {
                              return null;
                            }),
                          dependentAxis: true,
                          style: VictoryTheme$Astrolabe.theme.variants.axis.mdsMap.style
                        }), React.createElement(Victory.VictoryGroup, {
                          children: React.createElement(Victory.VictoryScatter, {
                                bubbleProperty: "freq",
                                maxBubbleSize: 30.0,
                                minBubbleSize: 5.0,
                                style: {
                                  data: {
                                    fillOpacity: 0.5,
                                    fill: (function (d) {
                                        return d.fill;
                                      }),
                                    stroke: (function (d) {
                                        if (selectedLabels.includes(d.cell_subset)) {
                                          return "#000000";
                                        } else {
                                          return "#AAAAAA";
                                        }
                                      }),
                                    strokeWidth: (function (d) {
                                        if (selectedLabels.includes(d.cell_subset)) {
                                          return 1.0;
                                        } else {
                                          return 0.5;
                                        }
                                      })
                                  }
                                },
                                events: [{
                                    target: "data",
                                    eventHandlers: {
                                      onClick: (function (param) {
                                          return [{
                                                    target: "data",
                                                    mutation: (function (props) {
                                                        var cellSubset = props.datum.cell_subset;
                                                        Curry._1(onClick, cellSubset);
                                                        return {
                                                                stroke: (function (_d) {
                                                                    if (selectedLabels.includes(cellSubset)) {
                                                                      return "#AAAAAA";
                                                                    } else {
                                                                      return "#000000";
                                                                    }
                                                                  }),
                                                                strokeWidth: (function (_d) {
                                                                    if (selectedLabels.includes(cellSubset)) {
                                                                      return 0.5;
                                                                    } else {
                                                                      return 1.0;
                                                                    }
                                                                  })
                                                              };
                                                      })
                                                  }];
                                        })
                                    }
                                  }],
                                labelComponent: scatterTooltip,
                                data: data
                              })
                        }))), differentialAbundance !== "none" || colorBy !== "" ? React.createElement("div", {
                    className: "col-2 pr-0 pt-3"
                  }, React.createElement("h6", undefined, Util$Astrolabe.or(mapData.legendTitle, "Untitled")), renderLegend(mapData)) : null);
}

var $$Array;

var $$Option;

var Dict;

var Pair;

var or = Util$Astrolabe.or;

var fmt = Util$Astrolabe.fmt;

var make = MDSMap;

var $$default = MDSMap;

exports.$$Array = $$Array;
exports.$$Option = $$Option;
exports.Dict = Dict;
exports.Pair = Pair;
exports.or = or;
exports.fmt = fmt;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
