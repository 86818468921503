// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function CircleIcon(Props) {
  var backgroundColor = Props.backgroundColor;
  var cursorOpt = Props.cursor;
  var sizeOpt = Props.size;
  var textColor = Props.textColor;
  var tooltip = Props.tooltip;
  var text = Props.text;
  var cursor = cursorOpt !== undefined ? cursorOpt : "help";
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var sizeInPx = size.toString() + "px";
  var baseClassName = "rounded-circle d-flex flex-column justify-content-center align-items-center ";
  var match;
  match = backgroundColor.tag ? /* tuple */[
      baseClassName,
      {
        backgroundColor: backgroundColor[0],
        height: sizeInPx,
        width: sizeInPx
      }
    ] : /* tuple */[
      baseClassName + backgroundColor[0],
      {
        height: sizeInPx,
        width: sizeInPx
      }
    ];
  var fontSize = (size / 24 * 100).toString() + "%";
  var match$1;
  match$1 = textColor.tag ? /* tuple */[
      "mb-0",
      {
        color: textColor[0],
        fontSize: fontSize
      }
    ] : /* tuple */[
      "mb-0 " + textColor[0],
      {
        fontSize: fontSize
      }
    ];
  return React.createElement("div", {
              className: "d-flex text-monospace flex-column align-items-center justify-content-center cursor-" + cursor
            }, React.createElement("div", {
                  className: match[0],
                  style: match[1],
                  title: tooltip
                }, React.createElement("div", {
                      className: match$1[0],
                      style: match$1[1]
                    }, ReasonReactHelpers$Astrolabe.str(text))));
}

var make = CircleIcon;

exports.make = make;
/* react Not a pure module */
