/* @flow */

export function getCookies(): { [string]: string } {
  return document.cookie
    .split(";")
    .map((pair) => pair.split("="))
    .reduce((obj, [k, v]) => {
      obj[(k || "").trim()] = (v || "").trim();
      return obj;
    }, {});
}

export function getCookie(key: string): ?string {
  return getCookies()[key];
}
