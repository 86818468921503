// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$Astrolabe = require("../../models/Util.bs.js");
var Sample$Astrolabe = require("../../models/Sample.bs.js");
var DisplayErrors$Astrolabe = require("../shared/DisplayErrors.bs.js");
var FocusOutInput$Astrolabe = require("../shared/FocusOutInput.bs.js");
var SampleGraphQL$Astrolabe = require("../../graphql/SampleGraphQL.bs.js");

function SampleNameInput(Props) {
  var disabledOpt = Props.disabled;
  var onComplete = Props.onComplete;
  var sample = Props.sample;
  var shouldUpdateFileFileNameOpt = Props.shouldUpdateFileFileName;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var shouldUpdateFileFileName = shouldUpdateFileFileNameOpt !== undefined ? shouldUpdateFileFileNameOpt : false;
  var match = React.useState((function () {
          return [];
        }));
  var setErrors = match[1];
  var stateErrors = match[0];
  var sampleId = Sample$Astrolabe.Js.id(sample);
  var onDone = function (errors) {
    Curry._1(setErrors, (function (param) {
            return errors;
          }));
    return Curry._1(onComplete, !Util$Astrolabe.$$Array.isEmpty(errors));
  };
  return React.createElement(SampleGraphQL$Astrolabe.UpdateSampleMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement("td", undefined, React.createElement(FocusOutInput$Astrolabe.make, {
                                  defaultText: Sample$Astrolabe.Js.name(sample),
                                  disabled: disabled,
                                  className: "minWidth-300px",
                                  onFocusOut: (function (updatedSampleName) {
                                      if (updatedSampleName === "") {
                                        return onDone(["Names cannot be blank"]);
                                      }
                                      var variables = shouldUpdateFileFileName ? SampleGraphQL$Astrolabe.UpdateSampleConfig.make(sampleId, updatedSampleName + ".fcs", undefined, updatedSampleName, undefined).variables : SampleGraphQL$Astrolabe.UpdateSampleConfig.make(sampleId, undefined, undefined, updatedSampleName, undefined).variables;
                                      Curry._4(mutation, Caml_option.some(variables), ["getExperiment"], undefined, undefined).then((function (resp) {
                                              if (typeof resp !== "number") {
                                                if (resp.tag) {
                                                  var updateSample = resp[0].updateSample;
                                                  if (updateSample !== undefined) {
                                                    onDone(Caml_option.valFromOption(updateSample).errors);
                                                  }
                                                  
                                                } else {
                                                  console.log(resp[0]);
                                                }
                                              }
                                              return Promise.resolve(undefined);
                                            }));
                                      
                                    })
                                }), React.createElement(DisplayErrors$Astrolabe.make, {
                                  errors: stateErrors
                                }));
                })
            });
}

var $$Array;

var Sample;

var UpdateSampleConfig;

var UpdateSampleMutation;

var make = SampleNameInput;

exports.$$Array = $$Array;
exports.Sample = Sample;
exports.UpdateSampleConfig = UpdateSampleConfig;
exports.UpdateSampleMutation = UpdateSampleMutation;
exports.make = make;
/* react Not a pure module */
