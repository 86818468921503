// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_null = require("bs-platform/lib/js/js_null.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function seriesToJs(series) {
  return {
          id: series.id,
          group: Js_null.fromOption(series.group),
          data: $$Array.map((function (d) {
                  return {
                          x: d.x,
                          y: d.y
                        };
                }), series.data)
        };
}

function point2d(json) {
  var point = Js_json.decodeArray(json);
  if (point !== undefined) {
    var floatPoint = $$Array.map((function (v) {
            var num = Js_json.decodeNumber(v);
            if (num !== undefined) {
              return num;
            }
            throw [
                  Json_decode.DecodeError,
                  "Expected number, got " + JSON.stringify(v)
                ];
          }), point);
    return {
            x: Caml_array.caml_array_get(floatPoint, 0),
            y: Caml_array.caml_array_get(floatPoint, 1)
          };
  }
  throw [
        Json_decode.DecodeError,
        "Expected array, got " + JSON.stringify(json)
      ];
}

function series(json) {
  var partial_arg = [];
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          group: Json_decode.field("group", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          data: Json_decode.field("data", (function (param) {
                  return Json_decode.withDefault(partial_arg, (function (param) {
                                return Json_decode.array(point2d, param);
                              }), param);
                }), json)
        };
}

function fromJson(json) {
  return Json_decode.dict((function (param) {
                return Json_decode.dict((function (param) {
                              return Json_decode.array(series, param);
                            }), param);
              }), json);
}

function getSinglePlotData(antibody, cellSubset, t) {
  var emptySeries = [];
  var dataByCellSubset = Js_dict.get(t, antibody);
  if (dataByCellSubset === undefined) {
    return emptySeries;
  }
  var dataByDonor = Js_dict.get(Caml_option.valFromOption(dataByCellSubset), cellSubset);
  if (dataByDonor !== undefined) {
    return dataByDonor;
  } else {
    return emptySeries;
  }
}

function maxFloat(arr) {
  return $$Array.fold_left((function (max, v) {
                if (v > max) {
                  return v;
                } else {
                  return max;
                }
              }), Pervasives.neg_infinity, arr);
}

function getMaxY(antibody, t) {
  var values = Js_dict.values(Belt_Option.getWithDefault(Js_dict.get(t, antibody), { }));
  return maxFloat($$Array.map((function (d) {
                    return d.y;
                  }), $$Array.of_list(List.fold_left((function (memo, seriesArray) {
                            return List.concat(/* :: */[
                                        memo,
                                        /* :: */[
                                          $$Array.fold_left((function (memo, series) {
                                                  return List.concat(/* :: */[
                                                              memo,
                                                              /* :: */[
                                                                $$Array.to_list(series.data),
                                                                /* [] */0
                                                              ]
                                                            ]);
                                                }), /* [] */0, seriesArray),
                                          /* [] */0
                                        ]
                                      ]);
                          }), /* [] */0, $$Array.to_list(values)))));
}

exports.seriesToJs = seriesToJs;
exports.point2d = point2d;
exports.series = series;
exports.fromJson = fromJson;
exports.getSinglePlotData = getSinglePlotData;
exports.maxFloat = maxFloat;
exports.getMaxY = getMaxY;
/* No side effect */
