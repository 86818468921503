// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Icons$Astrolabe = require("../Icons.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");

function SortByDropdown(Props) {
  var handleSortChange = Props.handleSortChange;
  var klass = Props.klass;
  var label = Props.label;
  var onSortClick = Props.onSortClick;
  var selectOptions = Props.selectOptions;
  var sortAscending = Props.sortAscending;
  var tooltip = Props.tooltip;
  var valueSort = Props.valueSort;
  var width = Props.width;
  var tmp = { };
  if (tooltip !== undefined) {
    tmp.title = Caml_option.valFromOption(tooltip);
  }
  var tmp$1 = { };
  if (width !== undefined) {
    tmp$1.width = Caml_option.valFromOption(width);
  }
  return React.createElement("div", {
              className: "d-flex flex-column align-items-end " + klass
            }, React.createElement("label", tmp, ReasonReactHelpers$Astrolabe.str(label)), React.createElement("div", {
                  className: "d-flex"
                }, React.createElement("div", {
                      title: "Click to reverse sort order",
                      onClick: onSortClick
                    }, sortAscending ? React.createElement(Icons$Astrolabe.SortUp.make, {
                            className: "cursor-pointer gray-on-black",
                            height: "15",
                            width: "15"
                          }) : React.createElement(Icons$Astrolabe.SortDown.make, {
                            className: "cursor-pointer gray-on-black",
                            height: "15",
                            width: "15"
                          })), React.createElement("select", {
                      className: "form-control-sm",
                      style: tmp$1,
                      value: valueSort,
                      onChange: handleSortChange
                    }, ReasonReactHelpers$Astrolabe.arr(selectOptions))));
}

var make = SortByDropdown;

exports.make = make;
/* react Not a pure module */
