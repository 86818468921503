/* @flow */
"use strict";

import DataTable from "./dataTable";
import { Feature } from "./index";

type Props = {
  [string]: {
    // Feature
    cell_subset: Array<string>,
    neg_log10_fdr: Array<number>,
    log_fc?: Array<number>,
    max_log_fc?: Array<number>,
  },
};

type FeatureToFDRs = {
  [string]: {
    // Feature
    [string]: Array<number>, // Cell subset to neg_log10_fdr (and log_fc if it was passed)
  },
};

export default class ExperimentDifferentialAbundance {
  features: FeatureToFDRs;
  yLabels: { [string]: string };

  constructor(props: Props) {
    this.features = {};
    this.yLabels = {};

    for (var feature in props) {
      if (!props[feature]) {
        continue;
      }

      let obj = {};

      const cellSubset = props[feature].cell_subset;
      const negLog10FDR = props[feature].neg_log10_fdr;
      const logFC = props[feature].log_fc;
      const maxLogFC = props[feature].max_log_fc;

      cellSubset.forEach((cs, i) => {
        obj[cs] = [props[feature].neg_log10_fdr[i]];
        if (logFC) {
          obj[cs].push(logFC[i]);
          this.yLabels[feature] = "log(FC)";
        } else if (maxLogFC) {
          obj[cs].push(maxLogFC[i]);
          this.yLabels[feature] = "maximum(log(FC))";
        }
      });

      this.features[feature] = obj;
    }
  }

  hasData(feature: Feature): boolean {
    return !!this.features[`feature_${feature.get("id")}`];
  }

  hasDataForId(featureId: string): boolean {
    console.assert(featureId !== undefined, "featureId must not be undefined");
    return !!this.features[`feature_${featureId}`];
  }

  getFDR(feature: Feature, cellSubset: string): number {
    return this.features[`feature_${feature.get("id")}`][cellSubset][0];
  }

  getFDRForId(featureId: string, cellSubset: string): number {
    return this.features[`feature_${featureId}`][cellSubset][0];
  }

  dataTable(feature: Feature, cellSubsets: Array<string>): DataTable {
    const featureId = `feature_${feature.get("id")}`;

    return new DataTable({
      columns: this.features[featureId],
      columnLabels: cellSubsets,
      rowLabels: ["-log10(FDR)", this.yLabels[featureId]],
    });
  }
}
