/* @flow */
"use strict";

import React from "react";
import { fetchJSON } from "components/utils";
import { Sample } from "models";
import FileUpload from "models/fileUpload";
import DeleteIcon from "../shared/DeleteIcon.bs";

type Props = {
  upload: FileUpload,
  experimentId: string,
  experimentIsPreprocessing: boolean,
  onDelete: (FileUpload) => void,
};

type State = {
  canceled: boolean,
  errorRemoved: boolean,
};

export default class UploadRow extends React.Component<Props, State> {
  handleOnDelete: () => any;
  _isMounted: boolean;

  constructor(props: Props) {
    super(props);

    this._isMounted = false;
  }

  state = {
    canceled: this.props.upload.errors && this.props.upload.errors.length > 0,
    errorRemoved: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.upload.errors !== this.props.upload.errors) {
      this.setState({ canceled: this.props.upload.errors && this.props.upload.errors.length > 0 });
    }
  }

  handleOnDelete = () => {
    if (this.props.upload.sample) {
      this.deleteSample(this.props.upload.sample);
    } else {
      this.props.upload.uploader.abortUpload();
      this.setState({ canceled: true });
    }
  };

  async deleteSample(sample: Sample) {
    this.setState({ canceled: true });
    const resp = await fetchJSON("DELETE", `/experiments/${this.props.experimentId}/samples/${this.props.upload.sample.id}.json`);

    if (resp.errors) {
      console.warn(resp.errors);
    }

    this.props.onDelete ? this.props.onDelete(this.props.upload) : void 0;

    if (!this._isMounted) {
      return;
    }
  }

  render() {
    const style = { width: `${this.props.upload.progress}%` };
    const isDuplicateError =
      this.props.upload.errors && this.props.upload.errors.includes("File file name has already been taken");

    const showDuplicateError = !this.props.experimentIsPreprocessing && isDuplicateError && !this.state.errorRemoved;

    const duplicateErrorRow = (
      <div className="UploadRow row align-items-center">
        <div className="col-12 d-flex">
          <div className="pr-2">
            <DeleteIcon title="Remove this error" onClick={(_) => this.setState({ errorRemoved: true })} />
          </div>
          <div>
            Astrolabe detected files with duplicate names ({this.props.upload.filename}). The platform kept only one copy of each
            such file.
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {showDuplicateError ? duplicateErrorRow : null}
        {this.state.canceled || isDuplicateError ? null : (
          <div className="UploadRow row align-items-center">
            <div className="col-12 col-md-6 d-flex">
              {!this.props.experimentIsPreprocessing && (
                <div className="pr-2">
                  <DeleteIcon title="Remove this file from the experiment" onClick={this.handleOnDelete} />
                </div>
              )}
              {this.props.upload.filename}
            </div>
            <div className="col-10 col-md-6 row align-items-center">
              <div className="col-12 row justify-content-end">
                {this.props.upload.formattedBytesSent()} / {this.props.upload.formattedFileSize()}
              </div>
              <div className="col-12 row justify-content-end">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={style}
                    aria-valuenow={this.props.upload.progress || 0}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
