// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Js_null = require("bs-platform/lib/js/js_null.js");
var DensityData$Astrolabe = require("../../models/DensityData.bs.js");
var ASDSDensityLineChart = require("./ASDSDensityLineChart");

function makeProps(data, maxY, title, yAxisLabel, param) {
  return {
          data: $$Array.map(DensityData$Astrolabe.seriesToJs, data),
          maxY: Js_null.fromOption(maxY),
          title: Js_null.fromOption(title),
          yAxisLabel: Js_null.fromOption(yAxisLabel)
        };
}

var make = ASDSDensityLineChart.default;

exports.makeProps = makeProps;
exports.make = make;
/* make Not a pure module */
