// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function getOrElse(record, key, orElse) {
  var value = record.get(key);
  if (value == null) {
    return orElse;
  } else {
    return value;
  }
}

var Getters = {
  getOrElse: getOrElse
};

var Setters = { };

exports.Getters = Getters;
exports.Setters = Setters;
/* No side effect */
