// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var JsModelsAttributes$Astrolabe = require("./JsModelsAttributes.bs.js");

function tToJs(param) {
  return {
          id: param.id,
          name: param.name,
          created_at: param.created_at,
          updated_at: param.updated_at,
          allowed_email_domain: param.allowed_email_domain,
          max_samples: param.max_samples,
          contract_start_date: param.contract_start_date,
          contract_end_date: param.contract_end_date,
          billed_sample_count: param.billed_sample_count,
          sample_count: param.sample_count,
          user_count: param.user_count
        };
}

function tFromJs(param) {
  return {
          id: param.id,
          name: param.name,
          created_at: param.created_at,
          updated_at: param.updated_at,
          allowed_email_domain: param.allowed_email_domain,
          max_samples: param.max_samples,
          contract_start_date: param.contract_start_date,
          contract_end_date: param.contract_end_date,
          billed_sample_count: param.billed_sample_count,
          sample_count: param.sample_count,
          user_count: param.user_count
        };
}

var Underscored_getOrElse = JsModelsAttributes$Astrolabe.Getters.getOrElse;

var Underscored = {
  getOrElse: Underscored_getOrElse,
  tToJs: tToJs,
  tFromJs: tFromJs,
  fromJs: tFromJs,
  toJs: tToJs
};

function tToJs$1(param) {
  return {
          id: param.id,
          name: param.name,
          allowedEmailDomain: param.allowedEmailDomain,
          billedSampleCount: param.billedSampleCount,
          contractEndDate: param.contractEndDate,
          contractStartDate: param.contractStartDate,
          maxSamples: param.maxSamples,
          sampleCount: param.sampleCount,
          userCount: param.userCount
        };
}

function tFromJs$1(param) {
  return {
          id: param.id,
          name: param.name,
          allowedEmailDomain: param.allowedEmailDomain,
          billedSampleCount: param.billedSampleCount,
          contractEndDate: param.contractEndDate,
          contractStartDate: param.contractStartDate,
          maxSamples: param.maxSamples,
          sampleCount: param.sampleCount,
          userCount: param.userCount
        };
}

function toUnderscored(t) {
  var js = tToJs$1(t);
  return {
          id: Caml_format.caml_int_of_string(js.id),
          name: Belt_Option.getWithDefault(js.name, ""),
          created_at: new Date(),
          updated_at: new Date(),
          allowed_email_domain: js.allowedEmailDomain,
          max_samples: js.maxSamples,
          contract_start_date: Belt_Option.map(js.contractStartDate, (function (prim) {
                  return new Date(prim);
                })),
          contract_end_date: Belt_Option.map(js.contractEndDate, (function (prim) {
                  return new Date(prim);
                })),
          billed_sample_count: js.billedSampleCount,
          sample_count: js.sampleCount,
          user_count: js.userCount
        };
}

var GraphQL = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  fromJs: tFromJs$1,
  toJs: tToJs$1,
  toUnderscored: toUnderscored
};

exports.Underscored = Underscored;
exports.GraphQL = GraphQL;
/* No side effect */
