/* @flow */
"use strict";

// THIS MODULES HAS BEEN REWRITTEN IN REASON AND WILL BE REMOVED SOON

import { Map } from "immutable";
import { interpolateRgbBasis } from "d3-interpolate";
import { scaleLinear } from "d3-scale";
import { interpolateGreens, schemeAccent } from "d3-scale-chromatic";
import DataTable from "./dataTable";
import { ensureArray } from "../components/utils";

export type MDSPoint = {
  x: number,
  y: number,
  freq: number,
  label: string | Array<string>,
  name: string,
  value: number,
  fill: number,
};

export type MDSMapData = {
  colorInterpolator: (number) => string,
  extent: [number, number],
  legendTitle: ?string,
  points: Array<MDSPoint>,
};

export const interpolateRdBkGn = interpolateRgbBasis(["#f00", "#000", "#0f0"]);

export default class MDSData {
  maxX: number;
  maxY: number;
  cellSubsets: Array<string>;
  defaultMapData: MDSMapData;
  medianChannelIntensity: { [string]: Array<number> }; /* Channel to arrays of values */
  logFC: { [string]: Array<number> }; /* "feature_${Feature ID}" to arrays of values */
  negLog10FDR: { [string]: Array<number> }; /* "feature_${Feature ID}" to arrays of values */

  constructor(props: Object) {
    // check if cellSubset is a single item and not an array, and wrap it in an array
    const { cell_subsets, points } = props;
    const pointsX = ensureArray(points.x);
    const pointsY = ensureArray(points.y);
    const pointsFreq = ensureArray(points.freq);
    this.cellSubsets = ensureArray(cell_subsets);

    const minX = Math.abs(Math.min.apply(null, pointsX));
    const minY = Math.abs(Math.min.apply(null, pointsY));

    this.maxX = Math.abs(Math.max.apply(null, pointsX)) + minX;
    this.maxY = Math.abs(Math.max.apply(null, pointsY)) + minY;

    this.defaultMapData = {
      colorInterpolator: (_) => schemeAccent[0],
      extent: [0, 0],
      legendTitle: null,
      points: pointsX.map((x, i) => ({
        i,
        x: Number(x) + minX,
        y: pointsY[i] + minY,
        freq: pointsFreq[i],
        cell_subset: this.cellSubsets[i],
        label: [this.cellSubsets[i], "Click to select"],
        name: this.cellSubsets[i],
        fill: schemeAccent[0],
      })),
    };
    this.medianChannelIntensity = props.median_channel_intensity;
    this.logFC = props.log_fc;
    this.negLog10FDR = props.neg_log_10_fdr;
  }

  getMapData(differentialAbundance: string, colorBy: string): MDSMapData {
    if (colorBy === "") {
      return this.defaultMapData;
    }

    if (differentialAbundance === "none") {
      return this.medianChannelIntensityMapData(colorBy);
    } else if (colorBy === "logFC") {
      return this.logFCMapData(differentialAbundance);
    } else if (colorBy === "negLog10FDR") {
      return this.negLog10FDRMapData(differentialAbundance);
    } else {
      throw `Invalid differentialAbundance (${differentialAbundance}) and colorBy (${colorBy}) combination`;
    }
  }

  medianChannelIntensityMapData(channel: string): MDSMapData {
    const medianChannelIntensity = this.medianChannelIntensity[channel];

    if (!medianChannelIntensity) {
      console.warn(`No median channel intensity data found in MDS data for ${channel}`);
      return this.defaultMapData;
    }

    /**
     * The minimum value will be 0. The maximum value will be at least 2.
     */
    const extent = [0, Math.max(2, Math.max.apply([], medianChannelIntensity))];
    const scale = scaleLinear()
      .domain(extent)
      .range([0, 1]);

    return {
      colorInterpolator: interpolateGreens,
      extent,
      legendTitle: channel,
      points: this.defaultMapData.points.map((pt, i) => {
        const value = medianChannelIntensity[i];
        const fill = interpolateGreens(scale(value));
        const cell_subset = this.cellSubsets[i];
        const name = pt.name;
        let label = [pt.name, "Click to select"];

        if (!Array.isArray(pt.name)) {
          label = window.debugMDS
            ? [pt.name, `${channel}: ${value.toFixed(2)}`, "Click to select", fill]
            : [pt.name, `${channel}: ${value.toFixed(2)}`, "Click to select"];
        }

        return { ...pt, cell_subset, label, name, value, fill };
      }),
    };
  }

  logFCMapData(differentialAbundanceFeatureId: string): MDSMapData {
    const logFC = this.logFC[differentialAbundanceFeatureId];
    if (!logFC || Object.keys(logFC).length == 0) {
      console.warn(`No log(FC) data found in MDS data for Feature ID ${differentialAbundanceFeatureId}`);
      return this.defaultMapData;
    }

    /**
     * The minimum value will be at most -1, and the maximum value will be at least 1.
     * The legend should be symmetric. In other words, if the minimum log(FC) in the data is -3 and the maximum is 2,
     * the legend should go from -3 to 3. This is similar to the X-axis on the volcano plot.
     */
    let min = Math.min(-1, Math.min.apply([], logFC));
    let max = Math.max(1, Math.max.apply([], logFC));

    max = Math.max(Math.abs(min), Math.abs(max));
    min = -max;

    const extent = [min, max];
    const scale = scaleLinear()
      .domain(extent)
      .range([0, 1]);

    return {
      colorInterpolator: interpolateRdBkGn,
      extent,
      legendTitle: "log(Fold Change)",
      points: this.defaultMapData.points.map((pt, i) => {
        const value = logFC[i];
        const fill = interpolateRdBkGn(scale(value));
        const cell_subset = this.cellSubsets[i];
        const name = pt.name;
        let label = [pt.name, "Click to select"];

        if (!Array.isArray(pt.name)) {
          label = window.debugMDS
            ? [pt.name, `log(FC): ${value.toFixed(2)}`, "Click to select", fill]
            : [pt.name, `log(FC): ${value.toFixed(2)}`, "Click to select"];
        }

        return { ...pt, cell_subset, label, name, value, fill };
      }),
    };
  }

  negLog10FDRMapData(differentialAbundanceFeatureId: string): MDSMapData {
    const negLog10FDR = this.negLog10FDR[differentialAbundanceFeatureId];

    if (!negLog10FDR) {
      console.warn(`No -log10(FDR) data found in MDS data for Feature ID ${differentialAbundanceFeatureId}`);
      return this.defaultMapData;
    }

    /**
     * The minimum value will be 0 and the maximum value will be at least 2.
     */
    const extent = [0, Math.max(2, Math.max.apply([], negLog10FDR))];
    const scale = scaleLinear()
      .domain(extent)
      .range([0, 1]);

    return {
      extent,
      colorInterpolator: interpolateGreens,
      legendTitle: "-log10(FDR)",
      points: this.defaultMapData.points.map((pt, i) => {
        const value = negLog10FDR[i];
        const fill = interpolateGreens(scale(value));
        const cell_subset = this.cellSubsets[i];
        const name = pt.name;
        let label = [pt.name, "Click to select"];

        if (!Array.isArray(pt.name)) {
          label = window.debugMDS
            ? [pt.name, `-log10(FDR): ${value.toFixed(2)}`, "Click to select", fill]
            : [pt.name, `-log10(FDR): ${value.toFixed(2)}`, "Click to select"];
        }

        return { ...pt, cell_subset, label, name, value, fill };
      }),
    };
  }
}
