// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var HeatMap$Astrolabe = require("./HeatMap.bs.js");

function DataTableHeatMap(Props) {
  var dataTable = Props.dataTable;
  var nanColor = Props.nanColor;
  var colors = Props.colors;
  var selectedRowLabels = Props.selectedRowLabels;
  var selectedColumnLabels = Props.selectedColumnLabels;
  var onSelectColumnLabel = Props.onSelectColumnLabel;
  var onSelectRowLabel = Props.onSelectRowLabel;
  var legendPosition = Props.legendPosition;
  var legendTitle = Props.legendTitle;
  var symmetricLegendValues = Props.symmetricLegendValues;
  var convertValuesToPercentages = Props.convertValuesToPercentages;
  var minWidthOpt = Props.minWidth;
  var minValue = Props.minValue;
  var maxValue = Props.maxValue;
  var id = Props.id;
  var minWidth = minWidthOpt !== undefined ? minWidthOpt : 100.0;
  var columnLabels = dataTable.columnLabels;
  var rowLabels = dataTable.rowLabels;
  var topMargin = (Util$Astrolabe.or(Util$Astrolabe.$$Array.max($$Array.map((function (prim) {
                    return prim.length;
                  }), columnLabels)), 5) << 3) + 20 | 0;
  var leftMargin = Caml_int32.imul(10, Util$Astrolabe.or(Util$Astrolabe.$$Array.max($$Array.map((function (prim) {
                      return prim.length;
                    }), rowLabels)), 5)) + 20 | 0;
  var height = topMargin + Caml_int32.imul(20, rowLabels.length) | 0;
  var width = leftMargin + Caml_int32.imul(20, columnLabels.length) | 0;
  var width$1 = width > minWidth ? width : minWidth;
  var margin = {
    left: leftMargin,
    top: topMargin,
    bottom: 0.0,
    right: 0.0
  };
  return HeatMap$Astrolabe.make({
              data: dataTable.toColumnsArray(),
              width: width$1,
              height: height,
              nanColor: nanColor,
              margin: margin,
              colors: colors,
              rowLabels: rowLabels,
              columnLabels: columnLabels,
              selectedRowLabels: selectedRowLabels,
              selectedColumnLabels: selectedColumnLabels,
              onSelctColumnLabel: onSelectColumnLabel,
              onSelectRowLabel: onSelectRowLabel,
              legendPosition: legendPosition,
              legendTitle: legendTitle,
              symmetricLegendValues: symmetricLegendValues,
              convertValuesToPercentages: convertValuesToPercentages,
              minValue: minValue,
              maxValue: maxValue,
              id: id
            });
}

var or = Util$Astrolabe.or;

var make = DataTableHeatMap;

var $$default = DataTableHeatMap;

exports.or = or;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Util-Astrolabe Not a pure module */
