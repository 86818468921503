// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var AnalysisKit$Astrolabe = require("../../../models/AnalysisKit.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");

var ppx_printed_query = "mutation CreateDeAnalysisKit($experimentId: ID!, $name: String!, $primaryFeatureId: ID!, $primaryFeatureBaselineValue: String!, $referenceFeatureId: ID, $referenceFeatureBaselineValue: String, $dependentFeatureIds: [ID!]!, $markerChannelIds: [ID!]!, $markerStatistic: MarkerStatistic!)  {\ncreateDeAnalysisKit(experimentId: $experimentId, name: $name, primaryFeatureId: $primaryFeatureId, primaryFeatureBaselineValue: $primaryFeatureBaselineValue, referenceFeatureId: $referenceFeatureId, referenceFeatureBaselineValue: $referenceFeatureBaselineValue, dependentFeatureIds: $dependentFeatureIds, markerChannelIds: $markerChannelIds, markerStatistic: $markerStatistic)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createDeAnalysisKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type AnalysisKit__DifferentialExpressionPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          createDeAnalysisKit: tmp
        };
}

function json_of_MarkerStatistic(value) {
  if (value !== 78066387) {
    if (value >= 826994442) {
      return "MEDIAN";
    } else {
      return "Q95";
    }
  } else {
    return "PERCENT_POSITIVE";
  }
}

function make(experimentId, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "primaryFeatureId",
                    primaryFeatureId
                  ],
                  /* tuple */[
                    "primaryFeatureBaselineValue",
                    primaryFeatureBaselineValue
                  ],
                  /* tuple */[
                    "referenceFeatureId",
                    referenceFeatureId !== undefined ? referenceFeatureId : null
                  ],
                  /* tuple */[
                    "referenceFeatureBaselineValue",
                    referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                  ],
                  /* tuple */[
                    "dependentFeatureIds",
                    dependentFeatureIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerChannelIds",
                    markerChannelIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerStatistic",
                    json_of_MarkerStatistic(markerStatistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  var name = variables.name;
  var primaryFeatureId = variables.primaryFeatureId;
  var primaryFeatureBaselineValue = variables.primaryFeatureBaselineValue;
  var referenceFeatureId = variables.referenceFeatureId;
  var referenceFeatureBaselineValue = variables.referenceFeatureBaselineValue;
  var dependentFeatureIds = variables.dependentFeatureIds;
  var markerChannelIds = variables.markerChannelIds;
  var markerStatistic = variables.markerStatistic;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "experimentId",
                    experimentId
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "primaryFeatureId",
                    primaryFeatureId
                  ],
                  /* tuple */[
                    "primaryFeatureBaselineValue",
                    primaryFeatureBaselineValue
                  ],
                  /* tuple */[
                    "referenceFeatureId",
                    referenceFeatureId !== undefined ? referenceFeatureId : null
                  ],
                  /* tuple */[
                    "referenceFeatureBaselineValue",
                    referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                  ],
                  /* tuple */[
                    "dependentFeatureIds",
                    dependentFeatureIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerChannelIds",
                    markerChannelIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerStatistic",
                    json_of_MarkerStatistic(markerStatistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "experimentId",
                  experimentId
                ],
                /* tuple */[
                  "name",
                  name
                ],
                /* tuple */[
                  "primaryFeatureId",
                  primaryFeatureId
                ],
                /* tuple */[
                  "primaryFeatureBaselineValue",
                  primaryFeatureBaselineValue
                ],
                /* tuple */[
                  "referenceFeatureId",
                  referenceFeatureId !== undefined ? referenceFeatureId : null
                ],
                /* tuple */[
                  "referenceFeatureBaselineValue",
                  referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                ],
                /* tuple */[
                  "dependentFeatureIds",
                  dependentFeatureIds.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "markerChannelIds",
                  markerChannelIds.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "markerStatistic",
                  json_of_MarkerStatistic(markerStatistic)
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "experimentId",
                      experimentId
                    ],
                    /* tuple */[
                      "name",
                      name
                    ],
                    /* tuple */[
                      "primaryFeatureId",
                      primaryFeatureId
                    ],
                    /* tuple */[
                      "primaryFeatureBaselineValue",
                      primaryFeatureBaselineValue
                    ],
                    /* tuple */[
                      "referenceFeatureId",
                      referenceFeatureId !== undefined ? referenceFeatureId : null
                    ],
                    /* tuple */[
                      "referenceFeatureBaselineValue",
                      referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                    ],
                    /* tuple */[
                      "dependentFeatureIds",
                      dependentFeatureIds.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "markerChannelIds",
                      markerChannelIds.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "markerStatistic",
                      json_of_MarkerStatistic(markerStatistic)
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var CreateDeAnalysisKitConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_MarkerStatistic: json_of_MarkerStatistic,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation UpdateDeAnalysisKit($id: ID!, $name: String!, $primaryFeatureId: ID!, $primaryFeatureBaselineValue: String!, $referenceFeatureId: ID, $referenceFeatureBaselineValue: String, $dependentFeatureIds: [ID!]!, $markerChannelIds: [ID!]!, $markerStatistic: MarkerStatistic!)  {\nupdateDeAnalysisKit(id: $id, name: $name, primaryFeatureId: $primaryFeatureId, primaryFeatureBaselineValue: $primaryFeatureBaselineValue, referenceFeatureId: $referenceFeatureId, referenceFeatureBaselineValue: $referenceFeatureBaselineValue, dependentFeatureIds: $dependentFeatureIds, markerChannelIds: $markerChannelIds, markerStatistic: $markerStatistic)  {\nerrors  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateDeAnalysisKit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type AnalysisKit__DifferentialExpressionPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateDeAnalysisKit: tmp
        };
}

function json_of_MarkerStatistic$1(value) {
  if (value !== 78066387) {
    if (value >= 826994442) {
      return "MEDIAN";
    } else {
      return "Q95";
    }
  } else {
    return "PERCENT_POSITIVE";
  }
}

function make$1(id, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "primaryFeatureId",
                    primaryFeatureId
                  ],
                  /* tuple */[
                    "primaryFeatureBaselineValue",
                    primaryFeatureBaselineValue
                  ],
                  /* tuple */[
                    "referenceFeatureId",
                    referenceFeatureId !== undefined ? referenceFeatureId : null
                  ],
                  /* tuple */[
                    "referenceFeatureBaselineValue",
                    referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                  ],
                  /* tuple */[
                    "dependentFeatureIds",
                    dependentFeatureIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerChannelIds",
                    markerChannelIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerStatistic",
                    json_of_MarkerStatistic$1(markerStatistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var id = variables.id;
  var name = variables.name;
  var primaryFeatureId = variables.primaryFeatureId;
  var primaryFeatureBaselineValue = variables.primaryFeatureBaselineValue;
  var referenceFeatureId = variables.referenceFeatureId;
  var referenceFeatureBaselineValue = variables.referenceFeatureBaselineValue;
  var dependentFeatureIds = variables.dependentFeatureIds;
  var markerChannelIds = variables.markerChannelIds;
  var markerStatistic = variables.markerStatistic;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "name",
                    name
                  ],
                  /* tuple */[
                    "primaryFeatureId",
                    primaryFeatureId
                  ],
                  /* tuple */[
                    "primaryFeatureBaselineValue",
                    primaryFeatureBaselineValue
                  ],
                  /* tuple */[
                    "referenceFeatureId",
                    referenceFeatureId !== undefined ? referenceFeatureId : null
                  ],
                  /* tuple */[
                    "referenceFeatureBaselineValue",
                    referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                  ],
                  /* tuple */[
                    "dependentFeatureIds",
                    dependentFeatureIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerChannelIds",
                    markerChannelIds.map((function (prim) {
                            return prim;
                          }))
                  ],
                  /* tuple */[
                    "markerStatistic",
                    json_of_MarkerStatistic$1(markerStatistic)
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(id, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "name",
                  name
                ],
                /* tuple */[
                  "primaryFeatureId",
                  primaryFeatureId
                ],
                /* tuple */[
                  "primaryFeatureBaselineValue",
                  primaryFeatureBaselineValue
                ],
                /* tuple */[
                  "referenceFeatureId",
                  referenceFeatureId !== undefined ? referenceFeatureId : null
                ],
                /* tuple */[
                  "referenceFeatureBaselineValue",
                  referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                ],
                /* tuple */[
                  "dependentFeatureIds",
                  dependentFeatureIds.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "markerChannelIds",
                  markerChannelIds.map((function (prim) {
                          return prim;
                        }))
                ],
                /* tuple */[
                  "markerStatistic",
                  json_of_MarkerStatistic$1(markerStatistic)
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, id, name, primaryFeatureId, primaryFeatureBaselineValue, referenceFeatureId, referenceFeatureBaselineValue, dependentFeatureIds, markerChannelIds, markerStatistic, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "name",
                      name
                    ],
                    /* tuple */[
                      "primaryFeatureId",
                      primaryFeatureId
                    ],
                    /* tuple */[
                      "primaryFeatureBaselineValue",
                      primaryFeatureBaselineValue
                    ],
                    /* tuple */[
                      "referenceFeatureId",
                      referenceFeatureId !== undefined ? referenceFeatureId : null
                    ],
                    /* tuple */[
                      "referenceFeatureBaselineValue",
                      referenceFeatureBaselineValue !== undefined ? referenceFeatureBaselineValue : null
                    ],
                    /* tuple */[
                      "dependentFeatureIds",
                      dependentFeatureIds.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "markerChannelIds",
                      markerChannelIds.map((function (prim) {
                              return prim;
                            }))
                    ],
                    /* tuple */[
                      "markerStatistic",
                      json_of_MarkerStatistic$1(markerStatistic)
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var UpdateDeAnalysisKitConfig = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_MarkerStatistic: json_of_MarkerStatistic$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var CreateDeAnalysisKitMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

var UpdateDeAnalysisKitMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query$1,
      parse: parse$1
    });

function SaveDeKitButton(Props) {
  var experimentId = Props.experimentId;
  var kit = Props.kit;
  var onClick = Props.onClick;
  var onCompleted = Props.onCompleted;
  var match = React.useState((function () {
          return false;
        }));
  var setSaving = match[1];
  var saving = match[0];
  var gqlVariables = AnalysisKit$Astrolabe.DifferentialExpression.toGqlVariables(experimentId, kit);
  var onCompleted$1 = function (data) {
    Curry._1(setSaving, (function (param) {
            return false;
          }));
    if (gqlVariables.tag) {
      return Curry._1(onCompleted, data.createDeAnalysisKit);
    } else {
      return Curry._1(onCompleted, data.updateDeAnalysisKit);
    }
  };
  var onClickBeforeMutation = function (e) {
    e.preventDefault();
    Curry._1(onClick, undefined);
    return Curry._1(setSaving, (function (param) {
                  return true;
                }));
  };
  if (gqlVariables.tag) {
    var vars = gqlVariables[0];
    return React.createElement(CreateDeAnalysisKitMutation.make, {
                onCompleted: onCompleted$1,
                children: (function (mutation, param) {
                    return React.createElement("button", {
                                className: "btn btn-primary",
                                disabled: saving,
                                onClick: (function (e) {
                                    onClickBeforeMutation(e);
                                    return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(makeWithVariables(vars).variables), ["getExperiment"], undefined, undefined));
                                  })
                              }, saving ? " Saving… " : "Create Analysis");
                  })
              });
  }
  var vars$1 = gqlVariables[0];
  return React.createElement(UpdateDeAnalysisKitMutation.make, {
              onCompleted: onCompleted$1,
              children: (function (mutation, param) {
                  return React.createElement("button", {
                              className: "btn btn-primary",
                              disabled: saving,
                              onClick: (function (e) {
                                  onClickBeforeMutation(e);
                                  return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(makeWithVariables$1(vars$1).variables), ["getExperiment"], undefined, undefined));
                                })
                            }, saving ? " Saving… " : "Save Analysis");
                })
            });
}

var make$2 = SaveDeKitButton;

exports.CreateDeAnalysisKitConfig = CreateDeAnalysisKitConfig;
exports.UpdateDeAnalysisKitConfig = UpdateDeAnalysisKitConfig;
exports.CreateDeAnalysisKitMutation = CreateDeAnalysisKitMutation;
exports.UpdateDeAnalysisKitMutation = UpdateDeAnalysisKitMutation;
exports.make = make$2;
/* CreateDeAnalysisKitMutation Not a pure module */
