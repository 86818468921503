// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Util$Astrolabe = require("../models/Util.bs.js");
var Theme$Astrolabe = require("../models/Theme.bs.js");
var NivoHeatMap$Astrolabe = require("./NivoHeatMap.bs.js");
var GradientLegend$Astrolabe = require("./experiments/cell_subset_navigator/GradientLegend.bs.js");

function nivoMakeEmptyRow(label) {
  return Curry._1(Util$Astrolabe.Dict.fromList, /* :: */[
              /* tuple */[
                "rowLabel",
                label
              ],
              /* [] */0
            ]);
}

function checkRowLabel(label) {
  return "✔️ " + label;
}

function uncheckRowLabel(label) {
  return label.replace("✔️ ", "");
}

function checkColumnLabel(label) {
  return label + " ✔️";
}

function uncheckColumnLabel(label) {
  return label.replace(" ✔️", "");
}

function getDataLabel(kind, selected, labels, i) {
  var label = Util$Astrolabe.or(Belt_Array.get(labels, i), String(i));
  if (Curry._2(Util$Astrolabe.$$String.$$Set.contains, label, selected)) {
    if (kind >= 5693978) {
      return "✔️ " + label;
    } else {
      return label + " ✔️";
    }
  } else {
    return label;
  }
}

function HeatMap(Props) {
  var data = Props.data;
  var width = Props.width;
  var height = Props.height;
  var nanColorOpt = Props.nanColor;
  var margin = Props.margin;
  var colorsOpt = Props.colors;
  var rowLabelsOpt = Props.rowLabels;
  var columnLabelsOpt = Props.columnLabels;
  var selectedRowLabelsOpt = Props.selectedRowLabels;
  var selectedColumnLabelsOpt = Props.selectedColumnLabels;
  var onSelctColumnLabel = Props.onSelctColumnLabel;
  var onSelectRowLabel = Props.onSelectRowLabel;
  var legendPositionOpt = Props.legendPosition;
  var legendTitleOpt = Props.legendTitle;
  var symmetricLegendValuesOpt = Props.symmetricLegendValues;
  var minValue = Props.minValue;
  var maxValue = Props.maxValue;
  var convertValuesToPercentagesOpt = Props.convertValuesToPercentages;
  var id = Props.id;
  var nanColor = nanColorOpt !== undefined ? nanColorOpt : "#000000";
  var colors = colorsOpt !== undefined ? colorsOpt : Theme$Astrolabe.Palette.greens;
  var rowLabels = rowLabelsOpt !== undefined ? rowLabelsOpt : [];
  var columnLabels = columnLabelsOpt !== undefined ? columnLabelsOpt : [];
  var selectedRowLabels = selectedRowLabelsOpt !== undefined ? Caml_option.valFromOption(selectedRowLabelsOpt) : Util$Astrolabe.$$String.$$Set.empty;
  var selectedColumnLabels = selectedColumnLabelsOpt !== undefined ? Caml_option.valFromOption(selectedColumnLabelsOpt) : Util$Astrolabe.$$String.$$Set.empty;
  var legendPosition = legendPositionOpt !== undefined ? legendPositionOpt : /* topRight */926161159;
  var legendTitle = legendTitleOpt !== undefined ? legendTitleOpt : "Marker Intensity";
  var symmetricLegendValues = symmetricLegendValuesOpt !== undefined ? symmetricLegendValuesOpt : false;
  var convertValuesToPercentages = convertValuesToPercentagesOpt !== undefined ? convertValuesToPercentagesOpt : false;
  var rowsCount = Util$Astrolabe.or(Curry._2(Util$Astrolabe.$$Option.map, Belt_Array.get(data, 0), (function (prim) {
              return prim.length;
            })), 0);
  var columnsCount = data.length;
  var nivoInitData = Util$Astrolabe.$$Array.generate(rowsCount, (function (i) {
          return nivoMakeEmptyRow(getDataLabel(/* row */5693978, selectedRowLabels, rowLabels, i));
        }));
  var keys = Util$Astrolabe.$$Array.generate(columnsCount, (function (param) {
          return "";
        }));
  var init = {
    columnIdx: {
      contents: 0
    },
    data: nivoInitData,
    min: {
      contents: 0.0
    },
    max: {
      contents: 0.0
    },
    keys: keys
  };
  var $$process = function (nivo, column) {
    var columnLabel = getDataLabel(/* column */-963948842, selectedColumnLabels, columnLabels, nivo.columnIdx.contents);
    Caml_array.caml_array_set(nivo.keys, nivo.columnIdx.contents, columnLabel);
    $$Array.iteri((function (rowIdx, columnValue) {
            var columnValue$1 = convertValuesToPercentages ? columnValue * 100.0 : columnValue;
            var rowData = Caml_array.caml_array_get(nivo.data, rowIdx);
            rowData[columnLabel] = columnValue$1;
            nivo.min.contents = columnValue$1 < nivo.min.contents ? columnValue$1 : nivo.min.contents;
            nivo.max.contents = columnValue$1 > nivo.max.contents ? columnValue$1 : nivo.max.contents;
            
          }), column);
    nivo.columnIdx.contents = nivo.columnIdx.contents + 1 | 0;
    return nivo;
  };
  var nivo = $$Array.fold_left($$process, init, data);
  Util$Astrolabe.$$Option.if_some(minValue, (function (v) {
          nivo.min.contents = convertValuesToPercentages ? v * 100.0 : v;
          
        }));
  Util$Astrolabe.$$Option.if_some(maxValue, (function (v) {
          nivo.max.contents = convertValuesToPercentages ? v * 100.0 : v;
          
        }));
  var onColumnLabelClick = onSelctColumnLabel !== undefined ? (function (_e, label) {
        return Curry._1(onSelctColumnLabel, label.replace(" ✔️", ""));
      }) : null;
  var onRowLabelClick = onSelectRowLabel !== undefined ? (function (_e, label) {
        return Curry._1(onSelectRowLabel, label.replace("✔️ ", ""));
      }) : null;
  var legendPositionAlign = legendPosition >= 926161159 ? "align-items-start" : "align-items-end";
  var heightCss = (
      height < 120 ? height + 100 : height
    ).toString() + "px";
  var match;
  if (symmetricLegendValues) {
    var limit = Caml_primitive.caml_float_max(Math.abs(nivo.max.contents), Math.abs(nivo.min.contents));
    match = /* tuple */[
      -limit,
      limit
    ];
  } else {
    match = /* tuple */[
      nivo.min.contents,
      nivo.max.contents
    ];
  }
  var legendMax = match[1];
  var legendMin = match[0];
  var tmp = {
    className: "d-flex flex-column" + legendPositionAlign,
    style: {
      height: heightCss,
      overflowX: "visible",
      width: "100%"
    }
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  style: {
                    height: heightCss,
                    width: Curry._1(Util$Astrolabe.fmt(/* Format */[
                              /* Float */Block.__(8, [
                                  /* Float_f */0,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* String_literal */Block.__(11, [
                                      "px",
                                      /* End_of_format */0
                                    ])
                                ]),
                              "%fpx"
                            ]), width)
                  }
                }, React.createElement(NivoHeatMap$Astrolabe.make, {
                      data: nivo.data,
                      width: width,
                      height: height,
                      hoverTarget: "cell",
                      cellShape: "rect",
                      indexBy: "rowLabel",
                      keys: nivo.keys,
                      labelTextColor: "rgba(0,0,0,0)",
                      nanColor: nanColor,
                      minValue: legendMin,
                      maxValue: legendMax,
                      margin: margin,
                      colors: colors,
                      forceSquare: false,
                      axisTop: {
                        tickSize: 5.0,
                        tickPadding: 5.0,
                        tickRotation: -90.0,
                        legendOffset: 36.0,
                        onClick: onColumnLabelClick
                      },
                      axisLeft: {
                        tickSize: 5.0,
                        tickPadding: 5.0,
                        legendOffset: 40.0,
                        onClick: onRowLabelClick
                      },
                      animate: false
                    })), React.createElement("div", {
                  className: "d-flex flex-column ml-5 mt-5",
                  style: {
                    height: "100%",
                    width: "100%"
                  }
                }, React.createElement("span", {
                      style: {
                        fontSize: "13px"
                      }
                    }, legendTitle), React.createElement(GradientLegend$Astrolabe.make, {
                      gradientStops: colors,
                      max: legendMax,
                      min: legendMin
                    })));
}

var fmt = Util$Astrolabe.fmt;

var or = Util$Astrolabe.or;

var Dict;

var $$Option;

var $$String$1;

var make = HeatMap;

var $$default = HeatMap;

exports.fmt = fmt;
exports.or = or;
exports.Dict = Dict;
exports.$$Option = $$Option;
exports.$$String = $$String$1;
exports.nivoMakeEmptyRow = nivoMakeEmptyRow;
exports.checkRowLabel = checkRowLabel;
exports.uncheckRowLabel = uncheckRowLabel;
exports.checkColumnLabel = checkColumnLabel;
exports.uncheckColumnLabel = uncheckColumnLabel;
exports.getDataLabel = getDataLabel;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
