// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Util$Astrolabe = require("../../../models/Util.bs.js");
var VictoryTheme$Astrolabe = require("../../VictoryTheme.bs.js");

var tickLabelsStyle = {
  fontFamily: VictoryTheme$Astrolabe.fontFamily,
  fontSize: String(VictoryTheme$Astrolabe.fontSize),
  padding: String(VictoryTheme$Astrolabe.padding)
};

var theme = {
  axis: {
    style: {
      tickLabels: {
        fontSize: 12
      }
    }
  }
};

var i = {
  contents: -1
};

function getMapLegendId(param) {
  i.contents = i.contents + 1 | 0;
  return String(i.contents);
}

function GradientLegend(Props) {
  var barWidthOpt = Props.barWidth;
  var gradientStops = Props.gradientStops;
  var heightOpt = Props.height;
  var max = Props.max;
  var min = Props.min;
  var tickCountOpt = Props.tickCount;
  var widthOpt = Props.width;
  var barWidth = barWidthOpt !== undefined ? barWidthOpt : 25;
  var height = heightOpt !== undefined ? heightOpt : 100;
  var tickCount = tickCountOpt !== undefined ? tickCountOpt : 5;
  var width = widthOpt !== undefined ? widthOpt : 100;
  var id = getMapLegendId(undefined);
  var textHeight = theme.axis.style.tickLabels.fontSize + 2 | 0;
  var textSpacing = Caml_int32.div(height - Caml_int32.imul(tickCount, textHeight) | 0, tickCount - 1 | 0);
  var tickStride = (max - min) / (Util$Astrolabe.Float.fromInt(tickCount) - 1.0);
  var values = Util$Astrolabe.$$Array.generate(tickCount, (function (i) {
          return max - tickStride * Util$Astrolabe.Float.fromInt(i);
        }));
  var gradientStops$1 = Util$Astrolabe.$$Array.reverse(gradientStops);
  var texts = values.map((function (value, i) {
          var textY = Caml_int32.imul(textHeight + textSpacing | 0, i);
          var lineY = textY + (textHeight / 2 | 0) | 0;
          var style = {
            stroke: "#fff",
            strokeWidth: "1"
          };
          return React.createElement(React.Fragment, {
                      children: null,
                      key: String(i)
                    }, React.createElement("line", {
                          style: style,
                          x1: "0",
                          x2: "5",
                          y1: String(lineY),
                          y2: String(lineY)
                        }), React.createElement("line", {
                          style: style,
                          x1: String(barWidth - 5 | 0),
                          x2: String(barWidth),
                          y1: String(lineY),
                          y2: String(lineY)
                        }), React.createElement("text", {
                          style: tickLabelsStyle,
                          dominantBaseline: "text-before-edge",
                          x: String(barWidth + 5 | 0),
                          y: String(textY)
                        }, value.toFixed(1)));
        }));
  var stopStride = Caml_int32.div(100, gradientStops$1.length - 1 | 0);
  var fill = Curry._1(Util$Astrolabe.fmt(/* Format */[
            /* String_literal */Block.__(11, [
                "url(#",
                /* String */Block.__(2, [
                    /* No_padding */0,
                    /* Char_literal */Block.__(12, [
                        /* ")" */41,
                        /* End_of_format */0
                      ])
                  ])
              ]),
            "url(#%s)"
          ]), id);
  return React.createElement("svg", {
              style: {
                height: String(height),
                width: String(width)
              }
            }, React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: id,
                      x1: "0%",
                      x2: "0%",
                      y1: "0%",
                      y2: "100%"
                    }, Curry._2(Util$Astrolabe.$$Array.mapIndexed, (function (i, stopColor) {
                            var offset = String(Caml_int32.imul(stopStride, i)) + "%";
                            return React.createElement("stop", {
                                        key: String(i),
                                        offset: offset,
                                        stopColor: stopColor
                                      });
                          }), gradientStops$1))), React.createElement("rect", {
                  height: String(height - (textHeight / 2 | 0) | 0),
                  width: String(barWidth),
                  fill: fill,
                  x: "0",
                  y: String(textHeight / 4 | 0)
                }), texts);
}

var Float;

var $$Array;

var Int;

var Style;

var fmt = Util$Astrolabe.fmt;

var make = GradientLegend;

exports.Float = Float;
exports.$$Array = $$Array;
exports.Int = Int;
exports.Style = Style;
exports.fmt = fmt;
exports.tickLabelsStyle = tickLabelsStyle;
exports.theme = theme;
exports.getMapLegendId = getMapLegendId;
exports.make = make;
/* tickLabelsStyle Not a pure module */
