/* @flow */
"use strict";

import React from "react";
import { schemeAccent } from "d3-scale-chromatic";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryGroup,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import theme from "models/victoryTheme";

type Props = {
  data: Array<{
    id: string,
    group: string,
    data: Array<{ x: number, y: number }>,
  }>,
  maxY?: number,
  title?: string,
  yAxisLabel?: string,
};

type TitledTooltipProps = {
  datum?: Object,
  style?: Array<Object>,
  text?: Array<string>,
  titleStyle?: Object,
  title?: string | ((Object) => string),
};

class TitledTooltip extends React.Component<TitledTooltipProps> {
  static defaultEvents = VictoryTooltip.defaultEvents;

  render() {
    let title = this.props.title;
    if (typeof this.props.title === "function") {
      title = this.props.title(this.props.datum || {});
    }

    const titleStyle = this.props.titleStyle || { fill: "#000" };

    const style = title ? [titleStyle, ...(this.props.style || [])] : this.props.style;
    const text = title ? [title, ...(this.props.text || [])] : this.props.text;

    return (
      <g>
        <VictoryTooltip {...this.props} style={style} text={text} renderInPortal={false} />
      </g>
    );
  }
}

export default class ASDSDensityLineChart extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const width = 350;
    const leftPadding = 50;

    const groupedDataObj = this.props.data.reduce((groupedData, data) => {
      const group = data.group || data.id;
      if (groupedData[group]) {
        groupedData[group].push(data);
      } else {
        groupedData[group] = [data];
      }
      return groupedData;
    }, {});

    const groupedData = Object.keys(groupedDataObj).map((key) => {
      return { key, data: groupedDataObj[key] };
    });

    return (
      <React.Fragment>
        <VictoryChart
          theme={theme}
          width={width}
          height={250}
          domainPadding={{
            y: 10,
          }}
          padding={{
            top: this.props.title ? 30 : 10,
            right: 10,
            bottom: 20,
            left: this.props.yAxisLabel ? leftPadding : 30,
          }}
          containerComponent={
            <VictoryVoronoiContainer
              width={width}
              height={250}
              responsive={false}
              voronoiDimension="x"
              labels={(d, i) => `${(this.props.data[i] || {}).id}: ${d.y}`}
              labelComponent={<TitledTooltip title={(d) => `x: ${d.x}`} />}
            />
          }
        >
          {this.props.title ? (
            <VictoryLabel text={this.props.title} textAnchor="middle" x={(width + leftPadding) / 2} y={10} />
          ) : null}
          <VictoryAxis crossAxis dependentAxis label={this.props.yAxisLabel} tickFormat={(d) => d.toFixed(1)} />
          <VictoryAxis />
          {groupedData.map((group, i) => {
            return group.data.map((series) => {
              return (
                <VictoryLine
                  key={series.id}
                  name={series.id}
                  interpolation="monotoneX"
                  data={series.data}
                  domain={this.props.maxY ? { y: [0, this.props.maxY] } : undefined}
                  style={{
                    data: {
                      stroke: schemeAccent[i],
                      strokeWidth: 2,
                      strokeOpacity: 1,
                    },
                    labels: {
                      fill: schemeAccent[i],
                    },
                  }}
                />
              );
            });
          })}
        </VictoryChart>
      </React.Fragment>
    );
  }
}
