// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var Notification$Astrolabe = require("../../models/Notification.bs.js");
var NotificationBanner$Astrolabe = require("./NotificationBanner.bs.js");
var NotificationGraphQL$Astrolabe = require("../../graphql/NotificationGraphQL.bs.js");

var GetVisibleNotificationsQuery = ReasonApollo.CreateQuery({
      query: NotificationGraphQL$Astrolabe.GetVisible.query,
      parse: NotificationGraphQL$Astrolabe.GetVisible.parse
    });

function str(prim) {
  return prim;
}

function NotificationBanners(Props) {
  return React.createElement(GetVisibleNotificationsQuery.make, {
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return React.createElement("h3", undefined, " Loading… ");
                  } else if (result.tag) {
                    return $$Array.map((function (un) {
                                  return React.createElement(NotificationBanner$Astrolabe.make, {
                                              id: un.id,
                                              title: un.title,
                                              text: un.text,
                                              alertStyle: un.alertStyle,
                                              key: un.id
                                            });
                                }), $$Array.map(Notification$Astrolabe.GraphQL.fromJs, result[0].visibleNotifications));
                  } else {
                    return null;
                  }
                })
            });
}

var make = NotificationBanners;

var $$default = NotificationBanners;

exports.GetVisibleNotificationsQuery = GetVisibleNotificationsQuery;
exports.str = str;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* GetVisibleNotificationsQuery Not a pure module */
