// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var GraphQLHelpers$Astrolabe = require("../../../utils/GraphQLHelpers.bs.js");
var ConfirmedButton$Astrolabe = require("../../header/ConfirmedButton.bs.js");

var ppx_printed_query = "mutation UpdateExperiment($id: ID!, $isShowAnalysisColumn: Boolean)  {\nupdateExperiment(id: $id, isShowAnalysisColumn: $isShowAnalysisColumn)  {\nerrors  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateExperiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "errors");
      tmp = {
        errors: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map((function (value) {
                  var value$1 = Js_json.decodeString(value);
                  if (value$1 !== undefined) {
                    return value$1;
                  } else {
                    return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
                  }
                })) : Js_exn.raiseError("graphql_ppx: Field errors on type ExperimentPayload is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          updateExperiment: tmp
        };
}

function make(id, isShowAnalysisColumn, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "isShowAnalysisColumn",
                    isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  var isShowAnalysisColumn = variables.isShowAnalysisColumn;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  /* tuple */[
                    "id",
                    id
                  ],
                  /* tuple */[
                    "isShowAnalysisColumn",
                    isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                  ]
                ].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(id, isShowAnalysisColumn, param) {
  return Js_dict.fromArray([
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "isShowAnalysisColumn",
                  isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                ]
              ].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, id, isShowAnalysisColumn, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    /* tuple */[
                      "id",
                      id
                    ],
                    /* tuple */[
                      "isShowAnalysisColumn",
                      isShowAnalysisColumn !== undefined ? isShowAnalysisColumn : null
                    ]
                  ].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var UpdateShowAnalysisConfig = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UpdateShowAnalysisMutation = ReasonApollo.CreateMutation({
      query: ppx_printed_query,
      parse: parse
    });

function AnalysisColumnSettings(Props) {
  var experimentId = Props.experimentId;
  var isShowAnalysisColumn = Props.isShowAnalysisColumn;
  return React.createElement(UpdateShowAnalysisMutation.make, {
              children: (function (mutation, param) {
                  return React.createElement("div", {
                              className: "margin-new-title d-flex flex-column"
                            }, React.createElement("div", undefined, React.createElement("span", {
                                      className: "cursor-help",
                                      title: "If enabled, allows you to exclude channels from cell subset labeling but still include them in visualizations."
                                    }, React.createElement("span", {
                                          className: "astrolabe-text-pink"
                                        }, "(Advanced) "), "\"Analysis Only\" Column")), React.createElement("div", {
                                  className: "btn-group btn-group-toggle d-flex justify-content-end"
                                }, React.createElement(ConfirmedButton$Astrolabe.make, {
                                      buttonClassName: (
                                        isShowAnalysisColumn ? "btn-light " : "btn-secondary "
                                      ) + "btn btn-sm",
                                      buttonTitle: "Disable",
                                      modalButtonClassName: "btn-danger",
                                      modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", {
                                                className: "text-danger"
                                              }, "All 'Analysis Only' channels will become 'Classification and Analysis'. This cannot be undone."), React.createElement("p", undefined, "Are you sure you want to disable the 'Analysis Only' Column?")),
                                      onConfirm: (function (complete) {
                                          GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(experimentId, false, undefined).variables), ["getExperiment"], undefined, undefined));
                                          return Curry._1(complete, undefined);
                                        }),
                                      title: "Disable Analysis Only Column"
                                    }), React.createElement("button", {
                                      className: (
                                        isShowAnalysisColumn ? "btn-secondary " : "btn-light "
                                      ) + "btn btn-sm",
                                      type: "button",
                                      onClick: (function (param) {
                                          return GraphQLHelpers$Astrolabe.ignoreDataHandleErrors(Curry._4(mutation, Caml_option.some(make(experimentId, true, undefined).variables), ["getExperiment"], undefined, undefined));
                                        })
                                    }, "Enabled")));
                })
            });
}

var make$1 = AnalysisColumnSettings;

exports.UpdateShowAnalysisConfig = UpdateShowAnalysisConfig;
exports.UpdateShowAnalysisMutation = UpdateShowAnalysisMutation;
exports.make = make$1;
/* UpdateShowAnalysisMutation Not a pure module */
