/* @flow */
"use strict";

import React from "react";
import { findDOMNode } from "react-dom";
import * as Immutable from "immutable";

import HeatMap from "../HeatMap.bs";

import { Sample, SampleStatistics, Experiment } from "../../models";
import ReUtils from "../../models/Util.bs";
import DataTable from "../../models/dataTable";
import GraphDownloadButton, { canvasGraphType } from "../GraphDownloadButton.bs";

type Props = {
  sample: Sample,
  sampleStatistics: SampleStatistics,
  cellSubsetLevel: string,
  cellSubsetLevels: Array<string>,
  cellSubsetSelectDisabled?: boolean,
  experiment: Experiment,
  onCellSubsetChange: (string) => void,
  onSelectedRowLabelsChange?: (Immutable.OrderedSet<string>) => void,
  onSelectedColumnLabelsChange?: (Immutable.OrderedSet<string>) => void,
  onAllCellsChange?: (boolean) => void,
  selectedRowLabels?: Immutable.OrderedSet<string>,
  selectedColumnLabels?: Immutable.OrderedSet<string>,
  includeAllCells: boolean,
};

type State = {
  dataTable: DataTable,
  columnSort: string,
  columnSortAscending: boolean,
  selectedColumnLabels: Immutable.OrderedSet<string>,
  rowSort: string,
  rowSortAscending: boolean,
  selectedRowLabels: Immutable.OrderedSet<string>,
  includeAllCells: boolean,
  animateHeatMap: boolean,
};

export default class ChannelCellHeatMap extends React.Component<*, *> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = this.initialState(props.cellSubsetLevel);
  }

  initialState(
    cellSubsetLevel: string,
    selectedRowLabels?: Immutable.OrderedSet<string>,
    selectedColumnLabels?: Immutable.OrderedSet<string>,
  ): State {
    return {
      dataTable: this.props.sampleStatistics.dataTable(cellSubsetLevel),
      columnSort: "alphanumeric",
      columnSortAscending: true,
      selectedColumnLabels: selectedColumnLabels || this.props.selectedColumnLabels || Immutable.OrderedSet(),
      rowSort: "alphanumeric",
      rowSortAscending: true,
      selectedRowLabels: selectedRowLabels || this.props.selectedRowLabels || Immutable.OrderedSet(),
      includeAllCells: this.props.includeAllCells,
      animateHeatMap: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.cellSubsetLevel !== nextProps.cellSubsetLevel) {
      this.setState(this.initialState(nextProps.cellSubsetLevel, nextProps.selectedRowLabels, nextProps.selectedColumnLabels));
      return;
    }

    const newState = {};

    if (this.props.sampleStatistics !== nextProps.sampleStatistics) {
      newState.dataTable = nextProps.sampleStatistics.dataTable(nextProps.cellSubsetLevel);
    }

    if (!Immutable.is(this.props.selectedColumnLabels, nextProps.selectedColumnLabels)) {
      newState.selectedColumnLabels = nextProps.selectedColumnLabels;
    }

    if (!Immutable.is(this.props.selectedRowLabels, nextProps.selectedRowLabels)) {
      newState.selectedRowLabels = nextProps.selectedRowLabels;
    }
    this._setStateWithoutAnimation(newState);
  }

  sortByColumn(key: string, ascending: boolean) {
    if (key === "alphanumeric" || key === "hierarchical") {
      const sortOrder = this.props.sampleStatistics.suggested_order[this.props.cellSubsetLevel].cell_subset_order[key];
      return this.state.dataTable.applySort(sortOrder, ascending);
    } else {
      return this.state.dataTable.sortBy(key, ascending);
    }
  }

  sortByRow(key: string, ascending: boolean) {
    if (key === "alphanumeric" || key === "hierarchical") {
      const sortOrder = this.props.sampleStatistics.suggested_order[this.props.cellSubsetLevel].channel_order[key];
      return this.state.dataTable
        .transpose()
        .applySort(sortOrder, ascending)
        .transpose();
    } else {
      return this.state.dataTable
        .transpose()
        .sortBy(key, ascending)
        .transpose();
    }
  }

  handleCellSubsetChange = (e: SyntheticInputEvent<*>) => {
    this.setState(this.initialState(e.target.value));
    if (this.props.onCellSubsetChange) {
      this.props.onCellSubsetChange(e.target.value);
    }
  };

  handleColumnSortChange = (e: SyntheticInputEvent<*>) => {
    this.setState({
      columnSort: e.target.value,
      dataTable: this.sortByColumn(e.target.value, this.state.columnSortAscending),
    });
  };

  handleRowSortChange = (e: SyntheticInputEvent<*>) => {
    this.setState({
      rowSort: e.target.value,
      dataTable: this.sortByRow(e.target.value, this.state.rowSortAscending),
    });
  };

  handleAllCellsChange = (e: SyntheticInputEvent<*>) => {
    const includeAllCells = !this.state.includeAllCells;

    this.setState({ includeAllCells });
    this.props.onAllCellsChange(includeAllCells);
  };

  handleColumnSortDirClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.setState({
      columnSortAscending: !this.state.columnSortAscending,
      dataTable: this.sortByColumn(this.state.columnSort, !this.state.columnSortAscending),
    });
    return false;
  };

  handleRowSortDirClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.setState({
      rowSortAscending: !this.state.rowSortAscending,
      dataTable: this.sortByRow(this.state.rowSort, !this.state.rowSortAscending),
    });
    return false;
  };

  handleResetClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    this.setState(this.initialState(this.props.cellSubsetLevel));
    return false;
  };

  handleClearSelectionClick = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    const newRowLabels = new Immutable.OrderedSet();
    const newColumnLabels = new Immutable.OrderedSet();
    this._setStateWithoutAnimation({
      selectedRowLabels: newRowLabels,
      selectedColumnLabels: newColumnLabels,
    });

    if (this.props.onSelectedColumnLabelsChange) {
      this.props.onSelectedColumnLabelsChange(newColumnLabels);
    }
    if (this.props.onSelectedRowLabelsChange) {
      this.props.onSelectedRowLabelsChange(newRowLabels);
    }
    return false;
  };

  handleRowLabelClick = (originalRowLabel: string) => {
    var newRowLabels = null;

    if (this.state.selectedRowLabels.contains(originalRowLabel)) {
      newRowLabels = this.state.selectedRowLabels.remove(originalRowLabel);
    } else {
      newRowLabels = this.state.selectedRowLabels.add(originalRowLabel);
    }

    this._setStateWithoutAnimation({ selectedRowLabels: newRowLabels });
    if (this.props.onSelectedRowLabelsChange) {
      this.props.onSelectedRowLabelsChange(newRowLabels);
    }
  };

  /**
   * We only allow selecting a maximum of two columns at a time. Once two columns are selected, the first column is
   * "locked in", and subsequent clicks change the second column. If the first column is deselected, the currently
   * selected second column becomes the first column.
   */
  handleColumnLabelClick = (originalColumnLabel: string) => {
    var newColumnLabels = null;

    const toggleColumnLabel = (labels, label) => {
      return labels.contains(label) ? labels.remove(label) : labels.add(label);
    };

    if (this.state.selectedColumnLabels.size < 2 || originalColumnLabel === this.state.selectedColumnLabels.first()) {
      newColumnLabels = toggleColumnLabel(this.state.selectedColumnLabels, originalColumnLabel);
    } else {
      newColumnLabels = toggleColumnLabel(this.state.selectedColumnLabels, this.state.selectedColumnLabels.last());
      newColumnLabels = toggleColumnLabel(newColumnLabels, originalColumnLabel);
    }

    this._setStateWithoutAnimation({ selectedColumnLabels: newColumnLabels });
    if (this.props.onSelectedColumnLabelsChange) {
      this.props.onSelectedColumnLabelsChange(newColumnLabels);
    }
  };

  _setStateWithoutAnimation(newState: any) {
    this.setState({ ...newState, animateHeatMap: false }, () => this.setState({ animateHeatMap: true }));
  }

  transformedDataByColumn(): any {
    var acc = [];
    this.state.dataTable.columnLabels.forEach((colName) => {
      acc.push(this.state.dataTable.columns[colName]);
    });
    return acc;
  }

  transformedColumnLabels(): Array<string> {
    return this.state.dataTable.columnLabels.map((columnLabel) => {
      if (this.state.selectedColumnLabels.contains(columnLabel)) {
        return `${columnLabel} ✔️`;
      } else {
        return columnLabel;
      }
    });
  }

  render() {
    var levelSelect = (
      <div className="form-row">
        <label className="col-12">Cell Subset Level</label>

        <div className="form-group col-12">
          <select
            className="form-control form-control-sm"
            onChange={this.handleCellSubsetChange}
            disabled={this.props.cellSubsetSelectDisabled}
            value={this.props.cellSubsetLevel}
          >
            {this.props.cellSubsetLevels.map((val, i) => (
              <option key={i} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
      </div>
    );

    const data = this.transformedDataByColumn();
    const topMargin =
      9 *
      Math.max.apply(
        null,
        this.state.dataTable.columnLabels.map((x) => x.length),
      );
    const leftMargin =
      9 *
      (Math.max.apply(
        null,
        this.state.dataTable.rowLabels.map((rowLabel) => rowLabel.length),
      ) || 5);
    const width = leftMargin + 20 * this.state.dataTable.columnLabels.length;
    const height = topMargin + 20 * this.state.dataTable.rowLabels.length;

    // Reason sets that must only be used with Reason components. In this case with HeatMap.re
    const re_selectedColumnLabels = ReUtils.js_reasonSetFromImmutableJsOrderedSet(this.state.selectedColumnLabels);
    const re_selectedRowLabels = ReUtils.js_reasonSetFromImmutableJsOrderedSet(this.state.selectedRowLabels);

    return (
      <div className="row">
        <div className="col-3">
          <form>
            {levelSelect}

            <div className="form-row">
              <label className="col-12">Sort Subsets By</label>

              <div className="form-group col-10">
                <select
                  className="form-control form-control-sm"
                  onChange={this.handleColumnSortChange}
                  value={this.state.columnSort}
                >
                  <option value="alphanumeric">Subset Name</option>
                  <option value="hierarchical">Hierarchical Clustering</option>
                  {this.state.dataTable.columnLabels.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-2">
                <button className="btn btn-sm btn-primary" onClick={this.handleColumnSortDirClick}>
                  {this.state.columnSortAscending ? "▲" : "▼"}
                </button>
              </div>
            </div>

            <div className="form-row">
              <label className="col-12">Sort Channels By</label>

              <div className="form-group col-10">
                <select className="form-control form-control-sm" onChange={this.handleRowSortChange} value={this.state.rowSort}>
                  <option value="alphanumeric">Channel Name</option>
                  <option value="hierarchical">Hierarchical Clustering</option>
                  {this.state.dataTable.rowLabels.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-2">
                <button className="btn btn-sm btn-primary" onClick={this.handleRowSortDirClick}>
                  {this.state.rowSortAscending ? "▲" : "▼"}
                </button>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group d-flex justify-content-between">
                <button className="btn btn-sm btn-primary col-5" onClick={this.handleResetClick}>
                  Reset Sort
                </button>
                {this.state.selectedRowLabels.size > 0 || this.state.selectedColumnLabels.size > 0 ? (
                  <button className="btn btn-sm btn-primary col-6" onClick={this.handleClearSelectionClick}>
                    Clear Selection
                  </button>
                ) : null}
              </div>
            </div>

            <hr className="col" />

            <div className="form-row">
              <div className="form-group col-12">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={this.handleAllCellsChange}
                      checked={this.state.includeAllCells}
                    />
                    <span>Include "All Cells" in plots</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-9" ref={(el) => (this.heatMap = el)}>
          <div style={{ height: `${height}px` }} className="mx-auto">
            <GraphDownloadButton
              prefix={this.props.experiment.name}
              name="Heat Map"
              graphType={canvasGraphType}
              container={() => this.heatMap}
              filenames={`${this.props.sample.get("name")}_${this.props.cellSubsetLevel}`}
            />
            <HeatMap
              width={width}
              height={height}
              data={data}
              margin={{ top: topMargin, left: leftMargin, right: 0, bottom: 0 }}
              rowLabels={this.state.dataTable.rowLabels}
              columnLabels={this.state.dataTable.columnLabels}
              selectedColumnLabels={re_selectedColumnLabels}
              selectedRowLabels={re_selectedRowLabels}
              onSelctColumnLabel={this.handleColumnLabelClick}
              onSelectRowLabel={this.handleRowLabelClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
