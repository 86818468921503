// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Modal$Astrolabe = require("../Modal.bs.js");
var ConfirmedButton$Astrolabe = require("../header/ConfirmedButton.bs.js");
var ExperimentGraphQL$Astrolabe = require("../../graphql/ExperimentGraphQL.bs.js");

function reducer(state, action) {
  if (action) {
    return {
            showModal: true,
            errors: action[0]
          };
  } else {
    return {
            showModal: false,
            errors: state.errors
          };
  }
}

function RewindDebarcodingButton(Props) {
  var experimentId = Props.experimentId;
  var disabled = Props.disabled;
  var initialState_errors = [];
  var initialState = {
    showModal: false,
    errors: initialState_errors
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(ExperimentGraphQL$Astrolabe.UpdateEventMutation.make, {
                  children: (function (mutation, param) {
                      return React.createElement(ConfirmedButton$Astrolabe.make, {
                                  buttonClassName: "btn btn-danger",
                                  buttonTitle: "Rewind Debarcoding",
                                  buttonTooltip: disabled ? "Debarcoding can only be rewinded when experiment status is mapping barcoding channels or pending analysis" : "Rewind Debarcoding",
                                  confirmationText: "REWIND",
                                  disabled: disabled,
                                  modalButtonClassName: "btn-danger",
                                  modalContents: React.createElement(React.Fragment, undefined, React.createElement("p", {
                                            className: "text-danger"
                                          }, "All experiment channel mapping will be deleted, then all debarcoding pipeline runs will be canceled and deleted. This cannot be undone."), React.createElement("p", undefined, "Are you sure you want to delete all of the experiment channel mapping and debarcoding pipeline runs?")),
                                  onConfirm: (function (confirm) {
                                      return ExperimentGraphQL$Astrolabe.updateEventAndRedirectToExpRoot(mutation, experimentId, "rewind_debarcoding", (function (errors) {
                                                    Curry._1(dispatch, /* ShowErrorModal */[errors]);
                                                    return Curry._1(confirm, undefined);
                                                  }), undefined);
                                    }),
                                  title: "Rewind Debarcoding"
                                });
                    })
                }), React.createElement(Modal$Astrolabe.make, {
                  onCancel: (function (param) {
                      return Curry._1(dispatch, /* CloseErrorModal */0);
                    }),
                  title: "There has been an error rewinding debarcoding",
                  visible: state.showModal,
                  children: React.createElement("div", {
                        className: "modal-body"
                      }, React.createElement("div", {
                            style: {
                              height: "300px"
                            }
                          }, state.errors.map((function (error, i) {
                                  return React.createElement("div", {
                                              key: String(i) + "error"
                                            }, error);
                                }))), React.createElement("div", {
                            className: "col-12"
                          }, React.createElement("div", {
                                className: "row justify-content-end"
                              }, React.createElement("button", {
                                    className: "btn btn-primary",
                                    onClick: (function (param) {
                                        return Curry._1(dispatch, /* CloseErrorModal */0);
                                      })
                                  }, "Close"))))
                }));
}

var UpdateEventConfig;

var UpdateEventMutation;

var make = RewindDebarcodingButton;

exports.UpdateEventConfig = UpdateEventConfig;
exports.UpdateEventMutation = UpdateEventMutation;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
