// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var User$Astrolabe = require("../../models/User.bs.js");
var ExperimentName$Astrolabe = require("./ExperimentName.bs.js");
var ExperimentTabs$Astrolabe = require("./ExperimentTabs.bs.js");
var SysadminButton$Astrolabe = require("./SysadminButton.bs.js");
var DownloadsDropdown$Astrolabe = require("./DownloadsDropdown.bs.js");
var FinishSetupButton$Astrolabe = require("./FinishSetupButton.bs.js");
var ManageUsersButton$Astrolabe = require("./ManageUsersButton.bs.js");
var ReasonReactHelpers$Astrolabe = require("../../utils/ReasonReactHelpers.bs.js");
var NotificationBanners$Astrolabe = require("../notifications/NotificationBanners.bs.js");
var RewindAnalysisButton$Astrolabe = require("./RewindAnalysisButton.bs.js");
var DeleteExperimentButton$Astrolabe = require("./DeleteExperimentButton.bs.js");
var ExperimentArchiveButton$Astrolabe = require("./ExperimentArchiveButton.bs.js");
var ProcessingNotificationBanners$Astrolabe = require("./ProcessingNotificationBanners.bs.js");

var ppx_printed_query = "query getUserExperiment($experimentId: ID!)  {\nexperiment(id: $experimentId)  {\nid  \ncreateUserEmail  \nflags  \nhasDebarcodingKits  \nhasPipelineStatus  \nisArchived  \nisDeletableByCustomer  \nisEditable  \nisPublic  \nname  \nstatus  \nunassignedUsers  {\nid  \nemail  \n}\n\nusers  {\nid  \nemail  \n}\n\n}\n\ncurrentUser  {\nid  \nemail  \nisAdmin  \nisStaff  \nisSysadmin  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "experiment");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "id");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Experiment is missing");
    }
    var value$7 = Js_dict.get(value$3, "createUserEmail");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field createUserEmail on type Experiment is missing");
    }
    var value$10 = Js_dict.get(value$3, "flags");
    var tmp$3;
    if (value$10 !== undefined) {
      var value$11 = Caml_option.valFromOption(value$10);
      var value$12 = Js_json.decodeString(value$11);
      tmp$3 = value$12 !== undefined ? value$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$11));
    } else {
      tmp$3 = Js_exn.raiseError("graphql_ppx: Field flags on type Experiment is missing");
    }
    var value$13 = Js_dict.get(value$3, "hasDebarcodingKits");
    var tmp$4;
    if (value$13 !== undefined) {
      var value$14 = Caml_option.valFromOption(value$13);
      var value$15 = Js_json.decodeBoolean(value$14);
      tmp$4 = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$14));
    } else {
      tmp$4 = Js_exn.raiseError("graphql_ppx: Field hasDebarcodingKits on type Experiment is missing");
    }
    var value$16 = Js_dict.get(value$3, "hasPipelineStatus");
    var tmp$5;
    if (value$16 !== undefined) {
      var value$17 = Caml_option.valFromOption(value$16);
      var value$18 = Js_json.decodeBoolean(value$17);
      tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$17));
    } else {
      tmp$5 = Js_exn.raiseError("graphql_ppx: Field hasPipelineStatus on type Experiment is missing");
    }
    var value$19 = Js_dict.get(value$3, "isArchived");
    var tmp$6;
    if (value$19 !== undefined) {
      var value$20 = Caml_option.valFromOption(value$19);
      var value$21 = Js_json.decodeBoolean(value$20);
      tmp$6 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$20));
    } else {
      tmp$6 = Js_exn.raiseError("graphql_ppx: Field isArchived on type Experiment is missing");
    }
    var value$22 = Js_dict.get(value$3, "isDeletableByCustomer");
    var tmp$7;
    if (value$22 !== undefined) {
      var value$23 = Caml_option.valFromOption(value$22);
      var value$24 = Js_json.decodeBoolean(value$23);
      tmp$7 = value$24 !== undefined ? value$24 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$23));
    } else {
      tmp$7 = Js_exn.raiseError("graphql_ppx: Field isDeletableByCustomer on type Experiment is missing");
    }
    var value$25 = Js_dict.get(value$3, "isEditable");
    var tmp$8;
    if (value$25 !== undefined) {
      var value$26 = Caml_option.valFromOption(value$25);
      var value$27 = Js_json.decodeBoolean(value$26);
      tmp$8 = value$27 !== undefined ? value$27 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$26));
    } else {
      tmp$8 = Js_exn.raiseError("graphql_ppx: Field isEditable on type Experiment is missing");
    }
    var value$28 = Js_dict.get(value$3, "isPublic");
    var tmp$9;
    if (value$28 !== undefined) {
      var value$29 = Caml_option.valFromOption(value$28);
      var value$30 = Js_json.decodeBoolean(value$29);
      tmp$9 = value$30 !== undefined ? value$30 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$29));
    } else {
      tmp$9 = Js_exn.raiseError("graphql_ppx: Field isPublic on type Experiment is missing");
    }
    var value$31 = Js_dict.get(value$3, "name");
    var tmp$10;
    if (value$31 !== undefined) {
      var value$32 = Caml_option.valFromOption(value$31);
      var value$33 = Js_json.decodeString(value$32);
      tmp$10 = value$33 !== undefined ? value$33 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$32));
    } else {
      tmp$10 = Js_exn.raiseError("graphql_ppx: Field name on type Experiment is missing");
    }
    var value$34 = Js_dict.get(value$3, "status");
    var tmp$11;
    if (value$34 !== undefined) {
      var value$35 = Caml_option.valFromOption(value$34);
      var value$36 = Js_json.decodeString(value$35);
      if (value$36 !== undefined) {
        switch (value$36) {
          case "ADDING_DEBARCODING_KITS" :
              tmp$11 = /* ADDING_DEBARCODING_KITS */889637598;
              break;
          case "ANALYZING" :
              tmp$11 = /* ANALYZING */-384073879;
              break;
          case "CREATED" :
              tmp$11 = /* CREATED */289311176;
              break;
          case "DEBARCODING" :
              tmp$11 = /* DEBARCODING */1020136956;
              break;
          case "DONE" :
              tmp$11 = /* DONE */758036610;
              break;
          case "MAPPING_BARCODING_CHANNELS" :
              tmp$11 = /* MAPPING_BARCODING_CHANNELS */-408660381;
              break;
          case "PENDING_ANALYSIS" :
              tmp$11 = /* PENDING_ANALYSIS */175814564;
              break;
          case "PREPROCESSING" :
              tmp$11 = /* PREPROCESSING */-525726826;
              break;
          default:
            tmp$11 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Status: " + value$36);
        }
      } else {
        tmp$11 = Js_exn.raiseError("graphql_ppx: Expected enum value for Status, got " + JSON.stringify(value$35));
      }
    } else {
      tmp$11 = Js_exn.raiseError("graphql_ppx: Field status on type Experiment is missing");
    }
    var value$37 = Js_dict.get(value$3, "unassignedUsers");
    var tmp$12;
    if (value$37 !== undefined) {
      var value$38 = Caml_option.valFromOption(value$37);
      var match = Js_json.decodeNull(value$38);
      tmp$12 = match !== undefined ? undefined : Js_option.getExn(Js_json.decodeArray(value$38)).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type User is missing");
                }
                var value$5 = Js_dict.get(value$1, "email");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
                }
                return {
                        id: tmp,
                        email: tmp$1
                      };
              }));
    } else {
      tmp$12 = undefined;
    }
    var value$39 = Js_dict.get(value$3, "users");
    var tmp$13;
    if (value$39 !== undefined) {
      var value$40 = Caml_option.valFromOption(value$39);
      var match$1 = Js_json.decodeNull(value$40);
      tmp$13 = match$1 !== undefined ? undefined : Js_option.getExn(Js_json.decodeArray(value$40)).map((function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "id");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field id on type User is missing");
                }
                var value$5 = Js_dict.get(value$1, "email");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
                }
                return {
                        id: tmp,
                        email: tmp$1
                      };
              }));
    } else {
      tmp$13 = undefined;
    }
    tmp = {
      id: tmp$1,
      createUserEmail: tmp$2,
      flags: tmp$3,
      hasDebarcodingKits: tmp$4,
      hasPipelineStatus: tmp$5,
      isArchived: tmp$6,
      isDeletableByCustomer: tmp$7,
      isEditable: tmp$8,
      isPublic: tmp$9,
      name: tmp$10,
      status: tmp$11,
      unassignedUsers: tmp$12,
      users: tmp$13
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field experiment on type Query is missing");
  }
  var value$41 = Js_dict.get(value$1, "currentUser");
  var tmp$14;
  if (value$41 !== undefined) {
    var value$42 = Caml_option.valFromOption(value$41);
    var match$2 = Js_json.decodeNull(value$42);
    if (match$2 !== undefined) {
      tmp$14 = undefined;
    } else {
      var value$43 = Js_option.getExn(Js_json.decodeObject(value$42));
      var value$44 = Js_dict.get(value$43, "id");
      var tmp$15;
      if (value$44 !== undefined) {
        var value$45 = Caml_option.valFromOption(value$44);
        var value$46 = Js_json.decodeString(value$45);
        tmp$15 = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$45));
      } else {
        tmp$15 = Js_exn.raiseError("graphql_ppx: Field id on type User is missing");
      }
      var value$47 = Js_dict.get(value$43, "email");
      var tmp$16;
      if (value$47 !== undefined) {
        var value$48 = Caml_option.valFromOption(value$47);
        var value$49 = Js_json.decodeString(value$48);
        tmp$16 = value$49 !== undefined ? value$49 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$48));
      } else {
        tmp$16 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
      }
      var value$50 = Js_dict.get(value$43, "isAdmin");
      var tmp$17;
      if (value$50 !== undefined) {
        var value$51 = Caml_option.valFromOption(value$50);
        var value$52 = Js_json.decodeBoolean(value$51);
        tmp$17 = value$52 !== undefined ? value$52 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$51));
      } else {
        tmp$17 = Js_exn.raiseError("graphql_ppx: Field isAdmin on type User is missing");
      }
      var value$53 = Js_dict.get(value$43, "isStaff");
      var tmp$18;
      if (value$53 !== undefined) {
        var value$54 = Caml_option.valFromOption(value$53);
        var value$55 = Js_json.decodeBoolean(value$54);
        tmp$18 = value$55 !== undefined ? value$55 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$54));
      } else {
        tmp$18 = Js_exn.raiseError("graphql_ppx: Field isStaff on type User is missing");
      }
      var value$56 = Js_dict.get(value$43, "isSysadmin");
      var tmp$19;
      if (value$56 !== undefined) {
        var value$57 = Caml_option.valFromOption(value$56);
        var value$58 = Js_json.decodeBoolean(value$57);
        tmp$19 = value$58 !== undefined ? value$58 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$57));
      } else {
        tmp$19 = Js_exn.raiseError("graphql_ppx: Field isSysadmin on type User is missing");
      }
      tmp$14 = {
        id: tmp$15,
        email: tmp$16,
        isAdmin: tmp$17,
        isStaff: tmp$18,
        isSysadmin: tmp$19
      };
    }
  } else {
    tmp$14 = undefined;
  }
  return {
          experiment: tmp,
          currentUser: tmp$14
        };
}

function make(experimentId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var experimentId = variables.experimentId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([/* tuple */[
                    "experimentId",
                    experimentId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse
        };
}

function makeVariables(experimentId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "experimentId",
                  experimentId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002(graphql_ppx_use_json_variables_fn, experimentId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "experimentId",
                      experimentId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var GetUserExperiment = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var GetUserExperimentQuery = ReasonApollo.CreateQuery({
      query: ppx_printed_query,
      parse: parse
    });

function getNavHeightPx(extraHeightOpt, param) {
  var extraHeight = extraHeightOpt !== undefined ? extraHeightOpt : 0;
  return String(45 + extraHeight | 0) + "px";
}

function ExperimentHeader(Props) {
  var continueDisabledOpt = Props.continueDisabled;
  var experimentData = Props.experimentData;
  var experimentId = Props.experimentId;
  var handleContinue = Props.handleContinue;
  var childrenOpt = Props.children;
  var continueDisabled = continueDisabledOpt !== undefined ? continueDisabledOpt : false;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  return React.createElement(GetUserExperimentQuery.make, {
              variables: make(experimentId, undefined).variables,
              children: (function (param) {
                  var result = param.result;
                  if (typeof result === "number") {
                    return null;
                  }
                  if (!result.tag) {
                    return React.createElement("div", {
                                className: "alert alert-danger",
                                role: "alert"
                              }, React.createElement("h4", {
                                    className: "alert-heading mb-0 text-center"
                                  }, "Experiment Not Found"));
                  }
                  var data = result[0];
                  var experiment = data.experiment;
                  var currentUser = data.currentUser;
                  var isArchived = experiment.isArchived;
                  var isEditable = experiment.isEditable;
                  var status = experiment.status;
                  var isComplete = status === /* DONE */758036610;
                  var isPublic = experiment.isPublic && status === /* DONE */758036610 && ReasonReactHelpers$Astrolabe.isPublicRoute;
                  var isPublicPathWithoutDoneStatus = status !== /* DONE */758036610 && ReasonReactHelpers$Astrolabe.isPublicRoute;
                  var showContinue = status === /* PENDING_ANALYSIS */175814564;
                  var match;
                  if (currentUser !== undefined) {
                    var user = Caml_option.valFromOption(currentUser);
                    match = /* tuple */[
                      User$Astrolabe.Js.isStaff(user),
                      User$Astrolabe.Js.isSysadmin(user)
                    ];
                  } else {
                    match = /* tuple */[
                      false,
                      false
                    ];
                  }
                  var isSysadmin = match[1];
                  var navHeightPx = isEditable ? "105px" : getNavHeightPx(undefined, undefined);
                  if (isPublicPathWithoutDoneStatus) {
                    return null;
                  } else {
                    return React.createElement("div", undefined, React.createElement("nav", {
                                    className: "navbar fixed-top border-top-dark-blue-2px p-0",
                                    style: {
                                      height: navHeightPx,
                                      top: "56px",
                                      zIndex: "1025"
                                    }
                                  }, isEditable ? React.createElement("div", {
                                          className: "astrolabe-bg-dark-blue w-100 pb-1 pt-2"
                                        }, React.createElement("div", {
                                              className: "d-flex align-items-center py-2",
                                              style: {
                                                marginLeft: "5%",
                                                width: "40%"
                                              }
                                            }, React.createElement(ExperimentName$Astrolabe.make, {
                                                  experimentId: experimentId,
                                                  experimentName: experiment.name,
                                                  isEditable: isEditable
                                                }))) : null, React.createElement("div", {
                                        className: "d-flex justify-content-between align-items-center w-100 astrolabe-bg-blue",
                                        style: {
                                          height: "58px"
                                        }
                                      }, React.createElement("div", {
                                            style: {
                                              marginLeft: "5%",
                                              width: "70%"
                                            }
                                          }, React.createElement(ExperimentTabs$Astrolabe.make, {
                                                experimentStatus: status,
                                                experimentId: experimentId,
                                                experimentName: experiment.name,
                                                hasDebarcodingKits: experiment.hasDebarcodingKits
                                              })), React.createElement("div", {
                                            className: "d-flex justify-content-end align-items-center astrolabe-bg-blue children-ml-3",
                                            style: {
                                              marginRight: "5%",
                                              width: "25%"
                                            }
                                          }, isPublic ? React.createElement(DownloadsDropdown$Astrolabe.make, {
                                                  experimentId: experimentId,
                                                  hasDebarcodingKits: experiment.hasDebarcodingKits
                                                }) : React.createElement(React.Fragment, undefined, experiment.hasPipelineStatus ? null : React.createElement(DeleteExperimentButton$Astrolabe.make, {
                                                        experimentId: experimentId,
                                                        isDeletableByCustomer: experiment.isDeletableByCustomer,
                                                        isStaff: match[0]
                                                      }), React.createElement(ManageUsersButton$Astrolabe.make, {
                                                      currentUser: currentUser,
                                                      experiment: experiment
                                                    }), isSysadmin ? React.createElement(SysadminButton$Astrolabe.make, {
                                                        experiment: experiment
                                                      }) : null, isComplete ? React.createElement(React.Fragment, undefined, React.createElement(ExperimentArchiveButton$Astrolabe.make, {
                                                            experimentId: experimentId,
                                                            isArchived: isArchived
                                                          }), isSysadmin ? React.createElement(RewindAnalysisButton$Astrolabe.make, {
                                                              experimentId: experimentId
                                                            }) : null, React.createElement(DownloadsDropdown$Astrolabe.make, {
                                                            experimentId: experimentId,
                                                            hasDebarcodingKits: experiment.hasDebarcodingKits
                                                          })) : null, showContinue && handleContinue !== undefined ? React.createElement(FinishSetupButton$Astrolabe.make, {
                                                        buttonDisabled: continueDisabled,
                                                        experimentId: experimentId,
                                                        experimentData: experimentData,
                                                        handleContinue: handleContinue
                                                      }) : null)))), React.createElement("div", {
                                    style: {
                                      marginTop: navHeightPx
                                    }
                                  }), children, isPublic ? null : React.createElement(NotificationBanners$Astrolabe.make, { }), React.createElement(ProcessingNotificationBanners$Astrolabe.make, {
                                    status: status
                                  }));
                  }
                })
            });
}

var make$1 = ExperimentHeader;

var $$default = ExperimentHeader;

exports.GetUserExperiment = GetUserExperiment;
exports.GetUserExperimentQuery = GetUserExperimentQuery;
exports.getNavHeightPx = getNavHeightPx;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* GetUserExperimentQuery Not a pure module */
