/* @flow */
"use strict";

import * as React from "react";
import { Experiment } from "models";
import Modal from "../Modal";

type Props = {
  buttonClassName: ?string,
  buttonTitle?: string,
  buttonTooltip?: string,
  confirmationText: ?string,
  disabled: ?boolean,
  isIcon: ?boolean,
  icon: ?React.node,
  modalButtonClassName: ?string,
  modalContents: React.Node,
  onConfirm: (completionCallback: () => void) => void | Promise<void>,
  title: string,
};

type State = {
  confirmationModalVisible: boolean,
  confirmationTextTyped: string,
};

export default class ConfirmedButton extends React.Component<Props, State> {
  handleCancel: () => void;
  handleConfirm: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      confirmationModalVisible: false,
      confirmationTextTyped: "",
    };
  }

  handleCancel = () => {
    this.setState({ confirmationModalVisible: false });
  };

  handleConfirm = () => {
    this.props.onConfirm(() => {
      this.setState({ confirmationModalVisible: false });
    });
  };

  render() {
    const showConfirmationInput = this.props.confirmationText && this.props.confirmationText !== "";
    const confirmedTextEntered = showConfirmationInput && this.props.confirmationText !== this.state.confirmationTextTyped;
    return (
      <React.Fragment>
        {this.props.isIcon ? (
          <div
            title={this.props.buttonTitle}
            className={`cursor-pointer ${this.props.buttonClassName || ""}`}
            onClick={() => this.setState({ confirmationModalVisible: true })}
          >
            {this.props.icon}
          </div>
        ) : (
          <button
            title={this.props.buttonTooltip}
            className={`btn ${this.props.buttonClassName || ""}`}
            disabled={this.props.disabled}
            onClick={() => this.setState({ confirmationModalVisible: true })}
          >
            {this.props.buttonTitle ? this.props.buttonTitle : `${this.props.title}…`}
          </button>
        )}

        <Modal title={this.props.title} visible={this.state.confirmationModalVisible} onCancel={this.handleCancel}>
          <div className="modal-body">
            {this.props.modalContents}
            <hr />
            {showConfirmationInput && (
              <div className="form-group">
                <label htmlFor="confirmation_text">Please type {this.props.confirmationText} (in upper case) to confirm</label>
                <input
                  className="form-control"
                  type="text"
                  name="confirmation_text"
                  value={this.state.confirmationTextTyped}
                  onChange={(e) => this.setState({ confirmationTextTyped: e.target.value })}
                />
              </div>
            )}
            <div className="col-12">
              <div className="row justify-content-end">
                <button className="btn btn-light" onClick={this.handleCancel}>
                  Cancel
                </button>
                &nbsp;
                <button
                  className={`btn ${this.props.modalButtonClassName || ""}`}
                  onClick={this.handleConfirm}
                  disabled={confirmedTextEntered}
                >
                  {this.props.title}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
