// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$Astrolabe = require("./Theme.bs.js");
var Channel$Astrolabe = require("./Channel.bs.js");
var Feature$Astrolabe = require("./Feature.bs.js");

function of_string(s) {
  switch (s) {
    case "MEDIAN" :
        return /* MEDIAN */826994442;
    case "PERCENT_POSITIVE" :
        return /* PERCENT_POSITIVE */78066387;
    case "Q95" :
        return /* Q95 */4040813;
    default:
      return Pervasives.invalid_arg("invalid statistic code");
  }
}

function to_string(s) {
  if (s !== 78066387) {
    if (s >= 826994442) {
      return "MEDIAN";
    } else {
      return "Q95";
    }
  } else {
    return "PERCENT_POSITIVE";
  }
}

function name(s) {
  if (s !== 78066387) {
    if (s >= 826994442) {
      return "Median";
    } else {
      return "95th Quantile";
    }
  } else {
    return "% Positive";
  }
}

function isBeta(s) {
  return s === 78066387;
}

var all = [
  /* Q95 */4040813,
  /* MEDIAN */826994442,
  /* PERCENT_POSITIVE */78066387
];

var Statistic = {
  of_string: of_string,
  to_string: to_string,
  name: name,
  isBeta: isBeta,
  all: all
};

function value(markerStatistic) {
  if (markerStatistic !== 78066387) {
    return 4.0;
  } else {
    return 1.0;
  }
}

var FC = {
  value: value
};

function legendTitle(markerStatistic) {
  if (markerStatistic !== 78066387) {
    return "Marker Intensity";
  } else {
    return "% Positive";
  }
}

function colors(markerStatistic) {
  if (markerStatistic !== 78066387) {
    return Theme$Astrolabe.Palette.greens;
  } else {
    return Theme$Astrolabe.Palette.purples;
  }
}

function convertValuesToPercentages(markerStatistic) {
  return markerStatistic === 78066387;
}

var Overview = {
  legendTitle: legendTitle,
  colors: colors,
  convertValuesToPercentages: convertValuesToPercentages
};

var HeatMap = {
  FC: FC,
  Overview: Overview
};

function make(id, name, position, primaryFeature, primaryFeatureBaselineValue, dependentFeatures, referenceFeature, referenceFeatureBaselineValueOpt, markerChannels, markerStatisticOpt, param) {
  var referenceFeatureBaselineValue = referenceFeatureBaselineValueOpt !== undefined ? Caml_option.valFromOption(referenceFeatureBaselineValueOpt) : undefined;
  var markerStatistic = markerStatisticOpt !== undefined ? markerStatisticOpt : /* Q95 */4040813;
  var orEmptyArray = function (str) {
    return Belt_Option.getWithDefault(str, []);
  };
  return {
          id: id,
          position: position,
          name: Belt_Option.getWithDefault(name, ""),
          primaryFeature: primaryFeature,
          primaryFeatureBaselineValue: Belt_Option.getWithDefault(primaryFeatureBaselineValue, ""),
          dependentFeatures: orEmptyArray(dependentFeatures),
          referenceFeature: referenceFeature,
          referenceFeatureBaselineValue: referenceFeatureBaselineValue,
          markerChannels: orEmptyArray(markerChannels),
          markerStatistic: markerStatistic
        };
}

function getFeatureId(feature) {
  if (feature !== undefined) {
    return Feature$Astrolabe.id(Caml_option.valFromOption(feature));
  } else {
    return "";
  }
}

function fromJs(js) {
  var channelUsageFromJs = function (cu) {
    return Channel$Astrolabe.make(cu.id, cu.name, cu.desc, cu.usage, undefined);
  };
  return make(js.id, js.name, js.position, Caml_option.some(Feature$Astrolabe.fromJs(js.primaryFeature)), js.primaryFeatureBaselineValue, $$Array.map(Feature$Astrolabe.fromJs, js.dependentFeatures), Belt_Option.map(js.referenceFeature, Feature$Astrolabe.fromJs), Caml_option.some(js.referenceFeatureBaselineValue), $$Array.map(channelUsageFromJs, js.markerChannels), js.markerStatistic, undefined);
}

function toGqlVariables(experimentId, t) {
  var id = t.id;
  if (id !== undefined) {
    return /* UpdateVariables */Block.__(0, [{
                id: id,
                name: t.name,
                primaryFeatureId: getFeatureId(t.primaryFeature),
                primaryFeatureBaselineValue: t.primaryFeatureBaselineValue,
                dependentFeatureIds: $$Array.map((function (f) {
                        return getFeatureId(Caml_option.some(f));
                      }), t.dependentFeatures),
                referenceFeatureId: Belt_Option.map(t.referenceFeature, Feature$Astrolabe.id),
                referenceFeatureBaselineValue: t.referenceFeatureBaselineValue,
                markerChannelIds: $$Array.map((function (cu) {
                        return cu.id;
                      }), t.markerChannels),
                markerStatistic: t.markerStatistic
              }]);
  } else {
    return /* CreateVariables */Block.__(1, [{
                name: t.name,
                experimentId: experimentId,
                primaryFeatureId: getFeatureId(t.primaryFeature),
                primaryFeatureBaselineValue: t.primaryFeatureBaselineValue,
                dependentFeatureIds: $$Array.map((function (f) {
                        return getFeatureId(Caml_option.some(f));
                      }), t.dependentFeatures),
                referenceFeatureId: Belt_Option.map(t.referenceFeature, Feature$Astrolabe.id),
                referenceFeatureBaselineValue: t.referenceFeatureBaselineValue,
                markerChannelIds: $$Array.map((function (cu) {
                        return cu.id;
                      }), t.markerChannels),
                markerStatistic: t.markerStatistic
              }]);
  }
}

var DifferentialExpression = {
  make: make,
  getFeatureId: getFeatureId,
  fromJs: fromJs,
  toGqlVariables: toGqlVariables
};

exports.Statistic = Statistic;
exports.HeatMap = HeatMap;
exports.DifferentialExpression = DifferentialExpression;
/* Theme-Astrolabe Not a pure module */
