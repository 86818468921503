// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function fromSlug(str) {
  switch (str) {
    case "analysis" :
        return /* ANALYSIS */739437404;
    case "classification" :
        return /* CLASSIFICATION */257034022;
    case "ignored" :
        return /* IGNORED */-592639246;
    case "live_dead" :
        return /* LIVE_DEAD */-568609769;
    default:
      return Pervasives.invalid_arg(str);
  }
}

function toSlug(param) {
  if (param >= 257034022) {
    if (param >= 739437404) {
      return "analysis";
    } else {
      return "classification";
    }
  } else if (param >= -568609769) {
    return "live_dead";
  } else {
    return "ignored";
  }
}

function toDisplayString(param) {
  if (param >= 257034022) {
    if (param >= 739437404) {
      return "Analysis Only";
    } else {
      return "Used";
    }
  } else if (param >= -568609769) {
    return "Live/Dead";
  } else {
    return "Ignored";
  }
}

var Usage = {
  fromSlug: fromSlug,
  toSlug: toSlug,
  toDisplayString: toDisplayString
};

function name(self) {
  return self.name;
}

function desc(self) {
  return self.desc;
}

function make(id, name, desc, usage, param) {
  return {
          id: id,
          name: name,
          desc: desc,
          usage: usage
        };
}

function channelUsage(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          desc: Json_decode.field("desc", Json_decode.string, json),
          usage: fromSlug(Json_decode.field("usage", Json_decode.string, json))
        };
}

var Decode = {
  channelUsage: channelUsage
};

function channelUsage$1(channel) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                channel.id
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  channel.name
                ],
                /* :: */[
                  /* tuple */[
                    "desc",
                    channel.desc
                  ],
                  /* :: */[
                    /* tuple */[
                      "usage",
                      toSlug(channel.usage)
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var Encode = {
  channelUsage: channelUsage$1
};

function id(self) {
  return self.id;
}

function name$1(self) {
  return self.name;
}

var Js = {
  id: id,
  name: name$1
};

exports.Usage = Usage;
exports.name = name;
exports.desc = desc;
exports.make = make;
exports.Decode = Decode;
exports.Encode = Encode;
exports.Js = Js;
/* No side effect */
